import { InputMaybe, NotificationStatus, } from '@/__generated__/graphql'

export interface IAddNotificationBody {
  message: string;
  tab?: string;
  reqId?: number
}

export interface IAddNotificationMeta {
  path: string;
  from?: string;
}


export interface IAddNotification {
  source: string;
  targetIds: string[];
  body: IAddNotificationBody;
  meta: IAddNotificationMeta;
}

interface PaginationType {
  offset: number
  limit: number
}


export enum NotificationMetaType {
  message = 'message',
  dynamic = 'dynamic',
}

enum SortOrder {
  asc,
  desc
}

interface SortField {
  column: string
  order: SortOrder
  nulls?: string
}

export interface INotificationListInput {
  pagination?: PaginationType
  status?: [NotificationStatus]
  metaType?: NotificationMetaType
  source?: string[]
  sort?: [SortField]
}

export interface IOpenToast {
  message: string
}

export interface IAppContext {
  addNotification: (source: string,
    targetIds: string[],
    body: IAddNotificationBody,
    meta: IAddNotificationMeta,
    ) => void;
  updateNotification: (message_ids: number[],
    status: InputMaybe<NotificationStatus>) => void;
  useNotifications: (obj:INotificationListInput) => any;
  useNotificationStats: () => any;
  openToast: (obj: IOpenToast) => void;
}

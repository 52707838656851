import { TitleH3, } from '../../../../common/GeneralElements/GeneralElements'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple, { IconComponent, } from '../../../../common/ButtonSimple/ButtonSimple'
import { IconCircleCheck, IconCircleX, } from '@tabler/icons'
import { useEffect, useState, } from 'react'
import TextArea from '../../../../common/TextArea/TextArea'
import { useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'


const PopUpContent_1_41 = ({
  data,
  onClosePopUp,
}: any) => {
  const [
    rejectAchievement,
    setRejectAchievement,
  ] = useState(false)
  const [
    reason,
    setReason,
  ] = useState('')

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (oldData: any, updateField: any, newValue: any, reason = '') => onUpdateRow({
    variables: {
      rec_id: oldData.rec_id,
      new_data: {
        ...oldData,
        reason: reason,
        [updateField]: newValue,
      },
    },
  })

  useEffect(() => {
    setRejectAchievement(false)
  }, [
    data,
  ])

  return (
    <>
      <TitleH3>
        Участие в профориентационных мероприятиях, организованных кафедрой, институтом/факультетом, Университетом.
      </TitleH3>
      <br />


      <InputTextDeprecated
        defaultHint="Год набора"
        value={ data?.yearOfRecruitment }
        disabled
      /><br />

      <InputTextDeprecated
        defaultHint="Код и направление подготовки"
        value={ data?.major }
        disabled
      /><br />

      <InputTextDeprecated
        defaultHint="Форма обучения"
        value={ data?.formOfEducation }
        disabled
      /><br />

      <InputTextDeprecated
        defaultHint="Программа магистратуры/аспирантуры"
        value={ data?.programm }
        disabled
      /><br />

      <InputTextDeprecated
        defaultHint="Тематика научного исследования"
        value={ data?.theme }
        disabled
      /><br />


      <AttachedFiles
        fileList={ data.document }
        isDeleteFilesAllowed={ false }
      />

      { rejectAchievement ? (
        <>
          <TextArea
            defaultHint='По какой причине вы хотите отклонить заявку?'
            withPlaceholder={ false }
            value={ reason }
            onChangeValue={ setReason }
          />

          <br />

          <div style={ {
            display: 'flex',
            justifyContent: 'flex-end',
          } }>
            <ButtonSimple
              value="Отменить"
              variant="groupe"
              styles={ {
                marginRight: '1rem',
                backgroundColor: '#F5F6FA',
              } }
              onClickHandler={ () => setRejectAchievement(false) }
            />

            <ButtonSimple
              value={ (
                <IconComponent
                  Icon={ IconCircleX }
                  iconStyles={ { color: '#FF5E5E', } }
                  text="Отклонить"
                />
              ) }
              variant="groupe"
              isDisabled={ reason.split(' ').length < 3 }
              onClickHandler={ () => {
                onUpdateRowHandler(data, 'status', 'rejected', reason)
                onClosePopUp()
              } }
            />
          </div>
        </>
      ) : false }

      { !rejectAchievement ? (
        <div style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }>
          <ButtonSimple
            value={ (
              <IconComponent
                Icon={ IconCircleX }
                iconStyles={ { color: '#FF5E5E', } }
                text="Отклонить"
              />
            ) }
            variant="groupe"
            styles={ { marginRight: '1rem', } }
            onClickHandler={ () => setRejectAchievement(true) }
          />

          <ButtonSimple
            value={ (
              <IconComponent
                Icon={ IconCircleCheck }
                text="Подтвердить"
              />
            ) }
            variant="groupe"
            active
            onClickHandler={ () => {
              onUpdateRowHandler(data, 'status', 'approved')
              onClosePopUp()
            } }
          />
        </div>
      ) : false }
    </>
  )
}


export default PopUpContent_1_41

import { Table, } from 'antd'
import './ResultsTable.less'


function ResultsTable () {
  const columns = [
    {
      title: 'Отсутствий',
      width: 101,
      dataIndex: 'miss',
      key: 'miss',
    },
    {
      title: 'Баллы по практ. занятиям',
      width: 200,
      dataIndex: 'point',
      key: 'point',
    },
    {
      title: 'Итоговая оценка',
      width: 139,
      dataIndex: 'mark',
      key: 'mark',
    },
  ]

  const data = [
    {
      key: Math.random(),
      miss: '5',
      point: '105',
      mark: '500',
    },
    {
      key: Math.random(),
      miss: '5',
      point: '105',
      mark: '500',
    },
    {
      key: Math.random(),
      miss: '5',
      point: '105',
      mark: '500',
    },
    {
      key: Math.random(),
      miss: '5',
      point: '105',
      mark: '500',
    },
    {
      key: Math.random(),
      miss: '5',
      point: '105',
      mark: '500',
    },
    {
      key: Math.random(),
      miss: '5',
      point: '105',
      mark: '500',
    },
    {
      key: Math.random(),
      miss: '5',
      point: '105',
      mark: '500',
    },
  ]


  return (
    <>
      <Table columns={ columns } dataSource={ data } />
    </>
  )
}

export default ResultsTable

import { TitleH3, TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import ButtonSimple, { IconComponent, } from '../../../../common/ButtonSimple/ButtonSimple'
import { IconCircleCheck, IconCircleX, } from '@tabler/icons'
import { useEffect, useState, } from 'react'
import TextArea from '../../../../common/TextArea/TextArea'
import { useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useGetEcListData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import CalendarSimple from '../../../../common/CalendarSimple/CalendarSimple'
import { useLocation, } from 'react-router-dom'
import { format, } from 'date-fns'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputFile from '../../../../common/InputFile/InputFile'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import { table_cat_2_5, } from '../../../popUpInputsCatalogs'
import SpecsSearch from '../../../Hooks/SpecsSearch/SpecsSearch'
import { getScores_2_5, } from '../../../getScores'


const PopUpAppealContent_2_5 = ({
  data,
  onClosePopUp,
}: any) => {
  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  const [
    ids,
  ] = useState(moderateId)


  const [
    otherVectorActivity,
    setOtherVectorActivity,
  ] = useState(false)

  const [
    vectorActivity,
    setVectorActivity,
  ] = useState('')
  const [
    level,
    setLevel,
  ] = useState('')
  const [
    competitionName,
    setCompetitionName,
  ] = useState('')
  const [
    date,
    setDate,
  ] = useState('')
  const [
    organizer,
    setOrganizer,
  ] = useState('')
  const [
    comandName,
    setComandName,
  ] = useState('')
  const [
    vector,
    setVector,
  ] = useState('')
  const [
    document,
    setDocument,
  ] = useState([])


  const [
    textAppeal,
    setTextAppeal,
  ] = useState('')
  const [
    currentAppealId,
    setCurrentAppealId,
  ] = useState(data.rec_id)
  const [
    currentAchievementId,
    setCurrentAchievementId,
  ] = useState(null)
  const [
    rejectAchievement,
    setRejectAchievement,
  ] = useState(false)
  const [
    reason,
    setReason,
  ] = useState('')
  const [
    scores,
    setScores,
  ] = useState('')


  useEffect(() => {
    setVectorActivity(data.vectorActivity)
    setLevel(data.level)
    setCompetitionName(data.competitionName)
    setDate(format(new Date(data.date), 'yyyy-MM-dd'))
    setOrganizer(data.organizer)
    setComandName(data.comandName)
    setVector(data.vector)
    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setDocument(data.document ? [
      data.document,
    ] : [])

    setTextAppeal(data.textAppeal)
    setCurrentAppealId(data.rec_id)
  }, [
    data,
  ])


  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    setScores(getScores_2_5({
      level: level,
    }))
  }, [
    level,
  ])


  const [
    currentAchievement,
    setCurrentAchievement,
  ] = useState(null)
  const [
    currentAppeal,
    setCurrentAppeal,
  ] = useState(null)

  const getCurrentAppeal = useGetEcListData({
    rec_id: currentAppealId,
  })

  const getCurrentAchievement = useGetEcListData({
    rec_id: currentAchievementId,
  })

  useEffect(() => {
    if (getCurrentAppeal?.data) {
      setCurrentAppeal(
        getCurrentAppeal?.data?.ecListData[0]?.data)

      setCurrentAchievementId(getCurrentAppeal?.data?.ecListData[0]?.data?.rec_id)
    }
  }, [
    getCurrentAppeal.loading,
    getCurrentAppeal.data,
  ])

  useEffect(() => {
    if (getCurrentAchievement?.data && currentAchievementId) {
      setCurrentAchievement(
        getCurrentAchievement?.data?.ecListData[0]?.data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCurrentAchievement.loading,
    getCurrentAchievement.data,
  ])


  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (rec_id: any, newData: any, ids: any) => onUpdateRow({
    variables: {
      rec_id: rec_id,
      new_data: newData,
      user_ids: ids,
    },
  })


  useEffect(() => {
    setRejectAchievement(false)
  }, [
    data,
  ])


  return <>
    <TitleH3>
      Подготовка студентов-победителей и призеров олимпиад/конкурсов.
    </TitleH3>
    <p>Внесите требуемые изменения и подтвердие или отклоните апелляцию</p>
    <p>Комментарий: <i><b>{ textAppeal }</b></i></p>
    <br />


    { !rejectAchievement ? (
      <>
        <InputSelectDeprecated
          defaultHint="Направление деятельности"
          items={ table_cat_2_5.vectorActivity }
          defaultValue={ otherVectorActivity ? 'иная' : vectorActivity }
          withResetValue={ true }
          callBack={ (answer: any) => {
            if (answer === 'иная') {
              setVectorActivity('')
              setOtherVectorActivity(true)
            } else {
              setOtherVectorActivity(false)
              setVectorActivity(answer)
            }
          } }
        />

        { otherVectorActivity ? (
          <InputTextDeprecated
            defaultHint="Направление деятельности (свой вариант)"
            value={ vectorActivity }
            onChangeValue={ setVectorActivity }
          />
        ) : false }

        <br />

        <InputSelectDeprecated
          defaultHint="Уровень конкурса"
          items={ table_cat_2_5.level }
          defaultValue={ level }
          withResetValue={ true }
          callBack={ setLevel }
        /><br />

        <InputTextDeprecated
          defaultHint="Название конкурса"
          value={ competitionName }
          onChangeValue={ setCompetitionName }
        /><br />

        <TitleH5>
          Дата проведения
        </TitleH5>

        <CalendarSimple
          selectRange={ false }
          withBorder
          styles={ { height: '4.56rem', } }
          defaultSingleDate={
            data.date
              ? format(new Date(data.date), 'dd.MM.yyyy')
              : date
                ? format(new Date(date), 'dd.MM.yyyy')
                : false }
          returnSingle={ (date: any) => setDate(
            format(new Date(date), 'yyyy-MM-dd')) }
          calendarPositionStatic
          clickableAreaPositioAbsolute
        /><br />

        <InputTextDeprecated
          defaultHint="Учреждение, организатор конкурса"
          value={ organizer }
          onChangeValue={ setOrganizer }
        /><br />

        <InputTextDeprecated
          defaultHint="ФИО студента/ или название команды"
          value={ comandName }
          onChangeValue={ setComandName }
        /><br />

        <SpecsSearch
          defaultHint="Шифр и наименование направления подготовки / специальности"
          value={ vector }
          onChangeValue={ setVector }
          handler={ (answer: any) => {
            setVector(answer)
          } }
          withListPositionAbsolute
          maxHeightList="12rem"
        /><br />

        <br />

        <TitleH5>
          Подтверждающий документ
        </TitleH5>

        { document.length < 1 ? (
          <InputFile
            onChange={ event => {
              // TODO: сделать upload
              // uploadFile(...event.target.files, setDocument)
            } }
          />
        ) : false }


        <AttachedFiles
          isDeleteFilesAllowed={ true }
          fileList={ document }
          getIndexFile={ (index: any) => {
            setDocument(document.filter((file, fIndex) => fIndex !== index))
          } }
        /><br />


        <InputTextDeprecated
          defaultHint="Баллы"
          value={ scores }
          numbersOnly
          disabled
        /><br />
      </>
    ) : false }


    { rejectAchievement ? (
      <>
        <TextArea
          defaultHint='По какой причине вы хотите отклонить заявку?'
          withPlaceholder={ false }
          value={ reason }
          onChangeValue={ setReason }
        />

        <br />

        <div style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }>
          <ButtonSimple
            value="Отменить"
            variant="groupe"
            styles={ {
              marginRight: '1rem',
              backgroundColor: '#F5F6FA',
            } }
            onClickHandler={ () => {
              setRejectAchievement(false)
            } }
          />

          <ButtonSimple
            value={ (
              <IconComponent
                Icon={ IconCircleX }
                iconStyles={ { color: '#FF5E5E', } }
                text="Отклонить"
              />
            ) }
            variant="groupe"
            isDisabled={ reason.split(' ').length < 3 }
            onClickHandler={ () => {
              onUpdateRowHandler(
                currentAppealId,
                {
                  // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                  ...currentAppeal,
                  status: 'appealRejected',
                  appealConclusion: reason,
                },
                ids
              )

              onClosePopUp()
            } }
          />
        </div>
      </>
    ) : false }

    { !rejectAchievement ? (
      <div style={ {
        display: 'flex',
        justifyContent: 'flex-end',
      } }>
        <ButtonSimple
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          isDisabled={ ids.length < 1 }
          value={ (
            <IconComponent
              Icon={ IconCircleX }
              iconStyles={ { color: '#FF5E5E', } }
              text="Отклонить"
            />
          ) }
          variant="groupe"
          styles={ { marginRight: '1rem', } }
          onClickHandler={ () => setRejectAchievement(true) }
        />

        <ButtonSimple
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          isDisabled={ ids.length < 1 }
          value={ (
            <IconComponent
              Icon={ IconCircleCheck }
              text="Подтвердить"
            />
          ) }
          variant="groupe"
          active
          onClickHandler={ () => {
            onUpdateRowHandler(
              currentAchievementId,
              {
                // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                ...currentAchievement,
                vectorActivity: vectorActivity,
                level: level,
                competitionName: competitionName,
                date: date,
                organizer: organizer,
                comandName: comandName,
                vector: vector,
                document: document,
                status: 'approved',
                scores: scores,
              },
              ids
            )

            onUpdateRowHandler(
              currentAppealId,
              {
                // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                ...currentAppeal,
                status: 'appealApproved',
                appealConclusion: 'Ваша апелляция удовлетворена',
              },
              ids
            )

            onClosePopUp()
          } }
        />
      </div>
    ) : false }
  </>
}


export default PopUpAppealContent_2_5

import './AddToFavorites.less'
import { IconStar, } from '@tabler/icons'


const AddToFavorites = () => {
  return <p className="library__add-favorites-wrap">Добавить в избранное
    <IconStar color="#7671DD" /></p>
}

export default AddToFavorites

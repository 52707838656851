import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'


const GET_AVERAGE_SCORE_OF_RECORD_BOOK = gql`
  query AverageGrade ($input: AverageGradeInput!) {
    averageGrade (input: $input)
  }
`

const useAverageScoreRecordBook = ({
  recordBookNumber,
}: any) => {
  const { data, loading, } = useQuery(GET_AVERAGE_SCORE_OF_RECORD_BOOK, {
    variables: {
      input: {
        recordbook_number: recordBookNumber,
      },
    },
  })

  const averageScoreRecordBook = useMemo(() => {
    if (!loading && data) {
      const currentData = data.averageGrade

      return [
        {
          name: 'semester',
          title: 'В прошлом семестре',
          score: currentData?.semester || 0,
        },
        {
          name: 'total',
          title: 'За все время',
          score: currentData?.total || 0,
        },
      ]
    }
  }, [
    data,
    loading,
  ])


  return { averageScoreRecordBook, }
}


export default useAverageScoreRecordBook

import { createSlice, } from '@reduxjs/toolkit'


export const initialState = {
  isDetails: false,
  ratingId: null,
  moderateStudent: false,
  filters: {
    users: {
      full_name: '',
      institute: '',
      education_level: '',
      major: '',
      education_form: '',
      course_number: '',
      dateRange: {
        from: '',
        to: '',
      },
      finalYearStudent: '',
    },
    rating: {
      full_name: '',
      institute: '',
      education_level: '',
      major: '',
      education_form: '',
      course_number: '',
      dateRange: {
        from: '',
        to: '',
      },
      finalYearStudent: '',
    },
    achievements: {
      type: undefined,
      status: undefined,
    },
  },
  tableSort: {
    users: {
      order: 'asc',
      field: 'full_name',
    },
    rating: {
      order: 'asc',
      field: 'fio',
    },
  },
}


export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setIsDetails: (state, action) => {
      state.isDetails = action.payload
    },
    setRatingId: (state, action) => {
      state.ratingId = action.payload
    },
    setModerStudent: (state, action) => {
      state.moderateStudent = action.payload
    },
    setFilterField: (state, action) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      state.filters[action.payload.filter] = action.payload.value
    },
    setSortTable: (state, action) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      state.tableSort[action.payload.tableName] = action.payload.newSort
    },
    clearPortfolio: state => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars, no-param-reassign
      state = initialState
    },
  },
})


export const { setIsDetails, setRatingId, setModerStudent, setFilterField, setSortTable, clearPortfolio, } = portfolioSlice.actions


export default portfolioSlice.reducer

// TODO: Проверить, используется ли где то этот хук, возможно его надо выпилить

import { EC_UPDATE_DATA, } from '@/legacy/gql/hooks/effectiveContractHooks/useUpdateEcData'
import { useMutation, } from '@apollo/client'


const useGetHandlerForUpdateEcDataInTable = () => {
  const [
    updateData,
    { data, },
  ] = useMutation(EC_UPDATE_DATA)

  const onUpdateRowHandler = (allRowData: any, fieldIndex: any, newValue: any) => updateData({
    variables: {
      rec_id: allRowData.rec_id,
      new_data: {
        ...allRowData.old_data,
        [fieldIndex]: newValue,
      },
      user_ids: allRowData.user_ids,
    },
  })


  return {
    onUpdateRowHandler,
    data,
  }
}


export default useGetHandlerForUpdateEcDataInTable

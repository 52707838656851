export const initialSettings = [
  // {
  //   "id": "downgradePayment",
  //   "data": [
  //     {
  //       "x": "downgradePayment",
  //       "y": 0,
  //     },
  //   ]
  // },
  {
    'id': 'bonusPoints',
    'data': [
      {
        'x': 'bonusPoints',
        'y': 0,
      },
    ],
  },
  {
    'id': 'hightPerformance',
    'data': [
      {
        'x': 'hightPerformance',
        'y': 0,
      },
    ],
  },
  {
    'id': 'basicIndicators',
    'data': [
      {
        'x': 'basicIndicators',
        'y': 0,
      },
    ],
  },
]

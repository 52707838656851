import { useMemo, } from 'react'
import './Main.less'
import ShortAds from './MainComponents/Ads/ShortAds'
import Banner from './MainComponents/Banner/Banner'
import Debt from './MainComponents/Debt/Debt'
import Navigation from './MainComponents/Navigation/Navigation'
import MiniNews from './MainComponents/News/News'
import ShortSchedule from './MainComponents/Schedule/ShortSchedule'
import { useSelector, } from 'react-redux'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import WorkspaceIconsList from '@/entities/WorkspaceIconsList/WorkspaceIconsList'
import { JivoWidget } from '@/widgets'

function Main () {
  const role = useGetRole()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const stud = useSelector(state => state.prof.studentInfo)

  const specs = useMemo(() => {
    if (stud) {
      return (role === 'student' || role === 'student-rakus') && <ShortSchedule studInfo={ stud } />
    }
  }, [
    stud,
    role,
  ])


  return (
    <>
      <div>

        {
          !stud
            ? <div className="home d-scrolloff"><WorkspaceIconsList />
            { <JivoWidget role={role}/> }
            </div>
            : <><Banner />
            { <JivoWidget role={role}/> }
            </>
        }
        <div className="home d-scrolloff">
          <Navigation />
          <br />
          <ShortAds />
          <br />
          <br className="br" />
          { specs }
          <br />
          <br className="br" />
          { (role === 'student') && <Debt /> }
          <br />
          <br className="br" />
          <MiniNews />
        </div>
      </div>
    </>
  )
}

export default Main

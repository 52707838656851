import AttachedFiles from '@/legacy/components/common/AttachedFiles/AttachedFiles'
import { FileData, } from '@/legacy/types'


interface TableAttachedFilesProps {
  fileList: File[] | FileList | FileData[]
}


const TableAttachedFiles = ({
  fileList,
}: TableAttachedFilesProps): JSX.Element => {
  return (
    <td>
      <AttachedFiles
        isDeleteFilesAllowed={ false }
        fileList={ fileList }
      />
    </td>
  )
}


export default TableAttachedFiles

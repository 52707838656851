/* eslint-disable no-console */
import { useState, useMemo, } from 'react'
import { NewPageWrapper, } from '../../../common/GeneralElements/GeneralElements'
import './GetApplication.less'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import ConfirmPhoneNumber from '../../ConfirmPhoneNumber'
import ChooseSpec from '../../../common/ChooseSpec/ChooseSpec'
import FilterDropDown from '../../../common/FilterDropDown/FilterDropDown'
import {
  useGetFormTypes,
  useSendApplication,
} from '../../../../gql/hooks/mfc/useApplications'
import { Preloader, } from '@/shared'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import { useSelector, } from 'react-redux'
import { findContact, } from '../../../../Utils/functions'
import format from 'date-fns/format'
import SuccessPopup from '../../../common/SuccessPopup/SuccessPopup'
import FormGenerator from '@/legacy/components/Mfc/FormGenerator/FormGenerator'
import useRedirect from '@/legacy/hooks/useRedirect'
import { MfcStatementType, } from '@/__generated__/graphql'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'

const GetApplication = () => {
  const redirect = useRedirect()

  const [
    newMobile,
    setNewMobile,
  ] = useState<any>()
  const [
    nameSpec,
    setNameSpec,
  ] = useState()
  const [
    applicationType,
    setApplicationType,
  ] = useState<any>()
  const [
    isVarified,
    setIsVarified,
  ] = useState(false)
  const [
    phoneOption,
    setPhoneOption,
  ] = useState('Из моего профиля')
  const [
    payload,
    setPayload,
  ] = useState<any>({})
  const [
    isSubmitDisabled,
    setIsSubmitDisabled,
  ] = useState<any>(false)
  const [
    isSuccess,
    setIsSuccess,
  ] = useState(false)

  const [
    smsCode,
    setSmsCode,
  ] = useState(null)

  const studInfo = useSelector((state: any) => state.prof.studentInfo)
  const personalInfo = useSelector((state: any) => state.auth.me)

  const tel =
    findContact(personalInfo.data.contacts, {
      kind_contact_information: 'ТелефонМобильныйФизическиеЛица',
    }) !== '--'
      ? findContact(personalInfo.data.contacts, {
        kind_contact_information: 'ТелефонМобильныйФизическиеЛица',
      })
      : findContact(personalInfo.data.contacts, {
        kind_contact_information: 'Мобильный телефон',
      })

  const role = useGetRole()

  const handleApply = () => {
    setIsSubmitDisabled(true)
    mfcSendStatement()
      .then(() => {
        setIsSuccess(true)
        setIsSubmitDisabled(false)
      })
      .catch((e: any) => console.log('ochibka', e))
  }

  const inputStudent: any = {
    RecordbookNumber:
      studInfo &&
      studInfo?.find((i: any) => i.namespec === nameSpec)?.recordbook_guid,
    StudentPhone: newMobile ? newMobile : tel,
    StatementsDetails: {
      ...payload,
      DateOfStatements: format(new Date(Date.now()), 'yyyy-MM-dd'),
      TypeOfStatements: applicationType?.id,
      statementName: applicationType?.name,
    },
    meta: {
      smsCode: smsCode,
      phone: tel,
    },
  }

  const employeeInput: any = {
    StatementsDetails: {
      ...payload,
      DateOfStatements: format(new Date(Date.now()), 'yyyy-MM-dd'),
      TypeOfStatements: applicationType?.id,
      statementName: applicationType?.name,
    },
    meta: {
      smsCode: smsCode,
      phone: tel,
    },
  }

  const [
    mfcSendStatement,
  ] = useSendApplication(
    role === 'student' || role === 'student-rakus'
      ? inputStudent
      : employeeInput,
    role === 'student' || role === 'student-rakus'
      ? 'StudentStatements'
      : 'EmployeeStatements'
  )

  // @ts-ignore: Unreachable code error
  const { data, error, loading, } = useGetFormTypes(
    role === 'student' || role === 'student-rakus'
      ? MfcStatementType.StudentStatements
      : MfcStatementType.EmployeeStatements
  )

  const applicationTypes = useMemo(() => {
    if (error) {
      return <div>произошла ошибка, попробуйте повторить позднее</div>
    }
    if (loading) {
      return <Preloader />
    } else if (data) {
      return (
        <FilterDropDown
          items={ data.mfcGetFormTypes }
          field="name"
          onClickHandler={ setApplicationType }
          defaultHint="Выберите тип"
          firstIsSelected
          customWidth="40%"
        />
      )
    }
  }, [
    data,
    error,
    loading,
  ])

  return (
    <NewPageWrapper className="GetMfcApplication">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Конструктор подачи заявления"
      />
      <h1 className="page-main-title">Конструктор подачи заявления</h1>
      { (role === 'student' || role === 'student-rakus') && (
        <>
          <ChooseSpec
            onClickHandler={ setNameSpec }
            firstIsSelected={ true }
            customWidth={ '40%' }
          />
          {/* { (nameSpec) && <><h2 className='section-title'>Укажите номер мобильного телефона</h2>
        <ConfirmPhoneNumber setMobile={ setNewMobile } newMobile={ newMobile }
          setIsVarified={ setIsVarified } isVarified={ isVarified }
          setPhoneOption={ setPhoneOption } phoneOption={ phoneOption } tel={ tel } /></> } */}
        </>
      ) }
      {/* {((newMobile?.length === 12 && isVarified) ||
        (role !== "student" && role !== "student-rakus") ||
        (phoneOption === "Из моего профиля" && nameSpec)) && ( */}
      <>
        <h2 className="section-title">Выберите тип заявления</h2>
        { applicationTypes }
      </>
      {/*  )} */ }
      { applicationType && (
        <>
          <FormGenerator
            setPayload={ setPayload }
            payload={ payload }
            id={ applicationType.id }
          />
          <ConfirmPhoneNumber
            setMobile={ setNewMobile }
            newMobile={ newMobile }
            setIsVarified={ setIsVarified }
            isVarified={ isVarified }
            setPhoneOption={ setPhoneOption }
            setSmsCode={ setSmsCode }
            smsCode={ smsCode }
            phoneOption={ phoneOption }
            tel={ tel }
            isDisabled={
              Object.values(payload).some(i => !i) || isSubmitDisabled
            }
          />
        </>
      ) }
      { applicationType && isVarified && (
        <ButtonSimple
          value="Отправить заявление"
          isDisabled={
            Object.values(payload).some(i => !i) || isSubmitDisabled
          }
          onClickHandler={ handleApply }
          variant="alone"
          styles={ {
            width: 'fit-content',
            alignSelf: 'flex-end',
          } }
        />
      ) }
      <SuccessPopup
        isOpen={ isSuccess }
        handlePopup={ () => redirect('/mfc-applications') }
        text={ `
      Вы успешно  подали заявление "${ applicationType?.name }" Отслеживайте статус согласования в разделе «Мои заявления»».
      `}
      />
    </NewPageWrapper>
  )
}

export default GetApplication

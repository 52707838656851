import { ChangeEvent, } from 'react'
import './TextArea.less'

/** @deprecated используй InputTextArea из shared */
const TextArea = ({
  defaultHint,
  value,
  onChangeValue,
  withPlaceholder = 'false',
  rows,
  isDisabled,
  style,
}: any) => {
  return (
    <label className="input-text" style={ style }>
      <div className="input-text__inner">
        <span className="input-text__label-hint">
          { value || !withPlaceholder ? defaultHint : false }
        </span>

        <textarea
          rows={ rows }
          className={ 'input-text__value' }
          onChange={ (event: ChangeEvent<HTMLTextAreaElement>) => onChangeValue(event.target.value)
          }
          placeholder={ withPlaceholder ? defaultHint : false }
          value={ value }
          disabled={ isDisabled }
        />
      </div>
    </label>
  )
}

export default TextArea

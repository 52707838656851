import { useMemo, } from 'react'
import { useGetProjectApplys, } from '../../../../gql/hooks/useProjects'
import ProjectCard from '../../ProjectCard/ProjectCard'
import NoContracts from '../../../Finances/PaymentPage/NoContracts/NoContracts'
import { useLocation, } from 'react-router-dom'
import { checkParticipateData, } from '@/shared/lib/utils/project-activity'
interface CardsProps {
  params: {
    godMode: boolean;
    status: string[];
    filter: any;
  };
}

function Cards({ params, }: CardsProps): JSX.Element {
  const pagination = {
    offset: 0,
    limit: 1000,
  }

  const location = useLocation()


  console.log('params:', params)
  const { data, error, loading, } = useGetProjectApplys(params, pagination)

  const applications = useMemo(() => {
    if (error) {
      return <div>.....errrr</div>
    } else if (loading && !data) {
      return <div>......loading....</div>
    }

    return <>
      { data.paListProjects.items.map((i: any, index: any) => <ProjectCard key={ index } type="active" i={ i } participateData={ checkParticipateData(data) } place="my" historyRef={ location.state.from } />) }
    </>
  }, [
    data,
    error,
    loading,
    location.state.from,
  ])


  return (data?.paListProjects.items.length > 0 ? applications : <NoContracts title="У вас еще нет активных проектов" />)
}

export default Cards

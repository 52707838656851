import { gql, useQuery } from '@apollo/client';

// Define the GraphQL query
const GET_EC_PERSON_DATA = gql`
  query ecGetEmployeePosition($dtsuId: String!) {
    ecGetEmployeePosition(dtsuId: $dtsuId)
  }
`;

// Hook to fetch real data
export const useGetEcPersonData = (dtsuId: string) => {
  const { data, loading, error } = useQuery(GET_EC_PERSON_DATA, { variables: { dtsuId } });

  return { data, loading, error };
};

// Hook for mock testing
export const useGetEcPersonDataTest = () => {
  const mockData = {
    ecGetEmployeePosition: {
      positionName: 'Преподаватель',
      department: 'Кафедра математики',
      positionType: 'ОсновноеМестоРаботы',
    },
  };

  return { data: mockData, loading: false, error: null };
};

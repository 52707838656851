// TODO: Проверить исполузуется ли где то этот модуль, выпилить за ненадобностью.

import InputFile from '../../common/InputFile/InputFile'
import styles from './UploadFile.module.less'


const UploadFile = ({
  setFileList,
  category,
}: any) => {
  return (
    <>
      <div className={ styles.wrapper }>
        <InputFile
          isMultiple
          onChange={ event => {
            // TODO: сделать uploading файлов
            // uploadFileFunction({
            //   files: event.target.files,
            //   setLocalState: setFileList,
            //   category,
            // })
          } }
          className={ styles.inputFile }
        />
      </div>
    </>
  )
}


export default UploadFile

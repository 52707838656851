/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useEffect, useState, } from 'react'
import './ResponseCard.less'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import ru from 'date-fns/locale/ru'
import Image from '../../common/Image/Image'
import { useCreateProject, } from '../../../gql/hooks/useProjects'
import clsx from 'clsx'
import { useAppContext, } from '@/app/providers/AppProvider'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'

function ResponseCard ({
  response,
  responses,
  req_id,
  project,
  resInt,
}: any) {
  if (response.approved == null) {
    return null
  }
  const { birth, spec_info, created, name, id, } = response
  const [
    time,
    setTime,
  ] = useState(formatDistanceToNowStrict(new Date(created), { locale: ru, }))
  const [
    paControl,
  ] = useCreateProject()

  const { addNotification, } = useAppContext()

  const role = useGetRole()


  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'void' is not assignable to param... Remove this comment to see the full error message
    const timer = setInterval(setTime(formatDistanceToNowStrict(new Date(created), { locale: ru, })), 1000)
    return () => {
      clearInterval(timer)
    }
  }, [
    created,
  ])

  const { openToast, } = useAppContext()


  function handleResponse (action: any) {
    const newResponces: any = []

    // eslint-disable-next-line array-callback-return
    responses.map((i: any) => {
      if (i.id === id && i.approved !== 'rejected') {
        newResponces.push({
          ...i,
          approved: action === 'approve' ? 'approved' : 'rejected',
        })
      } else {
        newResponces.push(i)
      }
    })
    const params = {
      event: 'change',
      payload: {
        req_id: req_id,
        args: {
          status: 'ready',
          data: {
            ...project.data,
            responses: newResponces,
          },
        },
        action: 'update',
      },
    }
    paControl({ variables: { params, }, })
      .then(res => {
        if (action === 'approve') {
          addNotification?.('Проектная деятельность', [
            id,
          ], {
            message: 'Ваш отклик на участие в проекте одобрен',
            reqId: req_id,
          }, {
            path: '/my-projects',
            from: 'Проектная деятельность',
          })
        } else {
          addNotification?.('Проектная деятельность', [
            id,
          ], {
            message: 'Ваш отклик на участие в проекте отклонен',
            reqId: req_id,
          }, {
            path: '/projects-all',
            from: 'Проектная деятельность',
          })
        }
      })
      .then(res => {
        openToast?.({ message: 'Вы отреагировали на отклик', })
      })
      .catch(e => {
        console.log('onCreate error', e)
      })
  }

  return (
    <div className="applyResponse">
      <div className="imageWrap">
        <Image id={ id } />
        <div className="responceInfo">
          <h2>{ name }</h2>
          <p className="age">{ `${ formatDistanceToNowStrict(new Date((birth).replaceAll('.', ',').split(',').reverse().join(',')), { locale: ru, }) } · Гражданство: Россия` }</p>
          <p className="spec">Профиль: <span>{ spec_info[0].nameprof }</span></p>
          <p className="spec">Направление подготовки: <span>{ spec_info[0].namespec }</span></p>
          <p className="spec">Курс: <span>{ spec_info[0].course }</span></p>
        </div>
      </div>
      <div className="panel">
        <p className="age">{ `${ formatDistanceToNowStrict(new Date(created), { locale: ru, }) } назад` }</p>
        { (response.approved === 'send') && <div className="buttonsWrap">
          <button onClick={ () => handleResponse('rej') }>Отказать</button>
          <button onClick={ () => handleResponse('approve') }>Принять</button>
        </div> }
        <div style={ {
          display: 'flex',
          alignItems: 'center',
        } }>
          { response.approved === 'approved' && (role === 'pa_moder') && <div className="buttonsWrap" style={ { margin: '0', } }><button onClick={ () => handleResponse('rej') } style={ {
            padding: '8px 12px',
            margin: '0 10px 0 0',
            borderRadius: '10px',
          } }>Исключить</button></div> }
          { response.approved && <div className={ clsx(response.approved === 'approved' && 'appr', response.approved === 'rejected' && 'rej') }>
            { response.approved === 'approved' && 'Отклик одобрен' }
            { response.approved === 'rejected' && 'Отклик отклонен' }
          </div> }
        </div>
        { (project.data.participants - resInt <= 0) && <p>Максимальное число участников проекта</p> }
      </div>
    </div>
  )
}

export default ResponseCard

/**
 * Обертка-лейбл для текстовых полей ввода, таких как: input | textarea
 *
 * @param title - Является лейблом для поля ввода
 * @param className - Внешний CSS класс для лейбла
 * @returns `JSX element`
 */


import styles from './InputTextLable.module.less'
import clsx from 'clsx'
import { ReactNode, } from 'react'


interface InputTextLableProps {
  title?: string
  className?: string
  children: ReactNode
}


const InputTextLable = ({
  title,
  className,
  children,
}: InputTextLableProps) => (
  <label
    className={ clsx(styles.label, className) }
    style={ styles }
  >
    <div className={ styles.inputWrapper }>
      <span className={ styles.title }>
        { title }
      </span>

      { children }
    </div>
  </label>
)


export default InputTextLable

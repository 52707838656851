import { useState, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import useGetNotificationDrafts from '../../../gql/hooks/moderateNotifications/useGetNotificationDrafts'
import useNotificationDraftChanged from '../../../gql/hooks/moderateNotifications/useNotificationDraftChanged'
import { onChangePaginationValues, } from '../../../redux/moderateNotificationsSlice/moderateNotificationsSlice'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import PaginationWithAmountRecords from '../../common/PaginationWithAmountRecords/PaginationWithAmountRecords'
import RadioButtonFacade from '../../common/RadioButtonFacade/RadioButtonFacade'
import AddNotificationButton from '../AddNotificationButton/AddNotificationButton'
import List from './common/List/List'
import Filters from './Filters/Filters'
import styles from './TabsBox.module.less'


const buttonsContent = [
  {
    text: 'Системное уведомление',
  },
  {
    text: 'Объявление',
  },
]


const TabsBox = () => {
  const dispatch = useDispatch()

  const [
    activeIndex,
    setActiveIndex,
  ] = useState(0)
  const [
    localFilters,
    setLocalFilters,
  ] = useState({
    systemNotification: {
      title: '',
      roles: null,
    },
    announcementNotification: {
      title: '',
      roles: null,
    },
  })


  const systemNotificationFilters = useSelector(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    state => state.moderateNotifications.draftsFilters.systemNotification)
  const announcementNotificationFilters = useSelector(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    state => state.moderateNotifications.draftsFilters.announcementNotification)
  const allFilters = [
    systemNotificationFilters,
    announcementNotificationFilters,
  ]

  const systemNotificationPagination = useSelector(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    state => state.moderateNotifications.draftsPagination.systemNotification)
  const announcementNotificationPagination = useSelector(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    state => state.moderateNotifications.draftsPagination.announcementNotification)


  const { notificationDrafts: systemNotificationDrafts, } = useGetNotificationDrafts({
    type: 'Системное уведомление',
    limit: systemNotificationPagination.limit,
    offset: systemNotificationPagination.offset,
    localFilter: {
      title: localFilters.systemNotification.title,
      roles: localFilters.systemNotification.roles,
      // @ts-expect-error TS(2339): Property 'status' does not exist on type '{ title:... Remove this comment to see the full error message
      is_active: localFilters.systemNotification.status,
      // @ts-expect-error TS(2339): Property 'from' does not exist on type '{ title: s... Remove this comment to see the full error message
      from: localFilters.systemNotification.from,
      // @ts-expect-error TS(2339): Property 'to' does not exist on type '{ title: str... Remove this comment to see the full error message
      to: localFilters.systemNotification.to,
    },
  })

  const { notificationDrafts: announcementNotificationDrafts, } = useGetNotificationDrafts({
    type: 'Объявление',
    limit: announcementNotificationPagination.limit,
    offset: announcementNotificationPagination.offset,
    localFilter: {
      title: localFilters.announcementNotification.title,
      roles: localFilters.announcementNotification.roles,
      // @ts-expect-error TS(2339): Property 'status' does not exist on type '{ title:... Remove this comment to see the full error message
      is_active: localFilters.announcementNotification.status,
      // @ts-expect-error TS(2339): Property 'from' does not exist on type '{ title: s... Remove this comment to see the full error message
      from: localFilters.announcementNotification.from,
      // @ts-expect-error TS(2339): Property 'to' does not exist on type '{ title: str... Remove this comment to see the full error message
      to: localFilters.announcementNotification.to,
    },
  })

  useNotificationDraftChanged({
    refetches: [
      // @ts-expect-error TS(2322): Type '(variables?: Partial<{ input: { filter: { ty... Remove this comment to see the full error message
      systemNotificationDrafts?.refetch,
      // @ts-expect-error TS(2322): Type '(variables?: Partial<{ input: { filter: { ty... Remove this comment to see the full error message
      announcementNotificationDrafts?.refetch,
    ],
  })


  return <>
    <header className={ styles.header }>
      <AddNotificationButton />

      <div className={ styles.tabButtonsWrapper }>
        <RadioButtonFacade
          activeIndex={ activeIndex }
          setActiveIndex={ setActiveIndex }
          BtnComponent={ ButtonSimple }
          content={ buttonsContent }
        />
      </div>
    </header>

    <br />

    <Filters
      filterName={ activeIndex === 0 ? 'systemNotification' : 'announcementNotification' }
      setLocalFilters={ ({
        filterName,
      }: any) => {
        setLocalFilters(prev => ({
          ...prev,
          [filterName]: {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ...prev[filterName],
            title: allFilters[activeIndex].title.value,
            roles: allFilters[activeIndex].role.value ? [
              allFilters[activeIndex].role.value,
            ] : allFilters[activeIndex].role.value,
            status: allFilters[activeIndex].status.value.value,
            from: allFilters[activeIndex].date.value.from,
            to: allFilters[activeIndex].date.value.to,
          },
        }))
      } }
      { ...allFilters[activeIndex] }
    />

    <br />

    { activeIndex === 0 && (
      <List items={ systemNotificationDrafts.items } />
    ) }

    { activeIndex === 1 && (
      <List items={ announcementNotificationDrafts.items } />
    ) }

    <br />

    { activeIndex === 0 && (
      <PaginationWithAmountRecords
        offset={ systemNotificationDrafts.pagination.offset }
        totalRecords={ systemNotificationDrafts.pagination.total }
        setOffset={ (newValue: any) => {
          dispatch(
            onChangePaginationValues({
              paginationName: 'systemNotification',
              paginationKey: 'offset',
              newValue,
            })
          )
        } }
        limit={ systemNotificationPagination.limit }
        setNewLimit={ (newValue: any) => {
          dispatch(
            onChangePaginationValues({
              paginationName: 'systemNotification',
              paginationKey: 'limit',
              newValue,
            })
          )
        } }
      />
    ) }

    { activeIndex === 1 && (
      <PaginationWithAmountRecords
        offset={ systemNotificationDrafts.pagination.offset }
        totalRecords={ announcementNotificationDrafts.pagination.total }
        setOffset={ (newValue: any) => {
          dispatch(
            onChangePaginationValues({
              paginationName: 'announcementNotification',
              paginationKey: 'offset',
              newValue,
            })
          )
        } }
        limit={ announcementNotificationPagination.limit }
        setNewLimit={ (newValue: any) => {
          dispatch(
            onChangePaginationValues({
              paginationName: 'announcementNotification',
              paginationKey: 'limit',
              newValue,
            })
          )
        } }
      />
    ) }
  </>
}


export default TabsBox

import { gql, } from '@/__generated__'
import { useMutation, } from '@apollo/client'


const DELETE_UPLOAD = gql(`
  mutation DeleteUpload($upload_ids: [Int]!) {
    deleteUpload(upload_ids: $upload_ids)
  }
`)


const useDeleteUpload = () => {
  const [
    onDelete,
  ] = useMutation(DELETE_UPLOAD)

  const onDeleteHandler = ({
    uploadId,
  }: { uploadId: number }) => {
    return onDelete({
      variables: {
        upload_ids: [
          uploadId,
        ],
      },
    })
      .then(data => {
        const answer = data.data?.deleteUpload

        if (answer.length === 0) {
          return null
        } return answer[0].upload_id
      })
  }


  return onDeleteHandler
}


export default useDeleteUpload

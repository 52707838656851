import { gql, } from '@/__generated__'
import { FileEntryInput, } from '@/__generated__/graphql'
import { useLazyQuery, } from '@apollo/client'


const FILE_EXISTS_QUERY = gql(`
  query checkFiles($files: [FileEntryInput!]!) {
    checkFiles(files: $files)
  }
`)


const useCheckFilesExists = (files: null | FileEntryInput[]) => {
  const [
    checkFiles,
  ] = useLazyQuery(FILE_EXISTS_QUERY, {
    variables: {
      files: files as FileEntryInput[],
    },
    fetchPolicy: 'no-cache',
  })


  return {
    checkFiles,
  }
}


export default useCheckFilesExists

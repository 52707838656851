import {
  IconBook,
  IconBulb,
  IconTrendingUp,
  IconUser,
} from '@tabler/icons'
import { useContext, useEffect, useState, } from 'react'
import { useGetFirstPointScores, useGetPoint_1_1_Scores, } from '../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useSelector, } from 'react-redux'

import CollapseContainer2, { AccordionInnder, } from '../../../common/CollapseContainer2/CollapseContainer2'
import TableContainer from '../../TableContainer/TableContainer'

import {
  tableSource as headerFields_1_1,
} from '../../constants/tablesFormData/table_1_1'
import {
  tableSource as headerFields_1_11,
} from '../../constants/tablesFormData/table_1_11'
import {
  tableSource as headerFields_1_2,
} from '../../constants/tablesFormData/table_1_2'
import {
  tableSource as headerFields_1_3,
} from '../../constants/tablesFormData/table_1_3'
import {
  tableSource as headerFields_1_41,
} from '../../constants/tablesFormData/table_1_41'
import { defineViceHeadOfDepartments, } from '../../defineViceHeadOfDepartments'
import PaperContainerWrapper from '../../../common/PaperContainer/PaperContainerWrapper'
import { table_cat_1_2, } from '../../popUpInputsCatalogs'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { EcSubscriptionContext, } from '@/features'
import useGetUserInfo from '@/legacy/hooks/useGetUserInfo'
import useGetTableData from '@/legacy/gql/hooks/effectiveContractHooks/useGetTableData'


const BasicIndicators = () => {
  const dataTypeUpdated = useContext(EcSubscriptionContext)

  const { userGuid, } = useGetUserInfo()

  const table_1_1 = useGetTableData({
    dataType: EcTableName.TABLE_1_1,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_1_11 = useGetTableData({
    dataType: EcTableName.TABLE_1_11,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_1_2 = useGetTableData({
    dataType: EcTableName.TABLE_1_2,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_1_3 = useGetTableData({
    dataType: EcTableName.TABLE_1_3,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_1_41 = useGetTableData({
    dataType: EcTableName.TABLE_1_41,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })


  // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
  const getFirstPointScores_1_1 = useGetPoint_1_1_Scores()

  const getFirstPointScores_1_2 = useGetFirstPointScores({
    dataType: 'table_1_2',
  })

  const getFirstPointScores_1_3 = useGetFirstPointScores({
    dataType: 'table_1_3',
  })

  const [
    scores_1_1,
    setScores_1_1,
  ] = useState(null)
  const [
    scores_1_2,
    setScores_1_2,
  ] = useState('0/5')
  const [
    scores_1_3,
    setScores_1_3,
  ] = useState('0/1')

  const setScoresByDefault_1_1 = () => {
    setScores_1_1(null)
  }
  const setScoresByDefault_1_2 = () => {
    setScores_1_2('0/5')
  }
  const setScoresByDefault_1_3 = () => {
    setScores_1_3('0/1')
  }

  useEffect(() => {
    if (getFirstPointScores_1_1.data) {
      const data = getFirstPointScores_1_1.data.ecCheckCompletion

      if (!data) {
        setScoresByDefault_1_1()
      } else {
        if (data.me.proff && data.RPD_1_11.total > 0) {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          setScores_1_1(`${ data.scores }/${ data.RPD_1_11.total + 2 }`)
        } else if (data.me.proff && data.RPD_1_11.total === 0) {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          setScores_1_1(`${ data.scores }/${ 3 }`)
        }


        if (data.me.docent && data.RPD_1_11.total > 0) {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          setScores_1_1(`${ data.scores }/${ data.RPD_1_11.total + 1 }`)
        } else if (data.me.docent && data.RPD_1_11.total === 0) {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          setScores_1_1(`${ data.scores }/${ 2 }`)
        }


        if ((data.me.assist || data.me.teacher) && data.RPD_1_11.total > 0) {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          setScores_1_1(`${ data.scores }/${ data.RPD_1_11.total }`)
        } else if ((data.me.assist || data.me.teacher) && data.RPD_1_11.total === 0) {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          setScores_1_1(`${ data.scores }/${ 1 }`)
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getFirstPointScores_1_1.loading,
    getFirstPointScores_1_1.data,
  ])

  useEffect(() => {
    if (getFirstPointScores_1_2.data) {
      const data = getFirstPointScores_1_2.data.ecGetCompletionStats

      if (data.length === 0) {
        setScoresByDefault_1_2()
      } else {
        setScores_1_2(`${ data[0].isDone }/${ 5 }`)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getFirstPointScores_1_2.loading,
    getFirstPointScores_1_2.data,
  ])

  useEffect(() => {
    if (getFirstPointScores_1_3.data) {
      const data = getFirstPointScores_1_3.data.ecGetCompletionStats

      if (data.length === 0) {
        setScoresByDefault_1_3()
      } else {
        setScores_1_3(`${ data[0]?.isDone }/${ data[0]?.total }`)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getFirstPointScores_1_3.loading,
    getFirstPointScores_1_3.data,
  ])


  const personPositions = useSelector(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    state => state.auth.me.positions.filter((item: any) => item.position_name).map((item: any) => item.position_name.split(', ')))
  const [
    isHeadOfDepartment,
    setIsHeadOfDepartment,
  ] = useState(false)

  useEffect(() => {
    if (personPositions[0]) {
      for (let i = 0; i < personPositions.length; i++) {
        if (
          personPositions[i]?.some((item: any) => item === 'Заведующий кафедрой')
          || defineViceHeadOfDepartments(userGuid)
        ) {
          return setIsHeadOfDepartment(true)
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    personPositions,
  ])


  return (
    <>
      <h3 className="effective-contract-add-chievement__subtitle">
        Базовые показатели
      </h3>

      <CollapseContainer2>
        { isHeadOfDepartment ? (
          <AccordionInnder
            accordionDirection='column'
            Icon={ IconBook }
            headerText="Учебно-методическое обеспечение образовательного процесса (показатель качества)"
            subHeaderText={ scores_1_1 }
          >
            <TableContainer
              dataType={ EcTableName.TABLE_1_1 }
              headerFields={ headerFields_1_1 }
              ecTableData={ table_1_1 }
            />

            <PaperContainerWrapper
              style={ { width: '100%', } }
              variant="sub-paper"
            >
              <AccordionInnder
                headerText="Moodle"
              >
                <TableContainer
                  dataType={ EcTableName.TABLE_1_11 }
                  headerFields={ headerFields_1_11 }
                  ecTableData={ table_1_11 }
                />
              </AccordionInnder>
            </PaperContainerWrapper>
          </AccordionInnder>
        ) : false }


        <AccordionInnder
          Icon={ IconTrendingUp }
          headerText="Плановое повышение квалификации (показатель качества)"
          subHeaderText={ scores_1_2 }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_1_2 }
            headerFields={ headerFields_1_2 }
            ecTableData={ table_1_2.filter(
              item => table_cat_1_2.course_of_category.some(
                someItem => someItem === item.course_of_category)) }
          />
        </AccordionInnder>


        <AccordionInnder
          Icon={ IconUser }
          headerText="Участие в профориентационных мероприятиях, организованных кафедрой, институтом/факультетом, Университетом (показатель качества)"
          subHeaderText={ scores_1_3 }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_1_3 }
            headerFields={ headerFields_1_3 }
            ecTableData={ table_1_3 }
          />
        </AccordionInnder>

        { table_1_41?.length > 0 && (
          <AccordionInnder
            Icon={ IconBulb }
            headerText="Участие в научно-исследовательской работе по профилю кафедры, института (факультета) и(или) преподаваемого курса, дисциплины (модуля) (показатель интенсивности)"
          >
            <TableContainer
              dataType={ EcTableName.TABLE_1_41 }
              headerFields={ headerFields_1_41 }
              ecTableData={ table_1_41 }
            />
          </AccordionInnder>
        ) }

      </CollapseContainer2>
    </>
  )
}


export default BasicIndicators

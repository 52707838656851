import { gql, useMutation, } from '@apollo/client'


export const UPDATE_CONFIG = gql`
  mutation UtilUpdateConfig($input: UpdateConfigInput!) {
    utilUpdateConfig(input: $input)
  }
`


const useUpdateEcDocuments = () => {
  const [
    onUpdate,
  ] = useMutation(UPDATE_CONFIG)

  const onUpdateEcDocuments = (items: any) => onUpdate({
    variables: {
      input: {
        domain: 'effective-contract',
        key: 'documents',
        mode: 'replace',
        config: {
          items,
        },
      },
    },
  })

  return { onUpdateEcDocuments, }
}


export default useUpdateEcDocuments

import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, } from '@mui/material'
import { IconArrowDownCircle, IconArrowUpCircle, IconCircleMinus, IconCirclePlus, IconDotsVertical, IconEdit, IconTrash, } from '@tabler/icons'
import clsx from 'clsx'
import { memo, } from 'react'
import { useCallback, useMemo, useState, } from 'react'
import styles from './OptionsButton.module.less'


const OptionsButton = ({
  className,
  isEditMode,
  onDeleteItem,
  onChangeStatus,
  onEditHandle,
  onChangeOrder,
  order,
}: any): JSX.Element => {
  const [
    anchorEl,
    setAnchorEl,
  ] = useState(null)
  const open = Boolean(anchorEl)
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])


  const iconButton = useMemo(() => (
    <IconButton
      aria-label="more"
      id="long-button"
      aria-controls={ open ? 'long-menu' : undefined }
      aria-expanded={ open ? 'true' : undefined }
      aria-haspopup="true"
      onClick={ event => {
        // @ts-expect-error TS(2345): Argument of type 'EventTarget & HTMLButtonElement'... Remove this comment to see the full error message
        setAnchorEl(event.currentTarget)
      } }
      className={ clsx(className, styles.menuButton) }
      disabled={ isEditMode }
    >
      <IconDotsVertical />
    </IconButton>
  ), [
    className,
    isEditMode,
    open,
  ])


  return (
    <>
      { iconButton }

      <Menu
        id="basic-menu"
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
      >
        { order && (
          <MenuItem
            onClick={ () => {
              onChangeOrder('toUp')
              handleClose()
            } }
            disabled={ order?.current === null || order?.current < 2 }
          >
            <ListItemIcon>
              <IconArrowUpCircle className={ styles.icon } />
            </ListItemIcon>
            <ListItemText>Выше</ListItemText>
          </MenuItem>
        ) }

        { order && (
          <MenuItem
            onClick={ () => {
              onChangeOrder('toDown')
              handleClose()
            } }
            disabled={ order?.current === order?.max }
          >
            <ListItemIcon>
              <IconArrowDownCircle className={ styles.icon } />
            </ListItemIcon>
            <ListItemText>Ниже</ListItemText>
          </MenuItem>
        ) }

        <MenuItem
          onClick={ () => {
            onChangeStatus(true)
            handleClose()
          } }
        >
          <ListItemIcon>
            <IconCirclePlus className={ styles.icon } />
          </ListItemIcon>
          <ListItemText>Активировать</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={ () => {
            onChangeStatus(false)
            handleClose()
          } }
        >
          <ListItemIcon>
            <IconCircleMinus className={ styles.icon } />
          </ListItemIcon>
          <ListItemText>Деактивировать</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={ () => {
            onEditHandle()
            handleClose()
          } }
        >
          <ListItemIcon>
            <IconEdit className={ styles.icon } />
          </ListItemIcon>
          <ListItemText>Редактировать</ListItemText>
        </MenuItem>
        <MenuItem onClick={ onDeleteItem }>
          <ListItemIcon>
            <IconTrash className={ styles.icon_dangerColor } />
          </ListItemIcon>
          <ListItemText>Удалить</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}


export default memo(OptionsButton)


export const months = [
  {
    check: 'январь',
    ru: 'Январь',
    en: 'January',
  },
  {
    check: 'февраль',
    ru: 'Февраль',
    en: 'February',
  },
  {
    check: 'март',
    ru: 'Март',
    en: 'March',
  },
  {
    check: 'апрель',
    ru: 'Апрель',
    en: 'April',
  },
  {
    check: 'май',
    ru: 'Май',
    en: 'May',
  },
  {
    check: 'июнь',
    ru: 'Июнь',
    en: 'June',
  },
  {
    check: 'июль',
    ru: 'Июль',
    en: 'July',
  },
  {
    check: 'август',
    ru: 'Август',
    en: 'August',
  },
  {
    check: 'сентябрь',
    ru: 'Сентябрь',
    en: 'September',
  },
  {
    check: 'октябрь',
    ru: 'Октябрь',
    en: 'October',
  },
  {
    check: 'ноябрь',
    ru: 'Ноябрь',
    en: 'November',
  },
  {
    check: 'декабрь',
    ru: 'Декабрь',
    en: 'December',
  },
]

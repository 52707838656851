import { useEffect, } from 'react'
import { useDispatch, } from 'react-redux'
import useGetNotificationfilters from '../../../../gql/hooks/moderateNotifications/useGetNotificationfilters'
import { setDefaultFiltersValues, } from '../../../../redux/moderateNotificationsSlice/moderateNotificationsSlice'

const useSetDefaultFilterValues = () => {
  const dispatch = useDispatch()
  const filtersValues = useGetNotificationfilters()

  useEffect(() => {
    dispatch(
      setDefaultFiltersValues({
        ...filtersValues,
      })
    )
  }, [
    dispatch,
    filtersValues,
  ])
}


export default useSetDefaultFilterValues

import { gql, } from '@/__generated__/gql'
import { MfcStatementType, } from '@/__generated__/graphql'
import { useQuery, useMutation, useLazyQuery, } from '@apollo/client'

const GET_FORM_DATA = gql(`
query GetFormData($input: ReferenceFormFieldDataInput!) {
  referenceGetFormFieldData(input: $input)
}
`)

const GET_APPLICATIONS = gql(`
query GetApplications($input: MFCStatementsInput!){
  mfcStatements(input: $input)
}
`)

const GET_FORM_TYPES = gql(`
  query GetFormTypes($statementType: MFCStatementType){
    mfcGetFormTypes(statementType: $statementType)
  }
`)

const GET_FORM = gql(`
query GetForm($id:UUID!){
  mfcGetForm(id: $id)
}
`)

const SEND_APPLICATION = gql(`
mutation SendStatement($payload: JSON!, $type: MFCStatementType!) {
  mfcSendStatement(payload:$payload, type: $type)
}
`)

export const useGetFormData = (input: any) => useQuery(GET_FORM_DATA, { variables: { input, }, })
export const useGetFormTypes = (statementType: MfcStatementType) => useQuery(GET_FORM_TYPES, { variables: { statementType, }, })
export const useGetForm = (id: any) => useQuery(GET_FORM, { variables: { id, }, })
export const useLazyGetForm = (id: any) => useLazyQuery(GET_FORM, { variables: { id, }, })
export const useGetApplicationsMfc = (input: any) => useQuery(GET_APPLICATIONS, { variables: { input, }, })

export const useSendApplication = (payload: any, type: any) => useMutation(SEND_APPLICATION, {
  variables: {
    payload,
    type,
  },
})

/* eslint-disable no-console */
import { useState, } from 'react'
import './DetailsCard.less'
import { IconArchive, IconArchiveOff, IconPencil, IconTrash, } from '@tabler/icons'
import { IconCircleCheck, IconCircleX, IconPaperclip, } from '@tabler/icons'
import { downloadFile, } from '../../../hooks/useDownloadFile'
import { format, } from 'date-fns'
import ru from 'date-fns/locale/ru'
import EditPopup from '../EditPopup/EditPopup'
import Popup from '../../common/Popup/Popup'
import PortfolioAchievementCommentPopup from '../PortfolioAchievementCommentPopup/PortfolioAchievementCommentPopup'
import PortfolioAchievementChangeStatusPopup from '../PortfolioAchievementChangeStatusPopup/PortfolioAchievementChangeStatusPopup'
import clsx from 'clsx'
import useUpdateAchievement from '../../../gql/hooks/portfolio/useUpdateAchievement'
import styles from './DetailsCard.module.less'
import useDeleteAchievement from '../../../gql/hooks/portfolio/useDeleteAchievement'
import { URL, } from '@/shared/constants/constants'
import { useSelector, } from 'react-redux'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'


const getScoresTextVariant = (inputNumber: any) => {
  const number = Number(inputNumber)

  if (number === 1) {
    return 'балл'
  } else if (number > 1 && number < 5) {
    return 'балла'
  } return 'баллов'
}


export const defineDateFormat = (date: any) => {
  if (date?.isWithoutDay || !date || date?.isWithoutDay === false) {
    return 'Дата не указана'
  }
  return format(new Date(date * 1000), 'dd MMMM yyyy', { locale: ru, })
}


function DetailsCard ({
  item,
  index,
}: any) {
  const userRole = useGetRole()
  const [
    isEdit,
    setIsEdit,
  ] = useState(false)
  const [
    isAddComment,
    setIsAddComment,
  ] = useState(false)
  const [
    isChangeStatus,
    setIsChangeStatus,
  ] = useState(false)
  const update = useUpdateAchievement()
  const onDeleteHandler = useDeleteAchievement()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const userGuid = useSelector(state => state?.auth?.me?.data?.guid)

  return (
    <div
      key={ index }
      className={ clsx('detailsCard', {
        [styles.detailsCard_highlighted]: (/^student/.test(userRole) && item.status === 'rejected' && (item.comment?.length > 0 || Object.keys(item?.comment)?.length)),
      }) }
      onClick={ () => console.log('ACHIEVEMENT', item) }
    >
      <div className="statusTilte">
        { (item.status === 'available' || item.status === 'notavailable') ? (
          <IconCircleCheck color={ item.status === 'available' ? '#7671DD' : '#F9B754' } />
        ) : (
          <IconCircleX color={ item.status === 'archived' ? '#C5C5C5' : '#FF5E5E' } />
        ) }

        { /moder$/.test(userRole)
          ? (
            <button
              className="statusTilte_button"
              onClick={ () => setIsChangeStatus(true) }
            >
              { item.statusName }
            </button>
          )
          : item.statusName
        }

        { item?.comment && Object.values(item?.comment).length > 0 && Object.values(item?.comment)[0] !== '' && (
          <button
            className="comment"
            onClick={ () => setIsAddComment(true) }
          >
            Комментарий
          </button>
        ) }
      </div>

      { (item.direction !== 'Работы обучающегося') && (
        <p className="points">
          { `${ /^available$|^rejected$|^archived$/.test(item.status)
            ? 'Начислено'
            : 'Возможно получить' }: ${ item.status !== 'rejected' ? item.userpoints : 0 } ${ getScoresTextVariant(item.status !== 'rejected' ? item.userpoints : 0) }` }
        </p>
      ) }

      <p className="achivmentType">
        Тип достижения:  <span>{ item.type }</span>
      </p>

      { item.fields.map((field: any, index: any) => {
        return (
          <div key={ index } className='fieldWrap'>
            <p className="achivmentType">{ field.name }</p>


            { (field.type === 'text') && (
              <p
                className="points"
                // style={ { margin: '5px 0 0 0', } }
              >
                { field.value }
              </p>
            ) }


            { field.type === 'file'
              && field.value
              && Array.isArray(field.value)
              && field?.value?.[0]
              && 'upload_id' in field?.value?.[0]
              && (
                field.value.map((attachment: any) => <p
                  key={ attachment?.upload_id }
                  className="points"
                  onClick={ () => downloadFile({
                    upload_id: attachment?.upload_id,
                    name: attachment?.name,
                  }) }
                  style={ {
                    margin: '5px 0 0 0',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  } }
                >
                  Скачать  <IconPaperclip color={ '#7671DD' } />
                </p>)
              ) }


            { field.type === 'file'
              && field.value
              && 'upload_id' in field?.value
              && (
                <p
                  className="points"
                  onClick={ () => downloadFile({
                    upload_id: field?.value?.upload_id,
                    name: field?.value?.name,
                  }) }
                  style={ {
                    margin: '5px 0 0 0',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  } }
                >
                  Скачать  <IconPaperclip color={ '#7671DD' } />
                </p>
              ) }


            { (field.type === 'file' && field?.value && ('file_id' in field.value)) && (
              <p
                className="points"
                onClick={ () => window.open(`https://${ URL }/api/files/sharedAchievement?file_id=${ field.value.file_id }`) }
                style={ {
                  margin: '5px 0 0 0',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                } }
              >
                Скачать  <IconPaperclip color={ '#7671DD' } />
              </p>
            ) }


            { (field.type === 'file' && field.value && Array.isArray(field.value) && field?.value?.[0] && ('file_id' in field?.value?.[0])) && (
              <p
                className="points"
                onClick={ () => window.open(`https://${ URL }/api/files/sharedAchievement?file_id=${ field?.value?.[0]?.file_id }`) }
                style={ {
                  margin: '5px 0 0 0',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                } }
              >
                Скачать  <IconPaperclip color={ '#7671DD' } />
              </p>
            ) }


            { (field.type === 'data') && (
              <p
                className="points"
                // style={ { margin: '5px 0 0 0', } }
              >
                { defineDateFormat(field.value) }
              </p>
            ) }


            { (field.type === 'select') && (
              <p
                className="points"
                // style={ { margin: '5px 0 0 0', } }
              >
                { field?.options[field?.value]?.name }
              </p>
            ) }


            { (field?.type === 'two-level-select') && (
              <>
                <p
                  className="points"
                  // style={ { margin: '5px 0 0 0', } }
                >
                  { field?.levels[field?.value[0]]?.name }
                </p>

                <p
                  className="points"
                  // style={ { margin: '5px 0 0 0', } }
                >
                  { field?.levels[field?.value[0]]?.options[field?.value[1]]?.name }
                </p>
              </>
            ) }
          </div>
        )
      }) }

      <div className="dateCreate">
        <span>
          { format(new Date(item.time_created), 'dd MMMM yyyy, k:mm', { locale: ru, }) }
        </span>

        <span className={ styles.buttonsWrapper }>
          { /^available$|^archived$/.test(item.status) && (
            <button
              className={ clsx({
                'archiev-button': true,
                'archiev-button_archieved': item.status === 'archived',
              }) }
              onClick={ () => update({
                achievement_id: item.achievement_id,
                status: item.status === 'available' ? 'archived' : 'available',
              }) }
            >
              { (item.status === 'available' && (/moder$/.test(userRole))) && (
                <>
                  Отправить в архив <IconArchive color={ '#7671DD' } />
                </>
              ) }
              { item.status === 'archived' && (/urtos-moder/.test(userRole)) && (
                <>
                  Вернуть из архива <IconArchiveOff />
                </>
              ) }
            </button>
          ) }

          { ((/^notavailable$|^rejected$/.test(item.status) && (item.id === userGuid)) || (/urtos-moder/.test(userRole))) && (
            <IconPencil color={ '#C5C5C5' } onClick={ () => setIsEdit(!isEdit) } />
          ) }

          { /urtos-moder/.test(userRole) && (
            <IconTrash color="#FF5E5E" onClick={ () => onDeleteHandler({ achievement_id: item.achievement_id, }) } />
          ) }
        </span>
      </div>

      { isEdit && (
        <EditPopup
          setIsOpen={ setIsEdit }
          isOpen={ isEdit }
          old={ item }
        />
      ) }

      { isAddComment && (
        <Popup
          isOpen={ isAddComment }
          handlePopup={ () => setIsAddComment(false) }
        >
          <PortfolioAchievementCommentPopup
            achievement={ item }
            popUpCloseHandler={ setIsAddComment }
          />
        </Popup>
      ) }

      { isChangeStatus && (
        <Popup
          withScroll
          isOpen={ isChangeStatus }
          handlePopup={ () => setIsChangeStatus(false) }
        >
          <PortfolioAchievementChangeStatusPopup
            achievement={ item }
            popUpCloseHandler={ setIsChangeStatus }
          />
        </Popup>
      ) }
    </div>
  )
}


export default DetailsCard

/**
 * Обработчик добавления нового участника (не являющегося сотрудником университета) достижения в список.
 *
 * @remarks Обработчик используется только для добаление участников не имеющих GUID.
 *
 * @param otherParticipants - Участник который будет добавлен в список (объект, должен иметь строковое представление ФИО, guid будет присвоен автоматически, как null)
 * @param currentValueProps - Набор параметров относящихся к списку участников. value - список участников; valueIndex - строковый индекс по которому будет найдена ячейка в таблице, в которую запишется новое значение; onChangeRowValue - функция обновляющая значение в ячейке таблицы.
 */


import { CurrentValueProps, } from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/components/ParticipantsInput/types/currentValueProps'
import { AchievementMixedParticipant, AchievementOtherParticipant, } from '@/legacy/components/EffectiveContract/types/achievementParticipant'


const addOtherParticipantsHandler = (otherParticipants: string, currentValueProps: CurrentValueProps) => {
  const { value, valueIndex, onChangeRowValue, } = currentValueProps

  const newParticipants: AchievementOtherParticipant[] = otherParticipants.split(', ').map(authorName => {
    return {
      fio: authorName.trim(),
      guid: null,
    }
  }).filter(author => author.fio.length > 0)


  const newValue: AchievementMixedParticipant[] = [
    ...value,
    ...newParticipants,
  ]


  onChangeRowValue(valueIndex, newValue)
}


export default addOtherParticipantsHandler

import { TitleH3, } from '../../../common/GeneralElements/GeneralElements'
import PaperContainerWrapper from '../../../common/PaperContainer/PaperContainerWrapper'
import DocumentsBlock from '../../DocumentsBlock/DocumentsBlock'
import AddDocument from './AddDocument/AddDocument'

const Documents = ({
  documents,
}: any) => {
  return (
    <PaperContainerWrapper>
      <TitleH3>
        Документы
      </TitleH3>

      <DocumentsBlock
        isEditMode
        items={ documents }
        isWithTitle={ false }
        backgroundVariant='invisible'
      />

      <AddDocument currentDocuments={ documents } />
    </PaperContainerWrapper>
  )
}

export default Documents

import styles from './AddNewAchievementPopup.module.less'
import Popup from '@/legacy/components/common/Popup/Popup'
import { TableCellTypeWithValue, UpdateControlledField, } from '@/legacy/components/EffectiveContract/TableContainer/AddNewAchievement/AddNewAchievement'
import ButtonSimple from '@/legacy/components/common/ButtonSimple/ButtonSimple'
import { EcAchivementStatus, EcTableName, EC_MODER_GROUPE, } from '@/legacy/components/EffectiveContract/constants'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import effectiveContractUploadCategoryCreator from '@/legacy/components/EffectiveContract/lib/effectiveContractUploadCategoryCreator'
import useCreateEcData from '@/legacy/gql/hooks/effectiveContractHooks/useCreateEcData'
import useUrlSearch from '@/legacy/hooks/useUrlSearch'
import useUpload from '@/legacy/hooks/useUpload/useUpload'
import useUpdateEcData, { RequiredAchievementDataFields, } from '@/legacy/gql/hooks/effectiveContractHooks/useUpdateEcData'
import useGetUserInfo from '@/legacy/hooks/useGetUserInfo'
import findParticipants from '@/legacy/components/EffectiveContract/lib/findParticipants'
import useGetEcDataTypeFormInputs from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/hooks/useGetEcDataTypeFormInputs'
import { FileData, } from '@/legacy/types/common-types'

interface AddNewAchievementPopupProps {
  dataType: EcTableName
  controlledFields: TableCellTypeWithValue[]
  isPopupOpen: boolean
  handlePopup: Function
  onChange: UpdateControlledField
}

interface RequiredAchievementDataFieldsWithLoadingDocuments extends RequiredAchievementDataFields {
  [key: string]: 'loading' | unknown
}

const AddNewAchievementPopup = ({
  dataType,
  controlledFields,
  isPopupOpen,
  handlePopup,
  onChange,
}: AddNewAchievementPopupProps): JSX.Element => {
  const inputs = useGetEcDataTypeFormInputs({
    controlledFields,
    onChange,
  })

  const userRole = useGetRole()
  const moderateId = useUrlSearch('id')
  const { userGuid, } = useGetUserInfo()
  const {
    onUploadFileHandler,
  } = useUpload()

  const { onCreateEcDataHandler, } = useCreateEcData()
  const { onUpdateEcDataHandler, } = useUpdateEcData()

  const handleFormSubmit = () => {
    const restControlledFieldsData: {
      [key: string]: string
    } = {}

    controlledFields.forEach(field => {
      if (field.editType !== 'upload') {
        restControlledFieldsData[field.index as string] = field.value as string
      }
    })

    const requiredFields: RequiredAchievementDataFields = {
      status: EcAchivementStatus.PENDING,
      reason: '',
      scores: Number(restControlledFieldsData.scores) || null,
      isAddedByModer: Object.keys(EC_MODER_GROUPE).some(key => key === userRole),
      userId: moderateId,
    }

    const documentsData = controlledFields.filter(field => field.editType === 'upload')

    onAddNewEcDataHandler(dataType, requiredFields, documentsData, restControlledFieldsData)
      .then(() => handlePopup(false))
      .catch(error => {
        console.error('An error occurred while submitting the form:', error)
      })
  }

  const onAddNewEcDataHandler = (
    dataType: EcTableName,
    requiredData: RequiredAchievementDataFields,
    documentsData: TableCellTypeWithValue[],
    restAchievementData: { [key: string]: string }
  ) => {
    const requiredDataWithLoadingDocuments: RequiredAchievementDataFieldsWithLoadingDocuments = {
      ...requiredData,
    }

    if (documentsData.length > 0) {
      documentsData.forEach(document => requiredDataWithLoadingDocuments[document.index as string] = 'loading')
    }

    return onCreateEcDataHandler({
      dataType,
      user_ids: [
        moderateId || userGuid,
      ],
      newData: requiredDataWithLoadingDocuments,
    })
      .then(newRecordData => {
        if (newRecordData.data) {
          const recordId = Object.values(newRecordData.data)?.[0].rec_id

          const category = effectiveContractUploadCategoryCreator({
            tableName: dataType,
            userId: moderateId,
            recordId: recordId,
          })

          return Promise.all(documentsData.map(document => onUploadFileHandler(document.value as File[], category)))
            .then(answer => {
              const uploadedFilesData: { [key: string]: FileData[] } = {}

              documentsData.forEach((document, index) => uploadedFilesData[document.index as string] = answer[index])

              const allNewAchievementData = {
                ...restAchievementData,
                ...uploadedFilesData,
                ...requiredData,
                dataType,
              }

              return allNewAchievementData
            })
            .then(allNewAchievementData => {
              let userIds = [
                moderateId || userGuid,
              ]

              const participantsList = findParticipants(allNewAchievementData)

              if (participantsList.length > 0) {
                userIds = participantsList
              }

              return onUpdateEcDataHandler({
                recordId,
                userIds,
                newData: allNewAchievementData,
              })
            })
        }
      })
  }

  return (
    <Popup
      isOpen={ isPopupOpen }
      handlePopup={ handlePopup }
      withScroll
    >
      <>
        { isPopupOpen && (
          <div className={ styles.fieldsWrapper }>
            { inputs }

            <ButtonSimple
              value={ 'Добавить достижение' }
              variant={ 'full-filled' }
              isDisabled={ controlledFields.some(field => field.value.length < 1) }
              onClickHandler={ handleFormSubmit }
            />
          </div>
        ) }
      </>
    </Popup>
  )
}

export default AddNewAchievementPopup

/* eslint-disable indent */
/* eslint-disable no-console */
import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'

export const LIST_DIRECTIONS = gql`
  query Directions($is_admin: Boolean) {
    achievementDirections(is_admin: $is_admin)
  }
`


/**
 * @param {toReturn} toReturn 'nameList' | 'keyByName'
 * @param {name} name 'имя направления для которого будет найден ключ'
 */
const useAchievementDirections = ({
  toReturn,
  name,
  is_admin,
}: any = {
    toReturn: null,
    name: null,
  }) => {
  const { data, loading, refetch, } = useQuery(LIST_DIRECTIONS, {
    variables: { is_admin, },
    fetchPolicy: 'no-cache',
  })

  const directions = useMemo(() => {
    if (!loading && data) {
      const currentData = data.achievementDirections?.sort(
        (current: any, next: any) => {
          if (Number(current.order) > Number(next.order)) {
            return 1
          }
          if (Number(current.order) < Number(next.order)) {
            return -1
          }
          return 0
        }
      )

      try {
        if (!!toReturn && toReturn === 'nameList') {
          return currentData.map((direction: any) => direction.name)
        }

        if (!!toReturn && toReturn === 'keyByName') {
          if (!name) {
            throw new Error('Передай свойство name!')
          }

          return currentData.find((direction: any) => direction.name === name).key
        }

        return currentData
      } catch (error) {
        console.group('Произошла ошибка!')
        console.error(error)
        console.error('Возвращаю следующий ответ:', currentData)
        console.groupEnd()

        return currentData
      }
    } return []
  }, [
    data,
    loading,
    toReturn,
    name,
  ])

  return {
    directions,
    refetch,
  }
}


export default useAchievementDirections

import { IconTrash, } from '@tabler/icons'
import { useContext, } from 'react'
import ButtonSimple, { IconComponent, } from '../../../../../common/ButtonSimple/ButtonSimple'
import { TitleH3, } from '../../../../../common/GeneralElements/GeneralElements'
import { CreateJsonContext, } from '../../../PortfolioEditIndicator'
import styles from './CriteriaHeader.module.less'


const CriteriaHeader = ({
  type,
  field,
}: any) => {
  const {
    // @ts-expect-error TS(2339): Property 'removeCriteria' does not exist on type '... Remove this comment to see the full error message
    removeCriteria,
  } = useContext(CreateJsonContext)


  return (
    <>
      <div className={ styles.titleWrapper }>
        <TitleH3 className={ styles.title }>
          { type === 'text' && 'Текст' }
          { type === 'data' && 'Дата' }
          { type === 'file' && 'Файл' }
          { type === 'select' && 'Список' }
          { type === 'two-level-select' && '2-х уровневый список' }
        </TitleH3>

        <ButtonSimple
          variant={ 'alone' }
          value={
            <IconComponent text="Удалить" Icon={ IconTrash } iconStyles={ { color: '#FF5E5E', } } />
          }
          onClickHandler={ () => removeCriteria(field) }
        />
      </div>
      <br />
    </>
  )
}


export default CriteriaHeader

/* eslint-disable no-console */
import { ChangeEvent, useState, } from 'react'
import './ResultsTask.less'
import { IconFile, IconPaperclip, IconX, } from '@tabler/icons'
import { useChangeTask, } from '../../../../gql/hooks/useProjects'
import clsx from 'clsx'
import { downloadFile, } from '../../../../hooks/useDownloadFile'
import { useDeleteUpload, } from '../../../../gql/hooks/useAvatars'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import useUpload from '@/legacy/hooks/useUpload/useUpload'
import { Alert, Snackbar, } from '@mui/material'
import { useAppContext, } from '@/app/providers/AppProvider'


function ResultsTask ({
  task,
}: any) {
  const [
    files,
    setFiles,
  ] = useState<File[] | null>(null)
  const [
    result,
    setResult,
  ] = useState(false)

  const role = useGetRole()

  const { openToast, } = useAppContext()

  const category = `project/${ task.req_id }/${ task.stage_id }/${ task.task_id }/comments`
  const {
    InputFile,
    isFilesExists,
    setIsFilesExists,
    onCheckFilesHandler,
  } = useUpload()

  function onPreUploadFiles (event: ChangeEvent<HTMLInputElement>) {
    const fileList = event.target.files

    if (fileList) {
      onCheckFilesHandler(fileList, category)
        .then(isUploadFilesAllowed => {
          if (isUploadFilesAllowed) {
            setFiles(Array.from(fileList))
          }
        })
    }
  }

  const [
    paChangeTask,
  ] = useChangeTask()


  function handleFinishTask () {
    const params = {
      task_id: task.task_id,
      event: 'taskChanged',
      status: 'finished',
      data: {
        ...task.data,
        resultFiles: files,
        result: result,
      },
    }

    paChangeTask({ variables: { params, }, })
      .then(res => {
        openToast?.({ message: 'Задача отправлена на проверку', })
        setFiles(null)
        setResult(false)
      })
      .catch(e => console.log('err', e))
  }


  const [
    deleteUpload,
  ] = useDeleteUpload()

  function handleAcceptTask (accept: any) {
    const params = {
      task_id: task.task_id,
      event: 'taskChanged',
      status: accept ? 'completed' : 'new',
      data: accept ? task.data : {
        ...task.data,
        resultFiles: [],
        result: [],
      },
    }


    paChangeTask({ variables: { params, }, })
      .then(res => {
        openToast?.({ message: 'Задача одобрена', })
        setFiles(null)
        setResult(false)
        if (!accept && task.data.resultFiles.length > 0) {
          deleteUpload({
            variables: {
              upload_ids: task.data.resultFiles.map((i: any) => i.upload_id),
            },
          })
        }
        console.log('задача на проверке', res)
      })
      .catch(e => console.log('err', e))
  }


  return (
    <>
      <div className="taskResult">
        <h2>{ ((role === 'student' || role === 'student-rakus') && task.status !== 'finished' && task.status !== 'completed') ? 'Завершить задачу' : 'Результаты задачи' }</h2>
        { ((role === 'student' || role === 'student-rakus') && task.status !== 'finished' && task.status !== 'completed') ? <>
          <div className="mainComment">
            <textarea placeholder='Добавьте комментарий для задачи' onChange={ e => setResult(Boolean(e?.target?.value)) } />


            <div className=" endTask">
              <div style={ { display: 'flex', } }>
                { files && files.map((file: any, index: number) => (
                  <div key={ index } className="addedFile">
                    <IconFile color="#7671DD" />

                    { file.name }

                    <IconX
                      color="#C5C5C5"
                      onClick={ () => setFiles(files.filter((item: File) => file.name !== item.name)) }
                    />
                  </div>
                )) }

                <p style={ { background: 'red', } }>lol</p>

                <label className="attacheBut" style={ { background: 'white', } }>
                  <InputFile
                    style={ { display: 'none', } }
                    onChange={ onPreUploadFiles }
                  />

                  прикрепить
                  <IconPaperclip color="#7671DD" />
                </label>
              </div>


              <button className={ clsx(!result && 'disabled') } onClick={ handleFinishTask }>Завершить задачу</button>
            </div>
          </div>
        </>
          : <div className="workerResult">
            <p>{ task.data.result }</p>
            <ul>
              { task.data.resultFiles.map((i: any, index: any) => <li key={ index } onClick={ () => downloadFile(i) }><div className="addedFile"><IconFile color="#7671DD" />{ i.name }</div></li>) }
            </ul>
            <ul style={ {
              display: 'flex',
              alignSelf: 'self-end',
            } }>
              { (role !== 'student' && task.status !== 'completed' && task.status === 'finished') && <><button onClick={ () => handleAcceptTask(false) }>Отправить на доработку</button>
                <button onClick={ () => handleAcceptTask(true) }>Принять</button></> }
              { ((role === 'student' || role === 'student-rakus') && task.status === 'finished') && <div className="tag">Задача отправлена на проверку</div> }
              { task.status === 'completed' && <div className="tag">Задача завершена</div> }
              { (task.status !== 'completed' && task.status !== 'finished') && <div className="tag">Задача в процессе выполнения</div> }
            </ul>
          </div> }
      </div>


      <Snackbar open={ isFilesExists }>
        <Alert onClose={ () => setIsFilesExists(undefined) } severity="error">
          Файл с таким именем уже существует!
        </Alert>
      </Snackbar>
    </>
  )
}

export default ResultsTask

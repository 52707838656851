import React from 'react'
import { IconChevronDown, IconChevronUp, } from '@tabler/icons'
import FilterPopup from '../../FilterPopup/FilterPopup'
import clsx from 'clsx'

function Field ({
  title,
  value,
  activeField,
  onClick,
  handler,
  items,
  itemType,
}: any) {
  function handleFieldClick () {
    activeField ? onClick(null) : onClick(title)
  }

  const isOpen = title === activeField
  return (
    <div className={ clsx('chooseField', isOpen && 'chooseActive') } onClick={ handleFieldClick }>
      <FilterPopup isOpen={ isOpen } items={ items } handler={ handler } itemType={ itemType } />
      <div>
        <h4 className="fieldSubtitle">{ title }</h4>
        <div className="fieldValue">{ itemType === 'multiple' ? value.value : value }</div>
      </div>
      { !isOpen ? <IconChevronDown /> : <IconChevronUp /> }
    </div>
  )
}

export default Field

import { TextareaAutosize, } from '@mui/material'
import { DebounceInput, } from 'react-debounce-input'
import './TextField.less'


function TextField ({
  placeHold,
  value,
  handler,
  styles,
  isWithDebounce,
  isWithAutosize,
  debounceTimeout = 400,
  isDisabled,
}: any): JSX.Element {
  if (isWithDebounce) {
    return (
      <DebounceInput
        debounceTimeout={ debounceTimeout }
        className="justInput"
        type="text"
        placeholder={ placeHold }
        value={ value }
        onChange={ e => handler(e.target.value) }
        style={ styles }
        disabled={ isDisabled }
      />
    )
  } else if (isWithAutosize) {
    return (
      <TextareaAutosize
        className="justInput"
        placeholder={ placeHold }
        value={ value }
        onChange={ e => handler(e.target.value) }
        style={ styles }
        disabled={ isDisabled }
      />
    )
  } return (
    <input
      className="justInput"
      type="text"
      placeholder={ placeHold }
      defaultValue={ value }
      onChange={ e => handler(e.target.value) }
      style={ styles }
      disabled={ isDisabled }
    />
  )
}


export default TextField

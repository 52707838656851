import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  currentBook: null,
  filterStatus: 'Учебная деятельность',
  firstCourse: [],
  secondCourse: [],
  thirdC: [],
  fourthC: [],
  fifthCourse: [],
  sixC: [],
  currentCourse: '1 курс',
}

export const recordSlice = createSlice({
  name: 'record',
  initialState,
  reducers: {
    setCurrentBook: (state, action) => {
      state.currentBook = action.payload
      state.currentCourse = '1 курс'
    },
    setFilter: (state, action) => {
      state.filterStatus = action.payload
    },
    setFirst: (state, action) => {
      state.firstCourse = action.payload
    },
    setSecond: (state, action) => {
      state.secondCourse = action.payload
    },
    setThird: (state, action) => {
      state.thirdC = action.payload
    },
    setFourth: (state, action) => {
      state.fourthC = action.payload
    },
    setFifth: (state, action) => {
      state.fifthCourse = action.payload
    },
    setSix: (state, action) => {
      state.sixC = action.payload
    },
    setCurrentCourse: (state, action) => {
      state.currentCourse = action.payload
    },
  },
})

export const { setCurrentBook, setFilter, setFifth, setFirst, setFourth,
  setSecond, setSix, setThird, setCurrentCourse, } = recordSlice.actions
export default recordSlice.reducer

import { updateStudent } from "@/legacy/redux/ProfileSlices/ProfileSlices";
import useGetRole from "@/legacy/redux/hooks/common/useGetRole";
import { useQuery, gql } from "@apollo/client";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const STUDENT_INFO = gql`
  query GetStudentInfo {
    studentInfo
  }
`;

const STUDENT_INFO_BY_ID = gql`
  query GetStudentInfoById($id: String) {
    studentInfoById(id: $id)
  }
`;

const EMPLOYEE = gql`
  query getEmployee {
    employeesInfo
  }
`;

const useStudentInfo = () => {
  const role = useGetRole();

  const dispatch = useDispatch();

  const { data, error, loading } = useQuery(
    role === "student" || role === "student-rakus" ? STUDENT_INFO : EMPLOYEE
  );

  useEffect(() => {
    if (!error && !loading && data?.studentInfo) {
      dispatch(updateStudent(data.studentInfo));
    }
  }, [data, error, loading, dispatch]);
};

const useStudentInfoById = (id: any) =>
  useQuery(STUDENT_INFO_BY_ID, { variables: { id } });

export { useStudentInfo, useStudentInfoById };

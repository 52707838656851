import { useMutation, } from '@apollo/client'
import { UPDATE_CONFIG, } from './useUpdateEcDocuments'


const useUpdateEcThresholdValues = () => {
  const [
    onUpdate,
  ] = useMutation(UPDATE_CONFIG)

  const onUpdateEcThresholdValues = (dataType: any) => onUpdate({
    variables: {
      input: {
        domain: 'ec-ams',
        key: 'threshold-values',
        mode: 'merge',
        config: {
          ...dataType,
        },
      },
    },
  })


  return { onUpdateEcThresholdValues, }
}


export default useUpdateEcThresholdValues

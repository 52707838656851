import { CreatePayloadParamDataResponses, OnUpdateProjectPayload, } from '@/legacy/gql/hooks/projects/useUpdateProjectTypes'

const onAddResultHandler = (
  onUpdateProject: Function,
  projectPayloadData: OnUpdateProjectPayload,
  userGuid: string,
  currentUserGuid: string,
  report: string
) => {
  const newProjectPayloadData = JSON.parse(JSON.stringify(projectPayloadData))
  const userData = newProjectPayloadData.args.data.responses.find(
    (student: CreatePayloadParamDataResponses) => student.id === userGuid)

  const emptyFileObject = {
    file: null,
    title: report,
    whoAdded: currentUserGuid,
  }

  if (!('studentResults' in userData) || !('files' in userData?.studentResults)) {
    userData.studentResults = {
      status: 'pending',
      files: [
        emptyFileObject,
      ],
    }
  } else {
    userData.studentResults.files.push(emptyFileObject)
  }

  onUpdateProject(newProjectPayloadData)
}


export default onAddResultHandler

import './AttachedParticipants.less'

const AttachedParticipants = ({
  participantsList = [],
  removeMode = true,
  getName,
}: any): JSX.Element => (
  <ul className="attached-files-list">
    { participantsList.map((participant: any, index: any) => {
      return (
        <li
          key={ participant + index }
          className="attached-files-list__item"
        >
          <span
            className="attached-files-list__link attached-files-list__name"
          >
            <span>{ participant }</span>
          </span>

          { removeMode ? (
            <span
              className="attached-files-list__remove-file"
              onClick={ () => getName(participant) }
            >
              X
            </span>
          ) : false }
        </li>
      )
    }) }
  </ul>
)


export default AttachedParticipants

import React, { useMemo, } from 'react'
import './WorkerGroups.less'
import WorkerCard from '../../StudyJournal/WorkerJournal/WorkerCard/WorkerCard'
import { useGetGroups, } from '../../../gql/hooks/useJournal'

function WorkerGroups ({
  handleGroup,
}: any) {
  const { data, error, loading, } = useGetGroups()
  const cards = useMemo(() => {
    if (data) {
      return data.teacherDisciplineGroups.map((i: any) => {
        return <WorkerCard key={ data.teacherDisciplineGroups.indexOf(i) } info={ i } type="course" click={ handleGroup } />
      })
    } if (error) {
      return <div>.....error</div>
    } if (loading) {
      return <div>......Loading</div>
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
  ])

  return (
    <div className="workerGroups">
      <h3 className="groupsTitlte">Выберите группу, чтобы посмотреть темы курсовых работ</h3>
      { cards }
    </div>
  )
}

export default WorkerGroups

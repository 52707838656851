/* eslint-disable no-console */
import { TitleH3, } from '../../../../common/GeneralElements/GeneralElements'
import PaperContainerWrapper from '../../../../common/PaperContainer/PaperContainerWrapper'
import TextArea from '../../../../common/TextArea/TextArea'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'


const EditTrackMainContent = ({
  discipline,
  setPayload,
  payload,
}: any) => {
  const { info, semester, hours, teachers, details, name, } = payload

  console.log('detailEdit', payload)


  const handleTeachersFio = (value: any, field: any, index: any) => setPayload({
    ...payload,
    teachers: teachers.map((teach: any, num: any) => {
      if (index === num) {
        return {
          ...teach,
          fio: {
            ...teach.fio,
            [field]: value,
          },
        }
      }
      return teach
    }),
  })

  const handleTeachersExpertise = (value: any, field: any, index: any) => setPayload({
    ...payload,
    teachers: teachers.map((teach: any, num: any) => {
      if (index === num) {
        return {
          ...teach,
          [field]: value,
        }
      }
      return teach
    }),
  })

  const teachersFrame = teachers.map((i: any, index: any) => [
    {
      lable: 'Имя',
      value: teachers[index].fio.firstname,
      handler: (value: any) => handleTeachersFio(value, 'firstname', index),
    },
    {
      lable: 'Фамилия',
      value: i.fio.lastname,
      handler: (value: any) => handleTeachersFio(value, 'lastname', index),
    },
    {
      lable: 'Отчество',
      value: i.fio.patronymic,
      handler: (value: any) => handleTeachersFio(value, 'patronymic', index),
    },
    {
      lable: 'Стаж',
      value: i.standing,
      handler: (value: any) => handleTeachersExpertise(value, 'standing', index),
    },
    {
      lable: 'Ученая степень',
      value: i.grade,
      handler: (value: any) => handleTeachersExpertise(value, 'grade', index),
    },
    {
      lable: 'Ученое звание',
      value: i.degree,
      handler: (value: any) => handleTeachersExpertise(value, 'degree', index),
    },
    {
      lable: 'Опыт практической деятельности / иная интересная информация',
      value: i.experience,
      handler: (value: any) => handleTeachersExpertise(value, 'experience', index),
    },
  ])

  const handleResetMain = () => {
    setPayload({
      ...payload,
      teachers: discipline.teachers,
      info: discipline.info,
      semester: discipline.semester,
      hours: discipline.hours,
      details: discipline.details,
    })
  }

  return (
    <PaperContainerWrapper>
      <TitleH3 style={ { marginBottom: 0, } }>
        { discipline.discipline }
      </TitleH3>
      <TitleH3>
        Основная информация
      </TitleH3>
      <TextArea
        rows='3'
        withPlaceholder={ false }
        defaultHint='название'
        value={ name }
        onChangeValue={ (e: any) => setPayload({
          ...payload,
          name: e,
        }) }
      />
      <TextArea
        style={ { marginTop: '15px', } }
        rows='3'
        withPlaceholder={ false }
        defaultHint='Описание'
        value={ details }
        onChangeValue={ (e: any) => setPayload({
          ...payload,
          details: e,
        }) }
      />
      <TextArea
        style={ { marginTop: '15px', } }
        rows='3'
        withPlaceholder={ false }
        defaultHint='Интересная информация'
        value={ info }
        onChangeValue={ (e: any) => setPayload({
          ...payload,
          info: e,
        }) }
      />
      <br />
      <div style={ {
        display: 'flex',
        marginBottom: '1.6rem',
      } }>
        <InputTextDeprecated
          defaultHint="Семестр изучения"
          styles={ {
            width: '100%',
            marginRight: '1rem',
          } }
          value={ semester }
          onChangeValue={ (e: any) => setPayload({
            ...payload,
            semester: +e,
          }) }
        />
        <InputTextDeprecated
          defaultHint="Объем дисциплины"
          styles={ { width: '100%', } }
          value={ hours }
          onChangeValue={ (e: any) => setPayload({
            ...payload,
            hours: +e,
          }) }
        />
      </div>
      <div style={ {
        display: 'flex',
        alignItems: 'baseline',
      } }>
        <TitleH3 style={ { marginRight: '1rem', } }>
          Данные о преподавателе
        </TitleH3>
      </div>
      <PaperContainerWrapper variant="sub-paper">
        <div className="teachersEditWrap">
          { teachersFrame.map((i: any) => i.map((input: any, index: any) => <InputTextDeprecated
            defaultHint={ input.lable }
            styles={ { width: '32%', } }
            value={ input.value }
            onChangeValue={ input.handler }
            key={ index }
          />)) }
        </div>
      </PaperContainerWrapper>
      <div style={ {
        display: 'flex',
        justifyContent: 'flex-end',
      } }>
        <ButtonSimple
          styles={ { marginRight: '.6rem', } }
          value="Сбросить изменения"
          variant="groupe"
          onClickHandler={ handleResetMain }
        />
      </div>
    </PaperContainerWrapper>
  )
}

export default EditTrackMainContent

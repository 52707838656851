const RadioButtonFacade = ({
  content,
  BtnComponent,
  activeIndex,
  setActiveIndex,
}: any): JSX.Element => (
  <>
    { content.map((button: any, index: any) => (
      <BtnComponent
        value={ button.text }
        active={ index === activeIndex }
        onClickHandler={ () => setActiveIndex(index) }
        key={ button.text }
      />
    )) }
  </>
)


export default RadioButtonFacade

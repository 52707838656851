import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  activeTab: 'Рекомендованная литература',
}

export const LibrarySlice = createSlice({
  name: 'LibrarySlice',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
  },
})

export const { setActiveTab, } = LibrarySlice.actions
export default LibrarySlice.reducer

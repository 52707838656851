import { useEffect, useState, } from 'react'

const useCreateJsondata = (data: any) => {
  const [
    jsondata,
    setJsondata,
  ] = useState(data)

  useEffect(() => {
    setJsondata(data)
  }, [
    data,
  ])

  const removeCriteria = (field: any) => setJsondata(
    jsondata
      .filter((criteria: any) => criteria.field !== field)
      // @ts-expect-error TS(7006): Parameter 'criteria' implicitly has an 'any' type.
      .map((criteria, index) => ({
        ...criteria,
        field: `simple${ index }_value`,
      }))
  )


  const removeCriteriaElement = ({
    field,
    index,
    parentIndex,
  }: any) => {
    const type = jsondata.find((data: any) => data.field === field).type

    if (type === 'two-level-select') {
      if (typeof parentIndex === 'number') {
        setJsondata(
          jsondata
            .map((criteria: any) => {
              if (criteria.field === field) {
                return {
                  ...criteria,
                  levels: criteria.levels.map((level: any, levelIndex: any) => {
                    if (levelIndex === parentIndex) {
                      return {
                        ...level,
                        options: level.options.filter((option: any, optionIndex: any) => optionIndex !== index),
                      }
                    } return level
                  }),
                }
              } return criteria
            })
        )
      } else {
        setJsondata(
          jsondata
            .map((criteria: any) => {
              if (criteria.field === field) {
                return {
                  ...criteria,
                  levels: criteria.levels.filter((level: any, levelIndex: any) => levelIndex !== index),
                }
              } return criteria
            })
        )
      }
    }


    if (type === 'select') {
      setJsondata(
        jsondata
          .map((criteria: any) => {
            if (criteria.field === field) {
              return {
                ...criteria,
                options: criteria.options.filter((option: any, optionIndex: any) => optionIndex !== index),
              }
            } return criteria
          })
      )
    }
  }


  const addCriteria = (type: any) => {
    const newType = {
      ...type,
      field: `simple${ jsondata.length }_value`,
      scores: {
        ...type.scores,
        isMainScores: jsondata.length === 0,
      },
    }

    if (type.type === 'select' && jsondata.length === 0) {
      newType.scores.activeOption = 0
    }

    if (type.type === 'two-level-select' && jsondata.length === 0) {
      newType.scores.activeOption = [
        0,
        0,
      ]
    }


    setJsondata([
      ...jsondata,
      newType,
    ])
  }


  const addCriteriaElement = (field: any, optionFields: any) => {
    setJsondata(
      jsondata
        .map((criteria: any) => {
          if (criteria.field === field) {
            if (criteria.type === 'select') {
              return ({
                ...criteria,
                options: [
                  ...criteria?.options,
                  {
                    ...optionFields,
                    scores: {
                      amount: 0,
                    },
                  },
                ],
              })
            }

            if (criteria.type === 'two-level-select') {
              return ({
                ...criteria,
                levels: [
                  ...criteria.levels,
                  {
                    ...optionFields,
                    options: [],
                  },
                ],
              })
            }
          } return criteria
        })
    )
  }


  const addTwoLevelCriteriaElement = (field: any, levelIndex: any, optionFields: any) => {
    setJsondata(
      jsondata
        .map((criteria: any) => {
          if (criteria.field === field) {
            criteria.levels[levelIndex].options = [
              ...criteria.levels[levelIndex].options,
              {
                ...optionFields,
                scores: {
                  amount: 0,
                },
              },
            ]

            return criteria
          } return criteria
        })
    )
  }


  const onChangeInputValue = ({
    parentIndex,
    index,
    field,
    fieldType,
    subfield,
    newValue,
  }: any) => {
    const newField = jsondata.find((data: any) => data.field === field)

    if (newField.type === 'select' && typeof index === 'number') {
      if (subfield === 'scores') {
        newField.options[index][subfield] = {
          ...newField.options[index][subfield],
          amount: newValue,
        }


        if (!!newField.scores?.isMainScores && newField.scores.activeOption === index) {
          newField.scores.amount = newField.options[index][subfield].amount
        }
      } else if (subfield === 'detached') {
        newField.options[index][subfield] = newValue
      } else {
        newField.options[index][subfield] = newValue
      }
    } else if (newField.type === 'two-level-select') {
      if (fieldType === 'level') {
        newField.levels[index][subfield] = newValue
      } else if (fieldType === 'option') {
        if (subfield === 'scores') {
          newField.levels[parentIndex].options[index][subfield].amount = newValue

          if (
            !!newField.scores.isMainScores
            && newField.scores.activeOption[0] === parentIndex
            && newField.scores.activeOption[1] === index
          ) {
            newField.scores.amount = newField.levels[parentIndex].options[index][subfield].amount
          }
        } else if (subfield === 'detached') {
          newField.levels[parentIndex].options[index][subfield] = newValue
        } else {
          newField.levels[parentIndex].options[index][subfield] = newValue
        }
      } else {
        newField[subfield] = newValue
      }
    } else {
      if (subfield === 'scores') {
        newField[subfield] = {
          ...newField[subfield],
          amount: newValue,
        }
      } else {
        newField[subfield] = newValue
      }
    }


    setJsondata([
      ...jsondata,
    ])
  }


  return {
    jsondata,
    removeCriteria,
    removeCriteriaElement,
    addCriteria,
    addCriteriaElement,
    addTwoLevelCriteriaElement,
    onChangeInputValue,
  }
}


export default useCreateJsondata

import React from 'react'
import clsx from 'clsx'


function ContractCard ({
  contr,
  onClick,
  activeContract,
}: any) {
  const { number_contract, contract, educationType, date_contract, createDate, } = contr

  function isActive () {
    return current === active
  }

  const active = activeContract?.number_contract ? activeContract.number_contract : activeContract?.contract
  const current = contr.number_contract ? number_contract : contract
  const dateFrom = date_contract ? date_contract : createDate

  return (
    <div className="d-row d-space" onClick={ onClick }>
      <div className={ clsx(!isActive() && 'Contract', isActive() && 'active') }>
        <h2>{ `По договору №34 ${ number_contract ? number_contract : contract } от ${ dateFrom }` }</h2>
        <span style={ { fontSize: '1rem', } }>{ educationType ? educationType : 'Общежитие' }</span>
      </div>
    </div>
  )
}

export default ContractCard

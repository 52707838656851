/**
 * Конвертирует входящие данные в "Файловый тип".
 */


import { AllFileTypes, FileData, } from '@/legacy/types'


const toFilesArrayAdapter = (fileOrFiles: AllFileTypes | null | undefined): File[] | FileList | FileData[] => {
  if (Array.isArray(fileOrFiles)) {
    return fileOrFiles
  }

  if (fileOrFiles && !('name' in fileOrFiles)) {
    return fileOrFiles
  }

  if (fileOrFiles && 'upload_id' in fileOrFiles) {
    return [
      fileOrFiles,
    ]
  }

  if (fileOrFiles === null || fileOrFiles === undefined) {
    return []
  }

  return ([
    fileOrFiles,
  ])
}


export default toFilesArrayAdapter

const TableStringCell = ({
  cellValue,
}: { cellValue: unknown }): JSX.Element => {
  return (
    <td>
      { String(cellValue) }
    </td>
  )
}


export default TableStringCell

// TODO: Выпилить этот модуль из проекта


import { Fragment, useMemo, } from 'react'
import InputTextDeprecated from '../../../common/InputTextDeprecated/InputTextDeprecated'


const useCreateForm = (
  fields: any,
  formState: any,
  setFormState: any,
  isPrewievOnly: any
) => {
  const { formFields, } = useMemo(() => {
    if (!fields) {
      return { formFields: fields, }
    }


    const formFields = new Array(fields.length)

    fields.forEach((field: any, index: any) => {
      if (field.editType === 'input') {
        formFields[index] = (
          <Fragment
            key={ field.index }
          >
            <InputTextDeprecated
              disabled={ isPrewievOnly }
              value={ formState[field.index] }
              defaultHint={ field.title }
              numbersOnly={ field.inputDescription === 'numbersOnly' }
              onChangeValue={ (value: any) => {
                setFormState({
                  ...formState,
                  [field.index]: value,
                })
              } }
            />
            <br />
          </Fragment>
        )
      }
    })

    return { formFields, }
  }, [
    fields,
    formState,
    setFormState,
    isPrewievOnly,
  ])


  return {
    fields: formFields,
  }
}


export default useCreateForm

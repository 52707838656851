import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  values: {},
  errors: {},
  isValid: false,
}

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.values = {
        ...state.values,
        ...action.payload,
      }
    },
    setErrors: (state, action) => {
      state.errors = {
        ...state.errors,
        ...action.payload,
      }
    },
    setIsValid: (state, action) => {
      state.isValid = action.payload
    },
  },
})

export const { setValues, setErrors, setIsValid, } = formSlice.actions
export default formSlice.reducer

// TODO: этот файл надо будет выпилить


export const defineUserGroupe = (role: any, userGroupe: any) => {
  return userGroupe.some((item: any) => item === role)
}


export const moderGroupe_1_1 = [
  'dm-moder',
  'urvo-admin',
]

export const moderGroupe_1_2 = [
  'urvo-admin',
]

export const moderGroupe_1_3 = [
  'dm-moder',
  'urvo-admin',
]

export const moderGroupe_1_4 = [
  'ufpn-moder',
  'urvo-admin',
]

export const moderGroupe_2_1 = [
  'urvo-admin',
]

export const moderGroupe_2_2 = [
  'urvo-moder',
  'urvo-admin',
]

export const moderGroupe_2_3 = [
  'ufpn-moder',
  'urvo-moder',
  'urvo-admin',
]

export const moderGroupe_2_4 = [
  'urvo-moder',
  'urvo-admin',
]

export const moderGroupe_2_5 = [
  'uonid-moder',
  'ukp-moder',
  'urvo-moder',
  'tpd-moder',
  'urvo-admin',
]

export const moderGroupe_2_6 = [
  'urvo-moder',
  'urvo-admin',
]

export const moderGroupe_2_7 = [
  'ufpn-moder',
  'urvo-admin',
]

export const moderGroupe_2_8 = [
  'ufpn-moder',
  'urvo-admin',
]

export const moderGroupe_2_9 = [
  'ufpn-moder',
  'urvo-admin',
]

export const moderGroupe_2_10 = [
  'ufpn-moder',
  'urvo-admin',
]

export const moderGroupe_3_1 = [
  'ufpn-moder',
  'urvo-moder',
  'urvo-admin',
]

export const moderGroupe_3_2 = [
  'urvo-moder',
  'urvo-admin',
]

export const moderGroupe_3_3 = [
  'urvo-moder',
  'urvo-admin',
]

export const moderGroupe_3_4 = [
  'urvo-moder',
  'urvo-admin',
]

export const moderGroupe_3_5 = [
  'urvo-moder',
  'urvo-admin',
]

export const moderGroupe_4_1 = [
  'urvo-moder',
  'urvo-admin',
]

import {
  useCallback,
} from 'react'
import {
  useDispatch,
} from 'react-redux'
import {
  setShowFor,
} from '../../../../redux/moderateNotificationsSlice/moderateNotificationsSlice'
import { TitleH5, } from '../../../common/GeneralElements/GeneralElements'
import InputCheckbox from '../../../common/InputCheckbox/InputCheckbox'
import AddStudents from './AddStudents/AddStudents'


const UserGroupeChoice = ({
  titlle,
  checkboxList,
}: any) => {
  const dispatch = useDispatch()
  const memoSetShowFor = useCallback((newValue: any) => dispatch(setShowFor(newValue)), [
    dispatch,
  ])


  return <>
    <TitleH5>
      { titlle }
    </TitleH5>

    { checkboxList.map((value: any, index: any) => (
      <InputCheckbox
        key={ index }
        label={ value.item }
        checked={ value.isActive }
        onChange={ (event: any) => memoSetShowFor({
          item: value.item,
          isActive: event.target.checked,
        }) }
      />
    )) }

    <br />

    { checkboxList.find((checkbox: any) => checkbox.item === 'Студенты').isActive && (
      <AddStudents />
    ) }
  </>
}


export default UserGroupeChoice

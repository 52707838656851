import { createSlice, } from '@reduxjs/toolkit'


const initialState = {
  history: [],
  lastPath: '',
}


export const HistorySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    onAddToHistory: (state, action) => {
      if (state.history.length > 0) {
        // @ts-expect-error TS(2550): Property 'at' does not exist on type 'never[]'. Do... Remove this comment to see the full error message
        state.lastPath = state.history.at(-1)
      }

      state.history = [
        // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
        ...state.history,
        // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
        action.payload,
      ]
    },
  },
})


export const { onAddToHistory, } = HistorySlice.actions


export default HistorySlice.reducer

/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useMemo, useState, useEffect, } from 'react'
import './PaymentFrame.less'
import { useEducationContracts, } from '../../../../gql/hooks/useFinances'
import { format, } from 'date-fns'
import clsx from 'clsx'
import PaymentButtons from '../PaymentButtons/PaymentButtons'
import {
  IconArrowDownLeft,
  IconDiamond,
  IconWallet,
  IconCreditCard,
  IconArrowUpRight,
} from '@tabler/icons'
import Banks from '../Banks/Banks'
import HistoryPayment from '../HistoryPayment/HistoryPayment'
import NoContracts from '../NoContracts/NoContracts'
import DormitoryPayment from './DormitoryPayment/DormitoryPayment'
import ru from 'date-fns/locale/ru'
import { useSelector, } from 'react-redux'
import { findContact, } from '@/legacy/Utils/functions'
import { isDev, } from '@/shared/constants/constants'

function PaymentFrame ({ status, }: any) {
  function EducationPayment () {
    const { data, error, loading, } = useEducationContracts()
    const [
      activeContract,
      setActiveContract,
    ] = useState<any>()

    function isActive (i: any) {
      return i === activeContract?.contract
    }

    const contractCards = useMemo(() => {
      const currentCards: any = []
      data?.finEducationContracts?.map((i: any) => {
        const { educationType, } = i
        if (educationType === status) {
          currentCards.push(i)
        } else if (status === 'Образование' && educationType === 'Высшее') {
          currentCards.push(i)
        } else if (
          status === 'Образование' &&
          educationType === 'Аспирантура'
        ) {
          currentCards.push(i)
        } else {
          return null
        }
      })
      return currentCards
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      data,
      error,
      loading,
      status,
    ])

    useEffect(() => {
      setActiveContract(contractCards[0])
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      status,
      contractCards,
    ])

    // const {startDate, endDate, customer, debt} = activeContract ? activeContract : contractCards[0];
    const infoFields = [
      {
        title: 'Дата начала действия договора',
        value:
          activeContract?.startDate &&
          format(new Date(activeContract?.startDate), 'dd.MM.yyyy'),
        big: false,
      },
      {
        title: 'Дата окончания действия договора',
        value:
          activeContract?.endDate &&
          format(new Date(activeContract?.endDate), 'dd.MM.yyyy'),
        big: false,
      },
      {
        title: 'Заказчик договора',
        value: activeContract?.customer,
        big: false,
      },
      {
        title: activeContract?.debt.startsWith('-')
          ? 'Переплата по договору'
          : 'Задолженность по договору',
        value: `${ Number(activeContract?.debt.replace('-', '')) || 0 } руб.`,
        big: true,
        icon: activeContract?.debt.startsWith('-') ? (
          <IconArrowUpRight color={ 'white' } size={ 15 } />
        ) : (
          <IconArrowDownLeft color={ 'white' } size={ 15 } />
        ),
      },
      {
        title: 'Дата ближайшего обязательного платежа по графику',
        value:
          activeContract?.paymentSchedule?.paySum &&
            format(new Date(activeContract?.paymentSchedule.paySum), 'dd.MM.yyyy')
            ? activeContract?.paymentSchedule?.nearestPayDate &&
            format(
              new Date(activeContract?.paymentSchedule.nearestPayDate),
              'dd.MM.yyyy'
            )
            : '--',
        big: true,
      },
      {
        title: 'Сумма ближайшего обязательного платежа по графику',
        value: `${ Number(activeContract?.paymentSchedule?.paySum) || 0 } руб.`,
        big: true,
      },
      {
        title: 'Фактический платеж',
        value: `${ (Number(activeContract?.paymentSchedule?.TheSumFaktPayment) > 0 &&
          Number(activeContract?.paymentSchedule?.TheSumFaktPayment)) || 0 } руб.`,
        big: true,
      },
    ]

    const payButtons = [
      {
        value: 'Оплатить',
        icon: <IconCreditCard style={ { margin: '0 18px 0 0 ', } } />,
      }, , /* ,      {        value: 'Оплатить досрочно',        icon: <IconWallet style={ { margin: '0 18px 0 0 ', } } />,      },      {
        value: 'Взять образовательный кредит',
        icon: <IconDiamond strokeWidth={ 2 } style={ { margin: '0 18px 0 0 ', } } />,
      }, */
    ]

    const personalInfo = useSelector((state: any) => state.auth.me)

    const tel =
      findContact(personalInfo.data.contacts, {
        kind_contact_information: 'ТелефонМобильныйФизическиеЛица',
      }) !== '--'
        ? findContact(personalInfo.data.contacts, {
          kind_contact_information: 'ТелефонМобильныйФизическиеЛица',
        })
        : findContact(personalInfo.data.contacts, {
          kind_contact_information: 'Мобильный телефон',
        })

    const handleSimplePay = () => {
      window.open(
        `https://lk-dev.tsutmb.ru/api/es/payment/?order_price=${ activeContract.paymentSchedule.TheSumFaktPayment
        }&contract_id=${ activeContract.contract }&contract_date=${ format(
          new Date(activeContract.startDate),
          'yyyy-MM-dd'
        ) }&contract_name=${ personalInfo.data.full_name }&payer_name=${ activeContract.customer
        }&payer_phone=${ tel }&payer_email=${ personalInfo.email }`,
        '_blank'
      )
    }

    return activeContract ? (
      <>
        <div className="paymentFrame">
          <div className="contracts">
            { contractCards?.map((i: any) => {
              const { contract, createDate, refreshDate, } = i

              return (
                <div
                  key={ contract }
                  className={ clsx({
                    contractWrap: true,
                    wrapActive: isActive(contract),
                  }) }
                  onClick={ () => setActiveContract(i) }
                >
                  <h2>
                    { `По договору № ${ contract } от ${ createDate &&
                      format(new Date(createDate), 'dd.MM.yyyy', { locale: ru, })
                    }` }
                  </h2>
                  <p>{ `Информация актуальна на ${ format(
                    new Date(refreshDate),
                    'dd MMMM yyyy',
                    { locale: ru, }
                  ) }` }</p>
                </div>
              )
            }) }
          </div>
          <div className="paymentInfo">
            <h3>Информация</h3>
            <div className="infoGrid">
              { infoFields.map(i => {
                return (
                  <div key={ infoFields.indexOf(i) }>
                    <h3>{ i.title }</h3>
                    <div className={ clsx('value', i.big && 'big') }>
                      { i.value }
                      { i.icon ? (
                        <div
                          className={ clsx(
                            'debt',
                            activeContract?.debt.startsWith('-') && 'plus'
                          ) }
                        >
                          { i.icon }
                        </div>
                      ) : (
                        ''
                      ) }
                    </div>
                  </div>
                )
              }) }
            </div>
            { activeContract?.penalties > 0 ? (
              <p className="penalti">
                Внимание! У вас есть неоплаченные пени
                <div className="warn">!</div>
              </p>
            ) : (
              ''
            ) }
          </div>
          {isDev && <PaymentButtons
            buttons={ payButtons }
            handleSimplePay={ handleSimplePay }
          />}
        </div>
        <Banks contract={ activeContract?.contract } />
        <HistoryPayment contract={ activeContract } />
        <h3 className="footerTitle">
          * Информация о проведенной оплате отражается после получения
          возмещения от банка, спустя несколько дней после проведения успешной
          оплаты
        </h3>
      </>
    ) : (
      <NoContracts title="Информация о данном виде договоров отсутствует" />
    )
  }

  return status === 'Общежитие' ? <DormitoryPayment /> : <EducationPayment />
}

export default PaymentFrame

import { Dispatch, RefObject, SetStateAction, } from 'react'


const onBlurHandler = (
  inputRef: RefObject<HTMLTextAreaElement>,
  onUpdateRowHandler: Function,
  originValue: string,
  setOriginValue: Dispatch<SetStateAction<string>>

) => {
  if (inputRef.current) {
    const isCellEmpty = inputRef.current.value?.toString().length === 0

    if (isCellEmpty) {
      inputRef.current.focus()
    }

    if (!isCellEmpty && originValue !== inputRef.current.value) {
      setOriginValue(inputRef.current.value)
      onUpdateRowHandler()
    }
  }
}


export default onBlurHandler

import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  activeStatus: {
    name: null,
    serverStat: null,
  },
  isReferences: false,
  field: null,
  refAccountData: null,
  refAuthority: null,
  refType: null,
  howGet: null,
  refAmount: 1,
  isSuccessOpen: false,
}

export const mfcSlice = createSlice({
  name: 'mfc',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.activeStatus = action.payload
    },
    setReference: (state, action) => {
      state.isReferences = action.payload
    },
    setField: (state, action) => {
      state.field = state.field === null ? action.payload : null
    },
    setRefAccounData: (state, action) => {
      state.refAccountData = action.payload
    },
    setAuthority: (state, action) => {
      state.refAuthority = action.payload
    },
    setType: (state, action) => {
      state.refType = action.payload
    },
    setHowGet: (state, action) => {
      state.howGet = action.payload
    },
    setRefAmount: (state, action) => {
      state.refAmount = action.payload
    },
    openSucces: (state, action) => {
      state.isSuccessOpen = action.payload
    },
  },
})


export const { setStatus, setReference, setField,
  setRefAccounData, setAuthority, setType,
  openSucces, setHowGet, setRefAmount, } = mfcSlice.actions

export default mfcSlice.reducer

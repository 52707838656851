import './SmallDevicesPage.less'
import Develop from '@/shared/assets/images/Depelopment-in-process.png'
import { isIOS, } from 'react-device-detect'
import { IconBrandAndroid, IconBrandApple, } from '@tabler/icons'


const SmallDevicesPage = () => {
  return (<div className="smallDiviceWrap">
    <img src={ Develop } alt="картинка" />
    <h1>Web версия недоступна на вашем устройстве, воспользуйтесь приложением</h1>
    <p className="smallDevice__redirect" onClick={ isIOS ? () => window.location.replace(
      'https://apps.apple.com/us/app/%D0%BC%D0%BE%D0%B9-%D1%82%D0%B3%D1%83/id1645166117'
    ) : () => window.location.replace(
      'https://play.google.com/store/apps/details?id=com.mytgumobile'
    ) }>Перейти в приложение{ isIOS ? <IconBrandApple /> : <IconBrandAndroid /> }</p>
  </div>)
}

export default SmallDevicesPage

import { TableCellType, } from '@/legacy/types'
import { PlusCircleFilled, } from '@ant-design/icons'
import { useState, } from 'react'
import ButtonSimple, { IconComponent, } from '../../../common/ButtonSimple/ButtonSimple'
import styles from './AddNewAchievement.module.less'
import AddNewAchievementPopup from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/AddNewAchievementPopup'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { AchievementMixedParticipant, AchievementParticipant, } from '@/legacy/components/EffectiveContract/types/achievementParticipant'
import useCreateNewAchievementFormController from '@/legacy/components/EffectiveContract/Hooks/useCreateNewAchievementFormController/useCreateNewAchievementFormController'


type CellValue = string | File[] | AchievementParticipant[] | AchievementMixedParticipant[]

export interface TableCellTypeWithValue extends TableCellType {
  value: CellValue
}
export type UpdateControlledField = (valueIndex: string, newValue: CellValue) => void


interface AddNewAchievementProps {
  headerFields: TableCellType[] | undefined
  dataType: EcTableName
}

const AddNewAchievement = ({
  headerFields,
  dataType,
}: AddNewAchievementProps) => {
  const [
    isPopupOpen,
    setIsPopupOpen,
  ] = useState(false)

  const {
    controlledFields,
    updateControlledField,
    resetControlledValues,
  } = useCreateNewAchievementFormController(headerFields?.filter(field => !!field.editable))


  return (
    <>
      <ButtonSimple
        styles={ { width: 'max-content', } }
        value={
          <IconComponent
            text="Добавить достижение"
            Icon={ PlusCircleFilled }
            customClassName={ styles.icon }
            iconVariant="atLeft"
          />
        }
        variant="alone"
        onClickHandler={ () => setIsPopupOpen(true) }
      />


      <AddNewAchievementPopup
        dataType={ dataType }
        controlledFields={ controlledFields }
        isPopupOpen={ isPopupOpen }
        handlePopup={ () => {
          setIsPopupOpen(false)
          resetControlledValues()
        } }
        onChange={ updateControlledField }
      />
    </>
  )
}


export default AddNewAchievement

import { useMemo, useState, } from 'react'
import './RatingFilters.less'
import styles from './RatingFilters.module.less'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { IconChevronDown, IconCircleX, IconBackspace, } from '@tabler/icons'
import clsx from 'clsx'
import ChooseField from '../../../common/Fields/ChooseField/ChooseField'
import SpecsList from '../../../common/SpecsSearch/SpecsSearch'
import { DebounceInput, } from 'react-debounce-input'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import useGetInstitutes from '../../../../gql/hooks/common/useGetInstitutes'
import { useAchievementTypes, } from '../../../../gql/hooks/useAchievements'
import useAchievementStatuses from '../../../../gql/hooks/portfolio/useAchievementStatuses'
import useAchievementDirections from '../../../../gql/hooks/portfolio/useAchievementDirections'
import { setFilterField, } from '../../../../redux/PortfolioSlices/PortfolioSlice'
import { useDispatch, useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import Persons from '../../../common/PersonsInput/PersonsInput'
import CalendarSimple from '../../../common/CalendarSimple/CalendarSimple'
import { format, } from 'date-fns'
import InputSelectDeprecated from '../../../common/InputSelectDeprecated/InputSelectDeprecated'
import { URL, } from '@/shared/constants/constants'


const defaultUserFilter = {
  full_name: '',
  institute: '',
  education_level: '',
  major: '',
  education_form: '',
  course_number: '',
  direction: undefined,
  type: undefined,
  finalYearStudent: false,
}

const defaultAchievementFilter = {
  type: undefined,
  status: undefined,
  dateRange: undefined,
}


export const defineSection = (path: string) => {
  if (path === '/portfolio-rating') {
    return 'rating'
  }

  if (path === '/moder-portfolio') {
    return 'users'
  }

  if (path === '/moderate-achivments') {
    return 'achievements'
  }

  return ''
}


const filterValuesToQueryAdapter = (filterValues: any) => {
  const result = {
    full_name: filterValues?.full_name || undefined,
    institute: filterValues?.institute || undefined,
    eform: filterValues?.education_form || undefined,
    elevel: filterValues?.education_level || undefined,
    namespec: filterValues?.major || undefined,
    course: filterValues?.course_number || undefined,
    direction: filterValues?.direction || undefined,
    type: filterValues?.type || undefined,
    dateStart: filterValues?.dateRange?.from || undefined,
    dateEnd: filterValues?.dateRange?.to || undefined,
    finalYearStudent: filterValues.finalYearStudent,
  }

  return result
}


function RatingFilters ({
  directionTypeText,
  type,
}: any) {
  const { pathname, } = useLocation()
  const [
    personsValue,
    setPersonsValue,
  ] = useState('')
  const [
    currentField,
    setCurrentField,
  ] = useState(null)
  const [
    userName,
    setUserName,
  ] = useState({ full_name: '', })

  interface Filter {
    type: string;
    items?: any;
    width: string;
    handler: (data: any) => void;
    title: any;
    placeholder: string;
    checked?: boolean;
    selectedItems?: any;
  }

  interface CheckboxFilter extends Omit<Filter, 'items'> {
    type: 'checkbox';
    checked: boolean;
  }

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const filtersValues = useSelector(state => state.port.filters)
  const [
    localUsersFilter,
    setLocalUsersFilter,
  ] = useState(defineSection(pathname) === 'achievements'
    ? filtersValues.achievements : filtersValues.users)

  const [
    resetCalendar,
    setResetCalendar,
  ] = useState(false)

  const [
    isFull,
    setIsFull,
  ] = useState(false)
  const [
    isSpecSearchOpen,
    setIsSpecSearchOpen,
  ] = useState(false)
  const [
    nameSpec,
    setNameSpec,
  ] = useState([])

  const dispatch = useDispatch()

  const currentFilterValues = filtersValues[defineSection(pathname)]

  const getInstitutesQuery = useGetInstitutes()
  const institutesList = useMemo(() => {
    if (!getInstitutesQuery.loading && getInstitutesQuery?.data) {
      return getInstitutesQuery.data.catalogs.ecInstList
    }
  }, [
    getInstitutesQuery.loading,
    getInstitutesQuery.data,
  ])

  const { directions: directionsList, } = useAchievementDirections({
    toReturn: 'nameList',
  })

  const { directions: currentAchievementDirection, } = useAchievementDirections({
    toReturn: (
      (!!directionTypeText && type === 'moderPage') || localUsersFilter?.direction)
      ? 'keyByName'
      : null,
    name: directionTypeText || localUsersFilter?.direction,
  })


  const getAchievementTypes = useAchievementTypes(
    ((type === 'moderPage' || localUsersFilter?.direction) && typeof (currentAchievementDirection) === 'number')
      ? currentAchievementDirection
      : null)
  const filterTypes = useMemo(() => {
    if (!getAchievementTypes.loading && getAchievementTypes.data) {
      const data = getAchievementTypes.data.achievementTypes

      return data.map((achievementType: any) => achievementType.name)
    }
  }, [
    getAchievementTypes.loading,
    getAchievementTypes.data,
  ])

  const filterStatuses = useAchievementStatuses()

  const finalYearStudentFilter = {
    type: 'checkbox',
    width: '100%',
    handler: (data: any) => setLocalUsersFilter({
      ...localUsersFilter,
      finalYearStudent: data,
    }),
    title: 'Финальный курс',
    placeholder: '',
    checked: localUsersFilter.finalYearStudent,
  }

  const filters: (Filter | CheckboxFilter)[] = [
    {
      type: 'person',
      title: localUsersFilter.full_name,
      placeholder: 'ФИО',
      handler: (data: any) => setLocalUsersFilter({
        ...localUsersFilter,
        full_name: data,
      }),
      width: '49%',
    },
    {
      type: 'choose',
      title: localUsersFilter.institute,
      placeholder: 'Институт',
      items: institutesList,
      width: '49%',
      handler: (data: any) => setLocalUsersFilter({
        ...localUsersFilter,
        institute: data,
      }),
    },
    {
      type: 'many-choice',
      title: localUsersFilter.education_level,
      placeholder: 'Уровень образования',
      items: [
        'Бакалавриат',
        'Специалитет',
        'Магистр',
        'Аспирантура',
        'Ординатура',
        'СПО',
      ],
      selectedItems: localUsersFilter.education_level,
      width: '99%',
      handler: (data: any) => {
        if (new RegExp(data).test(localUsersFilter.education_level)) {
          setLocalUsersFilter({
            ...localUsersFilter,
            education_level: localUsersFilter.education_level.filter((item: any) => item !== data),
          })

          if (localUsersFilter?.education_level?.length === 0) {
            setLocalUsersFilter({
              ...localUsersFilter,
              education_level: '',
            })
          }
        } else {
          if (Array.isArray(localUsersFilter.education_level)) {
            setLocalUsersFilter({
              ...localUsersFilter,
              education_level: [
                ...localUsersFilter.education_level,
                data,
              ],
            })
          } else {
            setLocalUsersFilter({
              ...localUsersFilter,
              education_level: [
                data,
              ],
            })
          }
        }
      },
    },
    {
      type: 'spec',
      width: '32%',
      placeholder: 'Специальность',
      handler: (data: any) => setLocalUsersFilter({
        ...localUsersFilter,
        major: data,
      }),
      title: localUsersFilter.major,
    },
    {
      type: 'choose',
      width: '32%',
      items: [
        'Очная',
        'Очно/заочная',
        'Заочная',
      ],
      handler: (data: any) => setLocalUsersFilter({
        ...localUsersFilter,
        education_form: data,
      }),
      title: localUsersFilter.education_form,
      placeholder: 'Форма обучения',
    },
    {
      type: 'choose',
      width: '32%',
      items: [
        1,
        2,
        3,
        4,
        5,
        6,
      ],
      handler: (data: any) => setLocalUsersFilter({
        ...localUsersFilter,
        course_number: data,
      }),
      title: localUsersFilter.course_number,
      placeholder: 'Курс',
    },
    {
      placeholder: 'Раздел',
      title: localUsersFilter.direction,
      type: 'choose',
      width: '32%',
      items: directionsList,
      handler: (data: any) => setLocalUsersFilter({
        ...localUsersFilter,
        direction: data,
      }),
    },
    {
      title: localUsersFilter.type,
      placeholder: 'Вид достижения',
      type: 'choose',
      items: filterTypes,
      width: '32%',
      handler: (data: any) => setLocalUsersFilter({
        ...localUsersFilter,
        type: data,
      }),
    },
    {
      type: 'dateRange',
      width: '32%',
      handler: (range: any) => setLocalUsersFilter({
        ...localUsersFilter,
        dateRange: {
          from: format(new Date(range[0]), 'yyyy-MM-dd'),
          to: format(new Date(range[1]), 'yyyy-MM-dd'),
        },
      }),
      title: localUsersFilter?.dateRange,
      placeholder: 'Дата с - по',
    },
  ]

  const moderFilters: (Filter | CheckboxFilter)[] = [
    {
      type: 'choose',
      items: filterTypes,
      width: '32%',
      handler: (data: any) => setLocalUsersFilter({
        ...localUsersFilter,
        type: data,
      }),
      title: localUsersFilter.type,
      placeholder: 'Тип',
    },
    {
      type: 'choose',
      items: filterStatuses?.map((status: any) => status.name),
      width: '32%',
      handler: (data: any) => setLocalUsersFilter({
        ...localUsersFilter,
        status: filterStatuses.find((status: any) => status.name === data).key,
      }),
      title: filterStatuses?.find((item: any) => item.key === localUsersFilter.status)?.name,
      placeholder: 'Статус',
    },
    {
      type: 'dateRange',
      width: '32%',
      handler: (range: any) => setLocalUsersFilter({
        ...localUsersFilter,
        dateRange: {
          from: format(new Date(range[0]), 'yyyy-MM-dd'),
          to: format(new Date(range[1]), 'yyyy-MM-dd'),
        },
      }),
      title: localUsersFilter?.dateRange,
      placeholder: 'Дата добавления с - по',
    },
  ]

  if (pathname === '/portfolio-rating' || pathname === '/moder-portfolio') {
    filters.push(finalYearStudentFilter)
  }


  const arr = type === 'moderPage' ? moderFilters : filters

  /*  moderPage */

  // console.log(localUsersFilter)


  return (
    <Accordion
      className="ratingFilters"
      style={ {
        borderRadius: '15px',
        boxShadow: '0px 9px 14px rgba(0, 0, 0, 0.03)',
        paddingTop: '1rem',
        paddingBottom: '.2rem',
      } }
    >
      <AccordionSummary className='summary' onClick={ () => setIsFull(!isFull) }>
        { type === 'moderPage'
          ? (<h2>Фильтр</h2>)
          : (<h2>Фильтрация пользователей</h2>) }

        <div className={ clsx('chevronCircle', isFull && 'chevronUp') }>
          { <IconChevronDown color={ '#7F8190' } /> }
        </div>
      </AccordionSummary>


      <AccordionDetails style={ {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
      } }>
        { arr.map((i, index) => {
          if (i.type === 'choose') {
            return (
              <ChooseField
                value={ i.title }
                title={ i?.placeholder }
                handler={ i.handler }
                key={ index }
                styles={ {
                  width: `${ i.width }`,
                  marginBottom: '20px',
                } }
                items={ i.items }
              />
            )
          } else if (i.type === 'checkbox') {
            return (
              <div className="checkbox"
                style={ {
                  width: `${ i.width }`,
                  marginBottom: '20px',
                  marginLeft: '8px',
                } }
              >
                <input
                  type="checkbox"
                  checked={i.checked}
                  onChange={e => i.handler(e.target.checked)}
                  style = { {
                    cursor: 'pointer',
                  } }
                />
                <label>{i.title}</label>
              </div>
            )
          } else if (i.type === 'many-choice') {
            return (
              <InputSelectDeprecated
                // @ts-ignore
                defaultValue={ i.selectedItems }
                items={ i.items }
                styles={ {
                  width: `${ i.width }`,
                  marginBottom: '20px',
                  marginTop: 10,
                  height: '80px',
                } }
                defaultHint={ i?.placeholder }
                key={ index }
                callBack={ i.handler }
                isMultiple
                withResetValue={ i.selectedItems?.length === 0 }
              />
            )
          } else if (i.type === 'dateRange') {
            return (
              <CalendarSimple
                placeholderColor='#7f8191'
                withBorder
                key={ index }
                returnRange={ i.handler }
                styles={ {
                  width: `${ i.width }`,
                  marginTop: '10px',
                  marginBottom: '20px',
                } }
                defaultRangeDate={ resetCalendar }
                placeholder={ i.placeholder }
              />
            )
          } else if (i.type === 'person') {
            return (
              <div
                key={ index }
                className="dateWrap"
                style={ {
                  width: `${ i.width }`,
                  height: 'auto',
                  marginBottom: '30px',
                } }
              >
                <DebounceInput
                  style={ {
                    color: '#2E2E3D',
                    height: '100%',
                  } }
                  onChange={ e => setPersonsValue(e.target.value) }
                  debounceTimeout={ 500 }
                  type="text"
                  placeholder='ФИО'
                  value={ userName?.full_name }
                  // @ts-expect-error TS(2345): Argument of type '"ФИО пользователя"' is not assig... Remove this comment to see the full error message
                  onClick={ () => setCurrentField('ФИО пользователя') }
                />

                { personsValue?.length > 3 && (
                  <Persons
                    field='ФИО пользователя'
                    mode="student"
                    handler={ (answer: any) => {
                      setUserName(answer)
                      i.handler(answer.full_name)
                    } }
                    // @ts-expect-error TS(2345): Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
                    setCurrentField={ () => setCurrentField('') }
                    currentField={ currentField }
                    value={ personsValue }
                    clear={ setPersonsValue }
                    paginatonLimit={ 10 }
                  />
                ) }
              </div>
            )
          } else if (i.type === 'spec') {
            return (
              <div
                className="dateWrap multiple"
                key={ index }
                style={ {
                  width: `${ i.width }`,
                  marginBottom: '20px',
                } }
              >
                <span>
                  { nameSpec?.map(i => (
                    <div
                      className="specTag"
                      key={ i }
                    >
                      { i }
                      <IconCircleX
                        onClick={ () => setNameSpec(nameSpec.filter(item => item !== i)) }
                        color="#C5C5C5"
                      />
                    </div>
                  )) }
                </span>


                <DebounceInput
                  style={ { color: '#2E2E3D', } }
                  onChange={ event => {
                    i.handler(event.target.value)

                    if (event.target.value.length > 1) {
                      setIsSpecSearchOpen(true)
                    } else {
                      setIsSpecSearchOpen(false)
                    }
                  } }
                  debounceTimeout={ 500 }
                  type="text"
                  placeholder={ i?.placeholder }
                  value={ localUsersFilter.major }
                />


                { isSpecSearchOpen && (
                  <SpecsList
                    handler={ (data: any) => {
                      i.handler(data)
                      setIsSpecSearchOpen(false)
                    } }
                    value={ localUsersFilter.major }
                    currentField={ 1 }
                    field={ 1 }
                  />
                ) }
              </div>
            )
          } return null
        }) }


        <div className="filterAccept">
          <div
            className="clear"
            onClick={ () => {
              setPersonsValue('')
              setCurrentField(null)
              setUserName({ full_name: '', })
              setResetCalendar(!resetCalendar)

              setLocalUsersFilter(defineSection(pathname) === 'achievements'
                ? defaultAchievementFilter : defaultUserFilter)
              dispatch(setFilterField({
                filter: defineSection(pathname),
                value: {},
              }))
            } } >
            Очистить фильтры<IconBackspace size={ 30 } />
          </div>

          <ButtonSimple
            onClickHandler={ () => dispatch(setFilterField({
              filter: defineSection(pathname),
              value: localUsersFilter,
            })) }
            value="применить"
            active
            variant="alone"
            styles={ { border: 'none', } }
          />
        </div>

        { /^rating$|^users$/.test(defineSection(pathname)) && (
          <>
            <br /><br /><br /><br />

            <div className={ styles.downloadFilteredDataButtonWrapper }>
              <ButtonSimple
                value={ 'Скачать отфильтрованный список' }
                variant={ 'alone' }
                onClickHandler={ () => {
                  return document.location = `https://${ URL }/api/portfolio/${ defineSection(pathname) === 'users' ? 'stats' : 'all' }?filter=${ encodeURIComponent(JSON.stringify(filterValuesToQueryAdapter(currentFilterValues))) }`
                } }
              />
            </div>
          </>
        ) }
      </AccordionDetails>
    </Accordion>
  )
}

export default RatingFilters

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { memo, } from 'react'
import './AddStudentItem.less'
import Image from '../../Image/Image'
import clsx from 'clsx'


const AddStudentItem = ({
  i,
  newStudents,
  setNewStudents,
  isSingleCheck = true,
}: any) => {
  const isChecked = () => {
    return newStudents.find((it: any) => i.id === it.id)
  }

  const toggleAddStudent = () => {
    console.log('toggle')
    if (!isChecked()) {
      setNewStudents([
        ...newStudents,
        i,
      ])
    } else {
      setNewStudents(newStudents.filter((item: any) => item.full_name !== i.full_name))
    }
  }

  return (
    <div
      className={ clsx('addStudentItem', isChecked() && 'addStudentItem__checked') }
      onClick={ () => toggleAddStudent() }
    >
      <div style={ { display: 'flex', } }>
        <Image id={ i.id } className="image" />

        <div className="nameWrap">
          <h2>{ i.full_name }</h2>

          <p>{ `${ i.course } курс` }</p>
        </div>
      </div>

      <div className={ clsx('custom-circleCheck', isChecked() && 'custom-circleCheck_active') } />

      {/*  <Checkbox /> */ }
    </div>
  )
}


export default memo(AddStudentItem)

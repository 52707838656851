import { useMemo, } from 'react'
import Popup from '../../../../../common/Popup/Popup'
import { useLocation, } from 'react-router-dom'
import { useGetStats, } from '../../../../../../gql/hooks/useMyEducation'
import './StatisticDetailPopup.less'
import { Preloader, } from '@/shared'


const StatisticDetailPopup = ({
  type,
  isOpen,
  setPopup,
  payloadType,
}: any): JSX.Element => {
  const location = useLocation()
  const id = new URLSearchParams(location.search).get('id')
  const name = new URLSearchParams(location.search).get('name')

  const { data, error, loading, } = useGetStats({
    filter: {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      ref_id: +id,
      type: payloadType,
    },
  })


  const DetailStats = useMemo(() => {
    if (error) {
      return <div>...error...</div>
    } else if (loading) {
      return <Preloader />
    }


    return (
      <Popup
        isOpen={ isOpen }
        handlePopup={ setPopup }
      >
        <div>
          <h2 className="detailStatsPopupTitle">{ `Подробная статистика по ${ type }у "${ name }"` }</h2>

          { data.esDisciplineStats.items[0] && data.esDisciplineStats.items[0].views.map((i: any, index: any) => {
            return (
              <div className="statsItemWrap" key={ index }>
                <h3>{ i.namespec }</h3>

                <div className="statsDetailWrap">
                  <div>
                    <h4>Просмотров</h4>

                    <p>{ i.views }</p>
                  </div>


                  <div>
                    <h4>Студентов записалось</h4>

                    <p>
                      { data.esDisciplineStats.items[0].signs.map((sign: any) => {
                        if (sign?.namespec === i.namespec) {
                          return sign.sign_in
                        } return null
                      }) }
                    </p>
                  </div>


                  <div>
                    <h4>Студентов отписалось</h4>

                    <p>
                      { data.esDisciplineStats.items[0].signs.map((sign: any) => {
                        if (sign?.namespec === i.namespec) {
                          return sign.sign_out
                        } return null
                      }) }
                    </p>
                  </div>
                </div>
              </div>
            )
          }) }
        </div>
      </Popup>
    )
  }, [
    data,
    error,
    loading,
    name,
    type,
    isOpen,
    setPopup,
  ])


  return (DetailStats)
}


export default StatisticDetailPopup

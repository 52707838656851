/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconPalette, IconRefresh, IconTrendingUp, } from '@tabler/icons'
import CollapseContainer2, { AccordionInnder, } from '../../../../../common/CollapseContainer2/CollapseContainer2'
import CardDisciplinePathway from '../../CardDisciplinePathway/CardDisciplinePathway'
import { useSelector, } from 'react-redux'
import FacultativeList from './FacultativeList'
import TrecksList from './TrecksList'
import ElectiveList from './ElectiveList'


const additionalEducation = {
  advancedTraining: [
    {
      name: 'Игропедагогика',
      type: 'Курсы',
      studentCount: [
        5,
        30,
      ],
    },
    {
      name: 'Игропедагогика2',
      type: 'Курсы',
      studentCount: [
        25,
        30,
      ],
    },
    {
      name: 'Игропедагогика3',
      type: 'Курсы',
      studentCount: [
        10,
        30,
      ],
    },
  ],

  refreshTraining: [
    {
      name: 'Игропедагогика4',
      type: 'Курсы',
      studentCount: [
        3,
        30,
      ],
    },
  ],

  creativeCourses: [
    {
      name: 'Графический дизайн',
      type: 'Курсы',
      studentCount: [
        30,
        30,
      ],
    },
    {
      name: 'Первая помощь',
      type: 'Курсы',
      studentCount: [
        8,
        30,
      ],
    },
  ],
}


const Body = ({
  role,
  type,
  nameSpec,
}: any) => {
  const CardDisciplinePathwayWithStyleAndModerMode = (props: any) => <CardDisciplinePathway
    styles={ { width: '26rem', } }
    moderMode={ role === 'osrv-moder' }
    { ...props }
  />

  const cardSubtitle = type === 'elective' ? 'Факультатив' : 'Дисциплина'


  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)


  const listDisciplines: any = {
    elective: <FacultativeList nameSpec={ nameSpec } role={ role } currentUserId={ currentUserId } />,
    important: <TrecksList nameSpec={ nameSpec } role={ role } currentUserId={ currentUserId } />,
    lesser: <ElectiveList nameSpec={ nameSpec } role={ role } currentUserId={ currentUserId } />,
  }


  return (
    <>
      { type === 'additional' ? (
        <CollapseContainer2>
          <AccordionInnder
            isOpened
            Icon={ IconTrendingUp }
            headerText='Программы повышения квалификации'
          >
            { additionalEducation.advancedTraining.map((card, index) => (
              <CardDisciplinePathwayWithStyleAndModerMode
                key={ card.name + index }
                { ...card }
              />
            )) }
          </AccordionInnder>


          <AccordionInnder
            Icon={ IconRefresh }
            headerText='Программы профессиональной переподготовки'
          >
            { additionalEducation.refreshTraining.map((card, index) => (
              <CardDisciplinePathwayWithStyleAndModerMode
                key={ card.name + index }
                { ...card }
              />
            )) }
          </AccordionInnder>


          <AccordionInnder
            Icon={ IconPalette }
            headerText='Творческие курсы'
          >
            { additionalEducation.creativeCourses.map((card, index) => (
              <CardDisciplinePathwayWithStyleAndModerMode
                key={ card.name + index }
                { ...card }
              />
            )) }
          </AccordionInnder>
        </CollapseContainer2>
      ) : (
        <div className="learning-pathway-tab__cards-wrapper">
          { listDisciplines[type] }
        </div>
      ) }

      {/*  {role === 'osrv-moder' && type === 'important' ? (
        <div className='learning-pathway-tab__buttons-wrapper'>
          <ButtonSimple
            value={
              <IconComponent
                text="Выгрузить список записанных и незаписанных студентов"
                Icon={IconUsers}
              />
            }
            variant="groupe"
          />

          <ButtonSimple
            value={
              <IconComponent
                text="Выгрузить статистику"
                Icon={IconChartBar}
              />
            }
            variant="groupe"
          />
        </div>
      ) : false} */}
    </>
  )
}


export default Body

import styles from './AttachedFiles.module.less'
import { ReactComponent as IconDocument, } from './images/document.svg'
import { downloadFile, } from '../../../hooks/useDownloadFile'
import { FileData, } from '@/legacy/types'


const fileListAdapter = (fileList: FileList | FileData[] | File[] | undefined) => {
  if (fileList === undefined) {
    return []
  }

  if (Array.isArray(fileList)) {
    return fileList
  }

  return Array.from(fileList)
}


interface AttachedFilesProps {
  fileList: FileList | FileData[] | [] | File[] | undefined
  isDeleteFilesAllowed: boolean
  getDeleteFileInfo?: (file: FileData | File) => void,
  isDisabledDownload?: boolean
  /** @deprecated пользуйся методом getDeleteFileInfo */
  getIndexFile?: Function
}


/**
 * getIndexFile - в идеале должен исчезнуть, со временем его полностью заменит метод getDeleteFileInfo
 */
const AttachedFiles = ({
  fileList,
  isDeleteFilesAllowed,
  getIndexFile,
  getDeleteFileInfo,
  isDisabledDownload,
}: AttachedFilesProps): JSX.Element => {
  const files = fileListAdapter(fileList)


  return (
    <ul className={ styles.attachedFilesList }>
      { files.map((file: FileData | File, index: number) => {
        return (
          <li
            key={ file.name + index }
            className={ styles.item }
          >
            <IconDocument className={ styles.icon } />

            <a
              className={ styles.link }
              onClick={ () => !isDisabledDownload && downloadFile(file) }
            >
              { file.name }
            </a>

            { isDeleteFilesAllowed && (
              <span
                className={ styles.removeFile }
                onClick={ () => {
                  if (getIndexFile) {
                    getIndexFile(index)
                  }

                  if (getDeleteFileInfo) {
                    getDeleteFileInfo(file)
                  }
                } }
              >
                X
              </span>
            ) }
          </li>
        )
      }) }
    </ul>
  )
}


export default AttachedFiles

/* eslint-disable no-console */
import { useMemo, } from 'react'
import './Banner.less'

import { Carousel, } from 'antd'
import Left from './Arrow/Left'
import Right from './Arrow/Right'
import { useBanner, } from '../../../../gql/hooks/useMain'
import onError from '@/shared/assets/images/onError.png'
import { Preloader, } from '@/shared'


const Banner = () => {
  const { data, error, loading, } = useBanner()


  const initialImages = useMemo(() => {
    if (error) {
      return <div>....error...</div>
    } else if (loading) {
      return <Preloader />
    } else if (data) {
      return data.sliderImages.map((item: any) => <img className="SlideBanner"
        src={ decodeURIComponent(item.urls.imageUrl) }
        onError={ ({ currentTarget, }) => {
          currentTarget.onerror = null
          currentTarget.src = onError
        } }
        onClick={ () => (item.urls.linkUrl !== 'None' ? document.location.href = item.urls.linkUrl : console.log('net')) }
        key={ item?.id }
        alt="banner"
      />)
    }
  }, [
    data,
    error,
    loading,
  ])

  const settings = {
    nextArrow: <Right />,
    prevArrow: <Left />,
    arrows: true,
    // lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    /* adaptiveHeight: true, */
    draggable: true,
  }

  return (

    <Carousel { ...settings }>
      { initialImages }
    </Carousel>
  )
}


export default Banner

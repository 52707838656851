import { gql, useMutation, useLazyQuery, } from '@apollo/client'

const SEND_MOBILE_NUMBER = gql`
  mutation SMSRequest(
    $phone: String!
    $message: String
    $expire: Int
    $fake: Boolean
    $source: String
  ) {
    smsCodeRequest(
      phone: $phone
      message: $message
      expire: $expire
      fake: $fake
      source: $source
    )
  }
`

const CHECK_SMS_CODE = gql`
  query CheckCode($code: String!) {
    smsCodeCheck(code: $code)
  }
`

export const useSendMobile = ({ phone, message, expire, fake, source, }: any) => useMutation(SEND_MOBILE_NUMBER, {
  variables: {
    phone,
    message,
    expire,
    fake,
    source,
  },
})
export const useCheckCode = (code: any) => useLazyQuery(CHECK_SMS_CODE, { variables: { code, }, })

import useRedirect from '@/legacy/hooks/useRedirect'
import { PROJECT_SEMINAR_BY, } from '@/shared/constants/routes'
import { IconChevronRight, } from '@tabler/icons'
import styles from './GroupCard.module.less'


interface GroupCardProps {
  value: string,
}


const GroupCard = ({
  value,
}: GroupCardProps): JSX.Element => {
  const redirect = useRedirect()

  return (
    <button
      className={ styles.card }
      onClick={ () => redirect(`${ PROJECT_SEMINAR_BY }?groupName=${ value }`) }
    >
      <div className={ styles.groupName }>
        Группа № { value }
      </div>

      <IconChevronRight className={ styles.arrow } />
    </button>
  )
}


export default GroupCard

import { useState, useEffect, } from 'react'
import './PAmoderApplicationsList.less'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import { IconX, IconAdjustmentsHorizontal, IconBackspace, } from '@tabler/icons'
import clsx from 'clsx'
import Field from '@/legacy/components/Projects/AllProjects/Field/Field'
import { useLocation, } from 'react-router-dom'
import Calendar from 'react-calendar'
import { useSelector, useDispatch, } from 'react-redux'
import { IconCalendarMinus, } from '@tabler/icons'
import { format, } from 'date-fns'
import Themes from '@/entities/PAapplications/Themes'
import Applications from '@/entities/PAapplications/Applications'
import { DebounceInput, } from 'react-debounce-input'
import Persons from '@/legacy/components/common/PersonsInput/PersonsInput'
import { setModerPage, } from '@/legacy/redux/utilsSlice'
import ru from 'date-fns/locale/ru'
import useRedirect from '@/legacy/hooks/useRedirect'
import GenerateStudentsResultExport from '@/features/project-activity/DownloadExportedStudentsResults'
import GenerateProjectsExport from '@/features/project-activity/DownloadExportedProjects'
import StudentsDistribution from '@/features/project-activity/DownloadExportedStudents'

function PAmoderApplicationsList () {
  const redirect = useRedirect()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const moderPage = useSelector(state => state.util.moderPage)
  const location = useLocation()

  const [
    isFilters,
    setIsFilters,
  ] = useState(false)
  const [
    currentField,
    setCurrentField,
  ] = useState<any>(null)
  const [
    appliName,
    setAppliName,
  ] = useState('')
  const [
    userName,
    setUserName,
  ] = useState<any>('')
  const [
    projectType,
    setProjectType,
  ] = useState('')
  const [
    userType,
    setUserType,
  ] = useState('')
  const [
    req_id,
    setReqId,
  ] = useState(location.state?.from ? location.state?.from : '')
  const [
    applyStatus,
    setApplyStatus,
  ] = useState<any>('')
  const [
    themesParams,
    setThemesParams,
  ] = useState<any>({ godMode: true, /* status: applyStatus?.status ? [ applyStatus?.status ] : ["approved", "rejected", "ready"] */ })

  const dispatch = useDispatch()

  const [
    calendar,
    setCalendar,
  ] = useState(false)
  const [
    rangeDate,
    setRangeDate,
  ] = useState<any>('')

  const [
    personsValue,
    setPersonsValue,
  ] = useState('')


  // const [more, setMore] = useState(700)

  const filterButtons = [
    // 'Предложенные проекты',
    'Проектные заявки',
  ]


  function isActive (i: any) {
    return i === moderPage
  }


  const clearAll = () => {
    setAppliName('')
    setUserName(null)
    setProjectType('')
    setUserType('')
    setRangeDate('')
    setThemesParams({ godMode: true, })
    setReqId('')
    setApplyStatus('')
  }

  const themesPayl = {
    name: appliName,
    userType: userType,
    creatorId: userName?.id,
    dateStart: rangeDate && format(new Date(rangeDate[0]), 'yyyy.MM.dd'),
    dateEnd: rangeDate && format(new Date(rangeDate[1]), 'yyyy.MM.dd'),
    applyStatus: applyStatus?.status,
  }

  const projectsPayl = {
    name: appliName,
    projectType,
    req_id: +req_id,
    creatorId: userName?.id,
    dateEnd: rangeDate ? format(new Date(rangeDate[1]), 'yyyy-MM-dd') : null,
    dateStart: rangeDate ? format(new Date(rangeDate[0]), 'yyyy-MM-dd') : null,
    applyStatus: applyStatus?.status,
  }

  useEffect(() => {
    setThemesParams({
      ...themesParams,
      status: applyStatus?.status,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applyStatus,
  ])


  function onFilteredSearch (payload: any) {
    /*   if(Object.values(payload).every((i) => !i)) {
       setThemesParams({ ...themesParams})
     } else { */
    if (location.state.from) {
      redirect('/moderate-applications')
    }
    setThemesParams({
      ...themesParams,
      filter: payload,
    })
    /* }  */
  }

  return (
    <div className="InnerPage allProjects ModeApply" >
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
      ] } loc="Модерация заявок" />
      <div className="studentInner">
        <h2 className="profileTitle">Модерация заявок</h2>

        <div className="filterButts">
          <div>
            {
              filterButtons.map(i => {
                return <button className={ clsx({
                  'statusButton': true,
                  'buttonActive': isActive(i),
                }) } onClick={ () => dispatch(setModerPage(i)) } key={ filterButtons.indexOf(i) }>{ i }</button>
              })
            }
          </div>
          { moderPage === 'Проектные заявки' && (
            <button className="statusButton" onClick={ () => redirect('/apply-project') }>
              Добавить проектную заявку
            </button>
          ) }
        </div>
        <div className="filterWrap">
          <div className="dateWrap" ><div className="chooseField inp"><label className="inpTitle">Название заявки</label><DebounceInput debounceTimeout={ 300 } type="text" placeholder='Введите название заявки' onChange={ e => setAppliName(e.target.value) } value={ appliName } /></div></div>
          <div className="dateWrap" ><div className="chooseField inp"><label className="inpTitle">ФИО пользователя</label>
            <DebounceInput
              onChange={ e => setPersonsValue(e.target.value) }
              debounceTimeout={ 300 }
              type="text"
              placeholder='выберите пользователя'
              value={ userName ? userName?.full_name : '' }
              onClick={ () => setCurrentField('ФИО пользователя') }
            />
          </div>{ personsValue?.length > 3 && <Persons field='ФИО пользователя' mode="person" handler={ (i: any) => setUserName(i) } setCurrentField={ () => setCurrentField('') } currentField={ currentField } value={ personsValue } clear={ setPersonsValue } /> }</div>
          <p onClick={ () => setIsFilters(!isFilters) }>{ isFilters ? 'Свернуть фильтры' : 'Развернуть фильтры' }</p>
          { isFilters ? <IconX color="#7671DD" size={ 20 } onClick={ () => setIsFilters(!isFilters) } /> : <IconAdjustmentsHorizontal color="#7671DD" size={ 29 } onClick={ () => setIsFilters(!isFilters) } /> }
          <button className="searchBut" onClick={ moderPage !== 'Проектные заявки' ? () => onFilteredSearch(themesPayl) : () => onFilteredSearch(projectsPayl) }>Поиск заявок</button>
        </div>
        <div className={ clsx(!isFilters && 'filtersHid', isFilters && 'filterFields') }>
          <div className="filterWrap">
            { moderPage !== 'Предложенные проекты' && <div className="dateWrap" ><Field items={ [
              'Исследовательские проекты',
              'Прикладные проекты',
              'Социальные проекты',
            ] } title="Тематика проекта" value={ projectType } onClick={ setCurrentField } activeField={ currentField } handler={ setProjectType } /></div> }
            { moderPage === 'Предложенные проекты' && <div className="dateWrap" ><Field items={ [
              'Преподаватель',
              'Студент',
            ] } title="Выбор пользователя" value={ userType } onClick={ setCurrentField } activeField={ currentField } handler={ setUserType } /></div> }
            <div className="dateWrap" ><Field items={ [
              {
                value: 'Заявка одобрена',
                status: 'ready',
              },
              {
                value: 'Заявка отклонена',
                status: 'rejected',
              },
              {
                value: 'Ожидает одобрения',
                status: 'wait',
              },
            ] } title="Статус заявки" value={ applyStatus } onClick={ setCurrentField } activeField={ currentField } handler={ setApplyStatus } itemType="multiple" /></div>
            { moderPage !== 'Предложенные проекты' && <div className="dateWrap" ><div className="chooseField inp"><label className="inpTitle">ID заявки</label><input type="text" placeholder='введите id заявки' onChange={ e => setReqId(e.target.value) } value={ req_id } /></div></div> }
            <div className="dateWrap"><div className="chooseField inp" onClick={ () => setCalendar(!calendar) }><label className="inpTitle">Дата подачи заявки</label><input type="text" /* style={{ margin: '10px 0 0 0' }} */ placeholder='выберите дату' onClick={ () => setCalendar(!calendar) } disabled value={ rangeDate ? `С ${ format(new Date(rangeDate[0]), 'dd MMM yy', { locale: ru, }) } по ${ format(new Date(rangeDate[1]), 'dd MMM yy', { locale: ru, }) }` : '' } /></div>
              <IconCalendarMinus color="#7F8191" onClick={ () => setCalendar(!calendar) } />
              {/*  <ClickAwayListener onClickAway={() => setCalendar(!calendar)}>
                <Box sx={{ position: 'relative' }}> */}
              {calendar && <Calendar
                formatMonthYear={ (locale: any, date: any) => format(date, 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(date, 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(date, 'dd MMMM yyyy', { locale: ru, }).slice(4) }
                locale={ 'Ru' }
                className={ calendar ? 'ScheduleCalendar ' : 'ScheduleCalendar off' }
                onChange={ setRangeDate }
                selectRange={ true }
              />}
              {/*  </Box>
              </ClickAwayListener> */}
            </div>
            { moderPage === 'Предложенные проекты' && <div className="clear" onClick={ clearAll } style={ {
              width: '32%',
              display: 'flex',
              justifyContent: 'end',
            } }>Очистить фильтры<IconBackspace size={ 30 } /></div> }
          </div>
          { moderPage !== 'Предложенные проекты' && <div className="clear" onClick={ clearAll }>Очистить фильтры<IconBackspace size={ 30 } /></div> }
        </div>
        <GenerateProjectsExport/>
        <GenerateStudentsResultExport/>
        <StudentsDistribution/>
        <div style={{ marginTop: 10, }}></div>
        { moderPage === 'Предложенные проекты' ? (
          <Themes filter={ moderPage } params={ themesParams } />
        ) : (
          <Applications params={ themesParams } filter={ moderPage } payl={ projectsPayl } />
        ) }
      </div>
      <div className={ clsx('hiddenLayer', calendar && 'hiddenLayer_vis') } onClick={ () => setCalendar(!calendar) } />
      {/* <ToastContainer /> */ }

    </div >
  )
}

export default PAmoderApplicationsList

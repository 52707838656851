import { table_cat_1_12, } from '@/legacy/components/EffectiveContract/popUpInputsCatalogs'
import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Показатель',
    index: 'indicator',
    editType: 'select',
    selectOptions: table_cat_1_12.indicators,
    editable: true,
    style: { width: '12rem', },
  },
  {
    title: 'Октябрь',
    index: 'monthOct',
    editType: 'inputNumbers',
    editable: true,
    style: { width: '12rem', },
  },
  {
    title: 'Декабрь',
    index: 'monthDec',
    editType: 'inputNumbers',
    editable: true,
    style: { width: '12rem', },
  },
  {
    title: 'Февраль',
    index: 'monthFeb',
    editType: 'inputNumbers',
    editable: true,
    style: { width: '12rem', },
  },
  {
    title: 'Апрель',
    index: 'monthApr',
    editType: 'inputNumbers',
    editable: true,
    style: { width: '12rem', },
  },
  {
    title: 'Средний % выполнения',
    index: 'percentDone',
    editType: 'inputNumbers',
    editable: false,
    style: { width: '12rem', },
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '12rem', },
    editable: false,
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Показатель',
    index: 'indicator',
    editType: 'select',
    selectOptions: table_cat_1_12.indicators,
    editable: true,
    style: { width: '12rem', },
  },
  {
    title: 'Октябрь',
    index: 'monthOct',
    editType: 'inputNumbers',
    editable: true,
    style: { width: '12rem', },
  },
  {
    title: 'Декабрь',
    index: 'monthDec',
    editType: 'inputNumbers',
    editable: true,
    style: { width: '12rem', },
  },
  {
    title: 'Февраль',
    index: 'monthFeb',
    editType: 'inputNumbers',
    editable: true,
    style: { width: '12rem', },
  },
  {
    title: 'Апрель',
    index: 'monthApr',
    editType: 'inputNumbers',
    editable: true,
    style: { width: '12rem', },
  },
  {
    title: 'Средний % выполнения',
    index: 'percentDone',
    editType: 'inputNumbers',
    editable: false,
    style: { width: '12rem', },
  },
  {
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '8rem', },
    editable: false,
  },
]

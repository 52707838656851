import useDefineCellContent from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/AchievementDataCell/hooks/useDefineCellContent'
import { OnChangeRowValue, } from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableRow/TableRow'
import { TableCellType, } from '@/legacy/types'


interface AchievementDataCellProps {
  cellData: TableCellType
  cellValue: unknown
  isRowEditAvailable: boolean
  uploadCategory: string
  onChangeRowValue: OnChangeRowValue
  onUpdateRowHandler: Function
}

const AchievementDataCell = ({
  cellData,
  cellValue,
  isRowEditAvailable,
  onChangeRowValue,
  onUpdateRowHandler,
  uploadCategory,
}: AchievementDataCellProps): JSX.Element => {
  // console.log('cellData', cellData)
  // console.log('cellValue', cellValue)


  const cellContent = useDefineCellContent({
    cellKey: cellData.index as string,
    componentType: cellData.editType,
    isEditable: cellData.editable,
    cellValue: cellValue,
    isRowEditAvailable: isRowEditAvailable,
    onChangeRowValue: onChangeRowValue,
    onUpdateRowHandler: onUpdateRowHandler,
    uploadCategory: uploadCategory,
    cellSelectOptions: cellData.selectOptions,
  })


  return (
    <>
      { cellContent }
    </>
  )
}


export default AchievementDataCell

import useDeleteUpload from '../../../gql/hooks/common/useDeleteUpload'
import useUploadFiles from '../../../hooks/useUploadFiles'
import { hidePostfixName, } from '../../Portfolio/AddAchivmentPopup/AddAchivmentPopup'
import AttachedFiles from '../AttachedFiles/AttachedFiles'
import InputFile from '../InputFile/InputFile'


const withoutNamePostfixAdapter = (files: any) => {
  return files.map((file: any) => ({
    ...file,
    name: hidePostfixName(file.name),
  }))
}


/**
 * pathForSave - путь для хранения файла на сервере (например: "notifications/document")
 * uploadedFiles - ссылка на массив в стейте
 * addFilesToList - ссылка на редьюссер добавляющий файлы в список
 * removeFileFromList - ссылка на редьюссер удаляющий файл (один) из списка
 */
const AttachementInput = ({
  pathForSave,
  uploadedFiles = [],
  addFilesToList,
  removeFileFromList,
}: any) => {
  const onUploadFiles = useUploadFiles()
  const onDeleteUploadedFile = useDeleteUpload()


  return <>
    <InputFile
      isMultiple
      onChange={ event => onUploadFiles({
        pathForSave,
        files: event.target.files,
        addFilesToList,
      }) }
    />

    <br />

    <AttachedFiles
      isDeleteFilesAllowed={ false }
      fileList={ withoutNamePostfixAdapter(uploadedFiles) }
      getDeleteFileInfo={ (fileInfo: any) => onDeleteUploadedFile({ uploadId: fileInfo.upload_id, }).then(uploadId => removeFileFromList(uploadId)) }
    />
  </>
}


export default AttachementInput

import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  laborCount: 0,
  laborActive: 0,
  AdsCount: 0,
  AdsActive: 0,
}

export const MainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setLaborCount: (state, action) => {
      state.laborCount = action.payload
    },
    setLaborActive: (state, action) => {
      state.laborActive = action.payload
    },
    setAdsCount: (state, action) => {
      state.AdsCount = action.payload
    },
    setAdsActive: (state, action) => {
      state.AdsActive = action.payload
    },
  },
})


export const { setLaborCount, setLaborActive, setAdsCount, setAdsActive, } = MainSlice.actions

export default MainSlice.reducer

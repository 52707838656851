import { TableCellType, } from '@/legacy/types'
import { table_cat_2_41, } from '../../popUpInputsCatalogs'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Название онлайн-курса',
    index: 'courseName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Язык, на котором разработан курс',
    index: 'language',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_41.language,
  },
  {
    title: 'Количество авторов',
    index: 'countAuthors',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Копия страницы/скрина с образовательного сервера',
    index: 'document',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: false,
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '8rem', },
    editable: false,
  },
]


export const appealTableSource: TableCellType[] = [
  {
    editable: false,
    title: '№',
    index: 'number',
  },
  {
    title: 'Название онлайн-курса',
    index: 'courseName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Язык, на котором разработан курс',
    index: 'language',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_41.language,
  },
  {
    title: 'Количество авторов',
    index: 'countAuthors',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Копия страницы/скрина с образовательного сервера',
    index: 'document',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Причина отказа',
    editable: false,
    index: 'reason',
    style: { minWidth: '20rem', },
    editType: 'input',
  },
  {
    title: 'Комментарий',
    editable: false,
    index: 'textAppeal',
    editType: 'input',
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
  },
]

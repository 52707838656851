import { gql } from '@/__generated__'
import { useMutation } from "@apollo/client"

const DEACTIVATE_INTERNET_SESSION_BY_CURRENT_USER = gql(`
  mutation DeactivateInternetSessionByCurrentUser{
    deactivateInternetSessionByCurrentUser {
      code
      dateLastLogin
      intranetStatus
      ip
      login
    }
  }
`)

const useDeactivateInternetSessionMutation = () => {
  const [deactivateInternetSession, { data, loading, error }] = useMutation(DEACTIVATE_INTERNET_SESSION_BY_CURRENT_USER)

  const deactivateSession = async () => {
    try {
      return await deactivateInternetSession()
    } catch (error) {
      console.log(error)
    }
  };
  return { deactivateSession, data, loading, error }
}

export default useDeactivateInternetSessionMutation
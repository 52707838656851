import { useEffect, useMemo, } from 'react'
import { useEducationContracts, } from '../../../../gql/hooks/useFinances'
import ContractCard from './ContractCard/ContractCard'


function EducationCon ({
  setEd,
  activeContract,
  setActiveContract,
}: any) {
  const { data, error, loading, refetch, } = useEducationContracts()

  useEffect(() => {
    if (!activeContract) {
      refetch()
    }
  }, [
    activeContract,
    refetch,
  ])


  useEffect(() => {
    if (data) {
      setEd(data?.finEducationContracts.length)
      !activeContract && setActiveContract(data.finEducationContracts[0])
    }
  }, [
    setEd,
    activeContract,
    setActiveContract,
    data,
    error,
    loading,
  ])

  const education = useMemo(() => {
    if (data) {
      return data?.finEducationContracts.map((i: any) => <ContractCard key={ i.contract } onClick={ () => setActiveContract(i) } contr={ i } activeContract={ activeContract } />)
    } else if (error) {
      return <div>.....error</div>
    } else if (loading) {
      return <div>......loading</div>
    }
  }, [
    data,
    error,
    loading,
    activeContract,
    setActiveContract,
  ])
  return education
}

export default EducationCon

import { useMemo, useEffect, } from 'react'
import './TasksProject.less'
import { Table, } from 'antd'
import { useSelector, useDispatch, } from 'react-redux'
import { useGetTasks, } from '../../../../gql/hooks/useProjects'
import { format, } from 'date-fns'
import compareAsc from 'date-fns/compareAsc'
import useRedirect from '@/legacy/hooks/useRedirect'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { setCurrentTask } from '@/legacy/redux/ProjectsSlice/ProjectSlice'


function TasksProject ({
  project,
}: any) {
  const dispacth = useDispatch()
  const redirect = useRedirect()


  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const newTask = useSelector(state => state.subscriptions.taskChanged)

  const role = useGetRole()


  const { req_id, } = project


  function handleTaskClick (i: any) {
    dispacth(setCurrentTask(i))
    redirect('/task')
  }


  const columns = [
    {
      title: 'Название',
      width: 400,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Дата создания',
      width: 150,
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Исполнитель',
      width: 300,
      dataIndex: 'responsible',
      key: 'responsible',
    },
    {
      title: 'Крайний срок',
      width: 150,
      dataIndex: 'till',
      key: 'till',
    },
    {
      title: 'Статус',
      width: 400,
      dataIndex: 'status',
      key: 'status',
    },
  ]

  /*   useEffect(() => {
      if(project.workType === "ВКР") {
        columns[2] = {
          title: 'Ответственные (руководитель/консультант)',
          width: 300,
          dataIndex: 'responsible',
          key: "responsible",
        }
      }
    eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project])
 */

  const params = (role === 'student' || role === 'student-rakus') ? {
    req_id,
    performer_id: 'current',
  } : {
    req_id,
  }

  const { refetch, data, error, loading, } = useGetTasks(params)


  useEffect(() => {
    refetch()
  }, [
    newTask,
    refetch,
  ])

  function getStatus (i: any) {
    if (i === 'new' || i === 'finished') {
      return 'В работе'
    } else if (i === 'completed') {
      return 'Завершена'
    } if (i === 'finished') {
      return 'На проверке'
    } /* else {
      return "Просрочена"
    } */
  }


  const content = useMemo(() => {
    if (error) {
      return <div>.....error....</div>
    } else if (loading) {
      return <div>....loading...</div>
    } else if (data) {
      const all = data.paTasks.map((i: any) => {
        const isExpire = compareAsc(new Date(i.data.date.replaceAll('-', ',')), new Date(Date.now()))
        return {
          key: i.task_id,
          name: <div onClick={ () => handleTaskClick(i) }>{ i.data.taskName }</div>,
          date: format(new Date(i.created), 'yyyy.MM.dd'),
          responsible: i.data.performer.map((item: any, index: any) => `${ item.full_name }${ i.data.performer.length - 1 === index ? '' : ',' } `),
          till: format(new Date(i.data.date), 'yyyy.MM.dd'),
          status: isExpire < 0 ? 'Просрочена' : getStatus(i.status),
        }
      })
      return <Table className={ 'd-scrollbar' } columns={ columns } pagination={ false } dataSource={ all } />
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
  ])


  return (
    <div className="tableTasks">
      { content }
    </div>
  )
}

export default TasksProject

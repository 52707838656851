import React, { useMemo, } from 'react'
import './StudentInfo.less'
import Image from '../../../common/Image/Image'
import { useStudentInfoById, } from '../../../../gql/hooks/useStudentInfo'
import { useUserInfo, } from '../../../../gql/hooks/useME'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import ru from 'date-fns/locale/ru'
import usePersonnelConfig from '@/shared/api/hooks/usePersonnelConfig/usePersonnelConfig'
import HtmlToPdf from '@/legacy/components/Portfolio/ModeratePortfolio/HtmlToPdf/HtmlToPdf'


function StudentInfo({
  i,
}: any) {
  const { data, error, loading, } = useStudentInfoById(i.id)

  const {
    data: dataPersonnelConfig,
    error: errorPersonnelConfig,
    loading: loadingPersonnelConfig,
    settings,
  } = usePersonnelConfig(i.id)


  const personalGeneral = useMemo(() => {
    if (error) {
      return <div>...error..</div>
    }
    if (loading) {
      return <div>...loading...</div>
    }

    if (loadingPersonnelConfig) {
      return <div>...loading...</div>
    }

    if (data && settings) {
      const studyFields = data.studentInfoById.map((item: any) => [
        {
          title: 'Институт/факультет',
          value: item.department_name,
        },
        {
          title: 'Номер группы',
          value: item.group,
        },
        {
          title: 'Уровень образования',
          value: item.elevel,
        },
        {
          title: 'Курс',
          value: item.course,
        },
        {
          title: 'Направление подготовки',
          value: item.namespec,
        },
        {
          title: 'Форма обучения',
          value: item.eform,
        },
      ])

      const personalLists = studyFields.map((fields: any, index: any) => (
        <ul className="personalLists" key={index}>
          {fields.map((item: any, index: any) => (
            <li key={index}>
              <h4>{item.title}</h4>
              <p>{item.value}</p>
            </li>
          ))}
        </ul>
      ))

      const isStudentRatingIncludeMyself = settings.is_student_rating_include_myself === 'true'
      const isStudentRatingAvailableAll = settings.is_student_rating_available_all === 'true'


      return (
        <div>
          {personalLists}
          <div>
            <p>
              Включил себя в список рейтинга: {isStudentRatingIncludeMyself ? 'да' : 'нет'}
            </p>
            <p>Портфолио доступно всем: {isStudentRatingAvailableAll ? 'да' : 'нет'}</p>
          </div>
          <HtmlToPdf id={i.id}/>
        </div>
      )
    }
  }, [
    data,
    error,
    loading,
  ])

  function StudentPersonal() {
    const { data, } = useUserInfo(i.id)

    return data ? <div
      className="personal">{`${formatDistanceToNowStrict(new Date(data.userInfo.date_of_birth), { locale: ru, })} • Гражданство: ${data.userInfo.country_name}`}</div> :
      <div>....loading...</div>
  }


  return <div className="studentInfoRating">
    <Image id={i.id} style={{
      width: '179px',
      height: '179px',
      borderRadius: '20px',
      marginRight: '40px',
    }}/>
    <div className="commonInfoWrap">
      <h3 className="fullName">{i.fio}</h3>
      <StudentPersonal/>
      {personalGeneral}
    </div>
  </div>
}

export default StudentInfo

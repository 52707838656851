import { TitleH3, TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import ButtonSimple, { IconComponent, } from '../../../../common/ButtonSimple/ButtonSimple'
import { IconCircleCheck, IconCircleX, } from '@tabler/icons'
import { useEffect, useState, } from 'react'
import TextArea from '../../../../common/TextArea/TextArea'
import { useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useGetEcListData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputFile from '../../../../common/InputFile/InputFile'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import { table_cat_2_7, } from '../../../popUpInputsCatalogs'
import { getScores_2_7, } from '../../../getScores'
import { Divider, } from '@mui/material'
import TeacherSearch from '../../../Hooks/TeacherSearch/TeacherSearch'
import AttachedParticipants from '../../../AttachedParticipants/AttachedParticipants'


const PopUpAppealContent_2_7 = ({
  data,
  onClosePopUp,
}: any) => {
  const [
    isAddMode,
    setIsAddMode,
  ] = useState(false)
  const [
    participants,
    setParticipants,
  ] = useState('')
  const [
    authors,
    setAuthors,
  ] = useState<any>([])
  const [
    otherAuthors,
    setOtherAuthors,
  ] = useState('')
  const [
    ids,
    setIds,
  ] = useState([])


  const [
    article,
    setArticle,
  ] = useState('')
  const [
    journal,
    setJournal,
  ] = useState('')
  const [
    publication_year,
    setPublication_year,
  ] = useState('')
  const [
    countAuthors,
    setCountAuthors,
  ] = useState('')
  const [
    foreign_authors,
    setForeign_authors,
  ] = useState('')
  const [
    quartileNumber,
    setQuartileNumber,
  ] = useState('')
  const [
    document,
    setDocument,
  ] = useState([])


  const [
    textAppeal,
    setTextAppeal,
  ] = useState('')
  const [
    currentAppealId,
    setCurrentAppealId,
  ] = useState(data.rec_id)
  const [
    currentAchievementId,
    setCurrentAchievementId,
  ] = useState(null)
  const [
    rejectAchievement,
    setRejectAchievement,
  ] = useState(false)
  const [
    reason,
    setReason,
  ] = useState('')
  const [
    scores,
    setScores,
  ] = useState('')


  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setIds([
      ...data.authors.map((item: any) => item.guid),
    ])
    setAuthors(data.authors)
    setOtherAuthors('')

    setArticle(data.article)
    setJournal(data.journal)
    setPublication_year(data.publication_year)
    setCountAuthors(data.countAuthors)
    setForeign_authors(data.foreign_authors)
    setQuartileNumber(data.quartileNumber)
    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setDocument(data.document ? [
      data.document,
    ] : [])

    setTextAppeal(data.textAppeal)
    setCurrentAppealId(data.rec_id)
  }, [
    data,
  ])


  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    setScores(getScores_2_7(data))
  }, [
    data,
  ])


  const [
    currentAchievement,
    setCurrentAchievement,
  ] = useState(null)
  const [
    currentAppeal,
    setCurrentAppeal,
  ] = useState(null)

  const getCurrentAppeal = useGetEcListData({
    rec_id: currentAppealId,
  })

  const getCurrentAchievement = useGetEcListData({
    rec_id: currentAchievementId,
  })

  useEffect(() => {
    if (getCurrentAppeal?.data) {
      setCurrentAppeal(
        getCurrentAppeal?.data?.ecListData[0]?.data)

      setCurrentAchievementId(getCurrentAppeal?.data?.ecListData[0]?.data?.rec_id)
    }
  }, [
    getCurrentAppeal.loading,
    getCurrentAppeal.data,
  ])

  useEffect(() => {
    if (getCurrentAchievement?.data && currentAchievementId) {
      setCurrentAchievement(
        getCurrentAchievement?.data?.ecListData[0]?.data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCurrentAchievement.loading,
    getCurrentAchievement.data,
  ])


  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (rec_id: any, newData: any, ids: any) => onUpdateRow({
    variables: {
      rec_id: rec_id,
      new_data: newData,
      user_ids: ids,
    },
  })


  useEffect(() => {
    setRejectAchievement(false)
  }, [
    data,
  ])


  return <>
    <TitleH3>
      Научные гранты и проекты университета, получившие финансовую поддержку.
    </TitleH3>
    <p>Внесите требуемые изменения и подтвердие или отклоните апелляцию</p>
    <p>Комментарий: <i><b>{ textAppeal }</b></i></p>
    <br />


    { !rejectAchievement ? (
      <>
        <ButtonSimple
          value={ isAddMode ? 'Применить' : 'Добавить / удалить участников' }
          variant="alone"
          onClickHandler={ () => {
            setIsAddMode(!isAddMode)
            setParticipants('')
          } }
        />

        <br /><br /><Divider /><br />

        { isAddMode ? (
          <>
            <TeacherSearch
              defaultHint="Добавьте участников по их ФИО"
              value={ participants }
              onChangeValue={ setParticipants }
              handler={ (answer: any) => {
                if (!ids.find(id => id === answer.guid)) {
                  setIds([
                    // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
                    ...ids,
                    // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
                    answer.guid,
                  ])

                  setAuthors([
                    ...authors,
                    {
                      guid: answer.guid,
                      fio: answer.fio,
                    },
                  ])
                }
              } }
            />

            <InputTextDeprecated
              defaultHint="Прочие авторы (не сотрудники Державинского университета, перечислить через запятую)"
              value={ otherAuthors }
              onChangeValue={ setOtherAuthors }
              onBlur={ () => {
                setAuthors([
                  ...authors,
                  ...otherAuthors.split(', ').map(authorName => {
                    return {
                      fio: authorName.trim(),
                      guid: null,
                    }
                  }).filter(author => author.fio.length > 0),
                ])

                setOtherAuthors('')
              } }
            />

            <br />


            <AttachedParticipants
              // @ts-expect-error TS(2339): Property 'fio' does not exist on type 'never'.
              participantsList={ authors.map(author => author.fio) }
              getName={ (name: any) => {
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                const removeId = authors.find(item => item.fio === name).guid

                setIds(ids.filter(item => item !== removeId))

                if (removeId === null) {
                  // @ts-expect-error TS(2339): Property 'fio' does not exist on type 'never'.
                  setAuthors(authors.filter(item => item.fio !== name))
                } else {
                  // @ts-expect-error TS(2339): Property 'guid' does not exist on type 'never'.
                  setAuthors(authors.filter(item => item.guid !== removeId))
                }
              } }
            />
          </>
        ) : (
          <>
            <InputTextDeprecated
              defaultHint="Авторы статьи"
              // @ts-expect-error TS(2339): Property 'fio' does not exist on type 'never'.
              value={ authors.map(author => author.fio).join(', ') }
              disabled
            /><br />

            <InputTextDeprecated
              defaultHint="Название статьи"
              value={ article }
              onChangeValue={ setArticle }
            /><br />

            <InputTextDeprecated
              defaultHint="Источник"
              value={ journal }
              onChangeValue={ setJournal }
            /><br />

            <InputTextDeprecated
              defaultHint="Год публикации"
              value={ publication_year }
              onChangeValue={ setPublication_year }
              numbersOnly
            /><br />

            <InputTextDeprecated
              defaultHint="Количество авторов"
              value={ countAuthors }
              onChangeValue={ setCountAuthors }
              numbersOnly
            /><br />

            <InputSelectDeprecated
              defaultHint="Соавторы"
              items={ table_cat_2_7.foreign_authors }
              defaultValue={ foreign_authors }
              withResetValue={ true }
              callBack={ setForeign_authors }
            /><br />

            <InputSelectDeprecated
              defaultHint="Квартиль"
              items={ table_cat_2_7.quartileNumber }
              defaultValue={ quartileNumber }
              withResetValue={ true }
              callBack={ setQuartileNumber }
            />

            <br />

            <TitleH5>
              Документ
            </TitleH5>

            { document.length < 1 ? (
              <InputFile
                onChange={ event => {
                  // TODO: сделать upload
                  // uploadFile(...event.target.files, setDocument)
                } }
              />
            ) : false }

            <AttachedFiles
              isDeleteFilesAllowed={ true }
              fileList={ document }
              getIndexFile={ (index: any) => {
                setDocument(document.filter((file, fIndex) => fIndex !== index))
              } }
            />

            <br />

            <InputTextDeprecated
              defaultHint="Баллы"
              value={ scores }
              numbersOnly
              disabled
            /><br />
          </>
        ) }
      </>
    ) : false }


    { rejectAchievement ? (
      <>
        <TextArea
          defaultHint='По какой причине вы хотите отклонить заявку?'
          withPlaceholder={ false }
          value={ reason }
          onChangeValue={ setReason }
        />

        <br />

        <div style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }>
          <ButtonSimple
            value="Отменить"
            variant="groupe"
            styles={ {
              marginRight: '1rem',
              backgroundColor: '#F5F6FA',
            } }
            onClickHandler={ () => {
              setRejectAchievement(false)
            } }
          />

          <ButtonSimple
            value={ (
              <IconComponent
                Icon={ IconCircleX }
                iconStyles={ { color: '#FF5E5E', } }
                text="Отклонить"
              />
            ) }
            variant="groupe"
            isDisabled={ reason.split(' ').length < 3 }
            onClickHandler={ () => {
              onUpdateRowHandler(
                currentAppealId,
                {
                  // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                  ...currentAppeal,
                  status: 'appealRejected',
                  appealConclusion: reason,
                },
                ids
              )

              onClosePopUp()
            } }
          />
        </div>
      </>
    ) : false }

    { !rejectAchievement ? (
      <div style={ {
        display: 'flex',
        justifyContent: 'flex-end',
      } }>
        <ButtonSimple
          isDisabled={ ids.length < 1 }
          value={ (
            <IconComponent
              Icon={ IconCircleX }
              iconStyles={ { color: '#FF5E5E', } }
              text="Отклонить"
            />
          ) }
          variant="groupe"
          styles={ { marginRight: '1rem', } }
          onClickHandler={ () => setRejectAchievement(true) }
        />

        <ButtonSimple
          isDisabled={ ids.length < 1 }
          value={ (
            <IconComponent
              Icon={ IconCircleCheck }
              text="Подтвердить"
            />
          ) }
          variant="groupe"
          active
          onClickHandler={ () => {
            onUpdateRowHandler(
              currentAchievementId,
              {
                // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                ...currentAchievement,
                authors: authors,
                article,
                journal,
                publication_year,
                countAuthors,
                foreign_authors,
                quartileNumber,
                document: document[0],
                status: 'approved',
                scores: scores,
              },
              ids
            )

            onUpdateRowHandler(
              currentAppealId,
              {
                // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                ...currentAppeal,
                status: 'appealApproved',
                appealConclusion: 'Ваша апелляция удовлетворена',
              },
              ids
            )

            onClosePopUp()
          } }
        />
      </div>
    ) : false }
  </>
}


export default PopUpAppealContent_2_7

import clsx from 'clsx'
import './FilterPopup.less'
import Image from '../../common/Image/Image'


function FilterPopup ({
  items,
  isOpen,
  handler,
  onClickAway,
  type,
  clearField,
  style,
  className,
}: any): JSX.Element {
  function handleClick (i: any) {
    handler(i)
    clearField && clearField()
    if (onClickAway) {
      onClickAway()
    }
  }


  return (
    <ul className={ clsx('projPopup', !isOpen && 'hidden', className) } style={ style }>
      { items && items.map((i: any, index: any) => {
        return type === 'person' ? <li onClick={ () => handleClick(i) } key={ i.id } className="projPopupItem persons">
          <Image
            className='person-image'
            id={ i.id }
            style={ {
              width: '24px',
              height: '24px',
              borderRadius: '20px',
            } }
          />

          <div className="consultant">
            <p>{ i.full_name }</p>
            <div className="mail">{ i.contacts.map((con: any) => {
              if (con.type === 'Корпортативная почта') {
                return con.value
              } return null
            }) }</div>
            <div className="mail">{ i.position_name }</div>
          </div>
        </li> : <li onClick={ () => handleClick((i.value && i.id) ? i.value : i) } key={ index } className="projPopupItem">{ i.value ? i.value : i }</li>
      }) }
      { (items?.length === 0) && <li className="projPopupItem persons">Нет совпадений</li> }
      { !items && <li className="projPopupItem persons">....Поиск....</li> }
    </ul>
  )
}


export default FilterPopup

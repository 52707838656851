import { gql, } from '@/__generated__'
import { useMutation, } from '@apollo/client'


const CREATE_ACHIEVEMENT = gql(`
  mutation CreateAchievement($input: CreateAchievementInput!) {
    createAchievement(input: $input)
  }
`)


const useAddAchievement = () => useMutation(CREATE_ACHIEVEMENT)


export default useAddAchievement

import { Checkbox, FormControlLabel, FormGroup, } from '@mui/material'
import { useEffect, useState, } from 'react'
import styles from './InputCheckbox.module.less'


const InputCheckbox = ({
  label = 'label',
  ...rest
}) => {
  return (
    <FormGroup className={ styles.form }>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            sx={ {
              '&.Mui-checked': {
                color: '#7671DD',
              },
            } }
            { ...rest }
          />
        }
        label={ label }
      />
    </FormGroup>
  )
}


export const useInputCheckbox = ({
  initialValue = false,
  isLazyData,
}: any) => {
  const [
    checkbox,
    setCheckbox,
  ] = useState(initialValue)

  useEffect(() => {
    if (isLazyData) {
      setCheckbox(Boolean(initialValue))
    }
  }, [
    initialValue,
    isLazyData,
  ])


  return {
    checked: checkbox,
    onChange: (event: any) => setCheckbox(event.target.checked),
  }
}


export default InputCheckbox

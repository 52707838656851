import DefaultPage from '../../../DefaultPage/DefaultPage'


const Practice = () => {
  return (
    <DefaultPage />
  )
}

export default Practice

import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'


const GET_REQUIRED_STUDENT_PAPERS = gql`
  query RequiredStudentPapers ($input: RequiredStudentPapersInput!) {
    requiredStudentPapers (input: $input)
  }
`

const useRequiredStudentPapers = ({
  recordBookNumber,
}: any) => {
  const { data, loading, } = useQuery(GET_REQUIRED_STUDENT_PAPERS, {
    variables: {
      input: {
        recordbook_number: recordBookNumber,
      },
    },
  })

  const requiredStudentPapers = useMemo(() => {
    if (!loading && data) {
      const currentData = data.requiredStudentPapers


      return [
        {
          name: 'termPapers',
          title: 'Курсовая работа',
          amount: currentData?.term_papers || 0,
        },
        {
          name: 'practices',
          title: 'Практика',
          amount: currentData?.practices || 0,
        },
      ]
    }
  }, [
    data,
    loading,
  ])


  return { requiredStudentPapers, }
}


export default useRequiredStudentPapers

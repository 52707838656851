import { TableCellTypeWithValue, UpdateControlledField, } from '@/legacy/components/EffectiveContract/TableContainer/AddNewAchievement/AddNewAchievement'
import { TableCellType, } from '@/legacy/types'
import { useState, } from 'react'


const useCreateNewAchievementFormController = (fields: TableCellType[] | undefined): {
  controlledFields: TableCellTypeWithValue[]
  updateControlledField: UpdateControlledField
  resetControlledValues: () => void
} => {
  const fieldsWithEmptyValues = fields?.map(field => {
    if (field.editType === 'upload' || field.editType === 'participants') {
      return {
        ...field,
        value: [],
      }
    }

    return {
      ...field,
      value: '',
    }
  })


  const [
    controlledFields,
    setControlledFields,
  ] = useState<TableCellTypeWithValue[]>(fieldsWithEmptyValues || [])


  const updateControlledField: UpdateControlledField = (valueIndex, newValue) => {
    const newFields: TableCellTypeWithValue[] = [
      ...controlledFields,
    ]

    const currentField = newFields.find((field: TableCellTypeWithValue) => field.index === valueIndex)

    if (currentField) {
      currentField.value = newValue
    }

    setControlledFields(newFields)
  }


  const resetControlledValues = () => {
    setControlledFields(fieldsWithEmptyValues || [])
  }


  return {
    controlledFields,
    updateControlledField,
    resetControlledValues,
  }
}


export default useCreateNewAchievementFormController

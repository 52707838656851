import { useMemo, } from 'react'
import './WorkerJournal.less'
import WorkerCard from './WorkerCard/WorkerCard'
import { useGetGroups, } from '../../../gql/hooks/useJournal'


function WorkerJournal () {
  const { data, error, loading, } = useGetGroups()
  const cards = useMemo(() => {
    if (data) {
      return data.teacherDisciplineGroups.map((i: any) => {
        return <WorkerCard key={ data.teacherDisciplineGroups.indexOf(i) } info={ i } />
      })
    } if (error) {
      return <div>.....error</div>
    } if (loading) {
      return <div>......Loading</div>
    }
  }, [
    data,
    error,
    loading,
  ])

  return (
    <>
      <h2 className="profileTitle">Элеткронный журнал</h2>
      <h3 className="K-WorkerHead">Выберите журнал, чтобы просмотреть отметки</h3>
      <ul className="workerList">
        { cards }
      </ul>
    </>
  )
}

export default WorkerJournal

import { useMutation, } from '@apollo/client'
import { EDIT_AVHIEVEMENT_TYPE, } from './useCreateAchievementType'


const useDeleteAchievementType = () => {
  const [
    onDelete,
  ] = useMutation(EDIT_AVHIEVEMENT_TYPE)

  const onDeleteHandler = ({
    key,
    direction_key,
  }: any) => onDelete({
    variables: {
      input: {
        key,
        direction_key,
      },
    },
  })


  return onDeleteHandler
}


export default useDeleteAchievementType

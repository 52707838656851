import { useMutation, } from '@apollo/client'
import { ADD_NOTIFICATION_DRAFT as UPDATE_NOTIFICATION_DRAFT, } from '@/legacy/gql/hooks/moderateNotifications/useAddNotificationDraft'

// const UPDATE_NOTIFICATION_DRAFT = gql`
//   mutation NotificationDraftAdd($draft_id: Int, $body: JSON!) {
//     notificationDraftAdd(draft_id: $draft_id, body: $body)
//   }
// `


const useUpdateNotificationDraft = () => {
  const [
    onUpdate,
  ] = useMutation(UPDATE_NOTIFICATION_DRAFT)

  const onUpdateNotificationDraft = ({
    draft_id,
    body,
  }: any) => onUpdate({
    variables: {
      draft_id,
      body,
    },
  })


  return { onUpdateNotificationDraft, }
}


export default useUpdateNotificationDraft

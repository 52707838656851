/* eslint-disable no-console */
import clsx from 'clsx'
import { useState, } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import useLookupStudentData, { SearchResultItem, StudentSearchResultItem, } from './useLookupStudentData'
import InputTextDeprecated, { useInputText, } from '../InputTextDeprecated/InputTextDeprecated'
import styles from './UniversalStudentDataSearch.module.less'


// Этот компонент написан не полностью
// его надо развивать, дополнять новыми вариантами поиска по информации связанной со студентами


const types = {
  group: {
    defaultHint: 'Группа',
  },
  namespec: {
    defaultHint: 'Специальности студентов',
  },
  nameprof: {
    defaultHint: 'Профили студентов',
  },
}


const isResultOfSearch = (item: SearchResultItem | StudentSearchResultItem): item is SearchResultItem => {
  return typeof item === 'string'
}


/**
 *
 * @param { Object } params
 * @param { "group" | "namespec" | "nameprof" } params.type
 */
const UniversalStudentDataSearch = ({
  type,
  callback,
}: any) => {
  const [
    isOpen,
    setIsOpen,
  ] = useState(false)
  const input = useInputText({ initialValue: '', })
  const { items, setLimit, hasMore, } = useLookupStudentData({
    field: type,
    search: input.value,
  })

  const increaseLimit = () => {
    setLimit(prev => prev + 10)
  }


  return (
    <button
      type={ 'button' }
      className={ clsx(styles.wrapper, { [styles.wrapper_additionalMargin]: isOpen, }) }
      onBlur={ () => setIsOpen(false) }
      onFocus={ () => setIsOpen(true) }
    >
      <InputTextDeprecated
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        defaultHint={ types[type].defaultHint }
        withPlaceholder
        debounceTimeout={ 300 }
        { ...input }
      />


      { isOpen && (
        <ul className={ styles.openedList }>
          <InfiniteScroll
            height={ 300 }
            dataLength={ items.length } // This is important field to render the next data
            next={ increaseLimit }
            hasMore={ hasMore }
            loader={ <h4>Loading...</h4> }
            endMessage={
              <p style={ { textAlign: 'center', } }>
                <b>Записей больше нет</b>
              </p>
            }
          >
            {
              items.map((item, index) => (
                <li
                  className='filter-drop-down__option-choose'
                  key={ index }
                  onPointerDown={ () => {
                    try {
                      callback(item)
                    } catch {
                      console.error('callback - не задан')
                    }
                  } }
                >
                  { isResultOfSearch(item) && item }
                </li>
              ))
            }
          </InfiniteScroll>
        </ul>
      ) }
    </button>
  )
}


export default UniversalStudentDataSearch

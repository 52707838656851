/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, } from 'react'
import { useSelector, } from 'react-redux'
import {
  useSignUpFacultative,
  useSignOutFacultative,
} from '../../../../../../gql/hooks/useMyEducation'
import { useLocation, } from 'react-router-dom'
import { useUpdateStats, } from '../../../../../../gql/hooks/useMyEducation'
import HeaderBlock from './HeaderBlock'
import AddElectivePopup from '../../AddDisciplinePopup/AddElectivePopup'
import { useGetActiveStats, } from '../../../../../../gql/hooks/useMyEducation'
import { useAppContext, } from '@/app/providers/AppProvider'

const ElectiveHeader = ({ role, type, attachedFiles, res, }: any) => {
  const [
    statsType,
    setStatsType,
  ] = useState('in')
  const [
    isSucces,
    setIsSuccess,
  ] = useState(false)
  const [
    isPopup,
    setIsPopup,
  ] = useState(false)
  const [
    disable,
    setDisable,
  ] = useState(false)

  const location = useLocation()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const me = useSelector(state => state.auth.me)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)
  const namespec = new URLSearchParams(location.search).get('namespec')

  const studInfo = useSelector(state => (role === 'student' || role === 'student-rakus'
    ? // @ts-expect-error TS(18046):'state' is of type 'unknown'.
    state.prof.studentInfo?.filter((i: any) => i.namespec === namespec)
    : false)
  )

  const { openToast, } = useAppContext()

  const { department_name, eform, nameprof, course, group, }: any = studInfo
    ? studInfo[0]
    : {}
  const [
    esSignUpFacultative,
  ] = useSignUpFacultative({
    facultative_id: res.facultative_id,
    type: 'elective',
    participants: [
      {
        id: currentUserId,
        department_name,
        eform,
        nameprof,
        namespec,
        course,
        group,
      },
    ],
  })

  const track_id = new URLSearchParams(location.search).get('id')

  const { addNotification, } = useAppContext()

  const handleParticipate = () => {
    setDisable(true)
    esSignUpFacultative()
      .then(() => {
        addNotification?.(
          'Мое обучение',
          [
            currentUserId,
          ],
          { message: `Вы записались на электив "${ res.name }"`, },
          { path: '/learning-pathway', }
        )
        setIsSuccess(!isSucces)
        setDisable(false)
      })
      .catch(e => console.log(e))
  }

  const [
    esSignOutFacultative,
  ] = useSignOutFacultative({
    facultative_id: res.facultative_id,
    participant_id: [
      me.data.guid,
    ],
    type: 'elective',
  })

  const handleSignOut = () => {
    setDisable(true)
    esSignOutFacultative()
      .then(res => {
        addNotification?.(
          'Мое обучение',
          [
            currentUserId,
          ],
          // @ts-expect-error TS(2339):Property 'name' does not exist on type 'SingleExecutionResult<SignUpMutation, Record<string, any>, Record<string, any>>'.ts(2339)
          { message: `Вы отписались от электива "${ res.name }"`, },
          { path: '/learning-pathway', }
        )
        setDisable(false)
      })
      .then(() => openToast?.({ message: 'Вы успешно отписались от электива', }))
      .catch(e => console.log(e))
  }

  return (
    <>
      <HeaderBlock
        role={ role }
        type={ type }
        attachedFiles={ attachedFiles }
        handleSignOut={ handleSignOut }
        handleParticipate={ handleParticipate }
        isSucces={ isSucces }
        setIsSuccess={ setIsSuccess }
        isPopup={ isPopup }
        setIsPopup={ setIsPopup }
        description={ res.description }
        name={ res.name }
        is_active={ res.is_active }
        is_signed={ res.is_signed }
        isDisable={ disable }
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        track_id={ +track_id }
        max_participants={ res.max_participants }
        sign_count={ res.sign_count }
      />
      <AddElectivePopup
        isOpen={ isPopup }
        handlePopup={ () => setIsPopup(!isPopup) }
        type={ type }
        title="Редактировать Электив"
        data={ res }
      />
    </>
  )
}

export default ElectiveHeader

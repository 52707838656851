import { gql, } from '@/__generated__'
import { TitleH3, } from '@/legacy/components/common/GeneralElements/GeneralElements'
import Popup from '@/legacy/components/common/Popup/Popup'
import { useAppSelector, } from '@/legacy/redux/hooks'
import { setAndroidVersion, setIosVersion, } from '@/legacy/redux/mobileAppVersionSlice/mobileAppVersionSlice'
import MobileApplicationLinks from '@/widgets/Footer/ui/MobileApplicationLinks/MobileApplicationLinks'
import { useQuery, } from '@apollo/client'
import { useEffect, useMemo, useState, } from 'react'
import { useDispatch, } from 'react-redux'


const GET_MOBILE_APP_VERSIONS = gql(`
  query FrontCheckVersion {
    build: utilConfig(domain:"mobile",key:"version")
    ios: utilConfig(domain:"mobile",key:"version.ios")
    android: utilConfig(domain:"mobile",key:"version.android")
  }
`)


const NewMobileAppVersionPopupMessage = () => {
  const [
    isPopupOpen,
    setIsPopupOpen,
  ] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { stateIos, stateAndroid, } = useAppSelector(store => store.mobileAppVersion)
  const { loading, data, } = useQuery(GET_MOBILE_APP_VERSIONS)

  const mobileAppVersions = useMemo(() => {
    if (!loading && data) {
      return ({
        buildIos: data?.build?.config?.ios?.version,
        buildAndroid: data?.build?.config?.android?.version,
        ios: data?.ios?.config?.version || null,
        android: data?.android?.config?.version || null,
      })
    }
  }, [
    data,
    loading,
  ])


  useEffect(() => {
    if (mobileAppVersions) {
      const {
        buildIos,
        buildAndroid,
        ios,
        android,
      } = mobileAppVersions

      if (buildIos === ios && stateIos !== ios) {
        setIsPopupOpen(true)
      } else if (buildAndroid === android && stateAndroid !== android) {
        setIsPopupOpen(true)
      }

      dispatch(setIosVersion(ios))
      dispatch(setAndroidVersion(android))
    }
  }, [
    dispatch,
    mobileAppVersions,
    stateAndroid,
    stateIos,
  ])


  return (
    <Popup
      handlePopup={ () => setIsPopupOpen(false) }
      isOpen={ isPopupOpen }
    >
      <>
        <TitleH3>
          Доступны новые версии мобильных приложений
        </TitleH3>

        <MobileApplicationLinks />
      </>
    </Popup>
  )
}


export default NewMobileAppVersionPopupMessage

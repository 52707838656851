import Popup from '@/legacy/components/common/Popup/Popup'
import ButtonSimple from '@/legacy/components/common/ButtonSimple/ButtonSimple'
import Persons from '@/legacy/components/common/PersonsInput/PersonsInput'
import InputTextDeprecated, { useInputText, } from '@/legacy/components/common/InputTextDeprecated/InputTextDeprecated'
import styles from './ChangeUserIdPopup.module.less'
import { useState, } from 'react'
import { roles, } from '../ProfilePopup/rolesCatalog'
import { useDispatch, useSelector, } from 'react-redux'
import { setRealUserGuid, } from '@/legacy/redux/FakeChageUserSlice/FakeChageUserSlice'


function ChangeUserIdPopup ({
  isOpen,
  handlePopup,
}: any): JSX.Element {
  return (
    <Popup
      isOpen={ isOpen }
      handlePopup={ handlePopup }
    >
      <PopupContent />
    </Popup>
  )
}


const PopupContent = () => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const testerUserGuid = useSelector(state => state?.auth?.me?.data?.guid)

  const [
    userGuid,
    setUserGuid,
  ] = useState(null)
  const dispatch = useDispatch()

  return (
    <div className="addStudentWrap">
      <h2 className="addStudentTitle">Сменить пользователя</h2>

      <InputWithPersons
        setUserGuid={ setUserGuid }
      />

      <br />
      <ButtonSimple
        onClickHandler={ () => {
          dispatch(setRealUserGuid(testerUserGuid))

          window.location.assign(`api/spy/set?id=${ userGuid }&redirect=/&roles=${ roles.map(
            role => role.name).join(',') }`)
        } }
        variant="alone"
        active
        value="Подтвердить"
        className={ styles.submitButton }
        isDisabled={ !userGuid }
      />
    </div>
  )
}


const InputWithPersons = ({
  setUserGuid,
}: any) => {
  const userInput = useInputText({ defaultValue: '', })
  const [
    currentField,
    setCurrentField,
  ] = useState(null)


  return <>
    <div className={ styles.inputWrapper }>
      <InputTextDeprecated
        debounceTimeout={ 1000 }
        defaultHint={ 'ФИО пользователя' }
        withPlaceholder
        // @ts-expect-error TS(2345): Argument of type '1' is not assignable to paramete... Remove this comment to see the full error message
        onClick={ () => setCurrentField(1) }
        { ...userInput }
      />

      { userInput?.value?.length > 3 && (
        <Persons
          className={ styles.personsWindow }
          field={ 1 }
          currentField={ currentField }
          mode="person"
          handler={ (person: any) => {
            setUserGuid(person.id)
            userInput.onChangeValue(person.full_name)
          } }
          setCurrentField={ setCurrentField }
          value={ userInput.value }
          paginatonLimit={ 10 }
        />
      ) }
    </div>
  </>
}


export default ChangeUserIdPopup

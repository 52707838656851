/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useState, } from 'react'
import clsx from 'clsx'
import './FullCardPopup.less'
import { IconCircleCheck, IconCircleX, IconPencil, } from '@tabler/icons'
import { format, } from 'date-fns'
import Image from '../../common/Image/Image'
import ru from 'date-fns/locale/ru'

function FullCardPopup ({
  isOpen,
  close,
  i,
  onApprove,
  onReject,
  status,
  edit,
  dataStat,
}: any) {
  const [
    isDeclineOpen,
    setIsDeclineOpen,
  ] = useState(false)


  const { name, description, activity, req_id, created,
    dateStart, dateEnd, supervisor, consultant, projectType,
    participants, reportForm, requirments, additional, customer, foreignCustomer, } = i?.data

  const mainInfo = [
    {
      title: 'Сроки реализации',
      value: `${ format(new Date(dateStart), 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(new Date(dateStart), 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(new Date(dateStart), 'dd MMMM yyyy', { locale: ru, }).slice(4) } - ${ format(new Date(dateEnd), 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(new Date(dateEnd), 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(new Date(dateEnd), 'dd MMMM yyyy', { locale: ru, }).slice(4) }`,
    },
    {
      title: 'Тип проекта',
      value: projectType,
    },
    {
      title: 'Число участников',
      value: participants,
    },
    {
      title: 'Форма отчета',
      value: reportForm,
    },
  ]

  const fullInfo = [
    {
      title: 'Краткое описание проекта (цели, задачи, планируемые результаты)',
      value: description,
    },
    {
      title: 'Требования к студентам, участникам проекта (направление подготовки, специальность, личные характеристики)',
      value: requirments,
    },
    {
      title: 'Дополнительная информация',
      value: additional,
    },
    {
      title: 'Заказчик',
      value: customer ? customer : foreignCustomer,
    },
  ]

  function reject () {
    onReject()
    /*  setIsDeclineOpen(!isDeclineOpen); */
  }

  return (
    <div className={ clsx('K-LecturePop-Close', isOpen && 'K-LecturePop') }>
      <div className=" FullCard" style={ {
        width: '75%',
        height: '85%', /* maxWidth: "1440px", minWidth: '1300px' */
      } } onClick={ e => e.stopPropagation() }>
        <button className="close" onClick={ close }>x</button>
        <div className="ContentWrap">
          <h2>{ name }</h2>
          <div className="popupHeader">
            <div className="teacher">
              <Image id={ supervisor?.id } /* style={{width: "24px", height: "24px", borderRadius: "20px"}} */ />
              <div>
                <p className="subtitle">Руководитель</p>
                <p className="value">{ supervisor?.full_name }</p>
              </div>
            </div>
            { (status === 'wait' || dataStat === 'new') && <div className="doButtons">
              <button className="statusButton decline moder" onClick={ edit }>Редактировать<IconPencil color="#7671DD" /></button>
              <button className="statusButton decline moder" onClick={ () => setIsDeclineOpen(!isDeclineOpen) }>Отклонить<IconCircleX color="#FF5E5E" /></button>
              <button className="statusButton buttonActive moder" onClick={ onApprove }>Принять<IconCircleCheck /></button>
            </div> }
          </div>
          <div className={ clsx('declineWrap', !isDeclineOpen && 'declineHidden') }>
            <textarea placeholder='По какой причине вы хотите отклонить заявку?' onClick={ e => e.stopPropagation() } />
            { isDeclineOpen && <div className="btWrap">
              <button onClick={ () => setIsDeclineOpen(!isDeclineOpen) }>Отменить</button>
              <button style={ {
                background: '#FF5E5E',
                color: 'white',
              } } onClick={ onReject }>Отклонить<IconCircleX color="white" /></button>
            </div> }
          </div>
          <div className="mainInfo">
            { mainInfo.map(i => <div key={ mainInfo.indexOf(i) }>
              <h3>{ i.title }</h3>
              <p>{ i.value }</p>
            </div>) }
          </div>
          <h4>Консультанты</h4>
          <ul className="consalts">
            {
              consultant.map((i: any) => <li key={ i.full_name }><div><Image id={ i.id } /* style={{width: "24px", height: "24px", borderRadius: "20px"}} */ />{ i.full_name }</div></li>)
            }
          </ul>
          { fullInfo.map(i => <div key={ fullInfo.indexOf(i) } className="infoSection">
            <h4>{ i.title }</h4>
            <p className="article">{ i.value }</p>
          </div>) }
        </div>
        <div className="vigorLayout" />
        <div className="cardId">
          <p>{ `ID: ${ i.req_id }` }</p>
          <p>{ format(new Date(i.updated), 'yyyy.MM.dd') }</p>
        </div>
      </div>
    </div>
  )
}

export default FullCardPopup

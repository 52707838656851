import clsx from 'clsx'
import '../../NewProject.less'

const SubmissionSection = ({ disabled, editProject, onProjectEdit, onModerSubmit, }:any) => {
  return (
    <>
      {
        editProject?.name ? (
          <button
            className={ clsx(
              (!disabled) && 'statusButton buttonActive',
              (disabled) && 'disabled'
            ) }
            onClick={ onProjectEdit }
            disabled={ disabled }
          >
                Отредактировать
          </button>
        ) : (
          <button
            className={ clsx(
              (!disabled) && 'statusButton buttonActive',
              (disabled) && 'disabled'
            ) }
            onClick={ onModerSubmit }
            disabled={ disabled }
          >
                Отправить на модерацию
          </button>
        )
      }
    </>
  )
}

export default SubmissionSection

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react'
import { IconArrowBarToDown, } from '@tabler/icons'
import { Row, Col, } from 'antd'
import './ReferenceTemplate.less'
import { format, } from 'date-fns'
import clsx from 'clsx'
import ru from 'date-fns/locale/ru'
/* import { URL } from '../../../Utils/constants';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'; */

const ApplicationTemplate = ({ content, }: any) => {
  const { statusName, comment, created, } = content
  const { statementName, AttachedDocument, } = content.data.StatementsDetails

  const dateCreate = format(new Date(created), 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(new Date(created), 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(new Date(created), 'dd MMMM yyyy', { locale: ru, }).slice(4)
  const timeCreate = format(new Date(created), 'HH:mm')


  return (<div className="reference">
    <div className="titleWrap">
      <h3>{ statementName }</h3>
      <div className="status">
        <p className={ `stuBut new` }>{ statusName }</p>
      </div>
    </div>
    <Row>
      <Col span={ 12 }>
        <h4>Дата и время заказа</h4>
        <p className="subtitleRef">{ `${ timeCreate } . ${ dateCreate }` }</p>
      </Col>
      {/* <Col span={ 12 }>
        <h4>Комментарий от модератора</h4>
        <p className="subtitleRef">{ comment ? comment : 'Че делаешь?' }</p>
      </Col> */}
    </Row>
    { AttachedDocument &&
      <div>
        <button>Приложенные документы<IconArrowBarToDown className={ clsx('download') } /></button>
        <ul>{ AttachedDocument?.map((i: any, index: number) => <li key={ index }>{ i.name }</li>) }</ul>
      </div> }
  </div>)
}

export default ApplicationTemplate

import clsx from 'clsx'
import styles from './GeneralElements.module.less'


export const NewPageWrapper = (props: any) => <div className={ clsx(styles.wrapper, props.className) }>
  { props.children }
</div>


export const TitleH2 = (props: any) => <h2
  className={ clsx({
    [styles.h2]: true,
    [styles.newPageTitleMargin]: props.newPageHeaderTitle,
  }) }
  style={ props.style }>
  { props.children }
</h2>


export const TitleH3 = (props: any) => <h3 className={ clsx(styles.h3, props.className) } style={ props.style }>
  { props.children }
</h3>


export const TitleH4 = (props: any) => <h4 className={ clsx(styles.h4, props.className) } style={ props.style }>
  { props.children }
</h4>


export const TitleH5 = (props: any) => <h5 className={ styles.h5 } style={ props.style }>
  { props.children }
</h5>

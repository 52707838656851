import './AchivmentCard.less'
import { IconArrowRight, } from '@tabler/icons'
import clsx from 'clsx'
import styles from './AchivmentCard.module.less'


function AchivmentCard ({
  setIsDetails,
  achievementDirection,
  type,
  style,
  currentStudentRating,
  notificationsCount = null,
  additionalCardData,
}: any) {
  return (
    <div
      className="achivmentCard"
      onClick={ () => {
        if (achievementDirection?.name !== 'Средний балл зачетной книжки') {
          return setIsDetails()
        }
      } }
      style={ style }
    >
      <div>
        <div className={ styles.headerWrapper }>
          <h2>{ type !== 'stranger' ? achievementDirection.name : achievementDirection.direction }</h2>

          { notificationsCount && (
            <span className={ styles.notificationsCount }>{ notificationsCount }</span>
          ) }
        </div>

        { (achievementDirection?.name === 'Средний балл зачетной книжки' && additionalCardData) && (
          <ul>
            { additionalCardData.map(
              (item: any, index: any) => (
                <li key={ item?.name || index }>
                  <div className="cardField">
                    { `${ item?.title }: ${ item.score }` }
                  </div>
                </li>
              )) }
          </ul>
        ) }

        { (achievementDirection?.name === 'Работы обучающегося' && additionalCardData) && (
          <ul>
            { additionalCardData.map(
              (item: any, index: any) => (
                <li key={ item?.name || index }>
                  <div className="cardField">
                    { `${ item?.title }: ${ item.amount }` }
                  </div>
                </li>
              )) }
          </ul>
        ) }

        { !/^Работы обучающегося$|^Средний балл зачетной книжки$/.test(achievementDirection?.name) && (
          currentStudentRating ? (
            <ul>
              <li>
                <div className="cardField" style={ {
                  color: 'white',
                  background: '#7671DD',
                } }>
                  { `Место в рейтинге: ${ type === 'stranger'
                    ? achievementDirection?.position
                    : currentStudentRating?.position }/${ currentStudentRating?.totals }` }
                </div>
              </li>
              <li>
                <div className="cardField">
                  { `Общая сумма баллов: ${ type !== 'stranger'
                    ? currentStudentRating?.rating?.all
                    : Number(achievementDirection?.rating?.available) }` }
                </div>
              </li>

              { type !== 'stranger' && <li>
                <div className={ clsx('cardField', 'cardField_full-width') }>
                  Сумма баллов, учитывающаяся <br /> в рейтинге: { Number(currentStudentRating?.rating?.available) }
                </div>
              </li> }
            </ul>
          ) : (
            <div className="cardField">
              Нет достижений для участия в рейтинге по данному направлению
            </div>
          )
        ) }
      </div>

      {
        achievementDirection?.name !== 'Средний балл зачетной книжки' && (
          <div className="visitAchiv">
            <p>Перейти</p>

            <div className="arrowCircle">
              <IconArrowRight color="white" size={ 30 } />
            </div>
          </div>
        )
      }
    </div >
  )
}


export default AchivmentCard

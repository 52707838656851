import { useSelector, } from 'react-redux'
import { useAchievements, } from '../useAchievements'


const useGetNotifications = () => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserGuid = useSelector(state => state.auth.me.data.guid)
  const { data, loading, } = useAchievements({
    filter: { id: currentUserGuid, },
    limit: 100,
  })
  const result = {}


  if (data && !loading) {
    const filteredData = data.achievements.items
      .filter((achievement: any) => {
        const comments = achievement?.comment

        if (!!comments && achievement.status === 'rejected') {
          const commentsArray = Object.values(comments)
          const firstCommentFromArray = commentsArray[0]
          const firstMessageFromObject = comments[0]?.message

          if (
            commentsArray.length > 0
            && ((
              typeof firstCommentFromArray === 'string'
              && firstCommentFromArray?.length > 0
            )
              || (
                firstMessageFromObject?.length > 0
              ))) {
            return true
          }
        } return false
      })


    filteredData.forEach((item: any) => {
      const categoryName = item?.catid
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[categoryName] = result[categoryName] ? Number(result[categoryName]) + 1 : 1
    })
  }


  return result
}


export default useGetNotifications

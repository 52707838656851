import AddStudentResultInput from '@/legacy/components/Projects/ProjectPage/InfoProject/components/AddStudentResultInput/AddStudentResultInput'
import { OnUpdateProjectPayload, ProjectStudentResults, } from '@/legacy/gql/hooks/projects/useUpdateProjectTypes'
import { UUID, } from '@/legacy/types'


interface ResultsBlockForStudentProps {
  projectId: number,
  userGuid: UUID,
  studentResults: ProjectStudentResults | undefined,
  projectPayloadData: OnUpdateProjectPayload,
}


function ResultsBlockForStudent ({
  projectId,
  userGuid,
  studentResults,
  projectPayloadData,
}: ResultsBlockForStudentProps) {
  return (
    <div className="projResults">
      <div className="projResults__header">
        <>
          <h2>Результаты моей работы</h2>
          { (studentResults?.status !== ('approved' || 'pending' || 'rejected') && (studentResults?.status !== undefined) && (studentResults?.status !== '')) && <span>{`Оценка за выполненный проект: ${studentResults?.status}`}</span>}
          { studentResults?.status === 'pending' && <span>Ожидает одобрения</span> }
          { studentResults?.status === 'approved' && <span>Одобрены руководителем</span> }
          { studentResults?.status === 'rejected' && <span>Не одобрены руководителем</span> }
        </>
      </div>

      <AddStudentResultInput
        projectId={ projectId }
        studentResults={ studentResults }
        projectPayloadData={ projectPayloadData }
        responderGuid={ userGuid }
      />
    </div>
  )
}

export default ResultsBlockForStudent

import { gql } from '@/__generated__'
import {useQuery} from "@apollo/client";

interface WorkspaceIcon {
  iconDescription: string;
  iconID: string;
  iconImage: string;
  iconLink: string;
  iconName: string;
  iconSort: number;
}

interface WorkspaceIconsData {
  getWorkspaceIconsByCurrentUser: WorkspaceIcon[];
}

const GET_WORKSPACE_ICONS_LIST = gql(`
  query GetWorkspaceIconsByCurrentUser {
      getWorkspaceIconsByCurrentUser {
        iconDescription
        iconID
        iconImage
        iconLink
        iconName
        iconSort
      }
    }
`)

const useGetWorkspaceIconsList = () => {
  const {data, error, loading} = useQuery<WorkspaceIconsData>(GET_WORKSPACE_ICONS_LIST)
  return {
    loading,
    error,
    workspaceIcons: data?.getWorkspaceIconsByCurrentUser
  }
}

export default useGetWorkspaceIconsList

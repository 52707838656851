/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useMemo, useState, } from 'react'
import './Payouts.less'
import { IconChevronDown, IconChevronUp, IconArrowDownLeft, IconArrowUpRight, } from '@tabler/icons'
import { Table, } from 'antd'
import { usePeriods, } from '../../../../gql/hooks/useFinances'
import clsx from 'clsx'
import { format, } from 'date-fns'
import { ru, } from 'date-fns/locale'
import { useScolarPeriod, } from '../../../../gql/hooks/useFinances'
import NoContracts from '../../PaymentPage/NoContracts/NoContracts'
import { Preloader } from '@/shared'

function Payouts ({
  filtStatus,
}: any) {
  const [
    period,
    setPeriod,
  ] = useState()
  const [
    isOpen,
    setIsOpen,
  ] = useState<any>()

  const { data, error, loading, } = useScolarPeriod({
    period: period,
    mode: 'all',
  })

  // console.log('all',  format(new Date(data?.accruedScholarship[0]?.period.replaceAll('.', ',').split(',').reverse().join()), "yyyy-MM"))
  const pays = useMemo(() => {
    if (data && data?.finAccruedScholarship?.length > 0) {
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      !period && setPeriod(format(new Date(data?.finAccruedScholarship[0]?.period/* .replaceAll('.', ',').split(',').reverse().join() */), 'yyyyMM'))
      return data?.finAccruedScholarship[0].blocks
    } else if (error) {
      return 'error'
    } else if (loading) {
      return 'loading'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
  ])


  function ScholarShips (): JSX.Element {
    const dormitoryTable = useMemo(() => {
      const columns = [
        {
          title: 'Тип',
          dataIndex: 'period',
          key: 'period',
          width: 300,
        },
        {
          title: 'Вид',
          dataIndex: 'type',
          key: 'type',
          width: 600,
        },
        {
          title: 'Сумма',
          dataIndex: 'summ',
          key: 'summ',
          width: 300,
        },
      ]
      const content: any = []

      if (loading && !data) {
        return <div>......loading</div>
      } else if (error) {
        return <div>error</div>
      }
      pays?.block_accruals.forEach((i: any) => {
        content.push({
          key: pays?.block_accruals.indexOf(i),
          type: i.name,
          summ: `${ i.sum } руб.`,
          period: <div style={ { display: 'flex', } }>{ <div className="acur"><IconArrowUpRight color={ 'white' } size={ 15 } /></div> }Начисление</div>,
        })
      })

      pays?.block_holdings?.forEach((i: any) => {
        content.push({
          key: (pays?.block_holdings.indexOf(i) + 1) * 100,
          type: i.name,
          summ: `${ i.sum } руб.`,
          period: <div style={ { display: 'flex', } }>{ <div className="debt"><IconArrowDownLeft color={ 'white' } size={ 15 } /></div> }Удержание</div>,
        })
      })

      return content?.length > 0 ? <div className="scol"><Table pagination={ false } columns={ columns } dataSource={ content } /></div> : (loading ? <Preloader /> : <NoContracts title="Информация отсутствует" />)


      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      data,
      error,
      loading,
    ])
    return (dormitoryTable)
  }


  function Charges (): JSX.Element {
    const dormitoryTable = useMemo(() => {
      const columns = [
        {
          title: 'Тип выплаты',
          dataIndex: 'period',
          key: 'period',
          width: 300,
        },
        {
          title: 'Дата выплаты',
          dataIndex: 'date',
          key: 'date',
          width: 600,
        },
        {
          title: 'Сумма',
          dataIndex: 'summ',
          key: 'summ',
          width: 300,
        },
      ]


      if (loading && !data) {
        return <div>.......loading</div>
      }
      const content = pays?.block_payout.map((i: any) => {
        return {
          key: pays?.block_payout.indexOf(i),
          date: format(new Date(i.date), 'yyyy.MM.dd'),
          summ: `${ i.sum } руб.`,
          period: i.name,
        }
      })

      return content?.length > 0 ? <div className="scol"><Table columns={ columns }
        dataSource={ content.reverse() } pagination={ false } /></div> : (loading ? <Preloader /> : <NoContracts title="Информация отсутствует" />)


      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      data,
      error,
      loading,
    ])
    return (dormitoryTable)
  }

  function PeriodsPopup () {
    const { data, loading, error, } = usePeriods({ mode: 'all', })
    // console.log('per', format(new Date(data?.accruedScholarshipPeriods[0]), "yyyy-MM"))

    const periods = useMemo(() => {
      if (data) {
        return data.finAccruedScholarship.map((i: any, index: number) => {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          return <li onClick={ () => setPeriod(format(new Date(i.period), 'yyyyMM')) } key={ index } className="refPopupItem">{ format(new Date(i.period), 'LLLL yyyy', { locale: ru, })[0].toUpperCase() + format(new Date(i.period), 'LLLL yyyy', { locale: ru, }).slice(1) }</li>
        })
      } else if (error) {
        // @ts-expect-error TS(2322): Type 'ApolloError' is not assignable to type 'Reac... Remove this comment to see the full error message
        return <div>{ error }</div>
      } else if (loading) {
        return <Preloader />
      }
    }, [
      data,
      error,
      loading,
    ])

    return <ul className={ clsx('refPopup', !isOpen && 'hidden') }>
      { periods }
    </ul>
  }


  return (
    data?.finAccruedScholarship.length > 0 ? <>
      <div className="K-ChooseSpec" onClick={ () => setIsOpen(!isOpen) }>{ format(new Date(data?.finAccruedScholarship[0]?.period), 'LLLL yyyy', { locale: ru, })[0].toUpperCase() + format(new Date(data?.finAccruedScholarship[0]?.period), 'LLLL yyyy', { locale: ru, }).slice(1) }{ isOpen ? <IconChevronUp style={ { margin: '0 0 0 15px', } } size={ 20 } /> : <IconChevronDown style={ { margin: '0 0 0 15px', } } size={ 20 } /> }
        <PeriodsPopup />
      </div>
      <><h3 className="scolTitle">Начисления и удержания</h3>
        <ScholarShips />
        <h3 className="scolTitle" style={ { margin: '30px 0 0 0', } }>Выплаты</h3>
        <Charges /> </>
    </> : (loading ? <Preloader /> : <NoContracts title="Информация о выплатах отсутствует" />)
  )
}

export default Payouts

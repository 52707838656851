/** @deprecated этот компонент ужасен, не надо его использовать, он будет выпилен из проекта */
import './InputSelectDeprecated.less'
import { IconChevronUp, IconChevronDown, } from '@tabler/icons'
import { memo, useEffect, useState, } from 'react'
import clsx from 'clsx'

/** @deprecated этот компонент ужасен, не надо его использовать, он будет выпилен из проекта */
const InputSelectDeprecated = ({
  className,
  styles,
  items = [
    'empty',
  ],
  defaultHint,
  defaultValue = false,
  callBack,
  withResetValue = false,
  withListFixedPosition,
  withListAbsolutePosition,
  isMultiple,
  isDisabled,
}: any) => {
  const [
    isOpen,
    setIsOpen,
  ] = useState(false)
  const [
    selectedValue,
    setSelectedValue,
  ] = useState(
    isMultiple
      ? (defaultValue && Array.isArray(defaultValue)) ? [
        ...defaultValue,
      ] : []
      : null
  )

  useEffect(() => {
    if (withResetValue) {
      setSelectedValue(isMultiple ? [] : null)
    }
  }, [
    defaultValue,
    isMultiple,
    withResetValue,
  ])

  const onClickHandler = (value: any) => {
    if (isMultiple) {
      // @ts-expect-error TS(2345): Argument of type 'never[] | null' is not assignabl... Remove this comment to see the full error message
      if (new RegExp(value).test(selectedValue)) {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        setSelectedValue(selectedValue.filter(item => item !== value))
      } else {
        setSelectedValue([
          // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
          ...selectedValue,
          value,
        ])
      }
    } else {
      setSelectedValue(value)
    }

    callBack(value)
  }

  return (
    <button
      disabled={ isDisabled }
      className={ clsx(
        {
          'input-select': true,
          'input-select_with-value': selectedValue || defaultValue,
        },
        className
      ) }
      style={ styles }
      onClick={ () => {
        if (isMultiple && !isOpen) {
          setIsOpen(true)
        }

        if (!isMultiple) {
          setIsOpen(!isOpen)
        }
      } }
      onBlur={ () => setIsOpen(false) }
    >
      <div
        onClick={ () => {
          if (isMultiple) {
            setIsOpen(!isOpen)
          }
        } }
        style={ {
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        } }
      >
        <div className="input-select__inner">
          <span
            className={ clsx({
              'input-select__label-hint': true,
              'input-select__label-hint_lifted': selectedValue || defaultValue,
            }) }
          >
            { defaultHint }
          </span>

          <span className={ 'input-select__selected-value' }>
            { selectedValue
              ? Array.isArray(selectedValue)
                ? selectedValue.join(', ')
                : selectedValue
              : defaultValue }
          </span>
        </div>

        { isOpen ? <IconChevronUp /> : <IconChevronDown /> }
      </div>

      { isOpen ? (
        <ItemList
          items={ items }
          onClickHandler={ onClickHandler }
          withListFixedPosition={ withListFixedPosition }
          withListAbsolutePosition={ withListAbsolutePosition }
          isMultiple={ isMultiple }
        />
      ) : (
        false
      ) }
    </button>
  )
}

export const ItemList = ({
  items,
  onClickHandler,
  withListFixedPosition,
  withListAbsolutePosition,
}: any) => (
  <div
    className={ clsx({
      'input-select__opened-list': true,
      'input-select__opened-list_with-fixed-position': withListFixedPosition,
      'input-select__opened-list_with-absolute-position':
        withListAbsolutePosition,
    }) }
  >
    { items.map((item: any) => (
      <span
        className="input-select__option-choose"
        key={ item }
        onClick={ () => onClickHandler(item) }
      >
        { item }
      </span>
    )) }
  </div>
)

/** @deprecated этот компонент ужасен, не надо его использовать, он будет выпилен из проекта */
export default memo(InputSelectDeprecated)

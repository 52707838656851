import { useMemo, } from 'react'
import './QrPopup.less'
import clsx from 'clsx'
import { usePaymentQRCode, } from '../../../gql/hooks/useFinances'
import { QRCode, } from 'antd'
import { Preloader, } from '@/shared'

function QrPopup({ type, contract, isOpen, handleOpen, name, }: any) {
  const { data, error, loading, } = usePaymentQRCode({
    contract,
    type,
  })
  const qr = useMemo(() => {
    if (data) {
      const value = contract ? data.quittances : 'sdwfve'
      return <QRCode value={value} size={250} />
    } else if (error) {
      return <div>произошла ошибка, попробуйте позднее</div>
    } else if (loading) {
      return <Preloader />
    }
  }, [
    data,
    error,
    loading,
    contract,
  ])

  const onImgClick = () => {
    const canv = document.querySelector('canvas')
    const newCanv = document.createElement('canvas')
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    newCanv.height = canv.height + 50
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    newCanv.width = canv.width
    const newContext = newCanv.getContext('2d')
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    newContext.drawImage(canv, 10, 70, 250, 250)
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    newContext.font = '15px monrope'
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    newContext.fillText(`QR код по договору № ${contract}`, 10, 20)
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    newContext.fillText(`Банк: ${name}`, 10, 50)

    const base = newCanv.toDataURL('image/jpg')
    const img = new Image()
    img.onload = () => {
      const save = document.createElement('a')
      save.href = img.src
      save.target = '_blank'
      save.download = `Договор№${contract}-${name}.jpg`
      save.click()
    }
    img.src = `${base}`
    handleOpen()
  }

  return (
    <div className={clsx('successHidden', isOpen && 'sucessWrap')}>
      <div className="sucess qr" style={{ position: 'relative', }}>
        <button className="close" onClick={handleOpen}>
          x
        </button>
        <h2>{`QR код по договору № ${contract}`}</h2>
        <h2>{`Банк: ${name}`}</h2>
        {qr}
        <button className="activeBut" onClick={onImgClick}>
          Скачать
        </button>
      </div>
    </div>
  )
}

export default QrPopup

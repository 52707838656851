import './EditPopup.less'


function EditPopup ({
  close,
  name,
  mark,
  date,
}: any): JSX.Element {
  return (
    <div onClick={ close } className="JoEditPopup">
      <div className="editContainer" onClick={ e => e.stopPropagation() }>
        <h2>Поставить отметку</h2>

        <div>
          <h3>студент</h3>
          <p>{ name }</p>
        </div>

        <div>
          <h3>дата</h3>
          <p>{ date }</p>
        </div>

        <label>
          <input type="checkbox" />
          Не присутствовал
        </label>

        <input type="number" min="2" max="5" className="mark" defaultValue={ mark } />

        <button>Поставить отметку</button>
      </div>
    </div>
  )
}


export default EditPopup

import React from 'react'
import {
  IconAward,
  IconBook,
  IconBulb,
  IconTrendingUp,
  IconUser,
  TablerIcon,
  IconWallet,
  IconWorld,
  IconPlayerPlay,
  IconSchool,
  IconNotes,
  IconTrophy,
  IconSquareCheck,
  IconShield,
  IconMedal,
  IconFileInvoice,
  IconNews,
  IconAlertCircle,
  IconUsers,
} from '@tabler/icons'
import AccordionInner from '../AccordionInner/AccordionInner'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { UUID, } from '@/legacy/types'

interface Achievement {
  id: string;
  major: number;
  minor: number;
  revision: number;
  achievementTypeName: string;
  achievementTypeIcon?: string;
  scores?: number;
  max_scores?: number;
  achievementType?: EcTableName;
  children?: Achievement[];
}

interface AchievementAccordionProps {
  achievement: Achievement;
  moderateId: UUID;
  stage:any;
  handlers:any;
}

interface IconMap {
  [key: string]: TablerIcon;
}

const iconMap: IconMap = {
  IconBook: IconBook,
  IconAward: IconAward,
  IconBulb: IconBulb,
  IconTrendingUp: IconTrendingUp,
  IconUser: IconUser,
  IconWallet: IconWallet,
  IconWorld: IconWorld,
  IconPlayerPlay: IconPlayerPlay,
  IconSchool: IconSchool,
  IconNotes: IconNotes,
  IconTrophy: IconTrophy,
  IconSquareCheck: IconSquareCheck,
  IconShield: IconShield,
  IconMedal: IconMedal,
  IconFileInvoice: IconFileInvoice,
  IconNews: IconNews,
  IconUsers: IconUsers,
  IconAlertCircle: IconAlertCircle,
}

const getIconByName = (name: string): TablerIcon | undefined => {
  return iconMap[name]
}

const AchievementAccordion: React.FC<AchievementAccordionProps> = ({ achievement, moderateId, stage, handlers, }) => {
  const { achievementTypeName, achievementTypeIcon, scores, max_scores, children, achievementType: dataType, } = achievement

  return (
    <AccordionInner
      headerText={achievementTypeName}
      Icon={achievementTypeIcon ? getIconByName(achievementTypeIcon) : undefined}
      iconProps={{ src: achievementTypeIcon ?? '', }}
      subHeaderText={`${scores} / ${max_scores}`}
      moderateId={moderateId}
      dataType={dataType as EcTableName}
      stage={stage}
      handlers={handlers}
    >
      {children &&
          children.map(childAchievement => (
            <AchievementAccordion
              key={childAchievement.id}
              achievement={childAchievement}
              moderateId={moderateId}
              stage={stage}
              handlers={handlers}
            />
          ))}
    </AccordionInner>
  )
}

export default AchievementAccordion

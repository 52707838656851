import styles from './ModerTabs.module.less'
import { useMemo, useState, } from 'react'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import RadioButtonFacade from '../../../../common/RadioButtonFacade/RadioButtonFacade'
import { useEffect, } from 'react'
import useGetDirectionsWithAchievements from '../../../../../gql/hooks/portfolio/useGetDirectionsWithAchievements'
import { useSelector, } from 'react-redux'
import useGetSortedAchievementDirections from '../../../../../gql/hooks/portfolio/useGetAchievementDirectionsOrder'


function ModerTabs ({
  status,
  returnDirection,
}: any) {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studentId = useSelector(state => state.port.ratingId.id)
  const directions = useGetDirectionsWithAchievements({
    id: studentId,
    available: true,
    status,
  })
  const [
    activeIndex,
    setActiveIndex,
  ] = useState(0)
  const [
    direction,
    setDirection,
  ] = useState(null)
  const { sortedDirections, } = useGetSortedAchievementDirections({ directions, })


  const tabs = useMemo(() => {
    if (sortedDirections) {
      const buttonsContent = sortedDirections
        .map((button: any) => ({
          text: button.name,
        }))

      if (buttonsContent?.length) {
        setDirection(buttonsContent[activeIndex]?.text)
      } else {
        return (
          <>
            У данного пользователя нет достижений
          </>
        )
      }

      return (
        <RadioButtonFacade
          content={ buttonsContent }
          BtnComponent={ ButtonSimple }
          activeIndex={ activeIndex }
          setActiveIndex={ setActiveIndex }
        />
      )
    }
  }, [
    sortedDirections,
    activeIndex,
  ])


  useEffect(() => {
    returnDirection(direction)
  }, [
    direction,
    returnDirection,
  ])


  return (
    <div className={ styles.wrapper }>
      { tabs }
    </div>
  )
}


export default ModerTabs

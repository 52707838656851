import clsx from 'clsx'
import { forwardRef, } from 'react'
import './PaperContainerWrapper.less'


/**
 *
 * @param { 'sub-paper' } variant
 */
const PaperContainerWrapper = ({
  variant,
  style,
  className,
  ...props
}: any, ref: any) => (
  <div
    ref={ ref }
    className={ clsx({
      'paper-container': true,
      [`paper-container_${ variant }`]: variant,
    }, className) }
    style={ style }
    { ...props }
  >
    { props.children }
  </div>
)


export default forwardRef(PaperContainerWrapper)

import React from 'react'
import './AddStudentItem.less'
import Image from '../../../../../../common/Image/Image'
// import Checkbox from '@mui/material/Checkbox';
// import { Checkbox } from 'antd';
import clsx from 'clsx'


const AddStudentItem = ({
  i,
  newStudents,
  setNewStudents,
}: any) => {
  const isChecked = () => {
    return newStudents.find((it: any) => i.id === it.id)
  }

  const toggleAddStudent = () => {
    if (!isChecked()) {
      setNewStudents([
        ...newStudents,
        i,
      ])
    } else {
      setNewStudents(newStudents.filter((item: any) => item.full_name !== i.full_name))
    }
  }

  return (
    <div className={ clsx('addStudentItem', isChecked() && 'addStudentItem__checked') } onClick={ () => toggleAddStudent() }>
      <div style={ { display: 'flex', } }>
        <Image id={ i.id } style={ {
          width: '48px',
          height: '48px',
          borderRadius: '8px',
        } } />
        <div className="nameWrap">
          <h2>{ i.full_name }</h2>
          <p>{ `${ i.course } курс` }</p>
        </div>
      </div>
      <div className={ clsx('custom-circleCheck', isChecked() && 'custom-circleCheck_active') } />
      {/*  <Checkbox /> */ }
    </div>
  )
}


export default AddStudentItem

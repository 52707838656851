import InputTextDeprecated, { useInputText, } from '../../../../../common/InputTextDeprecated/InputTextDeprecated'
import { InputsWrapper, } from '../../../MainInfo/MainInfo'
import styles from './CriteriaInputs.module.less'


const CriteriaInputs = ({
  type,
  parentIndex,
  index,
  field,
  fieldType,
  name,
  scores,
  onChangeInputValue,
  ...rest
}: any) => {
  const nameInput = useInputText({
    initialValue: name,
    isLazyData: true,
  })
  const scoresInput = useInputText({
    initialValue: !/select$/.test(type) ? String(scores?.amount || 0) : String(rest.option.scores?.amount || 0),
    isLazyData: true,
  })

  return (
    <InputsWrapper>
      <InputTextDeprecated
        defaultHint={ 'Название' }
        withPlaceholder
        { ...nameInput }
        onBlur={ () => onChangeInputValue({
          field,
          fieldType,
          parentIndex,
          index,
          subfield: 'name',
          newValue: nameInput.value,
        }) }
      />

      <div className={ styles.scoresWrapper }>
        <InputTextDeprecated
          numbersOnly
          defaultHint={ 'Баллы' }
          withPlaceholder
          { ...scoresInput }
          onBlur={ () => onChangeInputValue({
            field,
            fieldType,
            parentIndex,
            index,
            subfield: 'scores',
            newValue: Number(scoresInput.value),
          }) }
        />
      </div>
    </InputsWrapper>
  )
}


export default CriteriaInputs

import './Tab.less'

import PaperContainerWrapper from '../../../../common/PaperContainer/PaperContainerWrapper'
import Body from './TabComponents/Body'
import Header from './TabComponents/Header'


const Tab = ({
  attachedFiles,
  role,
  type,
  nameSpec,
}: any) => {
  return (
    <>
      <PaperContainerWrapper>
        <Header
          role={ role }
          type={ type }
          attachedFiles={ attachedFiles }
          nameSpec={ nameSpec }
        />

        { type !== 'additional' ? (
          <Body role={ role } type={ type } nameSpec={ nameSpec } />
        ) : false }
      </PaperContainerWrapper>

      { type === 'additional' ? (
        <Body role={ role } type={ type } />
      ) : false }
    </>
  )
}


export default Tab

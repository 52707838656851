/**
 * Компонент для ввода текста.
 * Для оптимизации ввода текста, под капотом лежит DebounceInput.
 *
 * @param title - Является лейблом для поля ввода
 * @param value - Значение поля ввода
 * @param type - Тип определяющий вводимые в input данные (по умолчанию 'text')
 * @param disabled - Блокирует input
 * @param debounceTimeout - Таймаут для debounce функции, по умолчанию 400 ms
 * @param className - Внешний CSS класс
 * @param onChange - Callback для события onChange для input
 * @param onBlur - Callback для события onBlur для input
 * @returns `JSX element`
 */


import styles from './InputText.module.less'
import { DebounceInput, } from 'react-debounce-input'
import { ChangeEventHandler, FocusEventHandler, } from 'react'
import InputTextLable from '@/shared/ui/InputTextLable/InputTextLable'


export interface InputTextProps {
  value: string
  type?: 'text' | 'number' | 'email' | 'password' | 'tel' | 'url' | 'date' | 'time' | 'datetime-local' | 'month' | 'week' 
  title?: string
  disabled?: boolean
  placeholder?: string;
  debounceTimeout?: number
  className?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}


const InputText = ({
  value,
  onChange,
  type = 'text',
  title,
  placeholder = '',
  disabled,
  onBlur,
  debounceTimeout = 400,
  className,
}: InputTextProps) => (
  <InputTextLable
    title={ title }
    className={ className }
  >
    {
      type === 'text'
        ? <DebounceInput
          debounceTimeout={ debounceTimeout }
          className={ styles.input }
          type={ type }
          value={ value }
          onChange={ event => onChange && onChange(event) }
          placeholder={placeholder} 
          disabled={ disabled }
          onBlur={ onBlur }/>
        : <DebounceInput
          debounceTimeout={ debounceTimeout }
          className={ styles.input }
          type={ type }
          value={ value }
          onChange={ event => onChange && onChange(event) }
          placeholder={placeholder} 
          disabled={ disabled }
          onBlur={ onBlur }
          min='0'
        />
    }
  </InputTextLable>
)


export default InputText

import { useCallback, } from 'react'
import { Navigate, } from 'react-router-dom'
import { useLoggedState, } from '../../gql/hooks/useSystem'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'


const ProtectedRoute = ({
  element: Component,
  roles, /* , ...props */
}: any) => {
  useLoggedState()

  const role = useGetRole()

  const isRoute = useCallback(() => {
    return roles.includes(role)
  }, [
    role,
    roles,
  ])
  const is_route = isRoute()
  return (
    is_route ? <Component /* {...props} */ /> : <Navigate to="/" replace />
  )
}

export default ProtectedRoute

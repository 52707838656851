import { createSlice, PayloadAction, } from '@reduxjs/toolkit'


const initialState: {
  stateIos: string | null,
  stateAndroid: string | null,
} = {
  stateIos: null,
  stateAndroid: null,
}


export const mobileAppVersionSlice = createSlice({
  name: 'mobileAppVersion',
  initialState,
  reducers: {
    setIosVersion: (state, action: PayloadAction<string>) => {
      state.stateIos = action.payload
    },

    setAndroidVersion: (state, action: PayloadAction<string>) => {
      state.stateAndroid = action.payload
    },
  },
})


export const {
  setIosVersion,
  setAndroidVersion,
} = mobileAppVersionSlice.actions

export default mobileAppVersionSlice.reducer

import { useEffect, useState, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import useGetInstitutes from '../../gql/hooks/common/useGetInstitutes'
import { useGetModeratorPositionsFilter, } from '../../gql/hooks/effectiveContractHooks/useEcCatalogs'
import { useGetTeachersWithAchievement as useGetTeachersWithAppeals, } from '../../gql/hooks/effectiveContractHooks/useGetTeacher'
import {
  setAppealsUserlistFilterUsersSearch,
  setAppealsUserlistFilterInstituteFilter,
  setAppealsUserlistFilterPositionNameFilter,
  setAppealsUserlistFilterTableRowsLength,

} from '../../redux/efectiveContractSlices/efectiveContractSlices'
import ButtonSimple from '../common/ButtonSimple/ButtonSimple'
import CollapseContainer2, { AccordionInnder, } from '../common/CollapseContainer2/CollapseContainer2'
import ChooseField from '../common/Fields/ChooseField/ChooseField'
import FilterDropDown from '../common/FilterDropDown/FilterDropDown'
import InputTextWithDebounce from '../common/InputTextWithDebounce/InputTextWithDebounce'
import LinkButton from '../common/LinkButton/LinkButton'
import PaginationSimple from '../common/PaginationSimple/PaginationSimple'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import UsersTable from './UserList/UsersTable/UsersTable'


const Appeals = () => {
  const [
    usersTable,
    setUsersTable,
  ] = useState([])
  const [
    offset,
    setOffset,
  ] = useState(0)
  const [
    countPages,
    setCountPages,
  ] = useState(1)
  const [
    page,
    setPage,
  ] = useState(1)

  const [
    sortColumn,
    setSortColumn,
  ] = useState('appealPending')
  const [
    sortOrder,
    setSortOrder,
  ] = useState('desc')

  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const usersSearch = useSelector(state => state.efContract.appealsUserlistFilter.usersSearch)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const instituteFilter = useSelector(state => state.efContract.appealsUserlistFilter.instituteFilter)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const positionNameFilter = useSelector(state => state.efContract.appealsUserlistFilter.positionNameFilter)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const tableRowsLength = useSelector(state => state.efContract.appealsUserlistFilter.tableRowsLength)

  const { pathname, } = useLocation()


  const onClickSort = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'fullName') {
      setSortColumn('full_name')
    }

    if (sorter.field === 'unConfirmCount') {
      setSortColumn('appealPending')
    }

    if (sorter.field === 'confirmCount') {
      setSortColumn('appealApproved')
    }

    if (sorter.order === 'ascend') {
      setSortOrder('asc')
    }

    if (sorter.order === 'descend' || !sorter.order) {
      setSortOrder('desc')
    }

    if (!sorter.order) {
      setSortColumn('appealPending')
      setSortOrder('desc')
    }
  }

  const getTeachersWithAchievement = useGetTeachersWithAppeals(
    {
      search: usersSearch,
      institutes: instituteFilter,
      positions: positionNameFilter,
    },
    {
      offset: offset,
      limit: tableRowsLength,
    },
    [
      {
        'column': sortColumn,
        'order': sortOrder,
      },
    ],
    /ams/.test(pathname) ? 'amsAppeal' : 'appeal'
  )


  useEffect(() => {
    if (getTeachersWithAchievement?.data?.ecDataTableByUserAndStatus?.items) {
      const total = getTeachersWithAchievement.data.ecDataTableByUserAndStatus.pagination.total
      const items = getTeachersWithAchievement.data.ecDataTableByUserAndStatus.items

      setCountPages(Math.ceil(total / tableRowsLength))

      setUsersTable(
        items.map((user: any) => {
          return {
            id: user.user_id,
            fullName: user.full_name,
            appealApproved: user.appealApproved,
            appealPending: user.appealPending,
          }
        }))
    }
  }, [
    getTeachersWithAchievement.loading,
    getTeachersWithAchievement.data,
    tableRowsLength,
  ])


  useEffect(() => {
    setOffset(0)
    setPage(1)
  }, [
    usersSearch,
  ])


  const [
    institutesList,
    setInstitutesList,
  ] = useState([])
  const getInstitutesQuery = useGetInstitutes()

  useEffect(() => {
    if (getInstitutesQuery?.data) {
      setInstitutesList(getInstitutesQuery.data.catalogs.ecInstList)
    }
  }, [
    getInstitutesQuery.loading,
    getInstitutesQuery.data,
  ])


  const [
    allTeachersPositions,
    setAllTeachersPositions,
  ] = useState([])
  const getModeratorPositionsFilter = useGetModeratorPositionsFilter()

  useEffect(() => {
    if (getModeratorPositionsFilter?.data) {
      setAllTeachersPositions(
        getModeratorPositionsFilter.data.catalogs.ecModeratorPositionsFilter)
    }
  }, [
    getModeratorPositionsFilter.loading,
    getModeratorPositionsFilter.data,
  ])


  return (
    <div className="effective-contract">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
          // { value: "Эффективный контракт", path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE },
        ] }
        loc="Модерация апелляций"
      />

      <h2 className="effective-contract__title">Модерация апелляций</h2>

      {/* { stage === 'stage-5' ? ( */ }
      <>
        <CollapseContainer2 styles={ { marginBottom: '2rem', } }>
          <AccordionInnder
            isOpened
            headerText={ (
              <span className="effective-contract__subtitle">
                Фильтрация пользователей
              </span>
            ) }
          >
            <UsersFilter
              usersSearch={ usersSearch }
              setUsersSearch={ (data: any) => dispatch(setAppealsUserlistFilterUsersSearch(data)) }
              institutesList={ institutesList }
              allTeachersPositions={ allTeachersPositions }
              instituteFilter={ instituteFilter }
              positionNameFilter={ positionNameFilter }
              setInstituteFilter={ (data: any) => dispatch(setAppealsUserlistFilterInstituteFilter(data)) }
              setPositionNameFilter={ (data: any) => dispatch(setAppealsUserlistFilterPositionNameFilter(data)) }
            />
          </AccordionInnder>
        </CollapseContainer2>

        <UsersTable
          isLoading={ getTeachersWithAchievement.loading }
          users={ usersTable }
          onClickSort={ onClickSort }
          isAppealsTable
          mode={ /ams/.test(pathname) && 'AMS' }
        />


        <div className='paginationWrap'>
          <PaginationSimple
            page={ page }
            count={ countPages }
            onChange={ (event: any, newPage: any) => {
              setOffset((newPage - 1) * tableRowsLength)
              setPage(newPage)
            } }
            style={ { zIndex: 0, } }
          />

          <div className="limitWrap">
            Записей на странице
            <ChooseField
              items={ [
                20,
                40,
                60,
                80,
                100,
              ] }
              value={ tableRowsLength }
              handler={ (data: any) => dispatch(setAppealsUserlistFilterTableRowsLength(data)) }
            />
          </div>
        </div>
      </>
      {/* ) : (<p>Раздел доступен в текущем году с 11 июля до 17 июля </p>) } */ }
    </div>
  )
}


const UsersFilter = ({
  usersSearch,
  setUsersSearch,
  institutesList,
  allTeachersPositions,
  instituteFilter,
  setInstituteFilter,
  positionNameFilter,
  setPositionNameFilter,
}: any) => {
  const [
    instituteValue,
    setInstituteValue,
  ] = useState(instituteFilter)
  const [
    positionNameValue,
    setPositionNameValue,
  ] = useState(positionNameFilter)

  const [
    clearFilter,
    setClearFilter,
  ] = useState(false)

  return (
    <div className="effective-contract-user-list__inputs-inner">
      <div className="effective-contract-user-list__inputs-wrapper">
        <InputTextWithDebounce
          value={ usersSearch }
          onChange={ setUsersSearch }
          defaultHint={ 'Поиск по ФИО преподавателя' }
          style={ { width: '100%', } }
        />
      </div>
      <div className="effective-contract-user-list__filters-inner">
        <div className="effective-contract-user-list__filters-wrapper">
          <FilterDropDown
            defaultHint={ instituteValue.length ? instituteValue : 'Институт' }
            withBorder
            customWidth="27.4rem"
            items={ [
              'Все',
              ...institutesList,
            ] }
            clearFilter={ clearFilter }
            onClickHandler={ (answer: any) => {
              if (answer === 'Все') {
                setInstituteValue([])
              } else {
                setInstituteValue(answer)
              }
            } }
            maxWidthOfinput="24rem"
          />
          <FilterDropDown
            defaultHint={ positionNameValue.length ? positionNameValue : 'Должность' }
            withBorder
            customWidth="27.4rem"
            items={ [
              'Все',
              ...allTeachersPositions,
            ] }
            clearFilter={ clearFilter }
            onClickHandler={ (answer: any) => {
              if (answer === 'Все') {
                setPositionNameValue([])
              } else {
                setPositionNameValue(answer)
              }
            } }
            maxWidthOfinput="24rem"
          />
        </div>

        <div
          style={ {
            display: 'flex',
            alignItems: 'center',
          } }
        >
          <LinkButton
            style={ { marginRight: '1rem', } }
            type="clearFilters"
            onClickHandler={
              () => {
                setInstituteFilter([])
                setPositionNameFilter([])
                setClearFilter(!clearFilter)
                setInstituteValue([])
                setPositionNameValue([])
              }
            }
          />

          <ButtonSimple
            value="Применить"
            variant="fill"
            onClickHandler={ () => {
              setInstituteFilter(instituteValue)
              setPositionNameFilter(positionNameValue)
            } }
          />
        </div>
      </div>
    </div>
  )
}


export default Appeals

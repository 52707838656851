const createFilesEntryInput = (files: File[], category: string) => {
  return (files.map(
    file => ({
      category,
      name: file.name,
    })
  ))
}


export default createFilesEntryInput

import { setLogged } from '@/legacy/redux/features/auth/auth'
import { useMutation, gql, useQuery, } from '@apollo/client'
import { useEffect, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { useNavigate, } from 'react-router-dom'

const IS_LOGGED = gql`
  query IsLogged {
    isLogged
  }
`


const FEEDBACK = gql`
  mutation SendFeedback($subject: String!, $body: String!, $extra:JSON) {
    uiFeedback(subject: $subject, body: $body, extra: $extra)
  }
`

const DELETE_UPLOAD = gql`
  mutation DeleteUpload($upload_ids: [Int]!) {
    deleteUpload(upload_ids: $upload_ids)
  }
`


export const LOG_FRONTEND = gql`
  mutation LogFrontEnd($input:LogFrontendInput!) {
    utilLogfrontEnd(input:$input)
  }
`

export const useDeleteUpload = () => useMutation(DELETE_UPLOAD)
export const useFeedback = (subject: any, body: any, extra: any) => useMutation(FEEDBACK, {
  variables: {
    subject,
    body,
    extra,
  },
})
export const useLogFrontend = () => useMutation(LOG_FRONTEND)
export const useIsLogged = () => useQuery(IS_LOGGED, {
  fetchPolicy: 'network-only',
  notifyOnNetworkStatusChange: true,
})

export const useLoggedState = () => {
  const { loading, error, data, } = useIsLogged()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const isLogged = useSelector(state => state.auth.isLogged)
  useEffect(() => {
    if (loading) {
      return
    }
    if (error) {
      navigate('/logout')
      /* dispatch(setLogged(false));
      window.location.replace(EndPoints.endSession); */
      return
    }
    dispatch(setLogged(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    error,
    data,
    dispatch,
  ])
  return isLogged
}

import { EC_CREATE_DATA, } from '@/legacy/gql/hooks/effectiveContractHooks/useCreateEcData'
import { EC_UPDATE_DATA, } from '@/legacy/gql/hooks/effectiveContractHooks/useUpdateEcData'
import { gql, } from '@/__generated__'
import { useMutation, useQuery, } from '@apollo/client'

export const EC_LIST_DATA = gql(`
  query EcListData($params:JSON){
    ecListData(params:$params)
  }
`)


const EC_UPDATE_DATA_TYPE = gql(`
  mutation EcUpdateDataType(
    $rec_id: Int!,
    $dataType: String
  ) {
  ecUpdateDataType(
    rec_id: $rec_id,
    dataType: $dataType
  )
}
`)

const EC_GET_SCORES = gql(`
  query GetScoresBySelector(
    $user_id: String,
    $selector: DataTypeSelector,
    $extra: JSON
  ){
  ecGetScoresTableBySelector(
    user_id: $user_id,
    dataTypeSelector: $selector,
    extra: $extra
  )
}
`)


const EC_GET_SCORES_POINT_1_1 = gql(`
  query CheckCompletion($user_id: String) {
    ecCheckCompletion(user_id: $user_id)
  }
`)


const EC_GET_SCORES_POINT_1_4 = gql(`
  query GetBaseLine ($user_id: String, $dataType: [String]!) {
    ecGetBaselineCompletion(user_id: $user_id, dataType: $dataType)
  }
`)


const EC_GET_SCORES_FIRST_POINT = gql(`
  query GetComplitionsStats($user_id: String, $dataType: String!) {
    ecGetCompletionStats(user_id:$user_id, dataType:$dataType)
  }
`)


const EC_GET_ALL_DIAGRAMM_SCORES = gql(`
  query GetGraphDataTotals($id:String){
    ecGraphDataTotals(user_id:$id)
  }
`)


export const useGetEcListData = (params: any) => useQuery(EC_LIST_DATA, {
  variables: {
    params: params,
  },
})


// TODO: Выпилить этот хук
/** @deprecated Используй нормальный, одноименный хук */
export const useCreateEcData = () => useMutation(EC_CREATE_DATA)


// TODO: Выпилить этот хук
/** @deprecated Используй нормальный, одноименный хук */
export const useUpdateEcData = () => useMutation(EC_UPDATE_DATA)


export const useUpdateEcDataType = () => useMutation(
  EC_UPDATE_DATA_TYPE)


export const useGetScores = (params: any) => useQuery(EC_GET_SCORES, {
  variables: params,
})


export const useGetPoint_1_1_Scores = (params: any) => useQuery(EC_GET_SCORES_POINT_1_1, {
  variables: params,
})


export const useGetPoint_1_4_Scores = (params: any) => useQuery(EC_GET_SCORES_POINT_1_4, {
  variables: {
    ...params,
    dataType: [],
  },
})


export const useGetFirstPointScores = (params: any) => useQuery(EC_GET_SCORES_FIRST_POINT, {
  variables: params,
})


export const useGetAllDiagrammScores = () => useQuery(EC_GET_ALL_DIAGRAMM_SCORES)

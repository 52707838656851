import './CardDiscipline.less'
import clsx from 'clsx'
import { useDispatch, } from 'react-redux'
import useRedirect from '@/legacy/hooks/useRedirect'
import { setSelectedTabIndex, } from '@/legacy/redux/learningPathwaySlices/learningPathwaySlices'


const NoDisciplineCard = ({
  description,
  index,
}: any) => {
  const redirect = useRedirect()
  const dispatch = useDispatch()

  const handleNavigate = () => {
    redirect('/learning-pathway', index)
    dispatch(setSelectedTabIndex(index))
  }

  return <div onClick={ handleNavigate } className={ clsx('card-discipline', 'card-discipline__warn') }>
    <div className='card-discipline__subtitle '>{ description }</div>
  </div>
}


export default NoDisciplineCard

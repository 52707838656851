import {
  IconBook,
  IconNotes,
  IconPlayerPlay,
  IconSchool,
  IconUser,
  IconUsers,
  IconWallet,
  IconWorld,
} from '@tabler/icons'
import { useContext, useEffect, useState, } from 'react'
import { useGetScores, } from '../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import CollapseContainer2, { AccordionInnder, } from '../../../common/CollapseContainer2/CollapseContainer2'
import TableContainer from '../../TableContainer/TableContainer'

import {
  tableSource as headerFields_2_1,
} from '../../constants/tablesFormData/table_2_1'
import {
  tableSource as headerFields_2_2,
} from '../../constants/tablesFormData/table_2_2'
import {
  tableSource as headerFields_2_3,
} from '../../constants/tablesFormData/table_2_3'
import {
  tableSource as headerFields_2_4,
} from '../../constants/tablesFormData/table_2_4'
import {
  tableSource as headerFields_2_41,
} from '../../constants/tablesFormData/table_2_41'
import {
  tableSource as headerFields_2_5,
} from '../../constants/tablesFormData/table_2_5'
import {
  tableSource as headerFields_2_6,
} from '../../constants/tablesFormData/table_2_6'
import {
  tableSource as headerFields_2_7,
} from '../../constants/tablesFormData/table_2_7'
import {
  tableSource as headerFields_2_79,
} from '../../constants/tablesFormData/table_2_79'
import {
  tableSource as headerFields_2_10,
} from '../../constants/tablesFormData/table_2_10'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import useGetTableData from '@/legacy/gql/hooks/effectiveContractHooks/useGetTableData'
import { EcSubscriptionContext, } from '@/features'
import useGetUserInfo from '@/legacy/hooks/useGetUserInfo'


const HightPerformansIndicators = () => {
  const dataTypeUpdated = useContext(EcSubscriptionContext)

  const { userGuid, } = useGetUserInfo()


  const table_2_1 = useGetTableData({
    dataType: EcTableName.TABLE_2_1,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_2_2 = useGetTableData({
    dataType: EcTableName.TABLE_2_2,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_2_3 = useGetTableData({
    dataType: EcTableName.TABLE_2_3,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_2_4 = useGetTableData({
    dataType: EcTableName.TABLE_2_4,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_2_41 = useGetTableData({
    dataType: EcTableName.TABLE_2_41,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_2_5 = useGetTableData({
    dataType: EcTableName.TABLE_2_5,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_2_6 = useGetTableData({
    dataType: EcTableName.TABLE_2_6,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_2_7 = useGetTableData({
    dataType: EcTableName.TABLE_2_7,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_2_79 = useGetTableData({
    dataType: EcTableName.TABLE_2_79,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_2_10 = useGetTableData({
    dataType: EcTableName.TABLE_2_10,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })


  const getScoresQuery = useGetScores({
    selector: {
      major: 2,
      minor: [
        1,
        2,
        3,
        4,
        41,
        5,
        6,
        7,
        8,
        9,
        10,
      ],
    },
    extra: [
      {
        dataTypes: [
          '2_4',
          '2_41',
        ],
        max: 150,
      },
    ],
  })

  const [
    scores_2_1,
    setScores_2_1,
  ] = useState(0)
  const [
    scores_2_2,
    setScores_2_2,
  ] = useState(0)
  const [
    scores_2_3,
    setScores_2_3,
  ] = useState(0)
  const [
    scores_2_4,
    setScores_2_4,
  ] = useState(0)
  const [
    scores_2_5,
    setScores_2_5,
  ] = useState(0)
  const [
    scores_2_6,
    setScores_2_6,
  ] = useState(0)
  const [
    scores_2_7,
    setScores_2_7,
  ] = useState(0)
  // const [scores_2_8, setScores_2_8] = useState(0);
  // const [scores_2_9, setScores_2_9] = useState(0);
  const [
    scores_2_10,
    setScores_2_10,
  ] = useState(0)

  const setScoresByDefault = () => {
    setScores_2_1(0)
    setScores_2_2(0)
    setScores_2_3(0)
    setScores_2_4(0)
    setScores_2_5(0)
    setScores_2_6(0)
    setScores_2_7(0)
    // setScores_2_8(0)
    // setScores_2_9(0)
    setScores_2_10(0)
  }

  useEffect(() => {
    if (getScoresQuery.data) {
      if (getScoresQuery.data.ecGetScoresTableBySelector.items.length === 0) {
        setScoresByDefault()
      }

      if (getScoresQuery.data.ecGetScoresTableBySelector.extras) {
        const extras = getScoresQuery.data.ecGetScoresTableBySelector.extras

        extras.forEach(
          (extra: any) => {
            extra.forEach(
              (item: any) => {
                if (item.dataTypes.find(
                  (item: any) => item === 'table_2_4' || item === 'table_2_41')) {
                  setScores_2_4(item.actualScores)
                  // setScores_2_4(item.scores) // баллы с ограничением
                }
              }
            )
          }
        )
      }


      setScores_2_7(
        getScoresQuery.data.ecGetScoresTableBySelector.items.filter((item: any) => item.dataType === 'table_2_7')
          .reduce((accumulator: any, currentValue: any) => accumulator + Number(currentValue.actualScores), 0)
      )


      getScoresQuery.data.ecGetScoresTableBySelector.items.forEach((item: any) => {
        if (item.dataType === 'table_2_1') {
          setScores_2_1(item.actualScores)
          // setScores_2_1(item.scores) // баллы с ограничением
        }

        if (item.dataType === 'table_2_2') {
          setScores_2_2(item.actualScores)
          // setScores_2_2(item.scores) // баллы с ограничением
        }

        if (item.dataType === 'table_2_3') {
          setScores_2_3(item.actualScores)
          // setScores_2_3(item.scores) // баллы с ограничением
        }

        if (item.dataType === 'table_2_5') {
          setScores_2_5(item.actualScores)
          // setScores_2_5(item.scores) // баллы с ограничением
        }

        if (item.dataType === 'table_2_6') {
          setScores_2_6(item.actualScores)
          // setScores_2_6(item.scores) // баллы с ограничением
        }

        // if (item.dataType === 'table_2_7') {
        //   setScores_2_7(item.actualScores)
        //   // setScores_2_7(item.scores) // баллы с ограничением
        // }

        // if (item.dataType === 'table_2_8') {
        //   setScores_2_8(item.actualScores)
        //   // setScores_2_8(item.scores) // баллы с ограничением
        // }

        // if (item.dataType === 'table_2_9') {
        //   setScores_2_9(item.actualScores)
        //   // setScores_2_9(item.scores) // баллы с ограничением
        // }

        if (item.dataType === 'table_2_10') {
          setScores_2_10(item.actualScores)
          // setScores_2_10(item.scores) // баллы с ограничением
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getScoresQuery.loading,
    getScoresQuery.data,
  ])


  return (
    <>
      <h3 className="effective-contract-add-chievement__subtitle">
        Показатели высокой эффективности труда
      </h3>

      <CollapseContainer2>
        <AccordionInnder
          Icon={ IconWallet }
          headerText="Государственные премии"
          subHeaderText={ `Баллы: ${ scores_2_1 }` }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_2_1 }
            headerFields={ headerFields_2_1 }
            ecTableData={ table_2_1 }
          />
        </AccordionInnder>


        <AccordionInnder
          Icon={ IconWorld }
          headerText="Владение иностранным языком"
          subHeaderText={ `Баллы: ${ scores_2_2 }` }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_2_2 }
            headerFields={ headerFields_2_2 }
            ecTableData={ table_2_2 }
          />
        </AccordionInnder>


        <AccordionInnder
          Icon={ IconBook }
          headerText="Издание учебной и научной литературы"
          subHeaderText={ `Баллы: ${ scores_2_3 }` }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_2_3 }
            headerFields={ headerFields_2_3 }
            ecTableData={ table_2_3 }
          />
        </AccordionInnder>


        <AccordionInnder
          Icon={ IconPlayerPlay }
          headerText="Издание мультимедийной учебной литературы и (или) разработка нового онлайн-курса по программам ВО и (или) ДО (показатель интенсивности)"
          subHeaderText={ `Баллы: ${ scores_2_4 }` }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_2_4 }
            headerFields={ headerFields_2_4 }
            ecTableData={ table_2_4 }
          />

          <br /><br /><br /><br /><br /><br />

          <TableContainer
            dataType={ EcTableName.TABLE_2_41 }
            headerFields={ headerFields_2_41 }
            ecTableData={ table_2_41 }
          />
        </AccordionInnder>


        <AccordionInnder
          Icon={ IconUsers }
          headerText="Подготовка студентов-победителей и призеров олимпиад/конкурсов"
          subHeaderText={ `Баллы: ${ scores_2_5 }` }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_2_5 }
            headerFields={ headerFields_2_5 }
            ecTableData={ table_2_5 }
          />
        </AccordionInnder>


        <AccordionInnder
          Icon={ IconSchool }
          headerText="Визит в партнерский университет для чтения курсов, в рамках образовательных программ принимающего университета"
          subHeaderText={ `Баллы: ${ scores_2_6 }` }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_2_6 }
            headerFields={ headerFields_2_6 }
            ecTableData={ table_2_6 }
          />
        </AccordionInnder>


        <AccordionInnder
          Icon={ IconNotes }
          headerText="Публикации  в изданиях,  индексируемых  в базах данных  «Web of Science»,  «Scopus»"
          subHeaderText={ `Баллы: ${ scores_2_7 }` }
        >
          <TableContainer
            headerFields={ headerFields_2_7 }
            ecTableData={ table_2_7 }
            dataType={ EcTableName.TABLE_2_7 }
          />

          <br /><br /><br /><br /><br /><br />

          <TableContainer
            // isEditable={ false }
            headerFields={ headerFields_2_79 }
            ecTableData={ table_2_79 }
            dataType={ EcTableName.TABLE_2_79 }
          />
        </AccordionInnder>


        <AccordionInnder
          Icon={ IconUser }
          headerText="Членство в составе редколлегий российских и зарубежных журналов WoS и/или Scopus, журналах из списка ВАК"
          subHeaderText={ `Баллы: ${ scores_2_10 }` }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_2_10 }
            headerFields={ headerFields_2_10 }
            ecTableData={ table_2_10 }
          />
        </AccordionInnder>
      </CollapseContainer2>
    </>
  )
}


export default HightPerformansIndicators

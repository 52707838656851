import { Fragment, } from 'react'
import useAchievementDirectionChanged from '../../../gql/hooks/portfolio/useAchievementDirectionChanged'
import useAchievementDirections from '../../../gql/hooks/portfolio/useAchievementDirections'
import useChangeOrderAchievementDirection from '../../../gql/hooks/portfolio/useChangeOrderAchievementDirection'
import useCreateAchievementDirection from '../../../gql/hooks/portfolio/useCreateAchievementDirection'
import useDeleteAchievementDirection from '../../../gql/hooks/portfolio/useDeleteAchievementDirection'
import useRenameAchievementDirection from '../../../gql/hooks/portfolio/useRenameAchievementDirection'
import useUpdatePublishAchievementDirection from '../../../gql/hooks/portfolio/useUpdatePublishAchievementDirection'
import { PORTFOLIO_INDICATORS, } from '@/shared/constants/routes'
import { NewPageWrapper, TitleH2, } from '../../common/GeneralElements/GeneralElements'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import CreateNewItemBlock from './TemplateItem/CreateNewItemBlock/CreateNewItemBlock'
import TemplateItem from './TemplateItem/TemplateItem'


const PortfolioTemplates = () => {
  const { directions, refetch, } = useAchievementDirections({ is_admin: true, })
  const onCreateNewAchievementDirection = useCreateAchievementDirection()
  const onDeleteAchievementDirection = useDeleteAchievementDirection()
  const onRenameAchievementDirection = useRenameAchievementDirection()
  const onChangeOrderAchievementDirection = useChangeOrderAchievementDirection()
  const onUpdatePublishAchievementDirection = useUpdatePublishAchievementDirection()
  useAchievementDirectionChanged(refetch)

  return (
    <NewPageWrapper>
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
      ] } loc="Шаблоны показателей" />
      <br />
      <TitleH2>
        Шаблоны показателей
      </TitleH2>

      <div>
        { directions?.map((direction: any, index: any, allDirections: any) => (
          <Fragment key={ direction?.key }>
            <TemplateItem
              onDeleteItem={ () => onDeleteAchievementDirection(direction.key) }
              onChangeStatus={ (newAvailableStatus: any) => onUpdatePublishAchievementDirection({
                ...direction,
                newAvailableStatus,
              }) }
              onRenameItem={ (newName: any) => onRenameAchievementDirection({
                ...direction,
                newName,
              }) }
              onChangeOrder={ (vectorOfOrder: any) => {
                const previousDirection = allDirections[index - 1]
                const nextDirection = allDirections[index + 1]

                if (vectorOfOrder === 'toUp') {
                  onChangeOrderAchievementDirection({
                    ...direction,
                    newOrder: previousDirection.order,
                  })
                    .then(() => onChangeOrderAchievementDirection({
                      ...previousDirection,
                      newOrder: direction.order,
                    }))
                }

                if (vectorOfOrder === 'toDown') {
                  if (Number(direction.order) === 0) {
                    onChangeOrderAchievementDirection({
                      ...direction,
                      newOrder: allDirections.at(-1).order + 1,
                    })
                  } else {
                    onChangeOrderAchievementDirection({
                      ...direction,
                      newOrder: nextDirection.order,
                    })
                      .then(() => onChangeOrderAchievementDirection({
                        ...nextDirection,
                        newOrder: direction.order,
                      }))
                  }
                }
              } }
              linkTo={ `${ PORTFOLIO_INDICATORS }?catid=${ direction?.key }` }
              { ...direction }
              order={ {
                current: direction.order,
                max: allDirections.at(-1).order,
              } }
            />
            <br />
          </Fragment>
        )) }

      </div>

      <br />
      <CreateNewItemBlock
        onCreateNewItem={ onCreateNewAchievementDirection }
      />
    </NewPageWrapper >
  )
}


export default PortfolioTemplates

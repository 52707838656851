/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useMemo, } from 'react'
import useGetInstitutes from '../../../../../gql/hooks/common/useGetInstitutes'
import FilterDropDown from '../../../../common/FilterDropDown/FilterDropDown'


const InstitutesField = ({
  setInstitute,
  value,
}: any) => {
  const { data, error, loading, } = useGetInstitutes()

  const instList = useMemo(() => {
    if (error) {
      return [
        'произошла ошибка',
      ]
    }
    if (loading) {
      return [
        'загрузка..',
      ]
    } else if (data) {
      return data.catalogs.ecInstList
    }
  }, [
    data,
    error,
    loading,
  ])


  return (<FilterDropDown items={ [
    'Выбрать все',
    ...instList,
  ] } withBorder="true" defaultHint="Выберите институт" onClickHandler={ setInstitute } />)
}


export default InstitutesField

export const SHORT_WEEKDAYS = [
  {
    ru: 'пн',
    upd: 'Пн',
    en: 'Mo',
  },
  {
    ru: 'вт',
    upd: 'Вт',
    en: 'Tu',
  },
  {
    ru: 'ср',
    upd: 'Ср',
    en: 'We',
  },
  {
    ru: 'чт',
    upd: 'Чт',
    en: 'Th',
  },
  {
    ru: 'пт',
    upd: 'Пт',
    en: 'Fr',
  },
  {
    ru: 'сб',
    upd: 'Сб',
    en: 'Sa',
  },
  {
    ru: 'вс',
    upd: 'Вс',
    en: 'Su',
  },
]

interface Weekdays {
  ru: string;
  en: string;
}

export const weekdays: Weekdays[] = [
  {
    ru: 'Понедельник',
    en: 'Monday',
  },
  {
    ru: 'Вторник',
    en: 'Tuesday',
  },
  {
    ru: 'Среда',
    en: 'Wednesday',
  },
  {
    ru: 'Четверг',
    en: 'Thursday',
  },
  {
    ru: 'Пятница',
    en: 'Friday',
  },
  {
    ru: 'Суббота',
    en: 'Saturday',
  },
  {
    ru: 'Воскресенье',
    en: 'Sunday',
  },
]

import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'


const PERSONAL_RATING_BY_ID = gql`
  query GetPersonalRatingById($id: UUID) {
    achievementRatingById(id: $id)
  }
`


const usePersonalRatingById = (id: any) => {
  const { data, loading, } = useQuery(PERSONAL_RATING_BY_ID, { variables: { id, }, })

  const directions = useMemo(() => {
    if (!loading && data) {
      return data.achievementRatingById
    } return []
  }, [
    data,
    loading,
  ])


  return { directions, }
}


export default usePersonalRatingById

import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  moderPage: 'Предложенные проекты',
  teacherApplyTab: 'Предложенные темы проектов',
  snowAnymation: true,
}

export const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    setModerPage: (state, action) => {
      state.moderPage = action.payload
    },
    setTeacherTab: (state, action) => {
      state.teacherApplyTab = action.payload
    },
    setSnowAnimation: (state, action) => {
      state.snowAnymation = action.payload
    },
  },
})

export const { setModerPage, setTeacherTab, setSnowAnimation, } = utilsSlice.actions
export default utilsSlice.reducer

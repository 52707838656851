import TextArea from '../../../../common/TextArea/TextArea'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'


const ThemeCard = ({
  title,
  description,
  results,
  payload,
  index,
  setPayload,
}: any) => {
  const handleReset = () => {
    setPayload({
      ...payload,
      themes: payload.themes.map((i: any, num: any) => {
        if (num === index) {
          return {
            ...i,
            content: description,
            name: title,
            results,
          }
        }
        return i
      }),
    })
  }

  return (
    <div style={ {
      width: '100%',
      margin: 0,
    } }>
      <InputTextDeprecated
        defaultHint="Название"
        styles={ {
          width: '100%',
          marginRight: '1rem',
        } }
        value={ payload.themes[index].name }
        onChangeValue={ (e: any) => setPayload({
          ...payload,
          themes: payload.themes.map((i: any, current: any) => {
            if (current === index) {
              return {
                ...i,
                name: e,
              }
            }
            return i
          }),
        }) } />
      <br />
      <TextArea
        rows='3'
        withPlaceholder={ false }
        defaultHint='Описание'
        value={ payload.themes[index].content }
        onChangeValue={ (e: any) => setPayload({
          ...payload,
          themes: payload.themes.map((i: any, current: any) => {
            if (current === index) {
              return {
                ...i,
                content: e,
              }
            }
            return i
          }),
        }) } />
      <br />
      <TextArea
        rows='3'
        withPlaceholder={ false }
        defaultHint='Вы научитесь'
        value={ payload.themes[index].results }
        onChangeValue={ (e: any) => setPayload({
          ...payload,
          themes: payload.themes.map((i: any, current: any) => {
            if (current === index) {
              return {
                ...i,
                results: e,
              }
            }
            return i
          }),
        }) } />
      <br />
      <div style={ {
        display: 'flex',
        justifyContent: 'flex-end',
      } }>
        <ButtonSimple
          styles={ { marginRight: '.6rem', } }
          value="Сбросить"
          onClickHandler={ handleReset } />
      </div>
    </div>
  )
}

export default ThemeCard

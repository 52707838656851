import _ from 'lodash'
import format from 'date-fns/format'

export function findContact(list: any, type: any) {
  const info: any = _.find(list, type)
  if (info) {
    return info?.represent
  }
  return '--'
}

export function handleRole(role: any) {
  if (role === 'student' || role === 'student-rakus') {
    return 'student'
  }
  return 'teacher'
}

const monthsFirstPart = [
  '1',
  '9',
  '10',
  '11',
  '12',
]

export const getSemester = (course: any) => {
  if (monthsFirstPart.includes(format(new Date(Date.now()), 'M'))) {
    return ((course * 2) - 1)
  }
  return (course * 2)
}

export function debounceCast(this: any, callback: any, time: any) {
  return (...args: any[]) => {
    const previousCall = this.lastCall

    this.lastCall = Date.now()

    if (previousCall && this.lastCall - previousCall <= time) {
      clearTimeout(this.lastCallTimer)
    }
    this.lastCallTimer = setTimeout(() => callback(...args), time)
  }
}

import { useMemo, useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import ProjectCard from '../ProjectCard/ProjectCard'
import { useGetProjectApplys, } from '../../../gql/hooks/useProjects'
import PaginationSimple from '../../common/PaginationSimple/PaginationSimple'
import { InputNumber, } from 'antd'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import NoContracts from '../../Finances/PaymentPage/NoContracts/NoContracts'
import { Preloader, } from '@/shared'
import { checkParticipateData, } from '@/shared/lib/utils/project-activity'

function Cards ({
  params,
  isParticipate,
}: any): JSX.Element {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const updateProjects = useSelector(state => state.subscriptions.applyStatus)

  const [
    offs,
    setOffs,
  ] = useState(0)
  const [
    page,
    setPage,
  ] = useState<any>(0)

  const pagination = {
    offset: offs,
    limit: 10,
  }

  const { refetch, data, error, loading, } = useGetProjectApplys(params, pagination)


  function handlePaginate (e: any, value: any) {
    const page = document.querySelector('.main-scroll-bar')?.firstChild
    setOffs((value * 10) - 10)
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    page.scrollTo(0, 0)
  }

  useEffect(() => {
    refetch()
  }, [
    updateProjects,
    params,
    refetch,
  ])

  const choosePage = (i: any) => {
    if (i > 0) {
      goTop()
      if (i === 1) {
        setOffs(0)
      } else {
        setOffs(((i - 1) * 10))
      }
    }
  }

  const goTop = () => {
    const page = document.querySelector('.main-scroll-bar')?.firstChild
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    page.scrollTo(0, 0)
  }


  const applications = useMemo(() => {
    if (error) {
      return <div>.....errrr</div>
    } else if (loading) {
      return <Preloader />
    }

    return data.paListProjects.items.length > 0 ? <>
      { data.paListProjects.items.map((i: any, index: any) => <ProjectCard type="active" i={ i } participateData={ checkParticipateData(data) } key={ index } isParticipate={ isParticipate } filter={params?.filter} />) }
      { data.paListProjects.pagination.total > 10 && <div style={ {
        display: 'flex',
        alignItems: 'center',
      } } className="choosePageWrap">
        <InputNumber min={ 1 } max={ 300 } placeholder="Введите номер страницы" autoFocus={ false } onChange={ setPage } />
        <ButtonSimple onClickHandler={ () => choosePage(page) } value="перейти" variant="fill" active={ true } styles={ { marginLeft: '10px', } } />
      </div> }
      { data.paListProjects.pagination.total > 10 && <PaginationSimple count={ Math.ceil(data.paListProjects.pagination.total / 10) } onChange={ handlePaginate } page={ offs ? Math.round((offs + 10) / 10) : 1 } /> }
    </> : <NoContracts title="У вас нет проектов для записи" />

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
    offs,
    page,
  ])

  return (applications)
}

export default Cards

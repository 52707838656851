import { gql, useQuery, } from '@apollo/client'
import { useEffect, useMemo, } from 'react'
import { useSelector, } from 'react-redux'

const GET_SPLASH = gql`
  query GetSplash {
    notificationSplash
  }
`

const useNotificationSplash = () => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const notificationUpdateEvent = useSelector(store => store.subscriptions.notifChanged)
  const { loading, data, refetch, } = useQuery(GET_SPLASH)

  useEffect(() => {
    refetch()
  }, [
    refetch,
    notificationUpdateEvent,
  ])

  const splashArray = useMemo(() => {
    if (!loading && data) {
      const currentData = data[Object.keys(data)[0]]

      return currentData.map((data: any) => ({
        msg_id: data.msg_id,
        body: data?.body,
        status: data?.status,
        broadcast: data?.meta?.broadcast,
        files: data?.meta?.files,
        isImportant: data?.meta?.is_important,
      }))
    } return []
  }, [
    loading,
    data,
  ])

  return splashArray
}


export default useNotificationSplash

import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'
import { Divider, } from '@mui/material'
import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/app/providers/AppProvider'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputFile from '../../../../common/InputFile/InputFile'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import AttachedParticipants from '../../../AttachedParticipants/AttachedParticipants'
import { defineUserGroupe, } from '../../../defineUserGroupe'
import SpecsSearch from '../../../Hooks/SpecsSearch/SpecsSearch'
import TeacherSearch from '../../../Hooks/TeacherSearch/TeacherSearch'
import { table_cat_1_1, } from '../../../popUpInputsCatalogs'


const Inputs_1_1 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, }: any = useAppContext()


  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)
  // Этот вариант сохранятет гуид заведующего кафедрой, что бы тот мог увидеть это достижение вместе с другими участниками
  const [
    ids,
    setIds,
  ] = useState(
    defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
      ? []
      : [
        currentUserId,
      ]
  )
  // const [ids, setIds] = useState([]) // этот вариант сохраняет только участников достижения


  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification(
          'Эффективный контракт',
          ids,
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (rec_id: any, newData: any, user_ids: any) => onUpdateRow({
    variables: {
      rec_id: rec_id,
      new_data: newData,
      user_ids: user_ids,
    },
  })


  const [
    isAddMode,
    setIsAddMode,
  ] = useState(false)
  const [
    participants,
    setParticipants,
  ] = useState('')
  const [
    authors,
    setAuthors,
  ] = useState<any>([])
  const [
    otherAuthors,
    setOtherAuthors,
  ] = useState('')

  const [
    book_title,
    setBook_title,
  ] = useState('')
  const [
    type_work,
    setType_work,
  ] = useState('')
  // const [
  //   publish,
  //   setPublish,
  // ] = useState('')
  // const [
  //   publisher,
  //   setPublisher,
  // ] = useState('')
  const [
    publication_year,
    setPublication_year,
  ] = useState('')
  const [
    author_count,
    setAuthor_count,
  ] = useState('')
  // const [
  //   major,
  //   setMajor,
  // ] = useState('')
  const [
    disciplineName,
    setDisciplineName,
  ] = useState('')
  const [
    volume,
    setVolume,
  ] = useState('')
  // const [
  //   format,
  //   setFormat,
  // ] = useState('')
  // const [
  //   financeSource,
  //   setFinanceSource,
  // ] = useState('')
  // const [
  //   print_run,
  //   setPrint_run,
  // ] = useState('')
  const [
    document,
    setDocument,
  ] = useState([])


  useEffect(() => {
    if (hint === 'edit') {
      setIds([
        currentUserId,
        ...defaultValues.authors.map((item: any) => item.guid),
      ])
      setAuthors(defaultValues.authors)
      setOtherAuthors('')

      setBook_title(defaultValues.book_title)
      setType_work(defaultValues.type_work)
      // setPublish(defaultValues.publish)
      // setPublisher(defaultValues.publisher)
      setPublication_year(defaultValues.publication_year)
      // setMajor(defaultValues.major)
      setDisciplineName(defaultValues.disciplineName)
      setVolume(defaultValues.volume)
      // setFormat(defaultValues.format)
      // setFinanceSource(defaultValues.financeSource)
      // setPrint_run(defaultValues.print_run)
      // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setDocument(defaultValues.document ? [
        defaultValues.document,
      ] : [])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    setIds(
      defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
        ? []
        : [
          currentUserId,
        ]
    )
    setAuthors([])
    setOtherAuthors('')

    setBook_title('')
    setType_work('')
    // setPublish('')
    // setPublisher('')
    setPublication_year('')
    // setMajor('')
    setDisciplineName('')
    setVolume('')
    // setFormat('')
    // setFinanceSource('')
    // setPrint_run('')
    setDocument([])
  }


  return <>
    <ButtonSimple
      value={ isAddMode ? 'Применить' : 'Добавить / удалить участников' }
      variant="alone"
      onClickHandler={ () => {
        setIsAddMode(!isAddMode)
        setParticipants('')
      } }
    />

    <Divider /><br />

    { isAddMode ? (
      <>
        <TeacherSearch
          defaultHint="Добавьте участников по их ФИО"
          value={ participants }
          onChangeValue={ setParticipants }
          handler={ (answer: any) => {
            if (!ids.find(id => id === answer.guid)) {
              setIds([
                ...ids,
                answer.guid,
              ])
            }

            setAuthors([
              ...authors,
              {
                guid: answer.guid,
                fio: answer.fio,
              },
            ])
          } }
        />

        <InputTextDeprecated
          defaultHint="Прочие авторы (не сотрудники Державинского университета, перечислить через запятую)"
          value={ otherAuthors }
          onChangeValue={ setOtherAuthors }
          onBlur={ () => {
            setAuthors([
              ...authors,
              ...otherAuthors.split(', ').map(authorName => {
                return {
                  fio: authorName.trim(),
                  guid: null,
                }
              }).filter(author => author.fio.length > 0),
            ])

            setOtherAuthors('')
          } }
        />

        <br />

        <AttachedParticipants
          // @ts-expect-error TS(2339): Property 'fio' does not exist on type 'never'.
          participantsList={ authors.map(author => author.fio) }
          getName={ (name: any) => {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            const removeId = authors.find(item => item.fio === name).guid

            setIds(ids.filter(item => item !== removeId))

            if (removeId === null) {
              // @ts-expect-error TS(2339): Property 'fio' does not exist on type 'never'.
              setAuthors(authors.filter(item => item.fio !== name))
            } else {
              // @ts-expect-error TS(2339): Property 'guid' does not exist on type 'never'.
              setAuthors(authors.filter(item => item.guid !== removeId))
            }
          } }
        />
      </>
    ) : (
      <>
        <InputTextDeprecated
          defaultHint="ФИО автора (авторов)"
          // @ts-expect-error TS(2339): Property 'fio' does not exist on type 'never'.
          value={ authors.map(author => author.fio).join(', ') }
          disabled
        />

        <InputTextDeprecated
          defaultHint="Название работы"
          value={ book_title }
          onChangeValue={ setBook_title }
        />

        <InputSelectDeprecated
          defaultHint="Вид работы"
          items={ table_cat_1_1.projectType }
          defaultValue={ type_work }
          withResetValue={ true }
          callBack={ setType_work }
        />

        {/* <InputSelectDeprecated
          defaultHint="Издательство"
          items={ table_cat_1_1.publish }
          defaultValue={ publish }
          withResetValue={ true }
          callBack={ setPublish }
        />

        <InputTextDeprecated
          defaultHint="Издатель"
          value={ publisher }
          onChangeValue={ setPublisher }
        /> */}

        <InputTextDeprecated
          defaultHint="Год издания"
          value={ publication_year }
          onChangeValue={ setPublication_year }
          numbersOnly
        />

        {/* <InputText
          defaultHint="Количество авторов"
          value={ authors.length }
          disabled
        /> */}

        <InputTextDeprecated
          defaultHint="Количество авторов"
          value={ author_count }
          onChangeValue={ setAuthor_count }
          numbersOnly
        />

        {/* <SpecsSearch
          defaultHint="Шифр и наименование направления подготовки / специальности"
          withListPositionAbsolute
          value={ major }
          onChangeValue={ setMajor }
          handler={ (answer: any) => {
            setMajor(answer)
          } }
        /> */}

        {/* <InputTextDeprecated
          defaultHint="Название дисциплины"
          value={ disciplineName }
          onChangeValue={ setDisciplineName }
        /> */}

        <InputTextDeprecated
          defaultHint="Объем, п.л"
          value={ volume }
          onChangeValue={ setVolume }
          numbersOnly
        />

        {/* <InputSelectDeprecated
          defaultHint="Формат издания"
          items={ table_cat_1_1.format }
          defaultValue={ hint === 'edit' ? format : '' }
          callBack={ setFormat }
        /> */}

        {/* <InputSelectDeprecated
          defaultHint="Источник финансирования"
          items={ table_cat_1_1.financeSource }
          defaultValue={ hint === 'edit' ? financeSource : '' }
          callBack={ setFinanceSource }
        />

        <InputTextDeprecated
          defaultHint="Тираж"
          value={ print_run }
          onChangeValue={ setPrint_run }
          numbersOnly
        /> */}

        <br />

        <TitleH5>
          Документ
        </TitleH5>

        { document.length < 1 ? (
          <InputFile
            onChange={ event => {
              // TODO: добавить uploadFile
              // uploadFile(...event.target.files, setDocument)
            } }
          />
        ) : false }

        <AttachedFiles
          isDeleteFilesAllowed={ true }
          fileList={ document }
          getIndexFile={ (index: any) => {
            setDocument(document.filter((file, fIndex) => fIndex !== index))
          } }
        />

        <ButtonSimple
          isDisabled={ (
            ids.length < 1
            || book_title.trim().length < 1
            || type_work.trim().length < 1
            // || publish.trim().length < 1
            // || publisher.trim().length < 1
            || publication_year.trim().length < 1
            || author_count.trim().length < 1
            // || major.trim().length < 1
            // || disciplineName.trim().length < 1
            || volume.trim().length < 1
            // || format.trim().length < 1
            // || financeSource.trim().length < 1
            // || print_run.trim().length < 1
            || document.length < 1
          ) }
          value={ hint === 'edit' ? 'Сохранить изменения' : 'Добавить достижение' }
          variant="full-filled"
          onClickHandler={ () => {
            if (hint === 'edit') {
              onUpdateRowHandler(
                defaultValues.rec_id,
                {
                  authors: authors,
                  book_title: book_title,
                  type_work: type_work,
                  // publish: publish,
                  // publisher: publisher,
                  publication_year: publication_year,
                  author_count: author_count,
                  // major: major,
                  disciplineName: disciplineName,
                  volume: volume,
                  // format: format,
                  // financeSource: financeSource,
                  // print_run: print_run,
                  document: document[0],
                  status: defaultValues.status,
                  reason: '',
                  scores: null,
                },
                ids
              )

              fieldsToDefault()
              onClickHandler()
            } else {
              onAddNewEcDataHandler({
                variables: {
                  dataType: 'table_1_1',
                  data: {
                    authors: authors,
                    book_title: book_title,
                    type_work: type_work,
                    // publish: publish,
                    // publisher: publisher,
                    publication_year: publication_year,
                    author_count: author_count,
                    // major: major,
                    disciplineName: disciplineName,
                    volume: volume,
                    // format: format,
                    // financeSource: financeSource,
                    // print_run: print_run,
                    document: document[0],
                    status: 'pending',
                    reason: '',
                    scores: null,
                    isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
                  },
                  user_ids: ids,
                },
              })

              fieldsToDefault()
              onClickHandler()
            };
          } }
        />
      </>
    ) }
  </>
}


export default Inputs_1_1

import { memo, } from 'react'
import { Link, } from 'react-router-dom'
import './HistoryLinks.less'

interface Link {
  value: string;
  path: string;
}

interface HistoryLinksProps {
  links: Link[];
  loc: string | null;
}

const HistoryLinks = ({
  links,
  loc,
}: HistoryLinksProps) => (
  <div className="historyWrap">
    {
      links.map((link: any, index: any) => (
        <Link
          key={ index }
          className="historyLink"
          to={ link.path }
        >
          &nbsp;{ `${ link.value } /` }
        </Link>
      ))
    }

    <p className="location">&nbsp;{ `${ loc }` }</p>
  </div>
)


export default memo(HistoryLinks)

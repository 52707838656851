/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import './ToggleButtons.less'
import clsx from 'clsx'
import { setFilter, } from '../../../../redux/RecordBookSlices/RecordBookSlice'
import { useDispatch, useSelector, } from 'react-redux'
import {
  useMemo,
  useRef,
  useState,
  useEffect,
  forwardRef,
  createRef,
} from 'react'


const Buttons = ({
  items,
  parent,
  onButtonClicked,
  initialIndex = 0,
}: any) => {
  const [
    buttonsRefs,
    setButtonsRefs,
  ] = useState()
  const [
    markerState,
    setMarkerState,
  ] = useState<any>()

  const buttonStyle = {
    margin: 0,
    height: 62,
    zIndex: 1,
  }

  const markerStyle = {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 0,
  }

  // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
  const Button = forwardRef(({ title, onClick, }, ref) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const filtStatus = useSelector(state => state.rec.filterStatus)

    function isActive (i: any) {
      return filtStatus === i
    } return (
      <div
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ ref }
        style={ { ...buttonStyle, } }
        className={ clsx({
          'refBut': true,
          'refActice': isActive(title),
        }) } onClick={ () => onClick(ref) }
      >
        { title }
      </div>
    )
  })

  const onClick = (idx: any, ref: any) => {
    const p = parent?.current?.getBoundingClientRect()
    const sRect = ref.current.getBoundingClientRect()
    const left = sRect.x - p.x

    setMarkerState({
      transition: 'all 0.4s ease-in-out',
      top: 0,
      bottom: 0,
      transform: `translateX(${ left }px)`,
      width: sRect.width,
    })

    onButtonClicked(idx, items[idx])
  }

  const buttons = useMemo(() => {
    const refs = new Array(items.length).fill(0).map((_, idx) => createRef())
    const divs = items.map((props: any, idx: any) => <Button key={ idx } { ...props } onClick={ (ref: any) => onClick(idx, ref) } ref={ refs[idx] } />)
    // @ts-expect-error TS(2345): Argument of type 'RefObject<unknown>[]' is not ass... Remove this comment to see the full error message
    setButtonsRefs(refs)
    return divs

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    items,
  ])

  useEffect(() => {
    if (!(buttonsRefs && buttonsRefs[initialIndex])) {
      return
    }

    // @ts-expect-error TS(2339): Property 'current' does not exist on type 'never'.
    const sRect = buttonsRefs[initialIndex].current.getBoundingClientRect()

    setMarkerState({
      top: 0,
      left: 0,
      width: 222.275,
      height: sRect.height,
    })
  }, [
    initialIndex,
    buttonsRefs,
    parent,
  ])

  return (
    <div style={ {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
    } }>
      <div style={ {
        ...markerStyle,
        ...markerState,
      } } className="markerw" />
      { buttons }
    </div>
  )
}

const ITEMS = [
  {
    title: 'Учебная деятельность',
    key: 'one', /* other props, key, ids,icon... */
  },
  {
    title: 'Курсовые',
    key: 'two',
  },
  {
    title: 'Практики',
    key: 'three',
  },
]

function ToggleButtons () {
  // родидительский контейнер для отностительности - х
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const onButtonClicked = (index: any, button: any) => {
    dispatch(setFilter(button.title))
  }


  return (
    <div className="App">
      <div style={ {
        display: 'flex',
        flexDirection: 'row',
      } } className="recFilterButtons">
        <div ref={ ref }>
          <Buttons items={ ITEMS } parent={ ref } initialIndex={ 1 } onButtonClicked={ onButtonClicked } />
        </div>
      </div>
    </div>
  )
}


export default ToggleButtons

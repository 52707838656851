import React, { useState, } from 'react'
import { IconChevronDown, IconChevronUp, } from '@tabler/icons'
import clsx from 'clsx'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'


function InfoCard ({
  info,
  type,
}: any) {
  const [
    isOpen,
    setIsOpen,
  ] = useState(false)

  function getTitle () {
    if (type === 'get') {
      return 'Требования для получения'
    } else if (type === 'general') {
      return 'Общая информация'
    }
    return 'Сроки прекращения выплат'
  }

  return (
    <Accordion style={ {
      borderRadius: '15px',
      boxShadow: '0px 12.521552085876465px 6.636422634124756px 0px rgba(0, 0, 0, 0.015)',
      marginBottom: '.7rem',
    } } className="infoCard">
      <AccordionSummary
        id="panel1a-header"
        onClick={ () => setIsOpen(!isOpen) }
      >
        <div className="accordionTitle">
          <h3>{ getTitle() }</h3>
          { isOpen ? <IconChevronUp size={ 35 } color="#C5C5C5" style={ { cursor: 'pointer', } } /> : <IconChevronDown size={ 35 } color="#C5C5C5" style={ { cursor: 'pointer', } } /> }
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <ul className={ clsx(isOpen && 'openInfo') }>
          {
            info.map((i: any) => <li key={ info.indexOf(i) }>{ i }</li>)
          }
        </ul>
      </AccordionDetails>
    </Accordion>
  )
}

export default InfoCard

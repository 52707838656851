import React, { Fragment, useEffect, useState } from 'react'
import { Divider } from '@mui/material'
import { formatDistanceToNowStrict } from 'date-fns'
import { ru } from 'date-fns/locale'
import ButtonSimple from '@/legacy/components/common/ButtonSimple/ButtonSimple'
import Image from '@/legacy/components/common/Image/Image'
import AddStudentResultInput from '@/legacy/components/Projects/ProjectPage/InfoProject/components/AddStudentResultInput/AddStudentResultInput'
import useUpdateProject from '@/legacy/gql/hooks/projects/useUpdateProject'
import { CreatePayloadParamDataResponses, OnUpdateProjectPayload } from '@/legacy/gql/hooks/projects/useUpdateProjectTypes'
import { getSemester } from '@/legacy/Utils/functions'
import { InputText } from '@/shared'
import cls from './resultBlock.module.less';

interface ResultsBlockForSupervisorProps {
  responsesList: CreatePayloadParamDataResponses[]
  projectId: number
  projectPayloadData: OnUpdateProjectPayload
}

const ResultsBlockForSupervisor = ({
  responsesList,
  projectId,
  projectPayloadData,
}: ResultsBlockForSupervisorProps) => {
  const { onUpdateProject } = useUpdateProject()
  const [formValues, setFormValues] = useState<{ [key: number]: string }>({})

  const handleInputChange = (index: number, value: string) => {
    setFormValues((prevValues) => ({ ...prevValues, [index]: value }))
  }

  const onUpdateStudentWorkStatus = (userId: string, newStatus: string) => {
    const newProjectPayloadData = JSON.parse(JSON.stringify(projectPayloadData));
    const student = newProjectPayloadData.args.data.responses.find(
      (student: CreatePayloadParamDataResponses) => student.id === userId
    );
    if (student) {
      student.studentResults = {
        ...student.studentResults,
        status: newStatus,
      };
      onUpdateProject(newProjectPayloadData)
    }
  };

  return (
    <div className="projResults">
      <h2>Результаты работы студентов</h2>

      {responsesList
        .filter((response) => response.approved === 'approved')
        .map((student, index) => {
          const { id, name, birth, spec_info, studentResults } = student
          const studentProfile = spec_info[0]
          const isGraduatingSemester = [4, 7].includes(getSemester(studentProfile.course))

          const formattedAge = formatDistanceToNowStrict(
            new Date(birth.split('.').reverse().join(',')),
            { locale: ru }
          )

          useEffect(() => {
            console.log('Current studentResults status:', studentResults?.status);
          }, [studentResults]);

          return (
            <Fragment key={id}>
            <br />
            <div className="applyResponse">
              <div className="imageWrap">
                <Image id={id} />
                <div className="responceInfo">
                  <h2>{name}</h2>
                  <p className="age">{`${formattedAge} · Гражданство: Россия`}</p>
                  <p className="spec">Профиль: <span>{studentProfile.nameprof}</span></p>
                  <p className="spec">Направление подготовки: <span>{studentProfile.namespec}</span></p>
                  <p className="spec">Курс: <span>{studentProfile.course}</span></p>
                </div>
              </div>
          
              <div className="applyResponse__buttons-wrapper">
                  {isGraduatingSemester ? (
                    <>
                      {studentResults?.files?.length ? (
                        studentResults.status !== 'pending' && studentResults.status !== 'rejected' ? (
                          <>
                          <InputText
                              className={cls.inputResult}
                              type="text"
                              value={`Оценка за работу: ${studentResults.status}`}
                              disabled={true}
                            />
                           <ButtonSimple
                            value={
                              studentResults?.status === 'rejected'
                                ? 'Отклонено'
                                : 'Отклонить'
                            }
                            variant="groupe"
                            onClickHandler={() => onUpdateStudentWorkStatus(id, 'rejected')}
                            active={studentResults?.status !== 'rejected'}
                            isDisabled={studentResults?.status === 'rejected'}
                          />
                          </>
                        ) : (
                          <>
                            <InputText
                              className={cls.inputResult}
                              type="number"
                              value={formValues[index] ?? ''}
                              title='Оценка'
                              onChange={(e) => handleInputChange(index, e.target.value)}
                            />
                            <ButtonSimple
                              className={cls.button}
                              value="Отправить"
                              variant="transparent"
                              onClickHandler={() => {
                                onUpdateStudentWorkStatus(id, formValues[index]);
                              }}
                              isDisabled={!formValues[index]}
                            />
                          </>
                        )
                      ) : (
                        studentResults?.status && <div>{`Оценка за работу: ${studentResults.status}`}</div>
                      )}
                    </>
                  ) : (
                  <>
                    {studentResults?.status === 'approved' ? (
                     <InputText
                     className={cls.inputResult}
                     type="text"
                     value="Подтверждено"
                     title='Оценка'
                     disabled={true}
                   />
                    ) : (
                      <ButtonSimple
                        value="Подтвердить"
                        variant="groupe"
                        onClickHandler={() => onUpdateStudentWorkStatus(id, 'approved')}
                        active={studentResults?.status !== 'approved'}
                        isDisabled={
                          !studentResults?.files?.length ||
                          studentResults.status === 'approved'
                        }
                      />
                    )}
                    <ButtonSimple
                      value={
                        studentResults?.status === 'rejected'
                          ? 'Отклонено'
                          : 'Отклонить'
                      }
                      variant="groupe"
                      onClickHandler={() => onUpdateStudentWorkStatus(id, 'rejected')}
                      active={studentResults?.status !== 'rejected'}
                      isDisabled={studentResults?.status === 'rejected'}
                    />
                  </>
                )}
              </div>
            </div>
          
            <AddStudentResultInput
              studentResults={studentResults}
              projectId={projectId}
              responderGuid={id}
              projectPayloadData={projectPayloadData}
            />
          
            <Divider />
            </Fragment>
          )
        })}
    </div>
  )
}

export default ResultsBlockForSupervisor;

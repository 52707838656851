/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, } from 'react'
// import search from '@/shared/assets/NavIcons/search.svg';
import message from '@/shared/assets/NavIcons/message.svg'
import cloud from '@/shared/assets/NavIcons/cloud.svg'
// import messenger from '@/legacy/assets/NavIcons/messenger.svg';
import bell from '@/shared/assets/NavIcons/bell.svg'
// import speack from '@/shared/assets/NavIcons/speack.svg';
import './HeaderNav.less'
import { useAppContext, } from '@/app/providers/AppProvider'
import clsx from 'clsx'
import { URL, } from '@/shared/constants/constants'
import useRedirect from '@/legacy/hooks/useRedirect'
import { useNotificationStats, } from '@/shared/api/notifications'

function HeaderNav ({
  color,
  messages,
}: any) {
  const redirect = useRedirect()


  const navIcons = [
    /*  {
       icon: search,
       path: '/notifications',
       source: 'no'
     },
     {
       icon: speack,
       path: '/notifications',
       source: 'no'
     }, */
    {
      icon: bell,
      path: '/notifications',
      source: 'bell',
      notify: true,
    },
    {
      source: 'no',
      icon: message,
      notify: false,
      path: `mail`,
    },
    /* {source: 'no',
      icon: message,
      notify: true,
      path: '/notifications',
    },*/
    {
      icon: cloud,
      notify: false,
      path: 'cloud',
      source: 'no',
    },
    /* {
       icon: messenger,
       notify: true,
       path: '/notifications',
       source: 'no'
     }, */
  ]


  const stats = useNotificationStats?.()
  const newAmount = useMemo(() => {
    if (stats?.data) {
      return stats.data.uiNotificationsStats.reduce((previos: any, current: any) => {
        if (current.status === 'new') {
          return { count: current.count + previos.count, }
        }
        return { count: previos.count, }
      }, { count: 0, })
    }
  }, [
    stats,
  ])


  /* const HeaderItem = ({uiStats,targets,icon}) => {

    const counter = useMemo(()=>{
      const count = uiStats?.filter(({source,status}) => targets.indexOf(source) !== -1 && status === 'new')
      .reduce((agg,item)=> (agg + item.count),0);
      return count;
    },[uiStats,targets]);

    return (
      <li>
        <div>{icon}</div>
        <sup>{counter}</sup>
      </li>
    )
  } */

  const handleRedirect = (path: any) => {
    if (path.startsWith('/')) {
      redirect(path)
    } else if (path.startsWith('cloud')) {
      window.open(`https://cloud.mail.ru`, '_blank')
    } else {
      window.open(`https://biz.mail.ru/login/tsutmb.ru`, '_blank')
    }
  }

  const HeaderItem = ({
    icon,
    path,
    uiStats,
    notify,
    source,
  }: any) => {
    const amount = newAmount?.count

    return <li className="messageIcon" onClick={ () => handleRedirect(path) }>
      <img src={ icon } alt="icon" />
      { notify && <div className={ clsx(amount && 'notification', (!amount || amount === 0) && 'hide') }>{ amount ? amount : 0 }</div> }
    </li>
  }

  const allItems = useMemo(() => {
    return navIcons.map((i, index) => <HeaderItem key={ index } { ...i } />)
  }, [
    stats,
  ])

  return (
    <nav className="headerNav">
      <ul>
        { allItems }
      </ul>
    </nav>
  )
}

export default HeaderNav

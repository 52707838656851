import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    editable: false,
    title: '№',
    index: 'number',
  },

  {
    title: 'Наименование подразделения',
    index: 'department',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'input',
  },
  // {
  //   title: 'Количество преподавателей',
  //   index: 'amount_teachers',
  //   editable: false,
  //   editType: 'inputNumbers',
  //   indexDescription: 'autoFill',
  // },
  {
    title: 'Набрано по показателю',
    index: 'calculated_amount_teachers',
    indexDescription: 'autoFill',
    editable: true,
    round: '2',
    editType: 'input'
  },
  {
    title: 'Пороговое значение',
    index: 'thresholdValue',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'input'
  },
  {
    title: 'Начислено баллов',
    index: 'scores',
    indexDescription: 'autoFill',
    editable: false,
    ceil: '0',
  },

  {
    title: 'Статус',
    editable: false,
    index: 'status',
    style: { minWidth: '11rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },

  {
    title: 'Наименование подразделения',
    index: 'department',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Количество преподавателей',
    index: 'amount_teachers',
    editable: false,
    editType: 'inputNumbers',
    indexDescription: 'autoFill',
  },
  {
    title: 'Набрано по показателю',
    index: 'calculated_amount_teachers',
    indexDescription: 'autoFill',
    editable: false,
    round: '2',
  },
  {
    title: 'Пороговое значение',
    index: 'thresholdValue',
    indexDescription: 'autoFill',
    editable: false,
  },
  {
    title: 'Начислено баллов',
    index: 'scores',
    indexDescription: 'autoFill',
    editable: false,
    ceil: '0',
  },

  {
    title: 'Причина отказа',
    editable: false,
    index: 'reason',
    style: { minWidth: '20rem', },
  },
  {
    title: 'Комментарий',
    editable: false,
    index: 'textAppeal',
    style: { minWidth: '20rem', },
  },
  {
    title: 'Статус',
    editable: false,
    index: 'status',
    style: { minWidth: '11rem', },
  },
]

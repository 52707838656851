import { useMutation, } from '@apollo/client'
import { EDIT_AVHIEVEMENT_TYPE, } from './useCreateAchievementType'


const useUpdatePublishAchievementType = () => {
  const [
    onUpdate,
  ] = useMutation(EDIT_AVHIEVEMENT_TYPE)

  const onUpdateHandler = ({
    key,
    direction_key,
    name,
    points,
    newStatus,
    jsondata,
    need_confirm,
    detached,
  }: any) => onUpdate({
    variables: {
      input: {
        key,
        direction_key,
        name,
        points,
        status: newStatus,
        jsondata,
        need_confirm,
        detached,
      },
    },
  })


  return onUpdateHandler
}


export default useUpdatePublishAchievementType

/**
 * Проверяет относятся ли данные входящего параметра к какому ни будь из "файловых типов".
 */


import { AllFileTypes, } from '@/legacy/types'

export const isFilesTypeCheck = (unknownData: AllFileTypes | unknown): unknownData is AllFileTypes => {
  if (typeof unknownData === 'object'
    && unknownData !== null) {
    if ('size' in unknownData
      || 'length' in unknownData
      || 'upload_id' in unknownData) {
      return true
    }
  }

  return false
}

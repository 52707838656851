import styles from './DocumentsBlock.module.less'
import { IconFolderOff, IconTrash, } from '@tabler/icons'
import { TitleH4, } from '../../common/GeneralElements/GeneralElements'
import PaperContainerWrapper from '../../common/PaperContainer/PaperContainerWrapper'
import { hidePostfixName, } from '../../Portfolio/AddAchivmentPopup/AddAchivmentPopup'
import { ReactComponent as PdfIcon, } from './img/pdf.svg'
import { ReactComponent as DocIcon, } from './img/doc.svg'
import { ReactComponent as XlsIcon, } from './img/xls.svg'
import { ReactComponent as UnknowIcon, } from './img/unknow.svg'
import useUpdateEcDocuments from '../../../gql/hooks/effectiveContractHooks/useUpdateEcDocuments'
import clsx from 'clsx'
import { downloadFile, } from '../../../hooks/useDownloadFile'

const DocumentsBlock = ({
  items = [],
  isWithTitle = true,
  isEditMode,
  backgroundVariant = 'light',
}: any) => {
  const { onUpdateEcDocuments, } = useUpdateEcDocuments()

  return (
    <PaperContainerWrapper
      className={ clsx({
        [styles.wrapper]: true,
        [styles.wrapper_invisible]: backgroundVariant === 'invisible',
      }) }
    >
      { isWithTitle && (
        <TitleH4>
          Документы
        </TitleH4>
      ) }

      <div className={ styles.contentWrapper }>
        { items.map((item: any) => (
          <PaperContainerWrapper
            key={ item.upload_id }
            variant="sub-paper"
            className={ styles.documentBuble }
          >
            <div className={ styles.documentItem }>
              <div style={ {
                display: 'flex',
                alignItems: 'center',
              } }>
                <div className={ styles.iconBorder }>
                  {
                    /.pdf/.test(item.name)
                      ? <PdfIcon />
                      : /.doc/.test(item.name)
                        ? <DocIcon />
                        : /.xls/.test(item.name)
                          ? <XlsIcon />
                          : <UnknowIcon />
                  }
                </div>

                <a
                  className={ clsx(
                    styles.attachedFilesList,
                    styles.attachedFilesList__link,
                    styles.attachedFilesList__name
                  ) }
                  href={ item.path }
                  onClick={ () => downloadFile(item) }
                >
                  { hidePostfixName(item.name) }
                </a>
              </div>

              { isEditMode && (
                <button onClick={ () => onUpdateEcDocuments(items.filter((filterItem: any) => filterItem !== item)) }>
                  <IconTrash className={ styles.trashButton } />
                </button>
              ) }
            </div>
          </PaperContainerWrapper>
        )) }

        { !items.length && (
          <div className={ styles.emptyBox }>
            <IconFolderOff />
            <p style={ { margin: 0, } }>Документов пока нет</p>
          </div>
        ) }
      </div>
    </PaperContainerWrapper >
  )
}


export default DocumentsBlock

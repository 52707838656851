import './PrigrammResults.less'


function ProgrammResults ({
  data,
}: any) {
  return (
    <div className="resultsWrap">
      <h2 className="semestrTitle">Результаты освоения программы</h2>
      <ul>
        { data.programresults.map((i: any) => {
          return <li key={ data.programresults.indexOf(i) }>
            <div className="resultWrap">
              <p><span>{ i.name }</span>{ i.value }</p>
            </div>
          </li>
        }) }
      </ul>
    </div>
  )
}

export default ProgrammResults

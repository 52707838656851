import { useState, } from 'react'
import './AddTheme.less'
import clsx from 'clsx'

function AddTheme () {
  const [
    inputValue,
    setInputValue,
  ] = useState('')

  return (
    <div className="addTheme">
      <h3 className="termTitle">Предложить свою тему</h3>
      <div className="filterWrap">
        <input type="text" placeholder='Введите название темы' onChange={ e => setInputValue(e.target.value) } />
        <button className={ clsx('searchBut', !inputValue && 'disabled') } disabled={ Boolean(inputValue) }>Предложить</button>
      </div>
    </div>
  )
}

export default AddTheme

import { handleCheckboxChange, updateStateArray, } from '@/legacy/components/Projects/NewProject/handlers/handlers'
import TeacherSearch from '@/legacy/components/common/TeacherSearch/TeacherSearch'
import { IconCircleX, } from '@tabler/icons'
import { DebounceInput, } from 'react-debounce-input'
import { InputNumber, } from 'antd'
import { Checkbox, } from '@mui/material'
import { format, } from 'date-fns'

const ParticipantsSection = ({ setField, setFields, setCurrentField, currentField, formFields, role, checkboxValue, setRangeDate, setCheckboxValue, calendar, setCalendar, rangeDate, }:any) => {
  const currentYear = new Date().getFullYear()
  const nextYear = currentYear + 1
  const getCheckboxLabel = (index:any, startYear:any, endYear:any, semester:any) => (
    <label style={{ marginRight: '10px',
      marginTop: '2px',
      fontSize: '18px', }}>
      <Checkbox
        onChange={() => handleCheckboxChange(index, currentYear, nextYear, setRangeDate, setCheckboxValue)}
        checked={checkboxValue === index}
      />
      {semester} {startYear}-{endYear} учебного года
    </label>
  )
  const renderCheckboxLabels = () => {
    const labels: any = []
    const startYear = currentYear
    const endYear = nextYear
    const semesters = [
      { index: 1, },
      { index: 2, },
      { index: 3, },
      { index: 4, },
    ]

    semesters.forEach(({ index, }) => {
      const semesterLabel = index <= 2
        ? `${index} полугодие ${startYear - 1}-${endYear - 1}`
        : `${index - 2} полугодие ${endYear - 1}-${endYear}`

      const isChecked = checkboxValue === index

      labels.push(
        <label
          key={index}
          style={{
            marginRight: '10px',
            marginTop: '2px',
            fontSize: '18px',
          }}
        >
          <Checkbox
            onChange={() => handleCheckboxChange(index, currentYear, nextYear, setRangeDate, setCheckboxValue)}
            checked={isChecked}
          />
          {semesterLabel} учебного года
        </label>
      )
    })

    return labels
  }
  console.log(formFields)
  return (
    <div>
      <h2>Участники проекта</h2>
      <div className="type">
        {
          ((!!formFields.customerType) && (formFields.customerType !== 'Университет') && (formFields.customerType !== 'Технопарк Державинский'))
            ? <DebounceInput
              debounceTimeout={ 500 }
              type="text"
              placeholder="Наименование организации"
              onChange={(e: any) => setField('foreignCustomer', e.target.value) }
              value={ formFields.foreignCustomer }
            />
            : <DebounceInput
              debounceTimeout={ 500 }
              type="text"
              placeholder="Заказчик"
              onChange={(e: any) => setField('customer', e.target.value) }
              value={ formFields.customer }
            />
        }
        <div className="dateWrap">
          <DebounceInput
            pattern="[a-zA-Z]*"
            onChange={(e: any) => setField('personsValue', e.target.value) }
            debounceTimeout={ 300 }
            type="text"
            value={ formFields?.supervisor?.full_name }
            placeholder="Руководитель проекта от университета"
            onClick={ () => setCurrentField('Руководитель проекта от университета') }
          />
          {
            formFields.personsValue && (
              <TeacherSearch
                field="Руководитель проекта от университета"
                handler={(item: any) => setFields({ supervisor: item,
                  personsValue: '', })}
                setCurrentField={() => setCurrentField('')}
                currentField={currentField}
                value={formFields.personsValue}
                mode="employee"
              />)
          }
        </div>
        <div className="dateWrap multiple" style={ { width: '100%', } }>
          <span>
            { formFields.consultant?.map((i: any) => (
              <div className="specTag" key={ i.id }>
                { i.full_name }

                <IconCircleX
                  onClick={() => {
                    const updatedConsultants = formFields.consultant.filter((item: any) => item.id !== i.id)
                    setField('consultant', updatedConsultants)
                  }}
                  color="#C5C5C5"
                />
              </div>
            )) }
          </span>

          <DebounceInput
            onChange={ e => setField('consultValue', e.target.value) }
            debounceTimeout={ 300 }
            type="text"
            placeholder="Консультанты (максимум 7)"
            onClick={ () => setCurrentField('консультант') }
            value={ formFields.consultValue }
          />
          { formFields.consultValue && formFields.consultant.length < 7 && (
            <TeacherSearch
              field="консультант"
              mode="employee"
              handler={(item: any) => updateStateArray(item, formFields.consultant, setFields, 'consultant', { consultValue: '', })}
              setCurrentField={() => setCurrentField('')}
              currentField={currentField}
              value={formFields.consultValue}
            />) }
        </div>

        <textarea
          placeholder="Краткое описание проекта (цели, задачи, планируемые результаты)"
          onChange={ (e:any) => setField('thesis', e.target.value) }
          defaultValue={ formFields.thesis }
        />
        <div className="dateWrap">
          <div onClick={ () => setCalendar(!calendar) }>
            <input
              type="text"
              placeholder="Сроки реализации"
              value={
                rangeDate
                  ? `С ${ format(
                    new Date(rangeDate[0]),
                    'yyyy.MM.dd'
                  ) } по ${ format(new Date(rangeDate[1]), 'yyyy.MM.dd') }`
                  : ''
              }
              onClick={ () => setCalendar(!calendar) }
              disabled
            />
          </div>
        </div>

        <InputNumber
          min={ 1 }
          max={ 300 }
          defaultValue={ formFields.participants }
          formatter={ value => `Количество участников: ${ value }` }
          autoFocus={ false }
          onChange={(e: any) => setField('participants', e) }
        />

        <div style={{ marginTop: '5px',
          display: 'grid', }}>
          {(role === 'pa_moder') ? (
            renderCheckboxLabels()
          ) : (
            <>
              {getCheckboxLabel(2, currentYear - 1, nextYear - 1, '2 полугодие')}
              {getCheckboxLabel(3, currentYear, nextYear, '1 полугодие')}
              {getCheckboxLabel(4, currentYear, nextYear, '2 полугодие')}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ParticipantsSection

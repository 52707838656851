/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, } from 'react'
import './TermCard.less'
import { Table, } from 'antd'
import { IconLock, IconPencil, } from '@tabler/icons'
// import { Popover } from 'antd';
import clsx from 'clsx'
import Tooltip from '@mui/material/Tooltip'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ThemePopup from '../ThemePopup/ThemePopup'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'

function TermCard ({
  prof,
  spec,
  type,
}: any) {
  const [
    chosTheme,
    setChoseTheme,
  ] = useState(null)
  const [
    workerThemes,
    setWorkerThemes,
  ] = useState([])
  const [
    openTest,
    setOpenTest,
  ] = useState(null)
  const [
    activeCell,
    setActiveCell,
  ] = useState(null)


  const handleTooltipClose = () => {
    setOpenTest(null)
  }

  const handleTolltipOpen = (i: any) => {
    setOpenTest(i)
    setActiveCell(i)
  }

  const role = useGetRole()

  const columns = [
    {
      title: 'Выбор',
      width: 81,
      dataIndex: 'choice',
      key: 'choice',
    },
    {
      title: 'Темы дипломных работы',
      width: 559,
      dataIndex: 'theme',
      key: 'theme',
    },
    {
      title: 'Студенты',
      width: 340,
      dataIndex: 'students',
      key: 'students',
    },
    {
      title: 'Руководители',
      width: 400,
      dataIndex: 'teachers',
      key: 'teachers',
    },
  ]


  const mokThemes = [
    {
      key: '1',
      theme: 'Методы для подсчета средней величины активов',
      students: [],
      teachers: '',
    },
    {
      key: '2',
      theme: 'Методы для подсчета средней величины ',
      students: [
        'Блохин Виктор Игоревич',
      ],
      teachers: '',
    },
    {
      key: '3',
      theme: 'Методы для подсчета средне',
      students: [],
      teachers: '',
    },
    {
      key: '4',
      theme: 'Методы для подсче',
      students: [
        'Блохин Виктор Игоревич',
      ],
      teachers: '',
    },

  ]

  const srudentContent = mokThemes.map(i => {
    return {
      key: i.key,
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      choice: i.students.length > 0 ? null : <input type="checkbox" id="how-get" checked={ i.theme === chosTheme } onChange={ () => setChoseTheme(i.theme) } />,
      theme: i.theme,
      students: <div className='studentsWrap'>{ i.students.map(i => i) }{ i.students.length > 0 ? <IconLock color="#C5C5C5" /> : <></> }</div>,
      teachers: i.teachers,
    }
  })


  const workerContent = mokThemes.map((i: any) => {
    return {
      key: i.key,
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      choice: <input type="checkbox" id="how-get" checked={ workerThemes.includes(i.theme) } onChange={ () => (workerThemes.includes(i.theme) ? workerThemes.splice(workerThemes.indexOf(i.theme), 1) : setWorkerThemes([
        // @ts-ignore: Unreachable code error
        i.theme,
        ...workerThemes,
      ])) } />,
      theme: i.theme,
      students:
        <Tooltip
          PopperProps={ {
            disablePortal: true,
          } }
          onClose={ () => handleTooltipClose }
          open={ openTest === i.key }
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="top"
          classes={ {
            tooltip: 'tooltipe',
            arrow: 'arrow',
          } }
          arrow
          title={
            <React.Fragment>
              <ThemePopup handleClose={ handleTooltipClose } />
            </React.Fragment>
          }
        ><div className={ clsx('studentsWrap', activeCell === i.key && 'activeCell', 'teacherCell') } onClick={ () => handleTolltipOpen(i.key) }>{ i.students.map((i: any) => i) }<IconPencil color="#C5C5C5" /></div></Tooltip>,
      teachers: i.teachers,
    }
  })


  return (
    <ClickAwayListener onClickAway={ handleTooltipClose }>
      <div className="termCard">
        <h3 className="termTitle">{ type === 'Курсовые работы' ? 'Курсовая' : 'Дипломная' } работа по дисциплине <span>«Анализ финансовой деятельности предприятия»</span> для студентов 3 курса направления подготовки <span>«Экономика»</span></h3>
        <div className="teachersWrap">
          <div className="teacher">
            <img src="https://i.pinimg.com/736x/70/5b/bb/705bbb820c7332b04d619f7536645753.jpg" alt="avatar" />
            <div>
              <p className="subtitle">Руководитель</p>
              <p className="value">Дубровина Елена Викторовна</p>
            </div>
          </div>
          <p className="termRules">Разнообразный и богатый опыт дальнейшее развитие различных форм деятельности представляет собой интересный эксперимент проверки направлений прогрессивного развития. Таким образом реализация намеченных плановых заданий в значительной степени обуславливает создание систем массового участия. Товарищи! консультация с широким активом играет важную роль в формировании соответствующий условий активизации.</p>
          <Table columns={ columns } dataSource={ role === 'student' || role === 'student-rakus' ? srudentContent : workerContent } />
          <div onClick={ handleTooltipClose } className={ clsx('editBackground', openTest && 'backVisible') }></div>
        </div>
      </div>
    </ClickAwayListener>
  )
}

export default TermCard

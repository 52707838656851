/* eslint-disable no-console */
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { IconBoxMultiple, IconDeviceLaptop, IconPlayerPlay, IconReportAnalytics, IconSchool, IconWallet, } from '@tabler/icons'
import useGetEcAmsThresholdValues, { addThresholdValue, } from '../../../gql/hooks/effectiveContractHooks/useGetEcAmsThresholdValues'
import useGetEcListData from '../../../gql/hooks/effectiveContractHooks/useGetEcListData'
import useGetHandlerForUpdateEcDataInTable from '../../../gql/hooks/effectiveContractHooks/useGetHandlerForUpdateEcDataInTable'
import CollapseContainer2, { AccordionInnder, } from '../../common/CollapseContainer2/CollapseContainer2'
import { addedDefaultValues, clearAchievementFields, dataAdapterForTable, dataWithThresholdValues, } from '../amsUtils/amsUtils'
import AppealBlock from '../AppealBlock/AppealBlock'
import CardContainer from '../cards/CardContainer'
import TableContainer from '../TableContainer/TableContainer'
import { tableSource as headerFields_22_3, } from '../constants/tablesFormData/table_22_3'
import { tableSource as headerFields_22_4, } from '../constants/tablesFormData/table_22_4'
import { tableSource as headerFields_22_5, } from '../constants/tablesFormData/table_22_5'
import { tableSource as headerFields_22_6, } from '../constants/tablesFormData/table_22_6'
import { tableSource as headerFields_22_7, } from '../constants/tablesFormData/table_22_7'
import { tableSource as headerFields_22_8, } from '../constants/tablesFormData/table_22_8'


const EducationalActivitiesTab = ({
  contentType,
  moderateId,
  dataTypeUpdated,
  setDataTypeUpdated,
  returnDataForAddNewRow,
  changeStatusHandlers,
  returnDataForDisplayRejectReason,
  userRole,
}: any) => {
  const table_22_3 = useGetEcListData(moderateId, 'table_22_3', dataTypeUpdated, setDataTypeUpdated)
  const table_22_4 = useGetEcListData(moderateId, 'table_22_4', dataTypeUpdated, setDataTypeUpdated)
  const table_22_5 = useGetEcListData(moderateId, 'table_22_5', dataTypeUpdated, setDataTypeUpdated)
  const table_22_6 = useGetEcListData(moderateId, 'table_22_6', dataTypeUpdated, setDataTypeUpdated)
  const table_22_7 = useGetEcListData(moderateId, 'table_22_7', dataTypeUpdated, setDataTypeUpdated)
  const table_22_8 = useGetEcListData(moderateId, 'table_22_8', dataTypeUpdated, setDataTypeUpdated)


  const { onUpdateRowHandler, } = useGetHandlerForUpdateEcDataInTable()

  const thresholdValues = useGetEcAmsThresholdValues()


  return (
    <CollapseContainer2>
      { /^director-|-admin$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconSchool }
          headerText='Удельный вес численности обучающихся по программам магистратуры в общей численности приведенного контингента обучающихся по основным образовательным программам высшего образования'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_3 }
                // isEditable={ false }
                headerFields={ addThresholdValue({
                  thresholdValues: thresholdValues?.table_22_3,
                  headerFields: headerFields_22_3,
                }) }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_3, headerFields_22_3, 'table_22_3', moderateId)),
                    thresholdValues: thresholdValues?.table_22_3,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_22_3?.length && (
                <AppealBlock
                  data={ table_22_3?.at(-1) }
                />
              ) }
            </>
          ) }


          { contentType === 'card' && (
            <CardContainer
              cardsData={ table_22_3 }
              cardFields={
                addThresholdValue({
                  thresholdValues: thresholdValues?.table_22_3,
                  headerFields: clearAchievementFields(headerFields_22_3),
                })
              }
            />
          ) }
        </AccordionInnder>
      ) }


      { /^director-|-admin$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconBoxMultiple }
          headerText='Комплектность групп очной формы обучения'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_4 }
                // isEditable={ false }
                headerFields={ addThresholdValue({
                  thresholdValues: thresholdValues?.table_22_4,
                  headerFields: headerFields_22_4,
                }) }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_4, headerFields_22_4, 'table_22_4', moderateId)),
                    thresholdValues: thresholdValues?.table_22_4,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_22_4?.length && (
                <AppealBlock
                  data={ table_22_4?.at(-1) }
                />
              ) }
            </>
          ) }
        </AccordionInnder>
      ) }


      { /^director-|-admin$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconWallet }
          headerText='Количество студентов стипендиатов. Количество студентов-стипендиатов, победители/призеры олимпиад и конкурсов и абитуриенты-победители всероссийских олимпиад'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_5 }
                // isEditable={ /-admin$/.test(userRole) }
                // popUpControllHandler={ changeStatusHandlers?.popUpControllHandler }
                // getRowData={ changeStatusHandlers?.getRowData }
                headerFields={ addThresholdValue({
                  thresholdValues: thresholdValues?.table_22_5,
                  headerFields: headerFields_22_5,
                }) }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_5, headerFields_22_5, 'table_22_5', moderateId)),
                    thresholdValues: thresholdValues?.table_22_5,
                  })
                }
              // onUpdateRowHandler={ onUpdateRowHandler }
              // extra={ {
              //   returnDataForAddNewRow: returnDataForAddNewRow?.bind(
              //     null, 'table_22_5', clearAchievementFields(headerFields_22_5)),

              //   returnDataForDisplayRejectReason: returnDataForDisplayRejectReason,
              // } }
              />

              { /^director-/.test(userRole) && !!table_22_5?.length && (
                <AppealBlock
                  data={ table_22_5?.at(-1) }
                />
              ) }
            </>
          ) }


          { contentType === 'card' && (
            <CardContainer cardsData={ table_22_5 } cardFields={ clearAchievementFields(headerFields_22_5) } />
          ) }
        </AccordionInnder>
      ) }


      { /^director-|-admin$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconDeviceLaptop }
          headerText='Доля онлайн-курсов, включенных в учебные планы по образовательным программам ВО, реализуемых в Университете'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_6 }
                // isEditable={ /-admin$/.test(userRole) }
                // popUpControllHandler={ changeStatusHandlers?.popUpControllHandler }
                // getRowData={ changeStatusHandlers?.getRowData }
                headerFields={ addThresholdValue({
                  thresholdValues: thresholdValues?.table_22_6,
                  headerFields: headerFields_22_6,
                }) }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_6, headerFields_22_6, 'table_22_6', moderateId)),
                    thresholdValues: thresholdValues?.table_22_6,
                  })
                }
              // onUpdateRowHandler={ onUpdateRowHandler }
              // extra={ {
              //   returnDataForAddNewRow: returnDataForAddNewRow?.bind(
              //     null, 'table_22_6', clearAchievementFields(headerFields_22_6)),

              //   returnDataForDisplayRejectReason: returnDataForDisplayRejectReason,
              // } }
              />

              { /^director-/.test(userRole) && !!table_22_6?.length && (
                <AppealBlock
                  data={ table_22_6?.at(-1) }
                />
              ) }
            </>
          ) }


          { contentType === 'card' && (
            <CardContainer cardsData={ table_22_6 } cardFields={ clearAchievementFields(headerFields_22_6) } />
          ) }
        </AccordionInnder>
      ) }


      { /^director-|-admin$|^aup-pfu-moder$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconReportAnalytics }
          headerText='Объем дополнительных образовательных услуг, оказанных центрами, созданными в институте, на факультете'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_7 }
                // isEditable={ /-admin$|^aup-pfu-moder$/.test(userRole) }
                // popUpControllHandler={ changeStatusHandlers?.popUpControllHandler }
                // getRowData={ changeStatusHandlers?.getRowData }
                headerFields={ addThresholdValue({
                  thresholdValues: thresholdValues?.table_22_7,
                  headerFields: headerFields_22_7,
                }) }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_7, headerFields_22_7, 'table_22_7', moderateId)),
                    thresholdValues: thresholdValues?.table_22_7,
                  })
                }
              // onUpdateRowHandler={ onUpdateRowHandler }
              // extra={ {
              //   returnDataForAddNewRow: returnDataForAddNewRow?.bind(
              //     null, 'table_22_7', clearAchievementFields(headerFields_22_7)),

              //   returnDataForDisplayRejectReason: returnDataForDisplayRejectReason,
              // } }
              />

              { /^director-/.test(userRole) && !!table_22_7?.length && (
                <AppealBlock
                  data={ table_22_7?.at(-1) }
                />
              ) }
            </>
          ) }


          { contentType === 'card' && (
            <CardContainer cardsData={ table_22_7 } cardFields={ clearAchievementFields(headerFields_22_7) } />
          ) }
        </AccordionInnder>
      ) }


      { /^director-|-admin$|^aup-pfu-moder$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconPlayerPlay }
          headerText='Количество выполненных обучающимися проектов и стартапов на возмездной основе'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_8 }
                // isEditable={ /-admin$|^aup-pfu-moder$/.test(userRole) }
                // popUpControllHandler={ changeStatusHandlers?.popUpControllHandler }
                // getRowData={ changeStatusHandlers?.getRowData }
                headerFields={ addThresholdValue({
                  thresholdValues: thresholdValues?.table_22_8,
                  headerFields: headerFields_22_8,
                }) }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_8, headerFields_22_8, 'table_22_8', moderateId)),
                    thresholdValues: thresholdValues?.table_22_8,
                  })
                }
              // onUpdateRowHandler={ onUpdateRowHandler }
              // extra={ {
              //   returnDataForAddNewRow: returnDataForAddNewRow?.bind(
              //     null, 'table_22_8', clearAchievementFields(headerFields_22_8)),

              //   returnDataForDisplayRejectReason: returnDataForDisplayRejectReason,
              // } }
              />

              { /^director-/.test(userRole) && !!table_22_8?.length && (
                <AppealBlock
                  data={ table_22_8?.at(-1) }
                />
              ) }
            </>
          ) }


          { contentType === 'card' && (
            <CardContainer
              cardsData={ table_22_8 }
              cardFields={ clearAchievementFields(headerFields_22_8) }
              returnDataForDisplayRejectReason={ returnDataForDisplayRejectReason }
            />
          ) }
        </AccordionInnder>
      ) }
    </CollapseContainer2>
  )
}


export default EducationalActivitiesTab

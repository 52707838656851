import AdministratorTemplate from './pages/AdministratorTemplate/AdministratorTemplate'
import ModeratorTemplate from './pages/ModeratorTemplate/ModeratorTemplate'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'


const ModerateAchievements = () => {
  const role = useGetRole()


  return (
    <>
      { /^urvo-admin$|^urvo-2-moder$/.test(role) && (
        <AdministratorTemplate />
      ) }

      { /^dms-moder$|^ufpn-moder$|^dm-moder$|^uonid-moder$|^ukp-moder$|^tpd-moder$|^urvo-moder$/.test(role) && (
        <ModeratorTemplate />
      ) }
    </>
  )
}


export default ModerateAchievements

import { TableCellType, } from '@/legacy/types'
import { table_cat_1_2, } from '../../popUpInputsCatalogs'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  // {
  //   title: 'Вид курса',
  //   index: 'kind_of_education',
  //   editable: true,
  //   editType: 'input',
  // },
  // {
  //   title: 'Дата начала курса',
  //   index: 'start',
  //   indexDescription: 'date',
  //   editable: true,
  //   editType: 'calendar',
  // },
  // {
  //   title: 'Дата окончаня курса',
  //   index: 'end',
  //   indexDescription: 'date',
  //   editable: true,
  //   editType: 'calendar',
  // },
  // {
  //   title: 'Тип документа',
  //   index: 'type_of_document',
  //   editable: true,
  //   editType: 'input',
  // },
  // {
  //   title: 'Серия документа',
  //   index: 'doc_series',
  //   editable: true,
  //   editType: 'input',
  // },
  // {
  //   title: 'Номер документа',
  //   index: 'doc_number',
  //   editable: true,
  //   editType: 'input',
  // },
  {
    title: 'Название курса',
    index: 'course',
    editable: true,
    editType: 'input',
    style: { minWidth: '12rem', },
  },
  {
    title: 'Категория курсов',
    index: 'course_of_category',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_1_2.course_of_category,
  },
  // {
  //   title: 'Название учебного заведения',
  //   index: 'universityName',
  //   editable: true,
  //   editType: 'input',
  //   style: { minWidth: '16rem', },
  // },
  {
    title: 'Дата прохождения обучения',
    index: 'date_of_issue',
    indexDescription: 'date',
    editable: true,
    editType: 'calendar',
  },
  // {
  //   title: 'Ссылка на прикрепленный документ',
  //   index: 'attachedFile',
  //   editable: true,
  //   editType: 'upload',
  // },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '12rem', },
    editable: false,
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    editable: false,
    index: 'number',
  },
  // {
  //   title: 'Вид курса',
  //   index: 'kind_of_education',
  //   editable: true,
  //   editType: 'input',
  // },
  // {
  //   title: 'Дата начала курса',
  //   index: 'start',
  //   indexDescription: 'date',
  //   editable: true,
  //   editType: 'calendar',
  // },
  // {
  //   title: 'Дата окончаня курса',
  //   index: 'end',
  //   indexDescription: 'date',
  //   editable: true,
  //   editType: 'calendar',
  // },
  // {
  //   title: 'Тип документа',
  //   index: 'type_of_document',
  //   editable: true,
  //   editType: 'input',
  // },
  // {
  //   title: 'Серия документа',
  //   index: 'doc_series',
  //   editable: true,
  //   editType: 'input',
  // },
  // {
  //   title: 'Номер документа',
  //   index: 'doc_number',
  //   editable: true,
  //   editType: 'input',
  // },
  {
    title: 'Название курса',
    index: 'course',
    editable: true,
    editType: 'input',
    style: { minWidth: '15rem', },
  },
  {
    title: 'Категория курсов',
    index: 'course_of_category',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_1_2.course_of_category,
  },
  // {
  //   title: 'Название учебного заведения',
  //   index: 'universityName',
  //   editable: true,
  //   editType: 'input',
  //   style: { minWidth: '16rem', },
  // },
  {
    title: 'Дата прохождения обучения',
    index: 'date_of_issue',
    indexDescription: 'date',
    editable: true,
    editType: 'calendar',
  },
  // {
  //   title: 'Ссылка на прикрепленный документ',
  //   index: 'attachedFile',
  //   editable: true,
  //   editType: 'upload',
  // },
  {
    editable: false,
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editType: 'input',
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    editable: false,
    style: { minWidth: '20rem', },
    editType: 'input',
  },
  {
    title: 'Статус',
    editable: false,
    index: 'status',
    style: { minWidth: '10rem', },
  },
]

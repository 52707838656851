import './NoContracts.less'

function NoContracts ({
  title,
}: any): JSX.Element {
  return (
    <div className="noContracts">{ title }</div>
  )
}

export default NoContracts

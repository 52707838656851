// TODO: Выпилить этот модуль

import { EcAchivementStatus, EcAppealStatus, EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { EC_CREATE_DATA, } from '@/legacy/gql/hooks/effectiveContractHooks/useCreateEcData'
import { UUID, } from '@/legacy/types'
import { useMutation, } from '@apollo/client'


interface CreateEcDataDeprecated {
  dataType: EcTableName
  data: object
  status: EcAchivementStatus | EcAppealStatus
  user_ids: UUID[] | null
}


/** @deprecated пролет с параметрами и возвращаемым callback'ом */
const useCreateEcDataDeprecated = () => {
  const [
    create,
  ] = useMutation(EC_CREATE_DATA)

  const createData = ({
    dataType,
    data,
    status = EcAchivementStatus.PENDING,
    user_ids = null,
  }: CreateEcDataDeprecated) => create({
    variables: {
      dataType,
      data: {
        ...data,
        status,
      },
      user_ids,
    },
  })

  return createData
}


export default useCreateEcDataDeprecated

import { useEffect, useMemo, } from 'react'
import './TaskPage.less'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import TaskCard from './TaskCard/TaskCard'
import ResultsTask from './ResultsTask/ResultsTask'
import CommentsTask from './CommentsTask/CommentsTask'
import { useSelector, } from 'react-redux'
import { useGetTask, } from '../../../gql/hooks/useProjects'


function TaskPage () {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const task = useSelector(state => state.proj.currentTask)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const newTask = useSelector(state => state.subscriptions.taskChanged)


  const { refetch, error, data, loading, } = useGetTask(task.task_id)

  const content = useMemo(() => {
    if (error) {
      return <div>.....error....</div>
    } else if (loading) {
      return <div>....loading...</div>
    } else if (data) {
      const task = data.paGetTask[0]
      return <div className="studentInner task">
        <TaskCard task={ task } />
        <ResultsTask type="active" task={ task } />
        <CommentsTask task={ task } />
      </div>
    }
  }, [
    error,
    data,
    loading,
  ])

  useEffect(() => {
    refetch()
  }, [
    newTask,
    refetch,
  ])


  return (
    <div className="InnerPage allProjects">
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
        {
          value: 'Учебный процесс',
          path: '/projects-all',
        },
        {
          value: 'Проектная деятельность',
          path: '/projects-all',
        },
        {
          value: 'Проект',
          path: '/project',
        },
      ] } loc="Задача" />
      { content }
      {/* <ToastContainer /> */ }
    </div>
  )
}

export default TaskPage


import { gql, useQuery, } from '@apollo/client'

const CREATE_HTML_BLANK = gql`
  query Query($createHtmlBlankId: String!) {
    createHTMLBlank(id: $createHtmlBlankId)
  }
`

export const useCreateHtmlBlank = (createHtmlBlankId: string) => {
  const { data, loading, error, } = useQuery(CREATE_HTML_BLANK, {
    variables: {
      createHtmlBlankId,
    },
  })

  return { data,
    loading,
    error, }
}

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './PortfolioAchievementCommentPopup.module.less'
import { useMemo, } from 'react'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import { TitleH3, } from '../../common/GeneralElements/GeneralElements'
import InputTextDeprecated, { useInputText, } from '../../common/InputTextDeprecated/InputTextDeprecated'
import TextArea from '../../common/TextArea/TextArea'
import useGetRole from '../../../redux/hooks/common/useGetRole'
import clsx from 'clsx'
import useUpdateAchievement from '../../../gql/hooks/portfolio/useUpdateAchievement'
import CommentsHistory from './CommentsHistory/CommentsHistory'
import { conversationHistory, } from './CommentsHistory/commentsHistoryUtils'


const PortfolioAchievementCommentPopup = ({
  achievement,
  popUpCloseHandler,
}: any) => {
  const newCommentInput = useInputText({ initialValue: '', })
  const userRole = useGetRole()
  const updateAchievement = useUpdateAchievement()
  const makedHistory = conversationHistory(achievement.comment)


  const eventName = useMemo(() => {
    return achievement.fields.find((field: any) => new RegExp(eventNamesTemlate).test(field.name))?.value
  }, [
    achievement,
  ])
  return <>
    <TitleH3>
      { achievement.direction }
    </TitleH3>

    <InputTextDeprecated
      withPlaceholder
      defaultHint='Название мероприятия'
      disabled
      value={ eventName }
    />

    <br />

    <CommentsHistory history={ makedHistory } achievement_id={ achievement.achievement_id} />

    <br />
    <br />

    <TextArea
      withPlaceholder
      defaultHint="Оставить новое сообщение"
      rows={ 5 }
      { ...newCommentInput }
    />

    <br />

    <div className={ clsx(
      styles.buttonWrapper,
      styles.buttonWrapper_toRight
    ) }>
      <ButtonSimple
        variant="alone"
        active
        value="Добавить комментарий"
        onClickHandler={ () => {
          const newComment = [
            ...makedHistory,
            {
              authorName: (/moder$/.test(userRole) && 'Модератор') || (/^student/.test(userRole) && 'Студент'),
              message: newCommentInput.value,
            },
          ]

          updateAchievement({
            achievement_id: achievement.achievement_id,
            comment: { ...newComment, },
          })
        } }

        isDisabled={ newCommentInput.value < 1 || userRole === 'portfolio-kurator'}
      />
    </div>
  </>
}


const eventNamesTemlate = (((/^Наименование|^Название|^Вид/ig)))


export default PortfolioAchievementCommentPopup

import { TitleH3, } from '../../../../common/GeneralElements/GeneralElements'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import ButtonSimple, { IconComponent, } from '../../../../common/ButtonSimple/ButtonSimple'
import { IconCircleCheck, IconCircleX, } from '@tabler/icons'
import { useEffect, useState, } from 'react'
import TextArea from '../../../../common/TextArea/TextArea'
import { useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useGetEcListData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useLocation, } from 'react-router-dom'


const PopUpAppealContent_4_1 = ({
  data,
  onClosePopUp,
}: any) => {
  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  const [
    ids,
  ] = useState(moderateId)


  const [
    basis,
    setBasis,
  ] = useState('')


  const [
    textAppeal,
    setTextAppeal,
  ] = useState('')
  const [
    currentAppealId,
    setCurrentAppealId,
  ] = useState(data.rec_id)
  const [
    currentAchievementId,
    setCurrentAchievementId,
  ] = useState(null)
  const [
    rejectAchievement,
    setRejectAchievement,
  ] = useState(false)
  const [
    reason,
    setReason,
  ] = useState('')
  const [
    scores,
    setScores,
  ] = useState('')


  useEffect(() => {
    setBasis(data.basis)
    setScores(data.scores)

    setTextAppeal(data.textAppeal)
    setCurrentAppealId(data.rec_id)
  }, [
    data,
  ])


  const [
    currentAchievement,
    setCurrentAchievement,
  ] = useState(null)
  const [
    currentAppeal,
    setCurrentAppeal,
  ] = useState(null)

  const getCurrentAppeal = useGetEcListData({
    rec_id: currentAppealId,
  })

  const getCurrentAchievement = useGetEcListData({
    rec_id: currentAchievementId,
  })

  useEffect(() => {
    if (getCurrentAppeal?.data) {
      setCurrentAppeal(
        getCurrentAppeal?.data?.ecListData[0]?.data)

      setCurrentAchievementId(getCurrentAppeal?.data?.ecListData[0]?.data?.rec_id)
    }
  }, [
    getCurrentAppeal.loading,
    getCurrentAppeal.data,
  ])

  useEffect(() => {
    if (getCurrentAchievement?.data && currentAchievementId) {
      setCurrentAchievement(
        getCurrentAchievement?.data?.ecListData[0]?.data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCurrentAchievement.loading,
    getCurrentAchievement.data,
  ])


  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (rec_id: any, newData: any, ids: any) => onUpdateRow({
    variables: {
      rec_id: rec_id,
      new_data: newData,
      user_ids: ids,
    },
  })


  useEffect(() => {
    setRejectAchievement(false)
  }, [
    data,
  ])


  return (
    <>
      <TitleH3>
        Неисполнение индивидуального плана развития.
      </TitleH3>
      <p>Внесите требуемые изменения и подтвердие или отклоните апелляцию</p>
      <p>Комментарий: <i><b>{ textAppeal }</b></i></p>
      <br />


      { !rejectAchievement ? (
        <>
          <InputTextDeprecated
            defaultHint="Основание"
            value={ basis }
            onChangeValue={ setBasis }
          /><br />


          <InputTextDeprecated
            defaultHint="Баллы"
            value={ scores }
            onChangeValue={ setScores }
            numbersOnly
          /><br />
        </>
      ) : false }


      { rejectAchievement ? (
        <>
          <TextArea
            defaultHint='По какой причине вы хотите отклонить заявку?'
            withPlaceholder={ false }
            value={ reason }
            onChangeValue={ setReason }
          />

          <br />

          <div style={ {
            display: 'flex',
            justifyContent: 'flex-end',
          } }>
            <ButtonSimple
              value="Отменить"
              variant="groupe"
              styles={ {
                marginRight: '1rem',
                backgroundColor: '#F5F6FA',
              } }
              onClickHandler={ () => {
                setRejectAchievement(false)
              } }
            />

            <ButtonSimple
              value={ (
                <IconComponent
                  Icon={ IconCircleX }
                  iconStyles={ { color: '#FF5E5E', } }
                  text="Отклонить"
                />
              ) }
              variant="groupe"
              isDisabled={ reason.split(' ').length < 3 }
              onClickHandler={ () => {
                onUpdateRowHandler(
                  currentAppealId,
                  {
                    // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                    ...currentAppeal,
                    status: 'appealRejected',
                    appealConclusion: reason,
                  },
                  ids
                )

                onClosePopUp()
              } }
            />
          </div>
        </>
      ) : false }

      { !rejectAchievement ? (
        <div style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }>
          <ButtonSimple
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            isDisabled={ ids.length < 1 }
            value={ (
              <IconComponent
                Icon={ IconCircleX }
                iconStyles={ { color: '#FF5E5E', } }
                text="Отклонить"
              />
            ) }
            variant="groupe"
            styles={ { marginRight: '1rem', } }
            onClickHandler={ () => setRejectAchievement(true) }
          />

          <ButtonSimple
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            isDisabled={ ids.length < 1 }
            value={ (
              <IconComponent
                Icon={ IconCircleCheck }
                text="Подтвердить"
              />
            ) }
            variant="groupe"
            active
            onClickHandler={ () => {
              onUpdateRowHandler(
                currentAchievementId,
                {
                  // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                  ...currentAchievement,
                  basis: basis,
                  scores: scores,
                },
                ids
              )

              onUpdateRowHandler(
                currentAppealId,
                {
                  // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                  ...currentAppeal,
                  status: 'appealApproved',
                  appealConclusion: 'Ваша апелляция удовлетворена',
                },
                ids
              )

              onClosePopUp()
            } }
          />
        </div>
      ) : false }
    </>
  )
}


export default PopUpAppealContent_4_1

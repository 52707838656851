import { gql, } from '@/__generated__'
import { useMutation, } from '@apollo/client'

const EC_DELETE_DATA = gql(`
  mutation EcDeleteData($rec_id:Int!,$dataType:String!){
    ecDeleteData(rec_id:$rec_id,dataType:$dataType)
  }
`)

export interface EcDeleteData {
  rec_id: number
  dataType: string
}
const useDeleteEcData = () => {
  const [
    onDeleteEcData,
  ] = useMutation(EC_DELETE_DATA)

  const onDeleteEcDataHandler = ({ rec_id, dataType, }: EcDeleteData) => onDeleteEcData({
    variables: {
      rec_id,
      dataType,
    },
  })


  return { onDeleteEcDataHandler, }
}


export default useDeleteEcData

import { gql, } from '@/__generated__/gql'
import { useQuery, } from '@apollo/client'


const GET_DISCIPLINES = gql(`
query GetDisciplines($group: String!) {
  disciplines(group: $group)
      }
`)

const GET_JOURNAL = gql(`
query GetStudentJournal($group: String!, $discipline: String!, $type: String!, $semester: Int) {
  getStudentJournal(group: $group, discipline: $discipline, type: $type, semester: $semester)
}
`)

const GET_GROUPS = gql(`
query TeacherDisciplineGroups {
  teacherDisciplineGroups(testID:"010c0df0-3dfc-11e6-80bd-7824af85d229")
}
`)

export const useDisciplines = (group: any) => {
  return useQuery(GET_DISCIPLINES, {
    variables: { group, },
  })
}
export const useStudentJournal = (group: any, discipline: any, type: any, semester: any) => {
  return useQuery(GET_JOURNAL, {
    variables: {
      group,
      discipline,
      type,
      semester,
    },
  })
}

export const useGetGroups = () => useQuery(GET_GROUPS)

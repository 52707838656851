/**
 * Среди всех полей достижения, ищет то, в котором хранятся данные с участниками данного достижения.
 *
 * @remarks
 * Нужно в основном для того, что бы обновить поле user_ids в котором хранятся GUID'ы людей которые видят данное достижение в своем разделе ЭК.
 * Среди участников могут попадаться люди у которых не указаны GUID'ы (сотрудники других организаций), такие участники будут отфильтрованы.
 *
 * @param allAchievementFields - объект со всеми полями по достижению
 * @returns Строковый массив с GUID'ами участников достижения
 */


import { AchievementMixedParticipant, } from '@/legacy/components/EffectiveContract/types/achievementParticipant'
import { UUID, } from '@/legacy/types'


const findParticipants = (allAchievementFields: { [key: string]: unknown }): UUID[] => {
  const participants = Object.values(allAchievementFields)
    .filter(field => typeof field === 'object'
      && Array.isArray(field)
      && field.length > 0
      && typeof field[0] === 'object'
      && 'fio' in field[0]
      && 'guid' in field[0]
    )[0] as AchievementMixedParticipant[] | undefined

  if (participants) {
    const participantsGuids = participants.map(participant => participant.guid)

    const filteredParticipantsGuids = participantsGuids.filter(participant => typeof participant === 'string' && participant !== '' && participant !== 'null') as string[]

    return filteredParticipantsGuids
  } return []
}


export default findParticipants

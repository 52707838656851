import './SuccesPopup.less'
import { IconFileCheck, } from '@tabler/icons'
import { Button, } from 'antd'
import { useSelector, useDispatch, } from 'react-redux'
import clsx from 'clsx'
import useRedirect from '@/legacy/hooks/useRedirect'
import { openSucces, } from '@/legacy/redux/MfcSlices/MfcSlice'

function SuccesPopup () {
  const redirect = useRedirect()

  const dispatch = useDispatch()
  // const [isOpen, setIsOpen] = useState(false);
  const isOpen = useSelector((state: any) => state.mfc.isSuccessOpen)

  function closeSuccess () {
    redirect('/my-references')
    dispatch(openSucces(false))
  }

  return (
    <div className={ clsx('successHidden', isOpen && 'sucessWrap') }>
      <div className="sucess">
        <IconFileCheck size={ 60 } color={ '#7671DD' } />
        <h2 className="title">Ваша заявка отправленна!</h2>
        <p className="info">Проверьте статус о готовности на странице заказа справок.</p>
        <Button onClick={ closeSuccess } className={ 'button' }>Xорошо</Button>
      </div>
    </div>
  )
}

export default SuccesPopup

import { useMemo, } from 'react'
import './StudentGeneralRate.less'
import { useAchievementRating, } from '../../../../gql/hooks/useAchievements'


function StudentGeneralRate () {
  const { data, error, loading, } = useAchievementRating()

  const generalRate = useMemo(() => {
    if (loading) {
      return <div>.....loading...</div>
    } else if (error) {
      return <div>....error...</div>
    } else if (data) {
      const { rating, available, position, max, } = data.achievementRating

      const textSummory = [
        {
          title: 'Всего баллов:',
          value: rating ? rating : 0,
        },
        {
          title: 'Сумма баллов, учитывающаяся в рейтинге:',
          value: available ? available : 0,
          /* percent: 0, */
        },
        {
          title: 'Ожидают подтверждения:',
          value: (rating - available) ? (rating - available) : 0,
          /* percent: 0, */
        },
      ]
      return <div style={ {
        display: 'flex',
        justifyContent: 'space-between',
      } }>
        <div className="textSummoryWrap">
          <p className="summoryTitle">Место в рейтинге:  </p>
          <div className="textSummory">{ `${ position ? position : 0 }/${ max }` }</div>
        </div>
        <div style={ { display: 'flex', } }>
          { textSummory.map((i: any) => <p key={ i.title } className="effective-contract__description-item" style={ {
            padding: '8px 10px',
            width: 'fit-content',
            marginRight: '10px',
          } }>
            { i.title } { i.value } { i.percent && `(${ i.percent })%` }
          </p>) }
        </div>
      </div>
    }
  }, [
    data,
    error,
    loading,
  ])


  return (
    <div className="generalHeader">
      { generalRate }
    </div>
  )
}

export default StudentGeneralRate

interface Data {
  [key: string]: unknown
}

// TODO Переписать интерфейс для данных

// interface DataWithJournalQuartile extends Data {
//   quartile?: {
//     journal_quartile: string
//   }
// }


export const getScores_1_1 = (data: Data) => {
  if (
    (data.type_work === 'Учебник' || data.type_work === 'Монография')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 1 || Number(data.author_count) === 1)
  ) {
    return 150
  } else if (
    (data.type_work === 'Учебник' || data.type_work === 'Монография')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 2 || Number(data.author_count) === 2)
  ) {
    return 75
  } else if (
    (data.type_work === 'Учебник' || data.type_work === 'Монография')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 3 || Number(data.author_count) === 3)
  ) {
    return 50
  } else if (
    (data.type_work === 'Учебник' || data.type_work === 'Монография')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 4 || Number(data.author_count) === 4)
  ) {
    return 37
  } else if (
    (data.type_work === 'Учебник' || data.type_work === 'Монография')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 5 || Number(data.author_count) === 5)
  ) {
    return 30
  } else if (
    (data.type_work === 'Учебник' || data.type_work === 'Монография')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 6 || Number(data.author_count) === 6)
  ) {
    return 25
  } else if (
    (data.type_work === 'Учебник' || data.type_work === 'Монография')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 1 || Number(data.author_count) === 1)
  ) {
    return 50
  } else if (
    (data.type_work === 'Учебник' || data.type_work === 'Монография')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 2 || Number(data.author_count) === 2)
  ) {
    return 25
  } else if (
    (data.type_work === 'Учебник' || data.type_work === 'Монография')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 3 || Number(data.author_count) === 3)
  ) {
    return 17
  } else if (
    (data.type_work === 'Учебник' || data.type_work === 'Монография')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 4 || Number(data.author_count) === 4)
  ) {
    return 12
  } else if (
    (data.type_work === 'Учебник' || data.type_work === 'Монография')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 5 || Number(data.author_count) === 5)
  ) {
    return 10
  } else if (
    (data.type_work === 'Учебник' || data.type_work === 'Монография')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 6 || Number(data.author_count) === 6)
  ) {
    return 8
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 1 || Number(data.author_count) === 1)
  ) {
    return 140
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 2 || Number(data.author_count) === 2)
  ) {
    return 70
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 3 || Number(data.author_count) === 3)
  ) {
    return 47
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 4 || Number(data.author_count) === 4)
  ) {
    return 35
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 5 || Number(data.author_count) === 5)
  ) {
    return 28
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 6 || Number(data.author_count) === 6)
  ) {
    return 23
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 1 || Number(data.author_count) === 1)
  ) {
    return 120
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 2 || Number(data.author_count) === 2)
  ) {
    return 60
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 3 || Number(data.author_count) === 3)
  ) {
    return 40
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 4 || Number(data.author_count) === 4)
  ) {
    return 30
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 5 || Number(data.author_count) === 5)
  ) {
    return 24
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 6 || Number(data.author_count) === 6)
  ) {
    return 20
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Региональное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 1 || Number(data.author_count) === 1)
  ) {
    return 40
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Региональное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 2 || Number(data.author_count) === 2)
  ) {
    return 20
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Региональное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 3 || Number(data.author_count) === 3)
  ) {
    return 13
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Региональное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 4 || Number(data.author_count) === 4)
  ) {
    return 10
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Региональное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 5 || Number(data.author_count) === 5)
  ) {
    return 8
  } else if (
    (data.type_work === 'Учебное пособие')
    && data.publish === 'Региональное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 6 || Number(data.author_count) === 6)
  ) {
    return 7
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 1 || Number(data.author_count) === 1)
  ) {
    return 140
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 2 || Number(data.author_count) === 2)
  ) {
    return 70
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 3 || Number(data.author_count) === 3)
  ) {
    return 47
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 4 || Number(data.author_count) === 4)
  ) {
    return 35
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 5 || Number(data.author_count) === 5)
  ) {
    return 28
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Зарубежное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 6 || Number(data.author_count) === 6)
  ) {
    return 23
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 1 || Number(data.author_count) === 1)
  ) {
    return 120
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 2 || Number(data.author_count) === 2)
  ) {
    return 60
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 3 || Number(data.author_count) === 3)
  ) {
    return 40
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 4 || Number(data.author_count) === 4)
  ) {
    return 30
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 5 || Number(data.author_count) === 5)
  ) {
    return 24
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Российское издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 6 || Number(data.author_count) === 6)
  ) {
    return 20
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Региональное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 1 || Number(data.author_count) === 1)
  ) {
    return 40
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Региональное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 2 || Number(data.author_count) === 2)
  ) {
    return 20
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Региональное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 3 || Number(data.author_count) === 3)
  ) {
    return 13
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Региональное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 4 || Number(data.author_count) === 4)
  ) {
    return 10
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Региональное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 5 || Number(data.author_count) === 5)
  ) {
    return 8
  } else if (
    (data.type_work === 'Учебно-методическое пособие')
    && data.publish === 'Региональное издательство'
    && Number(data.volume) >= 10
    && (Number(data.countAuthors) === 6 || Number(data.author_count) === 6)
  ) {
    return 7
  }


  return 0
}

export const getScores_2_1 = (data: Data) => {
  if (data.presentName === 'Премия Президента') {
    return 168
  } else if (data.presentName === 'Премия Правительства') {
    return 84
  }
  return 0
}

export const getScores_2_2 = (data: Data) => {
  if (data.level === 'B2') {
    return 42
  } else if (data.level === 'C1') {
    return 84
  } else if (data.level === 'C2') {
    return 168
  } return 0
}


export const getScores_2_4 = (data: Data) => {
  if (data.countAuthors === '1') {
    return 80
  } else if (data.countAuthors === '2') {
    return 40
  } else if (data.countAuthors === '3') {
    return 26
  } else if (data.countAuthors === '4') {
    return 20
  } else if (data.countAuthors === '5') {
    return 16
  } else if (data.countAuthors === '6') {
    return 14
  } return 0
}


export const getScores_2_41 = (data: Data) => {
  if (data.language === 'Русский'
    && data.countAuthors === '1') {
    return 100
  } else if (data.language === 'Русский'
    && data.countAuthors === '2') {
    return 50
  } else if (data.language === 'Русский'
    && data.countAuthors === '3') {
    return 33
  } else if (data.language === 'Русский'
    && data.countAuthors === '4') {
    return 25
  } else if (data.language === 'Русский'
    && data.countAuthors === '5') {
    return 20
  } else if (data.language === 'Русский'
    && data.countAuthors === '6') {
    return 16
  } else if (data.language !== 'Русский'
    && data.countAuthors === '1') {
    return 150
  } else if (data.language !== 'Русский'
    && data.countAuthors === '2') {
    return 75
  } else if (data.language !== 'Русский'
    && data.countAuthors === '3') {
    return 50
  } else if (data.language !== 'Русский'
    && data.countAuthors === '4') {
    return 38
  } else if (data.language !== 'Русский'
    && data.countAuthors === '5') {
    return 30
  } else if (data.language !== 'Русский'
    && data.countAuthors === '6') {
    return 25
  } return 0
}


export const getScores_2_5 = (data: Data) => {
  if (data.level === 'Всероссийский') {
    return 28
  } else if (data.level === 'Международный') {
    return 42
  } return 0
}


export const getScores_2_6 = (data: Data) => {
  if (data.membership === 'Российский университет'
    && data.format === 'В очном формате') {
    return 20
  } else if (data.membership === 'Российский университет'
    && data.format === 'В дистанционной форме') {
    return 10
  } else if (data.membership === 'Ближнее зарубежье'
    && data.format === 'В очном формате') {
    return 30
  } else if (data.membership === 'Ближнее зарубежье'
    && data.format === 'В дистанционной форме') {
    return 15
  } else if (data.membership === 'Дальнее зарубежье'
    && data.format === 'В очном формате') {
    return 40
  } else if (data.membership === 'Дальнее зарубежье'
    && data.format === 'В дистанционной форме') {
    return 20
  } return 0
}


export const getScores_2_7 = (data: Data) => {
  const currentYear = new Date().getFullYear()
  const authors:any = data.authors
  // @ts-ignore
  const countAuthors:any = data.countAuthors

  if (Number(data.publication_year) !== currentYear - 1) {
    return 0
  }

  // TODO Переработать счетчик авторов, надо чтоб приходил с бэка
  let objectLength = 0

  for (const key in authors) {
    objectLength++
  }

  // TODO Перенести логику подсчета баллов в отдельную таблицу на бэк
  if (countAuthors > 6 && objectLength > 6) {
    return 0
  }

  if (data.quartileNumber === 'Q1') {
    if (countAuthors == 1 || objectLength === 1) {
      return 200
    }
    if (countAuthors == 2 || objectLength === 2) {
      return data.isForeignAuthor ? 120 : 100
    }
    if (countAuthors == 3 || objectLength === 3) {
      return data.isForeignAuthor ? 80 : 67
    }
    if (countAuthors == 4 || objectLength === 4) {
      return data.isForeignAuthor ? 60 : 50
    }
    if (countAuthors == 5 || objectLength === 5) {
      return data.isForeignAuthor ? 48 : 40
    }
    if (countAuthors == 6 || objectLength === 6) {
      return data.isForeignAuthor ? 40 : 33
    }
  } else if (data.quartileNumber === 'Q2') {
    if (countAuthors == 1 || objectLength === 1) {
      return 140
    }
    if (countAuthors == 2 || objectLength === 2) {
      return data.isForeignAuthor ? 84 : 70
    }
    if (countAuthors == 3 || objectLength === 3) {
      return data.isForeignAuthor ? 56 : 47
    }
    if (countAuthors == 4 || objectLength === 4) {
      return data.isForeignAuthor ? 42 : 35
    }
    if (countAuthors == 5 || objectLength === 5) {
      return data.isForeignAuthor ? 33 : 28
    }
    if (countAuthors == 6 || objectLength === 6) {
      return data.isForeignAuthor ? 27 : 23
    }
  } else if (data.quartileNumber === 'Q3') {
    if (countAuthors == 1 || objectLength === 1) {
      return 50
    }
    if (countAuthors == 2 || objectLength === 2) {
      return data.isForeignAuthor ? 30 : 25
    }
    if (countAuthors == 3 || objectLength === 3) {
      return data.isForeignAuthor ? 20 : 17
    }
    if (countAuthors == 4 || objectLength === 4) {
      return data.isForeignAuthor ? 15 : 13
    }
    if (countAuthors == 5 || objectLength === 5) {
      return data.isForeignAuthor ? 12 : 10
    }
    if (countAuthors == 6 || objectLength === 6) {
      return data.isForeignAuthor ? 9 : 8
    }
  } else if (data.quartileNumber === 'Q4') {
    if (countAuthors == 1 || objectLength === 1) {
      return 20
    }
    if (countAuthors == 2 || objectLength === 2) {
      return data.isForeignAuthor ? 12 : 10
    }
    if (countAuthors == 3 || objectLength === 3) {
      return data.isForeignAuthor ? 9 : 7
    }
    if (countAuthors == 4 || objectLength === 4) {
      return data.isForeignAuthor ? 6 : 5
    }
    if (countAuthors == 5 || objectLength === 5) {
      return data.isForeignAuthor ? 5 : 4
    }
    if (countAuthors == 6 || objectLength === 6) {
      return data.isForeignAuthor ? 4 : 3
    }
    return 3
  } else {
    return 0
  }
}


export const getScores_2_8 = (data: Data) => {
  if ((data.category === 'до 100 тыс. руб.'
    || data.category === 'от 100 до 500 тыс. руб.')
    && data.role === 'Участник') {
    return 14
  } else if ((data.category === 'до 100 тыс. руб.'
    || data.category === 'от 100 до 500 тыс. руб.')
    && data.role === 'Руководитель') {
    return 35
  } else if (data.category === 'от 500 до 1 млн. руб.'
    && data.role === 'Участник') {
    return 25
  } else if (data.category === 'от 500 до 1 млн. руб.'
    && data.role === 'Руководитель') {
    return 70
  } else if (data.category === 'от 1 до 3 млн. руб.'
    && data.role === 'Участник') {
    return 50
  } else if (data.category === 'от 1 до 3 млн. руб.'
    && data.role === 'Руководитель') {
    return 140
  } else if (data.category === 'свыше 3 млн. руб.'
    && data.role === 'Участник') {
    return 75
  } else if (data.category === 'свыше 3 млн. руб.'
    && data.role === 'Руководитель') {
    return 200
  } return 0
}


export const getScores_2_10 = (data: Data) => {
  if ((data.role === 'Гл. редактор журнала WOS/Scopus'
    || data.role === 'Зам гл. редактора журнала WOS/Scopus')) {
    return 80
  } else if ((data.role === 'Член редколлегии журнала WOS/Scopus') || (data.role === 'Гл. редактор  журнала из списка ВАК')) {
    return 60
  } else if ((data.role === 'Член редколлегии журнала из списка ВАК')) {
    return 20
  } return 0
}


export const getScores_3_1 = (data: Data) => {
  if (data.category === 'Защита собственной диссертации'
    && data.membership === 'докторская') {
    return 84
  } else if (data.category === 'Защита собственной диссертации'
    && data.membership === 'кандидатская') {
    return 42
  } else if (data.category === 'Осуществление руководства диссертацией, защищенной в отчетном периоде'
    && data.membership === 'докторская') {
    return 42
  } else if (data.category === 'Осуществление руководства диссертацией, защищенной в отчетном периоде'
    && data.membership === 'кандидатская') {
    return 33
  } else if (data.category === 'Рецензирование диссертационных работ (отзыв ведущей организации)'
    && data.membership === 'докторская') {
    return 50
  } else if (data.category === 'Рецензирование диссертационных работ (отзыв ведущей организации)'
    && data.membership === 'кандидатская') {
    return 40
  } else if (data.category === 'Рецензирование диссертационных работ (оппонирование)'
    && data.membership === 'докторская') {
    return 40
  } else if (data.category === 'Рецензирование диссертационных работ (оппонирование)'
    && data.membership === 'кандидатская') {
    return 30
  } return 0
}


export const getScores_3_2 = () => {
  return 168
}


export const getScores_3_3 = () => {
  return 50
}


export const getScores_3_4 = (data: Data) => {
  if (data.category === 'I категория сложности'
    && data.userRole === 'Руководитель проекта') {
    return 200
  } else if (data.category === 'I категория сложности'
    && data.userRole === 'Исполнитель проекта') {
    return 100
  } else if (data.category === 'II категория сложности'
    && data.userRole === 'Руководитель проекта') {
    return 100
  } else if (data.category === 'II категория сложности'
    && data.userRole === 'Исполнитель проекта') {
    return 40
  } else if (data.category === 'III категория сложности'
    && data.userRole === 'Руководитель проекта') {
    return 40
  } else if (data.category === 'III категория сложности'
    && data.userRole === 'Исполнитель проекта') {
    return 20
  } return 0
}

import { useDispatch, useSelector, } from 'react-redux'
import { setValues, setErrors, setIsValid, } from '@/legacy/redux/FormSlice/FormSlice'


function useFormWithValidation () {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const values = useSelector(state => state.form.values)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const errors = useSelector(state => state.form.errors)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const isValid = useSelector(state => state.form.isValid)


  const dispatch = useDispatch()

  const handleChange = (event: any) => {
    const target = event.target
    const name = target.name
    const value = target.value


    dispatch(setValues({ [name]: value, }))
    if (target.name === 'confirmPassword' && value !== values.newPassword) {
      dispatch(setErrors({ [name]: 'Пароль не совпадает', }))
    } else if (target.validationMessage) {
      dispatch(setErrors({ [name]: target.validationMessage, }))
    } else {
      dispatch(setErrors({ [name]: '', }))
    }
    dispatch(setIsValid(target.closest('form').checkValidity()))
  }


  return {
    values,
    handleChange,
    errors,
    isValid,
    setIsValid,
  }
}

export default useFormWithValidation

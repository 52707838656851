import { useMemo, } from 'react'
import { useCatalogs, } from '../../../../../gql/hooks/useCatalogs'
import { Preloader, } from '@/shared'
import ChooseField from '../../ChooseField/ChooseField'

const AuthorityList = ({
  authority,
}: any): JSX.Element => {
  const { data, error, loading, } = useCatalogs('referenceAuthority')


  const authorityTypes = useMemo(() => {
    if (error) {
      // @ts-expect-error TS(2322): Type 'ApolloError' is not assignable to type 'Reac... Remove this comment to see the full error message
      return <div>{ error }</div>
    } else if (loading) {
      return <Preloader />
    }
    const authorityList = data?.catalogs?.referenceAuthority?.map((i: any) => i?.name)

    return <ChooseField items={ authorityList } value={ authority } title="Укажите место предоставления" />
  }, [
    data,
    error,
    loading,
    authority,
  ])

  return authorityTypes
}

export default AuthorityList

import { NewPageWrapper, TitleH3, } from '../../common/GeneralElements/GeneralElements'
import PaperContainerWrapper from '../../common/PaperContainer/PaperContainerWrapper'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import CardThresholdValue from '../cards/CardThresholdValue/CardThresholdValue'
import useGetEcAmsThresholdValues from '../../../gql/hooks/effectiveContractHooks/useGetEcAmsThresholdValues'
import styles from './ModerateThresholdValues.module.less'
import useUpdateEcThresholdValues from '../../../gql/hooks/effectiveContractHooks/useUpdateEcThresholdValues'


const cardDataAdapter = (object: any) => {
  return Object.entries(object).map((entry: any) => {
    return {
      ...entry[1],
      dataType: entry[0],
    }
  })
}

const cardDataAdapterForUpdate = ({
  thresholdValues,
  newData,
}: any) => {
  const key = Object.keys(newData)[0]

  return {
    [key]: {
      ...thresholdValues[key],
      ...newData[key],
    },
  }
}


const ModerateThresholdValues = () => {
  const thresholdValues = useGetEcAmsThresholdValues()
  const { onUpdateEcThresholdValues, } = useUpdateEcThresholdValues()


  return (
    <NewPageWrapper>
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Модерация пороговых значений"
      />

      <h2 className="effective-contract__title">
        Модерация пороговых значений:
      </h2>

      <PaperContainerWrapper>
        <TitleH3>
          Пороговые значения и коэффициенты
        </TitleH3>

        <div className={ styles.cardsWrapper }>
          { cardDataAdapter(thresholdValues)?.map((item: any) => <CardThresholdValue
            key={ item.dataType }
            dataType={ item.dataType }
            title={ item.indicator_name }
            value={ item.value }
            scores={ item.completion_scores }
            coefficient={ item.coefficient }
            returnNewData={ (newData: any) => onUpdateEcThresholdValues(cardDataAdapterForUpdate({
              thresholdValues,
              newData,
            })) }
          />) }
        </div>
      </PaperContainerWrapper>
    </NewPageWrapper>
  )
}


export default ModerateThresholdValues

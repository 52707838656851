import { gql, } from '@/__generated__/gql'
import { useMutation, } from '@apollo/client'
import { useMemo, useState, } from 'react'


export const EDUCATION_OF_INDIVIDUALS_MUTATION = gql(`
  mutation Commit{
    ecCommitEducationOfIndividials
  }
`)


const useLazyExport = (MUTATION: any) => {
  const [
    exportData,
    exportDataResult,
  ] = useMutation(MUTATION)
  const [
    result,
    setResult,
  ] = useState(null)


  useMemo(() => {
    if (exportDataResult?.data) {
      // @ts-expect-error TS(2345): Argument of type '"Данные отправленны"' is not ass... Remove this comment to see the full error message
      setResult('Данные отправленны')
    }

    if (exportDataResult?.error) {
      // @ts-expect-error TS(2345): Argument of type '"Произошла ошибка"' is not assig... Remove this comment to see the full error message
      setResult('Произошла ошибка')
    }
  }, [
    exportDataResult.data,
    exportDataResult.error,
  ])


  return [
    exportData,
    exportDataResult.loading,
    result,
  ]
}


export default useLazyExport

import React, { useEffect, useRef, } from 'react'
import './ApplicationCard.less'
import clsx from 'clsx'
import { useDispatch, } from 'react-redux'
import Image from '../../../common/Image/Image'
import { IconPencil, } from '@tabler/icons'
import useRedirect from '@/legacy/hooks/useRedirect'
import useDownloadS3Test from '@/legacy/hooks/useDownloadS3Test/useDownloadS3Test'
import { setProjectId, setFixProject, } from '@/legacy/redux/ProjectsSlice/ProjectSlice'


function ApplicationCard ({
  i,
  type,
  historyRef,
}: any) {
  const redirect = useRedirect()
  const dispatch = useDispatch()

  const myRef = useRef(null)

  const { name, status, req_id, } = i

  const fileId = i?.data?.fileId

  const { loading, errorMessage, downloadUrl, } = useDownloadS3Test(fileId)

  function handleApply () {
    redirect('/apply-project')
    dispatch(setProjectId(req_id))
  }

  function edit () {
    redirect('/apply-project')
    dispatch(setFixProject(i.data))
    dispatch(setProjectId(i.req_id))
  }

  const handleDownload = () => {
    if (downloadUrl) {
      window.location.href = downloadUrl
    }
  }

  useEffect(() => {
    if (myRef && (historyRef === i.req_id)) {
      /*  console.log('myReeef', myRef,  historyRef) */
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      myRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [
    myRef,
    historyRef,
    i.req_id,
  ])

  /*  console.log('myReeef', myRef,  historyRef, i.req_id)  */

  return (
    <div className="applyCard" ref={ myRef }>
      <h2>{ i?.data ? i?.data.name : name }</h2>
      { i?.data && <div className="teacher">
        <Image id={ i?.data.supervisor.id } /* style={{width: "24px", height: "24px", borderRadius: "20px"}} */ />
        <div>
          <p className="subtitle">Руководитель</p>
          <p className="value">{ i.data.supervisor.full_name }</p>
        </div>
      </div> }
      <div className="applCardFoot">
        <div className={ clsx(status === 'approved' && 'appr', status === 'projected' && 'appr', status === 'ready' && 'appr', status === 'new' && 'appl', status === 'wait' && 'appl', status === 'rejected' && 'rej') }>
          { status === 'approved' && 'Заявка одобрена' }
          { status === 'ready' && 'Заявка одобрена' }
          { status === 'new' && 'Заявка подана' }
          { status === 'wait' && 'Заявка подана' }
          { status === 'rejected' && 'Проект отклонен' }
          { status === 'projected' && 'Отправлена заявка на создание проекта' }
        </div>
        { status === 'approved' && <button className="statusButton buttonActive" onClick={ handleApply }>Подать заявку</button> }
        { (type === 'project' && i?.status !== 'ready') && <button style={ {
          background: '#7671DD',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
        } } className="statusButton decline moder" onClick={ edit }>Редактировать<IconPencil color="white" /></button> }
        {
          fileId && (
            <div>
              { loading ? (
                <p>Loading...</p>
              ) : errorMessage ? (
                <p>Error: {errorMessage}</p>
              ) : (
                <button className="downloadButton" onClick={handleDownload}>Скачать подтверждающий документ</button>
              )}
            </div>
          )
        }
      </div>
    </div>
  )
}


export default ApplicationCard

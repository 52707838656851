import { useLazyQuery, } from '@apollo/client'
import { useEffect, useState, } from 'react'
import { EC_LIST_DATA, } from './useEffectiveContract'


const useGetCurrentAppeal = (isAppeal: boolean, appeal_id: number, dataType:string) => {
  const [
    appealData,
    setAppealData,
  ] = useState<any>(null)
  const [
    getAppeal,
    { loading, data, },
  ] = useLazyQuery(
    EC_LIST_DATA,
    {
      variables: { params:
        { rec_id: appeal_id,
          dataType: dataType, }, },
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (!loading && data) {
      setAppealData(data.ecListData[0])
    }
  }, [
    loading,
    data,
  ])

  return {
    getAppeal,
    appealData,
  }
}


export default useGetCurrentAppeal

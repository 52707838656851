import { gql, useMutation, } from '@apollo/client'

export const ADD_NOTIFICATION_DRAFT = gql(`
  mutation NotificationDraftAdd($body: JSON!) {
    notificationDraftAdd(body: $body)
  }
`)


const useAddNotificationDraft = () => {
  const [
    addNotificationDraft,
  ] = useMutation(ADD_NOTIFICATION_DRAFT)

  const addNotificationDraftHandler = ({
    body,
  }: any) => addNotificationDraft({
    variables: {
      body,
    },
  })


  return { addNotificationDraftHandler, }
}


export default useAddNotificationDraft

import clsx from 'clsx'
import { Fragment, useState, } from 'react'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import InputTextDeprecated, { useInputText, } from '../../../common/InputTextDeprecated/InputTextDeprecated'
import styles from './CardThresholdValue.module.less'


const CardThresholdValue = ({
  title,
  value = '',
  coefficient = '',
  scores = '',
  dataType,
  returnNewData,
}: any) => {
  const thresholdValueInput = useInputText({ initialValue: value, })
  const coefficientInput = useInputText({ initialValue: coefficient, })
  const scoresInput = useInputText({ initialValue: scores, })
  const [
    formCoefficientInput,
    setFormCoefficientInput,
  ] = useState(coefficient instanceof Object ? { ...coefficient, } : null)


  return (
    <div className={ styles.card }>
      <div>
        <h3 className={ styles.title }>
          Показатель
        </h3>
        <div className={ styles.description }>
          { title }
        </div>
        <br />

        { value.toString() && (
          <>
            <h3 className={ clsx(styles.title, styles.title_small) }>
              Пороговое значение
            </h3>
            <InputTextDeprecated
              numbersOnly
              { ...thresholdValueInput }
            />
            <br />
          </>
        ) }

        { coefficient.toString() && (
          <>
            <h3 className={ clsx(styles.title, styles.title_small) }>
              Числовой коэффициент
            </h3>
            { coefficient instanceof Object && (
              Object.entries(coefficient).sort((first: any, second: any) => {
                if (first[1].number < second[1].number) {
                  return -1
                } else if (first[1].number > second[1].number) {
                  return 1
                } return 0
              }).map((item: any) => <Fragment key={ item[0] }>
                <InputTextDeprecated
                  withPlaceholder
                  defaultHint={ formCoefficientInput[item[0]].name }
                  numbersOnly
                  value={ formCoefficientInput[item[0]].value }
                  onChangeValue={ (data: any) => setFormCoefficientInput({
                    ...formCoefficientInput,
                    [item[0]]: {
                      ...item[1],
                      value: Number(data),
                    },
                  }) }
                />
                <br />
              </Fragment>)
            ) }

            { typeof coefficient === 'number' && (
              <InputTextDeprecated
                numbersOnly
                { ...coefficientInput }
              />
            ) }
            <br />
          </>
        ) }

        { scores.toString() && (
          <>
            <h3 className={ clsx(styles.title, styles.title_small) }>
              Баллы
            </h3>
            <InputTextDeprecated
              numbersOnly
              { ...scoresInput }
            />
            <br />
          </>
        ) }
      </div>

      { (value.toString() || coefficient.toString() || scores.toString()) && (
        <div style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }>
          <ButtonSimple
            variant='alone'
            value='Сохранить'
            active
            onClickHandler={ () => {
              const newData = { [dataType]: {}, }

              if (thresholdValueInput.value !== '') {
                // @ts-expect-error TS(2339): Property 'value' does not exist on type '{}'.
                newData[dataType].value = Number(thresholdValueInput.value)
              }

              if (coefficient instanceof Object) {
                // @ts-expect-error TS(2339): Property 'coefficient' does not exist on type '{}'... Remove this comment to see the full error message
                newData[dataType].coefficient = formCoefficientInput
              }

              if (typeof coefficient === 'number' && coefficientInput.value !== '') {
                // @ts-expect-error TS(2339): Property 'coefficient' does not exist on type '{}'... Remove this comment to see the full error message
                newData[dataType].coefficient = Number(coefficientInput.value)
              }

              if (scoresInput.value !== '') {
                // @ts-expect-error TS(2339): Property 'completion_scores' does not exist on typ... Remove this comment to see the full error message
                newData[dataType].completion_scores = Number(scoresInput.value)
              }


              returnNewData(newData)
            } }
          />
        </div>
      ) }
    </div>
  )
}


export default CardThresholdValue

import { useQuery, } from '@apollo/client'
import { gql, } from '@/__generated__/gql'
import { useMemo, } from 'react'
import { UUID, } from '@/legacy/types'
import { DataTypeProjectSeminarTable, } from '@/legacy/components/Projects/ProjectSeminar/pages/ProjectSeminarByGroup/ProjectSeminarByGroup'

const GET_GROUP_DETAIL_BY_NAME = gql(`
query paProjectSeminarGroupDetail($input: ProjectSeminarGroupDetailInput!) {
    paProjectSeminarGroupDetail(input: $input)
  }
`)


const useGetProjectSeminarGroupDetail = (groupName: string) => {
  const { data, loading, } = useQuery(GET_GROUP_DETAIL_BY_NAME, {
    variables: {
      input: {
        groupName,
      },
    },
  })

  const result = useMemo(() => {
    if (!loading && data) {
      const currentData: DataTypeProjectSeminarTable[] = data.paProjectSeminarGroupDetail
      return currentData
    } return []
  }, [
    data,
    loading,
  ])

  return result
}


export default useGetProjectSeminarGroupDetail

import { gql, useMutation, } from '@apollo/client'


const CHANGE_NOTIFICATION_PUBLISH_STATUS = gql(`
  mutation NotificationDraftChangeActive($draft_id: [Int!]!, $is_active: Boolean) {
    notificationDraftChangeActive(draft_id: $draft_id, is_active: $is_active)
  }
`)


const useChangeNotificationPublishStatus = () => {
  const [
    onPublish,
  ] = useMutation(CHANGE_NOTIFICATION_PUBLISH_STATUS)

  const onPublishDraftHandler = ({
    draftIds,
    isActive,
  }: any) => onPublish({
    variables: {
      draft_id: draftIds,
      is_active: isActive,
    },
  })


  return { onPublishDraftHandler, }
}


export default useChangeNotificationPublishStatus

import { IconArrowLeft } from "@tabler/icons";
import clsx from "clsx";
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  MouseEventHandler,
} from "react";

interface BackToButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  onClick: MouseEventHandler<HTMLButtonElement>;
  title: string;
  className?: string;
}

const BackToButton = ({
  onClick,
  title,
  className,
  ...props
}: BackToButtonProps): JSX.Element => {
  return (
    <button
      onClick={onClick}
      className={clsx(className, "getRefBut")}
      {...props}
    >
      <IconArrowLeft
        style={{ margin: "0 16px 0 0" }}
        size={25}
        color={"#7671DD"}
      />

      {title}
    </button>
  );
};

export default BackToButton;

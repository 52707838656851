import { gql, useSubscription, } from '@apollo/client'
import { useEffect, } from 'react'


const CONFIG_CHANGES = gql`
  subscription ConfigChanged {
    configChanged
  }
`


const useConfigSubscription = ({
  condition,
  refetch,
}: any) => {
  const { data, } = useSubscription(CONFIG_CHANGES)


  useEffect(() => {
    if (data?.configChanged?.domain === condition.domain && data?.configChanged?.key === condition.key) {
      refetch()
    }
  }, [
    condition,
    data,
    refetch,
  ])
}


export default useConfigSubscription

/* eslint-disable no-console */
import { format, formatISO, } from 'date-fns'
import { Fragment, useCallback, } from 'react'
import { useAppDispatch, } from '../../../redux/hooks'
import {
  addFilesToList,
  removeFileFromList,
  setFormText,
  setFormTitle,
  setFormType,
  setIsImortant,
  setPublishDates,
  setPublishTime,
} from '../../../redux/moderateNotificationsSlice/moderateNotificationsSlice'
import AttachementInput from '../../common/AttachementInput/AttachementInput'
import CalendarSimple from '../../common/CalendarSimple/CalendarSimple'
import { TitleH5, } from '../../common/GeneralElements/GeneralElements'
import InputCheckbox from '../../common/InputCheckbox/InputCheckbox'
import InputSelectDeprecated from '../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputTextDeprecated from '../../common/InputTextDeprecated/InputTextDeprecated'
import TextEditor from '../../common/TextEditor/TextEditor'
import UserGroupeChoice from '../AddNotificationButton/UserGroupeChoice/UserGroupeChoice'


export const defineCalenderPlaceholder = (field: any) => {
  if (field?.value?.from && field?.value?.to) {
    return `${ format(new Date(field.value.from), 'dd.MM.yyyy') } - ${ format(new Date(field.value.to), 'dd.MM.yyyy') }`
  } return false
}


const useCreateFormNotificationEdit = ({
  formObject,
}: any) => {
  const dispatch = useAppDispatch()

  const memoSetFormType = useCallback((newValue: any) => dispatch(setFormType(newValue)), [
    dispatch,
  ])
  const memoSetFormTitle = useCallback((newValue: any) => dispatch(setFormTitle(newValue)), [
    dispatch,
  ])
  const memoSetFormText = useCallback((newValue: any) => dispatch(setFormText(newValue)), [
    dispatch,
  ])
  const memoSetPublishDates = useCallback((newValue: any) => dispatch(setPublishDates(
    newValue.map((date: any) => formatISO(new Date(date)))
  )), [
    dispatch,
  ])
  const memoSetPublishTime = useCallback((newValue: any) => dispatch(setPublishTime(newValue)), [
    dispatch,
  ])
  const memoSetIsImortant = useCallback((newValue: any) => dispatch(setIsImortant(newValue)), [
    dispatch,
  ])

  const memoRemoveFileFromList = useCallback((uploadId: any) => dispatch(removeFileFromList(uploadId)), [
    dispatch,
  ])


  return formObject.map((field: any, index: any) => {
    if (field.type === 'select') {
      return (
        <Fragment key={ index }>
          <InputSelectDeprecated
            withResetValue
            defaultHint={ field.name }
            items={ field.items }
            callBack={ memoSetFormType }
            defaultValue={ field.value }
          />
          <br />
        </Fragment>
      )
    }


    if (field.type === 'inputText') {
      return (
        <Fragment key={ index }>
          <InputTextDeprecated
            defaultHint={ field.name }
            value={ field.value }
            onChangeValue={ memoSetFormTitle }
          />
          <br />
        </Fragment>
      )
    }


    if (field.type === 'textEditor') {
      return (
        <Fragment key={ index }>
          <TextEditor
            value={ field.value }
            setValue={ memoSetFormText }
          />

          <br />
        </Fragment>
      )
    }


    if (field.type === 'files') {
      return (
        <AttachementInput
          key={ index }
          pathForSave='notifications/document'
          uploadedFiles={ field.value }
          addFilesToList={ addFilesToList }
          removeFileFromList={ memoRemoveFileFromList }
        />
      )
    }


    if (field.type === 'publishDates') {
      return (
        <Fragment key={ index }>
          <TitleH5>
            { field.name }
          </TitleH5>

          <CalendarSimple
            withBorder
            returnRange={ memoSetPublishDates }
            placeholder={ defineCalenderPlaceholder(field) }
            defaultRangeDate={ Object.values(field.value) }
          />
          <br />


          <TitleH5>
            Время публикации
          </TitleH5>

          <InputTextDeprecated
            value={
              field.value.from
                ? `${ new Date(field.value.from).getHours().toString().padStart(2, '0') }:${ new Date(field.value.from).getMinutes().toString().padStart(2, '0') }`
                : '00:00'
            }
            onChangeValue={ (data: any) => console.log(data) }
            onBlur={ (event: any) => memoSetPublishTime(event.target.value) }
            disabled={ !field.value.from }
          />
          <br />
        </Fragment>
      )
    }


    if (field.type === 'isImportant') {
      return (
        <Fragment key={ index }>
          <InputCheckbox
            label={ field.name }
            checked={ field.value }
            onChange={ (event: any) => memoSetIsImortant(event.target.checked) }
          />
          <br />
        </Fragment>
      )
    }


    if (field.type === 'checkboxList') {
      return (
        <UserGroupeChoice
          key={ index }
          titlle={ field.name }
          checkboxList={ field.value.filter((consumer: any) => !/^guids$|^filter$/.test(consumer.item)) }
        />
      )
    }


    return null
  })
}


export default useCreateFormNotificationEdit

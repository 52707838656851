// TODO: Убрать TS-игноры

import './ModeratorTemplate.less'

import { EFFECTIVE_CONTRACT_ROUTE, } from '@/shared/constants/routes'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import FilterDropDown from '../../../common/FilterDropDown/FilterDropDown'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import CalendarSimple from '../../../common/CalendarSimple/CalendarSimple'
import CollapseContainer2, { AccordionInnder, } from '../../../common/CollapseContainer2/CollapseContainer2'
import TableContainer from '../../TableContainer/TableContainer'


import {
  tableSource as headerFields_1_3,
} from '../../constants/tablesFormData/table_1_3'
import {
  tableSource as headerFields_1_4,
} from '../../constants/tablesFormData/table_1_4'
import {
  tableSource as headerFields_1_41,
} from '../../constants/tablesFormData/table_1_41'
import {
  tableSource as headerFields_2_2,
} from '../../constants/tablesFormData/table_2_2'
import {
  tableSource as headerFields_2_3,
} from '../../constants/tablesFormData/table_2_3'
import {
  tableSource as headerFields_2_4,
} from '../../constants/tablesFormData/table_2_4'
import {
  tableSource as headerFields_2_41,
} from '../../constants/tablesFormData/table_2_41'
import {
  tableSource as headerFields_2_5,
} from '../../constants/tablesFormData/table_2_5'
import {
  tableSource as headerFields_2_6,
} from '../../constants/tablesFormData/table_2_6'
import {
  tableSource as headerFields_2_7,
} from '../../constants/tablesFormData/table_2_7'
import {
  tableSource as headerFields_2_8,
} from '../../constants/tablesFormData/table_2_8'
import {
  tableSource as headerFields_2_9,
} from '../../constants/tablesFormData/table_2_9'
import {
  tableSource as headerFields_2_10,
} from '../../constants/tablesFormData/table_2_10'
import {
  tableSource as headerFields_3_1,
} from '../../constants/tablesFormData/table_3_1'
import {
  tableSource as headerFields_3_2,
} from '../../constants/tablesFormData/table_3_2'
import {
  tableSource as headerFields_3_3,
} from '../../constants/tablesFormData/table_3_3'
import {
  tableSource as headerFields_3_4,
} from '../../constants/tablesFormData/table_3_4'
import {
  tableSource as headerFields_3_5,
} from '../../constants/tablesFormData/table_3_5'
import {
  tableSource as headerFields_4_1,
} from '../../constants/tablesFormData/table_4_1'


import { IconAward, IconBook, IconBulb, IconFileInvoice, IconMedal, IconNews, IconNotes, IconPlayerPlay, IconSchool, IconShield, IconSquareCheck, IconTrophy, IconUser, IconUsers, IconWorld, } from '@tabler/icons'
import { useEffect, useState, } from 'react'
import { useEcSubscription, } from '../../../../gql/hooks/effectiveContractHooks/useEcSubscription'
import Popup from '../../../common/Popup/Popup'
import { useUserInfo, } from '../../../../gql/hooks/useME'
import { useGetEcListData, useGetFirstPointScores, useGetPoint_1_4_Scores, useGetScores, useUpdateEcData, } from '../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { format, } from 'date-fns'

import POP_UP_CONTENT_1_1 from './PopUpContent/PopUpContent_1_1'
import POP_UP_CONTENT_1_3 from './PopUpContent/PopUpContent_1_3'
import POP_UP_CONTENT_1_4 from './PopUpContent/PopUpContent_1_4'
import POP_UP_CONTENT_1_41 from './PopUpContent/PopUpContent_1_41'
import POP_UP_CONTENT_2_2 from './PopUpContent/PopUpContent_2_2'
import POP_UP_CONTENT_2_3 from './PopUpContent/PopUpContent_2_3'
import POP_UP_CONTENT_2_4 from './PopUpContent/PopUpContent_2_4'
import POP_UP_CONTENT_2_41 from './PopUpContent/PopUpContent_2_41'
import POP_UP_CONTENT_2_5 from './PopUpContent/PopUpContent_2_5'
import POP_UP_CONTENT_2_6 from './PopUpContent/PopUpContent_2_6'
import POP_UP_CONTENT_3_1 from './PopUpContent/PopUpContent_3_1'
import POP_UP_CONTENT_3_2 from './PopUpContent/PopUpContent_3_2'
import POP_UP_CONTENT_3_3 from './PopUpContent/PopUpContent_3_3'
import POP_UP_CONTENT_3_4 from './PopUpContent/PopUpContent_3_4'
import POP_UP_CONTENT_3_5 from './PopUpContent/PopUpContent_3_5'
import { AddManyParticipants, } from '../../AddAchievement'
import PopupContentReject from '../../popupContent/PopupContentReject/PopupContentReject'
import POP_UP_CONTENT_2_7 from './PopUpContent/PopUpContent_2_7'
import POP_UP_CONTENT_2_10 from './PopUpContent/PopUpContent_2_10'
import useGetEcStages from '../../../../gql/hooks/effectiveContractHooks/useGetEcStages'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import useUrlSearch from '@/legacy/hooks/useUrlSearch'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'


const ModeratorTemplate = () => {
  const { active_stage, } = useGetEcStages()
  const stage = `stage-${ active_stage?.stage_number }`

  const [
    achievementType,
    setAchievementType,
  ] = useState('all')
  const [
    achievementStatus,
    setAchievementStatus,
  ] = useState('')
  const [
    achievementDate,
    setAchievementDate,
  ] = useState(null)


  const moderateId = useUrlSearch('id')

  const role = useGetRole()

  const [
    popUpContent,
    setPopUpContent,
  ] = useState(null)
  const [
    isPopupOpen,
    setIsPopUpOpen,
  ] = useState(false)
  const [
    popUpData,
    setPopUpData,
  ] = useState<any>({})

  const fullName = useUserInfo(moderateId)?.data?.userInfo?.full_name


  const queryTable_1_1 = useGetEcListData({
    dataType: 'table_1_1',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_1_3 = useGetEcListData({
    dataType: 'table_1_3',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_1_4 = useGetEcListData({
    dataType: 'table_1_4',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_1_41 = useGetEcListData({
    dataType: 'table_1_41',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_2_2 = useGetEcListData({
    dataType: 'table_2_2',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_2_3 = useGetEcListData({
    dataType: 'table_2_3',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_2_39 = useGetEcListData({
    dataType: 'table_2_39',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_2_4 = useGetEcListData({
    dataType: 'table_2_4',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_2_41 = useGetEcListData({
    dataType: 'table_2_41',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_2_5 = useGetEcListData({
    dataType: 'table_2_5',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_2_6 = useGetEcListData({
    dataType: 'table_2_6',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_2_7 = useGetEcListData({
    dataType: 'table_2_7',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_2_79 = useGetEcListData({
    dataType: 'table_2_79',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_2_8 = useGetEcListData({
    dataType: 'table_2_8',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_2_9 = useGetEcListData({
    dataType: 'table_2_9',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_2_10 = useGetEcListData({
    dataType: 'table_2_10',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_3_1 = useGetEcListData({
    dataType: 'table_3_1',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_3_2 = useGetEcListData({
    dataType: 'table_3_2',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_3_3 = useGetEcListData({
    dataType: 'table_3_3',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_3_4 = useGetEcListData({
    dataType: 'table_3_4',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_3_5 = useGetEcListData({
    dataType: 'table_3_5',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })
  const queryTable_4_1 = useGetEcListData({
    dataType: 'table_4_1',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2022-01-01',
      to: '2023-01-01',
    },
  })

  // TODO Переписать запросы на запросы с параметрами

  const queryTable_2_2_date = useGetEcListData({
    dataType: 'table_2_2',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_2_3_date = useGetEcListData({
    dataType: 'table_2_3',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_2_39_date = useGetEcListData({
    dataType: 'table_2_39',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_2_4_date = useGetEcListData({
    dataType: 'table_2_4',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_2_41_date = useGetEcListData({
    dataType: 'table_2_41',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_2_5_date = useGetEcListData({
    dataType: 'table_2_5',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_2_6_date = useGetEcListData({
    dataType: 'table_2_6',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_2_7_date = useGetEcListData({
    dataType: 'table_2_7',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_2_79_date = useGetEcListData({
    dataType: 'table_2_79',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_2_8_date = useGetEcListData({
    dataType: 'table_2_8',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_2_9_date = useGetEcListData({
    dataType: 'table_2_9',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_2_10_date = useGetEcListData({
    dataType: 'table_2_10',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_3_1_date = useGetEcListData({
    dataType: 'table_3_1',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_3_2_date = useGetEcListData({
    dataType: 'table_3_2',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_3_3_date = useGetEcListData({
    dataType: 'table_3_3',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_3_4_date = useGetEcListData({
    dataType: 'table_3_4',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_3_5_date = useGetEcListData({
    dataType: 'table_3_5',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })
  const queryTable_4_1_date = useGetEcListData({
    dataType: 'table_4_1',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2023-01-01',
      to: '2023-12-31',
    },
  })

  const queryTable_2_2_date_2 = useGetEcListData({
    dataType: 'table_2_2',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_2_3_date_2 = useGetEcListData({
    dataType: 'table_2_3',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_2_39_date_2 = useGetEcListData({
    dataType: 'table_2_39',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_2_4_date_2 = useGetEcListData({
    dataType: 'table_2_4',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_2_41_date_2 = useGetEcListData({
    dataType: 'table_2_41',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_2_5_date_2 = useGetEcListData({
    dataType: 'table_2_5',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_2_6_date_2 = useGetEcListData({
    dataType: 'table_2_6',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_2_7_date_2 = useGetEcListData({
    dataType: 'table_2_7',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_2_79_date_2 = useGetEcListData({
    dataType: 'table_2_79',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_2_8_date_2 = useGetEcListData({
    dataType: 'table_2_8',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_2_9_date_2 = useGetEcListData({
    dataType: 'table_2_9',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_2_10_date_2 = useGetEcListData({
    dataType: 'table_2_10',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_3_1_date_2 = useGetEcListData({
    dataType: 'table_3_1',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_3_2_date_2 = useGetEcListData({
    dataType: 'table_3_2',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_3_3_date_2 = useGetEcListData({
    dataType: 'table_3_3',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_3_4_date_2 = useGetEcListData({
    dataType: 'table_3_4',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_3_5_date_2 = useGetEcListData({
    dataType: 'table_3_5',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })
  const queryTable_4_1_date_2 = useGetEcListData({
    dataType: 'table_4_1',
    user_id: moderateId,
    status: achievementStatus,
    date: {
      from: '2024-01-01',
      to: '2024-12-31',
    },
  })

  const [
    table_1_3,
    setTable_1_3,
  ] = useState([])
  const [
    table_1_4,
    setTable_1_4,
  ] = useState([])
  const [
    table_1_41,
    setTable_1_41,
  ] = useState([])
  const [
    table_2_2,
    setTable_2_2,
  ] = useState([])
  const [
    table_2_3,
    setTable_2_3,
  ] = useState([])
  const [
    table_2_4,
    setTable_2_4,
  ] = useState([])
  const [
    table_2_41,
    setTable_2_41,
  ] = useState([])
  const [
    table_2_5,
    setTable_2_5,
  ] = useState([])
  const [
    table_2_6,
    setTable_2_6,
  ] = useState([])
  const [
    table_2_7,
    setTable_2_7,
  ] = useState([])
  const [
    table_2_8,
    setTable_2_8,
  ] = useState([])
  const [
    table_2_9,
    setTable_2_9,
  ] = useState([])
  const [
    table_2_10,
    setTable_2_10,
  ] = useState([])
  const [
    table_3_1,
    setTable_3_1,
  ] = useState([])
  const [
    table_3_2,
    setTable_3_2,
  ] = useState([])
  const [
    table_3_3,
    setTable_3_3,
  ] = useState([])
  const [
    table_3_4,
    setTable_3_4,
  ] = useState([])
  const [
    table_3_5,
    setTable_3_5,
  ] = useState([])
  const [
    table_4_1,
    setTable_4_1,
  ] = useState([])

  // TODO Переписать это дерьмище

  const [
    table_2_2_date,
    setTable_2_2_date,
  ] = useState([])
  const [
    table_2_3_date,
    setTable_2_3_date,
  ] = useState([])
  const [
    table_2_4_date,
    setTable_2_4_date,
  ] = useState([])
  const [
    table_2_41_date,
    setTable_2_41_date,
  ] = useState([])
  const [
    table_2_5_date,
    setTable_2_5_date,
  ] = useState([])
  const [
    table_2_6_date,
    setTable_2_6_date,
  ] = useState([])
  const [
    table_2_7_date,
    setTable_2_7_date,
  ] = useState([])
  const [
    table_2_8_date,
    setTable_2_8_date,
  ] = useState([])
  const [
    table_2_9_date,
    setTable_2_9_date,
  ] = useState([])
  const [
    table_2_10_date,
    setTable_2_10_date,
  ] = useState([])
  const [
    table_3_1_date,
    setTable_3_1_date,
  ] = useState([])
  const [
    table_3_2_date,
    setTable_3_2_date,
  ] = useState([])
  const [
    table_3_3_date,
    setTable_3_3_date,
  ] = useState([])
  const [
    table_3_4_date,
    setTable_3_4_date,
  ] = useState([])
  const [
    table_3_5_date,
    setTable_3_5_date,
  ] = useState([])
  const [
    table_4_1_date,
    setTable_4_1_date,
  ] = useState([])

  const [
    table_2_2_date_2,
    setTable_2_2_date_2,
  ] = useState([])
  const [
    table_2_3_date_2,
    setTable_2_3_date_2,
  ] = useState([])
  const [
    table_2_4_date_2,
    setTable_2_4_date_2,
  ] = useState([])
  const [
    table_2_41_date_2,
    setTable_2_41_date_2,
  ] = useState([])
  const [
    table_2_5_date_2,
    setTable_2_5_date_2,
  ] = useState([])
  const [
    table_2_6_date_2,
    setTable_2_6_date_2,
  ] = useState([])
  const [
    table_2_7_date_2,
    setTable_2_7_date_2,
  ] = useState([])
  const [
    table_2_8_date_2,
    setTable_2_8_date_2,
  ] = useState([])
  const [
    table_2_9_date_2,
    setTable_2_9_date_2,
  ] = useState([])
  const [
    table_2_10_date_2,
    setTable_2_10_date_2,
  ] = useState([])
  const [
    table_3_1_date_2,
    setTable_3_1_date_2,
  ] = useState([])
  const [
    table_3_2_date_2,
    setTable_3_2_date_2,
  ] = useState([])
  const [
    table_3_3_date_2,
    setTable_3_3_date_2,
  ] = useState([])
  const [
    table_3_4_date_2,
    setTable_3_4_date_2,
  ] = useState([])
  const [
    table_3_5_date_2,
    setTable_3_5_date_2,
  ] = useState([])
  const [
    table_4_1_date_2,
    setTable_4_1_date_2,
  ] = useState([])


  const getFirstPointScores_1_3 = useGetFirstPointScores({
    user_id: moderateId,
    dataType: 'table_1_3',
  })

  const getFirstPointScores_1_4 = useGetPoint_1_4_Scores({
    user_id: moderateId,
  })

  const getScores2Query = useGetScores({
    user_id: moderateId,
    selector: {
      major: 2,
      minor: [
        1,
        2,
        3,
        4,
        41,
        5,
        6,
        7,
        8,
        9,
        10,
      ],
    },
    extra: [
      {
        dataTypes: [
          '2_4',
          '2_41',
        ],
        max: 150,
      },
    ],
  })

  const getScores3Query = useGetScores({
    user_id: moderateId,
    selector: {
      major: 3,
      minor: [
        1,
        2,
        3,
        4,
        5,
      ],
    },
  })

  const getScores4Query = useGetScores({
    user_id: moderateId,
    selector: {
      major: 4,
      minor: [
        1,
      ],
    },
  })

  const [
    scores_1_3,
    setScores_1_3,
  ] = useState('0/1')
  const [
    scores_1_4,
    setScores_1_4,
  ] = useState(null)

  const [
    scores_2_2,
    setScores_2_2,
  ] = useState(0)
  const [
    scores_2_3,
    setScores_2_3,
  ] = useState(0)
  const [
    scores_2_4,
    setScores_2_4,
  ] = useState(0)
  const [
    scores_2_41,
    setScores_2_41,
  ] = useState(0)
  const [
    scores_2_5,
    setScores_2_5,
  ] = useState(0)
  const [
    scores_2_6,
    setScores_2_6,
  ] = useState(0)
  const [
    scores_2_7,
    setScores_2_7,
  ] = useState(0)
  const [
    scores_2_8,
    setScores_2_8,
  ] = useState(0)
  const [
    scores_2_9,
    setScores_2_9,
  ] = useState(0)
  const [
    scores_2_10,
    setScores_2_10,
  ] = useState(0)

  const [
    scores_3_1,
    setScores_3_1,
  ] = useState(0)
  const [
    scores_3_2,
    setScores_3_2,
  ] = useState(0)
  const [
    scores_3_3,
    setScores_3_3,
  ] = useState(0)
  const [
    scores_3_4,
    setScores_3_4,
  ] = useState(0)
  const [
    scores_3_5,
    setScores_3_5,
  ] = useState(0)

  const [
    scores_4_1,
    setScores_4_1,
  ] = useState(0)


  const setScoresByDefault_1_3 = () => {
    setScores_1_3('0/1')
  }

  const setScoresByDefault2 = () => {
    setScores_2_2(0)
    setScores_2_3(0)
    setScores_2_4(0)
    setScores_2_41(0)
    setScores_2_5(0)
    setScores_2_6(0)
  }

  const setScoresByDefault3 = () => {
    setScores_3_1(0)
    setScores_3_2(0)
    setScores_3_3(0)
    setScores_3_4(0)
    setScores_3_5(0)
  }

  const setScoresByDefault4 = () => {
    setScores_4_1(0)
  }


  useEffect(() => {
    if (getFirstPointScores_1_3.data) {
      const data = getFirstPointScores_1_3.data.ecGetCompletionStats
      const result = data.items?.reduce((accumulator: any, currentValue: any) => accumulator + Number(currentValue.isDone), 0)

      if (data.length === 0) {
        setScoresByDefault_1_3()
      } else {
        setScores_1_3(`${ result }/${ data.total }`)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getFirstPointScores_1_3.loading,
  ])

  useEffect(() => {
    if (getFirstPointScores_1_4.data) {
      const data = getFirstPointScores_1_4?.data?.ecGetBaselineCompletion?.completion

      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setScores_1_4(`${ data?.completed }/${ data?.total }`)
    }

    if (getFirstPointScores_1_4.error) {
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setScores_1_4(new Error(getFirstPointScores_1_4.error).message)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getFirstPointScores_1_4.loading,
  ])

  useEffect(() => {
    if (getScores2Query.data) {
      if (getScores2Query.data.ecGetScoresTableBySelector.items.length === 0) {
        setScoresByDefault2()
      }

      // баллы по пункту 2.4 с ограничениями:
      // if (getScores2Query.data.ecGetScoresTableBySelector.extras) {
      //   const extras = getScores2Query.data.ecGetScoresTableBySelector.extras;

      //   extras.forEach(
      //     extra => {
      //       extra.forEach(
      //         item => {
      //           if (item.dataTypes.find(
      //             item => (item === 'table_2_4' || item === 'table_2_41'))) {
      //             setScores_2_4(item.scores)
      //           }
      //         }
      //       )
      //     }
      //   )
      // }

      setScores_2_3(
        getScores2Query.data.ecGetScoresTableBySelector.items.filter((item: any) => item.dataType === 'table_2_3')
          .reduce((accumulator: any, currentValue: any) => accumulator + Number(currentValue.actualScores), 0)
      )

      setScores_2_7(
        getScores2Query.data.ecGetScoresTableBySelector.items.filter((item: any) => item.dataType === 'table_2_7')
          .reduce((accumulator: any, currentValue: any) => accumulator + Number(currentValue.actualScores), 0)
      )

      setScores_2_8(
        getScores2Query.data.ecGetScoresTableBySelector.items.filter((item: any) => item.dataType === 'table_2_8')
          .reduce((accumulator: any, currentValue: any) => accumulator + Number(currentValue.actualScores), 0)
      )

      setScores_2_9(
        getScores2Query.data.ecGetScoresTableBySelector.items.filter((item: any) => item.dataType === 'table_2_9')
          .reduce((accumulator: any, currentValue: any) => accumulator + Number(currentValue.actualScores), 0)
      )


      getScores2Query.data.ecGetScoresTableBySelector.items.forEach(
        (item: any) => {
          if (item.dataType === 'table_2_4') {
            setScores_2_4(item.actualScores)
          }
          if (item.dataType === 'table_2_41') {
            setScores_2_41(item.actualScores)
          }


          if (item.dataType === 'table_2_2') {
            setScores_2_2(item.actualScores)
            // setScores_2_2(item.scores) // баллы с ограничениями
          }


          if (item.dataType === 'table_2_5') {
            setScores_2_5(item.actualScores)
            // setScores_2_5(item.scores) // баллы с ограничениями
          }


          if (item.dataType === 'table_2_6') {
            setScores_2_6(item.actualScores)
            // setScores_2_6(item.scores) // баллы с ограничениями
          }


          if (item.dataType === 'table_2_10') {
            setScores_2_10(item.actualScores)
            // setScores_2_10(item.scores) // баллы с ограничениями
          }
        }
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getScores2Query.loading,
    getScores2Query.data,
  ])


  useEffect(() => {
    if (getScores3Query.data) {
      if (getScores3Query.data.ecGetScoresTableBySelector.length === 0) {
        setScoresByDefault3()
      }

      getScores3Query.data.ecGetScoresTableBySelector.forEach((item: any) => {
        if (item.dataType === 'table_3_1') {
          setScores_3_1(item.actualScores)
          // setScores_3_1(item.scores) // баллы с ограничениями
        }

        if (item.dataType === 'table_3_2') {
          setScores_3_2(item.actualScores)
          // setScores_3_2(item.scores) // баллы с ограничениями
        }

        if (item.dataType === 'table_3_3') {
          setScores_3_3(item.actualScores)
          // setScores_3_3(item.scores) // баллы с ограничениями
        }

        if (item.dataType === 'table_3_4') {
          setScores_3_4(item.actualScores)
          // setScores_3_4(item.scores) // баллы с ограничениями
        }

        if (item.dataType === 'table_3_5') {
          setScores_3_5(item.actualScores)
          // setScores_3_5(item.scores) // баллы с ограничениями
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getScores3Query.loading,
    getScores3Query.data,
  ])

  useEffect(() => {
    if (getScores4Query.data) {
      if (getScores4Query.data.ecGetScoresTableBySelector.length === 0) {
        setScoresByDefault4()
      }

      getScores4Query.data.ecGetScoresTableBySelector.forEach((item: any) => {
        if (item.dataType === 'table_4_1') {
          setScores_4_1(item.scores)
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getScores4Query.loading,
    getScores4Query.data,
  ])


  useEffect(() => {
    if (queryTable_1_3.data?.ecListData) {
      setTable_1_3(
        queryTable_1_3.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_1_4.data?.ecListData) {
      setTable_1_4(
        queryTable_1_4.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_1_41.data?.ecListData) {
      setTable_1_41(
        queryTable_1_41.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_2.data?.ecListData) {
      setTable_2_2(
        queryTable_2_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_3.data?.ecListData) {
      setTable_2_3(
        queryTable_2_3.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_4.data?.ecListData) {
      setTable_2_4(
        queryTable_2_4.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_41.data?.ecListData) {
      setTable_2_41(
        queryTable_2_41.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_5.data?.ecListData) {
      setTable_2_5(
        queryTable_2_5.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_6.data?.ecListData) {
      setTable_2_6(
        queryTable_2_6.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_7.data?.ecListData) {
      setTable_2_7(
        queryTable_2_7.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_8.data?.ecListData) {
      setTable_2_8(
        queryTable_2_8.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_9.data?.ecListData) {
      setTable_2_9(
        queryTable_2_9.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_10.data?.ecListData) {
      setTable_2_10(
        queryTable_2_10.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_1.data?.ecListData) {
      setTable_3_1(
        queryTable_3_1.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_2.data?.ecListData) {
      setTable_3_2(
        queryTable_3_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_3.data?.ecListData) {
      setTable_3_3(
        queryTable_3_3.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_4.data?.ecListData) {
      setTable_3_4(
        queryTable_3_4.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_5.data?.ecListData) {
      setTable_3_5(
        queryTable_3_5.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_4_1.data?.ecListData) {
      setTable_4_1(
        queryTable_4_1.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    // TODO Переписать это дерьмо

    if (queryTable_2_2_date.data?.ecListData) {
      setTable_2_2_date(
        queryTable_2_2_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_3_date.data?.ecListData) {
      setTable_2_3_date(
        queryTable_2_3_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_4_date.data?.ecListData) {
      setTable_2_4_date(
        queryTable_2_4_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_41_date.data?.ecListData) {
      setTable_2_41_date(
        queryTable_2_41_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_5_date.data?.ecListData) {
      setTable_2_5_date(
        queryTable_2_5_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_6_date.data?.ecListData) {
      setTable_2_6_date(
        queryTable_2_6_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_7_date.data?.ecListData) {
      setTable_2_7_date(
        queryTable_2_7_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_8_date.data?.ecListData) {
      setTable_2_8_date(
        queryTable_2_8_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_9_date.data?.ecListData) {
      setTable_2_9_date(
        queryTable_2_9_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_10_date.data?.ecListData) {
      setTable_2_10_date(
        queryTable_2_10_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_1_date.data?.ecListData) {
      setTable_3_1_date(
        queryTable_3_1_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_2_date.data?.ecListData) {
      setTable_3_2_date(
        queryTable_3_2_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_3_date.data?.ecListData) {
      setTable_3_3_date(
        queryTable_3_3_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_4_date.data?.ecListData) {
      setTable_3_4_date(
        queryTable_3_4_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_5_date.data?.ecListData) {
      setTable_3_5_date(
        queryTable_3_5_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_4_1_date.data?.ecListData) {
      setTable_4_1_date(
        queryTable_4_1_date.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    // TODO Переписать это дерьмо

    if (queryTable_2_2_date_2.data?.ecListData) {
      setTable_2_2_date_2(
        queryTable_2_2_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_3_date_2.data?.ecListData) {
      setTable_2_3_date_2(
        queryTable_2_3_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_4_date_2.data?.ecListData) {
      setTable_2_4_date_2(
        queryTable_2_4_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_41_date_2.data?.ecListData) {
      setTable_2_41_date_2(
        queryTable_2_41_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_5_date_2.data?.ecListData) {
      setTable_2_5_date_2(
        queryTable_2_5_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_6_date_2.data?.ecListData) {
      setTable_2_6_date_2(
        queryTable_2_6_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_7_date_2.data?.ecListData) {
      setTable_2_7_date_2(
        queryTable_2_7_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_8_date_2.data?.ecListData) {
      setTable_2_8_date_2(
        queryTable_2_8_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_9_date_2.data?.ecListData) {
      setTable_2_9_date_2(
        queryTable_2_9_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_2_10_date_2.data?.ecListData) {
      setTable_2_10_date_2(
        queryTable_2_10_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_1_date_2.data?.ecListData) {
      setTable_3_1_date_2(
        queryTable_3_1_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_2_date_2.data?.ecListData) {
      setTable_3_2_date_2(
        queryTable_3_2_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_3_date_2.data?.ecListData) {
      setTable_3_3_date_2(
        queryTable_3_3_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_4_date_2.data?.ecListData) {
      setTable_3_4_date_2(
        queryTable_3_4_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_3_5_date_2.data?.ecListData) {
      setTable_3_5_date_2(
        queryTable_3_5_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }

    if (queryTable_4_1_date_2.data?.ecListData) {
      setTable_4_1_date_2(
        queryTable_4_1_date_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            creator_id: item.creator_id,
          }
        }))
    }
  }, [
    queryTable_1_1.loading,
    queryTable_1_3.loading,
    queryTable_1_4.loading,
    queryTable_1_41.loading,
    queryTable_2_2.loading,
    queryTable_2_3.loading,
    queryTable_2_39.loading,
    queryTable_2_4.loading,
    queryTable_2_41.loading,
    queryTable_2_5.loading,
    queryTable_2_6.loading,
    queryTable_2_7.loading,
    queryTable_2_79.loading,
    queryTable_2_8.loading,
    queryTable_2_9.loading,
    queryTable_2_10.loading,
    queryTable_3_1.loading,
    queryTable_3_2.loading,
    queryTable_3_3.loading,
    queryTable_3_4.loading,
    queryTable_3_5.loading,
    queryTable_4_1.loading,

    queryTable_2_2_date.loading,
    queryTable_2_3_date.loading,
    queryTable_2_39_date.loading,
    queryTable_2_4_date.loading,
    queryTable_2_41_date.loading,
    queryTable_2_5_date.loading,
    queryTable_2_6_date.loading,
    queryTable_2_7_date.loading,
    queryTable_2_79_date.loading,
    queryTable_2_8_date.loading,
    queryTable_2_9_date.loading,
    queryTable_2_10_date.loading,
    queryTable_3_1_date.loading,
    queryTable_3_2_date.loading,
    queryTable_3_3_date.loading,
    queryTable_3_4_date.loading,
    queryTable_3_5_date.loading,
    queryTable_4_1_date.loading,

    queryTable_2_2_date_2.loading,
    queryTable_2_3_date_2.loading,
    queryTable_2_39_date_2.loading,
    queryTable_2_4_date_2.loading,
    queryTable_2_41_date_2.loading,
    queryTable_2_5_date_2.loading,
    queryTable_2_6_date_2.loading,
    queryTable_2_7_date_2.loading,
    queryTable_2_79_date_2.loading,
    queryTable_2_8_date_2.loading,
    queryTable_2_9_date_2.loading,
    queryTable_2_10_date_2.loading,
    queryTable_3_1_date_2.loading,
    queryTable_3_2_date_2.loading,
    queryTable_3_3_date_2.loading,
    queryTable_3_4_date_2.loading,
    queryTable_3_5_date_2.loading,
    queryTable_4_1_date_2.loading,

    queryTable_1_1.data,
    queryTable_1_3.data,
    queryTable_1_4.data,
    queryTable_1_41.data,
    queryTable_2_2.data,
    queryTable_2_3.data,
    queryTable_2_39.data,
    queryTable_2_4.data,
    queryTable_2_41.data,
    queryTable_2_5.data,
    queryTable_2_6.data,
    queryTable_2_7.data,
    queryTable_2_79.data,
    queryTable_2_8.data,
    queryTable_2_9.data,
    queryTable_2_10.data,
    queryTable_3_1.data,
    queryTable_3_2.data,
    queryTable_3_3.data,
    queryTable_3_4.data,
    queryTable_3_5.data,
    queryTable_4_1.data,

    queryTable_2_2_date.data,
    queryTable_2_3_date.data,
    queryTable_2_39_date.data,
    queryTable_2_4_date.data,
    queryTable_2_41_date.data,
    queryTable_2_5_date.data,
    queryTable_2_6_date.data,
    queryTable_2_7_date.data,
    queryTable_2_79_date.data,
    queryTable_2_8_date.data,
    queryTable_2_9_date.data,
    queryTable_2_10_date.data,
    queryTable_3_1_date.data,
    queryTable_3_2_date.data,
    queryTable_3_3_date.data,
    queryTable_3_4_date.data,
    queryTable_3_5_date.data,
    queryTable_4_1_date.data,

    queryTable_2_2_date_2.data,
    queryTable_2_3_date_2.data,
    queryTable_2_39_date_2.data,
    queryTable_2_4_date_2.data,
    queryTable_2_41_date_2.data,
    queryTable_2_5_date_2.data,
    queryTable_2_6_date_2.data,
    queryTable_2_7_date_2.data,
    queryTable_2_79_date_2.data,
    queryTable_2_8_date_2.data,
    queryTable_2_9_date_2.data,
    queryTable_2_10_date_2.data,
    queryTable_3_1_date_2.data,
    queryTable_3_2_date_2.data,
    queryTable_3_3_date_2.data,
    queryTable_3_4_date_2.data,
    queryTable_3_5_date_2.data,
    queryTable_4_1_date_2.data,
  ])


  const ecSubscription = useEcSubscription()
  useEffect(() => {
    getScores2Query.refetch()
    getScores3Query.refetch()
    getScores4Query.refetch()

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_1_1') {
      queryTable_1_1.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_1_3') {
      queryTable_1_3.refetch()
      getFirstPointScores_1_3.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_1_4') {
      queryTable_1_4.refetch()
      getFirstPointScores_1_4.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_1_41') {
      queryTable_1_41.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_2') {
      queryTable_2_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_3') {
      queryTable_2_3.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_39') {
      queryTable_2_39.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_4') {
      queryTable_2_4.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_41') {
      queryTable_2_41.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_5') {
      queryTable_2_5.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_6') {
      queryTable_2_6.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_7') {
      queryTable_2_7.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_79') {
      queryTable_2_79.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_8') {
      queryTable_2_8.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_9') {
      queryTable_2_9.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_10') {
      queryTable_2_10.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_3_1') {
      queryTable_3_1.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_3_2') {
      queryTable_3_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_3_3') {
      queryTable_3_3.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_3_4') {
      queryTable_3_4.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_3_5') {
      queryTable_3_5.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_4_1') {
      queryTable_4_1.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_2') {
      queryTable_2_2_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_3') {
      queryTable_2_3_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_39') {
      queryTable_2_39_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_4') {
      queryTable_2_4_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_41') {
      queryTable_2_41_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_5') {
      queryTable_2_5_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_6') {
      queryTable_2_6_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_7') {
      queryTable_2_7_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_79') {
      queryTable_2_79_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_8') {
      queryTable_2_8_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_9') {
      queryTable_2_9_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_2_10') {
      queryTable_2_10_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_3_1') {
      queryTable_3_1_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_3_2') {
      queryTable_3_2_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_3_3') {
      queryTable_3_3_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_3_4') {
      queryTable_3_4_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_3_5') {
      queryTable_3_5_date_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'table_4_1') {
      queryTable_4_1.refetch()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ecSubscription.loading,
    ecSubscription.data,
  ])


  const popUpSelector = {
    // addNewRow: <PopupContentCreateOrEdit onClickHandler={ () => setIsPopUpOpen(false) } defaultValues={ { dataType: popUpData.dataType, } } />,
    displayRejectReason: <PopupContentReject title={ popUpData.achievement } reason={ popUpData.reason } onClickHandler={ () => setIsPopUpOpen(false) } />,
    addManyParticipants: <AddManyParticipants data={ popUpData } setIsPopUpOpen={ () => setIsPopUpOpen(false) } />,
    table_1_1: <POP_UP_CONTENT_1_1 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_1_3: <POP_UP_CONTENT_1_3 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_1_4: <POP_UP_CONTENT_1_4 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_1_41: <POP_UP_CONTENT_1_41 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_2: <POP_UP_CONTENT_2_2 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_3: <POP_UP_CONTENT_2_3 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_4: <POP_UP_CONTENT_2_4 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_41: <POP_UP_CONTENT_2_41 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_5: <POP_UP_CONTENT_2_5 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_6: <POP_UP_CONTENT_2_6 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_7: <POP_UP_CONTENT_2_7 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_10: <POP_UP_CONTENT_2_10 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_3_1: <POP_UP_CONTENT_3_1 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_3_2: <POP_UP_CONTENT_3_2 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_3_3: <POP_UP_CONTENT_3_3 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_3_4: <POP_UP_CONTENT_3_4 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_3_5: <POP_UP_CONTENT_3_5 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
  }


  return (
    <div className="effective-contract">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
          {
            value: 'Эффективный контракт',
            path: EFFECTIVE_CONTRACT_ROUTE,
          },
        ] }
        loc="Модерация достижений"
      />

      <h2 className="effective-contract__title">
        Модерация достижений: { fullName }
      </h2>

      <CollapseContainer2 styles={ { marginBottom: '2rem', } }>
        <AccordionInnder
          headerText={ (
            <span className="effective-contract__subtitle">
              Фильтрация достижений
            </span>
          ) }
        >

          <Filters
            setAchievementType={ setAchievementType }
            setAchievementStatus={ setAchievementStatus }
            setAchievementDate={ setAchievementDate }
          />
        </AccordionInnder>
      </CollapseContainer2>

      { (role === 'dm-moder'
        || role === 'ufpn-moder'
        // || role === 'urvo-moder'
      )
        && (achievementType === 'all' || achievementType === 'table_1_1')
        ? (
          <h3 className="effective-contract-add-chievement__subtitle">
            Базовые показатели
          </h3>
        ) : false }

      <CollapseContainer2>
        { (role === 'dm-moder')
          && (achievementType === 'all' || achievementType === 'table_1_3')
          ? (
            <AccordionInnder
              isOpened
              Icon={ IconUser }
              headerText="Участие в профориентационных мероприятиях, организованных кафедрой, институтом/факультетом, Университетом (показатель качества)"
              subHeaderText={ scores_1_3 }
            >
              <TableContainer
                dataType={ EcTableName.TABLE_1_3 }
                headerFields={ headerFields_1_3 }
                // @ts-ignore
                ecTableData={ table_1_3 }
              />
            </AccordionInnder>
          ) : false }


        { role === 'ufpn-moder' && (achievementType === 'all' || achievementType === 'table_1_4') ? (
          <AccordionInnder
            Icon={ IconBulb }
            headerText="Участие в научно-исследовательской работе по профилю кафедры, института (факультета) и(или) преподаваемого курса, дисциплины (модуля) (показатель интенсивности)"
            subHeaderText={ scores_1_4 }
          >
            <TableContainer
              dataType={ EcTableName.TABLE_1_4 }
              // isEditable={ role !== 'ufpn-moder' }
              headerFields={ headerFields_1_4 }
              // @ts-ignore
              ecTableData={ table_1_4 }
            />

            <br /><br /><br />

            <TableContainer
              dataType={ EcTableName.TABLE_1_41 }
              // isEditable={ role !== 'ufpn-moder' }
              headerFields={ headerFields_1_41 }
              // @ts-ignore
              ecTableData={ table_1_41 }
            />
          </AccordionInnder>
        ) : false }
      </CollapseContainer2>


      {
        (
          role === 'ufpn-moder'
          || role === 'urvo-moder'
          || role === 'tpd-moder'
          || role === 'uonid-moder'
          || role === 'ukp-moder'
        )
          && (achievementType === 'all'
            || achievementType === 'table_2_2'
            || achievementType === 'table_2_3'
            || achievementType === 'table_2_4'
            || achievementType === 'table_2_5'
            || achievementType === 'table_2_6'
          )
          ? (
            <h3
              style={ { marginTop: '2rem', } }
              className="effective-contract-add-chievement__subtitle"
            >
              Показатели высокой эффективности труда
            </h3>
          ) : false }

      <CollapseContainer2>
        { (role === 'urvo-moder')
          && (achievementType === 'all' || achievementType === 'table_2_2')
          && (
            <AccordionInnder
              Icon={ IconWorld }
              headerText="Владение иностранным языком"
              subHeaderText={ `Баллы: ${ Number(scores_2_2) }` }
            >
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2024</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_2 }
                headerFields={ headerFields_2_2 }
                // @ts-ignore
                ecTableData={ table_2_2_date_2 }
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2023</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_2 }
                headerFields={ headerFields_2_2 }
                // @ts-ignore
                ecTableData={ table_2_2_date }
                isAppealMode={true}
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2022</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_2 }
                headerFields={ headerFields_2_2 }
                // @ts-ignore
                ecTableData={ table_2_2 }
                isAppealMode={true}
              />
            </AccordionInnder>
          ) }

        { (
          role === 'urvo-moder'
          || role === 'ufpn-moder'
        )
          && (achievementType === 'all' || achievementType === 'table_2_3')
          && (
            <AccordionInnder
              Icon={ IconBook }
              headerText="Издание учебной и научной литературы"
              subHeaderText={ `Баллы: ${ Number(scores_2_3) }` }
            >
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2024</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_3 }
                // isEditable={ role !== 'ufpn-moder' }
                headerFields={ headerFields_2_3 }
                // @ts-ignore
                ecTableData={ table_2_3_date_2 }
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2023</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_3 }
                // isEditable={ role !== 'ufpn-moder' }
                headerFields={ headerFields_2_3 }
                // @ts-ignore
                ecTableData={ table_2_3_date }
                isAppealMode={true}
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2022</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_3 }
                // isEditable={ role !== 'ufpn-moder' }
                headerFields={ headerFields_2_3 }
                // @ts-ignore
                ecTableData={ table_2_3 }
                isAppealMode={true}
              />
            </AccordionInnder>
          ) }

        { (role === 'urvo-moder')
          && (achievementType === 'all' || achievementType === 'table_2_4')
          && (
            <AccordionInnder
              Icon={ IconPlayerPlay }
              headerText="Издание мультимедийной учебной литературы и (или) разработка нового онлайн-курса по программам ВО и (или) ДО (показатель интенсивности)"
              subHeaderText={ `Баллы: ${ Number(scores_2_4) + Number(scores_2_41) }` }
            // subHeaderText={ `Баллы: ${ scores_2_4 }` }
            >
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2024</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_4 }
                headerFields={ headerFields_2_4 }
                // @ts-ignore
                ecTableData={ table_2_4_date_2 }
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2023</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_4 }
                headerFields={ headerFields_2_4 }
                // @ts-ignore
                ecTableData={ table_2_4_date }
                isAppealMode={true}
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2022</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_4 }
                headerFields={ headerFields_2_4 }
                // @ts-ignore
                ecTableData={ table_2_4 }
                isAppealMode={true}
              />

              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2024</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_41 }
                headerFields={ headerFields_2_41 }
                // @ts-ignore
                ecTableData={ table_2_41_date_2 }
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2023</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_41 }
                headerFields={ headerFields_2_41 }
                // @ts-ignore
                ecTableData={ table_2_41_date }
                isAppealMode={true}
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2022</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_41 }
                headerFields={ headerFields_2_41 }
                // @ts-ignore
                ecTableData={ table_2_41 }
                isAppealMode={true}
              />
            </AccordionInnder>
          ) }

        { (role === 'urvo-moder'
          || role === 'uonid-moder'
          || role === 'ukp-moder'
          || role === 'tpd-moder')
          && (achievementType === 'all' || achievementType === 'table_2_5')
          && (
            <AccordionInnder
              Icon={ IconUsers }
              headerText="Подготовка студентов-победителей и призеров олимпиад/конкурсов"
              subHeaderText={ `Баллы: ${ Number(scores_2_5) }` }
            >
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2024</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_5 }
                headerFields={ headerFields_2_5 }
                // @ts-ignore
                ecTableData={ table_2_5_date_2 }
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2023</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_5 }
                headerFields={ headerFields_2_5 }
                // @ts-ignore
                ecTableData={ table_2_5_date }
                isAppealMode={true}
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2022</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_5 }
                headerFields={ headerFields_2_5 }
                // @ts-ignore
                ecTableData={ table_2_5 }
                isAppealMode={true}
              />
            </AccordionInnder>
          ) }

        { (
          role === 'urvo-moder'
          || role === 'dms-moder'
        )
          && (achievementType === 'all' || achievementType === 'table_2_6')
          && (
            <AccordionInnder
              Icon={ IconSchool }
              headerText="Визит в партнерский университет для чтения курсов, в рамках образовательных программ принимающего университета"
              subHeaderText={ `Баллы: ${ Number(scores_2_6) }` }
            >
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2024</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_6 }
                headerFields={ headerFields_2_6 }
                // @ts-ignore
                ecTableData={ table_2_6_date_2 }
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2023</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_6 }
                headerFields={ headerFields_2_6 }
                // @ts-ignore
                ecTableData={ table_2_6_date }
                isAppealMode={true}
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2022</div>
              <TableContainer
                dataType={ EcTableName.TABLE_2_6 }
                headerFields={ headerFields_2_6 }
                // @ts-ignore
                ecTableData={ table_2_6 }
                isAppealMode={true}
              />
            </AccordionInnder>
          ) }


        { role === 'ufpn-moder' && (achievementType === 'all' || achievementType === 'table_2_7') && (
          <AccordionInnder
            Icon={ IconNotes }
            headerText="Публикации  в изданиях,  индексируемых  в базах данных  «Web of Science»,  «Scopus»"
            subHeaderText={ `Баллы: ${ Number(scores_2_7) }` }
          >
            <div style={{ width: '100%',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: '0.35px', }}>2024</div>
            <TableContainer
              dataType={ EcTableName.TABLE_2_7 }
              // isEditable={ role !== 'ufpn-moder' }
              headerFields={ headerFields_2_7 }
              // @ts-ignore
              ecTableData={ table_2_7_date_2 }
            />
            <div style={{ width: '100%',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: '0.35px', }}>2023</div>
            <TableContainer
              dataType={ EcTableName.TABLE_2_7 }
              // isEditable={ role !== 'ufpn-moder' }
              headerFields={ headerFields_2_7 }
              // @ts-ignore
              ecTableData={ table_2_7_date }
              isAppealMode={true}
            />
            <div style={{ width: '100%',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: '0.35px', }}>2022</div>
            <TableContainer
              dataType={ EcTableName.TABLE_2_7 }
              // isEditable={ role !== 'ufpn-moder' }
              headerFields={ headerFields_2_7 }
              // @ts-ignore
              ecTableData={ table_2_7 }
              isAppealMode={true}
            />
          </AccordionInnder>
        ) }


        { role === 'ufpn-moder' && (achievementType === 'all' || achievementType === 'table_2_8') && (
          <AccordionInnder
            Icon={ IconTrophy }
            headerText="Научные гранты и проекты университета, получившие финансовую поддержку"
            subHeaderText={ `Баллы: ${ Number(scores_2_8) }` }
          >
            <div style={{ width: '100%',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: '0.35px', }}>2024</div>
            <TableContainer
              dataType={ EcTableName.TABLE_2_8 }
              // userGuid={ moderateId }
              // isEditable={ false }
              headerFields={ headerFields_2_8 }
              // @ts-ignore
              ecTableData={ table_2_8_date_2 }
            />
            <div style={{ width: '100%',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: '0.35px', }}>2023</div>
            <TableContainer
              dataType={ EcTableName.TABLE_2_8 }
              // userGuid={ moderateId }
              // isEditable={ false }
              headerFields={ headerFields_2_8 }
              // @ts-ignore
              ecTableData={ table_2_8_date }
              isAppealMode={true}
            />

            <div style={{ width: '100%',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: '0.35px', }}>2022</div>
            <TableContainer
              dataType={ EcTableName.TABLE_2_8 }
              // userGuid={ moderateId }
              // isEditable={ false }
              headerFields={ headerFields_2_8 }
              // @ts-ignore
              ecTableData={ table_2_8 }
              isAppealMode={true}
            />
          </AccordionInnder>
        ) }


        { role === 'ufpn-moder' && (achievementType === 'all' || achievementType === 'table_2_9') && (
          <AccordionInnder
            Icon={ IconSquareCheck }
            headerText="Результаты инновационной деятельности, прошедшей утверждение на НТС университета"
            subHeaderText={ `Баллы: ${ Number(scores_2_9) }` }
          >
            <div style={{ width: '100%',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: '0.35px', }}>2024</div>
            <TableContainer
              dataType={ EcTableName.TABLE_2_9 }
              // isEditable={ false }
              headerFields={ headerFields_2_9 }
              // @ts-ignore
              ecTableData={ table_2_9_date_2 }
            />
            <div style={{ width: '100%',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: '0.35px', }}>2023</div>
            <TableContainer
              dataType={ EcTableName.TABLE_2_9 }
              // isEditable={ false }
              headerFields={ headerFields_2_9 }
              // @ts-ignore
              ecTableData={ table_2_9_date }
              isAppealMode={true}
            />

            <div style={{ width: '100%',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: '0.35px', }}>2022</div>
            <TableContainer
              dataType={ EcTableName.TABLE_2_9 }
              // isEditable={ false }
              headerFields={ headerFields_2_9 }
              // @ts-ignore
              ecTableData={ table_2_9 }
              isAppealMode={true}
            />
          </AccordionInnder>
        ) }


        { role === 'ufpn-moder' && (achievementType === 'all' || achievementType === 'table_2_10') && (
          <AccordionInnder
            Icon={ IconUser }
            headerText="Членство в составе редколлегий российских и зарубежных журналов WoS и/или Scopus, журналах из списка ВАК"
            subHeaderText={ `Баллы: ${ Number(scores_2_10) }` }
          >
            <div style={{ width: '100%',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: '0.35px', }}>2024</div>
            <TableContainer
              dataType={ EcTableName.TABLE_2_10 }
              // isEditable={ role !== 'ufpn-moder' }
              headerFields={ headerFields_2_10 }
              // @ts-ignore
              ecTableData={ table_2_10_date_2 }
            />
            <div style={{ width: '100%',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: '0.35px', }}>2023</div>
            <TableContainer
              dataType={ EcTableName.TABLE_2_10 }
              // isEditable={ role !== 'ufpn-moder' }
              headerFields={ headerFields_2_10 }
              // @ts-ignore
              ecTableData={ table_2_10_date }
              isAppealMode={true}
            />
            <div style={{ width: '100%',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: '0.35px', }}>2022</div>
            <TableContainer
              dataType={ EcTableName.TABLE_2_10 }
              // isEditable={ role !== 'ufpn-moder' }
              headerFields={ headerFields_2_10 }
              // @ts-ignore
              ecTableData={ table_2_10 }
              isAppealMode={true}
            />
          </AccordionInnder>
        ) }


        { (
          role === 'urvo-moder'
          || role === 'ufpn-moder'
        )
          && (achievementType === 'all'
            || achievementType === 'table_3_1'
            || achievementType === 'table_3_2'
            || achievementType === 'table_3_3'
            || achievementType === 'table_3_4'
            || achievementType === 'table_3_5'
          )
          ? (
            <h3
              style={ { marginTop: '2rem', } }
              className="effective-contract-add-chievement__subtitle"
            >
              Бонусные баллы
            </h3>
          ) : false }


        { (
          role === 'urvo-moder'
          || role === 'ufpn-moder'
        )
          && (achievementType === 'all' || achievementType === 'table_3_1')
          && (
            <AccordionInnder
              Icon={ IconShield }
              headerText="Диссертационная деятельность"
              subHeaderText={ `Баллы: ${ Number(scores_3_1) }` }
            >
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2024</div>
              <TableContainer
                dataType={ EcTableName.TABLE_3_1 }
                // isEditable={ role !== 'ufpn-moder' }
                headerFields={ headerFields_3_1 }
                // @ts-ignore
                ecTableData={ table_3_1_date_2 }
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2023</div>
              <TableContainer
                dataType={ EcTableName.TABLE_3_1 }
                // isEditable={ role !== 'ufpn-moder' }
                headerFields={ headerFields_3_1 }
                // @ts-ignore
                ecTableData={ table_3_1_date }
                isAppealMode={true}
              />

              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2022</div>
              <TableContainer
                dataType={ EcTableName.TABLE_3_1 }
                // isEditable={ role !== 'ufpn-moder' }
                headerFields={ headerFields_3_1 }
                // @ts-ignore
                ecTableData={ table_3_1 }
                isAppealMode={true}
              />
            </AccordionInnder>
          ) }


        { (role === 'urvo-moder')
          && (achievementType === 'all' || achievementType === 'table_3_2')
          && (
            <AccordionInnder
              Icon={ IconMedal }
              headerText="Получение степени PhD, признаваемой в России"
              subHeaderText={ `Баллы: ${ Number(scores_3_2) }` }
            >
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2024</div>
              <TableContainer
                dataType={ EcTableName.TABLE_3_2 }
                headerFields={ headerFields_3_2 }
                // @ts-ignore
                ecTableData={ table_3_2_date_2 }
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2023</div>
              <TableContainer
                dataType={ EcTableName.TABLE_3_2 }
                headerFields={ headerFields_3_2 }
                // @ts-ignore
                ecTableData={ table_3_2_date }
                isAppealMode={true}
              />

              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2022</div>
              <TableContainer
                dataType={ EcTableName.TABLE_3_2 }
                headerFields={ headerFields_3_2 }
                // @ts-ignore
                ecTableData={ table_3_2 }
                isAppealMode={true}
              />
            </AccordionInnder>
          ) }


        { (role === 'urvo-moder')
          && (achievementType === 'all' || achievementType === 'table_3_3')
          && (
            <AccordionInnder
              Icon={ IconFileInvoice }
              headerText="Экспертная деятельность, членство в жюри, судейство федерального или международного уровней"
              subHeaderText={ `Баллы: ${ Number(scores_3_3) }` }
            >
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2024</div>
              <TableContainer
                dataType={ EcTableName.TABLE_3_3 }
                headerFields={ headerFields_3_3 }
                // @ts-ignore
                ecTableData={ table_3_3_date_2 }
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2023</div>
              <TableContainer
                dataType={ EcTableName.TABLE_3_3 }
                headerFields={ headerFields_3_3 }
                // @ts-ignore
                ecTableData={ table_3_3_date }
                isAppealMode={true}
              />

              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2022</div>
              <TableContainer
                dataType={ EcTableName.TABLE_3_3 }
                headerFields={ headerFields_3_3 }
                // @ts-ignore
                ecTableData={ table_3_3 }
                isAppealMode={true}
              />
            </AccordionInnder>
          ) }


        { (role === 'urvo-moder')
          && (achievementType === 'all' || achievementType === 'table_3_4')
          && (
            <AccordionInnder
              Icon={ IconNews }
              headerText="Участие в стратегических, инновационных и иных проектах Университета"
              subHeaderText={ `Баллы: ${ Number(scores_3_4) }` }
            >
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2024</div>
              <TableContainer
                dataType={ EcTableName.TABLE_3_4 }
                headerFields={ headerFields_3_4 }
                // @ts-ignore
                ecTableData={ table_3_4_date_2 }
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2023</div>
              <TableContainer
                dataType={ EcTableName.TABLE_3_4 }
                headerFields={ headerFields_3_4 }
                // @ts-ignore
                ecTableData={ table_3_4_date }
                isAppealMode={true}
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2022</div>
              <TableContainer
                dataType={ EcTableName.TABLE_3_4 }
                headerFields={ headerFields_3_4 }
                // @ts-ignore
                ecTableData={ table_3_4 }
                isAppealMode={true}
              />
            </AccordionInnder>
          ) }


        {/* { (role === 'urvo-moder')*/}
        {/*  && stage === 'stage-7'*/}
        {/*  && (achievementType === 'all' || achievementType === 'table_3_5')*/}
        {/*  && (*/}
        {/*    <AccordionInnder*/}
        {/*      Icon={ IconAward }*/}
        {/*      headerText="Отдельные достижения, имеющие значимость для Университета"*/}
        {/*      subHeaderText={ `Баллы: ${ scores_3_5 }` }*/}
        {/*    >*/}
        {/*      <TableContainer*/}
        {/*        dataType={ EcTableName.TABLE_3_5 }*/}
        {/*        headerFields={ headerFields_3_5 }*/}
        {/*        // @ts-ignore*/}
        {/*        ecTableData={ table_3_5 }*/}
        {/*      />*/}
        {/*    </AccordionInnder>*/}
        {/*  ) }*/}

      </CollapseContainer2>


      { (role === 'urvo-moder')
        && (achievementType === 'all'
          || achievementType === 'table_4_1'
        )
        ? (
          <h3
            style={ { marginTop: '2rem', } }
            className="effective-contract-add-chievement__subtitle"
          >
            Основания для снижения выплат стимулирующего характера
          </h3>
        ) : false }

      <CollapseContainer2>
        { (role === 'urvo-moder')
          && (achievementType === 'all' || achievementType === 'table_4_1')
          && (
            <AccordionInnder
              Icon={ IconWorld }
              headerText="Неисполнение индивидуального плана развития"
              subHeaderText={ `Баллы: ${ Number(scores_4_1) }` }
            >
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2024</div>
              <TableContainer
                dataType={ EcTableName.TABLE_4_1 }
                // isEditable={ false }
                headerFields={ headerFields_4_1 }
                // @ts-ignore
                ecTableData={ table_4_1_date_2 }
              />
              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2023</div>
              <TableContainer
                dataType={ EcTableName.TABLE_4_1 }
                // isEditable={ false }
                headerFields={ headerFields_4_1 }
                // @ts-ignore
                ecTableData={ table_4_1_date }
                isAppealMode={true}
              />

              <div style={{ width: '100%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: '0.35px', }}>2022</div>
              <TableContainer
                dataType={ EcTableName.TABLE_4_1 }
                // isEditable={ false }
                headerFields={ headerFields_4_1 }
                // @ts-ignore
                ecTableData={ table_4_1 }
                isAppealMode={true}
              />
            </AccordionInnder>
          ) }
      </CollapseContainer2>


      <Popup
        isOpen={ isPopupOpen }
        handlePopup={ () => setIsPopUpOpen(false) }
        withScroll
      >
        {
          // @ts-expect-error TS(2538): Type 'null' cannot be used as an index type.
          popUpSelector[popUpContent]
        }
      </Popup>
    </div>
  )
}


const Filters = ({
  setAchievementType,
  setAchievementStatus,
  setAchievementDate,
}: any) => {
  const achievementTypes = [
    {
      itemName: 'all',
      value: 'Все',
    },
    {
      itemName: 'table_1_1',
      value: 'Учебно - методическое обеспечение образовательного процесса',

    },
    {
      itemName: 'table_1_3',
      value: 'Участие в профориентационных мероприятиях, организованных кафедрой, институтом/факультетом, Университетом',
    },
    {
      itemName: 'table_2_2',
      value: 'Владение иностранным языком',
    },
    {
      itemName: 'table_2_3',
      value: 'Издание учебной и научной литературы',
    },
    {
      itemName: 'table_2_4',
      value: 'Издание мультимедийной учебной литературы и (или) разработка нового онлайн-курса по программам ВО и (или) ДО',
    },
    {
      itemName: 'table_2_5',
      value: 'Подготовка студентов-победителей и призеров олимпиад/конкурсов',
    },
    {
      itemName: 'table_2_6',
      value: 'Визит в партнерский университет для чтения курсов, в рамках образовательных программ принимающего университета',
    },

    {
      itemName: 'table_3_1',
      value: 'Диссертационная деятельность',
    },
    {
      itemName: 'table_3_2',
      value: 'Получение степени PhD, признаваемой в России',
    },
    {
      itemName: 'table_3_3',
      value: 'Экспертная деятельность, членство в жюри, судейство федерального или международного уровней',
    },
    {
      itemName: 'table_3_4',
      value: 'Участие в стратегических, инновационных и иных проектах университета',
    },
    // {
    //   itemName: 'table_3_5',
    //   value: 'Отдельные достижения, имеющие значимость для Университета',
    // },
    {
      itemName: 'table_4_1',
      value: 'Неисполнение индивидуального плана развития',
    },
  ]

  const achievementStatuses = [
    {
      itemName: '',
      value: 'Все',
    },
    {
      itemName: 'pending',
      value: 'Ожидает проверки',
    },
    {
      itemName: 'rejected',
      value: 'Отклонено',
    },
    {
      itemName: 'approved',
      value: 'Подтверждено',
    },
  ]

  const [
    achievementTypeFilter,
    setAchievementTypeFilter,
  ] = useState('all')
  const [
    achievementStatusFilter,
    setAchievementStatusFilter,
  ] = useState('')
  const [
    achievementDateFilter,
    setAchievementDateFilter,
  ] = useState('')

  return (
    <div className="effective-contract__filters-wrapper">
      <FilterDropDown
        customWidth="36rem"
        maxWidthOfinput="33rem"
        defaultHint="Тип достижения"
        items={ achievementTypes.map(type => type.value) }
        onClickHandler={ (answer: any) => {
          setAchievementTypeFilter(
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            achievementTypes.find(item => item.value === answer).itemName)
        } }
        withBorder
      />

      <FilterDropDown
        customWidth="21rem"
        defaultHint="Статус достижения"
        items={ achievementStatuses.map(type => type.value) }
        onClickHandler={ (answer: any) => {
          setAchievementStatusFilter(
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            achievementStatuses.find(item => item.value === answer).itemName)
        } }
        withBorder
      />

      <CalendarSimple
        styles={ { width: '18rem', } }
        returnRange={ (range: any) => {
          setAchievementDateFilter({
            // @ts-expect-error TS(2345): Argument of type '{ from: string; to: string; }' i... Remove this comment to see the full error message
            from: format(new Date(range[0]), 'yyyy-MM-dd'),
            to: format(new Date(range[1]), 'yyyy-MM-dd'),
          })
        } }
        withBorder
      />

      <ButtonSimple
        value="Применить"
        variant="fill"
        onClickHandler={ () => {
          setAchievementType(achievementTypeFilter)
          setAchievementStatus(achievementStatusFilter)
          setAchievementDate(achievementDateFilter)
        } }
      />
    </div>
  )
}


export default ModeratorTemplate

import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    style: { width: '1rem', },
    editable: false,
  },
  {
    title: 'Фамилия Имя Отчество',
    index: 'authors',
    editType: 'participants',
    editable: true,
    style: { width: '12rem', },
  },
  {
    title: 'Подтверждающий документ',
    index: 'confirmingDocument',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '8rem', },
    editable: false,
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    style: { width: '1rem', },
    editable: false,
  },
  {
    title: 'Фамилия Имя Отчество',
    index: 'authors',
    editType: 'participants',
    editable: true,
    style: { width: '12rem', },
  },
  {
    title: 'Подтверждающий документ',
    index: 'confirmingDocument',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
    editable: false,
  },
]

import { useMutation, } from '@apollo/client'
import { EDIT_AVHIEVEMENT_TYPE, } from './useCreateAchievementType'


const useRenameAchievementType = () => {
  const [
    onUpdate,
  ] = useMutation(EDIT_AVHIEVEMENT_TYPE)

  const onUpdateHandler = ({
    key,
    direction_key,
    newName,
    points,
    status,
    jsondata,
    need_confirm,
    detached,
  }: any) => onUpdate({
    variables: {
      input: {
        key,
        direction_key,
        name: newName,
        points,
        status,
        jsondata,
        need_confirm,
        detached,
      },
    },
  })


  return onUpdateHandler
}


export default useRenameAchievementType

import { EcAchivementStatus, EcAppealStatus, } from '@/legacy/components/EffectiveContract/constants'
import { UUID, } from '@/legacy/types'
import { gql, } from '@/__generated__'
import { useMutation, } from '@apollo/client'


export const EC_UPDATE_DATA = gql(`
  mutation EcUpdateData(
    $rec_id:Int!,
    $new_data:JSON,
    $user_ids:[String],
    $new_extra:JSON,
    $new_appeal:JSON,
  ) {
    ecUpdateData(
      rec_id:$rec_id,
      data:$new_data,
      user_ids:$user_ids,
      extra:$new_extra,
      appeal:$new_appeal,
    )
  }
`)


export interface RequiredAchievementDataFields {
  status: EcAchivementStatus | EcAppealStatus
  reason: ''
  scores: number | null
  isAddedByModer: boolean
  userId: UUID
}

interface AllAchievementDataFields extends RequiredAchievementDataFields {
  [key: string]: unknown
}

interface OnUpdateEcDataHandler {
  recordId: number
  newData: AllAchievementDataFields
  userIds: UUID[]
}


const useUpdateEcData = () => {
  const [
    onUpdate,
  ] = useMutation(EC_UPDATE_DATA)

  const onUpdateEcDataHandler = ({
    recordId,
    newData,
    userIds,
  }: OnUpdateEcDataHandler) => onUpdate({
    variables: {
      rec_id: recordId,
      new_data: newData,
      user_ids: userIds,
    },
  })


  return {
    onUpdateEcDataHandler,
  }
}


export default useUpdateEcData

import { useMutation, } from '@apollo/client'
import { UPDATE_CONFIG, } from './useUpdateEcDocuments'


const useUpdateEcStages = () => {
  const [
    onUpdate,
  ] = useMutation(UPDATE_CONFIG)

  const onUpdateEcStageComment = (stages: any) => onUpdate({
    variables: {
      input: {
        domain: 'effective-contract',
        key: 'stages',
        mode: 'merge',
        config: {
          stages,
        },
      },
    },
  })


  const onUpdateEcActiveStage = (stage_number: any) => onUpdate({
    variables: {
      input: {
        domain: 'effective-contract',
        key: 'stages',
        mode: 'merge',
        config: {
          active_stage: {
            stage_number,
          },
        },
      },
    },
  })

  return {
    onUpdateEcStageComment,
    onUpdateEcActiveStage,
  }
}


export default useUpdateEcStages

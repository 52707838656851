import { gql, useSubscription, } from '@apollo/client'
import { useEffect, } from 'react'


const CHANGED = gql`
  subscription PersonnelConfigChanged {
    personnelConfigChanged
  }
`


const usePersonnelConfigChanged = (refetch: any) => {
  const { loading, data, } = useSubscription(CHANGED)

  useEffect(() => {
    if (!loading && data?.personnelConfigChanged && refetch) {
      refetch()
    }
  }, [
    loading,
    data?.personnelConfigChanged,
    refetch,
  ])
}


export default usePersonnelConfigChanged

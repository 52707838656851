import { gql, } from '@/__generated__/gql'
import {
  useQuery,
} from '@apollo/client'


const LIST_TYPES = gql(`
  query AchievementTypes($catid: Int) {
    achievementTypes(catid: $catid)
  }
`)

export const GET_ACHIEVEMENTS = gql(`
  query GetAchievements($input: AchievementsInput!) {
    achievements(input: $input)
  }
`)

const RATING = gql(`
  query GetAchievementRating($count: Int) {
    achievementRating(count: $count)
  }
`)

const PERSONAL_RATING = gql(`
  query GetPersonalRating {
    achievementPersonalRating
  }
`)


export const useAchievementTypes = (catid: any) => useQuery(LIST_TYPES, {
  variables: { catid, },
  fetchPolicy: 'no-cache',
})


export const useAchievementRating = () => useQuery(RATING/* , { variables: { count: 4 } } */)


export const useAchievements = ({
  limit,
  filter,
}: any) => {
  return useQuery(GET_ACHIEVEMENTS, {
    variables: {
      input: {
        filter,
        pagination: {
          offset: 0,
          limit,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })
}


export const usePersonalRating = () => useQuery(PERSONAL_RATING)

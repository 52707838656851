import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    editable: false,
    editType: 'participants',
    title: 'ФИО',
    index: 'authors',
    style: { minWidth: '20rem', },
  },
  // {
  //   editable: false,
  //   editType: 'input',
  //   title: 'Веб ссылка',
  //   index: 'web_link',
  //   style: { minWidth: '15rem', },
  // },
  {
    editable: false,
    editType: 'input',
    title: 'Название работы',
    index: 'work_title',
    style: { minWidth: '22rem', },
  },
  {
    editable: false,
    editType: 'input',
    title: 'Тип работы',
    index: 'type_of_work',
  },
  {
    editable: false,
    editType: 'input',
    title: 'Номер патента',
    index: 'patent_number',
  },
  // {
  //   editable: false,
  //   editType: 'input',
  //   title: 'Описание работы',
  //   index: 'work_description',
  //   style: { minWidth: '40rem', },
  // },
  // {
  //   editable: false,
  //   editType: 'input',
  //   title: 'Подтверждающий документ',
  //   index: 'confirmation_document',
  // },
  {
    editable: false,
    editType: 'input',
    title: 'Дата регистрации патента',
    index: 'patent_registration_date',
    indexDescription: 'date',
  },
  {
    editable: false,
    title: 'Баллы',
    index: 'scores',
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '8rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    editable: false,
    index: 'number',
  },
  {
    title: 'ФИО',
    editable: false,
    index: 'authors',
    editType: 'participants',
    style: { minWidth: '20rem', },
  },
  // {
  //   editable: false,
  //   editType: 'input',
  //   title: 'Веб ссылка',
  //   index: 'web_link',
  // },
  {
    title: 'Название работы',
    editable: false,
    editType: 'input',
    index: 'work_title',
  },
  {
    title: 'Тип работы',
    editable: false,
    editType: 'input',
    index: 'type_of_work',
  },
  {
    title: 'Номер патента',
    editable: false,
    editType: 'input',
    index: 'patent_number',
  },
  // {
  //   title: 'Описание работы',
  //   editable: false,
  //   editType: 'input',
  //   index: 'work_description',
  // },
  // {
  //   title: 'Подтверждающий документ',
  //   editable: false,
  //   editType: 'input',
  //   index: 'confirmation_document',
  // },
  {
    title: 'Дата регистрации патента',
    index: 'patent_registration_date',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
    editable: false,
  },
]

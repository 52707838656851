import dayjs from 'dayjs'
import { months, } from '@/shared/constants/months'
import { SHORT_WEEKDAYS, } from '@/shared/constants/weekdays'


export function changeWeekDays (date: any) {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const result = SHORT_WEEKDAYS.find(i => i.ru === dayjs(date).format('dd')).upd
  return result
}

export function changeFullDate (date: any) {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const month = months.find(i => i.check === dayjs(date).format('MMMM')).ru
  const year = dayjs(date).format('YYYY')

  return `${ month } ${ year } г.`
}

export function translateDate (date: any) {
  alert(dayjs(date).format('MMMM'))
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const month = months.find(i => i.en === dayjs(date).format('MMMM')).ru
  const year = dayjs(date).format('YYYY')

  return `${ month } ${ year } г.`
}

export function translateWeekDate (date: any) {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const result = SHORT_WEEKDAYS.find(i => i.en === dayjs(date).format('dd')).upd
  return result
}

import './DisciplineDetailCard.less'
import LinkButton from '../../../../common/LinkButton/LinkButton'
import PaperContainerWrapper from '../../../../common/PaperContainer/PaperContainerWrapper'


const DisciplineDetailCard = ({
  setIsPopupOpen,
  setPopupContent,
  discipline,
}: any) => {
  const { info, details, semester, hours, teachers, themes, } = discipline

  return (
    <div className='discipline-detail-card'>
      <div className='discipline-detail-card__header-wrapper'>
        <h2 className='discipline-detail-card__title'>
          { discipline.discipline }
        </h2>
      </div>
      <PaperContainerWrapper variant="sub-paper">
        <div className='discipline-detail-card__description'>
          <h3 className='discipline-detail-card__description-title'>
            Основная информация
          </h3>
          <p className='discipline-detail-card__description-subtitle'>
            Описание
          </p>
          <p className='discipline-detail-card__description-text'>
            { info }
          </p>
          <p className='discipline-detail-card__description-text'>
            { details }
          </p>

          <div className='discipline-detail-card__additional-info-wrapper'>
            <div className='discipline-detail-card__additional-info'>
              <p className='discipline-detail-card__description-subtitle'>
                Семестр изучения
              </p>
              <p className='discipline-detail-card__description-text_important'>
                { semester } семестр
              </p>
            </div>
            <div className='discipline-detail-card__additional-info'>
              <p className='discipline-detail-card__description-subtitle'>
                Объем дисциплины
              </p>
              <p className='discipline-detail-card__description-text_important'>
                { hours } часа
              </p>
            </div>
          </div>

          <LinkButton type="subjectContent" onClickHandler={
            () => {
              setIsPopupOpen(true)
              setPopupContent(<>
                <h2 className='discipline-detail-card__title'>
                  { `Содержание тематики дисциплины «${ discipline.discipline }»` }
                </h2>
                { themes.map((i: any, index: any) => <PaperContainerWrapper key={ index } variant="sub-paper">
                  <div className='discipline-detail-card__teacher' style={ { width: '62rem', } }>
                    <h3 className='discipline-detail-card__description-title'>
                      { `${ index + 1 }. ${ i.name }` }
                    </h3>
                    <p className='discipline-detail-card__description-text'>
                      { i.content }
                    </p>
                    <p className='discipline-detail-card__description-text'>
                      { i.results }
                    </p>
                  </div>
                </PaperContainerWrapper>) }
              </>)
            }
          } />
        </div>
      </PaperContainerWrapper>

      <PaperContainerWrapper variant="sub-paper">
        <div className='discipline-detail-card__teacher'>
          <h3 className='discipline-detail-card__description-title'>
            Данные о преподавателе
          </h3>
          { teachers.map((i: any, index: any) => <div key={ index }>
            <div className='discipline-detail-card__teacher-main-info-name'>
              <span className='discipline-detail-card__description-subtitle'>
                Преподаватель
              </span>
              <span className='discipline-detail-card__description-text_important'>
                { `${ i.fio.lastname } ${ i.fio.firstname } ${ i.fio.patronymic }` }
              </span>
            </div>
            <div className='discipline-detail-card__teacher-main-info-name'>
              <span className='discipline-detail-card__description-subtitle'>
                Опыт практической деятельности / иная интересная информация
              </span>
              <span className='discipline-detail-card__description-text_important'>
                { i.experience ? `${ i.experience }` : '--' }
              </span>
            </div>
            <div className='discipline-detail-card__additional-info-wrapper'>
              <div className='discipline-detail-card__additional-info'>
                <p className='discipline-detail-card__description-subtitle'>
                  Ученая степень
                </p>
                <p className='discipline-detail-card__description-text_important'>
                  { i.grade ? i.grade : '--' }
                </p>
              </div>
              <div className='discipline-detail-card__additional-info'>
                <p className='discipline-detail-card__description-subtitle'>
                  Ученое звание
                </p>
                <p className='discipline-detail-card__description-text_important'>
                  { i.degree ? i.degree : '--' }
                </p>
              </div>
              <div className='discipline-detail-card__additional-info'>
                <p className='discipline-detail-card__description-subtitle'>
                  Стаж
                </p>
                <p className='discipline-detail-card__description-text_important'>
                  { i.standing }
                </p>
              </div>
            </div>
          </div>) }
        </div>
      </PaperContainerWrapper>
    </div>
  )
}


export default DisciplineDetailCard

import { OnChangeRowValue, } from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableRow/TableRow'
import styles from './TableSelect.module.less'


interface TableSelect {
  cellKey: string,
  cellValue: string,
  cellSelectOptions: string[],
  onUpdateRowHandler: Function,
  onChangeRowValue: OnChangeRowValue
}


const TableSelect = ({
  cellKey,
  cellValue,
  cellSelectOptions,
  onChangeRowValue,
  onUpdateRowHandler,
}: TableSelect) => {
  return (
    <td>
      <select
        className={ styles.select }
        value={ cellValue }
        onChange={ event => {
          onChangeRowValue(cellKey, event.target.value)
          onUpdateRowHandler()
        } }
      >
        { cellSelectOptions.map(
          option => (
            <option
              value={ option }
              key={ option }
            >
              { option }
            </option>
          )
        ) }
      </select>
    </td>
  )
}


export default TableSelect

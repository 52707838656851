import { gql, } from '@/__generated__/gql'
import { SortOrder, } from '@/__generated__/graphql'
import { useQuery, } from '@apollo/client'
import { useMemo, } from 'react'


const GET_NOTIFICATION_DRAFTS = gql(`
  query notificationDraft($input: NotificationDraftInput) {
    notificationDraft(input: $input)
  }
`)


const useGetNotificationDrafts = ({
  type,
  offset,
  limit,
  localFilter,
}: any) => {
  const { data, loading, refetch, } = useQuery(GET_NOTIFICATION_DRAFTS, {
    variables: {
      input: {
        filter: {
          type,
          title: localFilter?.title,
          roles: localFilter?.roles,
          is_active: localFilter?.is_active,
          from: localFilter?.from,
          to: localFilter?.to,
        },
        pagination: {
          offset,
          limit,
        },
        sort: [
          {
            column: 'draft_id',
            order: SortOrder.Asc,
          },
        ],
      },
    },
  })

  const notificationDrafts = useMemo(() => {
    if (!loading && data) {
      const items = data.notificationDraft.items
      const pagination = data.notificationDraft.pagination

      return {
        items,
        pagination,
        refetch,
      }
    }
    return {
      items: [],
      pagination: {},
      refetch,
    }
  }, [
    data,
    loading,
    refetch,
  ])


  return { notificationDrafts, }
}


export default useGetNotificationDrafts

import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Название',
    index: 'subject_name',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Направление подготовки/специальность',
    index: 'name_specialty',
    editable: false,
    editType: 'input',
  },
  {
    editable: false,
    editType: 'input',
    title: 'Профиль',
    index: 'name_profile',
  },
  {
    editable: false,
    editType: 'input',
    title: 'Курс',
    index: 'course',
  },
  {
    editable: false,
    editType: 'input',
    title: 'Семестр',
    index: 'semester',
  },
  // {
  //   editable: false,
  //   title: 'Статус РПД',
  //   index: 'status',
  //   style: { minWidth: '11rem', },
  // },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '11rem', },
  },
  // {
  //   editable: false,
  //   title: 'Статус итоговый',
  //   index: 'status_summary',
  //   style: { minWidth: '11rem', },
  // },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Название',
    index: 'subject_name',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Направление подготовки/специальность',
    editable: false,
    editType: 'input',
    index: 'name_specialty',
  },
  {
    title: 'Профиль',
    index: 'name_profile',
    editable: false,
    editType: 'input',
  },
  {
    editable: false,
    editType: 'input',
    title: 'Курс',
    index: 'course',
  },
  {
    editable: false,
    editType: 'input',
    title: 'Семестр',
    index: 'semester',
  },
  {
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    editable: false,
    editType: 'input',
  },
  // {
  //   editable: false,
  //   title: 'Статус РПД',
  //   index: 'status',
  //   style: { minWidth: '11rem', },
  // },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '11rem', },
  },
  // {
  //   editable: false,
  //   title: 'Статус итоговый',
  //   index: 'status_summary',
  //   style: { minWidth: '11rem', },
  // },
]

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/extensions
import '@/pages/EducationalProcessPage/SchedulePage/StudentSchedulePage/ui/Calendar.module.less'
import './Schedule.less'
import styles from './Table.module.less'

import { useEffect, useState, } from 'react'
import {
  useTeacherTimetable,
} from '../../../../../legacy/gql/hooks/useTimetable'

import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { changeWeekDays, } from './Fn'
import { format, } from 'date-fns'

import ru from 'date-fns/locale/ru'

import Calendar from 'react-calendar'
import CalendarSvg from '@/shared/assets/images/Group.svg'
import TableSchedule from './Table'
import addDays from 'date-fns/addDays'
import { useSelector, } from 'react-redux'
import clsx from 'clsx'
import { weekdays, } from '@/shared/constants/weekdays'

import InputTextDeprecated, { useInputText, } from '../../../../../legacy/components/common/InputTextDeprecated/InputTextDeprecated'

import TeacherSearch from '../../../../../legacy/components/common/TeacherSearch/TeacherSearch'

function Schedule() {
  dayjs.locale('ru')
  const [
    , setArr,
  ] = useState([])
  const [
    chooseDay,
    setChooseDay,
  ] = useState(true)
  const [
    calendar,
    setCalendar,
  ] = useState(false)
  const [
    singleDate,
    setSignleDate,
  ] = useState<any>(new Date())
  const [
    rangeDate,
    setRangeDate,
  ] = useState<any>(null)
  const [
    date,
  ] = useState(new Date())
  const [
    startDate,
    setStartDate,
  ] = useState(
    new Date(date.setDate(date.getDate() - date.getDay() + 1))
  )
  const [
    endDate,
    setEndDate,
  ] = useState(addDays(date, 6))
  const dateArr = []
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)

  const userInput = useInputText({ defaultValue: '', })

  const [
    currentField,
    setCurrentField,
  ] = useState<string | null>(null)

  const [
    teacherGuid,
    setUserGuid,
  ] = useState(currentUserId)

  const [
    defaultHint,
    setDefaultHint,
  ] = useState('ФИО преподавателя')

  const teacherTimetableForWeek = useTeacherTimetable({
    dateStart: format(new Date(startDate), 'yyyy-MM-dd'),
    dateEnd: format(new Date(endDate), 'yyyy-MM-dd'),
    teacher_guid: teacherGuid,
  })

  const teacherTimetableForToday = useTeacherTimetable({
    dateStart: format(new Date(singleDate), 'yyyy-MM-dd'),
    dateEnd: format(new Date(singleDate), 'yyyy-MM-dd'),
    teacher_guid: teacherGuid,
  })

  useEffect(() => {
    if (!chooseDay) {
      !startDate &&
        setStartDate(
          new Date(date.setDate(date.getDate() - date.getDay() + 1))
        )
      !endDate && setEndDate(addDays(date, 6))

      setArr(teacherTimetableForWeek?.data?.teacherTimetable)
    } else {
      setArr(teacherTimetableForToday?.data?.teacherTimetable)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    teacherTimetableForToday.data,
    teacherTimetableForWeek.data,
  ])

  function handleSingle() {
    setChooseDay(true)
    setRangeDate(null)
  }

  const switchDate = (move: any) => {
    if (chooseDay) {
      const down = addDays(singleDate, -1)
      const up = addDays(singleDate, 1)

      !move && setSignleDate(down)
      move && setSignleDate(up)
    } else {
      if (!move) {
        setStartDate(addDays(startDate, -7))
        setEndDate(addDays(endDate, -7))
      } else {
        setStartDate(addDays(startDate, 7))
        setEndDate(addDays(endDate, 7))
      }
    }
  }

  if (startDate && endDate) {
    let currentDate = startDate
    while (currentDate <= endDate) {
      dateArr.push(currentDate)
      currentDate = addDays(currentDate, 1)
    }
  }

  useEffect(() => {
    if (rangeDate) {
      setStartDate(rangeDate[0])
      setEndDate(rangeDate[rangeDate.length - 1])
    }
  }, [
    rangeDate,
  ])

  return (
    <div className="Schedule" onClick={ () => setCalendar(false) }>
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Расписание"
      />

      <h1 className="d-h1">Расписание</h1>

      <div className="Row d-row d-space Schedule__filter-wrapper">
        <div className={ styles.inputWrapper }>
          <InputTextDeprecated
            debounceTimeout={ 1000 }
            defaultHint={ defaultHint }
            withPlaceholder
            // @ts-expect-error TS(2345): Argument of type '1' is not assignable to paramete... Remove this comment to see the full error message
            onClick={ () => setCurrentField(1) }
            { ...userInput }
          />
          { userInput?.value && (
            <TeacherSearch
              className={ styles.personsWindow }
              defaultHint={ 'ФИО преподавателя' }
              handler={ (person: any) => {
                setUserGuid(person.id)
                userInput.onChangeValue('')
                setDefaultHint(person.full_name)
              } }
              setCurrentField={ setCurrentField }
              value={ userInput.value }
            />
          ) }

        </div>

        <div className="Schedule__controls-wrapper">
          <div className="Row d-row">
            <span
              onClick={ () => switchDate(false) }
              className="p-10 a-scale d-cursor"
            >
              <div style={ { height: 40, } } className="d-row d-center text-style">
                <div
                  style={ {
                    fontWeight: 500,
                    paddingRight: 10,
                    fontSize: 28,
                    color: 'grey',
                    margin: 0,
                  } }
                >
                  { '<' }
                </div>

                { chooseDay ? 'Предыдущий день' : 'Предыдущая неделя' }
              </div>
            </span>
            <div className="Cal" onClick={ e => e.stopPropagation() }>
              <button
                className="Click a-scale d-cursor"
                onClick={ () => setCalendar(!calendar) }
              >
                { rangeDate
                  ? `${ format(new Date(rangeDate[0]), 'dd MMMM yyyy', {
                    locale: ru,
                  }).slice(0, 3) +
                  format(new Date(rangeDate[0]), 'dd MMMM yyyy', {
                    locale: ru,
                  })[3].toUpperCase() +
                  format(new Date(rangeDate[0]), 'dd MMMM yyyy', {
                    locale: ru,
                  }).slice(4)
                  } - ${ format(new Date(rangeDate[1]), 'dd MMMM yyyy', {
                    locale: ru,
                  }).slice(0, 3) +
                  format(new Date(rangeDate[1]), 'dd MMMM yyyy', {
                    locale: ru,
                  })[3].toUpperCase() +
                  format(new Date(rangeDate[1]), 'dd MMMM yyyy', {
                    locale: ru,
                  }).slice(4)
                  }`
                  : format(singleDate, 'dd MMMM yyyy', { locale: ru, }).slice(
                    0,
                    3
                  ) +
                  format(singleDate, 'dd MMMM yyyy', {
                    locale: ru,
                  })[3].toUpperCase() +
                  format(singleDate, 'dd MMMM yyyy', { locale: ru, }).slice(4) }
                <img className="p-10" src={ CalendarSvg } alt={ '' } />
              </button>
              { calendar && (
                <Calendar
                  locale={ 'Ru' }
                  formatMonthYear={ (locale: any, date: any) => format(date, 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) +
                    format(date, 'dd MMMM yyyy', {
                      locale: ru,
                    })[3].toUpperCase() +
                    format(date, 'dd MMMM yyyy', { locale: ru, }).slice(4)
                  }
                  formatShortWeekday={ (locale: any, date: any) => changeWeekDays(date)
                  }
                  className={
                    calendar ? 'ScheduleCalendar ' : 'ScheduleCalendar off'
                  }
                  value={ chooseDay ? singleDate : rangeDate }
                  onChange={ chooseDay ? setSignleDate : setRangeDate }
                  selectRange={ !chooseDay }
                />
              ) }
            </div>
            <span
              onClick={ () => switchDate(true) }
              className="p-10 a-scale d-cursor"
            >
              <div
                style={ {
                  height: 40,
                  alignItems: 'center',
                } }
                className="d-row d-center text-style"
              >
                { chooseDay ? 'Следующий день' : 'Следующая неделя' }

                <div
                  style={ {
                    fontWeight: 500,
                    paddingLeft: 10,
                    fontSize: 28,
                    color: 'grey',
                    margin: 0,
                  } }
                >
                  { '>' }
                </div>
              </div>
            </span>
          </div>

          <div className="d-row">
            <span
              onClick={ () => handleSingle() }
              className={ `text-style-choise p-10 Choosed d-cursor ${ chooseDay && 'ActiveC'
              }` }
            >
              По дням
            </span>

            <span
              onClick={ () => setChooseDay(false) }
              className={ `text-style-choise p-10 Choosed d-cursor ${ !chooseDay && 'ActiveC'
              }` }
            >
              По неделям
            </span>
          </div>
        </div>
      </div>

      <br />
      { chooseDay ? (
        <div className="ShortSchedule">
          <TableSchedule
            status={ false }
            props={
              teacherTimetableForToday.loading
                ? null
                : teacherTimetableForToday?.data?.teacherTimetable
            }
            date={ {
              day: format(singleDate, 'd'),
              month: format(new Date(singleDate), 'MMMM', { locale: ru, }),
              weekday: format(singleDate, 'EEEE'),
            } }
          />
        </div>
      ) : (
        <div className="ShortSchedule d-scrolloff ">
          { dateArr.map(date => (
            <div className="Table d-scrolloff" key={ date.toString() }>
              <h2 className={ clsx('DateS p-20', styles.dateDescription) }>
                <span>
                  { date &&
                    `${ weekdays?.find(
                      weekDay => weekDay?.en === format(date, 'EEEE')
                    )?.ru
                    }, ${ format(date, 'd') } ${ format(new Date(date), 'MMMM', {
                      locale: ru,
                    }) }` }
                </span>

                <span>
                  { teacherTimetableForWeek?.data?.teacherTimetable?.[0]?.chislitel !== null &&
                    teacherTimetableForWeek?.data?.teacherTimetable?.[0]?.chislitel !==
                    undefined &&
                    (teacherTimetableForWeek?.data?.teacherTimetable?.[0]?.chislitel
                      ? 'Неделя: Числитель'
                      : 'Неделя: Знаменатель') }
                </span>
              </h2>

              <table>
                <tr className="info">
                  <td className="date Bold" style={ { fontWeight: '700', } }>Пара</td>
                  <td className="time Bold" style={ { fontWeight: '700', } }>Время</td>
                  <td className="lesson Bold" style={ { fontWeight: '700', } }>Предмет</td>
                  <td className="direction Bold" style={ { fontWeight: '700', } }>Направление подготовки</td>
                  <td className="prof Bold" style={ { fontWeight: '700', } }>Профиль</td>
                  <td className="course Bold" style={ { fontWeight: '700', } }>Курс</td>
                  <td className="eform Bold" style={ { fontWeight: '700', } }>Форма обучения</td>
                  <td className="audince Bold" style={ { fontWeight: '700', } }>Аудитория</td>
                </tr>
                { teacherTimetableForWeek?.data ? (
                  teacherTimetableForWeek.data.teacherTimetable.map((i: any) => {
                    // eslint-disable-next-line eqeqeq
                    return (
                      String(i.day) == format(date, 'd') && (
                        <tr key={ i.id } className="row">
                          <td className="date d-cursor" style={ { height: '90px', } }>{ i?.couple }</td>
                          <td className="time d-cursor" style={ { height: '90px', } }>
                            { i.start ? i.start : 'Не указано' }-{ i.end ? i.end : 'Не указано' }
                          </td>
                          <td className="lesson d-cursor" style={ { height: '90px', } }>{ i?.discipline }</td>
                          <td className="direction">
                            { i.teacher_schedule.map((scheduleItem: any) => (
                              <div style={ { height: '90px',
                                marginTop: '7px',
                                color: '#2e2e3d', } }>
                                { scheduleItem.spec_name ? scheduleItem.spec_name : <td> Нет </td> }
                              </div>
                            )) }
                          </td>
                          <td className="prof">
                            { i.teacher_schedule.map((scheduleItem: any) => (
                              <div style={ { height: '90px',
                                marginTop: '7px',
                                color: '#2e2e3d', } }>
                                { scheduleItem.prof_name ? scheduleItem.prof_name : <td> Нет </td> }
                              </div>
                            )) }
                          </td>
                          <td className="course">
                            { i.teacher_schedule.map((scheduleItem: any) => (
                              <div style={ { height: '90px',
                                marginTop: '7px',
                                color: '#2e2e3d', } }>
                                { scheduleItem.course ? scheduleItem.course : <td> Нет </td> }
                              </div>
                            )) }
                          </td>
                          <td className="eform">
                            { i.teacher_schedule.map((scheduleItem: any) => (
                              <div style={ { height: '90px',
                                marginTop: '7px',
                                color: '#2e2e3d', } }>
                                { scheduleItem.eform ? scheduleItem.eform : <td> Нет </td> }
                              </div>
                            )) }
                          </td>
                          <td className="audince" style={ { height: '90px', } }>{ i?.room }</td>
                        </tr>
                      )
                    )
                  })
                ) : (
                  <div>....loading....</div>
                ) }
              </table>
            </div>
          )) }
        </div>
      ) }

      <br />
    </div>
  )
}

export default Schedule

/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  query EsEducationPlan($esEducationPlanId: String) {\n    esEducationPlan(id: $esEducationPlanId) {\n      disciplineList {\n        ep_id\n        id\n        quantity\n        module\n        semester\n        title\n      }\n    }\n  }\n": types.EsEducationPlanDocument,
    "\n  query EducationPlanList {\n    EducationPlanList {\n      ep_id\n      nameprof\n      namespec\n      admission_end\n      admission_start\n  }\n}\n": types.EducationPlanListDocument,
    "\nsubscription OnProjectAdded {\n  projectChanged\n}\n": types.OnProjectAddedDocument,
    "\n  query GetNameProfs($input: NameProfsListInput) {\n    nameprofsList(input: $input)\n}\n": types.GetNameProfsDocument,
    "\n query GetSpecsName($input: NameSpecsListInput) {\n  namespecsList(input: $input)\n}\n": types.GetSpecsNameDocument,
    "\n  mutation Commit{\n    ecCommitEducationOfIndividials\n  }\n": types.CommitDocument,
    "\n  query FrontCheckVersion {\n    build: utilConfig(domain:\"mobile\",key:\"version\")\n    ios: utilConfig(domain:\"mobile\",key:\"version.ios\")\n    android: utilConfig(domain:\"mobile\",key:\"version.android\")\n  }\n": types.FrontCheckVersionDocument,
    "\n  query StudentDataLookup($input: StudentDataLookupInput!) {\n    studentDataLookup(input: $input)\n  }\n": types.StudentDataLookupDocument,
    "\n  mutation SMSRequest(\n    $phone: String!\n    $message: String\n    $expire: Int\n    $fake: Boolean\n    $source: String\n  ) {\n    smsCodeRequest(\n      phone: $phone\n      message: $message\n      expire: $expire\n      fake: $fake\n      source: $source\n    )\n  }\n": types.SmsRequestDocument,
    "\n  query CheckCode($code: String!) {\n    smsCodeCheck(code: $code)\n  }\n": types.CheckCodeDocument,
    "\n  mutation DeleteUpload($upload_ids: [Int]!) {\n    deleteUpload(upload_ids: $upload_ids)\n  }\n": types.DeleteUploadDocument,
    "\n  query ecInstList {\n    catalogs(names: [\"ecInstList\"])\n  }\n": types.EcInstListDocument,
    "\n    query DepList($input: DepartmentListInput) {\n        departmentList(input: $input)\n    }\n": types.DepListDocument,
    "\n  query GetSplash {\n    notificationSplash\n  }\n": types.GetSplashDocument,
    "\n  query ecExportTable_BaseStats{\n    ecExportTable_BaseStats\n  }\n": types.EcExportTable_BaseStatsDocument,
    "\n  query ecExportTable_1_1{\n    ecExportTable_1_1\n  }\n": types.EcExportTable_1_1Document,
    "\n  query ecExportTable_3_5{\n    ecExportTable_3_5\n  }\n": types.EcExportTable_3_5Document,
    "\n  query ecExportTable_EffectiveStats_all{\n    ecExportTable_EffectiveStats(name:\"allStats\",mode:ALL)\n  }\n": types.EcExportTable_EffectiveStats_AllDocument,
    "\n  query ecExportTable_EffectiveStats_mounthly{\n    ecExportTable_EffectiveStats(name:\"allStats\",mode:MONTHLY)\n  }\n": types.EcExportTable_EffectiveStats_MounthlyDocument,
    "\n  query ecExportTable_EffectiveStats{\n    ecExportTable_EffectiveStats(name:\"allStats\",mode:ONETIME)\n  }\n": types.EcExportTable_EffectiveStatsDocument,
    "\n  subscription ConfigChanged {\n    configChanged\n  }\n": types.ConfigChangedDocument,
    "\n  query Generate{\n    ecCreateData22_18(mode: ALL)\n  }\n": types.GenerateDocument,
    "\n  mutation EcCreateData(\n    $dataType: String!,\n    $data: JSON!,\n    $user_ids: [String],\n    $extra: JSON\n  ) {\n    ecCreateData(\n      dataType: $dataType,\n      data: $data,\n      user_ids: $user_ids,\n      extra: $extra\n    )\n  }\n": types.EcCreateDataDocument,
    "\n  mutation EcDeleteData($rec_id:Int!,$dataType:String!){\n    ecDeleteData(rec_id:$rec_id,dataType:$dataType)\n  }\n": types.EcDeleteDataDocument,
    "\n  query ecTable_2_8_Categories {\n    catalogs(names: [\"ecTable_2_8_Categories\"])\n  }\n": types.EcTable_2_8_CategoriesDocument,
    "\n  query ecTable2_8_Roles {\n    catalogs(names: [\"ecTable2_8_Roles\"])\n  }\n": types.EcTable2_8_RolesDocument,
    "\n  query TeacherPositions {\n    teacherPositions(search: \"\")\n  }\n": types.TeacherPositionsDocument,
    "\n  query GetModeratorPosFilter {\n    catalogs(names: [\"ecModeratorPositionsFilter\"])\n  }\n": types.GetModeratorPosFilterDocument,
    "\n  subscription OnContractChange {\n    contractsChanged\n  }\n": types.OnContractChangeDocument,
    "\n  query EcListData($params:JSON){\n    ecListData(params:$params)\n  }\n": types.EcListDataDocument,
    "\n  mutation EcUpdateDataType(\n    $rec_id: Int!,\n    $dataType: String\n  ) {\n  ecUpdateDataType(\n    rec_id: $rec_id,\n    dataType: $dataType\n  )\n}\n": types.EcUpdateDataTypeDocument,
    "\n  query GetScoresBySelector(\n    $user_id: String,\n    $selector: DataTypeSelector,\n    $extra: JSON\n  ){\n  ecGetScoresTableBySelector(\n    user_id: $user_id,\n    dataTypeSelector: $selector,\n    extra: $extra\n  )\n}\n": types.GetScoresBySelectorDocument,
    "\n  query CheckCompletion($user_id: String) {\n    ecCheckCompletion(user_id: $user_id)\n  }\n": types.CheckCompletionDocument,
    "\n  query GetBaseLine ($user_id: String, $dataType: [String]!) {\n    ecGetBaselineCompletion(user_id: $user_id, dataType: $dataType)\n  }\n": types.GetBaseLineDocument,
    "\n  query GetComplitionsStats($user_id: String, $dataType: String!) {\n    ecGetCompletionStats(user_id:$user_id, dataType:$dataType)\n  }\n": types.GetComplitionsStatsDocument,
    "\n  query GetGraphDataTotals($id:String){\n    ecGraphDataTotals(user_id:$id)\n  }\n": types.GetGraphDataTotalsDocument,
    "\n  query UtilConfig($domain: String, $key: String) {\n    utilConfig(domain: $domain, key: $key)\n  }\n": types.UtilConfigDocument,
    "\n  query Teachers($search:String, $pagination:JSON) {\n    teacherList(search: $search, pagination: $pagination)\n  }\n": types.TeachersDocument,
    "\n  query EcDataTableByUserAndStatus($input:ecDataTableByUserAndStatusInput!) {\n    ecDataTableByUserAndStatus(input:$input)\n  }\n": types.EcDataTableByUserAndStatusDocument,
    "\n  mutation Import_22_16_17($upload_id: Int!, $async: Boolean) {\n    ecImportTable_22_16x17(upload_id: $upload_id, async: $async)\n  }\n": types.Import_22_16_17Document,
    "\n  mutation EcUpdateData(\n    $rec_id:Int!,\n    $new_data:JSON,\n    $user_ids:[String],\n    $new_extra:JSON,\n    $new_appeal:JSON,\n  ) {\n    ecUpdateData(\n      rec_id:$rec_id,\n      data:$new_data,\n      user_ids:$user_ids,\n      extra:$new_extra,\n      appeal:$new_appeal,\n    )\n  }\n": types.EcUpdateDataDocument,
    "\n  mutation UtilUpdateConfig($input: UpdateConfigInput!) {\n    utilUpdateConfig(input: $input)\n  }\n": types.UtilUpdateConfigDocument,
    "\nquery GetFormData($input: ReferenceFormFieldDataInput!) {\n  referenceGetFormFieldData(input: $input)\n}\n": types.GetFormDataDocument,
    "\nquery GetApplications($input: MFCStatementsInput!){\n  mfcStatements(input: $input)\n}\n": types.GetApplicationsDocument,
    "\n  query GetFormTypes($statementType: MFCStatementType){\n    mfcGetFormTypes(statementType: $statementType)\n  }\n": types.GetFormTypesDocument,
    "\nquery GetForm($id:UUID!){\n  mfcGetForm(id: $id)\n}\n": types.GetFormDocument,
    "\nmutation SendStatement($payload: JSON!, $type: MFCStatementType!) {\n  mfcSendStatement(payload:$payload, type: $type)\n}\n": types.SendStatementDocument,
    "\n  query ReferenceStatus {\n    references(mode: \"stats\")\n  }\n": types.ReferenceStatusDocument,
    "\n  query ReferenceList($status: [String]) {\n    references(status: $status)\n  }\n": types.ReferenceListDocument,
    "\n  mutation CreateReference($payload: JSON) {\n    createReference(payload: $payload)\n  }\n": types.CreateReferenceDocument,
    "\nmutation CommitReferences($doc_id:Int ) {\n  referenceCommit(doc_id:$doc_id)\n}\n": types.CommitReferencesDocument,
    "\n  subscription ReferenceChanged {\n    referenceChanged\n  }\n": types.ReferenceChangedDocument,
    "\n  mutation NotificationDraftAdd($body: JSON!) {\n    notificationDraftAdd(body: $body)\n  }\n": types.NotificationDraftAddDocument,
    "\n  mutation NotificationDraftChangeActive($draft_id: [Int!]!, $is_active: Boolean) {\n    notificationDraftChangeActive(draft_id: $draft_id, is_active: $is_active)\n  }\n": types.NotificationDraftChangeActiveDocument,
    "\n  mutation NotificationDraftDelete($draft_id: Int!) {\n    notificationDraftDelete(draft_id: $draft_id)\n  }\n": types.NotificationDraftDeleteDocument,
    "\n  query notificationDraft($input: NotificationDraftInput) {\n    notificationDraft(input: $input)\n  }\n": types.NotificationDraftDocument,
    "\n  query NotificationDraftFilters {\n    notificationDraftFilters\n  }\n": types.NotificationDraftFiltersDocument,
    "\n  subscription NotificationDraftChanged {\n    notificationDraftChanged\n  }\n": types.NotificationDraftChangedDocument,
    "\n  mutation NotificationDraftPurgeMessages($draft_id: [Int!]!) {\n    notificationDraftPurgeMessages(draft_id: $draft_id)\n  }\n": types.NotificationDraftPurgeMessagesDocument,
    "\n  mutation NotificationDraftReset($draft_id: [Int!]!) {\n    notificationDraftResetProcessed(draft_id: $draft_id)\n  }\n": types.NotificationDraftResetDocument,
    "\n  subscription OnAchievementChanged {\n    achievementChanged\n  }\n": types.OnAchievementChangedDocument,
    "\n  subscription AchievementDirectionsChanged {\n    achievementDirectionsChanged\n  }\n": types.AchievementDirectionsChangedDocument,
    "\n  query Directions($is_admin: Boolean) {\n    achievementDirections(is_admin: $is_admin)\n  }\n": types.DirectionsDocument,
    "\n  query Statuses {\n    achievementStatuses(all: true)\n  }\n": types.StatusesDocument,
    "\n  subscription AchievementTypeChanged {\n    achievementTypeChanged\n  }\n": types.AchievementTypeChangedDocument,
    "\n  query AchievementTypes2($catid: Int, $key:Int, $is_admin: Boolean) {\n    achievementTypes(catid: $catid, key: $key, is_admin: $is_admin)\n  }\n": types.AchievementTypes2Document,
    "\n  mutation CreateAchievement($input: CreateAchievementInput!) {\n    createAchievement(input: $input)\n  }\n": types.CreateAchievementDocument,
    "\n  query StudentsAchievementsRating($input: AchievementsRatingInput!){\n    studentsAchievementsRating(input: $input)\n  }\n": types.StudentsAchievementsRatingDocument,
    "\n  query AverageGrade ($input: AverageGradeInput!) {\n    averageGrade (input: $input)\n  }\n": types.AverageGradeDocument,
    "\n  mutation SetUpdateConfig($name: String!, $value: String!){\n    updatePersonnelConfig(name: $name, value: $value)\n  }\n": types.SetUpdateConfigDocument,
    "\n  mutation changeTypeDirection($input: ChangeTypeDirectionInput) {\n    changeTypeDirection(input: $input)\n  }\n": types.ChangeTypeDirectionDocument,
    "\n  mutation EditDirection($input: EditAchievementDirectionInput) {\n    editAchievementDirection(input: $input)\n  }\n": types.EditDirectionDocument,
    "\n  mutation editAchievementType($input: EditAchievementTypeInput) {\n    editAchievementType(input: $input)\n  }\n": types.EditAchievementTypeDocument,
    "\n  mutation DeleteAchievement($achievement_id: Int) {\n    deleteAchievement(achievement_id: $achievement_id)\n  }\n": types.DeleteAchievementDocument,
    "\n  query GetAchievementRating($count: Int) {\n    achievementRating(count: $count)\n  }\n": types.GetAchievementRatingDocument,
    "\n  query GetDirectionsWithAchievements($id: UUID!, $available: Boolean, $status: AchievementStatus) {\n    getDirectionsWithAchievements(id: $id, available: $available, status: $status)\n  }\n": types.GetDirectionsWithAchievementsDocument,
    "\n  query personnelConfig {\n    personnelConfig(name: [\n      \"is_student_rating_available_all\",\n      \"is_student_rating_include_myself\"\n    ])\n  }\n": types.PersonnelConfigDocument,
    "\n  query StudentAchievements($input:StudentAchievementStatsInput!) {\n    studentAchievementStats(input:$input)\n  }\n": types.StudentAchievementsDocument,
    "\n  query GetPersonalRatingById($id: UUID) {\n    achievementRatingById(id: $id)\n  }\n": types.GetPersonalRatingByIdDocument,
    "\n  subscription PersonnelConfigChanged {\n    personnelConfigChanged\n  }\n": types.PersonnelConfigChangedDocument,
    "\n  query RequiredStudentPapers ($input: RequiredStudentPapersInput!) {\n    requiredStudentPapers (input: $input)\n  }\n": types.RequiredStudentPapersDocument,
    "\n  query Counters($direction_key: Int!, $id: UUID) {\n    achievementDirectionCounters(direction_key: $direction_key, id: $id)\n  }\n": types.CountersDocument,
    "\n  mutation UpdateAchievement($input: UpdateAchievementInput!) {\n    updateAchievement(input: $input)\n  }\n": types.UpdateAchievementDocument,
    "\nquery paProjectSeminarGroupDetail($input: ProjectSeminarGroupDetailInput!) {\n    paProjectSeminarGroupDetail(input: $input)\n  }\n": types.PaProjectSeminarGroupDetailDocument,
    "\n  query paTeacherTimetableLookup($input: TeacherTimetableLookupInput!) {\n    paTeacherTimetableLookup(input: $input)\n  }\n": types.PaTeacherTimetableLookupDocument,
    "\n  mutation PaControl($params: JSON) {\n    paControl(params: $params)\n  }\n": types.PaControlDocument,
    "\n  query AchievementTypes($catid: Int) {\n    achievementTypes(catid: $catid)\n  }\n": types.AchievementTypesDocument,
    "\n  query GetAchievements($input: AchievementsInput!) {\n    achievements(input: $input)\n  }\n": types.GetAchievementsDocument,
    "\n  query GetPersonalRating {\n    achievementPersonalRating\n  }\n": types.GetPersonalRatingDocument,
    "\nquery GetUploads {\n  uploads(category: \"avatars\")\n}\n": types.GetUploadsDocument,
    "\n  mutation SetDefaultAvatar($upload_id: Int!) {\n    defaultAvatar(upload_id: $upload_id)\n  }\n": types.SetDefaultAvatarDocument,
    "\nmutation DeleteUpload($upload_ids: [Int]!) {\n  deleteUpload(upload_ids: $upload_ids)\n}\n": types.DeleteUploadDocument,
    "\nsubscription UploadChanged{\n  uploadsChanged\n}\n": types.UploadChangedDocument,
    "\nsubscription DefaultChanged{\n  avatarChanged\n}\n": types.DefaultChangedDocument,
    "\n  query GetCatalogs($names: [String]) {\n    catalogs(names: $names)\n  }\n": types.GetCatalogsDocument,
    "\n  query GetEducationContracts {\n    finEducationContracts {\n      contract\n      createDate\n      startDate\n      endDate\n      customer\n      debt\n      penalties\n      hasPenalties\n      educationType\n      actual\n      refreshDate\n      paymentSchedule{\n        nearestPayDate\n        paySum\n        TheSumFaktPayment\n        graph{\n          PayDo\n          TheAmount\n        }\n      }\n      isLegal\n    }\n  }\n": types.GetEducationContractsDocument,
    "\n  query GetEducationPaymentsHistory($contract: String) {\n    educationPaymentsHistory(contract: $contract)\n  }\n": types.GetEducationPaymentsHistoryDocument,
    "\n  query QuitancesBanks($contract: String) {\n    quittancesBanks(contract: $contract)\n  }\n": types.QuitancesBanksDocument,
    "\n  query GetDormitoryContracts {\n    dormitoryContracts\n  }\n": types.GetDormitoryContractsDocument,
    "\n  query GetDormitoryPayments($number_contract: String) {\n    dormitoryPayments(number_contract: $number_contract)\n  }\n": types.GetDormitoryPaymentsDocument,
    "\n  query GetQR($contract: String, $type: String) {\n    quittances(contract: $contract, type: $type)\n  }\n": types.GetQrDocument,
    "\n  query GetAccrudeScholarShip($mode: String) {\n    accruedScholarship(mode: $mode)\n  }\n": types.GetAccrudeScholarShipDocument,
    "\n  query GetPlannedCharges {\n    plannedCharges\n  }\n": types.GetPlannedChargesDocument,
    "\nquery Periods($input:AccruedScholarshipInput) {\n  finAccruedScholarship(input: $input) {\n    period\n  }\n}": types.PeriodsDocument,
    "\nquery Scolarship($input:AccruedScholarshipInput) {\n  finAccruedScholarship(input: $input) {\n    period\n    rec_id\n    updated\n    blocks {\n\n      block_accruals {\n        sum\n        date\n        name\n      }\n\n      block_holdings{\n        sum\n        date\n        name\n      }\n\n      block_payout {\n        sum\n        date\n        name\n      }\n    }\n  }\n}": types.ScolarshipDocument,
    "\nquery GetDisciplines($group: String!) {\n  disciplines(group: $group)\n      }\n": types.GetDisciplinesDocument,
    "\nquery GetStudentJournal($group: String!, $discipline: String!, $type: String!, $semester: Int) {\n  getStudentJournal(group: $group, discipline: $discipline, type: $type, semester: $semester)\n}\n": types.GetStudentJournalDocument,
    "\nquery TeacherDisciplineGroups {\n  teacherDisciplineGroups(testID:\"010c0df0-3dfc-11e6-80bd-7824af85d229\")\n}\n": types.TeacherDisciplineGroupsDocument,
    "\n  query getME {\n    me\n  }\n": types.GetMeDocument,
    "\n  query UserInfo($user_id: String!) {\n    userInfo(user_id: $user_id)\n  }\n": types.UserInfoDocument,
    "\n  query SliderImages {\n    sliderImages\n  }\n": types.SliderImagesDocument,
    "\n  query EducationPlan($i:EducationPlanInput) {\n    educationPlan(input:$i){\n      user_id\n      recordbook\n      plans {\n        ep_id\n        ep_number\n        individual_plan\n        admission_start\n        admission_end\n        date\n    }\n   }\n  }\n": types.EducationPlanDocument,
    "\nquery EPDisciplines($i:EducationPlanDisciplinesInput) {\n  educationPlanDisciplines(input:$i)\n}\n": types.EpDisciplinesDocument,
    "\nquery States($type: DisciplineType) {\n  esDisciplinesState(type: $type)\n}\n": types.StatesDocument,
    "\nquery TrackState {\n  esTrackState\n}\n": types.TrackStateDocument,
    "\nquery DisciplinesBySemester($i:DisciplinesBySemestersInput!) {\n  bySemester: esDisciplinesBySemesters(input:$i)\n  common: esDisciplinesBySemesters(input:{\n    filter:{\n      semester:-1\n    }\n  })\n }\n": types.DisciplinesBySemesterDocument,
    "\n  query MoodleCourses($i:MoodleCoursesInput) {\n    moodleCourses(input:$i)\n  }\n": types.MoodleCoursesDocument,
    "\n  query GetOne($name: String!, $semester: [Int] ) {\n    esMoodleCoursesByDiscipline(discipline_name:$name, semester:$semester)\n  }\n": types.GetOneDocument,
    "\n  query ListFacultatives($i:FacultativeInput!){\n    esFacultative(input:$i)\n  }\n": types.ListFacultativesDocument,
    "\n  query Signed($i:SignedStudentsInput!){\n    esSignedStudents(input:$i)\n  }\n": types.SignedDocument,
    "\n  query GetDisciplineProcedure($i:DisciplineType!){\n    esDisciplineProcedure(type: $i){\n      procedure_info\n      procedure_data\n    }\n  }\n": types.GetDisciplineProcedureDocument,
    "\n  query GetStats($i:DisciplineStatsInput) {\n    esDisciplineStats(input: $i)\n  }\n": types.GetStatsDocument,
    "\nquery GetTracks($i:TrackListInput!) {\n  esTrackList(input: $i){\n    items {\n    is_active\n    is_visible\n    max_participants\n    track_id\n    is_signed\n    name\n    sign_count\n    file\n    data\n    disciplines{\n      discipline_id\n      name\n    }\n    }\n  }\n}\n": types.GetTracksDocument,
    "\n  query GetTrackDisciplines($i:TrackDisciplinesInput){\n    esTrackDisciplines(input:$i){\n      name\n      semester\n      discipline\n      info\n      discipline_id\n      semester\n      hours\n      teachers\n      details\n      themes\n      discipline\n    }\n  }\n": types.GetTrackDisciplinesDocument,
    "\n  query GetTrackStudents($i: TrackStudentsInput!) {\n    esTrackStudents(input: $i){\n      full_name\n      disciplines\n      student_info\n      student_id\n    }\n  }\n": types.GetTrackStudentsDocument,
    "\n  query GetSignStatus($id: UUID!){\n    esGetSignStatus(id: $id)\n  }\n": types.GetSignStatusDocument,
    "\n  query GetProjectThemes($i: ProjectThemesInput){\n    paProjectThemes(input: $i)\n  }\n": types.GetProjectThemesDocument,
    "\n  mutation CreateFacultative ($i:CreateFacultativeInput!){\n    esCreateFacultative(input:$i){\n      facultative_id\n      data\n      is_active\n      is_visible\n    }\n  }\n": types.CreateFacultativeDocument,
    "\n  mutation CreateTrack ($i:CreateTrackInput!){\n    esTrackCreate(input:$i)\n  }\n": types.CreateTrackDocument,
    "\n  mutation UpdateTrack ($i:TrackUpdateInput!){\n    esTrackUpdate(input:$i)\n  }\n": types.UpdateTrackDocument,
    "\n  mutation SignUpTrack ($i:SignUpTrackInput!){\n    esSignUpTrack(input: $i)\n  }\n": types.SignUpTrackDocument,
    "\n  mutation SignOutTrack ($i:SignOutTrackInput!){\n    esSignOutTrack(input: $i)\n  }\n": types.SignOutTrackDocument,
    "\nmutation SignOutOneTrack ($i: SignOutTrackStudentInput!){\n  esSignOutTrackStudent(input: $i)\n}\n": types.SignOutOneTrackDocument,
    "\nmutation UpdateTrackDiscipline($i:TrackDisciplineUpdateInput!){\n  esTrackUpdateDiscipline(input:$i)\n}\n": types.UpdateTrackDisciplineDocument,
    "\nmutation SignUp($i:SignUpFacultativeInput!){\n  esSignUpFacultative(input:$i)\n}\n": types.SignUpDocument,
    "\nmutation SignOut($i:SignOutFacultativeInput!){\n  esSignOutFacultative(input:$i)\n}\n": types.SignOutDocument,
    "\n mutation SignUpProjectTheme($i:ProjectThemeSignUpInput!){\n  paProjectThemeSignUp(input:$i)\n }\n": types.SignUpProjectThemeDocument,
    "\nmutation SignOutProjectTheme($i: ProjectThemeSignOutInput!){\n  paProjectThemeSignOut(input:$i)\n}\n": types.SignOutProjectThemeDocument,
    "\nmutation DeleteFacultative($i: DeleteFacultativeInput!) {\n  esDeleteFacultative(input:$i){\n    facultative_id\n  }\n}\n": types.DeleteFacultativeDocument,
    "\nmutation DeleteTrack($track_id:[Int!]!) {\n  esTrackDelete(track_id: $track_id)\n}\n": types.DeleteTrackDocument,
    "\nmutation CreateProjectTheme($i:ProjectThemeCreateInput!){\n  paProjectThemeCreate(input: $i)\n}\n": types.CreateProjectThemeDocument,
    "\nmutation esDisciplineActivate($is_active: Boolean!, $type: DisciplineType) {\n  esDisciplineActivate(is_active: $is_active, type: $type)\n}\n": types.EsDisciplineActivateDocument,
    "\nmutation ChangeActiveTrack($is_active: Boolean!){\n  esTrackActivate(is_active: $is_active)\n}\n": types.ChangeActiveTrackDocument,
    "\nmutation ChangeActiveTrackOne($is_active: Boolean!, $track_id: Int){\n  esTrackActivate(is_active: $is_active, track_id: $track_id)\n}\n": types.ChangeActiveTrackOneDocument,
    "\nmutation UpdateTrackVisibility($is_visible: Boolean!, $track_id: Int!) {\n  esTrackUpdateVisibility(is_visible: $is_visible, track_id: $track_id)\n}\n": types.UpdateTrackVisibilityDocument,
    "\nmutation esDisciplineActivateOne($is_active: Boolean!, $facultative_id: [Int!], $type:DisciplineType) {\n  esDisciplineActivate(is_active: $is_active, facultative_id: $facultative_id, type: $type)\n}\n": types.EsDisciplineActivateOneDocument,
    "\nmutation EditProcedure($i:EditDisciplineProcedureInput!){\n  esEditDisciplineProcedure(input:$i){\n    procedure_info\n    procedure_data\n  }\n}\n": types.EditProcedureDocument,
    "\nmutation UpdateStats($i:UpdateStatsInput!){\n  esUpdateDisciplineStats(input: $i)\n}\n": types.UpdateStatsDocument,
    "\nmutation Send($i:[DisciplineType]!) {\n  esESB_SelectedDiscipline(mode: $i)\n}\n": types.SendDocument,
    "\nsubscription OnFacultativeChanged {\n  facultativesChanged\n}\n": types.OnFacultativeChangedDocument,
    "\nsubscription OnTracksChanged {\n  esTracksChanged\n}\n": types.OnTracksChangedDocument,
    "\nsubscription OnProjectThemeChange {\n  projectIntroChanged\n}\n": types.OnProjectThemeChangeDocument,
    "\n  query GetAllProjects($params: JSON, $pagination: JSON) {\n    paGetAll(params: $params, pagination: $pagination)\n  }\n": types.GetAllProjectsDocument,
    "\nquery NameSpecList($search: String) {\n  namespecs(search: $search)\n}\n": types.NameSpecListDocument,
    "\nquery listNameSpecsWithAcceptedStudents($filter: JSON) {\n  listNameSpecsWithAcceptedStudents(filter: $filter)\n}\n": types.ListNameSpecsWithAcceptedStudentsDocument,
    "\nquery ProfsList($search: String) {\n  nameprofs(search: $search)\n}\n": types.ProfsListDocument,
    "\nquery Query($namespec: [String], $search: String) {\n  listNameProfsWithAcceptedStudents(namespec: $namespec, search: $search)\n}\n": types.QueryDocument,
    "\nquery ProfsListBySpec($input: NameProfsListInput) {\n  nameprofsList(input: $input)\n}\n": types.ProfsListBySpecDocument,
    "\nquery GetPersons($search: String, $mode: String, $pagination: JSON) {\n  persons(search: $search, mode: $mode, pagination: $pagination)\n}\n": types.GetPersonsDocument,
    "\nquery GetTasks($params: JSON) {\n  paTasks(params: $params)\n}\n": types.GetTasksDocument,
    "\nquery StagesList($params: JSON){\n  paStages(params: $params)\n}\n": types.StagesListDocument,
    "\nquery GetProjectApplys($params: JSON, $pagination: JSON){\n  paListProjects(params: $params, pagination: $pagination)\n}\n": types.GetProjectApplysDocument,
    "\n  mutation ApplyProject($params: JSON) {\n    paApply(params: $params)\n  }\n": types.ApplyProjectDocument,
    "\nmutation CreateProject($params: JSON) {\n  paControl(params: $params)\n}\n": types.CreateProjectDocument,
    "\n  mutation ProjectActivityCreate($params: JSON) {\n    paInitiate(params: $params)\n  }\n": types.ProjectActivityCreateDocument,
    "\nmutation Modify($params: JSON) {\n  paModify(params: $params)\n}\n": types.ModifyDocument,
    "\nmutation AddStage($params: JSON) {\n  paChangeStage(params: $params)\n}\n": types.AddStageDocument,
    "\nmutation DeleteStage($req_id:Int!,$stage_num:Int!,$event:String!) {\n  paDeleteStage(req_id: $req_id, stage_num: $stage_num, event: $event)\n}\n": types.DeleteStageDocument,
    "\nmutation AddTask($params: JSON) {\n  paAddTask(params: $params)\n}\n": types.AddTaskDocument,
    "\nmutation ChangeTask($params: JSON) {\n  paChangeTask(params: $params)\n}\n": types.ChangeTaskDocument,
    "\nmutation DeleteApply($req_id: [Int]) {\n  paDeleteInitiative(req_id: $req_id)\n}\n": types.DeleteApplyDocument,
    "\nmutation DeleteProject($params: JSON) {\n  paDeleteProject(params: $params)\n}\n": types.DeleteProjectDocument,
    "\nmutation AddComment($params: JSON) {\n  paAddComment(params: $params)\n}\n": types.AddCommentDocument,
    "\nquery GetComments($params: JSON){\n  paComments(params: $params)\n}\n": types.GetCommentsDocument,
    "\nquery GetProject($req_id: Int){\n  paGetProject(req_id: $req_id)\n}\n": types.GetProjectDocument,
    "\nquery GetTask($task_id: Int){\n  paGetTask(task_id: $task_id)\n}\n": types.GetTaskDocument,
    "\nquery paGetConfig($configName:String,$defaultConfig:JSON){\n  paGetConfig(configName:$configName,defaultConfig:$defaultConfig)\n}\n": types.PaGetConfigDocument,
    "\nmutation PaSetConfig($configName:String,$config:JSON){\n  paSetConfig(configName:$configName,config:$config)\n}\n": types.PaSetConfigDocument,
    "\n  query GetRecordbook {\n    recordbooks {\n      recordbook_number\n      namespec\n      department\n      eform\n      elevel\n      course\n      nameprof\n      recordbook_file\n      programresults {\n        name\n        value\n      }\n    }\n  }\n": types.GetRecordbookDocument,
    "\nquery GEtRecordbookDetails($recordbook_number: String){\n  recordbookBySemester(recordbook_number: $recordbook_number)\n}\n": types.GEtRecordbookDetailsDocument,
    "\n  query GetStudentInfo {\n    studentInfo\n  }\n": types.GetStudentInfoDocument,
    "\n  query GetStudentInfoById($id: String) {\n    studentInfoById(id: $id)\n  }\n": types.GetStudentInfoByIdDocument,
    "\n  query getEmployee {\n    employeesInfo\n  }\n": types.GetEmployeeDocument,
    "\n  query IsLogged {\n    isLogged\n  }\n": types.IsLoggedDocument,
    "\n  mutation SendFeedback($subject: String!, $body: String!, $extra:JSON) {\n    uiFeedback(subject: $subject, body: $body, extra: $extra)\n  }\n": types.SendFeedbackDocument,
    "\n  mutation LogFrontEnd($input:LogFrontendInput!) {\n    utilLogfrontEnd(input:$input)\n  }\n": types.LogFrontEndDocument,
    "\n  query References {\n    getUserGroups\n  }\n": types.ReferencesDocument,
    "\n  query TimetableWithTime($dateNow: String, $limit: Int) {\n    studentTimetableWithTime(dateNow: $dateNow, limit: $limit)\n  }\n": types.TimetableWithTimeDocument,
    "\n  query StudentTimetable(\n    $dateStart: String\n    $dateEnd: String\n    $groups: [String]\n  ) {\n    studentTimetable(\n      dateStart: $dateStart\n      dateEnd: $dateEnd\n      groups: $groups\n      limitOrCountOrAll: 1000\n    )\n  }\n": types.StudentTimetableDocument,
    "\n  query TeacherTimetable($dateStart: String, $dateEnd: String, $teacher_guid: String) {\n    teacherTimetable(\n      dateStart: $dateStart\n      dateEnd: $dateEnd\n      teacher_guid: $teacher_guid\n    )\n  }\n": types.TeacherTimetableDocument,
    "\n  query StudentTimetableCount($dateStart: String, $dateEnd: String) {\n    studentTimetable(\n      dateStart: $dateStart\n      dateEnd: $dateEnd\n      limitOrCountOrAll: -1\n    )\n  }\n": types.StudentTimetableCountDocument,
    "\n  query StudentTimetableYears {\n    studentTimetableYears\n  }\n": types.StudentTimetableYearsDocument,
    "\n  mutation ActivateInternetSessionByCurrentUser {\n    activateInternetSessionByCurrentUser {\n      code\n      dateLastLogin\n      intranetStatus\n      ip\n      login\n    }\n  }\n": types.ActivateInternetSessionByCurrentUserDocument,
    "\n  mutation DeactivateInternetSessionByCurrentUser{\n    deactivateInternetSessionByCurrentUser {\n      code\n      dateLastLogin\n      intranetStatus\n      ip\n      login\n    }\n  }\n": types.DeactivateInternetSessionByCurrentUserDocument,
    "\n  mutation RemoveFile($fileId: String!) {\n      removeFile(fileId: $fileId) {\n        ... on RemoveFileResult {\n          ... on RemoveFile {\n            result\n          }\n          ... on InvalidArgumentError {\n            message\n          }\n          ... on InvalidArgumentError {\n            message\n          }\n        }\n      }\n    }\n": types.RemoveFileDocument,
    "\n    query GetDownloadUrl($fileId: String!) {\n        getPresignedDownloadUrl(fileId: $fileId) {\n            __typename\n            ... on FileNotFoundError {\n                message\n            }\n            ... on InvalidArgumentError {\n                message\n            }\n            ... on PresignedDownloadUrl {\n                downloadUrl\n            }\n        }\n    }\n": types.GetDownloadUrlDocument,
    "\n  query GetWorkspaceIconsByCurrentUser {\n      getWorkspaceIconsByCurrentUser {\n        iconDescription\n        iconID\n        iconImage\n        iconLink\n        iconName\n        iconSort\n      }\n    }\n": types.GetWorkspaceIconsByCurrentUserDocument,
    "\n  query GetInternetConnectionStatusByCurrentUser {\n    getInternetConnectionStatusByCurrentUser {\n      code\n      dateLastLogin\n      intranetStatus\n      ip\n      login\n    }\n  }\n": types.GetInternetConnectionStatusByCurrentUserDocument,
    "\n  query checkFiles($files: [FileEntryInput!]!) {\n    checkFiles(files: $files)\n  }\n": types.CheckFilesDocument,
    "\n  mutation FileUploadConfirmation($fileId: String!) {\n    fileUploadConfirmation(fileId: $fileId) {\n      ... on FileUploadConfirmation {\n        result\n      }\n      ... on FileNotFoundError {\n        message\n      }\n      ... on InvalidArgumentError {\n        message\n      }\n    }\n  }\n": types.FileUploadConfirmationDocument,
    "\n    mutation GetUploadUrl($inputFile: InputFile!) {\n        getPresignedUploadURL(inputFile: $inputFile) {\n            ... on PresignedUploadUrlResult {\n                ... on InvalidArgumentError {\n                    __typename\n                    message\n                }\n                ... on PresignedUploadUrl {\n                    __typename\n                    fileId\n                    url\n                }\n            }\n        }\n    }\n": types.GetUploadUrlDocument,
    "\n    subscription OnUiNotification {\n        uiNotification\n    }\n": types.OnUiNotificationDocument,
    "\n    query ListNotification($i:NotificationListInput) {\n      notificationList(input:$i){\n        items{\n          body\n          meta\n          from\n          msg_id\n          status\n          created\n          source\n        }\n      }\n    }\n": types.ListNotificationDocument,
    "\n    query NotificationStats {\n        uiNotificationsStats\n    }\n": types.NotificationStatsDocument,
    "\n    mutation AddNotification($i:UINotificationInput) {\n      notificationCreate(input:$i)\n    }\n": types.AddNotificationDocument,
    "\n    mutation UpdateNotification($message_ids: [Int!]!, $status: NotificationStatus) {\n      notificationUpdateStatus(msg_id: $message_ids, status: $status)\n    }\n": types.UpdateNotificationDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EsEducationPlan($esEducationPlanId: String) {\n    esEducationPlan(id: $esEducationPlanId) {\n      disciplineList {\n        ep_id\n        id\n        quantity\n        module\n        semester\n        title\n      }\n    }\n  }\n"): (typeof documents)["\n  query EsEducationPlan($esEducationPlanId: String) {\n    esEducationPlan(id: $esEducationPlanId) {\n      disciplineList {\n        ep_id\n        id\n        quantity\n        module\n        semester\n        title\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EducationPlanList {\n    EducationPlanList {\n      ep_id\n      nameprof\n      namespec\n      admission_end\n      admission_start\n  }\n}\n"): (typeof documents)["\n  query EducationPlanList {\n    EducationPlanList {\n      ep_id\n      nameprof\n      namespec\n      admission_end\n      admission_start\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nsubscription OnProjectAdded {\n  projectChanged\n}\n"): (typeof documents)["\nsubscription OnProjectAdded {\n  projectChanged\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetNameProfs($input: NameProfsListInput) {\n    nameprofsList(input: $input)\n}\n"): (typeof documents)["\n  query GetNameProfs($input: NameProfsListInput) {\n    nameprofsList(input: $input)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n query GetSpecsName($input: NameSpecsListInput) {\n  namespecsList(input: $input)\n}\n"): (typeof documents)["\n query GetSpecsName($input: NameSpecsListInput) {\n  namespecsList(input: $input)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Commit{\n    ecCommitEducationOfIndividials\n  }\n"): (typeof documents)["\n  mutation Commit{\n    ecCommitEducationOfIndividials\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FrontCheckVersion {\n    build: utilConfig(domain:\"mobile\",key:\"version\")\n    ios: utilConfig(domain:\"mobile\",key:\"version.ios\")\n    android: utilConfig(domain:\"mobile\",key:\"version.android\")\n  }\n"): (typeof documents)["\n  query FrontCheckVersion {\n    build: utilConfig(domain:\"mobile\",key:\"version\")\n    ios: utilConfig(domain:\"mobile\",key:\"version.ios\")\n    android: utilConfig(domain:\"mobile\",key:\"version.android\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StudentDataLookup($input: StudentDataLookupInput!) {\n    studentDataLookup(input: $input)\n  }\n"): (typeof documents)["\n  query StudentDataLookup($input: StudentDataLookupInput!) {\n    studentDataLookup(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SMSRequest(\n    $phone: String!\n    $message: String\n    $expire: Int\n    $fake: Boolean\n    $source: String\n  ) {\n    smsCodeRequest(\n      phone: $phone\n      message: $message\n      expire: $expire\n      fake: $fake\n      source: $source\n    )\n  }\n"): (typeof documents)["\n  mutation SMSRequest(\n    $phone: String!\n    $message: String\n    $expire: Int\n    $fake: Boolean\n    $source: String\n  ) {\n    smsCodeRequest(\n      phone: $phone\n      message: $message\n      expire: $expire\n      fake: $fake\n      source: $source\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CheckCode($code: String!) {\n    smsCodeCheck(code: $code)\n  }\n"): (typeof documents)["\n  query CheckCode($code: String!) {\n    smsCodeCheck(code: $code)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteUpload($upload_ids: [Int]!) {\n    deleteUpload(upload_ids: $upload_ids)\n  }\n"): (typeof documents)["\n  mutation DeleteUpload($upload_ids: [Int]!) {\n    deleteUpload(upload_ids: $upload_ids)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ecInstList {\n    catalogs(names: [\"ecInstList\"])\n  }\n"): (typeof documents)["\n  query ecInstList {\n    catalogs(names: [\"ecInstList\"])\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query DepList($input: DepartmentListInput) {\n        departmentList(input: $input)\n    }\n"): (typeof documents)["\n    query DepList($input: DepartmentListInput) {\n        departmentList(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSplash {\n    notificationSplash\n  }\n"): (typeof documents)["\n  query GetSplash {\n    notificationSplash\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ecExportTable_BaseStats{\n    ecExportTable_BaseStats\n  }\n"): (typeof documents)["\n  query ecExportTable_BaseStats{\n    ecExportTable_BaseStats\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ecExportTable_1_1{\n    ecExportTable_1_1\n  }\n"): (typeof documents)["\n  query ecExportTable_1_1{\n    ecExportTable_1_1\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ecExportTable_3_5{\n    ecExportTable_3_5\n  }\n"): (typeof documents)["\n  query ecExportTable_3_5{\n    ecExportTable_3_5\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ecExportTable_EffectiveStats_all{\n    ecExportTable_EffectiveStats(name:\"allStats\",mode:ALL)\n  }\n"): (typeof documents)["\n  query ecExportTable_EffectiveStats_all{\n    ecExportTable_EffectiveStats(name:\"allStats\",mode:ALL)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ecExportTable_EffectiveStats_mounthly{\n    ecExportTable_EffectiveStats(name:\"allStats\",mode:MONTHLY)\n  }\n"): (typeof documents)["\n  query ecExportTable_EffectiveStats_mounthly{\n    ecExportTable_EffectiveStats(name:\"allStats\",mode:MONTHLY)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ecExportTable_EffectiveStats{\n    ecExportTable_EffectiveStats(name:\"allStats\",mode:ONETIME)\n  }\n"): (typeof documents)["\n  query ecExportTable_EffectiveStats{\n    ecExportTable_EffectiveStats(name:\"allStats\",mode:ONETIME)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription ConfigChanged {\n    configChanged\n  }\n"): (typeof documents)["\n  subscription ConfigChanged {\n    configChanged\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Generate{\n    ecCreateData22_18(mode: ALL)\n  }\n"): (typeof documents)["\n  query Generate{\n    ecCreateData22_18(mode: ALL)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EcCreateData(\n    $dataType: String!,\n    $data: JSON!,\n    $user_ids: [String],\n    $extra: JSON\n  ) {\n    ecCreateData(\n      dataType: $dataType,\n      data: $data,\n      user_ids: $user_ids,\n      extra: $extra\n    )\n  }\n"): (typeof documents)["\n  mutation EcCreateData(\n    $dataType: String!,\n    $data: JSON!,\n    $user_ids: [String],\n    $extra: JSON\n  ) {\n    ecCreateData(\n      dataType: $dataType,\n      data: $data,\n      user_ids: $user_ids,\n      extra: $extra\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EcDeleteData($rec_id:Int!,$dataType:String!){\n    ecDeleteData(rec_id:$rec_id,dataType:$dataType)\n  }\n"): (typeof documents)["\n  mutation EcDeleteData($rec_id:Int!,$dataType:String!){\n    ecDeleteData(rec_id:$rec_id,dataType:$dataType)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ecTable_2_8_Categories {\n    catalogs(names: [\"ecTable_2_8_Categories\"])\n  }\n"): (typeof documents)["\n  query ecTable_2_8_Categories {\n    catalogs(names: [\"ecTable_2_8_Categories\"])\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ecTable2_8_Roles {\n    catalogs(names: [\"ecTable2_8_Roles\"])\n  }\n"): (typeof documents)["\n  query ecTable2_8_Roles {\n    catalogs(names: [\"ecTable2_8_Roles\"])\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TeacherPositions {\n    teacherPositions(search: \"\")\n  }\n"): (typeof documents)["\n  query TeacherPositions {\n    teacherPositions(search: \"\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetModeratorPosFilter {\n    catalogs(names: [\"ecModeratorPositionsFilter\"])\n  }\n"): (typeof documents)["\n  query GetModeratorPosFilter {\n    catalogs(names: [\"ecModeratorPositionsFilter\"])\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription OnContractChange {\n    contractsChanged\n  }\n"): (typeof documents)["\n  subscription OnContractChange {\n    contractsChanged\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EcListData($params:JSON){\n    ecListData(params:$params)\n  }\n"): (typeof documents)["\n  query EcListData($params:JSON){\n    ecListData(params:$params)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EcUpdateDataType(\n    $rec_id: Int!,\n    $dataType: String\n  ) {\n  ecUpdateDataType(\n    rec_id: $rec_id,\n    dataType: $dataType\n  )\n}\n"): (typeof documents)["\n  mutation EcUpdateDataType(\n    $rec_id: Int!,\n    $dataType: String\n  ) {\n  ecUpdateDataType(\n    rec_id: $rec_id,\n    dataType: $dataType\n  )\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetScoresBySelector(\n    $user_id: String,\n    $selector: DataTypeSelector,\n    $extra: JSON\n  ){\n  ecGetScoresTableBySelector(\n    user_id: $user_id,\n    dataTypeSelector: $selector,\n    extra: $extra\n  )\n}\n"): (typeof documents)["\n  query GetScoresBySelector(\n    $user_id: String,\n    $selector: DataTypeSelector,\n    $extra: JSON\n  ){\n  ecGetScoresTableBySelector(\n    user_id: $user_id,\n    dataTypeSelector: $selector,\n    extra: $extra\n  )\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CheckCompletion($user_id: String) {\n    ecCheckCompletion(user_id: $user_id)\n  }\n"): (typeof documents)["\n  query CheckCompletion($user_id: String) {\n    ecCheckCompletion(user_id: $user_id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBaseLine ($user_id: String, $dataType: [String]!) {\n    ecGetBaselineCompletion(user_id: $user_id, dataType: $dataType)\n  }\n"): (typeof documents)["\n  query GetBaseLine ($user_id: String, $dataType: [String]!) {\n    ecGetBaselineCompletion(user_id: $user_id, dataType: $dataType)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetComplitionsStats($user_id: String, $dataType: String!) {\n    ecGetCompletionStats(user_id:$user_id, dataType:$dataType)\n  }\n"): (typeof documents)["\n  query GetComplitionsStats($user_id: String, $dataType: String!) {\n    ecGetCompletionStats(user_id:$user_id, dataType:$dataType)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetGraphDataTotals($id:String){\n    ecGraphDataTotals(user_id:$id)\n  }\n"): (typeof documents)["\n  query GetGraphDataTotals($id:String){\n    ecGraphDataTotals(user_id:$id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UtilConfig($domain: String, $key: String) {\n    utilConfig(domain: $domain, key: $key)\n  }\n"): (typeof documents)["\n  query UtilConfig($domain: String, $key: String) {\n    utilConfig(domain: $domain, key: $key)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Teachers($search:String, $pagination:JSON) {\n    teacherList(search: $search, pagination: $pagination)\n  }\n"): (typeof documents)["\n  query Teachers($search:String, $pagination:JSON) {\n    teacherList(search: $search, pagination: $pagination)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EcDataTableByUserAndStatus($input:ecDataTableByUserAndStatusInput!) {\n    ecDataTableByUserAndStatus(input:$input)\n  }\n"): (typeof documents)["\n  query EcDataTableByUserAndStatus($input:ecDataTableByUserAndStatusInput!) {\n    ecDataTableByUserAndStatus(input:$input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Import_22_16_17($upload_id: Int!, $async: Boolean) {\n    ecImportTable_22_16x17(upload_id: $upload_id, async: $async)\n  }\n"): (typeof documents)["\n  mutation Import_22_16_17($upload_id: Int!, $async: Boolean) {\n    ecImportTable_22_16x17(upload_id: $upload_id, async: $async)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EcUpdateData(\n    $rec_id:Int!,\n    $new_data:JSON,\n    $user_ids:[String],\n    $new_extra:JSON,\n    $new_appeal:JSON,\n  ) {\n    ecUpdateData(\n      rec_id:$rec_id,\n      data:$new_data,\n      user_ids:$user_ids,\n      extra:$new_extra,\n      appeal:$new_appeal,\n    )\n  }\n"): (typeof documents)["\n  mutation EcUpdateData(\n    $rec_id:Int!,\n    $new_data:JSON,\n    $user_ids:[String],\n    $new_extra:JSON,\n    $new_appeal:JSON,\n  ) {\n    ecUpdateData(\n      rec_id:$rec_id,\n      data:$new_data,\n      user_ids:$user_ids,\n      extra:$new_extra,\n      appeal:$new_appeal,\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UtilUpdateConfig($input: UpdateConfigInput!) {\n    utilUpdateConfig(input: $input)\n  }\n"): (typeof documents)["\n  mutation UtilUpdateConfig($input: UpdateConfigInput!) {\n    utilUpdateConfig(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetFormData($input: ReferenceFormFieldDataInput!) {\n  referenceGetFormFieldData(input: $input)\n}\n"): (typeof documents)["\nquery GetFormData($input: ReferenceFormFieldDataInput!) {\n  referenceGetFormFieldData(input: $input)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetApplications($input: MFCStatementsInput!){\n  mfcStatements(input: $input)\n}\n"): (typeof documents)["\nquery GetApplications($input: MFCStatementsInput!){\n  mfcStatements(input: $input)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFormTypes($statementType: MFCStatementType){\n    mfcGetFormTypes(statementType: $statementType)\n  }\n"): (typeof documents)["\n  query GetFormTypes($statementType: MFCStatementType){\n    mfcGetFormTypes(statementType: $statementType)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetForm($id:UUID!){\n  mfcGetForm(id: $id)\n}\n"): (typeof documents)["\nquery GetForm($id:UUID!){\n  mfcGetForm(id: $id)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation SendStatement($payload: JSON!, $type: MFCStatementType!) {\n  mfcSendStatement(payload:$payload, type: $type)\n}\n"): (typeof documents)["\nmutation SendStatement($payload: JSON!, $type: MFCStatementType!) {\n  mfcSendStatement(payload:$payload, type: $type)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ReferenceStatus {\n    references(mode: \"stats\")\n  }\n"): (typeof documents)["\n  query ReferenceStatus {\n    references(mode: \"stats\")\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ReferenceList($status: [String]) {\n    references(status: $status)\n  }\n"): (typeof documents)["\n  query ReferenceList($status: [String]) {\n    references(status: $status)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateReference($payload: JSON) {\n    createReference(payload: $payload)\n  }\n"): (typeof documents)["\n  mutation CreateReference($payload: JSON) {\n    createReference(payload: $payload)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation CommitReferences($doc_id:Int ) {\n  referenceCommit(doc_id:$doc_id)\n}\n"): (typeof documents)["\nmutation CommitReferences($doc_id:Int ) {\n  referenceCommit(doc_id:$doc_id)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription ReferenceChanged {\n    referenceChanged\n  }\n"): (typeof documents)["\n  subscription ReferenceChanged {\n    referenceChanged\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NotificationDraftAdd($body: JSON!) {\n    notificationDraftAdd(body: $body)\n  }\n"): (typeof documents)["\n  mutation NotificationDraftAdd($body: JSON!) {\n    notificationDraftAdd(body: $body)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NotificationDraftChangeActive($draft_id: [Int!]!, $is_active: Boolean) {\n    notificationDraftChangeActive(draft_id: $draft_id, is_active: $is_active)\n  }\n"): (typeof documents)["\n  mutation NotificationDraftChangeActive($draft_id: [Int!]!, $is_active: Boolean) {\n    notificationDraftChangeActive(draft_id: $draft_id, is_active: $is_active)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NotificationDraftDelete($draft_id: Int!) {\n    notificationDraftDelete(draft_id: $draft_id)\n  }\n"): (typeof documents)["\n  mutation NotificationDraftDelete($draft_id: Int!) {\n    notificationDraftDelete(draft_id: $draft_id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query notificationDraft($input: NotificationDraftInput) {\n    notificationDraft(input: $input)\n  }\n"): (typeof documents)["\n  query notificationDraft($input: NotificationDraftInput) {\n    notificationDraft(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NotificationDraftFilters {\n    notificationDraftFilters\n  }\n"): (typeof documents)["\n  query NotificationDraftFilters {\n    notificationDraftFilters\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription NotificationDraftChanged {\n    notificationDraftChanged\n  }\n"): (typeof documents)["\n  subscription NotificationDraftChanged {\n    notificationDraftChanged\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NotificationDraftPurgeMessages($draft_id: [Int!]!) {\n    notificationDraftPurgeMessages(draft_id: $draft_id)\n  }\n"): (typeof documents)["\n  mutation NotificationDraftPurgeMessages($draft_id: [Int!]!) {\n    notificationDraftPurgeMessages(draft_id: $draft_id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NotificationDraftReset($draft_id: [Int!]!) {\n    notificationDraftResetProcessed(draft_id: $draft_id)\n  }\n"): (typeof documents)["\n  mutation NotificationDraftReset($draft_id: [Int!]!) {\n    notificationDraftResetProcessed(draft_id: $draft_id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription OnAchievementChanged {\n    achievementChanged\n  }\n"): (typeof documents)["\n  subscription OnAchievementChanged {\n    achievementChanged\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription AchievementDirectionsChanged {\n    achievementDirectionsChanged\n  }\n"): (typeof documents)["\n  subscription AchievementDirectionsChanged {\n    achievementDirectionsChanged\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Directions($is_admin: Boolean) {\n    achievementDirections(is_admin: $is_admin)\n  }\n"): (typeof documents)["\n  query Directions($is_admin: Boolean) {\n    achievementDirections(is_admin: $is_admin)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Statuses {\n    achievementStatuses(all: true)\n  }\n"): (typeof documents)["\n  query Statuses {\n    achievementStatuses(all: true)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription AchievementTypeChanged {\n    achievementTypeChanged\n  }\n"): (typeof documents)["\n  subscription AchievementTypeChanged {\n    achievementTypeChanged\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AchievementTypes2($catid: Int, $key:Int, $is_admin: Boolean) {\n    achievementTypes(catid: $catid, key: $key, is_admin: $is_admin)\n  }\n"): (typeof documents)["\n  query AchievementTypes2($catid: Int, $key:Int, $is_admin: Boolean) {\n    achievementTypes(catid: $catid, key: $key, is_admin: $is_admin)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateAchievement($input: CreateAchievementInput!) {\n    createAchievement(input: $input)\n  }\n"): (typeof documents)["\n  mutation CreateAchievement($input: CreateAchievementInput!) {\n    createAchievement(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StudentsAchievementsRating($input: AchievementsRatingInput!){\n    studentsAchievementsRating(input: $input)\n  }\n"): (typeof documents)["\n  query StudentsAchievementsRating($input: AchievementsRatingInput!){\n    studentsAchievementsRating(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AverageGrade ($input: AverageGradeInput!) {\n    averageGrade (input: $input)\n  }\n"): (typeof documents)["\n  query AverageGrade ($input: AverageGradeInput!) {\n    averageGrade (input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetUpdateConfig($name: String!, $value: String!){\n    updatePersonnelConfig(name: $name, value: $value)\n  }\n"): (typeof documents)["\n  mutation SetUpdateConfig($name: String!, $value: String!){\n    updatePersonnelConfig(name: $name, value: $value)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation changeTypeDirection($input: ChangeTypeDirectionInput) {\n    changeTypeDirection(input: $input)\n  }\n"): (typeof documents)["\n  mutation changeTypeDirection($input: ChangeTypeDirectionInput) {\n    changeTypeDirection(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EditDirection($input: EditAchievementDirectionInput) {\n    editAchievementDirection(input: $input)\n  }\n"): (typeof documents)["\n  mutation EditDirection($input: EditAchievementDirectionInput) {\n    editAchievementDirection(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation editAchievementType($input: EditAchievementTypeInput) {\n    editAchievementType(input: $input)\n  }\n"): (typeof documents)["\n  mutation editAchievementType($input: EditAchievementTypeInput) {\n    editAchievementType(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteAchievement($achievement_id: Int) {\n    deleteAchievement(achievement_id: $achievement_id)\n  }\n"): (typeof documents)["\n  mutation DeleteAchievement($achievement_id: Int) {\n    deleteAchievement(achievement_id: $achievement_id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAchievementRating($count: Int) {\n    achievementRating(count: $count)\n  }\n"): (typeof documents)["\n  query GetAchievementRating($count: Int) {\n    achievementRating(count: $count)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDirectionsWithAchievements($id: UUID!, $available: Boolean, $status: AchievementStatus) {\n    getDirectionsWithAchievements(id: $id, available: $available, status: $status)\n  }\n"): (typeof documents)["\n  query GetDirectionsWithAchievements($id: UUID!, $available: Boolean, $status: AchievementStatus) {\n    getDirectionsWithAchievements(id: $id, available: $available, status: $status)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query personnelConfig {\n    personnelConfig(name: [\n      \"is_student_rating_available_all\",\n      \"is_student_rating_include_myself\"\n    ])\n  }\n"): (typeof documents)["\n  query personnelConfig {\n    personnelConfig(name: [\n      \"is_student_rating_available_all\",\n      \"is_student_rating_include_myself\"\n    ])\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StudentAchievements($input:StudentAchievementStatsInput!) {\n    studentAchievementStats(input:$input)\n  }\n"): (typeof documents)["\n  query StudentAchievements($input:StudentAchievementStatsInput!) {\n    studentAchievementStats(input:$input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPersonalRatingById($id: UUID) {\n    achievementRatingById(id: $id)\n  }\n"): (typeof documents)["\n  query GetPersonalRatingById($id: UUID) {\n    achievementRatingById(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription PersonnelConfigChanged {\n    personnelConfigChanged\n  }\n"): (typeof documents)["\n  subscription PersonnelConfigChanged {\n    personnelConfigChanged\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query RequiredStudentPapers ($input: RequiredStudentPapersInput!) {\n    requiredStudentPapers (input: $input)\n  }\n"): (typeof documents)["\n  query RequiredStudentPapers ($input: RequiredStudentPapersInput!) {\n    requiredStudentPapers (input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Counters($direction_key: Int!, $id: UUID) {\n    achievementDirectionCounters(direction_key: $direction_key, id: $id)\n  }\n"): (typeof documents)["\n  query Counters($direction_key: Int!, $id: UUID) {\n    achievementDirectionCounters(direction_key: $direction_key, id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateAchievement($input: UpdateAchievementInput!) {\n    updateAchievement(input: $input)\n  }\n"): (typeof documents)["\n  mutation UpdateAchievement($input: UpdateAchievementInput!) {\n    updateAchievement(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery paProjectSeminarGroupDetail($input: ProjectSeminarGroupDetailInput!) {\n    paProjectSeminarGroupDetail(input: $input)\n  }\n"): (typeof documents)["\nquery paProjectSeminarGroupDetail($input: ProjectSeminarGroupDetailInput!) {\n    paProjectSeminarGroupDetail(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query paTeacherTimetableLookup($input: TeacherTimetableLookupInput!) {\n    paTeacherTimetableLookup(input: $input)\n  }\n"): (typeof documents)["\n  query paTeacherTimetableLookup($input: TeacherTimetableLookupInput!) {\n    paTeacherTimetableLookup(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PaControl($params: JSON) {\n    paControl(params: $params)\n  }\n"): (typeof documents)["\n  mutation PaControl($params: JSON) {\n    paControl(params: $params)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AchievementTypes($catid: Int) {\n    achievementTypes(catid: $catid)\n  }\n"): (typeof documents)["\n  query AchievementTypes($catid: Int) {\n    achievementTypes(catid: $catid)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAchievements($input: AchievementsInput!) {\n    achievements(input: $input)\n  }\n"): (typeof documents)["\n  query GetAchievements($input: AchievementsInput!) {\n    achievements(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPersonalRating {\n    achievementPersonalRating\n  }\n"): (typeof documents)["\n  query GetPersonalRating {\n    achievementPersonalRating\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetUploads {\n  uploads(category: \"avatars\")\n}\n"): (typeof documents)["\nquery GetUploads {\n  uploads(category: \"avatars\")\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetDefaultAvatar($upload_id: Int!) {\n    defaultAvatar(upload_id: $upload_id)\n  }\n"): (typeof documents)["\n  mutation SetDefaultAvatar($upload_id: Int!) {\n    defaultAvatar(upload_id: $upload_id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation DeleteUpload($upload_ids: [Int]!) {\n  deleteUpload(upload_ids: $upload_ids)\n}\n"): (typeof documents)["\nmutation DeleteUpload($upload_ids: [Int]!) {\n  deleteUpload(upload_ids: $upload_ids)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nsubscription UploadChanged{\n  uploadsChanged\n}\n"): (typeof documents)["\nsubscription UploadChanged{\n  uploadsChanged\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nsubscription DefaultChanged{\n  avatarChanged\n}\n"): (typeof documents)["\nsubscription DefaultChanged{\n  avatarChanged\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCatalogs($names: [String]) {\n    catalogs(names: $names)\n  }\n"): (typeof documents)["\n  query GetCatalogs($names: [String]) {\n    catalogs(names: $names)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetEducationContracts {\n    finEducationContracts {\n      contract\n      createDate\n      startDate\n      endDate\n      customer\n      debt\n      penalties\n      hasPenalties\n      educationType\n      actual\n      refreshDate\n      paymentSchedule{\n        nearestPayDate\n        paySum\n        TheSumFaktPayment\n        graph{\n          PayDo\n          TheAmount\n        }\n      }\n      isLegal\n    }\n  }\n"): (typeof documents)["\n  query GetEducationContracts {\n    finEducationContracts {\n      contract\n      createDate\n      startDate\n      endDate\n      customer\n      debt\n      penalties\n      hasPenalties\n      educationType\n      actual\n      refreshDate\n      paymentSchedule{\n        nearestPayDate\n        paySum\n        TheSumFaktPayment\n        graph{\n          PayDo\n          TheAmount\n        }\n      }\n      isLegal\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetEducationPaymentsHistory($contract: String) {\n    educationPaymentsHistory(contract: $contract)\n  }\n"): (typeof documents)["\n  query GetEducationPaymentsHistory($contract: String) {\n    educationPaymentsHistory(contract: $contract)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query QuitancesBanks($contract: String) {\n    quittancesBanks(contract: $contract)\n  }\n"): (typeof documents)["\n  query QuitancesBanks($contract: String) {\n    quittancesBanks(contract: $contract)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDormitoryContracts {\n    dormitoryContracts\n  }\n"): (typeof documents)["\n  query GetDormitoryContracts {\n    dormitoryContracts\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDormitoryPayments($number_contract: String) {\n    dormitoryPayments(number_contract: $number_contract)\n  }\n"): (typeof documents)["\n  query GetDormitoryPayments($number_contract: String) {\n    dormitoryPayments(number_contract: $number_contract)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetQR($contract: String, $type: String) {\n    quittances(contract: $contract, type: $type)\n  }\n"): (typeof documents)["\n  query GetQR($contract: String, $type: String) {\n    quittances(contract: $contract, type: $type)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAccrudeScholarShip($mode: String) {\n    accruedScholarship(mode: $mode)\n  }\n"): (typeof documents)["\n  query GetAccrudeScholarShip($mode: String) {\n    accruedScholarship(mode: $mode)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPlannedCharges {\n    plannedCharges\n  }\n"): (typeof documents)["\n  query GetPlannedCharges {\n    plannedCharges\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery Periods($input:AccruedScholarshipInput) {\n  finAccruedScholarship(input: $input) {\n    period\n  }\n}"): (typeof documents)["\nquery Periods($input:AccruedScholarshipInput) {\n  finAccruedScholarship(input: $input) {\n    period\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery Scolarship($input:AccruedScholarshipInput) {\n  finAccruedScholarship(input: $input) {\n    period\n    rec_id\n    updated\n    blocks {\n\n      block_accruals {\n        sum\n        date\n        name\n      }\n\n      block_holdings{\n        sum\n        date\n        name\n      }\n\n      block_payout {\n        sum\n        date\n        name\n      }\n    }\n  }\n}"): (typeof documents)["\nquery Scolarship($input:AccruedScholarshipInput) {\n  finAccruedScholarship(input: $input) {\n    period\n    rec_id\n    updated\n    blocks {\n\n      block_accruals {\n        sum\n        date\n        name\n      }\n\n      block_holdings{\n        sum\n        date\n        name\n      }\n\n      block_payout {\n        sum\n        date\n        name\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetDisciplines($group: String!) {\n  disciplines(group: $group)\n      }\n"): (typeof documents)["\nquery GetDisciplines($group: String!) {\n  disciplines(group: $group)\n      }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetStudentJournal($group: String!, $discipline: String!, $type: String!, $semester: Int) {\n  getStudentJournal(group: $group, discipline: $discipline, type: $type, semester: $semester)\n}\n"): (typeof documents)["\nquery GetStudentJournal($group: String!, $discipline: String!, $type: String!, $semester: Int) {\n  getStudentJournal(group: $group, discipline: $discipline, type: $type, semester: $semester)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery TeacherDisciplineGroups {\n  teacherDisciplineGroups(testID:\"010c0df0-3dfc-11e6-80bd-7824af85d229\")\n}\n"): (typeof documents)["\nquery TeacherDisciplineGroups {\n  teacherDisciplineGroups(testID:\"010c0df0-3dfc-11e6-80bd-7824af85d229\")\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getME {\n    me\n  }\n"): (typeof documents)["\n  query getME {\n    me\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UserInfo($user_id: String!) {\n    userInfo(user_id: $user_id)\n  }\n"): (typeof documents)["\n  query UserInfo($user_id: String!) {\n    userInfo(user_id: $user_id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SliderImages {\n    sliderImages\n  }\n"): (typeof documents)["\n  query SliderImages {\n    sliderImages\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EducationPlan($i:EducationPlanInput) {\n    educationPlan(input:$i){\n      user_id\n      recordbook\n      plans {\n        ep_id\n        ep_number\n        individual_plan\n        admission_start\n        admission_end\n        date\n    }\n   }\n  }\n"): (typeof documents)["\n  query EducationPlan($i:EducationPlanInput) {\n    educationPlan(input:$i){\n      user_id\n      recordbook\n      plans {\n        ep_id\n        ep_number\n        individual_plan\n        admission_start\n        admission_end\n        date\n    }\n   }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery EPDisciplines($i:EducationPlanDisciplinesInput) {\n  educationPlanDisciplines(input:$i)\n}\n"): (typeof documents)["\nquery EPDisciplines($i:EducationPlanDisciplinesInput) {\n  educationPlanDisciplines(input:$i)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery States($type: DisciplineType) {\n  esDisciplinesState(type: $type)\n}\n"): (typeof documents)["\nquery States($type: DisciplineType) {\n  esDisciplinesState(type: $type)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery TrackState {\n  esTrackState\n}\n"): (typeof documents)["\nquery TrackState {\n  esTrackState\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery DisciplinesBySemester($i:DisciplinesBySemestersInput!) {\n  bySemester: esDisciplinesBySemesters(input:$i)\n  common: esDisciplinesBySemesters(input:{\n    filter:{\n      semester:-1\n    }\n  })\n }\n"): (typeof documents)["\nquery DisciplinesBySemester($i:DisciplinesBySemestersInput!) {\n  bySemester: esDisciplinesBySemesters(input:$i)\n  common: esDisciplinesBySemesters(input:{\n    filter:{\n      semester:-1\n    }\n  })\n }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MoodleCourses($i:MoodleCoursesInput) {\n    moodleCourses(input:$i)\n  }\n"): (typeof documents)["\n  query MoodleCourses($i:MoodleCoursesInput) {\n    moodleCourses(input:$i)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetOne($name: String!, $semester: [Int] ) {\n    esMoodleCoursesByDiscipline(discipline_name:$name, semester:$semester)\n  }\n"): (typeof documents)["\n  query GetOne($name: String!, $semester: [Int] ) {\n    esMoodleCoursesByDiscipline(discipline_name:$name, semester:$semester)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ListFacultatives($i:FacultativeInput!){\n    esFacultative(input:$i)\n  }\n"): (typeof documents)["\n  query ListFacultatives($i:FacultativeInput!){\n    esFacultative(input:$i)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Signed($i:SignedStudentsInput!){\n    esSignedStudents(input:$i)\n  }\n"): (typeof documents)["\n  query Signed($i:SignedStudentsInput!){\n    esSignedStudents(input:$i)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDisciplineProcedure($i:DisciplineType!){\n    esDisciplineProcedure(type: $i){\n      procedure_info\n      procedure_data\n    }\n  }\n"): (typeof documents)["\n  query GetDisciplineProcedure($i:DisciplineType!){\n    esDisciplineProcedure(type: $i){\n      procedure_info\n      procedure_data\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetStats($i:DisciplineStatsInput) {\n    esDisciplineStats(input: $i)\n  }\n"): (typeof documents)["\n  query GetStats($i:DisciplineStatsInput) {\n    esDisciplineStats(input: $i)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetTracks($i:TrackListInput!) {\n  esTrackList(input: $i){\n    items {\n    is_active\n    is_visible\n    max_participants\n    track_id\n    is_signed\n    name\n    sign_count\n    file\n    data\n    disciplines{\n      discipline_id\n      name\n    }\n    }\n  }\n}\n"): (typeof documents)["\nquery GetTracks($i:TrackListInput!) {\n  esTrackList(input: $i){\n    items {\n    is_active\n    is_visible\n    max_participants\n    track_id\n    is_signed\n    name\n    sign_count\n    file\n    data\n    disciplines{\n      discipline_id\n      name\n    }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTrackDisciplines($i:TrackDisciplinesInput){\n    esTrackDisciplines(input:$i){\n      name\n      semester\n      discipline\n      info\n      discipline_id\n      semester\n      hours\n      teachers\n      details\n      themes\n      discipline\n    }\n  }\n"): (typeof documents)["\n  query GetTrackDisciplines($i:TrackDisciplinesInput){\n    esTrackDisciplines(input:$i){\n      name\n      semester\n      discipline\n      info\n      discipline_id\n      semester\n      hours\n      teachers\n      details\n      themes\n      discipline\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTrackStudents($i: TrackStudentsInput!) {\n    esTrackStudents(input: $i){\n      full_name\n      disciplines\n      student_info\n      student_id\n    }\n  }\n"): (typeof documents)["\n  query GetTrackStudents($i: TrackStudentsInput!) {\n    esTrackStudents(input: $i){\n      full_name\n      disciplines\n      student_info\n      student_id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSignStatus($id: UUID!){\n    esGetSignStatus(id: $id)\n  }\n"): (typeof documents)["\n  query GetSignStatus($id: UUID!){\n    esGetSignStatus(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProjectThemes($i: ProjectThemesInput){\n    paProjectThemes(input: $i)\n  }\n"): (typeof documents)["\n  query GetProjectThemes($i: ProjectThemesInput){\n    paProjectThemes(input: $i)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateFacultative ($i:CreateFacultativeInput!){\n    esCreateFacultative(input:$i){\n      facultative_id\n      data\n      is_active\n      is_visible\n    }\n  }\n"): (typeof documents)["\n  mutation CreateFacultative ($i:CreateFacultativeInput!){\n    esCreateFacultative(input:$i){\n      facultative_id\n      data\n      is_active\n      is_visible\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateTrack ($i:CreateTrackInput!){\n    esTrackCreate(input:$i)\n  }\n"): (typeof documents)["\n  mutation CreateTrack ($i:CreateTrackInput!){\n    esTrackCreate(input:$i)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateTrack ($i:TrackUpdateInput!){\n    esTrackUpdate(input:$i)\n  }\n"): (typeof documents)["\n  mutation UpdateTrack ($i:TrackUpdateInput!){\n    esTrackUpdate(input:$i)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SignUpTrack ($i:SignUpTrackInput!){\n    esSignUpTrack(input: $i)\n  }\n"): (typeof documents)["\n  mutation SignUpTrack ($i:SignUpTrackInput!){\n    esSignUpTrack(input: $i)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SignOutTrack ($i:SignOutTrackInput!){\n    esSignOutTrack(input: $i)\n  }\n"): (typeof documents)["\n  mutation SignOutTrack ($i:SignOutTrackInput!){\n    esSignOutTrack(input: $i)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation SignOutOneTrack ($i: SignOutTrackStudentInput!){\n  esSignOutTrackStudent(input: $i)\n}\n"): (typeof documents)["\nmutation SignOutOneTrack ($i: SignOutTrackStudentInput!){\n  esSignOutTrackStudent(input: $i)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateTrackDiscipline($i:TrackDisciplineUpdateInput!){\n  esTrackUpdateDiscipline(input:$i)\n}\n"): (typeof documents)["\nmutation UpdateTrackDiscipline($i:TrackDisciplineUpdateInput!){\n  esTrackUpdateDiscipline(input:$i)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation SignUp($i:SignUpFacultativeInput!){\n  esSignUpFacultative(input:$i)\n}\n"): (typeof documents)["\nmutation SignUp($i:SignUpFacultativeInput!){\n  esSignUpFacultative(input:$i)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation SignOut($i:SignOutFacultativeInput!){\n  esSignOutFacultative(input:$i)\n}\n"): (typeof documents)["\nmutation SignOut($i:SignOutFacultativeInput!){\n  esSignOutFacultative(input:$i)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation SignUpProjectTheme($i:ProjectThemeSignUpInput!){\n  paProjectThemeSignUp(input:$i)\n }\n"): (typeof documents)["\n mutation SignUpProjectTheme($i:ProjectThemeSignUpInput!){\n  paProjectThemeSignUp(input:$i)\n }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation SignOutProjectTheme($i: ProjectThemeSignOutInput!){\n  paProjectThemeSignOut(input:$i)\n}\n"): (typeof documents)["\nmutation SignOutProjectTheme($i: ProjectThemeSignOutInput!){\n  paProjectThemeSignOut(input:$i)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation DeleteFacultative($i: DeleteFacultativeInput!) {\n  esDeleteFacultative(input:$i){\n    facultative_id\n  }\n}\n"): (typeof documents)["\nmutation DeleteFacultative($i: DeleteFacultativeInput!) {\n  esDeleteFacultative(input:$i){\n    facultative_id\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation DeleteTrack($track_id:[Int!]!) {\n  esTrackDelete(track_id: $track_id)\n}\n"): (typeof documents)["\nmutation DeleteTrack($track_id:[Int!]!) {\n  esTrackDelete(track_id: $track_id)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation CreateProjectTheme($i:ProjectThemeCreateInput!){\n  paProjectThemeCreate(input: $i)\n}\n"): (typeof documents)["\nmutation CreateProjectTheme($i:ProjectThemeCreateInput!){\n  paProjectThemeCreate(input: $i)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation esDisciplineActivate($is_active: Boolean!, $type: DisciplineType) {\n  esDisciplineActivate(is_active: $is_active, type: $type)\n}\n"): (typeof documents)["\nmutation esDisciplineActivate($is_active: Boolean!, $type: DisciplineType) {\n  esDisciplineActivate(is_active: $is_active, type: $type)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation ChangeActiveTrack($is_active: Boolean!){\n  esTrackActivate(is_active: $is_active)\n}\n"): (typeof documents)["\nmutation ChangeActiveTrack($is_active: Boolean!){\n  esTrackActivate(is_active: $is_active)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation ChangeActiveTrackOne($is_active: Boolean!, $track_id: Int){\n  esTrackActivate(is_active: $is_active, track_id: $track_id)\n}\n"): (typeof documents)["\nmutation ChangeActiveTrackOne($is_active: Boolean!, $track_id: Int){\n  esTrackActivate(is_active: $is_active, track_id: $track_id)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateTrackVisibility($is_visible: Boolean!, $track_id: Int!) {\n  esTrackUpdateVisibility(is_visible: $is_visible, track_id: $track_id)\n}\n"): (typeof documents)["\nmutation UpdateTrackVisibility($is_visible: Boolean!, $track_id: Int!) {\n  esTrackUpdateVisibility(is_visible: $is_visible, track_id: $track_id)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation esDisciplineActivateOne($is_active: Boolean!, $facultative_id: [Int!], $type:DisciplineType) {\n  esDisciplineActivate(is_active: $is_active, facultative_id: $facultative_id, type: $type)\n}\n"): (typeof documents)["\nmutation esDisciplineActivateOne($is_active: Boolean!, $facultative_id: [Int!], $type:DisciplineType) {\n  esDisciplineActivate(is_active: $is_active, facultative_id: $facultative_id, type: $type)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation EditProcedure($i:EditDisciplineProcedureInput!){\n  esEditDisciplineProcedure(input:$i){\n    procedure_info\n    procedure_data\n  }\n}\n"): (typeof documents)["\nmutation EditProcedure($i:EditDisciplineProcedureInput!){\n  esEditDisciplineProcedure(input:$i){\n    procedure_info\n    procedure_data\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateStats($i:UpdateStatsInput!){\n  esUpdateDisciplineStats(input: $i)\n}\n"): (typeof documents)["\nmutation UpdateStats($i:UpdateStatsInput!){\n  esUpdateDisciplineStats(input: $i)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation Send($i:[DisciplineType]!) {\n  esESB_SelectedDiscipline(mode: $i)\n}\n"): (typeof documents)["\nmutation Send($i:[DisciplineType]!) {\n  esESB_SelectedDiscipline(mode: $i)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nsubscription OnFacultativeChanged {\n  facultativesChanged\n}\n"): (typeof documents)["\nsubscription OnFacultativeChanged {\n  facultativesChanged\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nsubscription OnTracksChanged {\n  esTracksChanged\n}\n"): (typeof documents)["\nsubscription OnTracksChanged {\n  esTracksChanged\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nsubscription OnProjectThemeChange {\n  projectIntroChanged\n}\n"): (typeof documents)["\nsubscription OnProjectThemeChange {\n  projectIntroChanged\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAllProjects($params: JSON, $pagination: JSON) {\n    paGetAll(params: $params, pagination: $pagination)\n  }\n"): (typeof documents)["\n  query GetAllProjects($params: JSON, $pagination: JSON) {\n    paGetAll(params: $params, pagination: $pagination)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery NameSpecList($search: String) {\n  namespecs(search: $search)\n}\n"): (typeof documents)["\nquery NameSpecList($search: String) {\n  namespecs(search: $search)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery listNameSpecsWithAcceptedStudents($filter: JSON) {\n  listNameSpecsWithAcceptedStudents(filter: $filter)\n}\n"): (typeof documents)["\nquery listNameSpecsWithAcceptedStudents($filter: JSON) {\n  listNameSpecsWithAcceptedStudents(filter: $filter)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ProfsList($search: String) {\n  nameprofs(search: $search)\n}\n"): (typeof documents)["\nquery ProfsList($search: String) {\n  nameprofs(search: $search)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery Query($namespec: [String], $search: String) {\n  listNameProfsWithAcceptedStudents(namespec: $namespec, search: $search)\n}\n"): (typeof documents)["\nquery Query($namespec: [String], $search: String) {\n  listNameProfsWithAcceptedStudents(namespec: $namespec, search: $search)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ProfsListBySpec($input: NameProfsListInput) {\n  nameprofsList(input: $input)\n}\n"): (typeof documents)["\nquery ProfsListBySpec($input: NameProfsListInput) {\n  nameprofsList(input: $input)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetPersons($search: String, $mode: String, $pagination: JSON) {\n  persons(search: $search, mode: $mode, pagination: $pagination)\n}\n"): (typeof documents)["\nquery GetPersons($search: String, $mode: String, $pagination: JSON) {\n  persons(search: $search, mode: $mode, pagination: $pagination)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetTasks($params: JSON) {\n  paTasks(params: $params)\n}\n"): (typeof documents)["\nquery GetTasks($params: JSON) {\n  paTasks(params: $params)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery StagesList($params: JSON){\n  paStages(params: $params)\n}\n"): (typeof documents)["\nquery StagesList($params: JSON){\n  paStages(params: $params)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetProjectApplys($params: JSON, $pagination: JSON){\n  paListProjects(params: $params, pagination: $pagination)\n}\n"): (typeof documents)["\nquery GetProjectApplys($params: JSON, $pagination: JSON){\n  paListProjects(params: $params, pagination: $pagination)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ApplyProject($params: JSON) {\n    paApply(params: $params)\n  }\n"): (typeof documents)["\n  mutation ApplyProject($params: JSON) {\n    paApply(params: $params)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation CreateProject($params: JSON) {\n  paControl(params: $params)\n}\n"): (typeof documents)["\nmutation CreateProject($params: JSON) {\n  paControl(params: $params)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ProjectActivityCreate($params: JSON) {\n    paInitiate(params: $params)\n  }\n"): (typeof documents)["\n  mutation ProjectActivityCreate($params: JSON) {\n    paInitiate(params: $params)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation Modify($params: JSON) {\n  paModify(params: $params)\n}\n"): (typeof documents)["\nmutation Modify($params: JSON) {\n  paModify(params: $params)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddStage($params: JSON) {\n  paChangeStage(params: $params)\n}\n"): (typeof documents)["\nmutation AddStage($params: JSON) {\n  paChangeStage(params: $params)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation DeleteStage($req_id:Int!,$stage_num:Int!,$event:String!) {\n  paDeleteStage(req_id: $req_id, stage_num: $stage_num, event: $event)\n}\n"): (typeof documents)["\nmutation DeleteStage($req_id:Int!,$stage_num:Int!,$event:String!) {\n  paDeleteStage(req_id: $req_id, stage_num: $stage_num, event: $event)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddTask($params: JSON) {\n  paAddTask(params: $params)\n}\n"): (typeof documents)["\nmutation AddTask($params: JSON) {\n  paAddTask(params: $params)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation ChangeTask($params: JSON) {\n  paChangeTask(params: $params)\n}\n"): (typeof documents)["\nmutation ChangeTask($params: JSON) {\n  paChangeTask(params: $params)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation DeleteApply($req_id: [Int]) {\n  paDeleteInitiative(req_id: $req_id)\n}\n"): (typeof documents)["\nmutation DeleteApply($req_id: [Int]) {\n  paDeleteInitiative(req_id: $req_id)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation DeleteProject($params: JSON) {\n  paDeleteProject(params: $params)\n}\n"): (typeof documents)["\nmutation DeleteProject($params: JSON) {\n  paDeleteProject(params: $params)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddComment($params: JSON) {\n  paAddComment(params: $params)\n}\n"): (typeof documents)["\nmutation AddComment($params: JSON) {\n  paAddComment(params: $params)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetComments($params: JSON){\n  paComments(params: $params)\n}\n"): (typeof documents)["\nquery GetComments($params: JSON){\n  paComments(params: $params)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetProject($req_id: Int){\n  paGetProject(req_id: $req_id)\n}\n"): (typeof documents)["\nquery GetProject($req_id: Int){\n  paGetProject(req_id: $req_id)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetTask($task_id: Int){\n  paGetTask(task_id: $task_id)\n}\n"): (typeof documents)["\nquery GetTask($task_id: Int){\n  paGetTask(task_id: $task_id)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery paGetConfig($configName:String,$defaultConfig:JSON){\n  paGetConfig(configName:$configName,defaultConfig:$defaultConfig)\n}\n"): (typeof documents)["\nquery paGetConfig($configName:String,$defaultConfig:JSON){\n  paGetConfig(configName:$configName,defaultConfig:$defaultConfig)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation PaSetConfig($configName:String,$config:JSON){\n  paSetConfig(configName:$configName,config:$config)\n}\n"): (typeof documents)["\nmutation PaSetConfig($configName:String,$config:JSON){\n  paSetConfig(configName:$configName,config:$config)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRecordbook {\n    recordbooks {\n      recordbook_number\n      namespec\n      department\n      eform\n      elevel\n      course\n      nameprof\n      recordbook_file\n      programresults {\n        name\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetRecordbook {\n    recordbooks {\n      recordbook_number\n      namespec\n      department\n      eform\n      elevel\n      course\n      nameprof\n      recordbook_file\n      programresults {\n        name\n        value\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GEtRecordbookDetails($recordbook_number: String){\n  recordbookBySemester(recordbook_number: $recordbook_number)\n}\n"): (typeof documents)["\nquery GEtRecordbookDetails($recordbook_number: String){\n  recordbookBySemester(recordbook_number: $recordbook_number)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetStudentInfo {\n    studentInfo\n  }\n"): (typeof documents)["\n  query GetStudentInfo {\n    studentInfo\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetStudentInfoById($id: String) {\n    studentInfoById(id: $id)\n  }\n"): (typeof documents)["\n  query GetStudentInfoById($id: String) {\n    studentInfoById(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getEmployee {\n    employeesInfo\n  }\n"): (typeof documents)["\n  query getEmployee {\n    employeesInfo\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query IsLogged {\n    isLogged\n  }\n"): (typeof documents)["\n  query IsLogged {\n    isLogged\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendFeedback($subject: String!, $body: String!, $extra:JSON) {\n    uiFeedback(subject: $subject, body: $body, extra: $extra)\n  }\n"): (typeof documents)["\n  mutation SendFeedback($subject: String!, $body: String!, $extra:JSON) {\n    uiFeedback(subject: $subject, body: $body, extra: $extra)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation LogFrontEnd($input:LogFrontendInput!) {\n    utilLogfrontEnd(input:$input)\n  }\n"): (typeof documents)["\n  mutation LogFrontEnd($input:LogFrontendInput!) {\n    utilLogfrontEnd(input:$input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query References {\n    getUserGroups\n  }\n"): (typeof documents)["\n  query References {\n    getUserGroups\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TimetableWithTime($dateNow: String, $limit: Int) {\n    studentTimetableWithTime(dateNow: $dateNow, limit: $limit)\n  }\n"): (typeof documents)["\n  query TimetableWithTime($dateNow: String, $limit: Int) {\n    studentTimetableWithTime(dateNow: $dateNow, limit: $limit)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StudentTimetable(\n    $dateStart: String\n    $dateEnd: String\n    $groups: [String]\n  ) {\n    studentTimetable(\n      dateStart: $dateStart\n      dateEnd: $dateEnd\n      groups: $groups\n      limitOrCountOrAll: 1000\n    )\n  }\n"): (typeof documents)["\n  query StudentTimetable(\n    $dateStart: String\n    $dateEnd: String\n    $groups: [String]\n  ) {\n    studentTimetable(\n      dateStart: $dateStart\n      dateEnd: $dateEnd\n      groups: $groups\n      limitOrCountOrAll: 1000\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TeacherTimetable($dateStart: String, $dateEnd: String, $teacher_guid: String) {\n    teacherTimetable(\n      dateStart: $dateStart\n      dateEnd: $dateEnd\n      teacher_guid: $teacher_guid\n    )\n  }\n"): (typeof documents)["\n  query TeacherTimetable($dateStart: String, $dateEnd: String, $teacher_guid: String) {\n    teacherTimetable(\n      dateStart: $dateStart\n      dateEnd: $dateEnd\n      teacher_guid: $teacher_guid\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StudentTimetableCount($dateStart: String, $dateEnd: String) {\n    studentTimetable(\n      dateStart: $dateStart\n      dateEnd: $dateEnd\n      limitOrCountOrAll: -1\n    )\n  }\n"): (typeof documents)["\n  query StudentTimetableCount($dateStart: String, $dateEnd: String) {\n    studentTimetable(\n      dateStart: $dateStart\n      dateEnd: $dateEnd\n      limitOrCountOrAll: -1\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StudentTimetableYears {\n    studentTimetableYears\n  }\n"): (typeof documents)["\n  query StudentTimetableYears {\n    studentTimetableYears\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ActivateInternetSessionByCurrentUser {\n    activateInternetSessionByCurrentUser {\n      code\n      dateLastLogin\n      intranetStatus\n      ip\n      login\n    }\n  }\n"): (typeof documents)["\n  mutation ActivateInternetSessionByCurrentUser {\n    activateInternetSessionByCurrentUser {\n      code\n      dateLastLogin\n      intranetStatus\n      ip\n      login\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeactivateInternetSessionByCurrentUser{\n    deactivateInternetSessionByCurrentUser {\n      code\n      dateLastLogin\n      intranetStatus\n      ip\n      login\n    }\n  }\n"): (typeof documents)["\n  mutation DeactivateInternetSessionByCurrentUser{\n    deactivateInternetSessionByCurrentUser {\n      code\n      dateLastLogin\n      intranetStatus\n      ip\n      login\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveFile($fileId: String!) {\n      removeFile(fileId: $fileId) {\n        ... on RemoveFileResult {\n          ... on RemoveFile {\n            result\n          }\n          ... on InvalidArgumentError {\n            message\n          }\n          ... on InvalidArgumentError {\n            message\n          }\n        }\n      }\n    }\n"): (typeof documents)["\n  mutation RemoveFile($fileId: String!) {\n      removeFile(fileId: $fileId) {\n        ... on RemoveFileResult {\n          ... on RemoveFile {\n            result\n          }\n          ... on InvalidArgumentError {\n            message\n          }\n          ... on InvalidArgumentError {\n            message\n          }\n        }\n      }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetDownloadUrl($fileId: String!) {\n        getPresignedDownloadUrl(fileId: $fileId) {\n            __typename\n            ... on FileNotFoundError {\n                message\n            }\n            ... on InvalidArgumentError {\n                message\n            }\n            ... on PresignedDownloadUrl {\n                downloadUrl\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetDownloadUrl($fileId: String!) {\n        getPresignedDownloadUrl(fileId: $fileId) {\n            __typename\n            ... on FileNotFoundError {\n                message\n            }\n            ... on InvalidArgumentError {\n                message\n            }\n            ... on PresignedDownloadUrl {\n                downloadUrl\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetWorkspaceIconsByCurrentUser {\n      getWorkspaceIconsByCurrentUser {\n        iconDescription\n        iconID\n        iconImage\n        iconLink\n        iconName\n        iconSort\n      }\n    }\n"): (typeof documents)["\n  query GetWorkspaceIconsByCurrentUser {\n      getWorkspaceIconsByCurrentUser {\n        iconDescription\n        iconID\n        iconImage\n        iconLink\n        iconName\n        iconSort\n      }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetInternetConnectionStatusByCurrentUser {\n    getInternetConnectionStatusByCurrentUser {\n      code\n      dateLastLogin\n      intranetStatus\n      ip\n      login\n    }\n  }\n"): (typeof documents)["\n  query GetInternetConnectionStatusByCurrentUser {\n    getInternetConnectionStatusByCurrentUser {\n      code\n      dateLastLogin\n      intranetStatus\n      ip\n      login\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query checkFiles($files: [FileEntryInput!]!) {\n    checkFiles(files: $files)\n  }\n"): (typeof documents)["\n  query checkFiles($files: [FileEntryInput!]!) {\n    checkFiles(files: $files)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation FileUploadConfirmation($fileId: String!) {\n    fileUploadConfirmation(fileId: $fileId) {\n      ... on FileUploadConfirmation {\n        result\n      }\n      ... on FileNotFoundError {\n        message\n      }\n      ... on InvalidArgumentError {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation FileUploadConfirmation($fileId: String!) {\n    fileUploadConfirmation(fileId: $fileId) {\n      ... on FileUploadConfirmation {\n        result\n      }\n      ... on FileNotFoundError {\n        message\n      }\n      ... on InvalidArgumentError {\n        message\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation GetUploadUrl($inputFile: InputFile!) {\n        getPresignedUploadURL(inputFile: $inputFile) {\n            ... on PresignedUploadUrlResult {\n                ... on InvalidArgumentError {\n                    __typename\n                    message\n                }\n                ... on PresignedUploadUrl {\n                    __typename\n                    fileId\n                    url\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation GetUploadUrl($inputFile: InputFile!) {\n        getPresignedUploadURL(inputFile: $inputFile) {\n            ... on PresignedUploadUrlResult {\n                ... on InvalidArgumentError {\n                    __typename\n                    message\n                }\n                ... on PresignedUploadUrl {\n                    __typename\n                    fileId\n                    url\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    subscription OnUiNotification {\n        uiNotification\n    }\n"): (typeof documents)["\n    subscription OnUiNotification {\n        uiNotification\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query ListNotification($i:NotificationListInput) {\n      notificationList(input:$i){\n        items{\n          body\n          meta\n          from\n          msg_id\n          status\n          created\n          source\n        }\n      }\n    }\n"): (typeof documents)["\n    query ListNotification($i:NotificationListInput) {\n      notificationList(input:$i){\n        items{\n          body\n          meta\n          from\n          msg_id\n          status\n          created\n          source\n        }\n      }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query NotificationStats {\n        uiNotificationsStats\n    }\n"): (typeof documents)["\n    query NotificationStats {\n        uiNotificationsStats\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation AddNotification($i:UINotificationInput) {\n      notificationCreate(input:$i)\n    }\n"): (typeof documents)["\n    mutation AddNotification($i:UINotificationInput) {\n      notificationCreate(input:$i)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateNotification($message_ids: [Int!]!, $status: NotificationStatus) {\n      notificationUpdateStatus(msg_id: $message_ids, status: $status)\n    }\n"): (typeof documents)["\n    mutation UpdateNotification($message_ids: [Int!]!, $status: NotificationStatus) {\n      notificationUpdateStatus(msg_id: $message_ids, status: $status)\n    }\n"];

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
**/
export function gql(source: string): unknown;

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;
import React from 'react'
import './SectionWrapper.less'


const SectionWrapper = ({
  content,
}: any) => {
  return <section className="SectionWrapper">
    { content }
  </section>
}

export default SectionWrapper

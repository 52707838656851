import './PaymentButtons.less'

function PaymentButtons ({
  buttons,
  handleSimplePay,
}: any) {
  return (
    <div className="payButtons">
      { buttons.map((i: any) => {
        return <button key={ buttons.indexOf(i) } onClick={ () => handleSimplePay() }>{ i.icon }{ i.value }</button>
      }) }
    </div>
  )
}

export default PaymentButtons

import { gql, useMutation, } from '@apollo/client'

const DELETE_ACHIEVEMENT = gql`
  mutation DeleteAchievement($achievement_id: Int) {
    deleteAchievement(achievement_id: $achievement_id)
  }
`


const useDeleteAchievement = () => {
  const [
    onDelete,
  ] = useMutation(DELETE_ACHIEVEMENT)

  const onDeleteHandler = ({
    achievement_id,
  }: any) => onDelete({
    variables: {
      achievement_id,
    },
  })

  return onDeleteHandler
}


export default useDeleteAchievement

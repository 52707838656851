import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { IconBrandCodepen, IconBuildingSkyscraper, IconCircleDotted, IconFlag, IconSchool, IconWorld, } from '@tabler/icons'
import useCreateData22_18 from '../../../gql/hooks/effectiveContractHooks/useCreateData22_18'
import useGetEcAmsThresholdValues from '../../../gql/hooks/effectiveContractHooks/useGetEcAmsThresholdValues'
import useGetEcListData from '../../../gql/hooks/effectiveContractHooks/useGetEcListData'
import useGetHandlerForUpdateEcDataInTable from '../../../gql/hooks/effectiveContractHooks/useGetHandlerForUpdateEcDataInTable'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import CollapseContainer2, { AccordionInnder, } from '../../common/CollapseContainer2/CollapseContainer2'
import { addedDefaultValues, clearAchievementFields, dataAdapterForTable, dataWithThresholdValues, } from '../amsUtils/amsUtils'
import AppealBlock from '../AppealBlock/AppealBlock'
import CardContainer from '../cards/CardContainer'
import ImportTableFromFile from '../ImportTableFromFile/ImportTableFromFile'
import TableContainer from '../TableContainer/TableContainer'
import { tableSource as headerFields_22_13, } from '../constants/tablesFormData/table_22_13'
import { tableSource as headerFields_22_14, } from '../constants/tablesFormData/table_22_14'
import { tableSource as headerFields_22_15, } from '../constants/tablesFormData/table_22_15'
import { tableSource as headerFields_22_16, } from '../constants/tablesFormData/table_22_16'
import { tableSource as headerFields_22_17, } from '../constants/tablesFormData/table_22_17'
import { tableSource as headerFields_22_18, } from '../constants/tablesFormData/table_22_18'


const OtherActivitiesTab = ({
  contentType,
  moderateId,
  dataTypeUpdated,
  setDataTypeUpdated,
  // returnDataForAddNewRow,
  // changeStatusHandlers,
  // returnDataForDisplayRejectReason,
  userRole,
}: any) => {
  const table_22_13 = useGetEcListData(moderateId, 'table_22_13', dataTypeUpdated, setDataTypeUpdated)
  const table_22_14 = useGetEcListData(moderateId, 'table_22_14', dataTypeUpdated, setDataTypeUpdated)
  const table_22_15 = useGetEcListData(moderateId, 'table_22_15', dataTypeUpdated, setDataTypeUpdated)
  const table_22_16 = useGetEcListData(moderateId, 'table_22_16', dataTypeUpdated, setDataTypeUpdated)
  const table_22_17 = useGetEcListData(moderateId, 'table_22_17', dataTypeUpdated, setDataTypeUpdated)
  const table_22_18 = useGetEcListData(moderateId, 'table_22_18', dataTypeUpdated, setDataTypeUpdated)

  // const { onUpdateRowHandler, } = useGetHandlerForUpdateEcDataInTable()
  const thresholdValues = useGetEcAmsThresholdValues()
  const { createData_22_18, isLoading_22_18, } = useCreateData22_18()


  return (
    <CollapseContainer2>
      { /^director-|-admin$|^aup-dms-moder$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconWorld }
          headerText='Число иностранных преподавателей, привлеченных к работе в штат'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_13 }
                // isEditable={ false }
                // popUpControllHandler={ changeStatusHandlers?.popUpControllHandler }
                // getRowData={ changeStatusHandlers?.getRowData }
                headerFields={ headerFields_22_13 }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_13, headerFields_22_13, 'table_22_13', moderateId)),
                    thresholdValues: thresholdValues?.table_22_13,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_22_13?.length && (
                <AppealBlock
                  data={ table_22_13?.at(-1) }
                />
              ) }
            </>
          ) }


          { contentType === 'card' && (
            <CardContainer cardsData={ table_22_13 } cardFields={ clearAchievementFields(headerFields_22_13) } />
          ) }
        </AccordionInnder>
      ) }


      { /^director-|-admin$|^aup-ck-moder$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconFlag }
          headerText='Число преподавателей Университета, приглашенных для преподавания в образовательные, научно-образовательные российские и иностранные организации'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_14 }
                // isEditable={ /-admin$|^aup-ck-moder$/.test(userRole) }
                // popUpControllHandler={ changeStatusHandlers?.popUpControllHandler }
                headerFields={ headerFields_22_14 }
                // getRowData={ changeStatusHandlers?.getRowData }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_14, headerFields_22_14, 'table_22_14', moderateId)),
                    thresholdValues: thresholdValues?.table_22_14,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_22_14?.length && (
                <AppealBlock
                  data={ table_22_14?.at(-1) }
                />
              ) }
            </>
          ) }
        </AccordionInnder>
      ) }


      { /^director-|-admin$|^aup-ck-moder$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconBrandCodepen }
          headerText='Реализация совместных международных образовательных программ'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_15 }
                // isEditable={ /-admin$|^aup-ck-moder$/.test(userRole) }
                // popUpControllHandler={ changeStatusHandlers?.popUpControllHandler }
                headerFields={ headerFields_22_15 }
                // getRowData={ changeStatusHandlers?.getRowData }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_15, headerFields_22_15, 'table_22_15', moderateId)),
                    thresholdValues: thresholdValues?.table_22_15,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_22_15?.length && (
                <AppealBlock
                  data={ table_22_15?.at(-1) }
                />
              ) }
            </>
          ) }
        </AccordionInnder>
      ) }


      { /^director-|-admin$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconSchool }
          headerText='Отношение числа выпускников, трудоустроившихся в течение календарного года, следующего за годом выпуска к общему числу выпускников за календарный год за вычетом выпускников, продолживших обучение по очной форме'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_16 }
                // isEditable={ false }
                headerFields={ headerFields_22_16 }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_16, headerFields_22_16, 'table_22_16', moderateId)),
                    thresholdValues: thresholdValues?.table_22_16,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_22_16?.length && (
                <AppealBlock
                  data={ table_22_16?.at(-1) }
                />
              ) }

              {/*{ /-admin$|^aup-dms-moder$/.test(userRole) && (*/}
              {/*  <ImportTableFromFile importName={ 'table_16_17' } />*/}
              {/*) }*/}
            </>
          ) }
        </AccordionInnder>
      ) }


      { /^director-|-admin$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconBuildingSkyscraper }
          headerText='Отношение числа выпускников, трудоустроившихся по полученной специальности в течение календарного года, следующего за годом выпуска  к общему числу выпускников за календарный год за вычетом выпускников, продолживших обучение по очной форме'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_17 }
                // isEditable={ false }
                headerFields={ headerFields_22_17 }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_17, headerFields_22_17, 'table_22_17', moderateId)),
                    thresholdValues: thresholdValues?.table_22_17,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_22_17?.length && (
                <AppealBlock
                  data={ table_22_17?.at(-1) }
                />
              ) }

              {/*{ /-admin$|^aup-dms-moder$/.test(userRole) && (*/}
              {/*  <ImportTableFromFile importName={ 'table_16_17' } />*/}
              {/*) }*/}
            </>
          ) }
        </AccordionInnder>
      ) }


      { /^director-|-admin$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconCircleDotted }
          headerText='Средний балл рейтинга НПР'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_18 }
                // isEditable={ false }
                headerFields={ headerFields_22_18 }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_18, headerFields_22_18, 'table_22_18', moderateId)),
                    thresholdValues: thresholdValues?.table_22_18,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_22_18?.length && (
                <AppealBlock
                  data={ table_22_18?.at(-1) }
                />
              ) }

              { /-admin$/.test(userRole) && (
                <ButtonSimple
                  variant={ 'alone' }
                  active
                  value='Обновить данные'
                  onClickHandler={ () => createData_22_18() }
                  isLoading={ isLoading_22_18 }
                  isDisabled={ isLoading_22_18 }
                />
              ) }
            </>
          ) }
        </AccordionInnder>
      ) }
    </CollapseContainer2>
  )
}


export default OtherActivitiesTab

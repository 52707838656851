import './TeacherRatingTable.less'
import { Table, Tooltip, } from 'antd'
import PaginationSimple from '../../../common/PaginationSimple/PaginationSimple'
import ChooseField from '../../../common/Fields/ChooseField/ChooseField'
import { useState, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { setTeacherRatingFilterTableRowsLength, } from '../../../../redux/efectiveContractSlices/efectiveContractSlices'
import { IconHelp, } from '@tabler/icons'


const infoText = {
  methodicalActivity: '2.3 (БЕЗ монографий), 2.4;',
  researchActivity: '2.3 (БЕЗ учебных пособий), 2.7, 2.8, 2.9, 2.10, бонусы (руководство диссертацией, рецензирование диссерт.работ);',
  teachingActiviti: '2.5, 2.6;',
  selfDevelopment: '2.1, 2.2, бонусы (защита собст.диссертации, получение степени PhD);',
  expertActiviti: 'бонусы (экспертная деятельность…)',
  inovationActiviti: 'бонусы (участие в стратегических, инновационных и иных проектах)',
}


const TableTitelWithInfo = ({
  title,
  infoText,
}: any) => (
  <>
    <div
      style={ {
        display: 'flex',
        alignItems: 'flex-start',
        paddingRight: '.5rem',
      } }
    >
      { title }
      <Tooltip
        color="#43444D"
        overlayInnerStyle={ {
          borderRadius: '.5rem',
          fontWeight: 700,
        } }
        title={ `Пункты ЭК и отдельные виды достижений, которые формируют рейтинг в этой категории: ${ infoText }` }
      >
        <IconHelp style={ {
          color: '#7671DD',
          height: '2rem',
          width: '2rem',
        } } />
      </Tooltip>
    </div>
  </>
)


const TeacherRatingTable = () => {
  // коменты что бы линтер не ругался, раскоментить когда буду доделывать раздел
  const [
    countPages,
  ] = useState(1)
  const [
    setIsLoading,
  ] = useState(true)
  const [
    page,
    setPage,
  ] = useState(1)
  const [
    setOffset,
  ] = useState(0)

  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const tableRowsLength = useSelector(state => state.efContract.teacherRatingFilter.tableRowsLength)


  const dataSource = [
    {
      key: '1',
      ratingNumber: 1,
      fio: 'Булгаков Игорь Иванович',
      methodicalActivity: 5,
      researchActivity: 5,
      teachingActiviti: 5,
      selfDevelopment: 5,
      expertActiviti: 3,
      inovationActiviti: 3,
      total: 5,
    },
    {
      key: '2',
      ratingNumber: 1,
      fio: 'Булгаков Игорь Иванович',
      methodicalActivity: 5,
      researchActivity: 5,
      teachingActiviti: 5,
      selfDevelopment: 5,
      expertActiviti: 3,
      inovationActiviti: 3,
      total: 5,
    },
    {
      key: '3',
      ratingNumber: 1,
      fio: 'Булгаков Игорь Иванович',
      methodicalActivity: 5,
      researchActivity: 5,
      teachingActiviti: 5,
      selfDevelopment: 5,
      expertActiviti: 3,
      inovationActiviti: 3,
      total: 5,
    },
  ]

  const columns = [
    {
      width: '7rem',
      title: 'Место в рейтинге',
      dataIndex: 'ratingNumber',
      key: 'ratingNumber',
      fixed: 'left',
    },
    {
      width: '10rem',
      title: 'ФИО',
      dataIndex: 'fio',
      key: 'fio',
      fixed: 'left',
    },
    {
      sorter: true,
      width: '12rem',
      title: (
        <TableTitelWithInfo
          title="Методическая деятельность"
          infoText={ infoText.methodicalActivity }
        />
      ),
      dataIndex: 'methodicalActivity',
      key: 'methodicalActivity',
      render: (title: any) => <Tooltip
        mouseEnterDelay={ .5 }
        placement="bottomLeft"
        color="#43444D"
        overlayInnerStyle={ {
          borderRadius: '.5rem',
          fontWeight: 700,
        } }
        title={ 'Методическая деятельность' } >
        { title }
      </Tooltip >,
    },
    {
      sorter: true,
      width: '19rem',
      title: (
        <TableTitelWithInfo
          title="Научно-исследовательская деятельность"
          infoText={ infoText.researchActivity }
        />
      ),
      dataIndex: 'researchActivity',
      key: 'researchActivity',
      render: (title: any) => <Tooltip
        mouseEnterDelay={ .5 }
        placement="bottomLeft"
        color="#43444D"
        overlayInnerStyle={ {
          borderRadius: '.5rem',
          fontWeight: 700,
        } }
        title={ 'Научно-исследовательская деятельность' } >
        { title }
      </Tooltip >,
    },
    {
      width: '14rem',
      sorter: true,
      title: (
        <TableTitelWithInfo
          title="Педагогическая деятельность"
          infoText={ infoText.teachingActiviti }
        />
      ),
      dataIndex: 'teachingActiviti',
      key: 'teachingActiviti',
      render: (title: any) => <Tooltip
        mouseEnterDelay={ .5 }
        placement="bottomLeft"
        color="#43444D"
        overlayInnerStyle={ {
          borderRadius: '.5rem',
          fontWeight: 700,
        } }
        title={ 'Педагогическая деятельность' } >
        { title }
      </Tooltip >,
    },
    {
      width: '13rem',
      sorter: true,
      title: (
        <TableTitelWithInfo
          title="Индивидуальное саморазвитие"
          infoText={ infoText.selfDevelopment }
        />
      ),
      dataIndex: 'selfDevelopment',
      key: 'selfDevelopment',
      render: (title: any) => <Tooltip
        mouseEnterDelay={ .5 }
        placement="bottomLeft"
        color="#43444D"
        overlayInnerStyle={ {
          borderRadius: '.5rem',
          fontWeight: 700,
        } }
        title={ 'Индивидуальное саморазвитие' } >
        { title }
      </Tooltip >,
    },
    {
      width: '12rem',
      sorter: true,
      title: (
        <TableTitelWithInfo
          title="Экспертная деятельность"
          infoText={ infoText.expertActiviti }
        />
      ),
      dataIndex: 'expertActiviti',
      key: 'expertActiviti',
      render: (title: any) => <Tooltip
        mouseEnterDelay={ .5 }
        placement="bottomLeft"
        color="#43444D"
        overlayInnerStyle={ {
          borderRadius: '.5rem',
          fontWeight: 700,
        } }
        title={ 'Экспертная деятельность' } >
        { title }
      </Tooltip >,
    },
    {
      width: '13rem',
      sorter: true,
      title: (
        <TableTitelWithInfo
          title="Инновационная деятельность"
          infoText={ infoText.inovationActiviti }
        />
      ),
      dataIndex: 'inovationActiviti',
      key: 'inovationActiviti',
      render: (title: any) => <Tooltip
        mouseEnterDelay={ .5 }
        placement="bottomLeft"
        color="#43444D"
        overlayInnerStyle={ {
          borderRadius: '.5rem',
          fontWeight: 700,
        } }
        title={ 'Инновационная деятельность' } >
        { title }
      </Tooltip >,
    },
    {
      width: '8rem',
      sorter: true,
      title: 'Всего',
      dataIndex: 'total',
      key: 'total',
      fixed: 'right',
      render: (title: any) => <Tooltip
        mouseEnterDelay={ .5 }
        placement="bottomLeft"
        color="#43444D"
        overlayInnerStyle={ {
          borderRadius: '.5rem',
          fontWeight: 700,
        } }
        title={ 'Всего' } >
        { title }
      </Tooltip >,
    },
  ]


  return <>
    <Table
      scroll={ {
        x: '100%',
      } }
      tableLayout="fixed"
      showSorterTooltip={ false }
      className='effective-contract__teacher-rating-table'
      dataSource={ dataSource }
      // @ts-expect-error TS(2322): Type '({ width: string; title: string; dataIndex: ... Remove this comment to see the full error message
      columns={ columns }
    />

    <br /><br />

    <div className='paginationWrap'>
      <PaginationSimple
        page={ page }
        count={ countPages }
        onChange={ (event: any, newPage: any) => {
          if (page !== newPage) {
            // @ts-expect-error TS(2349): This expression is not callable.
            setIsLoading(true)
          }
          // @ts-expect-error TS(2349): This expression is not callable.
          setOffset((newPage - 1) * tableRowsLength)
          setPage(newPage)
        } }
        style={ { zIndex: 0, } }
      />

      <div className="limitWrap">
        Записей на странице
        <ChooseField
          items={ [
            20,
            40,
            60,
            80,
            100,
          ] }
          value={ tableRowsLength }
          handler={ (data: any) => dispatch(setTeacherRatingFilterTableRowsLength(data)) }
        />
      </div>
    </div>
  </>
}


export default TeacherRatingTable

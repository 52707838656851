import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { IconArrowRampRight, IconCircleDotted, } from '@tabler/icons'
import useGetEcAmsThresholdValues from '../../../gql/hooks/effectiveContractHooks/useGetEcAmsThresholdValues'
import useGetEcListData from '../../../gql/hooks/effectiveContractHooks/useGetEcListData'
import CollapseContainer2, { AccordionInnder, } from '../../common/CollapseContainer2/CollapseContainer2'
import { addedDefaultValues, dataAdapterForTable, dataWithThresholdValues, } from '../amsUtils/amsUtils'
import AppealBlock from '../AppealBlock/AppealBlock'
import TableContainer from '../TableContainer/TableContainer'
import { tableSource as headerFields_22_1, } from '../constants/tablesFormData/table_22_1'
import { tableSource as headerFields_22_2, } from '../constants/tablesFormData/table_22_2'


const CareerGuidanceWorkTab = ({
  contentType,
  moderateId,
  dataTypeUpdated,
  setDataTypeUpdated,
  userRole,
}: any) => {
  const table_22_1 = useGetEcListData(moderateId, 'table_22_1', dataTypeUpdated, setDataTypeUpdated)
  const table_22_2 = useGetEcListData(moderateId, 'table_22_2', dataTypeUpdated, setDataTypeUpdated)

  const thresholdValues = useGetEcAmsThresholdValues()

  return (
    <CollapseContainer2>
      { /^director-|-admin$|^aup-pk-moder$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconArrowRampRight }
          headerText='Количество направлений подготовки, на которые объявлен набор'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_1 }
                // isEditable={ false }
                headerFields={ headerFields_22_1 }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_1, headerFields_22_1, 'table_22_1', moderateId)),
                    thresholdValues: thresholdValues?.table_22_1,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_22_1?.length && (
                <AppealBlock
                  data={ table_22_1?.at(-1) }
                />
              ) }
            </>
          ) }
        </AccordionInnder>
      ) }


      { /^director-|-admin$|^aup-pk-moder$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconCircleDotted }
          headerText='Средний балл ЕГЭ студентов, принятых по результатам ЕГЭ на обучение по очной форме по программам бакалавриата и специалитета'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_2 }
                // isEditable={ false }
                headerFields={ headerFields_22_2 }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_2, headerFields_22_2, 'table_22_2', moderateId)),
                    thresholdValues: thresholdValues?.table_22_2,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_22_2?.length && (
                <AppealBlock
                  data={ table_22_2?.at(-1) }
                />
              ) }
            </>
          ) }
        </AccordionInnder>
      ) }
    </CollapseContainer2>
  )
}


export default CareerGuidanceWorkTab

/**
 * Компонен-карточка, обзначающая намериние добавить какой либо элемент. Как правило распологается первым среди подобных карточек.
 *
 * @param title - Отренерится в виде заголовка под иконкой плюса, примерно по центру карточки.
 * @param className - Внешний стиль, который быдет применен к div-контейнеру самой карточки.
 */


import styles from './CardAddItem.module.less'
import { IconCirclePlus, } from '@tabler/icons'
import { DetailedHTMLProps, HTMLAttributes, } from 'react'
import clsx from 'clsx'


interface CardAddItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string
  className?: string
}


const CardAddItem = ({
  title,
  className,
  ...rest
}: CardAddItemProps) => (
  <div
    className={ clsx(styles.cardAddItem, className) }
    { ...rest }
  >
    <div className={ styles.iconWrapper } >
      <IconCirclePlus className={ styles.icon } />
    </div>

    { title }
  </div>
)


export default CardAddItem

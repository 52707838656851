import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import useCreateNewAchievementFormController from '@/legacy/components/EffectiveContract/Hooks/useCreateNewAchievementFormController/useCreateNewAchievementFormController'
import AddNewAchievementPopup from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/AddNewAchievementPopup'
import { CardAddItem, } from '@/shared'
import { TableCellType, } from '@/legacy/types'
import { useState, } from 'react'


interface AddNewAchievementCardProps {
  dataType: EcTableName
  tableSource: TableCellType[]
  title?: string
}


const AddNewAchievementCard = ({
  dataType,
  tableSource,
  title = 'Добавить достижение',
}: AddNewAchievementCardProps) => {
  const [
    isPopupOpen,
    setIsPopupOpen,
  ] = useState(false)

  const {
    controlledFields,
    resetControlledValues,
    updateControlledField,
  } = useCreateNewAchievementFormController(tableSource.filter(field => !!field.editable))

  return (
    <>
      <CardAddItem
        title={ title }
        onClick={ () => setIsPopupOpen(true) }
      />


      <AddNewAchievementPopup
        dataType={ dataType }
        controlledFields={ controlledFields }
        isPopupOpen={ isPopupOpen }
        handlePopup={ () => {
          setIsPopupOpen(false)
          resetControlledValues()
        } }
        onChange={ updateControlledField }
      />
    </>
  )
}


export default AddNewAchievementCard

import { useMemo, } from 'react'
import './UserAchievementsDirections.less'
import AchivmentCard from '../../AchivmentCard/AchivmentCard'
import usePersonalRatingById from '../../../../gql/hooks/portfolio/usePersonalRatingById'
import useGetSortedAchievementDirections from '../../../../gql/hooks/portfolio/useGetAchievementDirectionsOrder'


function UserAchievementsDirections ({
  id,
  setIsDetails,
}: any) {
  const { directions, } = usePersonalRatingById(id)
  const { sortedDirections, } = useGetSortedAchievementDirections({ directions, })


  const directionsCard = useMemo(() => {
    if (sortedDirections) {
      return sortedDirections
        .filter((achievementDirection: any) => achievementDirection.direction !== 'Работы обучающегося')
        .map(
          (achievementDirection: any, index: any) => (
            <AchivmentCard
              key={ achievementDirection.key ? achievementDirection.key : index }
              currentStudentRating={ { ...achievementDirection, } }
              achievementDirection={ achievementDirection }
              setIsDetails={ () => setIsDetails(achievementDirection) }
              type="stranger"
              style={ { height: '238px', } }
            />
          )
        )
    } return (<>...loading</>)
  }, [
    setIsDetails,
    sortedDirections,
  ])


  return (
    <ul className="cardList">
      { directionsCard }
    </ul>
  )
}


export default UserAchievementsDirections

import { gql, } from '@apollo/client'


export const QUERY_EXPORT_TABLE_1 = gql`
  query ecExportTable_BaseStats{
    ecExportTable_BaseStats
  }
`

export const QUERY_EXPORT_TABLE_1_1 = gql`
  query ecExportTable_1_1{
    ecExportTable_1_1
  }
`

export const QUERY_EXPORT_TABLE_3_5 = gql`
  query ecExportTable_3_5{
    ecExportTable_3_5
  }
`

export const QUERY_EXPORT_TABLE_2_AND_3_ALL = gql`
  query ecExportTable_EffectiveStats_all{
    ecExportTable_EffectiveStats(name:"allStats",mode:ALL)
  }
`

export const QUERY_EXPORT_TABLE_2_AND_3_MONTHLY = gql`
  query ecExportTable_EffectiveStats_mounthly{
    ecExportTable_EffectiveStats(name:"allStats",mode:MONTHLY)
  }
`

export const QUERY_EXPORT_TABLE_2_AND_3_ONETIME = gql`
  query ecExportTable_EffectiveStats{
    ecExportTable_EffectiveStats(name:"allStats",mode:ONETIME)
  }
`

import React from 'react'
import './ThemePopup.less'
import { IconTrash, IconCircleX, } from '@tabler/icons'

const ThemePopup = ({
  type,
  handleClose,
}: any) => {
  return (
    <div className="courseEdit">
      <h2 className="editPopupTitle">Редактировать тему курсовой работы</h2>
      <h3 className="popupTheme">Тема курсовой</h3>
      <p className="popupTheme value">Методы для подсчета средней величины пассивов</p>
      { type === 'students' ? <p className="deleteTheme">Удалить тему по причине несоответствия<IconTrash /></p> : <></> }
      <div className="students">
        <h3>Студенты</h3>
        <ul>
          <li><div className="studentItem">Василенко Алексей Дмитриевич<IconCircleX color="#C5C5C5" /></div></li>
          <li><div className="studentItem">Василенко Алексей Дмитриевич<IconCircleX color="#C5C5C5" /></div></li>
        </ul>
      </div>
      <div style={ {
        display: 'flex',
        alignSelf: 'flex-end',
      } }>
        <button className="close" onClick={ handleClose }>Закрыть</button>
        <button onClick={ handleClose }>Сохранить изменения</button>
      </div>
    </div>
  )
}

export default ThemePopup

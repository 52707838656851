import React, { useEffect, } from 'react'
import CalendarSimple from '@/legacy/components/common/CalendarSimple/CalendarSimple'
import FilterDropDown from '@/legacy/components/common/FilterDropDown/FilterDropDown'
import InputFile from '@/legacy/components/common/InputFile/InputFile'
import InputNumber from '@/legacy/components/common/InputNumber/InputNumber'
import InputTextDeprecated from '@/legacy/components/common/InputTextDeprecated/InputTextDeprecated'
import Persons from '@/legacy/components/common/PersonsInput/PersonsInput'
import SelectFromQueryField from '@/legacy/components/Mfc/FormGenerator/SelectFromQueryField'
import TextArea from '@/legacy/components/common/TextArea/TextArea'
import { format, } from 'date-fns'
import { DebounceInput, } from 'react-debounce-input'
import { IconCircleX, } from '@tabler/icons'
import SwitchToggle from '@/legacy/components/common/Switch/SwitchToggle'
import CheckBoxItem from '@/legacy/components/common/CheckBox/CheckBoxItem'

const FormField = ({
  type,
  index,
  label,
  jsonKey,
  title,
  children,
  items,
  query,
  setPayload,
  payload,
  defaults,
  performerValue,
  setPerformerValue,
  currentField,
  uploadFile,
  setCurrentField,
  multiple,
  sum,
}: {
  type: string;
  index: number;
  label: string;
  jsonKey: string;
  title: string;
  children: any[];
  items: any[];
  query: string;
  setPayload: (...args: any) => void;
  payload: any;
  defaults: any;
  performerValue: string;
  setPerformerValue: (fn: any) => void;
  setCurrentField: (...args: any) => void;
  currentField: string | null;
  uploadFile: (...args: any) => void;
  multiple: string[];
  sum?: number;
}) => {
  const fields: any = {
    text: (key: any, label: any, jsonKey: any) => (
      <InputTextDeprecated
        key={ key }
        defaultHint={ label }
        onChangeValue={ (e: any) => setPayload({
          ...payload,
          [jsonKey]: e,
        }) }
        value={ defaults[jsonKey] ? defaults[jsonKey] : null }
      />
    ),
    select: (
      key: any,
      label: any,
      jsonKey: any,
      title: string,
      children: any,
      items: any,
      query: any
    ) => (items ? (
      <FilterDropDown
        items={ items }
        key={ key }
        onClickHandler={ (i: any) => setPayload({
          ...payload,
          [jsonKey]: i,
        })
        }
        defaultHint={ label }
      />
    ) : (
      <SelectFromQueryField
        label={ label }
        jsonKey={ jsonKey }
        type={ query }
        onClickHandler={ (i: any) => setPayload({
          ...payload,
          [jsonKey]: i.id ? i.id : i.name,
        })
        }
      />
    )),
    number: (key: any, label: any, jsonKey: any) => (
      <InputTextDeprecated
        key={ key }
        defaultHint={ label }
        onChangeValue={ (e: any) => {
          setPayload({
            ...payload,
            [jsonKey]: e,
          })
        } }
        value={ defaults[jsonKey] ? defaults[jsonKey] : null }
      />
    ),

    date: (key: any, label: any, jsonKey: any) => (
      <CalendarSimple
        key={ key }
        styles={ { width: '100%', } }
        placeholder={ label }
        selectRange={ false }
        returnSingle={ (date: any) => setPayload({
          ...payload,
          [jsonKey]: format(new Date(date), 'yyyy-MM-dd'),
        })
        }
      />
    ),
    RangeDate: (key: any, label: any, jsonKey: any) => (
      <CalendarSimple
        key={ key }
        styles={ { width: '100%', } }
        placeholder={ label }
        returnRange={ (range: any) => setPayload({
          ...payload,
          [jsonKey]: `${ format(new Date(range[0]), 'yyyy-MM-dd') } - ${ format(
            new Date(range[0]),
            'yyyy-MM-dd'
          ) }`,
        })
        }
      />
    ),
    person: (key: any, label: any, jsonKey: any) => (
      <div
        className="dateWrap multiple"
        style={ {
          width: '100%',
          margin: 0,
        } }
      >
        <DebounceInput
          required
          onChange={ e => setPerformerValue(e.target.value) }
          debounceTimeout={ 300 }
          type="text"
          placeholder={ label }
          onClick={ () => setCurrentField('исполнитель') }
        />

        { performerValue?.length > 3 && (
          <Persons
            mode="person"
            field="исполнитель"
            setValue="id"
            handler={ (i: any) => setPayload({
              ...payload,
              [jsonKey]: i.id,
            })
            }
            setCurrentField={ () => setCurrentField('исполнитель') }
            currentField={ currentField }
            value={ performerValue }
            clear={ setPerformerValue }
          />
        ) }
      </div>
    ),
    personList: (key: any, label: any, jsonKey: any) => (
      <div
        className="dateWrap multiple"
        style={ {
          width: '100%',
          margin: 0,
        } }
      >
        <span>
          { payload[jsonKey]?.map((i: any) => (
            <div className="specTag" key={ i }>
              { i }
              <IconCircleX
                onClick={ () => setPayload({
                  ...payload,
                  [jsonKey]: payload[jsonKey].filter(
                    (item: any) => item !== i
                  ),
                })
                }
                color="#C5C5C5"
              />
            </div>
          )) }
        </span>
        <DebounceInput
          required
          onChange={ e => setPerformerValue(e.target.value) }
          debounceTimeout={ 300 }
          type="text"
          placeholder={ label }
          onClick={ () => setCurrentField(jsonKey) }
        />

        { performerValue?.length > 3 && (
          <Persons
            mode="person"
            field={ jsonKey }
            setValue="id"
            handler={ (i: any) => setPayload({
              ...payload,
              [jsonKey]: payload[jsonKey]
                ? [
                  ...payload[jsonKey],
                  i.full_name,
                ]
                : [
                  i.full_name,
                ],
            })
            }
            setCurrentField={ () => setCurrentField(jsonKey) }
            currentField={ currentField }
            value={ performerValue }
            clear={ setPerformerValue }
          />
        ) }
      </div>
    ),
    int: (
      key: any,
      label: any,
      jsonKey: any,
      title: string,
      children: any,
      items: any,
      query: any,
      multiple: string[],
      sum?: string[]
    ) => {
      if (multiple) {
        useEffect(() => {
          if (payload[multiple[0]] && payload[multiple[1]]) {
            setPayload({
              ...payload,
              [jsonKey]: payload[multiple[0]] * payload[multiple[1]],
            })
          }
        }, [
          payload[multiple[0]],
          payload[multiple[1]],
          jsonKey,
          multiple,
        ])
      }
      if (sum) {
        useEffect(
          () => {
            if (sum.some(i => payload[i])) {
              setPayload({
                ...payload,
                [jsonKey]: sum.reduce((previos, current) => {
                  return payload[current]
                    ? previos + payload[current]
                    : previos
                }, 0),
              })
            }
          },
          sum.map(i => payload[i])
        )
      }

      return (
        <InputNumber
          key={ key }
          placeholder={
            payload[jsonKey] ? `${ label }: ${ payload[jsonKey] }` : label
          }
          onChangeHandler={ (e: any) => {
            setPayload({
              ...payload,
              [jsonKey]: e,
            })
          } }
          styles={ { width: '100%', } }
        />
      )
    },
    textarea: (key: any, label: any, jsonKey: any) => (
      <TextArea
        key={ key }
        defaultHint={ label }
        withPlaceholder={ true }
        onChangeValue={ (e: any) => setPayload({
          ...payload,
          [jsonKey]: e.target.value,
        })
        }
      />
    ),
    file: (key: any, label: string, jsonKey: string) => (
      <div>
        <InputFile
          key={ key }
          title={ label }
          onChange={ event => uploadFile(event.target.files, jsonKey) }
          isMultiple={ true }
        />
        { payload[jsonKey] && (
          <ul>
            { payload[jsonKey].map((i: any, index: number) => (
              <li key={ index } className="fileName">
                { i.name }
                <IconCircleX
                  color="#7671dd"
                  onClick={ () => setPayload({
                    ...payload,
                    [jsonKey]: payload[jsonKey].filter(
                      (i: any, pos: number) => pos !== index
                    ),
                  })
                  }
                />
              </li>
            )) }
          </ul>
        ) }
      </div>
    ),
    listOfFields: (
      key: any,
      label: string,
      jsonKey: string,
      title: string,
      children: any
    ) => (
      <div className="formGeneratorChildrenWrap">
        <h2>{ title }</h2>
        <ul key={ key } className="formGeneratorListOfFields">
          { children.map((i: any, index: any) => {
            if (!i.dependency || payload[i.dependency]) {
              return fields[i.type](
                index,
                i.label,
                i.jsonKey,
                i.title,
                i.children,
                i.items,
                i.query,
                i.multiple
              )
            }
            return null
          }) }
        </ul>
      </div>
    ),
    switch: (key: any, label: string, jsonKey: string) => (
      <SwitchToggle
        key={ key }
        label={ label }
        changeHandler={ () => setPayload({
          ...payload,
          [jsonKey]: !payload[jsonKey],
        })
        }
        isChecked={ payload[jsonKey] }
      />
    ),
    checkBox: (key: any, label: string, jsonKey: string) => (
      <CheckBoxItem
        key={ key }
        value={ label }
        isChecked={ payload[jsonKey] }
        onClick={ () => setPayload({
          ...payload,
          [jsonKey]: !payload[jsonKey],
        })
        }
      />
    ),
  }

  return fields[type](
    index,
    label,
    jsonKey,
    title,
    children,
    items,
    query,
    multiple,
    sum
  )
}

export default FormField

import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'
import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/app/providers/AppProvider'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputFile from '../../../../common/InputFile/InputFile'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import { defineUserGroupe, moderGroupe_3_4, } from '../../../defineUserGroupe'

const Inputs_3_4 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, } = useAppContext()


  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserType = useSelector(state => state.auth.userType)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)

  const [
    user_ids,
  ] = useState(
    moderGroupe_3_4.some(moderRole => (moderRole === currentRole || moderRole === currentUserType))
      ? moderateId
      : currentUserId
  )


  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification?.(
          'Эффективный контракт',
          (
            moderGroupe_3_4.some(moderRole => (moderRole === currentRole || moderRole === currentUserType))
              ? moderateId
              : currentUserId
          ),
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (id: any, newData: any) => onUpdateRow({
    variables: {
      rec_id: id,
      new_data: newData,
    },
  })


  const [
    projectName,
    setProjectName,
  ] = useState('')
  const [
    category,
    setCategory,
  ] = useState('')
  const [
    userRole,
    setUserRole,
  ] = useState('')
  const [
    documents,
    setDocuments,
  ] = useState([])


  useEffect(() => {
    if (hint === 'edit') {
      setProjectName(defaultValues.projectName)
      setCategory(defaultValues.category)
      setUserRole(defaultValues.userRole)
      // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setDocuments(defaultValues.documents ? [
        ...defaultValues.documents,
      ] : [])
    }
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    setProjectName('')
    setCategory('')
    setUserRole('')
    setDocuments([])
  }


  return <>
    <InputTextDeprecated
      defaultHint="Название проекта"
      value={ projectName }
      onChangeValue={ setProjectName }
    />

    <InputSelectDeprecated
      defaultHint="Категория сложности"
      items={ [
        'I категория сложности',
        'II категория сложности',
        'III категория сложности',
      ] }
      defaultValue={ category }
      withResetValue={ true }
      callBack={ setCategory }
    />

    <InputSelectDeprecated
      defaultHint="Роль"
      items={ [
        'Руководитель проекта',
        'Исполнитель проекта',
      ] }
      defaultValue={ userRole }
      withResetValue={ true }
      callBack={ setUserRole }
    />

    <br />

    <TitleH5>
      Подтверждающие документы
    </TitleH5>

    <InputFile
      onChange={ event => {
        // TODO: сделать upload
        // uploadFiles(event.target.files, documents, setDocuments)
      } }
    />


    <AttachedFiles
      isDeleteFilesAllowed={ true }
      fileList={ documents }
      getIndexFile={ (index: any) => {
        setDocuments(documents.filter((file, fIndex) => fIndex !== index))
      } }
    />

    <br />

    <ButtonSimple
      isDisabled={ (
        projectName.trim().length < 1
        || category.trim().length < 1
        || userRole.trim().length < 1
        || documents.length < 1
      ) }
      value={ hint === 'edit' ? 'Сохранить изменения' : 'Добавить достижение' }
      variant="full-filled"
      onClickHandler={ () => {
        if (hint === 'edit') {
          onUpdateRowHandler(defaultValues.rec_id, {
            projectName: projectName,
            category: category,
            userRole: userRole,
            documents: documents,
            status: 'pending',
            reason: '',
            scores: null,
          })

          fieldsToDefault()
          onClickHandler()
        } else {
          onAddNewEcDataHandler({
            variables: {
              user_ids: [
                user_ids,
              ],
              dataType: 'table_3_4',
              data: {
                projectName: projectName,
                category: category,
                userRole: userRole,
                documents: documents,
                status: 'pending',
                reason: '',
                scores: null,
                isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
              },
            },
          })

          fieldsToDefault()
          onClickHandler()
        };
      } }
    />
  </>
}


export default Inputs_3_4

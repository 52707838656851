import './LinkButton.less'

import { IconPencil, IconNotebook, IconChartBar, IconBackspace, } from '@tabler/icons'

const variants: any = {
  edit: {
    text: 'Редактировать',
    icon: <IconPencil />,
  },
  subjectContent: {
    text: 'Содержание тематики дисциплины',
    icon: <IconNotebook />,
  },
  statistics: {
    text: 'Подробная статистика',
    icon: <IconChartBar />,
  },
  clearFilters: {
    text: 'Очистить фильтры',
    icon: <IconBackspace />,
  },
  resetStatus: {
    text: 'Сбросить статус',
    // icon: <IconBackspace />,
  },
  reject: {
    text: 'Отклонить',
    // icon: <IconBackspace />,
  },
  approve: {
    text: 'Подтвердить',
    // icon: <IconBackspace />,
  },
  exportAchievement: {
    text: 'Выгрузить отдельные достижения',
    icon: <IconChartBar />,
  },
  editAllow: {
    text: 'Кому доступен',
    icon: <IconPencil />,
  },
  rejectReason: {
    text: 'Причина отказа',
    // icon: <IconPencil />,
  },
}

const LinkButton = ({
  type = 'edit',
  onClickHandler,
  style,
  isLoading,
  customType,
  isError,
  errorMessage,
}: any): JSX.Element => {
  return (
    <button
      className='link-button'
      onClick={ onClickHandler }
      style={ style }
      disabled={ isLoading }
    >
      { isLoading
        ? ('загрузка данных...')
        : isError
          ? (
            <span className='link-button__inner-text_error'>
              { errorMessage }
            </span>
          ) : (
            <>
              <span className='link-button__inner-text'>
                { customType ? customType?.text : variants[type].text }
              </span>

              { customType ? customType?.icon : variants[type].icon }
            </>
          ) }
    </button>
  )
}


export default LinkButton

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useEffect, } from 'react'
import StudentReferenceTemplate from '../../ReferenceTemplate/StudentReferenceTemplate'
import { useReferenceList, } from '../../../../gql/hooks/mfc/useReferences'
import { useSelector, useDispatch, } from 'react-redux'
import { Preloader, } from '@/shared'
import { useReferenceChanged, } from '../../../../gql/hooks/mfc/useReferences'
import { updateReference, } from '../../../../redux/Subscriptions/SubscriptionsSlice'


function StudentMfc ({ currentButtons, }: any) {
  const activeStatus = useSelector((state: any) => state.mfc.activeStatus)
  // const isReferences = useSelector((state) => state.mfc.isReferences)
  const refresh = useSelector((state: any) => state.subscriptions.references)

  const useReferenceEvent = () => {
    const dispatch = useDispatch()
    const { error, loading, data, } = useReferenceChanged()
    useEffect(() => {
      if (loading || error) {
        return
      }
      if (!data?.referenceChanged) {
        return
      }
      dispatch(updateReference())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      loading,
      error,
      data,
    ])
  }

  useReferenceEvent()


  const { refetch, data, error, loading, } = useReferenceList(activeStatus.serverStat)
  

  // const dispatch = useDispatch();
  useEffect(() => {
    refetch()
  }, [
    refresh,
    refetch,
  ])

  const ref = useMemo(() => {
    if (data) {
      if (data.references.length < 1) {
        return <h2 className="emptyReferences">У вас еще нет активных заявок</h2>
      }
      return data.references.map((i: any) => <StudentReferenceTemplate key={ data.references.indexOf(i) } data={ i } />)
    }
    if (loading) {
      return <Preloader />
    } if (error) {
      return <div>Error !</div>
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    activeStatus,
  ])


  return (ref)
}

export default StudentMfc

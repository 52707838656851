import { gql } from '@/__generated__'
import { useMutation } from "@apollo/client"

const ACTIVATE_INTERNET_SESSION_BY_CURRENT_USER = gql(`
  mutation ActivateInternetSessionByCurrentUser {
    activateInternetSessionByCurrentUser {
      code
      dateLastLogin
      intranetStatus
      ip
      login
    }
  }
`)

const useActivateInternetSessionByCurrentUser = () => {
  const [activateInternetSession, { data, loading, error }] = useMutation(ACTIVATE_INTERNET_SESSION_BY_CURRENT_USER)

  const activateSession = async () => {
    try {
      return await activateInternetSession()
    } catch (error) {
      console.log(error)
    }
  };
  return { activateSession, data, loading, error }
}

export default useActivateInternetSessionByCurrentUser
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-expect-error TS(2616): 'React' can only be imported by using 'import Reac... Remove this comment to see the full error message
import { React, useMemo, } from 'react'
import { Row, } from 'antd'
import './PersonalForm.less'
import Col from '../Col/Col'
import { useSelector, } from 'react-redux'


function PersonalForm () {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const personalInfo = useSelector(state => state.auth.me)
  const {
    name,
    surname,
    patronymic,
    date_of_birth,
    sex,
    snils,
    inn,
    document_type,
    document_series,
    document_number,
    document_date_issue,
    document_was_issued_by,
    document_department_code,
  } = personalInfo.data


  const personalBlock = useMemo(() => {
    return (
      <>
        <Row gutter={ 30 }>
          <Col space={ 8 } title="Имя" value={ name } />
          <Col space={ 8 } title="Фамилия" value={ surname } />
          <Col space={ 8 } title="Отчество" value={ patronymic } />
        </Row>
        <Row gutter={ 30 }>
          <Col space={ 8 } title="Дата рождения" value={ date_of_birth } />
          <Col space={ 8 } title="Пол" value={ sex } />
          {/* <Col space={8} title="Снилс" value={snils}/> */ }
        </Row>
        {/* <Row gutter={30}>
          <Col space={24} title="ИНН" value={inn}/>
        </Row> */}
        {/* <Row gutter={30}>
          <Col space={24} title="Документ удостоверяющий личность" value={document_type}/>
        </Row> */}
        {/* <Row gutter={30}>
          <Col space={8} title="Серия" value={document_series}/>
          <Col space={8} title="Номер" value={document_number}/>
          <Col space={8} title="Дата выдачи" value={document_date_issue}/>
        </Row> */}
        {/*   <Row gutter={30}>
          <Col space={12} title="Кем выдан" value={document_was_issued_by}/>
          <Col space={12} title="Код подразделения" value={document_department_code}/>
        </Row> */}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    personalInfo,
  ])

  return (personalBlock)
}

export default PersonalForm

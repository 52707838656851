import 'react-quill/dist/quill.snow.css'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import useAddNotificationDraft from '../../../../gql/hooks/moderateNotifications/useAddNotificationDraft'
import useCreateFormNotificationEdit from '../../hooks/useCreateFormNotificationEdit'
import createNotificationDraftBody from '../../utils/createNotificationDraftBody'
import { useAppSelector, } from '@/legacy/redux/hooks'
import { Dispatch, SetStateAction, } from 'react'
import { CreateNotificationFormDates, CreateNotificationFormItem, CreateNotificationFormStudentFilterValue, CreateNotificationFormTargetValue, } from '@/legacy/redux/moderateNotificationsSlice/types'
import { FileData, } from '@/legacy/types'


const isTarget = (field: FieldTypes): field is CreateNotificationFormTargetValue[] => Array.isArray(field)


type FieldTypes = string | boolean | [] | CreateNotificationFormDates | CreateNotificationFormTargetValue[] | CreateNotificationFormStudentFilterValue | FileData[]

type FieldsMap = {
  [key: string]: FieldTypes,
}

export const defineDisableForButton = (fields: CreateNotificationFormItem[]) => {
  const fieldsMap: FieldsMap = {}
  fields.forEach((field: CreateNotificationFormItem) => {
    fieldsMap[field.jsonFieldName] = field.value
  })


  if (!fieldsMap.type) {
    return true
  }

  if (!fieldsMap.title) {
    return true
  }

  if (!fieldsMap.html) {
    return true
  }

  if (
    typeof fieldsMap.publish_dates === 'object'
    && 'from' in fieldsMap.publish_dates
    && 'to' in fieldsMap.publish_dates
  ) {
    if ((!fieldsMap.publish_dates.from) || !fieldsMap.publish_dates.to) {
      return true
    }
  }


  if (isTarget(fieldsMap.target)) {
    if (fieldsMap.target.find((target: CreateNotificationFormTargetValue) => target.item === 'Студенты')?.isActive
      && (
        !fieldsMap?.target?.find(target => target?.item === 'Студенты')?.filter
        && (Array.isArray(fieldsMap.student_list) && fieldsMap?.student_list?.length < 1)
      )
    ) {
      return true
    }


    if (fieldsMap.target.every((targetGroup: CreateNotificationFormTargetValue) => !targetGroup.isActive)) {
      return true
    }
  }

  return false
}


const PopupContent = ({
  onClosePopup,
}: {
  onClosePopup: Dispatch<SetStateAction<boolean>>,
}) => {
  const createNotificationForm = useAppSelector(state => state.moderateNotifications.createNotificationForm)

  const { addNotificationDraftHandler, } = useAddNotificationDraft()
  const onAddNotificationDraftHandler = () => {
    addNotificationDraftHandler({
      body: createNotificationDraftBody({
        formTemplate: createNotificationForm,
      }),
    })
      .then(() => onClosePopup((prev: boolean) => !prev))
  }

  const form = useCreateFormNotificationEdit({ formObject: createNotificationForm, })


  return (
    <>
      { form }

      <ButtonSimple
        variant={ 'full-filled' }
        value={ 'Добавить' }
        onClickHandler={ onAddNotificationDraftHandler }
        isDisabled={ defineDisableForButton(createNotificationForm) }
      />
    </>
  )
}


export default PopupContent

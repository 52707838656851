import { Fragment, useEffect, useState, } from 'react'
import './AddAchivmentPopup.less'
import clsx from 'clsx'
import { useAchievementTypes, } from '../../../gql/hooks/useAchievements'
import InputFile from '../../common/InputFile/InputFile'
import getUnixTime from 'date-fns/getUnixTime'
import useAchievementDirections from '../../../gql/hooks/portfolio/useAchievementDirections'
import InputSelectDeprecated from '../../common/InputSelectDeprecated/InputSelectDeprecated'
import useAddAchievement from '../../../gql/hooks/portfolio/useAddAchievement'
import { useSelector, } from 'react-redux'
import { useGetAchievementTypeInfo, } from '../../../gql/hooks/portfolio/useAchievementTypes'
import AttachedFiles from '../../common/AttachedFiles/AttachedFiles'
import InputTextDeprecated from '../../common/InputTextDeprecated/InputTextDeprecated'
import CalendarSimple from '../../common/CalendarSimple/CalendarSimple'
import { Checkbox, FormControlLabel, } from '@mui/material'
import useUpload from '@/legacy/hooks/useUpload/useUpload'
import { AchievementStatus, } from '@/__generated__/graphql'
import useUpdateAchievement from '@/legacy/gql/hooks/portfolio/useUpdateAchievement'
import { transformPayloadToObject, } from '@/legacy/components/Portfolio/EditPopup/EditPopup'
import useRedirect from '@/legacy/hooks/useRedirect'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'


function AddAchivmentPopup ({
  isOpen,
  setIsOpen,
  category = null,
}: any) {
  const {
    onUploadFileHandler,
  } = useUpload()

  const redirect = useRedirect()

  const [
    direction,
    setDirection,
  ] = useState(null)
  const [
    type,
    setType,
  ] = useState<any>(null)
  const [
    achievementTypes,
    setAchievementTypes,
  ] = useState(null)
  const [
    payload,
    setPayload,
  ] = useState({})

  const [
    isValid,
    setIsValid,
  ] = useState(true)


  // @ts-ignore: Unreachable code error
  const { directions, } = useAchievementDirections()
  // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
  const kind = useAchievementTypes((!!direction?.id && typeof (direction.id) === 'number') ? direction?.id : 8)
  const userRole = useGetRole()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const moderateId = useSelector(state => state?.port?.ratingId?.id)
  // @ts-expect-error TS(2339): Property 'prof' does not exist on type '{}'.
  const educationLevel = useSelector(state => state?.prof?.studentInfo[0]?.elevel)

  useEffect(() => {
    if (kind?.data?.achievementTypes) {
      setAchievementTypes(kind?.data?.achievementTypes)
    }
  }, [
    kind,
  ])


  useEffect(() => {
    if (!!category?.name && !!category?.key) {
      setDirection(
        {
          // @ts-expect-error TS(2345): Argument of type '{ id: any; key: any; name: any; ... Remove this comment to see the full error message
          id: category?.key,
          key: category?.key,
          name: category?.name,
          value: category?.name,
        }
      )
    }
  }, [
    category?.key,
    category?.name,
  ])


  const onClose = () => {
    setType(null)
    setPayload({})
    setIsOpen(!isOpen)
  }

  const [
    isSubmitDisabled,
    setIsSubmitDisabled,
  ] = useState(false)

  const [
    isLoading,
    setIsLoading,
  ] = useState(false)


  // @ts-expect-error TS(2339): Property 'key' does not exist on type 'never'.
  const { basicStatus, refetch: basicRefetch, } = useGetAchievementTypeInfo(direction?.key, type?.key)
  const [
    addAchievement,
  ] = useAddAchievement()
  const updateAchievement = useUpdateAchievement()

  function handleAddAchivment () {
    setIsSubmitDisabled(true)
    setIsLoading(true)

    const fields = Object.values(payload) as { [key: string]: unknown }[]

    const defineUserpoints = () => {
      const scores = fields.map((field: any) => {
        if (/^data$|^text$|^file$/.test(field.type)) {
          return Number(field?.scores?.amount || 0)
        }

        if (field.type === 'select') {
          return Number(field.options[field.value]?.scores?.amount || 0)
        }

        if (field.type === 'two-level-select') {
          // @ts-expect-error TS(2538): Type 'any[]' cannot be used as an index type.
          return Number(field.levels[[
            field.value[0],
          ]].options[field.value[1]]?.scores?.amount || 0)
        }

        return 0
      })

      const sumScores = scores.reduce((previousValue: any, currentValue: any) => previousValue + currentValue, 0)

      return sumScores
    }


    addAchievement({
      variables: {
        input: {
          header: {
            type_key: type.key,
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            direction_key: direction.key,
            userpoints: defineUserpoints(),
            status: basicStatus ? AchievementStatus.Notavailable : AchievementStatus.Available,
          },
          fields: [
            {},
          ],
          user_id: (/^student/.test(userRole)) ? null : moderateId,
        },
      },
    })
      .then(data => {
        const currentData = data.data?.createAchievement[0]
        const userId = currentData.id
        const directionId = currentData.catid
        const typeId = currentData.type_id
        const achievementId = currentData.achievement_id

        const category = `portfolio/achievements/${ String(userId) }/${ String(directionId) }/${ String(typeId) }/${ String(achievementId) }`

        const allFilesFields = fields.filter(field => field.type === 'file')

        Promise.all(allFilesFields.map(document => onUploadFileHandler(document.value as File[], category)
          .then(answer => {
            const currentField = fields.find(field => field.field === document.field)
            if (currentField) {
              currentField.value = answer
            }
          })
        ))
          .then(() => {
            updateAchievement({
              achievement_id: achievementId,
              fields: transformPayloadToObject(fields),
            })
          })
          .then(() => onClose())
          .then(() => redirect('/portfolio'))
      })
  }


  const renderForm = (arr: any) => {
    return arr.map((item: any, index: any) => {
      if (item.type === 'data') {
        return (
          <Fragment key={ index }>
            <p>{ item.name }</p>

            <FormControlLabel
              control={ (
                <Checkbox
                  onChange={ (event, isChecked) => {
                    return setPayload({
                      ...payload,
                      [item.name]: {
                        ...item,
                        value: {
                          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                          ...payload[item?.name]?.value,
                          isWithoutDay: isChecked,
                        },
                      },
                    })
                  } }
                />
              ) }
              label="Без даты"
            />

            <CalendarSimple
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              isWithoutDay={ payload[item?.name]?.value?.isWithoutDay }
              withBorder
              placeholderColor={ '#7f8190' }
              selectRange={ false }
              styles={ {
                marginBottom: `20px`,
                height: '53px',
              } }
              returnSingle={ (value: any) => {
                return setPayload({
                  ...payload,
                  [item.name]: {
                    ...item,
                    value: getUnixTime(value),
                  },
                })
              } }
            />
          </Fragment>
        )
      }
      if (item.type === 'text') {
        return (
          <Fragment key={ index }>
            <InputTextDeprecated
              styles={ { fontSize: '1.05rem', } }
              defaultHint={ item.name }
              withPlaceholder
              isAutosizeArea
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              value={ (payload !== null && payload[item?.name]?.value) ? payload[item?.name]?.value : '' }
              isResetLocalValue={ type?.name }
              onChangeValue={ (value: any) => {
                setPayload({
                  ...payload,
                  [item.name]: {
                    ...item,
                    value: value,
                  },
                })
                setIsValid(value !== '')
              } }
            />
            {!isValid && <div style={{ color: 'red', }}>Значение обязательно для заполнения</div>}
            <br />
          </Fragment>
        )
      }
      if (item.type === 'file') {
        const isEducationLevelMatch = !item.required?.elevel?.[0] || item.required.elevel[0] === educationLevel
        const content = (
          <div key={ index }>
            <p className="fileTitle">
              { item?.name }
            </p>

            <InputFile
              onChange={ event => {
                setPayload({
                  ...payload,
                  [item.name]: {
                    ...item,
                    value: Array.from(event.target.files || []),
                  },
                })
              } }
              accept='.pdf,.zip,.jpeg'
            />


            <AttachedFiles
              isDeleteFilesAllowed
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              fileList={ payload?.[item.name]?.value.map((file: any) => ({
                ...file,
                name: file.name,
              })) }
              getIndexFile={ (returnedIndex: any) => {
                setPayload({
                  ...payload,
                  [item.name]: {
                    ...item,
                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    value: payload?.[item.name]?.value.filter((file: any, index: any) => index !== returnedIndex),
                  },
                })
              } }
            />
          </div>
        )
        return isEducationLevelMatch ? content : false
      }
      if (item.type === 'two-level-select') {
        return (
          <Fragment key={ index }>
            <p className="fileTitle">
              { item?.name }
            </p>

            <InputSelectDeprecated
              className='full-filled'
              defaultHint={ item.name }
              items={ item?.levels.map((option: any) => option.name) }
              callBack={ (value: any) => setPayload({
                ...payload,
                [item.name]: {
                  ...item,
                  value: [
                    item.levels.findIndex((item: any) => item.name === value),
                    0,
                  ],
                },
              }) }
            />
            <br />

            {
              // @ts-expect-error TS(2550): Property 'values' does not exist on type 'ObjectCo... Remove this comment to see the full error message
              typeof Object.values(payload)?.find((type: any) => type?.field === item?.field)?.value?.[0] === 'number'
              && (
                <>
                  <InputSelectDeprecated
                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    withResetValue={ String(payload[item.name].value[0]) }
                    className='full-filled'
                    // @ts-expect-error TS(2550): Property 'values' does not exist on type 'ObjectCo... Remove this comment to see the full error message
                    defaultHint={ item.levels[Object.values(payload)?.find((type: any) => type?.field === item?.field)?.value?.[0]].name }
                    // @ts-expect-error TS(2550): Property 'values' does not exist on type 'ObjectCo... Remove this comment to see the full error message
                    items={ item?.levels[Object.values(payload)?.find((type: any) => type?.field === item?.field)?.value?.[0]].options.map((option: any) => option.name) }
                    callBack={ (value: any) => setPayload({
                      ...payload,
                      [item.name]: {
                        ...item,
                        value: [
                          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                          payload[item.name].value[0],
                          // @ts-expect-error TS(2550): Property 'values' does not exist on type 'ObjectCo... Remove this comment to see the full error message
                          item.levels[Object.values(payload)?.find((type: any) => type?.field === item?.field)?.value?.[0]].options.findIndex((item: any) => item.name === value),
                        ],
                      },
                    }) }
                  />
                  <br />
                </>
              )
            }
          </Fragment>
        )
      }
      return (
        <Fragment key={ index }>
          <InputSelectDeprecated
            className='full-filled'
            defaultHint={ item.name }
            items={ item?.options.map((option: any) => option.name) }
            callBack={ (value: any) => setPayload({
              ...payload,
              [item.name]: {
                ...item,
                value: item.options.findIndex((item: any) => item.name === value),
              },
            }) }
          />
          <br />
        </Fragment>
      )
    })
  }

  const isDisabled = () => {
    const required = type?.jsondata?.find((item: any) => item.required)
    if (isSubmitDisabled) { // проверяем значение isSubmitDisabled
      return true
    }
    if (!required && (Object.keys(payload).length !== type?.jsondata?.length)) {
      return true
    }
    if (required && (required.required?.elevel[0] === educationLevel) && (Object.keys(payload).length !== type?.jsondata?.length)) {
      return true
    }
    if (required && (required.required?.elevel[0] !== educationLevel) && ((Object.keys(payload).length !== type?.jsondata?.length - 1))) {
      return true
    }
    const values = Object.values(payload) as { [key: string]: { [key: string]: unknown } }[]
    const documents = values.find((value: any) => value.type === 'file')?.value as File[] | undefined
    return !!(documents && 'length' in documents && documents.length < 1)
  }

  return (
    <div className={ clsx('addAchivePopup', isOpen && 'taskPopAct') }>
      <div
        className=" addAchive"
        style={ { position: 'relative', } }
      >
        <button className="close" onClick={ onClose }>x</button>

        <h2>Добавить достижение</h2>

        <InputSelectDeprecated
          defaultHint="Выберите раздел достижения"
          // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
          defaultValue={ direction ? direction.name : '' }
          items={(/^student/.test(userRole)
              ? directions?.filter((direction: any) => direction.name === 'Работы обучающегося').map((direction: any) => direction.name)
              : directions?.map((direction: any) => direction.name)
          )}
          callBack={ (answer: any) => {
            const selected = directions.find((direction: any) => direction.name === answer)

            setDirection({
              // @ts-expect-error TS(2345): Argument of type '{ id: any; key: any; name: any; ... Remove this comment to see the full error message
              id: selected.key,
              key: selected.key,
              name: selected.name,
              value: selected.name,
            })
          } }
        />

        <br />

        { direction && (
          <InputSelectDeprecated
            defaultValue={ type?.name }
            withResetValue={ true }
            defaultHint="Выберите тип достижения"
            items={
              achievementTypes
                ? achievementTypes
                  // @ts-expect-error TS(2339): Property 'map' does not exist on type 'never'.
                  .map((type: any) => type.name)
                : []
            }
            callBack={ (answer: any) => {
              basicRefetch()

              setPayload({})

              // @ts-expect-error TS(2531): Object is possibly 'null'.
              const selected = achievementTypes.find((type: any) => type.name === answer)
              setType(selected)
            } }
          />
        ) }

        <br />

        { type && (
          <div>
            { renderForm(type?.jsondata) }
          </div>
        ) }

        { isLoading && (
          <p style={ { color: 'red', } }>После нажатия "Добавить достижение" дождитесь полной загрузки файлов.</p>
        ) }

        { type && (
          <button
            onClick={ handleAddAchivment }
            className={ clsx('statusButton buttonActive', isDisabled() && 'disabled') }
            disabled={ isSubmitDisabled || isDisabled() }
          >
            Добавить достижение
          </button>
        ) }
      </div>
    </div >
  )
}


export const hidePostfixName = (fileName: any) => {
  const parts = fileName.split('.')
  const elementForDelete = parts.at(-2)
  const newParts = parts.filter((part: any) => part !== elementForDelete)

  const newFileName = newParts.join('.')


  return newFileName
}


export default AddAchivmentPopup

import { gql, useQuery, } from '@apollo/client'


/* Запросы по каталогам: */
const GET_2_8_CATEGORIES = gql`
  query ecTable_2_8_Categories {
    catalogs(names: ["ecTable_2_8_Categories"])
  }
`

const GET_2_8_ROLES = gql`
  query ecTable2_8_Roles {
    catalogs(names: ["ecTable2_8_Roles"])
  }
`

const GET_ALL_TEACHERS_POSITIONS = gql`
  query TeacherPositions {
    teacherPositions(search: "")
  }
`

const GET_TEACHERS_POSITIONS_FOR_MODERATOR_FILTER = gql`
  query GetModeratorPosFilter {
    catalogs(names: ["ecModeratorPositionsFilter"])
  }
`


export const useGet_2_8_categories = () => useQuery(GET_2_8_CATEGORIES)

export const useGet_2_8_roles = () => useQuery(GET_2_8_ROLES)

export const useGetModeratorPositionsFilter = () => useQuery(
  GET_TEACHERS_POSITIONS_FOR_MODERATOR_FILTER)

export const useGetAllTeachersPositions = () => useQuery(GET_ALL_TEACHERS_POSITIONS)

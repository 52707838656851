import styles from './NumberCell.module.less'
import { IconTrash, } from '@tabler/icons'
import { ReactNode, } from 'react'
import useDeleteEcData from '@/legacy/gql/hooks/effectiveContractHooks/useDeleteEcData'


interface NumberCellProps {
  children: ReactNode
  recId: number
  dataType: string
  isRowEditAvailable: boolean
}


const NumberCell = ({
  children,
  recId,
  dataType,
  isRowEditAvailable,
}: NumberCellProps) => {
  const { onDeleteEcDataHandler, } = useDeleteEcData()


  return (
    <td>
      { children }

      { isRowEditAvailable && (
        <IconTrash
          className={ styles.deleteIcon }
          onClick={ () => onDeleteEcDataHandler({ rec_id: recId, dataType, }) }
        />
      ) }
    </td>
  )
}


export default NumberCell

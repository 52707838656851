import { gql, useQuery, } from '@apollo/client'

const GET_EXPORTED_STUDENTS_DISTRIBUTION = gql`
  query {
    createExcelStudentsDistribution {
      result,
      fileId,
      fileName,
    }
  }
`

export const useGetExportedStudents = () => {
  const { data, loading, error, refetch, } = useQuery(GET_EXPORTED_STUDENTS_DISTRIBUTION)

  return { data,
    loading,
    error,
    refetch, }
}

import CalendarSimple from '@/legacy/components/common/CalendarSimple/CalendarSimple'
import { format, } from 'date-fns'


interface TableCalendarProps {
  cellKey: string
  cellValue: string
  onUpdateRowHandler: Function
  onChangeRowValue: (cellKey: string, newValue: string) => void
}


const TableCalendar = ({
  cellKey,
  cellValue,
  onUpdateRowHandler,
  onChangeRowValue,
}: TableCalendarProps): JSX.Element => (
  <td>
    <CalendarSimple
      display="center"
      selectRange={ false }
      tableView
      defaultSingleDate={ cellValue && format(new Date(String(cellValue)), 'dd.MM.yyyy') }
      returnSingle={ (date: Date) => {
        onChangeRowValue(cellKey, format(new Date(date), 'yyyy-MM-dd'))
        onUpdateRowHandler()
      } }
    />
  </td>
)


export default TableCalendar

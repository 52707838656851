import { useEffect, useMemo, } from 'react'
// import { useNavigate } from "react-router-dom"
// import { ADS_ROUTE } from '../../../../Utils/routes'
import { Carousel, } from 'antd'

import './ShortAds.less'

import ArrowClick from './Arrows/Arrow'
import ArrowToRightClick from './Arrows/ArrowToRight'
import { useDispatch, useSelector, } from 'react-redux'
import { setAdsActive, setAdsCount, } from '../../../../redux/MainSlices/MainSlice'
import BlockAds from './Block'
import { useGetNews, } from '../../../../gql/hooks/useNews'
import { Preloader, } from '@/shared'

const ShortAds = () => {
  const settings = {
    dots: false,
    arrows: true,
    prevArrow: <ArrowClick />,
    nextArrow: <ArrowToRightClick />,
    slidesToScroll: 2,
    slidesToShow: 2,
    autoplay: true,
  }

  const { data, loading, error, } = useGetNews(0, 8, {
    eventType: 'Announcements',
  })

  const anounces = useMemo(() => {
    if (loading) {
      return <Preloader />
    }
    if (error) {
      return <div>...error..</div>
    } else if (data) {
      return data.newsFeed.items.map((i: any, index: any) => <BlockAds props={ i } key={ index } count={ index } />)
    }
  }, [
    data,
    loading,
    error,
  ])

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const AdsCount = useSelector((state => state.main.AdsCount))
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const AdsActive = useSelector((state => state.main.AdsActive))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setAdsActive(1))
    dispatch(setAdsCount(4))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (AdsActive <= 0) {
      dispatch(setAdsActive(AdsCount))
    }
    if (AdsActive > AdsCount) {
      dispatch(setAdsActive(1))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    AdsCount,
    AdsActive,
  ])

  // const navigate = useNavigate();

  return (
    <div className="ShortAds">
      <div className="Row d-row d-space">
        <h1 className="d-h1">Объявления</h1>

        <div className="Swiper d-row d-center">
          <div className="ResultArr p-20" style={ {
            position: 'absolute',
            top: '-7px',
          } }>{ AdsActive } / { AdsCount }</div>
        </div>
      </div>

      <Carousel { ...settings } className="Carousel">
        { anounces }
      </Carousel>


    </div >
  )
}

export default ShortAds

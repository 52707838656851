import './Contacts.less'
import { IconPhone, IconMail, IconMapPin, } from '@tabler/icons'


function Contacts () {
  return (
    <div className="footerContacts">
      <div className="contactsColumn" style={ { margin: '0 0 0 70px', } }>
        <h2>Контакты</h2>
        <div className="contactWrap">
          <IconPhone size={ 18 } color={ '#7671DD' } />
          <a href="tel:+74752723434">8(4752)72-34-34</a>
        </div>
        <div className="contactWrap">
          <IconPhone size={ 18 } color={ '#7671DD' } />
          <a href="tel:+78002004465">8-800-200-44-65</a>
        </div>
        <div className="contactWrap">
          <IconPhone size={ 18 } color={ '#7671DD' } />
          <a href="tel:*4465">*4465(с мобильных)</a>
        </div>
        <div className="contactWrap">
          <IconMail size={ 18 } color={ '#7671DD' } />
          <a href="mailto:post@tsutmb.ru"><span>post@tsutmb.ru</span></a>
        </div>
        <div className="contactWrap">
          <IconMapPin size={ 18 } color={ '#7671DD' } />
          <a href="https://yandex.ru/maps/?rtext=~52.721088%2C41.446336" target="_blank"><span>г. Тамбов, ул. Интернациональная, 33</span></a>
        </div>
      </div>
      <div className="contactsColumn" style={ { margin: '0 0 0 60px', } }>
        <h2>Тех.поддержка</h2>
        <div className="contactWrap">
          <IconPhone size={ 18 } color={ '#7671DD' } />
          <a href="tel:+78002004465,3">8-800-200-44-65</a>
        </div>
        <div className="contactWrap">
          <IconPhone size={ 18 } color={ '#7671DD' } />
          <a href="tel:+74752723434,3">8(4752)-72-34-34</a>
        </div>
        <h2>Тех.поддержка внутренний номер</h2>
        <div className="contactWrap">
          <IconPhone size={ 18 } color={ '#7671DD' } />
          <a href="tel:+74752723434,46000">6000</a>
        </div>
        <div className="contactWrap">
          <IconMail size={ 18 } color={ '#7671DD' } />
          <a href="mailto:support@tsutmb.ru"><span>support@tsutmb.ru</span></a>
        </div>
      </div>
    </div>
  )
}

export default Contacts

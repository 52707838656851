import React from 'react'
import FilterDropDown from '../FilterDropDown/FilterDropDown'
import { useSelector, } from 'react-redux'


const ChooseSpec = ({
  onClickHandler,
  customWidth,
}: any) => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studentInfo = useSelector(state => state.prof.studentInfo)
  const namesSpec = studentInfo.map((info: any) => info.namespec)

  return (
    <FilterDropDown
      items={ namesSpec }
      defaultHint="Выберите специализацию"
      onClickHandler={ onClickHandler }
      firstIsSelected
      customWidth={ customWidth }
    />
  )
}

export default ChooseSpec

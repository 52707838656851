import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  references: false,
  avatars: false,
  initiates: false,
  applyStatus: false,
  projects: false,
  stageChanged: false,
  taskChanged: false,
  newComment: false,
  notifChanged: false,
  achivmentChanged: false,
  configChanged: false,
  updateDefaultAvatar: false,
  timeLeft: 60,
  isCounting: false,
}

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    updateReference: state => {
      state.references = !state.references
    },
    updateAvatar: state => {
      state.avatars = !state.avatars
    },
    updateInitiate: state => {
      state.initiates = !state.initiates
    },
    updateApplyStatus: state => {
      state.applyStatus = !state.applyStatus
    },
    updateProjects: state => {
      state.projects = !state.projects
    },
    updateStages: state => {
      state.stageChanged = !state.stageChanged
    },
    updateTasks: state => {
      state.taskChanged = !state.taskChanged
    },
    updateComments: state => {
      state.newComment = !state.newComment
    },
    updateNotifications: state => {
      state.notifChanged = !state.notifChanged
    },
    updateAchivments: state => {
      state.achivmentChanged = !state.achivmentChanged
    },
    updateDefaultAvatar: state => {
      state.updateDefaultAvatar = !state.updateDefaultAvatar
    },
    setTimeLeft: (state, action) => {
      state.timeLeft = action.payload
    },
    setIsCounting: (state, action) => {
      state.isCounting = action.payload
    },
  },
})


export const { updateReference, updateAvatar, updateInitiate, updateApplyStatus, updateProjects,
  updateStages, updateTasks, updateComments, updateNotifications, updateAchivments, updateDefaultAvatar, setTimeLeft, setIsCounting, } = subscriptionsSlice.actions

export default subscriptionsSlice.reducer

export const conversationHistory = (history: any) => {
  const historyArray = Object.values(history)

  if (typeof historyArray[0] !== 'string') {
    return historyArray
  }
  const entres = Object.entries(history)
  const result = new Array(entres.length)

  let index = 0
  for (const [
    authorName,
    message,
  ] of entres) {
    result[index] = {
      authorName: defineAutorName(authorName),
      message,
    }

    index++
  }

  return result
}


const defineAutorName = (authorName: any) => {
  if (/author|moderator/.test(authorName)) {
    if (authorName === 'author') {
      return 'Студент'
    }

    if (authorName === 'moderator') {
      return 'Модератор'
    }
  }

  return authorName
}


export const defineIsAnotherAuthor = (allHistory: any, currentIndex: any) => {
  if (allHistory[0]?.authorName !== allHistory?.[currentIndex]?.authorName) {
    return true
  } return false
}

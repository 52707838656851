import React, { useMemo, } from 'react'
import { useProfsList, } from '../../../gql/hooks/useProjects'
import FilterPopup from '../../Projects/FilterPopup/FilterPopup'

function ProfsSearch ({
  value,
  setCurrentField,
  currentField,
  field,
  handler,
  clear,
}: any): JSX.Element {
  const { data, error, loading, } = useProfsList(value)

  const profs = useMemo(() => {
    if (error) {
      return <div>.....errrr</div>
    } else if (loading && !data) {
      return <div>......loading....</div>
    }
    return <FilterPopup setCurrentField={ setCurrentField } isOpen={ field === currentField } handler={ handler } items={ data.nameprofs } clearField={ clear } />


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    data,
    loading,
  ])


  return (profs)
}

export default ProfsSearch

const DisciplineResumeCard = ({
  items,
}: any): JSX.Element => (
  <div className='discipline-detail-card'>
    <h2 className='discipline-detail-card__title'>
      Резюме после изучения дисциплин курса (трека)
    </h2>

    <ol className='discipline-detail-card__list'>
      { items.map((i: any, index: any) => <li key={ index }>{ i }</li>) }
    </ol>
  </div>
)


export default DisciplineResumeCard

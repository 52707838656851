import { useState, } from 'react'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import './CourseWork.less'
import { useSelector, } from 'react-redux'
import { IconChevronUp, IconChevronDown, } from '@tabler/icons'
import TypeFilter from '../TypeFilter/TypeFilter'
import FilterButtons from '../common/FilterButtons/FilterButtons'
import TermCard from './TermCard/TermCard'
import AddTheme from './AddTheme/AddTheme'
import WorkerGroups from './WorkerGroups/WorkerGroups'
import { IconArrowLeft, } from '@tabler/icons'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'

function CourseWork () {
  const [
    isTypes,
    setIsTypes,
  ] = useState(false)
  const [
    filterStatus,
    setFilterStatus,
  ] = useState('Курсовые работы')
  const [
    currentGroup,
    setCurrentGroup,
  ] = useState(null)

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studInfo = useSelector(state => state.prof.studentInfo)

  const role = useGetRole()

  // const [group, setGroup] = useState(studInfo[0].group);
  const [
    spec,
    setSpec,
  ] = useState(studInfo[0]?.namespec)

  return (
    <div className="InnerPage CourseWork">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
          {
            value: 'Учебный процесс',
            path: '/coursework',
          },
        ] }
        loc="Курсовые и дипломные"
      />

      <div className="studentInner">
        <h2 className="profileTitle">Курсовые и дипломные работы</h2>

        { role !== 'student' && !currentGroup ? (
          <WorkerGroups handleGroup={ setCurrentGroup } />
        ) : (
          <>
            <div
              className="tableSet"
              style={ { justifyContent: 'space-between', } }
            >
              { role !== 'student' ? (
                <button
                  onClick={ () => setCurrentGroup(null) }
                  className={ 'getRefBut' }
                >
                  <IconArrowLeft
                    style={ { margin: '0 16px 0 0', } }
                    size={ 24 }
                    color={ '#7671DD' }
                  />
                  Выбрать другую группу
                </button>
              ) : (
                <div
                  className="lectures"
                  onClick={ () => setIsTypes(!isTypes) }
                  style={ { width: '25%', } }
                >
                  { spec }

                  { isTypes ? (
                    <IconChevronUp size={ 20 } />
                  ) : (
                    <IconChevronDown size={ 20 } />
                  ) }

                  <TypeFilter
                    isOpen={ isTypes }
                    type="own"
                    items={ studInfo.map((i: any) => i.namespec) }
                    setStat={ setSpec }
                  />
                </div>
              ) }

              <FilterButtons
                items={ FilterButtons }
                status={ filterStatus }
                onClick={ setFilterStatus }
              />
            </div>

            <TermCard type={ filterStatus } />

            { (role === 'student' || role === 'student-rakus') && <AddTheme /> }
          </>
        ) }
      </div>
    </div>
  )
}

export default CourseWork

import { useMemo, } from 'react'
import { useNameSpecList, } from '../../../gql/hooks/useProjects'
import FilterPopup from '../../Projects/FilterPopup/FilterPopup'

function SpecsList ({
  value,
  setCurrentField,
  currentField,
  field,
  handler,
  clear,
}: any): JSX.Element {
  const { data, error, loading, } = useNameSpecList(value)

  const specs = useMemo(() => {
    if (error) {
      return <div>.....errrr</div>
    } else if (loading && !data) {
      return <div>......loading....</div>
    }
    return (
      <FilterPopup
        setCurrentField={ setCurrentField }
        isOpen={ field === currentField }
        handler={ handler }
        items={ data.namespecs }
        clearField={ clear }
      />
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    data,
    loading,
  ])


  return (specs)
}

export default SpecsList

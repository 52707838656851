export const scolars = [
  {
    name: 'Государственные академические стипендии студентам',
    get: [
      '- отсутствие по итогам промежуточной аттестации оценки «удовлетворительно»',
      ' - отсутствие академической задолженности',
      '- студентам, зачисленным на первый курс на места, финансируемые из средств субсидии на финансовое обеспечение выполнения государственного задания на оказание государственных услуг до первой промежуточной аттестации в минимальном размере, установленным Университетом',
    ],
    finish: [
      '- получение по итогам промежуточной аттестации оценки «удовлетворительно»',
      '- отчисление из университета',
      '- уход в академический отпуск',
    ],
  },
  {
    name: 'Государственные стипендии аспирантам и ординаторам',
    get: [
      '- отсутствие по итогам промежуточной аттестации оценки «удовлетворительно»',
      ' - отсутствие академической задолженности',
      '- аспирантам и ординаторам, зачисленным на первый курс на места, финансируемые из средств субсидии на финансовое обеспечение выполнения государственного задания на оказание государственных услуг до первой промежуточной аттестации в минимальном размере, установленным Университетом',
    ],
    finish: [
      '- получение по итогам оценки промежуточной аттестации «удовлетворительно»',
      '- отчисление из университета',
      '- уход в академический отпуск',
    ],
  },
  {
    name: 'Государственные социальные стипендии студентам',
    get: [
      'Государственная социальная стипендия назначается студентам, являющимся:',
      '-  детьми-сиротами и детьми, оставшимися без попечения родителей',
      '-  лицами из числа детей-сирот и детей, оставшихся без попечения родителей',
      '-  лицами, потерявшими в период обучения обоих родителей или единственного родителя',
      '-  детьми-инвалидами, инвалидами I и II групп',
      '-  инвалидами с детства',
      '-  студентам, подвергшимся воздействию радиации вследствие катастрофы на Чернобыльской АЭС и иных радиационных катастроф, вследствие ядерных испытаний на Семипалатинском полигоне',
      '- студентам, являющимся инвалидами вследствие военной травмы или заболевания, полученных в период прохождения военной службы, и ветеранами боевых действий',
      '- студентам из числа граждан, проходивших в течение не менее трех лет военную службу по контракту в Вооруженных Силах Российской Федерации, во внутренних войсках Министерства внутренних дел Российской Федерации и федеральных государственных органах, в войсках национальной гвардии Российской Федерации и т.д.',
      '- государственная социальная стипендия назначается указанной категории студентов со дня представления в организацию, осуществляющую образовательную деятельность, документа, подтверждающего назначение государственной социальной помощи, на один год со дня назначения указанной государственной социальной помощи.',
    ],
    finish: [
      '- по истечению одного года со дня назначения указанной государственной социальной помощи (для студентов, относящихся к категории лиц, получивших государственную социальную помощь)',
      '- с первого числа месяца, следующего за месяцем прекращения действия основания ее назначения (за исключением категории лиц, получивших государственную социальную помощь)',
      '- с момента отчисления студента из Университета',
    ],
  },
  {
    name: 'Именные стипендии',
    general: [
      'Студентам, обучающимся в Университете могут назначаться именные стипендии, учрежденные органами государственной власти, органами местного самоуправления, юридическими и физическими лицами за счет соответствующих источников финансового обеспечения.',
    ],
    get: [
      '- Решение о представлении конкретных обучающихся на именную стипендию принимает Ученый совет Университета на основании представлений руководителей учебных структурных подразделений. При наличии конкурса среди кандидатов на стипендию отбор обучающихся производится специально назначаемой комиссией Ученого совета.',
      '- Решение о присуждении именной стипендии принимается учредителем соответствующей стипендии.',
      '- Назначение именной стипендии осуществляется приказом по Университету с выплатой из денежных средств, перечисляемых в Университет учредителем стипендии.',
    ],
  },
  {
    name: 'Государственные стипендии слушателям подготовительного отделения',
    get: [
      'Стипендия слушателям подготовительных отделений назначается обучающимся на подготовительном отделении по очной форме обучения за счет средств субсидий на финансовое обеспечение государственного задания.',
      'Назначение стипендии слушателям подготовительных отделений осуществляется на весь период обучения приказом ректора при зачислении на обучение.',
    ],
    finish: [
      'Выплата стипендии слушателям подготовительных отделений прекращается с момента отчисления из Университета.',
    ],
  },
  {
    name: 'Материальная поддержка нуждающимся обучающимся',
    general: [
      'Материальная помощь представляет собой единовременную выплату физическому лицу, находящемуся в трудной жизненной ситуации, либо нуждающемуся в помощи по иному основанию. Претендентами на материальную помощь являются студенты, обучающиеся за счет средств федерального бюджета или с полным возмещение затрат (договор).',
    ],
    get: [
      'Материальная помощь оказывается нуждающимся обучающимся Университета из числа: ',
      '- детей-сирот и детей, оставшихся без попечения родителей',
      '- детей-инвалидов, инвалидов I и II групп, инвалидов с детства',
      '- подвергшихся воздействию радиации вследствие катастрофы па Чернобыльской АЭС и иных радиационных катастроф, вследствие ядерных испытаний па Семипалатинском полигоне',
      '- являющихся инвалидами вследствие военной травмы или заболевания, полученных в период прохождения военной службы, и ветеранами боевых действий',
      '-  имеющих право на получение государственной социальной помощи',
      '-  из многодетных семей',
      '-  из неполных семей (имеющим только одного родителя)',
      '-  имеющих родителей-инвалидов или родителей-пенсионеров',
      '-  имеющих хронические заболевания, а также нуждающихся в приобретении дорогостоящих лекарств или в дорогостоящем лечении',
      '-  беременных студенток, вставших на учет по беременности в лечебно-профилактическом учреждении в установленном порядке',
      '-  студенческих семей, если оба супруга обучаются по очной форме',
      '-  имеющих детей',
      '-  проживающих в общежитии',
      '-  доноров',
      '-  имеющих статус беженца',
      '-  при рождении ребенка',
      '-  имеющих родителей - неработающих пенсионеров',
      '-  вступающих в брак',
      '-  являющихся членами спортивных команд Университета, нуждающимся в усиленном питании и оздоровительных процедурах, витаминизации в период подготовки к соревнованиям',
      '- оказавшихся в трудных жизненных ситуациях в связи со смертью близких родственников, утратой личного имущества в результате чрезвычайных обстоятельств (авария, преступление, пожар, стихийное бедствие и т.д.)',
      '- иных обучающихся, нуждающихся в материальной помощи, в случае наступления обстоятельств, наступление которых ухудшило материальное положение.',
    ],
  },
  {
    name: 'Государственная академическая стипендия в повышенном размере студентам',
    general: [
      'Государственная академическая стипендия в повышенном размере назначается дополнительно студентам очной формы обучения, получающим государственную академическую стипендию.',
    ],
    get: [
      '- студентам, обучающимся по всем основным программам специалитета и бакалавриата с 5-го семестра обучения',
      '- студентам, обучающимся в магистратуре с 3-го семестра обучения',
    ],
  },
  {
    name: 'Стипендия в научно-исследовательской деятельности',
    get: [
      '- отсутствие по итогам промежуточной аттестации оценки «удовлетворительно»',
      ' - отсутствие академической задолженности.',
      '- обучение только на оценки «отлично»',
      '- обучение на оценки «отлично» и имеющим только одну оценку «хорошо»',
      '- обучение на оценки «хорошо» и «отлично»',
      '- достижения в учебной, научно-исследовательской, общественной, культурно-творческой и спортивной деятельности. (В одной или несколько областях)',
    ],
    finish: [
      '- получение по итогам оценки промежуточной аттестации «удовлетворительно»',
      '- отчисление из университета',
      '- уход в академический отпуск',
    ],
  },
  {
    name: 'Стипендия в культурно-творческой деятельности',
    get: [
      '- отсутствие по итогам промежуточной аттестации оценки «удовлетворительно»',
      ' - отсутствие академической задолженности.',
      '- обучение только на оценки «отлично»',
      '- обучение на оценки «отлично» и имеющим только одну оценку «хорошо»',
      '- обучение на оценки «хорошо» и «отлично»',
      '- достижения в учебной, научно-исследовательской, общественной, культурно-творческой и спортивной деятельности. (В одной или несколько областях)',
    ],
    finish: [
      '- получение по итогам оценки промежуточной аттестации «удовлетворительно»',
      '- отчисление из университета',
      '- уход в академический отпуск',
    ],
  },
  {
    name: 'Стипендия в спортивной деятельности',
    get: [
      '- отсутствие по итогам промежуточной аттестации оценки «удовлетворительно»',
      ' - отсутствие академической задолженности.',
      '- обучение только на оценки «отлично»',
      '- обучение на оценки «отлично» и имеющим только одну оценку «хорошо»',
      '- обучение на оценки «хорошо» и «отлично»',
      '- достижения в учебной, научно-исследовательской, общественной, культурно-творческой и спортивной деятельности. (В одной или несколько областях)',
    ],
    finish: [
      '- получение по итогам оценки промежуточной аттестации «удовлетворительно»',
      '- отчисление из университета',
      '- уход в академический отпуск',
    ],
  },
  {
    name: 'Стипендия в учебной деятельности',
    get: [
      '- отсутствие по итогам промежуточной аттестации оценки «удовлетворительно»',
      ' - отсутствие академической задолженности.',
      '- обучение только на оценки «отлично»',
      '- обучение на оценки «отлично» и имеющим только одну оценку «хорошо»',
      '- обучение на оценки «хорошо» и «отлично»',
      '- достижения в учебной, научно-исследовательской, общественной, культурно-творческой и спортивной деятельности. (В одной или несколько областях)',
    ],
    finish: [
      '- получение по итогам оценки промежуточной аттестации «удовлетворительно»',
      '- отчисление из университета',
      '- уход в академический отпуск',
    ],
  },
  {
    name: 'Стипендия в общественной  деятельности',
    get: [
      '- отсутствие по итогам промежуточной аттестации оценки «удовлетворительно»',
      ' - отсутствие академической задолженности.',
      '- обучение только на оценки «отлично»',
      '- обучение на оценки «отлично» и имеющим только одну оценку «хорошо»',
      '- обучение на оценки «хорошо» и «отлично»',
      '- достижения в учебной, научно-исследовательской, общественной, культурно-творческой и спортивной деятельности. (В одной или несколько областях)',
    ],
    finish: [
      '- получение по итогам оценки «удовлетворительно»',
      '- отчисление из университета',
      '- уход в академический отпуск',
    ],
  },
  {
    name: 'Государственная социальная стипендия в повышенном размере',
    general: [
      'Государственная социальная стипендия в повышенном размере студентам первого и второго курсов, обучающимся по образовательным программам высшего образования (программам бакалавриата, программам специалитета)',
    ],
    get: [
      'Государственная социальная стипендия в повышенном размере назначается студентам, являющимся:',
      '-  детьми-сиротами и детьми, оставшимися без попечения родителей',
      '-  лицами из числа детей-сирот и детей, оставшихся без попечения родителей',
      '-  лицами, потерявшими в период обучения обоих родителей или единственного родителя',
      '-  детьми-инвалидами, инвалидами I и II групп',
      '-  инвалидами с детства',
      '-  студентам, подвергшимся воздействию радиации вследствие катастрофы на Чернобыльской АЭС и иных радиационных катастроф, вследствие ядерных испытаний на Семипалатинском полигоне',
      '- студентам, являющимся инвалидами вследствие военной травмы или заболевания, полученных в период прохождения военной службы, и ветеранами боевых действий',
      '- студентам из числа граждан, проходивших в течение не менее трех лет военную службу по контракту в Вооруженных Силах Российской Федерации, во внутренних войсках Министерства внутренних дел Российской Федерации и федеральных государственных органах, в войсках национальной гвардии Российской Федерации и т.д.',
      '- государственная социальная стипендия назначается указанной категории студентов со дня представления в организацию, осуществляющую образовательную деятельность, документа, подтверждающего назначение государственной социальной помощи, на один год со дня назначения указанной государственной социальной помощи.',
    ],
    finish: [
      '- получение по итогам оценки «удовлетворительно»',
      '- отчисление из университета',
      '- уход в академический отпуск',
    ],
  },
  {
    name: 'Стипендия Ученого совета',
    general: [
      'Стипендия Ученого совета назначается студентам, обучающимся по очной форме обучения: - программам среднего профессионального образования (обучающимся за счет средств субсидии на выполнение государственного задания и (или) по договору об образовании на обучение (с полным возмещением затрат на обучение (договорник)); - программам бакалавриата, специалитета, магистратуры по договору об образовании на обучение (с полным возмещением затрат на обучение (договорник)). Стипендия Ученого совета за достижения в общественной деятельности назначается студентам: - обучающимся по образовательным программам среднего профессионального образования 3-го семестра обучения; - обучающимся по всем основным образовательным программам специалитета и бакалавриата с 5-го семестра обучения; - обучающимся по образовательным программам магистратуры с 3-го семестра обучения.',
    ],
    get: [
      '- назначается при наличии только положительных оценок по результатам последней промежуточной аттестации.',
      'Назначается студентам за достижения в:  ',
      '- учебной деятельности',
      '- научно-исследовательской деятельности',
      '- общественной деятельности',
      '- культурно-творческой деятельности',
      '- спортивной деятельности',
    ],
  },
  {
    name: 'Стипендии Президента РФ, Правительства РФ',
    general: [
      'Стипендии Президента Российской Федерации и специальные государственные стипендии Правительства Российской Федерации назначаются обучающимся, достигшим выдающихся успехов в учебной и научной деятельности, в соответствии с положениями, утвержденными Президентом Российской Федерации и Правительством Российской Федерации, на основании соответствующих приказов Минобрнауки России.',
    ],
    get: [
      'Кандидаты на получение стипендий Президента РФ и Правительства РФ выдвигаются Ученым советом Университета из числа студентов, начиная с третьего курса по программам подготовки бакалавриата и специалистета и 2 курса по программам подготовки магистратуры и аспирантуры.',
    ],
  },
]

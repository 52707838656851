import styles from './InputFile.module.less'
import clsx from 'clsx'
import { ReactComponent as IconDocument, } from './images/document.svg'
import { IconCirclePlus, } from '@tabler/icons'
import { ChangeEvent, } from 'react'
import useUpload from '@/legacy/hooks/useUpload/useUpload'


interface InputFileProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  /** @deprecated styles - не использовать никогда, накидывай нужные стили через className */
  styles?: object,
  title?: string,
  isMultiple?: boolean,
  className?: string,
  isDisabled?: boolean,
  accept?: string
}


const InputFile = ({
  title,
  isMultiple,
  className,
  isDisabled,
  onChange,
  accept,
  styles: style,
}: InputFileProps) => {
  const {
    InputFile,
  } = useUpload()


  return (
    <label
      className={ clsx(styles.lable, {
        [styles.lable_disabled]: isDisabled,
      }, className) }
      onDragEnter={ event => event.preventDefault() }
      onDragOver={ event => event.preventDefault() }
      style={ style }
    >
      <div className={ styles.contentWrapper }>
        <span className={ styles.title }>
          <IconDocument className={ styles.documentIcon } />

          { title || 'Добавить документ' }
        </span>

        <IconCirclePlus className={ styles.plusIcon } />
      </div>

      <InputFile
        onChange={ onChange }
        disabled={ isDisabled }
        hidden
        multiple={ isMultiple }
        accept={ accept }
      />
    </label>
  )
}


export default InputFile

import { IconCircleCheck, } from '@tabler/icons'
import { IconEyeOff, } from '@tabler/icons'
import clsx from 'clsx'
import styles from './StatusBlock.module.less'

const StatusBlock = ({ isActive, }: any) => {
  return (
    <div
      className={ clsx(styles.block, {
        [styles.block_isActive]: isActive,
      }) }
    >
      { isActive && (
        <>
          <IconCircleCheck className={ styles.icon } />
          Опубликован
        </>
      ) }

      { !isActive && (
        <>
          <IconEyeOff className={ styles.icon } />
          Скрыт
        </>
      ) }
    </div>
  )
}

export default StatusBlock

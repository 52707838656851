import './AddAchievement.less'

import { Link, } from 'react-router-dom'
import { EFFECTIVE_CONTRACT_ROUTE, } from '@/shared/constants/routes'

import {
  IconArrowLeft,
} from '@tabler/icons'

import ButtonSimple from '../common/ButtonSimple/ButtonSimple'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'

import BasicIndicators from './achievmentGroups/BasicIndicators/BasicIndicators'
import HightPerformansIndicators from './achievmentGroups/HightPerformansIndicators/HightPerformansIndicators'
import { useEffect, useState, } from 'react'
import Popup from '../common/Popup/Popup'
import TeacherSearch from './Hooks/TeacherSearch/TeacherSearch'
import AttachedParticipants from './AttachedParticipants/AttachedParticipants'
import { useUpdateEcData, } from '../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import PopupContentReject from './popupContent/PopupContentReject/PopupContentReject'
import PopupContentCreateOrEdit from './popupContent/PopupContentCreateOrEdit/PopupContentCreateOrEdit'
import BonusPointsIndicators from './achievmentGroups/BonusPointsIndicators/BonusPointsIndicators'
import InputTextDeprecated from '../common/InputTextDeprecated/InputTextDeprecated'
import _ from 'lodash'


const AddAchievement = () => {
  const [
    isPopupOpen,
    setIsPopUpOpen,
  ] = useState(false)
  const [
    popUpType,
    setPopUpType,
  ] = useState(null)
  const [
    popUpData,
    setPopUpData,
  ] = useState<any>(null)


  return (
    <div className="effective-contract-add-chievement">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
          {
            value: 'Эффективный контракт',
            path: '/effective-contract',
          },
        ] }
        loc="Добавить достижение"
      />

      <Link
        style={ {
          color: 'inherit',
          marginTop: '1rem',
          width: 'fit-content',
        } }
        to={ EFFECTIVE_CONTRACT_ROUTE }>
        <ButtonSimple value={ buttonElement } />
      </Link>

      <h2 className="effective-contract-add-chievement__title">
        Эффективный контракт
      </h2>

      <BasicIndicators />

      <br /><br /><br />


      <HightPerformansIndicators />

      <br /><br /><br />

      <BonusPointsIndicators />


      <Popup
        isOpen={ isPopupOpen }
        handlePopup={ () => setIsPopUpOpen(false) }
        withScroll
      >
        <>
          {
            popUpType === 'addManyParticipants'
              ? (
                <AddManyParticipants data={ popUpData } setIsPopUpOpen={ setIsPopUpOpen } />
              )
              : popUpType === 'displayRejectReason'
                ? (
                  <PopupContentReject title={ popUpData.achievement } reason={ popUpData.reason } onClickHandler={ () => setIsPopUpOpen(false) } />
                )
                : popUpType === 'addNewRow'
                  ? <PopupContentCreateOrEdit onClickHandler={ () => setIsPopUpOpen(false) } defaultValues={ { tableName: popUpData.tableName, } } />
                  : popUpType === 'addOneParticipant'
                    ? (
                      <AddOneParticipant data={ popUpData } setIsPopUpOpen={ setIsPopUpOpen } />
                    ) : false }
        </>
      </Popup>
    </div>
  )
}


export const AddManyParticipants = ({
  data,
  setIsPopUpOpen,
}: any) => {
  const [
    participants,
    setParticipants,
  ] = useState('')
  const [
    otherAuthors,
    setOtherAuthors,
  ] = useState('')

  const [
    ids,
    setIds,
  ] = useState(
    data?.data?.user_ids && data?.data?.user_ids.length > 0
      ? data?.data?.user_ids
      : data.data.authors.length > 0
        ? data.data.authors.map((item: any) => item.guid).filter((id: any) => id !== null)
        : []
  )

  const [
    authors,
    setAuthors,
  ] = useState(data.data.authors ? data.data.authors : [])

  useEffect(() => {
    setParticipants('')

    setIds(
      data?.data?.user_ids && data?.data?.user_ids.length > 0
        ? data?.data?.user_ids
        : data.data.authors.length > 0
          ? data.data.authors.map((item: any) => item.guid).filter((id: any) => id !== null)
          : []
    )
    setAuthors(data.data.authors ? data.data.authors : [])
  }, [
    data,
  ])


  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (rec_id: any, newData: any, user_ids: any) => {
    const newFilteredData = _.omitBy(newData, (value: any, key: any) => (key === 'user_ids' || key === 'creator_id'))

    return onUpdateRow({
      variables: {
        rec_id: rec_id,
        new_data: newFilteredData,
        user_ids: user_ids,
      },
    })
  }

  return <>
    <TeacherSearch
      defaultHint="Добавьте участников по их ФИО"
      value={ participants }
      onChangeValue={ setParticipants }
      handler={ (answer: any) => {
        if (!ids.find((id: any) => id === answer.guid)) {
          setIds([
            ...ids,
            answer.guid,
          ])
        }

        setAuthors([
          ...authors,
          {
            guid: answer.guid,
            fio: answer.fio,
          },
        ])
      } }
    />

    <br />

    <InputTextDeprecated
      defaultHint="Прочие авторы (не сотрудники Державинского университета, перечислить через запятую)"
      value={ otherAuthors }
      onChangeValue={ setOtherAuthors }
      onBlur={ () => {
        setAuthors([
          ...authors,
          ...otherAuthors.split(',').map(authorName => {
            return {
              fio: authorName.trim(),
              guid: null,
            }
          }).filter(author => author.fio.length > 0),
        ])

        setOtherAuthors('')
      } }
    />

    <br />

    <AttachedParticipants
      participantsList={ authors.map((author: any) => author.fio) }
      getName={ (name: any) => {
        const removeId = authors.find((item: any) => item.fio === name).guid

        setIds(ids.filter((item: any) => item !== removeId))

        if (removeId === null) {
          setAuthors(authors.filter((item: any) => item.fio !== name))
        } else {
          setAuthors(authors.filter((item: any) => item.guid !== removeId))
        }
      } }
    />

    <br /><br />
    <div style={ {
      display: 'flex',
      justifyContent: 'flex-end',
    } }>
      <ButtonSimple
        value="Подтвердить"
        variant="alone"
        active
        isDisabled={ authors.length < 1 }
        onClickHandler={ () => {
          const newIds = () => {
            if (data.headOfDeapartmentId) {
              return [
                data.headOfDeapartmentId,
                ...ids.filter((id: any) => id !== data.headOfDeapartmentId),
              ]
            } return ids
          }


          onUpdateRowHandler(
            data.data.rec_id,
            {
              ...data.data,
              authors: authors,
            },
            newIds()
          )

          setIsPopUpOpen(false)
        } }
      />
    </div>
  </>
}


export const AddOneParticipant = ({
  data,
  setIsPopUpOpen,
}: any) => {
  const [
    participants,
    setParticipants,
  ] = useState<any>([])
  const [
    searchValue,
    setSearchValue,
  ] = useState('')
  const [
    user_ids,
    setUser_ids,
  ] = useState([])

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (rec_id: any, newData: any, user_ids: any) => onUpdateRow({
    variables: {
      rec_id: rec_id,
      new_data: newData,
      user_ids: user_ids,
    },
  })

  return <>
    { participants.length < 1 ? (
      <TeacherSearch
        defaultHint="Выберите нового участника по его ФИО"
        value={ searchValue }
        onChangeValue={ setSearchValue }
        handler={ (answer: any) => {
          setParticipants([
            answer.fio,
          ])

          if (data.headOfDeapartmentId && data.headOfDeapartmentId !== answer.guid) {
            setUser_ids([
              // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
              data.headOfDeapartmentId,
              // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
              answer.guid,
            ])
          } else {
            setUser_ids([
              // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
              answer.guid,
            ])
          }
        } }
      />
    ) : false }

    <br />

    <AttachedParticipants
      participantsList={ participants }
      getName={ (name: any) => {
        setParticipants(participants.filter((participant: any) => participant !== name))
        setUser_ids([])
      } }
    />

    <br /><br />
    <div style={ {
      display: 'flex',
      justifyContent: 'flex-end',
    } }>
      <ButtonSimple
        value="Подтвердить"
        variant="alone"
        active
        isDisabled={ participants.length < 1 }
        onClickHandler={ () => {
          onUpdateRowHandler(
            data.data.rec_id,
            {
              ...data.data,
              teacherName: participants[0],
            },
            user_ids
          )

          setIsPopUpOpen(false)
        } }
      />
    </div>
  </>
}


const buttonElement = (
  <div className="effective-contract-add-chievement__button-back">
    <IconArrowLeft
      className="effective-contract-add-chievement__button-back_accent"
    />
    <span>Вернуться к моим достижениям</span>
  </div>
)


export default AddAchievement

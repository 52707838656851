import { memo, } from 'react'
import ReactQuill from 'react-quill'
import styles from './TextEditor.module.less'


/**
 * Документация по настройке тулбара:
 * https://quilljs.com/docs/modules/toolbar/
 */
const modulesSettings = {
  toolbar: [
    [
      {
        'header': [
          // 1,
          2,
          3,
          4,
          false,
        ],
      },
    ],
    // [{ 'font': [] }],
    [
      { 'align': [], },
    ],
    [
      'bold',
      'italic',
      'underline',
      // 'strike',
      // 'blockquote',
    ],
    [
      { 'list': 'ordered', },
      { 'list': 'bullet', },
      { 'indent': '-1', },
      { 'indent': '+1', },
    ],
    [
      { 'color': [], },
      { 'background': [], },
    ],
    [
      'link',
      // 'image',
    ],
    [
      'clean',
    ],
  ],
}


const TextEditor = ({
  value,
  setValue,
}: any) => {
  return (
    <ReactQuill
      className={ styles.quillContainer }
      theme='snow'
      value={ value }
      onChange={ setValue }
      modules={ modulesSettings }
    />
  )
}


export default memo(TextEditor)

import { useState, useMemo, useEffect, useRef, ChangeEventHandler, ChangeEvent, } from 'react'
import './CommentsTask.less'
import { IconPaperclip, IconFile, IconMessagePlus, IconX, } from '@tabler/icons'
import clsx from 'clsx'
import { useGetComments, useAddComment, } from '../../../../gql/hooks/useProjects'
import Image from '../../../common/Image/Image'
import { useSelector, } from 'react-redux'
import { downloadFile, } from '../../../../hooks/useDownloadFile'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import useUpload from '@/legacy/hooks/useUpload/useUpload'
import { Alert, Snackbar, } from '@mui/material'
import { useAvatarQuery, } from '@/legacy/redux/utilsApi/imageApi'


const addFiles = (oldFiles: File[] | null, newFiles: File[]): File[] => {
  if (oldFiles) {
    return [
      ...oldFiles,
      ...newFiles.filter(newFile => oldFiles.every(oldFile => oldFile.name !== newFile.name)),
    ]
  } return newFiles
}


function CommentsTask ({
  task,
}: any) {
  const category = `project/${ task.req_id }/${ task.stage_id }/${ task.task_id }/comments`

  const [
    activeComment,
    setActiveComment,
  ] = useState(null)
  const [
    text,
    setText,
  ] = useState('')
  const [
    files,
    setFiles,
  ] = useState<File[] | null>(null)

  const formRef = useRef<HTMLFormElement | null>(null)

  const [
    paAddComment,
  ] = useAddComment()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const me = useSelector(state => state.auth.me)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const updateComments = useSelector(state => state.subscriptions.newComment)


  const {
    InputFile,
    onCheckFilesHandler,
    isFilesExists,
    setIsFilesExists,
    onUploadFileHandler,
  } = useUpload()


  const onPreloadFiles: ChangeEventHandler<HTMLInputElement> = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files


    if (fileList) {
      onCheckFilesHandler(fileList, category)
        .then(isAllowedPreloadFiles => {
          if (isAllowedPreloadFiles) {
            setFiles(addFiles(files, Array.from(fileList)))
          }
        })
    }
  }


  function addComment (parrent: any) {
    setText('')

    if (formRef.current) {
      formRef.current.reset()
    }

    if (files) {
      onUploadFileHandler(files, category)
        .then(files => {
          paAddComment({
            variables: {
              params: {
                task_id: task.task_id,
                event: 'commentAdded',
                ref_type: 'task',
                ref_id: task.task_id,
                data: {
                  message: text,
                  name: me.data.full_name,
                  files: files,
                },
                parent_id: parrent,
              },
            },
          })
            .then(() => {
              setFiles(null)
            })
            // eslint-disable-next-line no-console
            .catch(error => console.log('errr', error))
        })
    }
  }


  function Avatar () {
    // @ts-expect-error TS(2554): Expected 1-2 arguments, but got 0.
    const { isLoading, data, error, } = useAvatarQuery()

    return useMemo(() => {
      if (isLoading) {
        return <div>...загрузка</div>
      }
      if (error) {
        return <div>Ошибка</div>
      }
      return <img src={ `data:image/png;base64,${ data }` } alt="avatar" />
    }, [
      isLoading,
      error,
      data,
    ])
  }

  const params = {
    ref_type: 'task',
    ref_id: task.task_id,
  }
  const { refetch, data, error, loading, } = useGetComments(params)

  useEffect(() => {
    refetch()
  }, [
    updateComments,
    refetch,
  ])

  const comments = useMemo(() => {
    if (error) {
      return <div>....error...</div>
    } else if (loading) {
      return <div>.....loading.....</div>
    } else if (data) {
      const reverseComments: [] = JSON.parse(JSON.stringify(data.paComments)).reverse()

      return renderComments(reverseComments)
    }
  }, [
    data,
    error,
    loading,
    renderComments,
  ])


  function handleSubComment (parrent: any) {
    addComment(parrent)
    setActiveComment(null)
    setText('')
  }

  function openAnswer (id: any) {
    setFiles(null)
    setActiveComment(id)
  }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  function renderComments (comments: any) {
    return comments.map((comment: any, index: number) => (
      <div key={ index } className="subComment">
        <Image id={ comment.creator_id } />


        <div>
          <h3>
            { comment.data.name }
            <p>{ comment.date }</p>
          </h3>


          <p>{ comment.data.message }</p>


          <ul>
            { comment.data.files.length > 0 && comment.data.files?.map((file: any) => file && <li key={ file.upload_id } onClick={ () => downloadFile(file) }><p><IconFile size={ 30 } color="#C5C5C5" />{ file.name }</p></li>) }
          </ul>


          <div className={ clsx(comment.comment_id === activeComment && 'subWrap', comment.comment_id !== activeComment && 'subWrapHidden') }>
            <Avatar />


            <form className="subField">
              <textarea onChange={ event => setText(event.target.value) } value={ text } />
              { (files && comment.comment_id === activeComment) && files.map((file: File, index: number) => (
                <div key={ index } className="addedFile"><IconFile color="#7671DD" />
                  { file?.name }

                  <IconX
                    color="#C5C5C5"
                    onClick={ () => setFiles(files.filter((fileItem: File) => file?.name !== fileItem.name)) }
                  />
                </div>
              )) }


              <div className="subButs">
                <label className="attacheBut">
                  <InputFile
                    style={ { display: 'none', } }
                    onChange={ onPreloadFiles }
                    multiple
                  />

                  Прикрепить
                  <IconPaperclip color="#7671DD" />
                </label>


                <button
                  className="attacheBut"
                  type="reset"
                  onClick={ () => {
                    setActiveComment(null)
                    setText('')
                  } }
                >
                  Отменить
                </button>


                <ButtonSimple
                  value="Отправить"
                  variant="alone"
                  onClickHandler={ () => handleSubComment(comment.comment_id) }
                  active
                  isDisabled={ text.length === 0 }
                />
              </div>
            </form>
          </div>


          { comment.comment_id !== activeComment && (
            <p
              className="answerB"
              onClick={ () => openAnswer(comment.comment_id) }
            >
              <IconMessagePlus color="#7671DD" size={ 35 } strokeWidth="1.25" />
              Ответить
            </p>
          ) }


          { comment.childs.length > 0 && renderComments(comment.childs) }
        </div>
      </div>
    )
    )
  }


  return (
    <>
      { data
        ? (<div className="commentsTask">
          <h2>
            Комментарии
            <p
              style={ {
                marginTop: 0,
                marginBottom: 0,
              } }
            >
              { data?.paComments.length }
            </p>
          </h2>


          <form className="mainComment" ref={ formRef } >
            <textarea placeholder='Добавьте текст комментария' onChange={ e => setText(e.target.value) } />


            <div className="mainComButs">
              <>
                { (files && !activeComment) && files.map((file: File, index: number) => (
                  <div key={ index } className="addedFile">
                    <IconFile color="#7671DD" />
                    { file.name }

                    <IconX
                      color="#C5C5C5"
                      onClick={ () => setFiles(files.filter((fileItem: File) => file.name !== fileItem.name)) }
                    />
                  </div>
                )) }


                <label className="attacheBut" style={ { background: 'white', } }>
                  <InputFile
                    style={ { display: 'none', } }
                    onChange={ onPreloadFiles }
                    multiple
                  />

                  Прикрепить
                  <IconPaperclip color="#7671DD" />
                </label>


                <ButtonSimple
                  value="Добавить комментарий"
                  variant="alone"
                  onClickHandler={ () => addComment(-1) }
                  active
                  isDisabled={ !!((text.length === 0) || activeComment) }
                  styles={ { marginRight: '15px', } }
                />
              </>
            </div>
          </form>


          { comments }
        </div>
        ) : (
          <div>loading</div>
        ) }


      <Snackbar open={ isFilesExists }>
        <Alert onClose={ () => setIsFilesExists(undefined) } severity="error">
          Файл с таким именем уже существует!
        </Alert>
      </Snackbar>
    </>
  )
}


export default CommentsTask

import SpecsSearch from '@/legacy/components/EffectiveContract/Hooks/SpecsSearch/SpecsSearch'
import { OnChangeRowValue, } from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableRow/TableRow'


interface TableSpecsSearchProps {
  cellKey: string
  cellValue: string
  onChangeRowValue: OnChangeRowValue
  onUpdateRowHandler: Function
}


const TableSpecsSearch = ({
  cellKey,
  cellValue,
  onChangeRowValue,
  onUpdateRowHandler,
}: TableSpecsSearchProps) => {
  return (
    <td>
      <SpecsSearch
        value={ cellValue }
        onChangeValue={ (data: string) => onChangeRowValue(cellKey, data) }
        handler={ (answer: string) => {
          onChangeRowValue(cellKey, answer)
          onUpdateRowHandler()
        } }
        withListPositionAbsolute
      />
    </td>
  )
}

export default TableSpecsSearch

import './ButtonSimple.less'
import clsx from 'clsx'
import { CircularProgress, } from '@mui/material'
import { memo, MouseEventHandler, } from 'react'

interface ButtonSimpleProps {
  value: string | JSX.Element,
  variant?: 'fill' | 'full-filled' | 'alone' | 'groupe' | 'small-button' | 'transparent',
  onClickHandler?: MouseEventHandler<HTMLButtonElement>,
  active?: boolean,
  isDisabled?: boolean,
  styles?: object,
  isLoading?: boolean,
  className?: string,
}


const ButtonSimple = ({
  value,
  active,
  onClickHandler,
  variant,
  isDisabled = false,
  styles,
  isLoading,
  className,
}: ButtonSimpleProps): JSX.Element => (
  <button
    className={ clsx({
      'button-simple': true,
      'button-simple_active': active,
      'button-simple_filled': variant === 'fill',
      'button-simple_full-filled': variant === 'full-filled',
      'button-simple_alone': variant === 'alone',
      'button-simple_groupe': variant === 'groupe',
      'button-simple_small-button': variant === 'small-button',
      'button-simple_transparent': variant === 'transparent',
      'button-simple_disabled': isDisabled,
    }, className) }
    type="button"
    onClick={ onClickHandler }
    disabled={ isDisabled }
    style={ styles }
  >
    { isLoading
      ? (
        <CircularProgress
          color="secondary"
          style={ {
            height: '1.5rem',
            width: '1.5rem',
          } }
        />
      )
      : value }
  </button>
)


/**
 * Передать в проп value компоненту ButtonSimple
 * @param { Object } params
 * @param { string } params.text - текст кнопки
 * @param { JSX.Element } params.Icon - иконка
 * @param { Object } params.iconStyles - инлайн стили, если нужно
 * @param { 'atLeft' | 'atRight' } params.iconVariant - с какой стороны будет иконка
 * @param { string } params.customClassName - внешний css класс для иконки
 */

interface IconComponentProps {
  text: string
  Icon: any
  /** @deprecated Используй customClassName */
  iconStyles?: Object
  iconVariant?: 'atLeft' | 'atRight'
  customClassName?: string
}

export const IconComponent = ({
  text,
  Icon,
  iconStyles,
  iconVariant = 'atRight',
  customClassName,
}: IconComponentProps) => (
  <span className='button-simple__icon-component'>
    { iconVariant === 'atRight' ? text : false }
    <Icon
      className={
        clsx({
          'button-simple__icon-component-icon': true,
          [String(customClassName)]: customClassName,
        })
      }
      style={ iconStyles }
    />
    { iconVariant === 'atLeft' ? text : false }
  </span>
)


export default memo(ButtonSimple)

import React, { useMemo, } from 'react'
import { useProfsListAccepted, } from '../../../gql/hooks/useProjects'
import FilterPopup from '../../Projects/FilterPopup/FilterPopup'

function ProfsSearchAccepted({
  namespec,
  value,
  setCurrentField,
  currentField,
  field,
  handler,
  clear,
}: any): JSX.Element {
  if (value === ' ') {
    value = null
  }
  const { data, error, loading, } = useProfsListAccepted(namespec, value)
  const profs = useMemo(() => {
    if (error) {
      return <div>.....errrr</div>
    } else if (loading && !data) {
      return <div>......loading....</div>
    }
    return <FilterPopup
      setCurrentField={ setCurrentField }
      isOpen={ field === currentField }
      handler={ handler }
      items={ data.listNameProfsWithAcceptedStudents }
      clearField={ clear }
    />

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    data,
    loading,
  ])


  return (profs)
}

export default ProfsSearchAccepted

import useCheckFilesExists from '@/legacy/hooks/useUpload/hooks/useCheckFilesExists'
import createFilesEntryInput from '@/legacy/hooks/useUpload/utils/createFilesEntryInput'
import { EndPoints, } from '@/shared/constants/endpoints'
import { FileData, } from '@/legacy/types'
import { FileEntryInput, } from '@/__generated__/graphql'
import { useState, } from 'react'
import InputFile from './components/InputFile/InputFile'


const useUpload = () => {
  const [
    isFileLoading,
    setIsFileLoading,
  ] = useState<boolean>(false)

  const [
    isFilesExists,
    setIsFilesExists,
  ] = useState<undefined | boolean>(undefined)

  const [
    filesEntryInput,
    setFilesEntryInput,
  ] = useState<null | FileEntryInput[]>(null)


  const { checkFiles, } = useCheckFilesExists(filesEntryInput)


  const onCheckFilesHandler = (fileList: FileList | File[], category: string) => {
    const files = Array.from(fileList)
    setFilesEntryInput(createFilesEntryInput(files, category))


    return checkFiles().then(data => {
      const isAnyOfFilesExists = data.data?.checkFiles.some((data: { exists: boolean }) => !!data.exists)
      setIsFilesExists(isAnyOfFilesExists)
      const isUploadFilesAllowed = (() => !isAnyOfFilesExists)()

      return isUploadFilesAllowed
    })
  }


  const onUploadFileHandler = (fileList: FileList | File[], category: string) => {
    const files = Array.from(fileList)
    const formData = new FormData()

    setIsFileLoading(true)

    files.forEach(file => formData.append(category, file))


    return fetch(`${ EndPoints.upload }?strict`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    })
      .then(result => {
        setIsFileLoading(false)
        return result.json()
      })
      .then((files: FileData[]) => files)
  }


  return {
    InputFile,
    isFileLoading,
    isFilesExists,
    setIsFilesExists,
    onCheckFilesHandler,
    onUploadFileHandler,
  }
}


export default useUpload

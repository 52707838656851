import { gql, useQuery } from "@apollo/client";

export const GETNEWS_QUERY = gql`
  query GetNews($offset: Int, $limit: Int, $filter: JSON) {
    newsFeed(offset: $offset, limit: $limit, filter: $filter)
  }
`;

// let a = categories && categories.length > 0 ? categories : []
// console.log('Результат: ', a)

// let obj = {
//     startDate:
// }

// const result = gql`
// query GetNews($filter: JSON) {
//         newsFeed(filter:{
//             endDate:"${dayjs(date).format('YYYY-MM-DD')}",
//             startDate:"${dayjs(date).format('YYYY-MM-DD')}",
//             categories: $filter
//         })
// }`

// return useQuery(result, {
//     variables: {
//         arr: categories
//     }
// })

const GET_NEWS = gql`
  query NewsList($offset: Int, $limit: Int, $filter: JSON) {
    newsFeed(offset: $offset, limit: $limit, filter: $filter)
  }
`;

export const useGetNews = (offset: any, limit: any, filter: any) =>
  useQuery(GET_NEWS, {
    variables: {
      offset,
      limit,
      filter,
    },
  });

export const GETNEWSMAIN_QUERY = (start: any, count: any) => {
  return gql`
    query References {
        newsFeed(offset:${start}, limit: ${count})
    }
    `;
};

export const GETNEWSCATEGORIES_QUERY = (limit: any, offset: any) => {
  return gql`
    query References {
        newsTags(limit: ${limit}, offset: ${offset})
      }
    `;
};

export const GETNEWSFROMID_QUERY = (id: any) => {
  return gql`
        query References {
            getNewsFromId(id:${id})
        }
    `;
};

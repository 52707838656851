/* eslint-disable no-console */
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import { OPVO_ROUTE, } from '@/shared/constants/routes'
import {
  NewPageWrapper,
  TitleH2,
} from '../common/GeneralElements/GeneralElements'

import FilterDropDown from '../common/FilterDropDown/FilterDropDown'

import {
  useSelector,
} from 'react-redux'
import CollapseContainer from '../common/CollapseContainer/CollapseContainer'
import { Link, } from 'react-router-dom'


const Opvo = () => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studentInfo = useSelector(state => state.prof.studentInfo)
  const namesSpec = studentInfo.map((info: any) => info.namespec)

  return (
    <NewPageWrapper className='my-education'>
      <HistoryLinks
        links={
          [
            {
              value: 'Главная',
              path: '/',
            },
            {
              value: 'Учебный процесс',
              path: OPVO_ROUTE,
            },
          ]
        }
        loc="Образовательная программа высшего образования"
      />

      <TitleH2 newPageHeaderTitle>Образовательная программа высшего образования</TitleH2>

      <FilterDropDown
        items={ namesSpec }
        defaultHint="Выберите специализацию"
        onClickHandler={ onClickHandler }
        firstIsSelected
        customWidth="fit-content"
      />

      <br />

      <CollapseContainer content={ [
        {
          headerText: 'Описание образовательной программы с приложением ее копии',
          cardType: OpvoCard,
          cardContent: [
            {},
          ],
          opened: true,
        },
        {
          headerText: 'Учебный план с приложением его копии',
        },
        {
          headerText: 'Аннотации к рабочим программам дисциплин (по каждой дисциплине в составе образовательной программы) с приложением их копий',
        },
        {
          headerText: 'Рабочие программы практик, предусмотренных соответствующей образовательной программой',
        },
        {
          headerText: 'Ссылки на рабочие программы (по каждой дисциплине в составе образовательной программы)',
        },
        {
          headerText: 'Рабочая программа воспитания',
        },
        {
          headerText: 'Календарный план воспитательной работы',
        },
        {
          headerText: 'Календарный учебный График с приложением его копии',
        },
        {
          headerText: 'Методические и иные документы, разработанные образовательной организацией для обеспечения образовательного процесса',
        },
        {
          headerText: 'Использование при реализации образовательных программ электронного обучения и дистанционных образовательных технологий',
        },
      ] } />
    </NewPageWrapper>
  )
}

const OpvoCard = () => (
  <div style={ {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#7F8191',
  } }>
    <Link to='' style={ { color: '#7671DD', } }>
      Набор 2021
    </Link>
    { ' / ' }
    <Link to='' style={ { color: '#7671DD', } }>
      ЭЦП
    </Link>
  </div>
)

// TODO: изменить на нормальную функцию
const onClickHandler = (val: any) => console.log(val)


export default Opvo

import { UUID, } from '@/legacy/types'


interface effectiveContractUploadCategoryCreatorParams {
  tableName: string,
  userId: UUID,
  recordId: string | number,
}


const effectiveContractUploadCategoryCreator = ({
  tableName,
  userId,
  recordId,
}: effectiveContractUploadCategoryCreatorParams) => {
  return `effective-contract/achievements/${ String(userId) }/${ String(tableName) }/${ String(recordId) }`
}


export default effectiveContractUploadCategoryCreator

// TODO: выпилить этот компонент из проекта на его место поставить более тупой инпут

import { TextareaAutosize, } from '@mui/material'
import './InputTextDeprecated.less'
import { memo, useEffect, useState, } from 'react'
import clsx from 'clsx'
import { DebounceInput, } from 'react-debounce-input'
import { numbersOnlyTrimmer, } from '../../../Utils/numbersOnlyTrimmer'


/** @deprecated не используй этот компонент, он стал слишком умным и будет выпилен */
const InputTextDeprecated = ({
  className,
  defaultHint,
  value,
  onChangeValue,
  withPlaceholder = false,
  styles,
  disabled,
  numbersOnly,
  onBlur,
  isAutosizeArea,
  debounceTimeout = 0,
  ...props
}: any) => {
  return (
    <label
      className={ clsx('input-text', className) }
      style={ styles }
      { ...props }
    >
      <div className="input-text__inner">
        <span className="input-text__label-hint">
          { (value || !withPlaceholder) ? defaultHint : false }
        </span>

        { !isAutosizeArea && (
          <DebounceInput
            debounceTimeout={ debounceTimeout }
            type="text"
            className={ 'input-text__value' }
            value={ value }
            onChange={ event => {
              if (numbersOnly) {
                onChangeValue(numbersOnlyTrimmer(event.target.value))
              } else {
                onChangeValue(event.target.value)
              }
            } }
            placeholder={ withPlaceholder ? defaultHint : '' }
            disabled={ disabled }
            onBlur={ onBlur }
          />
        ) }


        { isAutosizeArea && (
          <TextareaAutosize
            value={ value }
            className='input-text__autosize-text-area'
            placeholder={ withPlaceholder ? defaultHint : '' }
            onChange={ event => {
              if (numbersOnly) {
                onChangeValue(numbersOnlyTrimmer(event.target.value))
              } else {
                onChangeValue(event.target.value)
              }
            } }
          />
        ) }
      </div>
    </label>
  )
}


export const useInputText = ({
  initialValue = '',
  isLazyData,
}: any) => {
  const [
    value,
    setValue,
  ] = useState(initialValue)

  useEffect(() => {
    if (isLazyData) {
      setValue(String(initialValue))
    }
  }, [
    initialValue,
    isLazyData,
  ])

  const onChangeValue = (newValue: any) => setValue(newValue)

  return {
    value,
    onChangeValue,
  }
}


/** @deprecated не используй этот компонент, он стал слишком умным и будет выпилен */
export default memo(InputTextDeprecated)

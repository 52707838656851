import React from 'react'
import AchievementAccordion from '../AchievementAccordion/AchievementAccordion'
import { gql, useQuery, } from '@apollo/client'
import useUrlSearch from '@/legacy/hooks/useUrlSearch'

const EC_ACHIEVEMENT_TYPE_BY_DTSUID = gql`
    query Query($dtsuId: String!) {
        ecAchievementTypeByDTSUID(dtsuId: $dtsuId)
    }
`

const BonusPointsIndicatorsAdminTab_2 = () => {
  const moderateId = useUrlSearch('id')
  const { loading, error, data, } = useQuery(EC_ACHIEVEMENT_TYPE_BY_DTSUID, {
    variables: { dtsuId: moderateId, },
  })

  if (loading) {
    return <p>Loading...</p>
  }
  if (error) {
    return <p>Error: {error.message}</p>
  }

  const firstItem = data?.ecAchievementTypeByDTSUID.find((item: any) => item.achievementTypeName === 'Бонусные баллы')

  if (!firstItem) {
    return <p>Нет данных</p>
  }

  const children = firstItem?.children || []

  return (
    <div className="bonus-points-admin-tab-2">
      {children.map((achievement: any) => (
        <AchievementAccordion key={achievement.id} achievement={achievement} moderateId={moderateId}/>
      ))}
    </div>
  )
}

export default BonusPointsIndicatorsAdminTab_2

import React from 'react'
import clsx from 'clsx'
import { IconCheckbox, } from '@tabler/icons'
import './SuccesPopup.less'
import { Button, } from 'antd'


function SuccessPopup ({
  isOpen,
  handlePopup,
  text,
}: any) {
  // const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={ clsx('successHidden', isOpen && 'sucessWrap') }>
      <div className="sucess">
        <IconCheckbox size={ 125 } color="#7671DD" />
        <h2 className="title">{ text }</h2>
        <Button onClick={ handlePopup } className={ 'button' }>Xорошо</Button>
      </div>
    </div>
  )
}

export default SuccessPopup

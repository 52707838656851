import { TitleH3, } from '../../../../common/GeneralElements/GeneralElements'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import ButtonSimple, { IconComponent, } from '../../../../common/ButtonSimple/ButtonSimple'
import { IconCircleCheck, IconCircleX, } from '@tabler/icons'
import { useEffect, useState, } from 'react'
import TextArea from '../../../../common/TextArea/TextArea'
import { useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { getScores_1_1, } from '../../../getScores'


const PopUpContent_2_3 = ({
  data,
  onClosePopUp,
}: any) => {
  const [
    rejectAchievement,
    setRejectAchievement,
  ] = useState(false)
  const [
    reason,
    setReason,
  ] = useState('')
  const [
    scores,
    setScores,
  ] = useState('')

  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    setScores(getScores_1_1(data))
  }, [
    data,
  ])

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (oldData: any, updateField: any, newValue: any, reason = '', scores = null) => onUpdateRow({
    variables: {
      rec_id: oldData.rec_id,
      new_data: {
        ...oldData,
        reason: reason,
        scores: scores,
        [updateField]: newValue,
      },
    },
  })

  useEffect(() => {
    setRejectAchievement(false)
  }, [
    data,
  ])

  return <>
    <TitleH3>
      Издание учебной и научной литературы
    </TitleH3>
    <br />
    <InputTextDeprecated
      defaultHint="ФИО автора (авторов)"
      value={ data?.authors.map((author: any) => author.fio).join(', ') }
      disabled
    />
    <br />
    <InputTextDeprecated
      defaultHint="Название работы"
      value={ data?.book_title }
      disabled
    />
    <br />
    <InputTextDeprecated
      defaultHint="Вид работы"
      value={ data?.type_work }
      disabled
    />
    <br />
    <InputTextDeprecated
      defaultHint="Издательство"
      value={ data?.publish }
      disabled
    />
    <br />
    <InputTextDeprecated
      defaultHint="Издатель"
      value={ data?.publisher }
      disabled
    />
    <br />
    <InputTextDeprecated
      defaultHint="Год издания"
      value={ data?.publication_year }
      disabled
    />
    <br />
    <InputTextDeprecated
      defaultHint="Количество авторов"
      value={ data?.authors.length }
      disabled
    />
    <br />
    <InputTextDeprecated
      defaultHint="Шифр и наименование направления подготовки / специальности"
      value={ data?.major }
      disabled
    />
    <br />
    <InputTextDeprecated
      defaultHint="Название дисциплины"
      value={ data?.disciplineName }
      disabled
    />
    <br />
    <InputTextDeprecated
      defaultHint="Объем, п.л"
      value={ data?.volume }
      disabled
    />
    <br />
    <InputTextDeprecated
      defaultHint="Формат издания"
      value={ data?.format }
      disabled
    />
    <br />
    <InputTextDeprecated
      defaultHint="Источник финансирования"
      value={ data?.financeSource }
      disabled
    />
    <br />
    <InputTextDeprecated
      defaultHint="Тираж"
      value={ data?.print_run }
      disabled
    />
    <br />

    { !rejectAchievement
      ? (
        <>
          <InputTextDeprecated
            defaultHint="Баллы"
            value={ scores }
            disabled
          />

          <br />
        </>
      ) : false }

    { rejectAchievement ? (
      <>
        <TextArea
          defaultHint='По какой причине вы хотите отклонить заявку?'
          withPlaceholder={ false }
          value={ reason }
          onChangeValue={ setReason }
        />

        <br />

        <div style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }>
          <ButtonSimple
            value="Отменить"
            variant="groupe"
            styles={ {
              marginRight: '1rem',
              backgroundColor: '#F5F6FA',
            } }
            onClickHandler={ () => {
              setRejectAchievement(false)
            } }
          />

          <ButtonSimple
            value={ (
              <IconComponent
                Icon={ IconCircleX }
                iconStyles={ { color: '#FF5E5E', } }
                text="Отклонить"
              />
            ) }
            variant="groupe"
            isDisabled={ reason.split(' ').length < 3 }
            onClickHandler={ () => {
              onUpdateRowHandler(data, 'status', 'rejected', reason)
              onClosePopUp()
            } }
          />
        </div>
      </>
    ) : false }

    { !rejectAchievement ? (
      <div style={ {
        display: 'flex',
        justifyContent: 'flex-end',
      } }>
        <ButtonSimple
          value={ (
            <IconComponent
              Icon={ IconCircleX }
              iconStyles={ { color: '#FF5E5E', } }
              text="Отклонить"
            />
          ) }
          variant="groupe"
          styles={ { marginRight: '1rem', } }
          onClickHandler={ () => setRejectAchievement(true) }
        />

        <ButtonSimple
          value={ (
            <IconComponent
              Icon={ IconCircleCheck }
              text="Подтвердить"
            />
          ) }
          variant="groupe"
          active
          onClickHandler={ () => {
            // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            onUpdateRowHandler(data, 'status', 'approved', '', scores)
            onClosePopUp()
          } }
        />
      </div>
    ) : false }
  </>
}


export default PopUpContent_2_3

import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'


const LIST_TYPES = gql`
  query AchievementTypes2($catid: Int, $key:Int, $is_admin: Boolean) {
    achievementTypes(catid: $catid, key: $key, is_admin: $is_admin)
  }
`


export const useGetAchievementTypeInfo = (cat_id = 0, type_id: any) => {
  const { data, loading, refetch, } = useQuery(LIST_TYPES, {
    variables: { catid: cat_id, },
    fetchPolicy: 'network-only',
  })

  const typeInfo = useMemo(() => {
    if (!loading && data && cat_id && type_id) {
      const type = data?.achievementTypes?.find((item: any) => item?.key === type_id)

      return {
        basicScores: type?.points,
        basicStatus: type?.need_confirm,
      }
    }
  }, [
    data,
    loading,
    cat_id,
    type_id,
  ])

  return {
    ...typeInfo,
    refetch,
  }
}


export const useGetAchievementTypesList = ({
  cat_id,
  is_admin,
}: any) => {
  const { data, loading, refetch, } = useQuery(LIST_TYPES,
    {
      variables:
      {
        catid: cat_id,
        is_admin,
      },
      fetchPolicy: 'no-cache',
    })

  const achievementTypesList = useMemo(() => {
    if (!loading && data) {
      return data?.achievementTypes
    } return []
  }, [
    data,
    loading,
  ])

  return {
    achievementTypesList,
    refetch,
  }
}


export const useGetAchievementType = ({
  key,
  cat_id,
  is_admin,
}: any) => {
  const { data, loading, refetch, } = useQuery(LIST_TYPES, {
    variables: {
      key,
      cat_id,
      is_admin,
    },
    fetchPolicy: 'no-cache',
  })

  const achievementType = useMemo(() => {
    if (!loading && data) {
      return data?.achievementTypes[0]
    } return {}
  }, [
    data,
    loading,
  ])

  return {
    achievementType,
    refetch,
  }
}

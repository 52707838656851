import './WorkerCard.less'
import { IconChevronRight, } from '@tabler/icons'
import useRedirect from '@/legacy/hooks/useRedirect'

function WorkerCard ({
  info,
  type,
  click,
}: any) {
  const redirect = useRedirect()


  function handleClick (i: any) {
    if (type === 'course') {
      click(i)
    } else {
      redirect('/journal-inner')
    }
  }

  return (
    <div className="K-WorkerCard">
      <h2>Дисциплина</h2>
      <h3>{ info.discipline }</h3>
      { info.groups.map((i: any) => {
        return <div key={ info.groups.indexOf(i) } className="groupWrap" onClick={ () => handleClick(i) }>
          <p>{ `№ ${ i }` }</p>
          <div>
            <IconChevronRight color={ '#7671DD' } />
          </div>
        </div>
      }) }
    </div>
  )
}

export default WorkerCard

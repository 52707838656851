/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useState, } from 'react'
import './NavigationCard.less'
import { IconArrowRight, } from '@tabler/icons'
import useRedirect from '@/legacy/hooks/useRedirect'


const NavigationCard = ({
  props,
}: any) => {
  const [
    active,
    setActive,
  ] = useState(false)

  const redirect = useRedirect()


  return (
    <div
      className="Card d-cursor"
      onClick={ () => redirect(props.path) }
      onMouseOut={ () => setActive(false) }
      onMouseOver={ () => setActive(true) }
    >
      { props.icon }

      <div className="C-h2 d-row d-center title">
        { props.name }
        { props.name === 'Справки'
          && <div className="d-row">
            { props.good > 0
              && <div className="Status Good">
                { props.good }
              </div> }
            {/* {props.bad > 0 && <div className="Status Bad">{props.bad}</div>} */ }
          </div> }
      </div>

      <div className="subtitle">
        { props.desc }
      </div>

      <div
        className="Arrow"
        onClick={ () => redirect(props.path) }
        onMouseOut={ () => setActive(false) }
        onMouseOver={ () => setActive(true) }
      >
        <IconArrowRight color='white' />
      </div>
    </div>
  )
}


export default NavigationCard

import HeaderNav from './HeaderNav'


function HeaderNavCurrent () {
  return (
    <HeaderNav color="#2E2E3D" messages={ 10 } />
  )
}

export default HeaderNavCurrent

/**
 * Компонент для ввода и отрисовки списка участников достижения.
 *
 * @param value - Список участников, является массивом.
 * @param valueIndex - Текстовый индекс ячейки в строке таблицы с достижениями.
 * @param title - Заголовок, информирующий о типе вводимых данных, который отрисуется внутри формы с полями ввода информации о достижении.
 * @param onChangeRowValue - Функция обновляющая значение ячейки в строке таблицы с достижениями.
 *
 */


import TeacherSearch from '@/legacy/components/EffectiveContract/Hooks/TeacherSearch/TeacherSearch'
import { UpdateControlledField, } from '@/legacy/components/EffectiveContract/TableContainer/AddNewAchievement/AddNewAchievement'
import addOtherParticipantsHandler from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/components/ParticipantsInput/handlers/addOtherParticipantsHandler'
import addParticipantsHandler from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/components/ParticipantsInput/handlers/addParticipantsHandler'
import removeParticipantFromListHandler from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/components/ParticipantsInput/handlers/removeParticipantFromListHandler'
import { CurrentValueProps, } from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/components/ParticipantsInput/types/currentValueProps'
import { AchievementMixedParticipant, AchievementParticipant, } from '@/legacy/components/EffectiveContract/types/achievementParticipant'
import { ChipsBox, InputText, } from '@/shared'
import { Divider, } from '@mui/material'
import { useState, } from 'react'


interface ParticipantsInputProps {
  value: AchievementMixedParticipant[]
  valueIndex: string
  title: string
  onChangeRowValue: UpdateControlledField
}


const ParticipantsInput = ({
  value,
  valueIndex,
  title,
  onChangeRowValue,
}: ParticipantsInputProps) => {
  const [
    participants,
    setParticipants,
  ] = useState('')

  const [
    otherParticipants,
    setOtherParticipants,
  ] = useState('')


  const participantList = value.map(participant => ({
    id: participant.fio + participant.guid,
    title: participant.fio,
  }))


  const currentValueProps: CurrentValueProps = {
    value,
    valueIndex,
    onChangeRowValue,
  }


  return (
    <>
      { title }

      <ChipsBox
        items={ participantList }
        getRemoveItemInfo={ listElementToBoDeleted => removeParticipantFromListHandler(listElementToBoDeleted, currentValueProps) }
      />


      <div>
        <TeacherSearch
          defaultHint="Добавьте участников по их ФИО"
          value={ participants }
          onChangeValue={ setParticipants }
          handler={ (answer: AchievementParticipant) => {
            addParticipantsHandler(answer, currentValueProps)
          } }
        />
      </div>


      <InputText
        title={ 'Прочие авторы (не сотрудники Державинского университета, перечислить через запятую)' }
        value={ otherParticipants }
        onChange={ event => setOtherParticipants(event.target.value) }
        onBlur={ () => {
          addOtherParticipantsHandler(otherParticipants, currentValueProps)
          setOtherParticipants('')
        } }
      />


      <Divider />
    </>
  )
}


export default ParticipantsInput

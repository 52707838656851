/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useRef, useState, } from 'react'
import { Link, } from 'react-router-dom'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import InputTextDeprecated, { useInputText, } from '../../../common/InputTextDeprecated/InputTextDeprecated'
import PaperContainerWrapper from '../../../common/PaperContainer/PaperContainerWrapper'
import DarkLayer from './DarkLayer/DarkLayer'
import OptionsButton from './OptionsButton/OptionsButton'
import StatusBlock from './StatusBlock/StatusBlock'
import styles from './TemplateItem.module.less'


const TemplateItem = ({
  name,
  available,
  need_confirm,
  detached,
  className,
  linkTo,
  onDeleteItem,
  onChangeStatus,
  onRenameItem,
  onChangeOrder,
  order,
  ...props
}: any) => {
  const itemRef = useRef(null)
  const [
    isEdit,
    setIsEdit,
  ] = useState(false)
  const newItemNameInput = useInputText({ initialValue: name, })

  const onEditHandle = useCallback(() => {
    setIsEdit(true)
  }, [])

  useEffect(() => {
    if (isEdit) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      itemRef.current.classList?.add(styles.wrapper_upper)
    }

    if (!isEdit) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      itemRef.current.classList?.remove(styles.wrapper_upper)
    }
  }, [
    itemRef,
    isEdit,
  ])


  const link = useMemo(() => (
    <Link
      to={ linkTo }
      className={ clsx(
        styles.titleWrapper,
        { [styles.titleWrapper_editMode]: isEdit, }
      ) }
    >
      <h3 className={ styles.title }>{ name }</h3>
      <StatusBlock isActive={ available } />
    </Link>
  ), [
    available,
    isEdit,
    linkTo,
    name,
  ])


  return (
    <>
      <PaperContainerWrapper
        ref={ itemRef }
        className={ clsx(className, styles.wrapper) }
        { ...props }
      >
        <OptionsButton
          onDeleteItem={ onDeleteItem }
          onChangeStatus={ onChangeStatus }
          onEditHandle={ onEditHandle }
          onChangeOrder={ onChangeOrder }
          isEditMode={ isEdit }
          order={ order }
        />

        { !isEdit && (
          <>
            { link }
          </>
        ) }

        { isEdit && (
          <div
            className={ clsx(
              styles.titleWrapper,
              { [styles.titleWrapper_editMode]: isEdit, }
            ) }
          >
            <InputTextDeprecated
              withPlaceholder
              defaultHint={ 'Новое название' }
              styles={ { width: '100%', } }
              { ...newItemNameInput }
            />

            <ButtonSimple
              value={ 'Сохранить' }
              variant={ 'alone' }
              active
              onClickHandler={ () => onRenameItem(newItemNameInput.value).then(() => setIsEdit(false)) }
            />
          </div>
        ) }
      </PaperContainerWrapper>

      { isEdit && (
        <DarkLayer onClick={ () => setIsEdit(false) } />
      ) }
    </>
  )
}


export default TemplateItem

const viceHeadOfDepartmentsList = [
  {
    fio: 'Богомолов Герман Семенович',
    guid: '39a136c7-a08f-11e8-80e3-7824af85d229',
  },
  {
    fio: 'Серова Светлана Александровна',
    guid: 'a696ee18-313b-11e6-80bc-7824af85d229',
  },
  {
    fio: 'Михайлов Игорь Владимирович',
    guid: 'a091d71d-313b-11e6-80bc-7824af85d229',
  },
  {
    fio: 'Андреева Алена Алексеевна',
    guid: '89f7a747-313b-11e6-80bc-7824af85d229',
  },
  {
    fio: 'Шуняева Вера Анатольевна',
    guid: '56a83246-313b-11e6-80bc-7824af85d229',
  },
  {
    fio: 'Камнева Нина Анатольевна',
    guid: 'e9d07edf-3a06-11e6-80bc-7824af85d229',
  },
  {
    fio: 'Шишкина Инна Викторовна',
    guid: '6589d15c-3f93-11e6-80bd-7824af85d229',
  },
  {
    fio: 'Варфоломеева Людмила Германовна',
    guid: '3c66bcf9-08a8-11ec-bc26-005056aa8995',
  },
  {
    fio: 'Лапаева Ангелина Вячеславовна 1',
    guid: '1cc41928-2563-11e1-a8c7-0022649fe938',
  },
  {
    fio: 'Лапаева Ангелина Вячеславовна 2',
    guid: '8967b09b-5b02-11e7-80cd-7824af85d229',
  },
]


export const defineViceHeadOfDepartments = (guid: any) => {
  return viceHeadOfDepartmentsList.some(employee => employee.guid === guid)
}

import React, {useState,} from 'react'
import LinkButton from '@/legacy/components/common/LinkButton/LinkButton'
import Popup from '@/legacy/components/common/Popup/Popup'
import {StatusGroup,} from '@/legacy/components/EffectiveContract/cards/CardAchievement/CardAchievement'
import {
  EC_MODER_GROUPE,
  EcAchivementStatus,
  EcAppealStatus,
  EcTableName,
} from '@/legacy/components/EffectiveContract/constants'
import defineAllowedChangeStatus
  from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/TableStatus/utils/defineAllowedChangeStatus'
import {
  OnChangeRowValue,
} from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableRow/TableRow'
import useGetUserInfo from '@/legacy/hooks/useGetUserInfo'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import {TableRowType,} from '@/legacy/types'
import PopupContent from './components/PopupContent'
import PopupContentReject
  from '@/legacy/components/EffectiveContract/popupContent/PopupContentReject/PopupContentReject'

interface TableStatusProps {
  row: TableRowType;
  dataType: EcTableName;
  onChangeRowValue: OnChangeRowValue;
  onUpdateRowHandler: Function;
  isAppealMode?: boolean;
}

const TableStatus = ({
  row,
  dataType,
  onChangeRowValue,
  onUpdateRowHandler,
  isAppealMode,
}: TableStatusProps): JSX.Element => {
  const [
    isPopupOpen,
    setIsPopupOpen,
  ] = useState(false)
  const [
    reasonFailure,
    setReasonFailure,
  ] = useState<{ reason: string } | null>(null)
  const [
    isPopupOpenRejected,
    setIsPopupOpenRejected,
  ] = useState(false)

  const userRole = useGetRole()
  const { userGuid, } = useGetUserInfo()

  const isStatusChangeAllowed = defineAllowedChangeStatus({
    row,
    userRole,
    userGuid,
    dataType,
  })

  const onClosePopup = () => {
    setIsPopupOpen(false)
    setIsPopupOpenRejected(false)
  }

  const getAndShowReasonFailure = (data: any) => {
    setReasonFailure({ ...data, })
    setIsPopupOpenRejected(true)
  }
  const handleRejectButtonClick = () => {
    if (isAppealMode) {
      getAndShowReasonFailure({reason: row.appealConclusion})
    } else {
      const rejectionData = {
        reason: row.reason, // Pass the reason from the row
      }
      getAndShowReasonFailure(rejectionData)
    }
  }

  return (
    <td>
      <StatusGroup
        status={row.status}
        isLink={isStatusChangeAllowed}
        onClickHandler={() => setIsPopupOpen(true)}
      />

      {row.status !== EcAchivementStatus.PENDING &&
        row.status !== EcAppealStatus.APPEAL_PENDING &&
        EC_MODER_GROUPE[userRole]?.canChangeStatus.some(canChangeStatus => canChangeStatus === dataType) && (
        <>
          <LinkButton
            type="resetStatus"
            onClickHandler={() => {
              if (!isAppealMode) {
                Promise.resolve(onChangeRowValue('status', EcAchivementStatus.PENDING))
                  .then(() => onChangeRowValue('scores', null))
                  .then(() => onChangeRowValue('reason', ''))
                  .then(() => onUpdateRowHandler())
              }

              if (isAppealMode) {
                Promise.resolve(onChangeRowValue('status', EcAppealStatus.APPEAL_PENDING))
                  .then(() => onChangeRowValue('scores', null))
                  .then(() => onChangeRowValue('reason', ''))
                  .then(() => onUpdateRowHandler())
              }
            }}
          />
          {(row.status === EcAchivementStatus.REJECTED || row.status === EcAppealStatus.APPEAL_REJECTED) && (
            <LinkButton type="rejectReason" onClickHandler={handleRejectButtonClick} />
          )}
        </>
      )}

      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          handlePopup={() => {
            onChangeRowValue('reason', '')
            onChangeRowValue('scores', null)
            onClosePopup()
          }}
          withScroll
        >
          <PopupContent
            row={row}
            dataType={dataType}
            isAppealMode={isAppealMode}
            onChangeRowValue={onChangeRowValue}
            onUpdateRowHandler={onUpdateRowHandler}
            onClosePopup={onClosePopup}
          />
        </Popup>
      )}

      {isPopupOpenRejected && (
        <Popup
          isOpen={isPopupOpenRejected}
          handlePopup={() => setIsPopupOpenRejected(false)}
          withScroll // Если ваш PopupContentReject требует скроллинга
        >
          <PopupContentReject
            reason={reasonFailure?.reason}
            onClickHandler={() => setIsPopupOpenRejected(false)}
          />
        </Popup>
      )}
    </td>
  )
}

export default TableStatus

import React, { useEffect, useState } from "react";
import useDownload from "@/shared/api/hooks/useDownload/useDownload";
import TableSpinner from "@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/TableSpinner/TableSpinner";
import { useGetExportedPractics } from "@/features/portfolio/api/hooks/useGetExportedPractics";

const FileDownloader = () => {
  const { data, loading, error } = useGetExportedPractics();
  const [fileId, setFiledId] = useState<string | null>(null);

  const {
    downloadUrl,
    errorMessage,
    loading: downloadLoading,
  } = useDownload(fileId);
  useEffect(() => {
    if (data?.exportPractics.fileId) {
      setFiledId(data.exportPractics.fileId);
    }
  }, [data]);

  useEffect(() => {
    if (downloadUrl) {
      window.location.href = downloadUrl;
    }
  }, [downloadUrl]);

  return (
    <>
      {(loading || downloadLoading) && <TableSpinner />}
      {error && <p>Error: {error.message}</p>}
      {errorMessage && <p>Error generating download URL: {errorMessage}</p>}
    </>
  );
};

export default FileDownloader;

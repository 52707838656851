import './AnimatedTrash.less'


const AnimatedTrash = ({
  styles,
  dataTip,
  clickHandler,
}: any) => {
  return <section style={ styles } data-tip={ dataTip }>
    <span className="trash" onClick={ clickHandler }>
      <span></span>
      <i></i>
    </span>
    {/* <ReactTooltip place="bottom" className="tollTip" backgroundColor={"#43444D"} /> */ }
  </section>
}

export default AnimatedTrash

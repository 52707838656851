import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  studentInfo: false,
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateStudent: (state, action) => {
      state.studentInfo = action.payload
    },
    clearStudent: state => {
      state.studentInfo = false
    },
  },
})


export const { updateStudent, clearStudent, } = profileSlice.actions

export default profileSlice.reducer

import React from 'react'
import { StrictMode, } from 'react'
import ReactDOM from 'react-dom/client'
import './index.less'
import './legacy/less/DefaultStyles.less'
import './legacy/less/Default/Animation.less'
import App from '@/app/App'
import reportWebVitals from './reportWebVitals'
import { HelmetProvider, } from 'react-helmet-async'


const rootElement = document.getElementById('root')
if (!rootElement) {
  throw new Error('Failed to find the root element')
}
const root = ReactDOM.createRoot(rootElement)

root.render(
  <StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
reportWebVitals()

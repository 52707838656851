import { CreatePayloadParamDataResponses, OnUpdateProjectPayload, ProjectStudentResult, ProjectStudentResults, } from '@/legacy/gql/hooks/projects/useUpdateProjectTypes'
import { UUID, } from '@/legacy/types'


interface OnDeleteResultHandlerParams {
  resultForDelete: ProjectStudentResult,
  allStudentResults: ProjectStudentResults,
  projectPayloadData: OnUpdateProjectPayload,
  responderGuid: UUID,
  deleteUpload: Function,
  onUpdateProject: Function,
}


const onDeleteResultHandler = ({
  resultForDelete,
  allStudentResults,
  projectPayloadData,
  responderGuid,
  deleteUpload,
  onUpdateProject,
}: OnDeleteResultHandlerParams) => {
  const newFiles = allStudentResults.files.filter((file: ProjectStudentResult) => file.title !== resultForDelete.title)
  const newProjectPayloadData = JSON.parse(JSON.stringify(projectPayloadData))
  const userData = newProjectPayloadData.args.data.responses.find(
    (student: CreatePayloadParamDataResponses) => student.id === responderGuid)
  userData.studentResults.files = newFiles

  if (Array.isArray(resultForDelete.file)) {
    deleteUpload({
      variables: {
        upload_ids: [
          resultForDelete.file[0].upload_id,
        ],
      },
    })
      .then(() => onUpdateProject(newProjectPayloadData))
      // eslint-disable-next-line no-console
      .catch((error: string) => console.log(error))
  } else {
    onUpdateProject(newProjectPayloadData)
  }
}


export default onDeleteResultHandler

import { IconCirclePlus, } from '@tabler/icons'
import { memo, useState, } from 'react'
import { useDispatch, } from 'react-redux'
import { resetCreateNotificationForm, } from '../../../redux/moderateNotificationsSlice/moderateNotificationsSlice'
import ButtonSimple, {
  IconComponent,
} from '../../common/ButtonSimple/ButtonSimple'
import Popup from '../../common/Popup/Popup'
import styles from './AddNotificationButton.module.less'
import PopupContent from './PopupContent/PopupContent'

const AddNotificationButton = () => {
  const dispatch = useDispatch()
  const [
    isPopupOpen,
    setIsPopupOpen,
  ] = useState(false)

  return (
    <>
      <ButtonSimple
        active
        value={
          <IconComponent
            text="Добавить уведомление"
            Icon={ IconCirclePlus }
            customClassName={ styles.buttonIcon }
          />
        }
        onClickHandler={ () => {
          dispatch(resetCreateNotificationForm())
          setIsPopupOpen(prev => !prev)
        } }
      />

      <Popup
        isOpen={ isPopupOpen }
        handlePopup={ () => setIsPopupOpen(prev => !prev) }
        isCloseButton
        withScroll
      >
        <PopupContent onClosePopup={ setIsPopupOpen } />
      </Popup>
    </>
  )
}

export default memo(AddNotificationButton)

// @ts-expect-error TS(2616): 'React' can only be imported by using 'import Reac... Remove this comment to see the full error message
import { React, useMemo } from "react";
import Col from "../Col/Col";
import ColInput from "../Col/ColInput";
import { useSelector } from "react-redux";
import { Row } from "antd";
import { findContact } from "../../../Utils/functions";
import { useME } from "@/legacy/gql/hooks/useME";

function ContactsForm() {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const personalInfo = useSelector((state) => state.auth.me);
  // const registration = findContact(personalInfo.data.contacts, {kind_contact_information: "Адрес по прописке"}) !== "--" ? findContact(personalInfo.data.contacts, {kind_contact_information: "Адрес по прописке"}) : findContact(personalInfo.data.contacts, {kind_contact_information: "АдресПоПропискеФизическиеЛица"})
  // const current_loc = findContact(personalInfo.data.contacts, {kind_contact_information: "Адрес для информирования"}) !== "--" ? findContact(personalInfo.data.contacts, {kind_contact_information: "Адрес для информирования"}) : findContact(personalInfo.data.contacts, {kind_contact_information: "АдресДляИнформированияФизическиеЛица"})
  const tel =
    findContact(personalInfo.data.contacts, {
      kind_contact_information: "ТелефонМобильныйФизическиеЛица",
    }) !== "--"
      ? findContact(personalInfo.data.contacts, {
          kind_contact_information: "ТелефонМобильныйФизическиеЛица",
        })
      : findContact(personalInfo.data.contacts, {
          kind_contact_information: "Мобильный телефон",
        });
  const email =
    findContact(personalInfo.data.contacts, {
      kind_contact_information: "EMailФизическиеЛица",
    }) !== "--"
      ? findContact(personalInfo.data.contacts, {
          kind_contact_information: "EMailФизическиеЛица",
        })
      : findContact(personalInfo.data.contacts, {
          kind_contact_information: "Личная почта",
        });
  const emailCorp =
    findContact(personalInfo.data.contacts, {
      kind_contact_information: "Адрес корпоративной электронной почты",
    }) !== "--"
      ? findContact(personalInfo.data.contacts, {
          kind_contact_information: "Адрес корпоративной электронной почты",
        })
      : findContact(personalInfo.data.contacts, {
          kind_contact_information: "Корпортативная почта",
        });
  const me = useME();

  // console.log(me);

  const personalBlock = useMemo(() => {
    return (
      <>
        <Row gutter={30}>
          <Col
            space={12}
            title="Адрес корпоративной электронной почты"
            value={me.data?.me.email_work}
          />
          <ColInput
            type="email"
            space={12}
            title="Адрес личной электронной почты"
            value={email}
          />
        </Row>
        <Row gutter={30}>
          <ColInput
            type="tel"
            space={12}
            title="Номер мобильного телефона"
            value={tel}
          />
        </Row>
        {/* <Row gutter={30}>
        <ColInput type="text" space={24} title="Адрес места жительства" value={current_loc} />
      </Row>
      <Row gutter={30}>
        <ColInput type="text" space={24} title="Адрес по прописке" value={registration} />
      </Row>
      <Row gutter={30}>
        <ColInput type="text" space={24} title="Адрес для информирования" value={current_loc} />
      </Row> */}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalInfo]);

  return personalBlock;
}

export default ContactsForm;

function Icon ({
  name,
  color,
}: any) {
  function choseIcon () {
    switch (name) {
      case 'home':
        return <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 20H4C3.73478 20 3.48043 19.8946 3.29289 19.7071C3.10536 19.5196 3 19.2652 3 19V10H0L10.327 0.611996C10.5111 0.444474 10.7511 0.351639 11 0.351639C11.2489 0.351639 11.4889 0.444474 11.673 0.611996L22 10H19V19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20ZM5 18H17V8.157L11 2.703L5 8.157V18ZM7 14H15V16H7V14Z" fill={ color } />
        </svg>
      // break;
      case 'news':
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 18V2H2.00002V17C2.00002 17.2652 2.10537 17.5196 2.29291 17.7071C2.48045 17.8946 2.7348 18 3.00002 18H14ZM17 20H3.00002C2.20437 20 1.4413 19.6839 0.878695 19.1213C0.316086 18.5587 1.52588e-05 17.7956 1.52588e-05 17V1C1.52588e-05 0.734784 0.105372 0.48043 0.292908 0.292893C0.480445 0.105357 0.734799 0 1.00002 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8947 0.48043 16 0.734784 16 1V8H20V17C20 17.7956 19.6839 18.5587 19.1213 19.1213C18.5587 19.6839 17.7957 20 17 20ZM16 10V17C16 17.2652 16.1054 17.5196 16.2929 17.7071C16.4804 17.8946 16.7348 18 17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8947 17.5196 18 17.2652 18 17V10H16ZM4.00002 4H10V10H4.00002V4ZM6.00002 6V8H8.00002V6H6.00002ZM4.00002 11H12V13H4.00002V11ZM4.00002 14H12V16H4.00002V14Z" fill={ color } />
        </svg>
      case 'anounces':
        return <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 20H1.00002C0.734799 20 0.480445 19.8946 0.292908 19.7071C0.105372 19.5196 1.52588e-05 19.2652 1.52588e-05 19V1C1.52588e-05 0.734784 0.105372 0.48043 0.292908 0.292893C0.480445 0.105357 0.734799 0 1.00002 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8947 0.48043 18 0.734784 18 1V19C18 19.2652 17.8947 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20ZM16 18V2H2.00002V18H16ZM4.00002 4H8.00002V8H4.00002V4ZM4.00002 10H14V12H4.00002V10ZM4.00002 14H14V16H4.00002V14ZM10 5H14V7H10V5Z" fill={ color } />
        </svg>

      case 'notebook':
        return <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.52588e-05 16.5V3C1.52588e-05 2.20435 0.316086 1.44129 0.878695 0.87868C1.4413 0.316071 2.20437 0 3.00002 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8947 0.48043 18 0.734784 18 1V19C18 19.2652 17.8947 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3.50002C2.57176 20 1.68152 19.6313 1.02514 18.9749C0.368764 18.3185 1.52588e-05 17.4283 1.52588e-05 16.5ZM16 18V15H3.50002C3.10219 15 2.72066 15.158 2.43935 15.4393C2.15805 15.7206 2.00002 16.1022 2.00002 16.5C2.00002 16.8978 2.15805 17.2794 2.43935 17.5607C2.72066 17.842 3.10219 18 3.50002 18H16ZM7.00002 2H3.00002C2.7348 2 2.48045 2.10536 2.29291 2.29289C2.10537 2.48043 2.00002 2.73478 2.00002 3V13.337C2.46871 13.1144 2.98117 12.9993 3.50002 13H16V2H14V10L10.5 8L7.00002 10V2Z" fill={ color } />
        </svg>
      // break;
      case 'calendar-event':
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8947 2.48043 20 2.73478 20 3V19C20 19.2652 19.8947 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1.00002C0.734799 20 0.480445 19.8946 0.292908 19.7071C0.105372 19.5196 1.52588e-05 19.2652 1.52588e-05 19V3C1.52588e-05 2.73478 0.105372 2.48043 0.292908 2.29289C0.480445 2.10536 0.734799 2 1.00002 2H5.00002V0H7.00002V2H13V0H15V2ZM18 10H2.00002V18H18V10ZM13 4H7.00002V6H5.00002V4H2.00002V8H18V4H15V6H13V4ZM4.00002 12H6.00002V14H4.00002V12ZM9.00002 12H11V14H9.00002V12ZM14 12H16V14H14V12Z" fill={ color } />
        </svg>
      // break;
      case 'book':
        return <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 18.5V20.5H9.00002V18.5H1.00002C0.734799 18.5 0.480445 18.3946 0.292908 18.2071C0.105372 18.0196 1.52588e-05 17.7652 1.52588e-05 17.5V1.5C1.52588e-05 1.23479 0.105372 0.980432 0.292908 0.792896C0.480445 0.60536 0.734799 0.500003 1.00002 0.500003H7.00002C7.5676 0.499329 8.1288 0.619759 8.64614 0.85325C9.16347 1.08674 9.62504 1.42792 10 1.854C10.375 1.42792 10.8366 1.08674 11.3539 0.85325C11.8712 0.619759 12.4324 0.499329 13 0.500003H19C19.2652 0.500003 19.5196 0.60536 19.7071 0.792896C19.8947 0.980432 20 1.23479 20 1.5V17.5C20 17.7652 19.8947 18.0196 19.7071 18.2071C19.5196 18.3946 19.2652 18.5 19 18.5H11ZM18 16.5V2.5H13C12.4696 2.5 11.9609 2.71072 11.5858 3.08579C11.2107 3.46086 11 3.96957 11 4.5V16.5H18ZM9.00002 16.5V4.5C9.00002 3.96957 8.7893 3.46086 8.41423 3.08579C8.03916 2.71072 7.53045 2.5 7.00002 2.5H2.00002V16.5H9.00002Z" fill={ color } />
        </svg>
      // break;
      case 'bulb':
        return <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.00002 0C12.068 0 15.426 3.036 15.934 6.965L18.184 10.504C18.332 10.737 18.302 11.084 17.959 11.232L16 12.07V15C16 16.105 15.105 17 14 17H12.001L12 20H3.00002V16.306C3.00002 15.126 2.56402 14.009 1.75602 13.001C0.657015 11.631 1.52588e-05 9.892 1.52588e-05 8C1.52588e-05 3.582 3.58202 0 8.00002 0ZM8.00002 2C4.68602 2 2.00002 4.686 2.00002 8C2.00002 9.385 2.46802 10.693 3.31602 11.75C4.41002 13.114 5.00002 14.667 5.00002 16.306V18H10L10.002 15H14V10.752L15.55 10.088L14.007 7.663L13.95 7.221C13.566 4.251 11.024 2 8.00002 2ZM8.00002 5C8.55202 5 9.00002 5.448 9.00002 6V7H10C10.552 7 11 7.448 11 8C11 8.552 10.552 9 10 9H9.00002V10C9.00002 10.552 8.55202 11 8.00002 11C7.44802 11 7.00002 10.552 7.00002 10V8.999L6.00002 9C5.44802 9 5.00002 8.552 5.00002 8C5.00002 7.448 5.44802 7 6.00002 7L7.00002 6.999V6C7.00002 5.448 7.44802 5 8.00002 5Z" fill={ color } />
        </svg>
      // break;
      case 'contacts-book':
        return <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 20H3.00002C2.20437 20 1.4413 19.6839 0.878695 19.1213C0.316086 18.5587 1.52588e-05 17.7956 1.52588e-05 17V3C1.52588e-05 2.20435 0.316086 1.44129 0.878695 0.87868C1.4413 0.316071 2.20437 0 3.00002 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8947 0.48043 18 0.734784 18 1V19C18 19.2652 17.8947 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20ZM16 18V16H3.00002C2.7348 16 2.48045 16.1054 2.29291 16.2929C2.10537 16.4804 2.00002 16.7348 2.00002 17C2.00002 17.2652 2.10537 17.5196 2.29291 17.7071C2.48045 17.8946 2.7348 18 3.00002 18H16ZM2.00002 14.17C2.31302 14.06 2.65002 14 3.00002 14H16V2H3.00002C2.7348 2 2.48045 2.10536 2.29291 2.29289C2.10537 2.48043 2.00002 2.73478 2.00002 3V14.17ZM9.00002 8C8.46958 8 7.96087 7.78929 7.5858 7.41421C7.21073 7.03914 7.00002 6.53043 7.00002 6C7.00002 5.46957 7.21073 4.96086 7.5858 4.58579C7.96087 4.21071 8.46958 4 9.00002 4C9.53045 4 10.0392 4.21071 10.4142 4.58579C10.7893 4.96086 11 5.46957 11 6C11 6.53043 10.7893 7.03914 10.4142 7.41421C10.0392 7.78929 9.53045 8 9.00002 8ZM6.00002 12C6.00002 11.2044 6.31609 10.4413 6.87869 9.87868C7.4413 9.31607 8.20437 9 9.00002 9C9.79566 9 10.5587 9.31607 11.1213 9.87868C11.6839 10.4413 12 11.2044 12 12H6.00002Z" fill={ color } />
        </svg>
      // break;
      case 'archive':
        return <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.52588e-05 1.492C1.52588e-05 0.944 0.445015 0.5 0.993015 0.5H17.007C17.2696 0.50183 17.521 0.6069 17.7068 0.792513C17.8926 0.978126 17.9979 1.22938 18 1.492V19.508C17.9998 19.7712 17.895 20.0235 17.7088 20.2095C17.5226 20.3955 17.2702 20.5 17.007 20.5H0.993015C0.730393 20.4982 0.479033 20.3931 0.293233 20.2075C0.107433 20.0219 0.00210991 19.7706 1.52588e-05 19.508V1.492ZM16 9.5V2.5H2.00002V9.5H16ZM16 11.5H2.00002V18.5H16V11.5ZM6.00002 4.5H12V6.5H6.00002V4.5ZM6.00002 13.5H12V15.5H6.00002V13.5Z" fill={ color } />
        </svg>

      // break;
      case 'file-analytics':
        return <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 16H3.00002C2.7348 16 2.48045 16.1054 2.29291 16.2929C2.10537 16.4804 2.00002 16.7348 2.00002 17C2.00002 17.2652 2.10537 17.5196 2.29291 17.7071C2.48045 17.8946 2.7348 18 3.00002 18H18V20H3.00002C2.20437 20 1.4413 19.6839 0.878695 19.1213C0.316086 18.5587 1.52588e-05 17.7956 1.52588e-05 17V2C1.52588e-05 1.46957 0.210729 0.960859 0.585802 0.585786C0.960874 0.210714 1.46958 0 2.00002 0H18V16ZM2.00002 14.05C2.16202 14.017 2.32902 14 2.50002 14H16V2H2.00002V14.05ZM13 7H5.00002V5H13V7Z" fill={ color } />
        </svg>
      // break;
      case 'briefcase':
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.00002 4V1C5.00002 0.734784 5.10537 0.48043 5.29291 0.292893C5.48044 0.105357 5.7348 0 6.00002 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8947 0.48043 15 0.734784 15 1V4H19C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8947 4.48043 20 4.73478 20 5V19C20 19.2652 19.8947 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1.00002C0.734799 20 0.480445 19.8946 0.292908 19.7071C0.105372 19.5196 1.52588e-05 19.2652 1.52588e-05 19V5C1.52588e-05 4.73478 0.105372 4.48043 0.292908 4.29289C0.480445 4.10536 0.734799 4 1.00002 4H5.00002ZM18 12H2.00002V18H18V12ZM18 6H2.00002V10H5.00002V8H7.00002V10H13V8H15V10H18V6ZM7.00002 2V4H13V2H7.00002Z" fill={ color } />
        </svg>
      // break;
      case 'license':
        return <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 0C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H17ZM16 2H2V18H16V2ZM13 14V16H5V14H13ZM9 4C10.0609 4 11.0783 4.42143 11.8284 5.17157C12.5786 5.92172 13 6.93913 13 8C13 9.06087 12.5786 10.0783 11.8284 10.8284C11.0783 11.5786 10.0609 12 9 12C7.93913 12 6.92172 11.5786 6.17157 10.8284C5.42143 10.0783 5 9.06087 5 8C5 6.93913 5.42143 5.92172 6.17157 5.17157C6.92172 4.42143 7.93913 4 9 4ZM9 6C8.46957 6 7.96086 6.21071 7.58579 6.58579C7.21071 6.96086 7 7.46957 7 8C7 8.53043 7.21071 9.03914 7.58579 9.41421C7.96086 9.78929 8.46957 10 9 10C9.53043 10 10.0391 9.78929 10.4142 9.41421C10.7893 9.03914 11 8.53043 11 8C11 7.46957 10.7893 6.96086 10.4142 6.58579C10.0391 6.21071 9.53043 6 9 6Z" fill={ color } />
        </svg>
      // break;
      case 'notes':
        return <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.52588e-05 16.5V3C1.52588e-05 2.20435 0.316086 1.44129 0.878695 0.87868C1.4413 0.316071 2.20437 0 3.00002 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8947 0.48043 18 0.734784 18 1V19C18 19.2652 17.8947 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3.50002C2.57176 20 1.68152 19.6313 1.02514 18.9749C0.368764 18.3185 1.52588e-05 17.4283 1.52588e-05 16.5ZM16 18V15H3.50002C3.10219 15 2.72066 15.158 2.43935 15.4393C2.15805 15.7206 2.00002 16.1022 2.00002 16.5C2.00002 16.8978 2.15805 17.2794 2.43935 17.5607C2.72066 17.842 3.10219 18 3.50002 18H16ZM7.00002 2H3.00002C2.7348 2 2.48045 2.10536 2.29291 2.29289C2.10537 2.48043 2.00002 2.73478 2.00002 3V13.337C2.46871 13.1144 2.98117 12.9993 3.50002 13H16V2H14V10L10.5 8L7.00002 10V2Z" fill={ color } />
        </svg>
      //  break;
      case 'report-money':
        return <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM18 8H2V16H18V8ZM18 6V2H2V6H18ZM12 12H16V14H12V12Z" fill={ color } />
        </svg>
      // break;
      case 'search':
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.199 7.94503C12.7653 7.53415 12.4863 6.98644 12.409 6.39403L12.006 3.31103L9.276 4.79703C8.7511 5.08305 8.14367 5.17946 7.556 5.07003L4.5 4.50003L5.07 7.55603C5.17943 8.1437 5.08302 8.75113 4.797 9.27603L3.311 12.006L6.394 12.409C6.98605 12.4866 7.53338 12.7655 7.944 13.199L10.082 15.456L11.418 12.649C11.6744 12.1096 12.1088 11.6749 12.648 11.418L15.456 10.082L13.199 7.94503ZM13.224 13.508L11.011 18.158C10.9691 18.246 10.9065 18.3224 10.8285 18.3807C10.7505 18.439 10.6595 18.4774 10.5633 18.4927C10.4671 18.5079 10.3687 18.4995 10.2765 18.4682C10.1842 18.4369 10.101 18.3837 10.034 18.313L6.492 14.574C6.39737 14.4742 6.27134 14.41 6.135 14.392L1.028 13.724C0.931512 13.7113 0.839564 13.6753 0.760087 13.6192C0.68061 13.563 0.615989 13.4883 0.571797 13.4016C0.527605 13.3149 0.505169 13.2187 0.506424 13.1214C0.507679 13.0241 0.532587 12.9286 0.579 12.843L3.041 8.31903C3.10645 8.19804 3.12834 8.05823 3.103 7.92303L2.16 2.86003C2.14209 2.7642 2.14781 2.66544 2.17665 2.57232C2.20549 2.47919 2.25659 2.39449 2.32553 2.32556C2.39446 2.25662 2.47916 2.20552 2.57229 2.17668C2.66542 2.14784 2.76417 2.14212 2.86 2.16003L7.923 3.10303C8.05821 3.12837 8.19801 3.10648 8.319 3.04103L12.843 0.579029C12.9286 0.532604 13.0242 0.507716 13.1216 0.506515C13.219 0.505315 13.3152 0.527837 13.4019 0.572138C13.4887 0.616439 13.5633 0.681186 13.6194 0.760788C13.6755 0.84039 13.7114 0.932454 13.724 1.02903L14.392 6.13503C14.41 6.27137 14.4742 6.3974 14.574 6.49203L18.313 10.034C18.3836 10.1011 18.4369 10.1843 18.4682 10.2765C18.4995 10.3687 18.5079 10.4671 18.4926 10.5633C18.4774 10.6595 18.4389 10.7505 18.3806 10.8285C18.3223 10.9065 18.2459 10.9692 18.158 11.011L13.508 13.224C13.3835 13.2833 13.2832 13.3835 13.224 13.508ZM14.021 15.435L15.435 14.021L19.678 18.263L18.263 19.678L14.021 15.435Z" fill={ color } />
        </svg>
      // break;
      case 'tablet':
        return (
          <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 2V0H14V2H17.007C17.555 2 18 2.445 18 2.993V19.007C17.9997 19.2703 17.895 19.5227 17.7089 19.7089C17.5227 19.895 17.2703 19.9997 17.007 20H0.993C0.729721 19.9997 0.477302 19.895 0.291135 19.7089C0.104969 19.5227 0.000264735 19.2703 0 19.007V2.993C0 2.445 0.445 2 0.993 2H4ZM4 4H2V18H16V4H14V6H4V4ZM6 2V4H12V2H6Z" fill={ color } />
          </svg>
        )
      // break;
      case 'tablet-fill':
        return (
          <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 2V6H15V2H17.007C17.555 2 18 2.445 18 2.993V19.007C17.9997 19.2703 17.895 19.5227 17.7089 19.7089C17.5227 19.895 17.2703 19.9997 17.007 20H0.993C0.729721 19.9997 0.477302 19.895 0.291135 19.7089C0.104969 19.5227 0.000264735 19.2703 0 19.007V2.993C0 2.445 0.445 2 0.993 2H3ZM5 0H13V4H5V0Z" fill={ color } />
          </svg>
        )
      // break;
      case 'folderGear':
        return (
          <svg width="20" height="18" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18"><path fill={ color } d="M10.414 2H19a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h7.414l2 2zM2 2v14h16V4H9.586l-2-2H2zm4.591 8.809a3.508 3.508 0 0 1 0-1.622L5.6 8.615l1-1.732.991.573a3.495 3.495 0 0 1 1.404-.812V5.5h2v1.144c.532.159 1.01.44 1.403.812l.992-.573 1 1.731-.991.573a3.508 3.508 0 0 1 0 1.622l.991.572-1 1.731-.991-.572a3.494 3.494 0 0 1-1.404.811v1.145h-2V13.35a3.494 3.494 0 0 1-1.404-.811l-.991.572-1-1.73.991-.573v.001zm3.404.688a1.5 1.5 0 1 0 .11-2.997 1.5 1.5 0 0 0-.11 2.997z" /></svg>
        )
      // break;
      case 'folderGearBlack':
        return (
          <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={ color } d="M10.414 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H8.414L10.414 2ZM6.591 10.809L5.6 11.381L6.6 13.112L7.591 12.54C7.984 12.911 8.463 13.193 8.996 13.351V14.496H10.995V13.35C11.5201 13.1935 12.001 12.9157 12.399 12.539L13.39 13.111L14.39 11.381L13.399 10.808C13.5257 10.2748 13.5257 9.71924 13.399 9.186L14.389 8.613L13.39 6.883L12.398 7.455C12.0001 7.07798 11.5192 6.79983 10.994 6.643V5.5H8.995V6.644C8.4698 6.80083 7.98885 7.07899 7.591 7.456L6.6 6.883L5.6 8.615L6.591 9.187C6.46429 9.72024 6.46429 10.2758 6.591 10.809ZM9.995 11.497C9.79354 11.5044 9.59267 11.471 9.40438 11.399C9.21609 11.327 9.04424 11.2178 8.89912 11.0778C8.75399 10.9379 8.63856 10.7702 8.55972 10.5847C8.48087 10.3991 8.44024 10.1996 8.44024 9.998C8.44024 9.79641 8.48087 9.59688 8.55972 9.41135C8.63856 9.22581 8.75399 9.05807 8.89912 8.91815C9.04424 8.77823 9.21609 8.669 9.40438 8.59698C9.59267 8.52496 9.79354 8.49164 9.995 8.499C10.3831 8.51318 10.7505 8.67731 11.0201 8.95688C11.2896 9.23645 11.4402 9.60966 11.4402 9.998C11.4402 10.3863 11.2896 10.7596 11.0201 11.0391C10.7505 11.3187 10.3831 11.4828 9.995 11.497Z" />
          </svg>

        )
      // break;
      case 'path':
        return (
          <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 12V5.5C3 4.30653 3.47411 3.16193 4.31802 2.31802C5.16193 1.47411 6.30653 1 7.5 1C8.69347 1 9.83807 1.47411 10.682 2.31802C11.5259 3.16193 12 4.30653 12 5.5V12.5C12 13.163 12.2634 13.7989 12.7322 14.2678C13.2011 14.7366 13.837 15 14.5 15C15.163 15 15.7989 14.7366 16.2678 14.2678C16.7366 13.7989 17 13.163 17 12.5V5.83C16.3325 5.59409 15.7699 5.1298 15.4116 4.51919C15.0534 3.90858 14.9225 3.19098 15.0422 2.49321C15.1619 1.79545 15.5244 1.16246 16.0656 0.706126C16.6069 0.249797 17.292 -0.000488281 18 -0.000488281C18.708 -0.000488281 19.3931 0.249797 19.9344 0.706126C20.4756 1.16246 20.8381 1.79545 20.9578 2.49321C21.0775 3.19098 20.9466 3.90858 20.5884 4.51919C20.2301 5.1298 19.6675 5.59409 19 5.83V12.5C19 13.6935 18.5259 14.8381 17.682 15.682C16.8381 16.5259 15.6935 17 14.5 17C13.3065 17 12.1619 16.5259 11.318 15.682C10.4741 14.8381 10 13.6935 10 12.5V5.5C10 4.83696 9.73661 4.20107 9.26777 3.73223C8.79893 3.26339 8.16304 3 7.5 3C6.83696 3 6.20107 3.26339 5.73223 3.73223C5.26339 4.20107 5 4.83696 5 5.5V12H8L4 17L0 12H3ZM18 4C18.2652 4 18.5196 3.89464 18.7071 3.70711C18.8946 3.51957 19 3.26522 19 3C19 2.73478 18.8946 2.48043 18.7071 2.29289C18.5196 2.10536 18.2652 2 18 2C17.7348 2 17.4804 2.10536 17.2929 2.29289C17.1054 2.48043 17 2.73478 17 3C17 3.26522 17.1054 3.51957 17.2929 3.70711C17.4804 3.89464 17.7348 4 18 4Z" fill={ color } />
          </svg>
        )
      // break;
      case 'threshold':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill={ color } strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="12" y1="4" x2="12" y2="14" />
            <line x1="12" y1="4" x2="16" y2="8" />
            <line x1="12" y1="4" x2="8" y2="8" />
            <line x1="4" y1="20" x2="20" y2="20" />
          </svg>
        )
      // break;
      case 'info-circle':
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 5H11V7H9V5ZM9 9H11V15H9V9Z" fill={ color } />
        </svg>
      // break;
      case 'ref':
        return <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2H2.00002V18H16V6H12V2ZM1.52588e-05 0.992C1.52588e-05 0.444 0.447015 0 0.999015 0H13L18 5V18.993C18.0009 19.1243 17.976 19.2545 17.9266 19.3762C17.8772 19.4979 17.8043 19.6087 17.7121 19.7022C17.6199 19.7957 17.5101 19.8701 17.3892 19.9212C17.2682 19.9723 17.1383 19.9991 17.007 20H0.993015C0.730393 19.9982 0.479033 19.8931 0.293233 19.7075C0.107433 19.5219 0.00210991 19.2706 1.52588e-05 19.008V0.992ZM9.00002 9.5C8.33697 9.5 7.70109 9.23661 7.23225 8.76777C6.76341 8.29893 6.50002 7.66304 6.50002 7C6.50002 6.33696 6.76341 5.70107 7.23225 5.23223C7.70109 4.76339 8.33697 4.5 9.00002 4.5C9.66306 4.5 10.2989 4.76339 10.7678 5.23223C11.2366 5.70107 11.5 6.33696 11.5 7C11.5 7.66304 11.2366 8.29893 10.7678 8.76777C10.2989 9.23661 9.66306 9.5 9.00002 9.5ZM4.52702 15C4.64824 13.8984 5.1717 12.8803 5.9971 12.1407C6.82249 11.4012 7.89177 10.9922 9.00002 10.9922C10.1083 10.9922 11.1775 11.4012 12.0029 12.1407C12.8283 12.8803 13.3518 13.8984 13.473 15H4.52702Z" fill={ color } />
        </svg>
      // break;
      case 'credit':
        return <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.00002 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8947 0.48043 20 0.734784 20 1V17C20 17.2652 19.8947 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1.00002C0.734799 18 0.480445 17.8946 0.292908 17.7071C0.105372 17.5196 1.52588e-05 17.2652 1.52588e-05 17V1C1.52588e-05 0.734784 0.105372 0.48043 0.292908 0.292893C0.480445 0.105357 0.734799 0 1.00002 0ZM18 9H2.00002V16H18V9ZM18 5V2H2.00002V5H18Z" fill={ color } />
        </svg>
      case 'wallet':
        return <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 4.5H21V14.5H20V17.5C20 17.7652 19.8947 18.0196 19.7071 18.2071C19.5196 18.3946 19.2652 18.5 19 18.5H1.00002C0.734799 18.5 0.480445 18.3946 0.292908 18.2071C0.105372 18.0196 1.52588e-05 17.7652 1.52588e-05 17.5V1.5C1.52588e-05 1.23478 0.105372 0.98043 0.292908 0.792893C0.480445 0.605357 0.734799 0.5 1.00002 0.5H19C19.2652 0.5 19.5196 0.605357 19.7071 0.792893C19.8947 0.98043 20 1.23478 20 1.5V4.5ZM18 14.5H12C10.6739 14.5 9.40216 13.9732 8.46448 13.0355C7.5268 12.0979 7.00002 10.8261 7.00002 9.5C7.00002 8.17392 7.5268 6.90215 8.46448 5.96447C9.40216 5.02678 10.6739 4.5 12 4.5H18V2.5H2.00002V16.5H18V14.5ZM19 12.5V6.5H12C11.2044 6.5 10.4413 6.81607 9.87869 7.37868C9.31609 7.94129 9.00002 8.70435 9.00002 9.5C9.00002 10.2956 9.31609 11.0587 9.87869 11.6213C10.4413 12.1839 11.2044 12.5 12 12.5H19ZM12 8.5H15V10.5H12V8.5Z" fill={ color } />
        </svg>
      case 'get-ref':
        return <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2.5H2.00002V18.5H16V6.5H12V2.5ZM1.52588e-05 1.492C1.52588e-05 0.944 0.447015 0.5 0.999015 0.5H13L18 5.5V19.493C18.0009 19.6243 17.976 19.7545 17.9266 19.8762C17.8772 19.9979 17.8043 20.1087 17.7121 20.2022C17.6199 20.2957 17.5101 20.3701 17.3892 20.4212C17.2682 20.4723 17.1383 20.4991 17.007 20.5H0.993015C0.730393 20.4982 0.479033 20.3931 0.293233 20.2075C0.107433 20.0219 0.00210991 19.7706 1.52588e-05 19.508V1.492ZM8.00002 9.5V6.5H10V9.5H13V11.5H10V14.5H8.00002V11.5H5.00002V9.5H8.00002Z" fill={ color } />
        </svg>
      // break;
      case 'journal':
        return <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.52588e-05 0.993C0.0018453 0.730378 0.106916 0.479017 0.292528 0.293218C0.478141 0.107418 0.729396 0.00209465 0.992015 0H19.008C19.556 0 20 0.445 20 0.993V17.007C19.9982 17.2696 19.8931 17.521 19.7075 17.7068C19.5219 17.8926 19.2706 17.9979 19.008 18H0.992015C0.728829 17.9997 0.476512 17.895 0.290505 17.7088C0.104497 17.5226 1.51253e-05 17.2702 1.52588e-05 17.007V0.993ZM9.00002 2H2.00002V16H9.00002V2ZM11 2V16H18V2H11ZM12 4H17V6H12V4ZM12 7H17V9H12V7Z" fill={ color } />
        </svg>
      // break;
      case 'projects-all':
        return <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.00002 4V1C4.00002 0.734784 4.10537 0.48043 4.29291 0.292893C4.48045 0.105357 4.7348 0 5.00002 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8947 0.48043 18 0.734784 18 1V15C18 15.2652 17.8947 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14V19C14 19.552 13.55 20 12.993 20H1.00702C0.875142 20.0008 0.744413 19.9755 0.622338 19.9256C0.500263 19.8757 0.389249 19.8022 0.295674 19.7093C0.202099 19.6164 0.127808 19.5059 0.0770695 19.3841C0.0263309 19.2624 0.000144633 19.1319 1.52588e-05 19L0.00301528 5C0.00301528 4.448 0.453015 4 1.00902 4H4.00002ZM2.00202 6L2.00002 18H12V6H2.00202ZM6.00002 4H14V14H16V2H6.00002V4ZM4.00002 9H10V11H4.00002V9ZM4.00002 13H10V15H4.00002V13Z" fill={ color } />
        </svg>
      // break;
      case 'my-projects':
        return <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 20.5H1.00002C0.734799 20.5 0.480445 20.3946 0.292908 20.2071C0.105372 20.0196 1.52588e-05 19.7652 1.52588e-05 19.5V1.5C1.52588e-05 1.23478 0.105372 0.98043 0.292908 0.792893C0.480445 0.605357 0.734799 0.5 1.00002 0.5H17C17.2652 0.5 17.5196 0.605357 17.7071 0.792893C17.8947 0.98043 18 1.23478 18 1.5V19.5C18 19.7652 17.8947 20.0196 17.7071 20.2071C17.5196 20.3946 17.2652 20.5 17 20.5ZM16 18.5V2.5H2.00002V18.5H16ZM5.00002 5.5H13V7.5H5.00002V5.5ZM5.00002 9.5H13V11.5H5.00002V9.5ZM5.00002 13.5H13V15.5H5.00002V13.5Z" fill={ color } />
        </svg>
      // break;
      case 'application-process':
        return <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.59502 10.812C5.46822 10.2784 5.46822 9.72257 5.59502 9.189L4.60302 8.616L5.60301 6.884L6.59502 7.457C6.99318 7.07988 7.47448 6.80172 8.00002 6.645V5.5H10V6.645C10.532 6.803 11.012 7.085 11.405 7.457L12.397 6.884L13.397 8.616L12.405 9.189C12.5317 9.72225 12.5317 10.2777 12.405 10.811L13.397 11.384L12.397 13.116L11.405 12.543C11.0069 12.9201 10.5256 13.1983 10 13.355V14.5H8.00002V13.355C7.47448 13.1983 6.99318 12.9201 6.59502 12.543L5.60301 13.116L4.60302 11.384L5.59502 10.812V10.812ZM9.00002 11.5C9.39784 11.5 9.77937 11.342 10.0607 11.0607C10.342 10.7794 10.5 10.3978 10.5 10C10.5 9.60218 10.342 9.22064 10.0607 8.93934C9.77937 8.65804 9.39784 8.5 9.00002 8.5C8.60219 8.5 8.22066 8.65804 7.93935 8.93934C7.65805 9.22064 7.50002 9.60218 7.50002 10C7.50002 10.3978 7.65805 10.7794 7.93935 11.0607C8.22066 11.342 8.60219 11.5 9.00002 11.5ZM12 2H2.00002V18H16V6H12V2ZM1.52588e-05 0.992C1.52588e-05 0.444 0.447015 0 0.999015 0H13L18 5V18.993C18.0009 19.1243 17.976 19.2545 17.9266 19.3762C17.8772 19.4979 17.8043 19.6087 17.7121 19.7022C17.6199 19.7957 17.5101 19.8701 17.3892 19.9212C17.2682 19.9723 17.1383 19.9991 17.007 20H0.993015C0.730393 19.9982 0.479033 19.8931 0.293233 19.7075C0.107433 19.5219 0.00210991 19.2706 1.52588e-05 19.008V0.992Z" fill={ color } />
        </svg>
      // break;
      case 'portfolioCase':
        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M7 2V5H3C2.73478 5 2.48043 5.10536 2.29289 5.29289C2.10536 5.48043 2 5.73478 2 6V20C2 20.2652 2.10536 20.5196 2.29289 20.7071C2.48043 20.8946 2.73478 21 3 21H13.634L12.4793 19H4V16H12.8295L12.8295 15.9999L11.4481 14.7859L11.9019 14H4V7H20V9H21.4685L21.8304 10.8032L22 10.7458V6C22 5.73478 21.8946 5.48043 21.7071 5.29289C21.5196 5.10536 21.2652 5 21 5H17V2C17 1.73478 16.8946 1.48043 16.7071 1.29289C16.5196 1.10536 16.2652 1 16 1H8C7.73478 1 7.48043 1.10536 7.29289 1.29289C7.10536 1.48043 7 1.73478 7 2ZM22 12.8572L21.504 13.025V13.026L21.5029 13.025C21.4129 12.9438 21.3191 12.8667 21.2218 12.7939C20.8765 12.5354 20.4879 12.3316 20.07 12.196L20 11.8472V14H18.83H14.2113L14 14.366L14.916 15.171C14.8583 15.4443 14.8295 15.7222 14.8295 16H16.83C16.83 15.4696 17.0407 14.9609 17.4158 14.5858C17.7909 14.2107 18.2996 14 18.83 14C19.2638 14 19.683 14.1409 20.0265 14.3974C20.074 14.4329 20.1201 14.4705 20.1645 14.5103C20.1788 14.5232 20.193 14.5362 20.207 14.5495C20.2195 14.5614 20.2319 14.5735 20.2442 14.5858C20.6193 14.9609 20.83 15.4696 20.83 16C20.83 16.5304 20.6193 17.0391 20.2442 17.4142C20.168 17.4904 20.0863 17.5598 20 17.6221V19H16.1842C16.3187 19.1188 16.4612 19.2279 16.6097 19.3272C16.9124 19.5297 17.2421 19.6903 17.59 19.804L17.83 21H19.83L20.069 19.805C20.606 19.63 21.094 19.345 21.504 18.975L22 19.1428V12.8572ZM16.0793 19H14.7887L15 19.366L16.0793 19ZM13 12.0981V11H11V13H12.4793L13 12.0981ZM9 5V3H15V5H9Z" fill={ color } />
          <path d="M21.504 13.025L22.66 12.634L23.66 14.366L22.744 15.171C22.8593 15.7177 22.8593 16.2823 22.744 16.829L23.66 17.634L22.66 19.366L21.504 18.975C21.094 19.345 20.606 19.63 20.069 19.805L19.83 21H17.83L17.59 19.804C17.0593 19.6306 16.5708 19.3478 16.156 18.974L15 19.366L14 17.634L14.916 16.829C14.8007 16.2823 14.8007 15.7177 14.916 15.171L14 14.366L15 12.634L16.156 13.025C16.566 12.655 17.054 12.37 17.591 12.195L17.83 11H19.83L20.07 12.196C20.606 12.37 21.094 12.656 21.504 13.026V13.025ZM18.83 18C19.3604 18 19.8691 17.7893 20.2442 17.4142C20.6193 17.0391 20.83 16.5304 20.83 16C20.83 15.4696 20.6193 14.9609 20.2442 14.5858C19.8691 14.2107 19.3604 14 18.83 14C18.2996 14 17.7909 14.2107 17.4158 14.5858C17.0407 14.9609 16.83 15.4696 16.83 16C16.83 16.5304 17.0407 17.0391 17.4158 17.4142C17.7909 17.7893 18.2996 18 18.83 18Z" fill={ color } />
        </svg>
      // break;
      case 'personStar':
        return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 14.5V16.5C10.4087 16.5 8.88258 17.1321 7.75736 18.2574C6.63214 19.3826 6 20.9087 6 22.5H4C4 20.3783 4.84285 18.3434 6.34315 16.8431C7.84344 15.3429 9.87827 14.5 12 14.5ZM12 13.5C8.685 13.5 6 10.815 6 7.5C6 4.185 8.685 1.5 12 1.5C15.315 1.5 18 4.185 18 7.5C18 10.815 15.315 13.5 12 13.5ZM12 11.5C14.21 11.5 16 9.71 16 7.5C16 5.29 14.21 3.5 12 3.5C9.79 3.5 8 5.29 8 7.5C8 9.71 9.79 11.5 12 11.5ZM18 22L15.061 23.545L15.622 20.273L13.245 17.955L16.531 17.477L18 14.5L19.47 17.477L22.755 17.955L20.378 20.273L20.938 23.545L18 22Z" fill={ color } />
        </svg>
      // break;
      case 'list':
        return <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 20.5H1C0.734784 20.5 0.48043 20.3946 0.292893 20.2071C0.105357 20.0196 0 19.7652 0 19.5V1.5C0 1.23478 0.105357 0.98043 0.292893 0.792893C0.48043 0.605357 0.734784 0.5 1 0.5H17C17.2652 0.5 17.5196 0.605357 17.7071 0.792893C17.8946 0.98043 18 1.23478 18 1.5V19.5C18 19.7652 17.8946 20.0196 17.7071 20.2071C17.5196 20.3946 17.2652 20.5 17 20.5ZM16 18.5V2.5H2V18.5H16ZM5 5.5H13V7.5H5V5.5ZM5 9.5H13V11.5H5V9.5ZM5 13.5H10V15.5H5V13.5Z" fill={ color } />
        </svg>

      // break;
      case 'Проектная деятельность':
        return <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.00002 4V1C4.00002 0.734784 4.10537 0.48043 4.29291 0.292893C4.48045 0.105357 4.7348 0 5.00002 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8947 0.48043 18 0.734784 18 1V15C18 15.2652 17.8947 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14V19C14 19.552 13.55 20 12.993 20H1.00702C0.875142 20.0008 0.744413 19.9755 0.622338 19.9256C0.500263 19.8757 0.389249 19.8022 0.295674 19.7093C0.202099 19.6164 0.127808 19.5059 0.0770695 19.3841C0.0263309 19.2624 0.000144633 19.1319 1.52588e-05 19L0.00301528 5C0.00301528 4.448 0.453015 4 1.00902 4H4.00002ZM2.00202 6L2.00002 18H12V6H2.00202ZM6.00002 4H14V14H16V2H6.00002V4ZM4.00002 9H10V11H4.00002V9ZM4.00002 13H10V15H4.00002V13Z" fill={ color } />
        </svg>

      case 'Эффективный контракт':
        return <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 2V0H14V2H17.007C17.555 2 18 2.445 18 2.993V19.007C17.9997 19.2703 17.895 19.5227 17.7089 19.7089C17.5227 19.895 17.2703 19.9997 17.007 20H0.993C0.729721 19.9997 0.477302 19.895 0.291135 19.7089C0.104969 19.5227 0.000264735 19.2703 0 19.007V2.993C0 2.445 0.445 2 0.993 2H4ZM4 4H2V18H16V4H14V6H4V4ZM6 2V4H12V2H6Z" fill={ color } />
        </svg>

      case 'new-project':
        return <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.414 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8947 2.48043 20 2.73478 20 3V17C20 17.2652 19.8947 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1.00002C0.734799 18 0.480445 17.8946 0.292908 17.7071C0.105372 17.5196 1.52588e-05 17.2652 1.52588e-05 17V1C1.52588e-05 0.734784 0.105372 0.48043 0.292908 0.292893C0.480445 0.105357 0.734799 0 1.00002 0H8.41401L10.414 2ZM2.00002 2V16H18V4H9.58602L7.58602 2H2.00002ZM6.59101 10.809C6.4643 10.2758 6.4643 9.72024 6.59101 9.187L5.60002 8.615L6.60002 6.883L7.59101 7.456C7.98887 7.07899 8.46982 6.80083 8.99502 6.644V5.5H10.995V6.644C11.527 6.803 12.005 7.084 12.398 7.456L13.39 6.883L14.39 8.614L13.399 9.187C13.5257 9.72024 13.5257 10.2758 13.399 10.809L14.39 11.381L13.39 13.112L12.399 12.54C12.0011 12.9167 11.5201 13.1945 10.995 13.351V14.496H8.99502V13.35C8.46991 13.1935 7.98897 12.9157 7.59101 12.539L6.60002 13.111L5.60002 11.381L6.59101 10.808V10.809ZM9.99502 11.497C10.1965 11.5044 10.3973 11.471 10.5856 11.399C10.7739 11.327 10.9458 11.2178 11.0909 11.0778C11.236 10.9379 11.3515 10.7702 11.4303 10.5847C11.5091 10.3991 11.5498 10.1996 11.5498 9.998C11.5498 9.79641 11.5091 9.59688 11.4303 9.41135C11.3515 9.22581 11.236 9.05807 11.0909 8.91815C10.9458 8.77823 10.7739 8.669 10.5856 8.59698C10.3973 8.52496 10.1965 8.49164 9.99502 8.499C9.60693 8.51318 9.23947 8.67731 8.96993 8.95688C8.70039 9.23645 8.54978 9.60966 8.54978 9.998C8.54978 10.3863 8.70039 10.7596 8.96993 11.0391C9.23947 11.3187 9.60693 11.4828 9.99502 11.497Z" fill={ color } />
        </svg>
      // break;
      case 'record-nav':
        return <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2774_5138)">
            <path d="M63 2.25H6.75C5.85521 2.25104 4.99738 2.60696 4.36467 3.23967C3.73196 3.87238 3.37604 4.73021 3.375 5.625V66.375C3.37604 67.2698 3.73196 68.1276 4.36467 68.7603C4.99738 69.393 5.85521 69.749 6.75 69.75H63C63.2984 69.75 63.5845 69.6315 63.7955 69.4205C64.0065 69.2095 64.125 68.9234 64.125 68.625V68.1953L68.0032 66.2562C68.19 66.1627 68.3472 66.0191 68.457 65.8414C68.5668 65.6637 68.625 65.4589 68.625 65.25V54C68.625 53.7911 68.5668 53.5863 68.457 53.4086C68.3472 53.2309 68.19 53.0873 68.0032 52.9938L65.5155 51.75L68.0032 50.5062C68.19 50.4127 68.3472 50.2691 68.457 50.0914C68.5668 49.9137 68.625 49.7089 68.625 49.5V38.25C68.625 38.0411 68.5668 37.8363 68.457 37.6586C68.3472 37.4809 68.19 37.3373 68.0032 37.2438L65.5155 36L68.0032 34.7562C68.19 34.6627 68.3472 34.5191 68.457 34.3414C68.5668 34.1637 68.625 33.9589 68.625 33.75V22.5C68.625 22.2911 68.5668 22.0863 68.457 21.9086C68.3472 21.7309 68.19 21.5873 68.0032 21.4938L65.5155 20.25L68.0032 19.0062C68.19 18.9127 68.3472 18.7691 68.457 18.5914C68.5668 18.4137 68.625 18.2089 68.625 18V6.75C68.625 6.54109 68.5668 6.33631 68.457 6.15859C68.3472 5.98088 68.19 5.83726 68.0032 5.74383L64.125 3.80475V3.375C64.125 3.07663 64.0065 2.79048 63.7955 2.5795C63.5845 2.36853 63.2984 2.25 63 2.25ZM5.625 66.375V5.625C5.62533 5.32673 5.74397 5.04078 5.95487 4.82987C6.16578 4.61897 6.45173 4.50033 6.75 4.5H10.125V67.5H6.75C6.45173 67.4997 6.16578 67.381 5.95487 67.1701C5.74397 66.9592 5.62533 66.6733 5.625 66.375ZM12.375 67.5V4.5H61.875V67.5H12.375ZM66.375 64.5547L64.125 65.6797V53.5703L66.375 54.6953V64.5547ZM66.375 48.8047L64.125 49.9297V37.8203L66.375 38.9453V48.8047ZM66.375 33.0548L64.125 34.1798V22.0702L66.375 23.1952V33.0548ZM66.375 7.44525V17.3048L64.125 18.4298V6.32025L66.375 7.44525Z" fill={ color } />
            <path d="M47.25 21.375H27C26.7016 21.375 26.4155 21.4935 26.2045 21.7045C25.9935 21.9155 25.875 22.2016 25.875 22.5V49.5C25.875 49.7984 25.9935 50.0845 26.2045 50.2955C26.4155 50.5065 26.7016 50.625 27 50.625H47.25C47.5484 50.625 47.8345 50.5065 48.0455 50.2955C48.2565 50.0845 48.375 49.7984 48.375 49.5V22.5C48.375 22.2016 48.2565 21.9155 48.0455 21.7045C47.8345 21.4935 47.5484 21.375 47.25 21.375ZM37.125 36C35.9319 35.9987 34.7881 35.5241 33.9445 34.6805C33.1009 33.8369 32.6263 32.6931 32.625 31.5V30.375H34.875C35.7477 30.375 36.5579 29.9592 37.125 29.5701C37.6921 29.9592 38.5023 30.375 39.375 30.375H41.625V31.5C41.6237 32.6931 41.1491 33.8369 40.3055 34.6805C39.4619 35.5241 38.3181 35.9987 37.125 36ZM39.375 28.125C38.9343 28.125 38.2447 27.6484 37.9205 27.3295C37.7095 27.1201 37.4245 27.0023 37.1272 27.0017C36.8299 27.0012 36.5444 27.1178 36.3326 27.3264C36.0053 27.6484 35.3157 28.125 34.875 28.125H32.7675C33.0166 27.1587 33.5798 26.3025 34.3686 25.6913C35.1574 25.08 36.1271 24.7483 37.125 24.7483C38.1229 24.7483 39.0926 25.08 39.8814 25.6913C40.6702 26.3025 41.2334 27.1587 41.4825 28.125H39.375ZM28.125 23.625H33.3994C32.47 24.24 31.7074 25.0755 31.1797 26.0571C30.6519 27.0386 30.3755 28.1356 30.375 29.25V31.5C30.3742 33.231 31.0411 34.8957 32.2367 36.1474C30.6725 36.4549 29.2386 37.2304 28.125 38.3712V23.625ZM46.125 48.375H28.125V43.875C28.1267 42.3837 28.7198 40.9539 29.7744 39.8994C30.8289 38.8448 32.2587 38.2517 33.75 38.25H40.5C41.9913 38.2517 43.4211 38.8448 44.4756 39.8994C45.5302 40.9539 46.1233 42.3837 46.125 43.875V48.375ZM42.0133 36.1474C43.2089 34.8957 43.8758 33.231 43.875 31.5V29.25C43.8745 28.1356 43.5981 27.0386 43.0703 26.0571C42.5426 25.0755 41.78 24.24 40.8506 23.625H46.125V38.3712C45.0114 37.2304 43.5775 36.4549 42.0133 36.1474Z" fill={ color } />
            <path d="M41.625 52.875H28.125C27.8266 52.875 27.5405 52.9935 27.3295 53.2045C27.1185 53.4155 27 53.7016 27 54C27 54.2984 27.1185 54.5845 27.3295 54.7955C27.5405 55.0065 27.8266 55.125 28.125 55.125H41.625C41.9234 55.125 42.2095 55.0065 42.4205 54.7955C42.6315 54.5845 42.75 54.2984 42.75 54C42.75 53.7016 42.6315 53.4155 42.4205 53.2045C42.2095 52.9935 41.9234 52.875 41.625 52.875Z" fill={ color } />
            <path d="M47.25 12.375H27C26.7016 12.375 26.4155 12.4935 26.2045 12.7045C25.9935 12.9155 25.875 13.2016 25.875 13.5V18C25.875 18.2984 25.9935 18.5845 26.2045 18.7955C26.4155 19.0065 26.7016 19.125 27 19.125H47.25C47.5484 19.125 47.8345 19.0065 48.0455 18.7955C48.2565 18.5845 48.375 18.2984 48.375 18V13.5C48.375 13.2016 48.2565 12.9155 48.0455 12.7045C47.8345 12.4935 47.5484 12.375 47.25 12.375ZM46.125 16.875H28.125V14.625H46.125V16.875Z" fill={ color } />
            <path d="M46.125 55.125C46.7463 55.125 47.25 54.6213 47.25 54C47.25 53.3787 46.7463 52.875 46.125 52.875C45.5037 52.875 45 53.3787 45 54C45 54.6213 45.5037 55.125 46.125 55.125Z" fill={ color } />
          </g>
          <defs>
            <clipPath id="clip0_2774_5138">
              <rect width="72" height="72" fill="white" />
            </clipPath>
          </defs>
        </svg>

      // break;
      case 'bell':
        return (
          <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 18H0V16H1V9.031C1 4.043 5.03 0 10 0C14.97 0 19 4.043 19 9.031V16H20V18ZM3 16H17V9.031C17 5.148 13.866 2 10 2C6.134 2 3 5.148 3 9.031V16ZM7.5 19H12.5C12.5 19.663 12.2366 20.2989 11.7678 20.7678C11.2989 21.2366 10.663 21.5 10 21.5C9.33696 21.5 8.70107 21.2366 8.23223 20.7678C7.76339 20.2989 7.5 19.663 7.5 19Z" fill={ color } />
          </svg>
        )
      default: <></>
    }
  }

  return (<div>{ choseIcon() }</div>
  )
}

export default Icon

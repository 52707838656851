import { TableCellType, TableRowType, } from '@/legacy/types'


const defineCellIndex = (row: TableRowType, cell: TableCellType) => {
  if (Array.isArray(cell.index)) {
    const rowKeys = Object.keys(row)
    const result = cell.index.find(cellItem => rowKeys.some(rowItem => rowItem === cellItem))

    return String(result)
  } return cell.index
}


export default defineCellIndex

import { useMemo, useState, } from 'react'
import useEcSubscription from '../../../../gql/hooks/effectiveContractHooks/useEcSubscription2'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import { TitleH3, } from '../../../common/GeneralElements/GeneralElements'
import Popup from '../../../common/Popup/Popup'
import RadioButtonFacade from '../../../common/RadioButtonFacade/RadioButtonFacade'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import AmsBasicIndicatorsTab from '../../amsTabs/AmsBasicIndicatorsTab'
import CareerGuidanceWorkTab from '../../amsTabs/CareerGuidanceWorkTab'
import EducationalActivitiesTab from '../../amsTabs/EducationalActivitiesTab'
import OtherActivitiesTab from '../../amsTabs/OtherActivitiesTab'
import ScientificActivitiesTab from '../../amsTabs/ScientificActivitiesTab'
import tabButtons from '../../amsTabs/tabButtons'


const AmsDirectorTemplate = ({
  userRole,
}: any) => {
  const [
    contentIndex,
    setContentIndex,
  ] = useState(0)
  const [
    isShowRejectReason,
    setIsShowRejectReason,
  ] = useState(false)
  const [
    formInfo,
    setFormInfo,
  ] = useState<any>(null)

  const { dataTypeUpdated, setDataTypeUpdated, } = useEcSubscription()


  const returnDataForDisplayRejectReason = (data: any) => {
    setIsShowRejectReason(true)
    setFormInfo({
      reason: data.reason,
    })
  }


  const amsBasicIndicatorsTab = useMemo(() => {
    return <AmsBasicIndicatorsTab
      contentType="table"
      dataTypeUpdated={ dataTypeUpdated }
      setDataTypeUpdated={ setDataTypeUpdated }
      userRole={ userRole }
    />
  }, [
    dataTypeUpdated,
    setDataTypeUpdated,
    userRole,
  ])

  const careerGuidanceWorkTab = useMemo(() => {
    return <CareerGuidanceWorkTab
      contentType="table"
      dataTypeUpdated={ dataTypeUpdated }
      setDataTypeUpdated={ setDataTypeUpdated }
      userRole={ userRole }
    />
  }, [
    dataTypeUpdated,
    setDataTypeUpdated,
    userRole,
  ])

  const educationalActivitiesTab = useMemo(() => {
    return <EducationalActivitiesTab
      contentType="table"
      dataTypeUpdated={ dataTypeUpdated }
      setDataTypeUpdated={ setDataTypeUpdated }
      returnDataForDisplayRejectReason={ returnDataForDisplayRejectReason }
      userRole={ userRole }
    />
  }, [
    dataTypeUpdated,
    setDataTypeUpdated,
    userRole,
  ])

  const scientificActivitiesTab = useMemo(() => {
    return <ScientificActivitiesTab
      contentType="table"
      dataTypeUpdated={ dataTypeUpdated }
      setDataTypeUpdated={ setDataTypeUpdated }
      returnDataForDisplayRejectReason={ returnDataForDisplayRejectReason }
      userRole={ userRole }
    />
  }, [
    dataTypeUpdated,
    setDataTypeUpdated,
    userRole,
  ])

  const otherActivitiesTab = useMemo(() => {
    return <OtherActivitiesTab
      contentType="table"
      dataTypeUpdated={ dataTypeUpdated }
      setDataTypeUpdated={ setDataTypeUpdated }
      returnDataForDisplayRejectReason={ returnDataForDisplayRejectReason }
      userRole={ userRole }
    />
  }, [
    dataTypeUpdated,
    setDataTypeUpdated,
    userRole,
  ])


  const content = useMemo(() => {
    return [
      amsBasicIndicatorsTab,
      careerGuidanceWorkTab,
      educationalActivitiesTab,
      scientificActivitiesTab,
      otherActivitiesTab,
    ]
  }, [
    amsBasicIndicatorsTab,
    careerGuidanceWorkTab,
    educationalActivitiesTab,
    scientificActivitiesTab,
    otherActivitiesTab,
  ])


  return (
    <div className="effective-contract">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Эффективный контракт АУП"
      />

      <h2 className="effective-contract__title">Эффективный контракт АУП</h2>

      <div className="effective-contract__buttons-wrapper">
        <RadioButtonFacade
          content={ tabButtons }
          BtnComponent={ ButtonSimple }
          activeIndex={ contentIndex }
          setActiveIndex={ setContentIndex }
        />
      </div>

      { content[contentIndex] }


      { isShowRejectReason && (
        <Popup
          isOpen={ isShowRejectReason }
          handlePopup={ () => {
            setIsShowRejectReason(false)
            setFormInfo(null)
          } }
        >
          <>
            <TitleH3>Причина отказа:</TitleH3>
            { formInfo.reason }
          </>
        </Popup>
      ) }
    </div>
  )
}


export default AmsDirectorTemplate

import { useState, useMemo, } from 'react'
import './StudentInner.less'
import { IconChevronDown, IconChevronUp, } from '@tabler/icons'
import { Table, } from 'antd'
import info from '@/shared/assets/images/info-icon.svg'
import TooltipTgu from '@/shared/ui/TooltipTgu/TooltipTgu'
import LecturePopup from './LecturePopup'
import { useStudentJournal, } from '../../../../gql/hooks/useJournal'
import { useSelector, } from 'react-redux'
import { format, } from 'date-fns'
import TypeFilter from '../../../TypeFilter/TypeFilter'
import { TooltipPlacementVariant, } from '@/shared/ui/TooltipTgu/types'


function StudentInner () {
  const [
    isOpen,
    setIsOpen,
  ] = useState(false)
  const [
    isTypes,
    setIsTypes,
  ] = useState(false)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const dis = useSelector(state => state.studJour.discipline)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const group = useSelector(state => state.studJour.group)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studName = useSelector(state => state.auth.me.data.full_name)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const lessonType = useSelector(state => state.studJour.lesson)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const semester = useSelector(state => state.studJour.semester)
  function handlePopup () {
    setIsOpen(!isOpen)
  }

  const type = lessonType ? lessonType : dis.types[0]

  const { data, error, loading, } = useStudentJournal(group, dis.discipline, type, semester)

  const table = useMemo(() => {
    if (data) {
      const journal = data.getStudentJournal
      const columns = [
        {
          title: 'ФИО студента',
          width: 339,
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
        },
      ]

      journal.lessons.forEach(
        (lesson: any) => {
          const date = format(new Date(lesson.date), 'MM.dd.yy')

          columns.push(
            {
              width: 214,
              // @ts-expect-error TS(2322): Type 'Element' is not assignable to type 'string'.
              title: (
                <TooltipTgu
                  children={
                    <div
                      style={ { cursor: 'pointer', } }
                      onClick={ handlePopup }
                      className="infoTitle"
                    >
                      <img src={ info } alt="info" />
                      <p>{ journal.discipline }</p>
                      <div>
                        { date }
                      </div>
                    </div>
                  }
                  title={journal.discipline}
                  placement={TooltipPlacementVariant.bottom}/>
              ),
              dataIndex: 'mark',
              key: '1',
            }
          )
        }
      )

      // @ts-expect-error TS(2345): Argument of type '{ width: number; title: string; ... Remove this comment to see the full error message
      columns.push({
        width: 214,
        title: '',
        key: '1',
      }, {
        width: 214,
        title: '',
        key: '1',
      }, {
        width: 214,
        title: '',
        key: '1',
      }, {
        width: 214,
        title: '',
        key: '1',
      }, {
        width: 214,
        title: '',
        key: '1',
      })

      const content = [
        {
          key: '1',
          name: studName,
          mark: 'Н',
        },
      ]

      return (
        <>
          <Table
            className={ 'd-scrollbar' }
            // @ts-expect-error TS(2322): Type '{ title: string; width: number; dataIndex: s... Remove this comment to see the full error message
            columns={ columns }
            dataSource={ content }
            scroll={ { x: 'true', } }
          />

        </>
      )
    } else if (loading) {
      return (<div>......загрузка</div>)
    }
    return (<div>errror</div>)


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
  ])


  return (
    <>
      <div className="tableSet">
        <div
          className="lectures"
          onClick={ () => setIsTypes(!isTypes) }
        >
          { lessonType }

          { isTypes
            ? <IconChevronUp size={ 20 } />
            : <IconChevronDown size={ 20 } /> }

          <TypeFilter isOpen={ isTypes } items={ dis.types } /></div>
      </div>

      { table }

      <LecturePopup isOpen={ isOpen } close={ handlePopup } />
    </>
  )
};

export default StudentInner

import React, { useState, useMemo, } from 'react'
import './AchivmentDetails.less'
import BackToButton from '../../common/BackToButton/BackToButton'
import ChooseField from '../../common/Fields/ChooseField/ChooseField'
import RangeField from '../../common/Fields/RangeField/RangeField'
import { useAchievements, useAchievementTypes, } from '../../../gql/hooks/useAchievements'
import DetailsCard from '../DetailsCard/DetailsCard'
import ArchiveAchivments from './ArchiveAchivments/ArchiveAchivments'
import AddAchivmentPopup from '../AddAchivmentPopup/AddAchivmentPopup'
import useAchievementStatuses from '../../../gql/hooks/portfolio/useAchievementStatuses'
import useAchievementDirections from '../../../gql/hooks/portfolio/useAchievementDirections'
import useAchievementChanged from '../../../gql/hooks/portfolio/useAchievementChanged'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import LinkButton from '../../common/LinkButton/LinkButton'
import { getUnixTime, } from 'date-fns'
import CalendarSimple from '../../common/CalendarSimple/CalendarSimple'
import { useSelector, } from 'react-redux'
import useGetRole from '../../../redux/hooks/common/useGetRole'
import { CardAddItem, } from '@/shared'


const AchivmentDetails = ({
  setIsDetails,
  i,
  guid,
  type,
}: any) => {
  const [
    achivmentType,
    setAchivmentType,
  ] = useState(null)
  const [
    achivmentStatus,
    setAchivmentStatus,
  ] = useState(null)
  const [
    date,
    setDate,
  ] = useState(null)
  const [
    fromPoints,
    setFromPoints,
  ] = useState('')
  const [
    toPoints,
    setToPoints,
  ] = useState('')
  const [
    archive,
    setArchive,
  ] = useState([])
  const [
    isAdd,
    setIsAdd,
  ] = useState(false)
  const [
    clearCalender,
    setClearCalender,
  ] = useState(null)
  const [
    localFilter,
    setLocalFilter,
  ] = useState({})
  const userRole = useGetRole()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studentGuid = useSelector(state => state.auth.me.data.guid)


  const { directions: achievementKey, } = useAchievementDirections({
    toReturn: 'keyByName',
    name: i.name ? i.name : i.direction,
  })

  const getAchievementTypes = useAchievementTypes((typeof achievementKey === 'number') ? achievementKey : 8)
  const filterTypes = useMemo(() => {
    if (!getAchievementTypes.loading && getAchievementTypes.data) {
      const data = getAchievementTypes.data.achievementTypes
      return data.filter((type: any) => type.key !== 180 && type.key !== 367).map((achievementType: any) => achievementType.name)
    }
  }, [
    getAchievementTypes.loading,
    getAchievementTypes.data,
  ])

  const filterStatuses = useAchievementStatuses()


  const filters = [
    {
      type: 'choose',
      title: 'Тип достижений',
      value: achivmentType,
      items: filterTypes,
      width: '74%',
      handler: setAchivmentType,
    },
    {
      type: 'choose',
      title: 'Статус достижения',
      value: achivmentStatus,
      items: filterStatuses?.map((status: any) => status.name),
      width: '25%',
      handler: setAchivmentStatus,
    },
    {
      type: 'date',
      width: 'auto',
      handler: (date: any) => setDate({
        // @ts-expect-error TS(2345): Argument of type '{ start: number; end: number; }'... Remove this comment to see the full error message
        start: getUnixTime(date[0]),
        end: getUnixTime(date[1]),
      }),
      title: date,
      placeHold: 'Выберите дату',
    },
    {
      type: 'range',
      width: '20rem',
      toHandler: setToPoints,
      fromHandler: setFromPoints,
      placeHold: '0 баллов',
    },
  ]

  const {
    data,
    error,
    loading,
    refetch,
  } = useAchievements({
    filter: {
      id: guid ? guid : (/^student/.test(userRole)) && studentGuid,
      direction: [
        (i.name ? i.name : i.direction),
      ],
      ...localFilter,
    },
    limit: 1000,
  })

  useAchievementChanged(refetch)

  const achivments = useMemo(() => {
    if (loading) {
      return <div>.....loading...</div>
    } else if (error) {
      return <div>....error...</div>
    } else if (data) {
      setArchive(data.achievements.items.filter((item: any) => item.status === 'archived'))
      if (userRole === 'student' && type === 'stranger') {
        return data.achievements.items.filter((item: any) => item .status === 'available').map((item: any, index: any) => (
          <DetailsCard key={ index } item={ item } />))
      }
      return data.achievements.items.filter((item: any) => item.status !== 'archived').map((item: any, index: any) => (
        <DetailsCard key={ index } item={ item } />))
    }
  }, [
    data,
    error,
    loading,
    type,
    userRole,
  ])

  return (
    <div className="achiveDetails">
      <BackToButton
        onClick={ () => setIsDetails(false) }
        title={ type !== 'stranger' ? 'Вернуться к портфолио' : 'Вернуться к категориям' }
      />

      <h2 className="secondTitle">{ i.name ? i.name : i.direction }</h2>

      <div className='detailsFilter'>
        { filters.map((i, index) => {
          if (i.type === 'choose') {
            return (
              <ChooseField
                key={ index }
                title={ i.title }
                value={ i.value }
                handler={ i.handler }
                styles={ { width: `${ i.width }`, } }
                items={ i.items }
              />
            )
          } return null
        }) }
      </div>


      <div className='detailsFilter'>
        <div style={ {
          display: 'flex',
          justifyContent: 'space-between',
        } }>
          { filters.map((i, index) => {
            if (i.type === 'date') {
              return (
                <CalendarSimple
                  key={ index }
                  styles={ {
                    width: '18rem',
                    marginRight: '2rem',
                  } }
                  returnRange={ i.handler }
                  defaultRangeDate={ clearCalender }
                  placeholderColor='#7F8190'
                />
              )
            } else if (i.type === 'range') {
              return (
                <RangeField
                  fromValue={ fromPoints }
                  toValue={ toPoints }
                  key={ index }
                  styles={ {
                    width: `${ i.width }`,
                    height: '62px',
                  } }
                  placeholder={ i.placeHold }
                  // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                  fromHandler={ (event: any) => i.fromHandler(event.target.value) }
                  // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                  toHandler={ (event: any) => i.toHandler(event.target.value) }
                />
              )
            } return null
          }) }
        </div>


        <div className="filterAccept" style={ { width: 'auto', } }>
          <LinkButton
            style={ {
              marginRight: '1rem',
              color: '#C5C5C5',
            } }
            type="clearFilters"
            onClickHandler={ () => {
              setAchivmentType(null)
              setAchivmentStatus(null)
              setFromPoints('')
              setToPoints('')
              // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
              setClearCalender(Date.now())
              setLocalFilter({})
            } }
          />

          <ButtonSimple
            onClickHandler={ () => setLocalFilter({
              type: achivmentType || undefined,
              status: filterStatuses?.find((status: any) => status.name === achivmentStatus)?.key || undefined,
              pointsMin: Number(fromPoints),
              pointsMax: Number(toPoints),
              // @ts-expect-error TS(2339): Property 'start' does not exist on type 'never'.
              dateStart: date?.start || undefined,
              // @ts-expect-error TS(2339): Property 'end' does not exist on type 'never'.
              dateEnd: date?.end || undefined,
            }) }
            value="применить"
            active
            variant="alone"
            styles={ { border: 'none', } }
          />
        </div>
      </div>


      <div className="detailCards">
        { type !== 'stranger' && !!i?.available && (
          <CardAddItem
            title="Добавить достижение"
            onClick={ () => setIsAdd(!isAdd) }
          // TODO: Прокинуть стиль через className
          // styles={ { height: '190px', } }
          />
        ) }

        { achivments }
      </div>

      { archive.length > 0 && (type !== 'stranger' || userRole === 'urtos-moder') && <ArchiveAchivments archive={ archive } /> }

      <AddAchivmentPopup isOpen={ isAdd } setIsOpen={ setIsAdd } setIsDetails={ setIsDetails } category={ i } />
    </div>
  )
}

export default AchivmentDetails

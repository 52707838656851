import { EC_UPDATE_DATA, } from '@/legacy/gql/hooks/effectiveContractHooks/useUpdateEcData'
import { UUID, } from '@/legacy/types'
import { useMutation, } from '@apollo/client'


interface OnUpdateHandler {
  rec_id: number,
  new_data: Object,
  new_appeal?: Object,
  user_ids?: UUID[],
}


const useGetHandlerForUpdateEcData = () => {
  const [
    updateData,
    { data, },
  ] = useMutation(EC_UPDATE_DATA)

  const onUpdateHandler = ({
    rec_id,
    new_data,
    new_appeal,
    user_ids,
  }: OnUpdateHandler) => updateData({
    variables: {
      rec_id,
      new_data,
      new_appeal,
      user_ids,
    },
  })

  return {
    onUpdateHandler,
    data,
  }
}


export default useGetHandlerForUpdateEcData

import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  discipline: null,
  group: null,
  lesson: 'Выберите тип',
  semester: null,
}

export const studentJournal = createSlice({
  name: 'studJournal',
  initialState,
  reducers: {
    setDiscipline: (state, action) => {
      state.discipline = action.payload
    },
    setGroup: (state, action) => {
      state.group = action.payload
    },
    setLesson: (state, action) => {
      state.lesson = action.payload
    },
    setSemester: (state, action) => {
      state.semester = action.payload
    },
  },
})


export const { setDiscipline, setGroup, setLesson, setSemester, } = studentJournal.actions

export default studentJournal.reducer

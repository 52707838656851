import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Тема',
    index: 'grant_topic',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Сумма финансовой поддержки',
    index: 'category',
    editable: true,
    editType: 'select',
    selectOptions: [
      'до 100 тыс. руб.',
      'от 100 до 500 тыс. руб.',
      'от 500 до 1 млн. руб.',
      'от 1 до 3 млн. руб.',
      'свыше 3 млн. руб.',
    ],
  },
  {
    title: 'Роль',
    index: 'role',
    editable: true,
    editType: 'select',
    selectOptions: [
      'Руководитель',
      'Участник',
    ],
    isAlternativeHandler: true,
    is_2_8_alternativeSelect: true,
    indexDescription: 'deepAuthorRole',
  },
  // {
  //   title: 'Документ подтверждающий участие в гранте',
  //   index: 'document',
  //   editable: true,
  //   editType: 'upload',
  // },
  {
    title: 'Номер гранта',
    index: 'grant_number',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Баллы',
    index: 'scores',
    indexDescription: 'deepAuthorScores',
    editable: false,
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '8rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Тема',
    index: 'theme',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Сумма финансовой поддержки',
    index: 'category',
    editable: true,
    editType: 'select',
    selectOptions: [
      'до 100 тыс. руб.',
      'от 100 до 500 тыс. руб.',
      'от 500 до 1 млн. руб.',
      'от 1 до 3 млн. руб.',
      'свыше 3 млн. руб.',
    ],
  },
  {
    title: 'Роль',
    index: 'role',
    editable: true,
    editType: 'select',
    selectOptions: [
      'Руководитель',
      'Участник',
    ],
    isAlternativeHandler: true,
    is_2_8_alternativeSelect: true,
    indexDescription: 'deepAuthorRole',
  },
  // {
  //   title: 'Документ подтверждающий участие в гранте',
  //   index: 'document',
  //   editable: true,
  //   editType: 'upload',
  // },
  {
    title: 'Номер гранта',
    index: 'grant_number',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: true,
    editType: 'inputNumbers',
    indexDescription: 'deepAuthorScores',
  },
  {
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
    editable: false,
  },
]

import React from 'react'
import Checkbox from '@mui/material/Checkbox'


function CheckBoxItem ({
  onClick,
  value,
  isChecked,
}: any) {
  return <li key={ value }><Checkbox
    checked={ isChecked ? isChecked : false }
    sx={ {
      '&.Mui-checked': {
        color: '#7671DD',
      },
    } } onChange={ onClick } />{ value }</li>
}

export default CheckBoxItem

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, } from 'react'
import './ModerCard.less'
import clsx from 'clsx'
import { IconChevronUp, IconChevronDown, IconCircleCheck, IconCircleX, IconPencil, } from '@tabler/icons'
import FullCardPopup from '@/legacy/components/Projects/FullCardPopup/FullCardPopup'
import { useModify, useCreateProject, } from '@/legacy/gql/hooks/useProjects'
import { format, } from 'date-fns'
import { useDispatch, } from 'react-redux'
import { setFixProject, setProjectId, setEditStatus, } from '@/legacy/redux/ProjectsSlice/ProjectSlice'
import Image from '@/legacy/components/common/Image/Image'
import { useAppContext, } from '@/app/providers/AppProvider'
import ru from 'date-fns/locale/ru'
import useRedirect from '@/legacy/hooks/useRedirect'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'


function ModerCard ({
  type,
  i,
  data,
  status,
}: any) {
  const redirect = useRedirect()
  const dispatch = useDispatch()

  const [
    isCardOpen,
    setIsCardOpen,
  ] = useState(false)
  const [
    isDeclineOpen,
    setIsDeclineOpen,
  ] = useState(false)
  const [
    isFullPopup,
    setIsFullPopup,
  ] = useState(false)

  const { name, description, activity, req_id, created,
    dateStart, dateEnd, supervisor, } = data


  const [
    paModify,
  ] = useModify()
  const [
    paControl,
  ] = useCreateProject()

  const { addNotification, openToast, } = useAppContext()

  const role = useGetRole()


  function openCard (e: any) {
    e.stopPropagation()
    setIsCardOpen(!isCardOpen)
  }

  function openDecline (e: any) {
    e.stopPropagation()
    setIsDeclineOpen(!isDeclineOpen)
  }

  function handleCardClick (e: any) {
    if (type !== 'Предложенные проекты') {
      setIsFullPopup(!isFullPopup)
    }
  }

  function approveApplProj (e: any) {
    e.stopPropagation()
    const params = {
      event: 'change',
      payload: {
        req_id: i.req_id,
        args: { status: 'ready', },
        action: 'update',
      },
    }
    paControl({ variables: { params, }, })
      .then(() => {
        addNotification?.('Проектная деятельность', [
          supervisor.id,
        ], {
          message: 'Заявка прошла модерацию и в ближайшее время будет размещена в Бирже.',
          tab: 'Проектные заявки',
          reqId: i.req_id,
        }, {
          path: '/my-applications',
          from: 'Проектная деятельность',
        })
      })
      .then(res => {
        openToast?.({ message: 'Заявка одобрена', })
      })
      .catch(e => {
        console.log('onCreate error', e)
      })
  }

  function approveInit () {
    const params = {
      event: 'change',
      payload: {
        req_id,
        status: 'approved',
      },
    }
    paModify({ variables: { params, }, })
      .then(res => {
        openToast?.({ message: 'Заявка одобрена', })
      })
      .then(() => {
        addNotification?.('Проектная деятельность', [
          data.creator_id,
        ], {
          message: 'Ваша заявка одобрена, свяжитесь с руководителем проекта.',
          tab: 'Предложенные темы проектов',
          reqId: req_id,
        }, {
          path: '/my-applications',
          from: 'Проектная деятельность',
        })
      })
      .catch(e => {
        console.log('onCreate error', e)
      })
  }


  function rejectAppl (e: any) {
    e.stopPropagation()
    const params = {
      event: 'change',
      payload: {
        req_id: i.req_id,
        args: { status: 'rejected', },
        action: 'update',
      },
    }
    paControl({ variables: { params, }, })
      .then(res => {
        openToast?.({ message: 'Заявка отклонена', })
        console.log('Статус изменен', res)
      })
      .then(() => {
        addNotification?.('Проектная деятельность', [
          supervisor.id,
        ], {
          message: 'Заявка возвращена на доработку.',
          tab: 'Проектные заявки',
          reqId: i.req_id,
        }, {
          path: '/my-applications',
          from: 'Проектная деятельность',
        })
      })
      .catch(e => {
        console.log('onCreate error', e)
      })
  }

  function rejectInit (e: any) {
    const params = {
      event: 'change',
      payload: {
        req_id,
        status: 'rejected',
      },
    }
    paModify({ variables: { params, }, })
      .then(res => {
        openToast?.({ message: 'Заявка отклонена', })
      })
      .then(() => {
        addNotification?.('Проектная деятельность', [
          data.creator_id,
        ], {
          message: 'Ваша заявка отклонена.',
          tab: 'Предложенные темы проектов',
          reqId: req_id,
        }, {
          path: '/my-applications',
          from: 'Проектная деятельность',
        })
      })
      .then(() => openDecline(e))
      .catch(e => {
        console.log('onCreate error', e)
      })
  }

  function edit () {
    redirect('/apply-project')
    dispatch(setFixProject(data))
    dispatch(setEditStatus(i?.status))
    dispatch(setProjectId(i.req_id))
  }

  return (
    <div key={ name } className="applyCard moderCard" onClick={ handleCardClick }>
      <div className="tit" style={ {
        display: 'flex',
        justifyContent: 'space-between',
      } }>
        <h2>{ name }</h2>
        { type === 'Предложенные проекты' && <div>
          { isCardOpen ? <IconChevronUp size={ 35 } color="#C5C5C5" onClick={ openCard } style={ { cursor: 'pointer', } } /> : <IconChevronDown size={ 35 } color="#C5C5C5" onClick={ openCard } style={ { cursor: 'pointer', } } /> }
        </div> }
      </div>
      { type === 'Проектные заявки' ?
        <>
          <div className="deadlines">
            <h3>Примерные сроки реализации:</h3>
            <p>{ (dateStart && dateEnd) ? `${ format(new Date(dateStart), 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(new Date(dateStart), 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(new Date(dateStart), 'dd MMMM yyyy', { locale: ru, }).slice(4) } - ${ format(new Date(dateEnd), 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(new Date(dateEnd), 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(new Date(dateEnd), 'dd MMMM yyyy', { locale: ru, }).slice(4) }` : '' }</p>
          </div>
          { supervisor && <div className="teacher">
            <Image id={ supervisor?.id } /* style={{width: "24px", height: "24px", borderRadius: "20px"}} */ />
            <div>
              <p className="subtitle">Руководитель</p>
              <p className="value">{ supervisor.full_name }</p>
            </div>
          </div> }
        </> : <></>
      }
      { type !== 'Проектные заявки' &&
        <div className="teacher" style={ { marginTop: '40px', } }>
          <Image id={ data?.creator_id } /* style={{width: "24px", height: "24px", borderRadius: "20px"}} */ />
          <div>
            <p className="subtitle">Инициатор</p>
            <p className="value">{ data?.full_name }</p>
          </div>
        </div>
      }
      { type !== 'Проектные заявки' && <div className={ clsx(isCardOpen && 'discription', !isCardOpen && 'disHidden') }>
        <h3>Описание проблемы</h3>
        <p>{ description }</p>
        <h3>Описание решения с помощью проекта</h3>
        <p>{ activity }</p>
      </div> }
      <div className={ clsx('applCardFoot', type === 'Проектные заявки' && 'cardFootEqual') }>
        { (i?.status === 'wait' || data?.status === 'new' || role === 'pa_moder') && <>{ type === 'Проектные заявки' && <button className="statusButton decline moder" onClick={ edit }>Редактировать<IconPencil color="#7671DD" /></button> }
          { (i?.status === 'wait' || data?.status === 'new') && <button className="statusButton decline moder" onClick={ openDecline }>Отклонить<IconCircleX color="#FF5E5E" /></button> }
          { (i?.status === 'wait' || data?.status === 'new') && <button className="statusButton buttonActive moder" onClick={ status === 'project' ? approveApplProj : approveInit }>Принять<IconCircleCheck /></button> }</> }
        { (data?.status === 'approved' || i?.status === 'ready') && <div className="appr">Заявка одобрена</div> }
        { data?.status === 'projected' && <div className="appr">Подана заявка на проект</div> }
        { (data?.status === 'rejected' || i?.status === 'rejected') && <div className="rej">Заявка отклонена</div> }
      </div>
      <div className={ clsx('declineWrap', !isDeclineOpen && 'declineHidden') }>
        <textarea placeholder='По какой причине вы хотите отклонить заявку?' onClick={ e => e.stopPropagation() } />
        { isDeclineOpen && <div className="btWrap">
          <button onClick={ openDecline }>Отменить</button>
          <button style={ {
            background: '#FF5E5E',
            color: 'white',
          } } onClick={ status === 'project' ? e => rejectAppl(e) : e => rejectInit(e) }>Отклонить<IconCircleX color="white" /></button>
        </div> }
      </div>
      <div className="cardId">
        <p>{ `ID: ${ req_id ? req_id : i.req_id }` }</p>
        <p>{ created ? `${ format(new Date(created), 'yyyy.MM.dd') }` : `${ format(new Date(i.updated), 'yyyy.MM.dd') }` }</p>
      </div>
      { i ? <FullCardPopup edit={ edit } status={ i?.status } dataStat={ data?.status } isOpen={ isFullPopup } i={ i } close={ handleCardClick } onApprove={ approveApplProj } onReject={ rejectAppl } /> : '' }
    </div>
  )
}

export default ModerCard

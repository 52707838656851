import { useState, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'

import Arrow from '@/shared/assets/images/arrow.svg'
import WhiteArrow from '@/shared/assets/images/white-arrow.svg'
import { setAdsActive, } from '../../../../../redux/MainSlices/MainSlice'

const ArrowClick = (props: any) => {
  const { onClick, } = props

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const AdsActive = useSelector((state => state.main.AdsActive))

  const dispatch = useDispatch()

  const [
    left,
    setLeft,
  ] = useState(false)

  return (
    <div id="#arrow" onClick={ () => {
      onClick(); dispatch(setAdsActive(AdsActive - 1))
    } } className={ `ArrowL d-arrow-bg ${ left && 'd-arrow-bg-active' }` } onMouseOver={ () => setLeft(true) } onMouseOut={ () => setLeft(false) }>
      <img className="d-arrow-to-left" src={ left ? WhiteArrow : Arrow } alt={ 'Стрелка влево' } />
    </div>
  )
}

export default ArrowClick

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, } from 'react'
import { findContact, } from '../../Utils/functions'
import { useSelector, } from 'react-redux'
import InputTextDeprecated from '../common/InputTextDeprecated/InputTextDeprecated'
import InputCheckbox from '../common/InputCheckbox/InputCheckbox'
import PhoneInput from 'react-phone-number-input'
import ButtonSimple from '../common/ButtonSimple/ButtonSimple'
import InputNumber from '../common/InputNumber/InputNumber'
import clsx from 'clsx'
import {
  useCheckCode,
  useSendMobile,
} from '../../gql/hooks/common/useConfirmPhone'

const ConfirmPhoneNumber = ({
  setMobile,
  newMobile,
  isVarified,
  setIsVarified,
  phoneOption,
  setPhoneOption,
  isDisabled,
  setSmsCode,
  smsCode,
  tel,
}: any) => {
  const [
    isConfirmCode,
    setIsConfirmCode,
  ] = useState(false)
  const [
    isFailedCode,
    setIsFailedCode,
  ] = useState(false)

  const [
    smsCodeCheck,
  ] = useCheckCode(smsCode)

  const handleCheck = () => {
    smsCodeCheck()
      .then(res => {
        if (res.data?.smsCodeCheck?.valid) {
          setIsVarified(true)
        } else if (!res.data.smsCodeCheck.valid) {
          setIsFailedCode(true)
        }
      })
      .catch(e => console.log(e))
  }

  const [
    smsCodeRequest,
  ] = useSendMobile({
    phone: tel /* newMobile */,
    fake: true,
    expire: 60,
    message: 'Code',
    source: 'statements',
  })

  const handleSendPhone = () => {
    smsCodeRequest()
      .then(res => {
        setIsConfirmCode(true)
      })
      .catch(e => console.log(e))
  }

  return tel ? (
    <div className="confirmMob">
      <div className="mobileInputsWrap">
        {/*  <div className="mobileRow">
          <InputText
            value={tel}
            disabled={true}
            styles={{
              marginRight: "20px",
              width: "40%",
              padding: "1.2rem",
            }}
          />
          <InputCheckbox
            label="Из моего профиля"
            checked={phoneOption === "Из моего профиля"}
            onChange={() => setPhoneOption("Из моего профиля")}
          />
        </div> */}
        <div className="mobileRow">
          {/* <PhoneInput
            defaultCountry="RU"
            disabled={phoneOption !== "Другой"}
            placeholder="Введите номер мобильного телефона"
             onChange={setMobile}
          />
          <InputCheckbox
            label="Другой"
            checked={phoneOption === "Другой"}
            onChange={() => setPhoneOption("Другой")}
          />*/}
          {
            /* newMobile?.length === 12 */ tel &&
            !isVarified &&
            !isConfirmCode && (
              <ButtonSimple
                value="Подтвердить"
                variant="alone"
                onClickHandler={ handleSendPhone }
                isDisabled={ isDisabled }
              />
            )
          }
          { isConfirmCode && !isVarified && (
            <>
              <InputNumber
                placeholder="Код из смс"
                onChangeHandler={ setSmsCode }
                styles={ { marginLeft: '20px', } }
                max={ 5 }
              />
              <ButtonSimple
                value="Подтвердить"
                variant="alone"
                onClickHandler={ handleCheck }
              />
            </>
          ) }

          { isConfirmCode && (
            <p
              className={ clsx(
                'codeMessage',
                isVarified && 'active',
                !isVarified && 'd-red'
              ) }
            >
              { isVarified
                ? 'Проверка пройдена'
                : isFailedCode
                  ? 'Неверный код'
                  : 'Пройдите проверку' }
            </p>
          ) }
        </div>
      </div>
    </div>
  ) : (
    <p className={ clsx('codeMessage', 'd-red') }>
      Номер вашего телефона не найден, обратитесь в кадры для актуализации
      данных
    </p>
  )
}

export default ConfirmPhoneNumber

import NumberCell from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/NumberCell/NumberCell'
import TableSpinner from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/TableSpinner/TableSpinner'
import TableStatus from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/TableStatus/TableStatus'
import AchievementDataCell from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/AchievementDataCell/AchievementDataCell'
import defineCellIndex from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/utils/defineCellIndex'
import { TableCellType, TableRowType, UUID, } from '@/legacy/types'
import { OnChangeRowValue, } from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableRow/TableRow'
import useUrlSearch from '@/legacy/hooks/useUrlSearch'
import effectiveContractUploadCategoryCreator from '@/legacy/components/EffectiveContract/lib/effectiveContractUploadCategoryCreator'
import useGetUserInfo from '@/legacy/hooks/useGetUserInfo'
import { EcAchivementStatus, EcAppealStatus, EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import TableStatusMoodle from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/TableStatusMoodle/TableStatusMoodle'
import { StatusGroup, } from '@/legacy/components/EffectiveContract/cards/CardAchievement/CardAchievement'
import NumberCellMin from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/NumberCell/NumberCellMin'


interface UseGetCellContentParams {
  cell: TableCellType
  row: TableRowType
  rowNumber: number
  isRowEditAvailable: boolean
  onChangeRowValue: OnChangeRowValue
  onUpdateRowHandler: Function
  dataType: EcTableName
  isAppealMode?: boolean
}


const useGetCellContent = ({
  cell,
  row,
  rowNumber,
  isRowEditAvailable,
  onChangeRowValue,
  onUpdateRowHandler,
  dataType,
  isAppealMode,
}: UseGetCellContentParams): JSX.Element => {
  // console.log('CELL', cell)
  // console.log('ROW', row)
  const moderateId: UUID = useUrlSearch('id')
  const { userGuid, } = useGetUserInfo()

  const uploadCategory = effectiveContractUploadCategoryCreator({
    recordId: row.rec_id,
    userId: moderateId || userGuid,
    tableName: dataType,
  })

  const cellIndex = defineCellIndex(row, cell)


  if (cellIndex === 'number') {
    if (dataType === EcTableName.TABLE_1_12) {
      return (
        <NumberCellMin
          isRowEditAvailable={ isRowEditAvailable }
          recId={ row.rec_id }
          dataType={ dataType }
        >
          { rowNumber + 1 }
        </NumberCellMin>
      )
    }
    return (
      <NumberCell
        isRowEditAvailable={ isRowEditAvailable }
        recId={ row.rec_id }
        dataType={ dataType }
      >
        { rowNumber + 1 }
      </NumberCell>
    )
  }

  if (cellIndex === 'status') {
    return (
      <TableStatus
        row={ row }
        dataType={ dataType }
        onChangeRowValue={ onChangeRowValue }
        onUpdateRowHandler={ onUpdateRowHandler }
        isAppealMode={ isAppealMode }
      />
    )
  }

  if (cellIndex === 'scores' && !cell.editable) {
    return (
      <td>
        { row[cellIndex] }
      </td>
    )
  }


  if (row[cellIndex] === 'loading') {
    return (
      <TableSpinner />
    )
  }

  return (
    <AchievementDataCell
      cellData={ cell }
      cellValue={ row[cellIndex] }
      uploadCategory={ uploadCategory }
      isRowEditAvailable={ isRowEditAvailable }
      onChangeRowValue={ onChangeRowValue }
      onUpdateRowHandler={ onUpdateRowHandler }
    />
  )
}


export default useGetCellContent

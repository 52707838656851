/**
 * Обработчик удаления участника из списка.
 *
 * @remarks Удаление происходит по индексу списка участников, так как в список смешанный и в нем одновременно могут встречаться как участники имеющие GUID, так и его не имеющие.
 *
 * @param listElementToBoDeleted - Объект с информацией об удаляемом из спискаэлементе (должен содержать числовой индекс элемента списка).
 * @param currentValueProps - Набор параметров относящихся к списку участников. value - список участников; valueIndex - строковый индекс по которому будет найдена ячейка в таблице, в которую запишется новое значение; onChangeRowValue - функция обновляющая значение в ячейке таблицы.
 */


import { CurrentValueProps, } from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/components/ParticipantsInput/types/currentValueProps'


interface ListElementToBoDeleted {
  itemIndex: number
}


const removeParticipantFromListHandler = (
  listElementToBoDeleted: ListElementToBoDeleted,
  currentValueProps: CurrentValueProps
) => {
  const { value, valueIndex, onChangeRowValue, } = currentValueProps
  const newValue = value.filter((element, index) => index !== listElementToBoDeleted.itemIndex)

  onChangeRowValue(valueIndex, newValue)
}


export default removeParticipantFromListHandler

import { UUID, } from '@/legacy/types'
import { gql, } from '@/__generated__/gql'
import { useQuery, } from '@apollo/client'
import { useMemo, } from 'react'


type ScheduleItem = {
  group_names: string[],
}

const GET_TEACHERS_TIMETABLE = gql(`
  query paTeacherTimetableLookup($input: TeacherTimetableLookupInput!) {
    paTeacherTimetableLookup(input: $input)
  }
`)


const useGetProjectSeminarGroups = (id: UUID) => {
  const { data, loading, } = useQuery(GET_TEACHERS_TIMETABLE, {
    variables: {
      input: {
        id,
        discipline: 'Проектный семинар',
      },
    },
  })

  const result: string[] | [] = useMemo(() => {
    if (!loading && data) {
      const currentData = data.paTeacherTimetableLookup
      let groups = currentData.items.map((scheduleItem: ScheduleItem) => scheduleItem.group_names.join()).join()

      if (groups.length > 0) {
        groups = groups.split(',')
      }

      return groups
    } return []
  }, [
    data,
    loading,
  ])

  return result
}


export default useGetProjectSeminarGroups

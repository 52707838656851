import { DetailedHTMLProps, InputHTMLAttributes, } from 'react'

const onClickHandler = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
  const element = event.target as HTMLInputElement
  element.value = ''
}


interface InputFileProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string,
}

const InputFile = ({
  className,
  ...props
}: InputFileProps): JSX.Element => {
  return (
    <input
      className={ className }
      type="file"
      onClick={ onClickHandler }
      { ...props }
    />
  )
}


export default InputFile

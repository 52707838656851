import './AdministratorTemplate.less'

// import { EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE, } from "../../../../Utils/routes";
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'

import { useLocation, } from 'react-router-dom'
import { useUserInfo, } from '../../../../gql/hooks/useME'

import RadioButtonFacade from '../../../common/RadioButtonFacade/RadioButtonFacade'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import { useEffect, useState, } from 'react'

import BasicIndicatorsAdminTab_2
  from './adminTabs/refactor/BasicIndicatorsAdminTab_2/BasicIndicatorsAdminTab_2'
import HightPerformansIndicatorsAdminTab_2
  from './adminTabs/refactor/HightPerformansIndicatorsAdminTab_2/HightPerformansIndicatorsAdminTab_2'
import BonusPointsIndicatorsAdminTab_2
  from './adminTabs/refactor/BonusPointsIndicatorsAdminTab_2/BonusPointsIndicatorsAdminTab_2'
import DowngradePaymentIndicatorsAdminTab_2
  from '@/legacy/components/EffectiveContract/pages/AdministratorTemplate/adminTabs/refactor/DowngradePaymentIndicatorsAdminTab_2/DowngradePaymentIndicatorsAdminTab_2'

import Popup from '../../../common/Popup/Popup'
import { AddManyParticipants, AddOneParticipant, } from '../../AddAchievement'
import PopupContentReject from '../../popupContent/PopupContentReject/PopupContentReject'
import PopupContentCreateOrEdit from '../../popupContent/PopupContentCreateOrEdit/PopupContentCreateOrEdit'
import DowngradePaymentIndicatorsAdminTab from './adminTabs/DowngradePaymentIndicatorsAdminTab'
import POP_UP_CONTENT_1_1 from '../ModeratorTemplate/PopUpContent/PopUpContent_1_1'
import POP_UP_CONTENT_REJECT_STATUS_1_11 from '../ModeratorTemplate/PopUpContent/PopUpContentRejectStatus_1_11'
import POP_UP_CONTENT_1_2 from '../ModeratorTemplate/PopUpContent/PopUpContent_1_2'
import POP_UP_CONTENT_1_3 from '../ModeratorTemplate/PopUpContent/PopUpContent_1_3'
import POP_UP_CONTENT_1_4 from '../ModeratorTemplate/PopUpContent/PopUpContent_1_4'
import POP_UP_CONTENT_1_41 from '../ModeratorTemplate/PopUpContent/PopUpContent_1_41'
import POP_UP_CONTENT_2_1 from '../ModeratorTemplate/PopUpContent/PopUpContent_2_1'
import POP_UP_CONTENT_2_2 from '../ModeratorTemplate/PopUpContent/PopUpContent_2_2'
import POP_UP_CONTENT_2_3 from '../ModeratorTemplate/PopUpContent/PopUpContent_2_3'
import POP_UP_CONTENT_2_4 from '../ModeratorTemplate/PopUpContent/PopUpContent_2_4'
import POP_UP_CONTENT_2_41 from '../ModeratorTemplate/PopUpContent/PopUpContent_2_41'
import POP_UP_CONTENT_2_5 from '../ModeratorTemplate/PopUpContent/PopUpContent_2_5'
import POP_UP_CONTENT_2_6 from '../ModeratorTemplate/PopUpContent/PopUpContent_2_6'
import POP_UP_CONTENT_2_7 from '../ModeratorTemplate/PopUpContent/PopUpContent_2_7'
import POP_UP_CONTENT_2_8 from '../ModeratorTemplate/PopUpContent/PopUpContent_2_8'
import POP_UP_CONTENT_2_9 from '../ModeratorTemplate/PopUpContent/PopUpContent_2_9'
import POP_UP_CONTENT_2_10 from '../ModeratorTemplate/PopUpContent/PopUpContent_2_10'
import POP_UP_CONTENT_3_1 from '../ModeratorTemplate/PopUpContent/PopUpContent_3_1'
import POP_UP_CONTENT_3_2 from '../ModeratorTemplate/PopUpContent/PopUpContent_3_2'
import POP_UP_CONTENT_3_3 from '../ModeratorTemplate/PopUpContent/PopUpContent_3_3'
import POP_UP_CONTENT_3_4 from '../ModeratorTemplate/PopUpContent/PopUpContent_3_4'
import POP_UP_CONTENT_3_5 from '../ModeratorTemplate/PopUpContent/PopUpContent_3_5'


const tabButtons = [
  { text: 'Базовые показатели', },
  { text: 'Показатели высокой эффективности труда', },
  { text: 'Бонусные баллы', },
  { text: 'Основания для снижения выплат стимулирующего характера', },
]


const AdministratorTemplate = () => {
  const [
    contentIndex,
    setContentIndex,
  ] = useState<string | null>(null)

  const [
    isPopupOpen,
    setIsPopUpOpen,
  ] = useState(false)
  const [
    popUpType,
    setPopUpType,
  ] = useState(null)
  const [
    popUpData,
    setPopUpData,
  ] = useState<any>(null)

  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')

  const userInfo = useUserInfo(moderateId)?.data?.userInfo


  const [
    buttons,
    setButtons,
  ] = useState<any>([])

  const isTeacher = userInfo?.employee?.some((employee: any) => employee.isTeacher)
  useEffect(() => {
    setButtons(tabButtons)
    setContentIndex(tabButtons[0].text)
  }, [
    isTeacher,
  ])


  const popUpSelector = {
    addNewRow: <PopupContentCreateOrEdit onClickHandler={ () => setIsPopUpOpen(false) } defaultValues={ { tableName: popUpData?.tableName, } } />,

    displayRejectReason: <PopupContentReject title={ popUpData?.achievement } reason={ popUpData?.reason } onClickHandler={ () => setIsPopUpOpen(false) } />,

    addManyParticipants: <AddManyParticipants data={ popUpData } setIsPopUpOpen={ () => setIsPopUpOpen(false) } />,

    addOneParticipants: <AddOneParticipant data={ popUpData } setIsPopUpOpen={ () => setIsPopUpOpen(false) } />,

    table_1_1: <POP_UP_CONTENT_1_1 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_1_11: <POP_UP_CONTENT_REJECT_STATUS_1_11 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_1_2: <POP_UP_CONTENT_1_2 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_1_3: <POP_UP_CONTENT_1_3 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_1_4: <POP_UP_CONTENT_1_4 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_1_41: <POP_UP_CONTENT_1_41 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_1: <POP_UP_CONTENT_2_1 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_2: <POP_UP_CONTENT_2_2 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_3: <POP_UP_CONTENT_2_3 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_4: <POP_UP_CONTENT_2_4 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_41: <POP_UP_CONTENT_2_41 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_5: <POP_UP_CONTENT_2_5 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_6: <POP_UP_CONTENT_2_6 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_7: <POP_UP_CONTENT_2_7 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_8: <POP_UP_CONTENT_2_8 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_9: <POP_UP_CONTENT_2_9 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_2_10: <POP_UP_CONTENT_2_10 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_3_1: <POP_UP_CONTENT_3_1 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_3_2: <POP_UP_CONTENT_3_2 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_3_3: <POP_UP_CONTENT_3_3 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_3_4: <POP_UP_CONTENT_3_4 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    table_3_5: <POP_UP_CONTENT_3_5 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
  }

  return (
    <div className="effective-contract">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
          // { value: "Эффективный контракт", path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE },
        ] }
        loc="Модерация достижений"
      />

      <h2 className="effective-contract__title">
        Модерация достижений: { userInfo?.full_name }
      </h2>

      <div className="effective-contract__buttons-wrapper">
        <RadioButtonFacade
          content={ buttons }
          BtnComponent={ ButtonSimple }
          activeIndex={ buttons.findIndex((button: any) => button.text === contentIndex) }
          // @ts-ignore
          setActiveIndex={ (answer: any) => setContentIndex(buttons[answer].text) }
        />
      </div>

      { contentIndex === tabButtons[0].text && (
        <BasicIndicatorsAdminTab_2 />
      ) }


      { contentIndex === tabButtons[1].text && (
        <HightPerformansIndicatorsAdminTab_2 />
      ) }


      { contentIndex === tabButtons[2].text && (
        <BonusPointsIndicatorsAdminTab_2 />
      ) }


      { contentIndex === tabButtons[3].text && (
        <DowngradePaymentIndicatorsAdminTab_2 />
      ) }


      <Popup
        children={
          // @ts-expect-error TS(2538): Type 'null' cannot be used as an index type.
          popUpSelector[popUpType]
        }
        isOpen={ isPopupOpen }
        handlePopup={ () => setIsPopUpOpen(false) }
        withScroll
      />

      {/* <Popup
        contentComponent={
          popUpType === 'addManyParticipants'
            ? (
              <AddManyParticipants data={ popUpData } setIsPopUpOpen={ setIsPopUpOpen } />
            )
            : popUpType === 'displayRejectReason'
              ? (
                <PopupContentReject title={ popUpData.achievement } reason={ popUpData.reason } onClickHandler={ () => setIsPopUpOpen(false) } />
              )
              : popUpType === 'addNewRow'
                ? <PopupContentCreateOrEdit onClickHandler={ () => setIsPopUpOpen(false) } defaultValues={ { tableName: popUpData.tableName } } />
                : false
        }
        isOpen={ isPopupOpen }
        handlePopup={ () => setIsPopUpOpen(false) }
        withScroll
      /> */}
    </div>
  )
}


export default AdministratorTemplate

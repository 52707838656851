import { projectResponderType, } from '@/legacy/components/Projects/ProjectPage/InfoProject/components/AddStudentResultInput/AddStudentResultInput'


interface DefineToShowDeleteButtonParam {
  currentUserType: projectResponderType,
  isAuthorOfResult: boolean,
  isApproved: boolean,
  isFileAttached: boolean,
}


const defineToShowDeleteButton = ({
  currentUserType,
  isAuthorOfResult,
  isApproved,
  isFileAttached,
}: DefineToShowDeleteButtonParam): boolean => {
  if (currentUserType === projectResponderType.STUDENT && isAuthorOfResult && !isApproved && !isFileAttached) {
    return true
  } else if (currentUserType === projectResponderType.STUDENT && isAuthorOfResult && !isApproved && isFileAttached) {
    return true
  } else if (currentUserType === projectResponderType.SUPERVISOR && isAuthorOfResult && !isApproved && !isFileAttached) {
    return true
  } else if (currentUserType === projectResponderType.SUPERVISOR && isAuthorOfResult && !isApproved && isFileAttached) {
    return true
  } else if (currentUserType === projectResponderType.SUPERVISOR && isAuthorOfResult && isApproved && !isFileAttached) {
    return true
  } else if (currentUserType === projectResponderType.SUPERVISOR && isAuthorOfResult && isApproved && isFileAttached) {
    return true
  } return false
}


export default defineToShowDeleteButton

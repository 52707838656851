import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'


const LIST_STATUS = gql`
  query Statuses {
    achievementStatuses(all: true)
  }
`


const useAchievementStatuses = () => {
  const { data, loading, } = useQuery(LIST_STATUS)

  const statusesList = useMemo(() => {
    if (!loading && data) {
      return data.achievementStatuses.filter((status: any) => status.name.toLowerCase() !== 'ожидает достижения')
    }
  }, [
    data,
    loading,
  ])

  return statusesList
}


export default useAchievementStatuses

import { TableCellType, } from '@/legacy/types'
import { table_cat_2_10, } from '../../popUpInputsCatalogs'

export const tableSource: TableCellType[] = [
  {
    editable: false,
    title: '№',
    index: 'number',
  },
  {
    title: 'Название журнала',
    index: 'magazineName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Роль',
    index: 'role',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_10.role,
  },
  {
    title: 'Ссылка на сайт журнала',
    index: 'link',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: false,
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '8rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Название журнала',
    index: 'magazineName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Роль',
    index: 'role',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_10.role,
  },
  {
    title: 'Ссылка на сайт журнала',
    index: 'link',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Причина отказа',
    index: 'reason',
    editType: 'input',
    style: { minWidth: '20rem', },
    editable: false,
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    editable: false,
    editType: 'input',

  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
    editable: false,

  },
]

import { TableCellType, } from '@/legacy/types'
import { table_cat_1_4, } from '../../popUpInputsCatalogs'


export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
    style: { minWidth: '3rem', },
  },
  {
    title: 'Название статьи',
    index: table_cat_1_4.articleName,
    indexType: 'array',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Наличие в БД',
    index: 'dataBase',
    indexDescription: 'table_1_4_dataBase',
    indexType: 'array',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Год публикации',
    index: table_cat_1_4.publication_year,
    indexType: 'array',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Авторы статьи',
    index: 'authors',
    // index: table_cat_1_4.authorsName,
    // indexType: 'array',
    editable: true,
    editType: 'participants',
  },
  {
    title: 'Название журнала',
    index: table_cat_1_4.journalName,
    indexType: 'array',
    editable: true,
    editType: 'input',
  },
  // {
  //   title: 'Квартиль',
  //   index: 'quartileNumber',
  //   indexDescription: 'quartileType',
  //   editable: false,
  //   editType: 'select',
  //   selectOptions: [
  //     '1',
  //     '2',
  //     '3',
  //     '4',
  //   ],
  // },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '12rem', },
    editable: false,
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Название статьи',
    index: table_cat_1_4.articleName,
    indexType: 'array',
    editable: true,
    editType: 'input',
    style: { minWidth: '15rem', },
  },
  {
    title: 'Наличие в БД',
    index: 'dataBase',
    indexDescription: 'table_1_4_dataBase',
    indexType: 'array',
    editable: true,
    editType: 'input',
    // editable: false,
  },
  {
    title: 'Год публикации',
    index: table_cat_1_4.publication_year,
    indexType: 'array',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Авторы статьи',
    index: 'authors',
    // index: table_cat_1_4.authorsName,
    // indexType: 'array',
    editable: true,
    editType: 'participants',
  },
  {
    title: 'Название журнала',
    index: table_cat_1_4.journalName,
    indexType: 'array',
    editable: true,
    editType: 'input',
  },
  // {
  //   title: 'Квартиль',
  //   editable: false,
  //   index: 'quartileNumber',
  //   indexDescription: 'quartileType',
  //   // editable: true,
  //   editType: 'select',
  //   selectOptions: [
  //     '1',
  //     '2',
  //     '3',
  //     '4',
  //   ],
  // },
  {
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    editable: false,
    style: { minWidth: '30rem', },
    editType: 'input',
  },
  {
    title: 'Статус',
    index: 'status',
    editable: false,
    style: { minWidth: '10rem', },
  },
]

import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  selectedImg: null,
  allPhotos: [
    {
      id: 'jfiftryhtyjtyi',
      src: 'https://icdn.lenta.ru/images/2021/04/27/16/20210427163138131/square_320_c09ebae17387b7d6eeb9fa0d42afe5ee.jpg',
      cropedImage: null,
    },
    {
      id: 'jfihytjufi',
      src: 'https://icdn.lenta.ru/images/2021/04/27/16/20210427163138131/square_320_c09ebae17387b7d6eeb9fa0d42afe5ee.jpg',
      cropedImage: null,
    },
    {
      id: 'jfrgrgifi',
      src: 'https://icdn.lenta.ru/images/2021/04/27/16/20210427163138131/square_320_c09ebae17387b7d6eeb9fa0d42afe5ee.jpg',
      cropedImage: null,
    },
    {
      id: 'grtg',
      src: 'https://icdn.lenta.ru/images/2021/04/27/16/20210427163138131/square_320_c09ebae17387b7d6eeb9fa0d42afe5ee.jpg',
      cropedImage: null,
    },
  ],
  zoom: 1,
  crop: {
    x: 0,
    y: 0,
  },
  aspect: null,
  croppedAreaPixels: null,
}

export const cropSlice = createSlice({
  name: 'crop',
  initialState,
  reducers: {
    selectImg: (state, action) => {
      state.selectedImg = action.payload
    },
    setZoom: (state, action) => {
      state.zoom = action.payload
    },
    setCrop: (state, action) => {
      state.crop = action.payload
    },
    doCrop: (state, action) => {
      state.croppedAreaPixels = action.payload
    },
  },
})


export const { selectImg, setZoom, setCrop, doCrop, } = cropSlice.actions

export default cropSlice.reducer

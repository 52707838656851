import { useQuery, gql, useMutation, useSubscription, } from '@apollo/client'

const REFERENCE_STATS = gql`
  query ReferenceStatus {
    references(mode: "stats")
  }
`

const REFERENCE_LIST = gql`
  query ReferenceList($status: [String]) {
    references(status: $status)
  }
`

const CREATE_REFERENCE = gql`
  mutation CreateReference($payload: JSON) {
    createReference(payload: $payload)
  }
`

const RESEND_REFERENCE = gql`
mutation CommitReferences($doc_id:Int ) {
  referenceCommit(doc_id:$doc_id)
}
`


const REFERENCE_CHANGED = gql`
  subscription ReferenceChanged {
    referenceChanged
  }
`


export const useReferenceStats = () => useQuery(REFERENCE_STATS)
export const useReferenceList = (status: any) => useQuery(REFERENCE_LIST, { variables: { status, }, })
export const useCreateReference = () => useMutation(CREATE_REFERENCE)
export const useResendReference = (doc_id: any): any => useMutation(RESEND_REFERENCE, { variables: { doc_id, }, })

export const useReferenceChanged = () => useSubscription(REFERENCE_CHANGED)

import { gql, } from '@/__generated__'
import { useQuery, } from '@apollo/client'

interface InternetStatus {
  code: number
  dateLastLogin: string
  intranetStatus: boolean
  ip: string
  login: string
}

interface InternetConnectionStatusData {
  getInternetConnectionStatusByCurrentUser: InternetStatus
}

const GET_INTERNET_CONNECTION_STATUS_BY_CURRENT_USER = gql(`
  query GetInternetConnectionStatusByCurrentUser {
    getInternetConnectionStatusByCurrentUser {
      code
      dateLastLogin
      intranetStatus
      ip
      login
    }
  }
`)

const useInternetConnectionStatus = () => {
  const { loading, error, data, refetch, } = useQuery<InternetConnectionStatusData>(GET_INTERNET_CONNECTION_STATUS_BY_CURRENT_USER)

  return { loading,
    error,
    internetConnectionStatus: data?.getInternetConnectionStatusByCurrentUser,
    refetch, }
}

export default useInternetConnectionStatus

import './TableTemplate.less'
import { Table, } from 'antd'
import { useSelector, } from 'react-redux'
import { useMemo, } from 'react'


function TableTemplate ({
  data,
}: any) {
  const { Column, } = Table

  const handleProfessors = (arr: any) => {
    return arr.map((i: any, index: any) => {
      if (index === arr.length - 1) {
        return i
      }
      return `${ i }, `
    })
  }

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const filterStatus = useSelector(state => state.rec.filterStatus)
  function chooseColor (el: any) {
    if (el === 'Отлично' || el === 'Хорошо' || el === 'Зачтено') {
      return 'green'
    } else if (el === 'Неудовлетворительно' || el === 'Незачет') {
      return 'red'
    }
    return 'yellow'
  }

  function chooseGrade (el: any) {
    if (el === 'Неудовлетворительно') {
      return 'Неуд.'
    } else if (el === 'Удовлетворительно') {
      return 'Удовл.'
    }
    return el
  }


  const disciplineTable = useMemo(() => {
    return data?.map((i: any) => {
      const carcas: any = []
      if (filterStatus === 'Практики') {
        i.types.forEach((field: any) => {
          if (field.practice) {
            field.disciplines.forEach((i: any) => {
              const examInfo = {
                key: Math.random(),
                discipline: i.discipline,
                hours: i.hours,
                cul: i.CUL,
                control_type: field.type,
                grade: i.grade,
                programresults_code: i.programresults_code,
                vedomost_number: i.vedomost_number,
                professors: handleProfessors(i.professors),
                date_exam: i.date_exam,
                practice_place: i.practice_place,
              }
              carcas.push(examInfo)
            })
          }
        })
      } else {
        if (filterStatus === 'Учебная деятельность') {
          i.types.forEach((el: any) => {
            if (el.type !== 'Курсовая работа' && !el.practice) {
              el.disciplines.forEach((i: any) => {
                const color = chooseColor(i.grade)
                const grade = chooseGrade(i.grade)
                const examInfo = {
                  key: Math.random(),
                  discipline: i.discipline,
                  hours: i.hours,
                  cul: i.CUL,
                  control_type: el.type,
                  grade: <div className="gradeWrap" ><div className={ `point ${ color }` }></div>{ grade }</div>,
                  programresults_code: i.programresults_code,
                  vedomost_number: i.vedomost_number,
                  professors: handleProfessors(i.professors),
                  date_exam: i.date_exam,
                }
                carcas.push(examInfo)
              })
            }
          })
        } else if (filterStatus === 'Курсовые') {
          i.types.forEach((el: any) => {
            if (el.type === 'Курсовая работа') {
              el.disciplines.forEach((i: any) => {
                const color = chooseColor(i.grade)
                const grade = chooseGrade(i.grade)
                const examInfo = {
                  key: Math.random(),
                  discipline: i.discipline,
                  hours: i.hours,
                  cul: i.CUL,
                  control_type: el.type,
                  grade: <div className="gradeWrap" ><div className={ `point ${ color }` }></div>{ grade }</div>,
                  programresults_code: i.programresults_code,
                  vedomost_number: i.vedomost_number,
                  professors: handleProfessors(i.professors),
                  date_exam: i.date_exam,
                  paper_topic: i.paper_topic,
                }
                carcas.push(examInfo)
              })
            }
          })
        }
      }

      const locale = {
        emptyText: <div style={ { padding: '30px 0', } }>Нет данных</div>,
      }

      return <div key={ Math.random() }>
        <h2 className="semestrTitle">{ `${ i.semester } семестр` }</h2>
        <Table locale={ locale } pagination={ false } dataSource={ carcas } className={ 'd-scrollbar' }>
          <Column width={ 260 } title="Дисциплина" dataIndex="discipline" key="discipline" />
          <Column title="Часы" dataIndex="hours" key="hours" />
          <Column title="Зет" dataIndex="cul" key="cul" />
          <Column width={ 110 } title="Форма отчетности" dataIndex="control_type" key="control_type" />
          <Column width={ 85 } title="Оценка" dataIndex="grade" key="grade" />
          { filterStatus === 'Курсовые' ? <Column width={ 400 } title="Тема работы" dataIndex="paper_topic" key="paper_topic" /> : <></> }
          { filterStatus === 'Практики' ? <Column width={ 400 } title="Место прохождения практики" dataIndex="practice_place" key="practice_place" /> : <></> }
          <Column width={ 210 } title="Результаты освоения" dataIndex="programresults_code" key="programresults_code" />
          <Column width={ 165 } title="На основании ведомости №" dataIndex="vedomost_number" key="vedomost_number" />
          <Column width={ 114 } title="Дата" dataIndex="date_exam" key="date_exam" />
          <Column width={ 256.15 } title="Преподаватель" dataIndex="professors" key="professors" />
        </Table>
      </div>
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    filterStatus,
  ])


  return (disciplineTable)
}

export default TableTemplate

import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'


const GET_SETTINGS = gql(`
  query personnelConfig {
    personnelConfig(name: [
      "is_student_rating_available_all",
      "is_student_rating_include_myself"
    ])
  }
`)


const useGetPersonalRatingSettings = () => {
  const { data, loading, refetch, } = useQuery(GET_SETTINGS)

  const result = useMemo(() => {
    const settingFields = [
      'is_student_rating_available_all',
      'is_student_rating_include_myself',
    ]

    if (!loading && data) {
      const currentData = data.personnelConfig
      const settings = {}

      if (currentData.length < 1) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        settingFields.forEach(item => settings[item] = false)
      } else {
        settingFields.forEach(field => {
          if (currentData.some((item: any) => item.name === field)) {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            settings[field] = Boolean(JSON.parse(currentData.find((item: any) => item.name === field).value))
          } else {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            settings[field] = false
          }
        })
      }

      return settings
    }
  }, [
    data,
    loading,
  ])


  return {
    result,
    refetch,
  }
}


export default useGetPersonalRatingSettings

import { useMemo, useState, } from 'react'
import useEcSubscription from '../../../../gql/hooks/effectiveContractHooks/useEcSubscription2'
import { useUserInfo, } from '../../../../gql/hooks/useME'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import RadioButtonFacade from '../../../common/RadioButtonFacade/RadioButtonFacade'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import AmsBasicIndicatorsTab from '../../amsTabs/AmsBasicIndicatorsTab'
import CareerGuidanceWorkTab from '../../amsTabs/CareerGuidanceWorkTab'
import EducationalActivitiesTab from '../../amsTabs/EducationalActivitiesTab'
import ScientificActivitiesTab from '../../amsTabs/ScientificActivitiesTab'
import OtherActivitiesTab from '../../amsTabs/OtherActivitiesTab'
import tabButtons from '../../amsTabs/tabButtons'
import Popup from '../../../common/Popup/Popup'
import AddNewRecordPopupContent from '../../popupContent/AddNewRecordPopupContent/AddNewRecordPopupContent'
import ChangeStatusRecordPopupContent from '../../popupContent/ChangeStatusRecordPopupContent/ChangeStatusRecordPopupContent'
import { clearAchievementFields, } from '../../amsUtils/amsUtils'
import { TitleH3, } from '../../../common/GeneralElements/GeneralElements'


const AmsAdministratorTemplate = ({
  moderateId,
  userRole,
}: any) => {
  const tabs = useMemo(() => {
    return tabButtons.filter(tab => tab.roles.some(tabRole => tabRole === userRole))
  }, [
    userRole,
  ])

  const [
    contentIndex,
    setContentIndex,
  ] = useState(0)
  const [
    isAddNewRecord,
    setIsAddNewRecord,
  ] = useState(false)
  const [
    isChangeStatus,
    setIsChangeStatus,
  ] = useState(false)
  const [
    isShowRejectReason,
    setIsShowRejectReason,
  ] = useState(false)
  const [
    formInfo,
    setFormInfo,
  ] = useState<any>(null)

  const full_name = useUserInfo(moderateId)?.data?.userInfo?.full_name
  const { dataTypeUpdated, setDataTypeUpdated, } = useEcSubscription()


  const returnDataForAddNewRow = (dataType: any, fields: any) => {
    setIsAddNewRecord(true)
    setFormInfo({
      dataType,
      fields,
    })
  }


  const returnDataForDisplayRejectReason = (data: any) => {
    setIsShowRejectReason(true)
    setFormInfo({
      reason: data.reason,
    })
  }


  const amsBasicIndicatorsTab = useMemo(() => {
    return <AmsBasicIndicatorsTab
      contentType="table"
      moderateId={ moderateId }
      dataTypeUpdated={ dataTypeUpdated }
      setDataTypeUpdated={ setDataTypeUpdated }
      userRole={ userRole }
    />
  }, [
    moderateId,
    dataTypeUpdated,
    setDataTypeUpdated,
    userRole,
  ])

  const careerGuidanceWorkTab = useMemo(() => {
    return <CareerGuidanceWorkTab
      contentType="table"
      moderateId={ moderateId }
      dataTypeUpdated={ dataTypeUpdated }
      setDataTypeUpdated={ setDataTypeUpdated }
      userRole={ userRole }
    />
  }, [
    moderateId,
    dataTypeUpdated,
    setDataTypeUpdated,
    userRole,
  ])

  const educationalActivitiesTab = useMemo(() => {
    const changeStatusHandlers = {
      popUpControllHandler: () => setIsChangeStatus(true),
      getRowData: (data: any, headerFields: any) => setFormInfo({
        formRecId: data.rec_id,
        formData: data.old_data,
        formUserIds: data.user_ids,
        fields: clearAchievementFields(headerFields),
      }),
    }

    return <EducationalActivitiesTab
      contentType="table"
      moderateId={ moderateId }
      dataTypeUpdated={ dataTypeUpdated }
      setDataTypeUpdated={ setDataTypeUpdated }
      returnDataForAddNewRow={ returnDataForAddNewRow }
      changeStatusHandlers={ changeStatusHandlers }
      returnDataForDisplayRejectReason={ returnDataForDisplayRejectReason }
      userRole={ userRole }
    />
  }, [
    moderateId,
    dataTypeUpdated,
    setDataTypeUpdated,
    userRole,
  ])

  const scientificActivitiesTab = useMemo(() => {
    const changeStatusHandlers = {
      popUpControllHandler: () => setIsChangeStatus(true),
      getRowData: (data: any, headerFields: any) => setFormInfo({
        formRecId: data.rec_id,
        formData: data.old_data,
        formUserIds: data.user_ids,
        fields: clearAchievementFields(headerFields),
      }),
    }

    return <ScientificActivitiesTab
      contentType="table"
      moderateId={ moderateId }
      dataTypeUpdated={ dataTypeUpdated }
      setDataTypeUpdated={ setDataTypeUpdated }
      returnDataForAddNewRow={ returnDataForAddNewRow }
      changeStatusHandlers={ changeStatusHandlers }
      returnDataForDisplayRejectReason={ returnDataForDisplayRejectReason }
      userRole={ userRole }
    />
  }, [
    moderateId,
    dataTypeUpdated,
    setDataTypeUpdated,
    userRole,
  ])

  const otherActivitiesTab = useMemo(() => {
    const changeStatusHandlers = {
      popUpControllHandler: () => setIsChangeStatus(true),
      getRowData: (data: any, headerFields: any) => setFormInfo({
        formRecId: data.rec_id,
        formData: data.old_data,
        formUserIds: data.user_ids,
        fields: clearAchievementFields(headerFields),
      }),
    }

    return <OtherActivitiesTab
      contentType="table"
      moderateId={ moderateId }
      dataTypeUpdated={ dataTypeUpdated }
      setDataTypeUpdated={ setDataTypeUpdated }
      returnDataForAddNewRow={ returnDataForAddNewRow }
      changeStatusHandlers={ changeStatusHandlers }
      returnDataForDisplayRejectReason={ returnDataForDisplayRejectReason }
      userRole={ userRole }
    />
  }, [
    moderateId,
    dataTypeUpdated,
    setDataTypeUpdated,
    userRole,
  ])


  const content = useMemo(() => {
    const result = []

    if (/^director-|-admin$|^aup-pk-moder$/.test(userRole)) {
      result.push(amsBasicIndicatorsTab)
      result.push(careerGuidanceWorkTab)
    }

    if (/^director-|-admin$|^aup-pfu-moder$/.test(userRole)) {
      result.push(educationalActivitiesTab)
    }

    if (/^director-|-admin$|^aup-pfu-moder$|^aup-dms-moder$|^aup-ck-moder&/.test(userRole)) {
      result.push(scientificActivitiesTab)
    }

    if (/^director-|-admin$|^aup-dms-moder$|^aup-ck-moder&/.test(userRole)) {
      result.push(otherActivitiesTab)
    }


    return result
  }, [
    amsBasicIndicatorsTab,
    careerGuidanceWorkTab,
    educationalActivitiesTab,
    scientificActivitiesTab,
    otherActivitiesTab,
    userRole,
  ])


  return (
    <div className="effective-contract">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Модерация достижений АУП"
      />

      <h2 className="effective-contract__title">АУП - модерация достижений: { full_name } </h2>

      <div className="effective-contract__buttons-wrapper">
        <RadioButtonFacade
          content={ tabs }
          BtnComponent={ ButtonSimple }
          activeIndex={ contentIndex }
          setActiveIndex={ setContentIndex }
        />
      </div>

      { content[contentIndex] }

      { isAddNewRecord && (
        <Popup
          isOpen={ isAddNewRecord }
          handlePopup={ () => {
            setIsAddNewRecord(false)
            setFormInfo(null)
          } }
        >
          <AddNewRecordPopupContent
            formDataType={ formInfo.dataType }
            formFields={ formInfo.fields }
            formUserIds={ [
              moderateId,
            ] }
            onClosePopup={ () => setIsAddNewRecord(false) }
          />
        </Popup>
      ) }


      { isChangeStatus && (
        <Popup
          isOpen={ isChangeStatus }
          handlePopup={ () => {
            setIsChangeStatus(false)
            setFormInfo(null)
          } }
        >
          <ChangeStatusRecordPopupContent
            formFields={ formInfo?.fields }
            formData={ formInfo?.formData }
            formRecId={ formInfo?.formRecId }
            formUserIds={ formInfo?.formUserIds }
            onClosePopup={ () => setIsChangeStatus(false) }
          />
        </Popup>
      ) }


      { isShowRejectReason && (
        <Popup
          isOpen={ isShowRejectReason }
          handlePopup={ () => {
            setIsShowRejectReason(false)
            setFormInfo(null)
          } }
        >
          <>
            <TitleH3>Причина отказа:</TitleH3>
            { formInfo.reason }
          </>
        </Popup>
      ) }
    </div>
  )
}


export default AmsAdministratorTemplate

import { useSubscription, } from '@apollo/client'
import { useEffect, useState, } from 'react'
import { EC_CHANGES, } from './useEcSubscription'


const useEcSubscription = () => {
  const { data, loading, } = useSubscription(EC_CHANGES)
  const [
    dataTypeUpdated,
    setDataTypeUpdated,
  ] = useState(null)


  useEffect(() => {
    if (!loading && data) {
      setDataTypeUpdated(data?.contractsChanged?.result?.dataType)
    }
  }, [
    loading,
    data,
  ])


  return {
    dataTypeUpdated,
    setDataTypeUpdated,
  }
}


export default useEcSubscription

import PaperContainerWrapper from '../../../../common/PaperContainer/PaperContainerWrapper'
import NotificationItem from '../NotificationItem/NotificationItem'
import styles from './List.module.less'


const List = ({
  items,
}: any) => {
  return (
    <PaperContainerWrapper className={ styles.listWrapper }>
      { items.map((listItem: any) => <NotificationItem
        key={ listItem.draft_id }
        { ...listItem }
      />) }
    </PaperContainerWrapper>
  )
}


export default List

import { useMutation, } from '@apollo/client'
import { EDIT_AVHIEVEMENT_DIRECTION, } from './useCreateAchievementDirection'


const useDeleteAchievementDirection = () => {
  const [
    onDelete,
  ] = useMutation(EDIT_AVHIEVEMENT_DIRECTION)

  const onDeleteHandler = (key: any) => onDelete({
    variables: {
      input: {
        key,
      },
    },
  })


  return onDeleteHandler
}


export default useDeleteAchievementDirection

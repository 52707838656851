import './EffectiveContract.less'
import TaecherPage from './pages/TeacherPage/TeacherPage'
import UserList from './UserList/UserList'
import { defineUserGroupe, } from './defineUserGroupe'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'


const EffectiveContract = (): JSX.Element => {
  const role = useGetRole()

  return (
    <>
      { /^worker$|^employee$/.test(role) && <TaecherPage /> }

      { defineUserGroupe(role, legacy_effectiveContractModerGroupe) && <UserList /> }
    </>
  )
}


export default EffectiveContract

import { gql, useSubscription, } from '@apollo/client'
import { useEffect, } from 'react'


const CHANGED = gql`
  subscription OnAchievementChanged {
    achievementChanged
  }
`


const useAchievementChanged = (refetch: any) => {
  const { loading, data, } = useSubscription(CHANGED)

  useEffect(() => {
    if (!loading && data?.achievementChanged && refetch) {
      refetch()
    }
  }, [
    loading,
    refetch,
    data,
  ])
}


export default useAchievementChanged

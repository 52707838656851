import { createSlice, } from '@reduxjs/toolkit'


const initialState = {
  adminUserlistFilter: {
    usersSearch: '',
    instituteFilter: [],
    positionNameFilter: [],
    tableRowsLength: 20,
    section: [],
    status: [
      'approved',
      'pending',
    ],
  },

  appealsUserlistFilter: {
    usersSearch: '',
    instituteFilter: [],
    positionNameFilter: [],
    tableRowsLength: 20,
  },

  teacherRatingFilter: {
    instituteFilter: '',
    tableRowsLength: 20,
    sortOrder: 'desc',
    sortColumn: 'pending',
  },
}


export const efectiveContractSlices = createSlice({
  name: 'efectiveContract',
  initialState,
  reducers: {
    setAdminUserlistFilterUsersSearch: (state, action) => {
      state.adminUserlistFilter.usersSearch = action.payload
    },

    setAdminUserlistFilterSectionFilter: (state, action) => {
      state.adminUserlistFilter.section = action.payload
    },

    setAdminUserlistFilterStatusFilter: (state, action) => {
      state.adminUserlistFilter.status = action.payload
    },

    setAdminUserlistFilterInstituteFilter: (state, action) => {
      state.adminUserlistFilter.instituteFilter = action.payload
    },

    setAdminUserlistFilterPositionNameFilter: (state, action) => {
      state.adminUserlistFilter.positionNameFilter = action.payload
    },

    setAdminUserlistFilterTableRowsLength: (state, action) => {
      state.adminUserlistFilter.tableRowsLength = action.payload
    },


    setAppealsUserlistFilterUsersSearch: (state, action) => {
      state.appealsUserlistFilter.usersSearch = action.payload
    },

    setAppealsUserlistFilterInstituteFilter: (state, action) => {
      state.appealsUserlistFilter.instituteFilter = action.payload
    },

    setAppealsUserlistFilterPositionNameFilter: (state, action) => {
      state.appealsUserlistFilter.positionNameFilter = action.payload
    },

    setAppealsUserlistFilterTableRowsLength: (state, action) => {
      state.appealsUserlistFilter.tableRowsLength = action.payload
    },


    setTeacherRatingFilterInstituteFilter: (state, action) => {
      state.teacherRatingFilter.instituteFilter = action.payload
    },

    setTeacherRatingFilterTableRowsLength: (state, action) => {
      state.teacherRatingFilter.tableRowsLength = action.payload
    },
    setTeacherRatingFilterSortOrder: (state, action) => {
      state.teacherRatingFilter.sortOrder = action.payload
    },
    setTeacherRatingFilterSortColumn: (state, action) => {
      state.teacherRatingFilter.sortColumn = action.payload
    },
  },
})


export const {
  setAdminUserlistFilterUsersSearch,
  setAdminUserlistFilterSectionFilter,
  setAdminUserlistFilterStatusFilter,
  setAdminUserlistFilterInstituteFilter,
  setAdminUserlistFilterPositionNameFilter,
  setAdminUserlistFilterTableRowsLength,

  setAppealsUserlistFilterUsersSearch,
  setAppealsUserlistFilterInstituteFilter,
  setAppealsUserlistFilterPositionNameFilter,
  setAppealsUserlistFilterTableRowsLength,

  setTeacherRatingFilterInstituteFilter,
  setTeacherRatingFilterTableRowsLength,
  setTeacherRatingFilterSortOrder,
  setTeacherRatingFilterSortColumn,
} = efectiveContractSlices.actions

export default efectiveContractSlices.reducer

import { TitleH3, TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import ButtonSimple, { IconComponent, } from '../../../../common/ButtonSimple/ButtonSimple'
import { IconCircleCheck, IconCircleX, } from '@tabler/icons'
import { useEffect, useState, } from 'react'
import TextArea from '../../../../common/TextArea/TextArea'
import { useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import TeacherSearch from '../../../Hooks/TeacherSearch/TeacherSearch'
import AttachedParticipants from '../../../AttachedParticipants/AttachedParticipants'
import { table_cat_1_1, } from '../../../popUpInputsCatalogs'
import SpecsSearch from '../../../Hooks/SpecsSearch/SpecsSearch'
import InputFile from '../../../../common/InputFile/InputFile'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import { getScores_1_1, } from '../../../getScores'
import { useGetEcListData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { Divider, } from '@mui/material'


const PopUpAppealContent_1_1 = ({
  data,
  onClosePopUp,
}: any) => {
  const [
    isAddMode,
    setIsAddMode,
  ] = useState(false)
  const [
    participants,
    setParticipants,
  ] = useState('')
  const [
    authors,
    setAuthors,
  ] = useState<any>([])
  const [
    ids,
    setIds,
  ] = useState([])

  const [
    book_title,
    setBook_title,
  ] = useState('')
  const [
    type_work,
    setType_work,
  ] = useState('')
  const [
    publish,
    setPublish,
  ] = useState('')
  const [
    publication_year,
    setPublication_year,
  ] = useState('')
  const [
    countAuthors,
    setСountAuthors,
  ] = useState('')
  const [
    major,
    setMajor,
  ] = useState('')
  const [
    disciplineName,
    setDisciplineName,
  ] = useState('')
  const [
    volume,
    setVolume,
  ] = useState('')
  const [
    format,
    setFormat,
  ] = useState('')
  const [
    financeSource,
    setFinanceSource,
  ] = useState('')
  const [
    print_run,
    setPrint_run,
  ] = useState('')
  const [
    document,
    setDocument,
  ] = useState([])

  const [
    textAppeal,
    setTextAppeal,
  ] = useState('')
  const [
    currentAppealId,
    setCurrentAppealId,
  ] = useState(data.rec_id)
  const [
    currentAchievementId,
    setCurrentAchievementId,
  ] = useState(null)
  const [
    rejectAchievement,
    setRejectAchievement,
  ] = useState(false)
  const [
    reason,
    setReason,
  ] = useState('')
  const [
    scores,
    setScores,
  ] = useState('')


  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setIds([
      ...data.authors.map((item: any) => item.guid),
    ])
    setAuthors(data.authors)

    setBook_title(data.book_title)
    setType_work(data.type_work)
    setPublish(data.publish)
    setPublication_year(data.publication_year)
    setСountAuthors(data.countAuthors)
    setMajor(data.major)
    setDisciplineName(data.disciplineName)
    setVolume(data.volume)
    setFormat(data.format)
    setFinanceSource(data.financeSource)
    setPrint_run(data.print_run)
    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setDocument(data.document ? [
      data.document,
    ] : [])

    setTextAppeal(data.textAppeal)
    setCurrentAppealId(data.rec_id)
  }, [
    data,
  ])


  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    setScores(getScores_1_1({
      type_work: type_work,
      publish: publish,
      authors: authors,
    }))
  }, [
    type_work,
    publish,
    authors,
  ])


  const [
    currentAchievement,
    setCurrentAchievement,
  ] = useState(null)
  const [
    currentAppeal,
    setCurrentAppeal,
  ] = useState(null)

  const getCurrentAppeal = useGetEcListData({
    rec_id: currentAppealId,
  })

  const getCurrentAchievement = useGetEcListData({
    rec_id: currentAchievementId,
  })

  useEffect(() => {
    if (getCurrentAppeal?.data) {
      setCurrentAppeal(
        getCurrentAppeal?.data?.ecListData[0]?.data)

      setCurrentAchievementId(getCurrentAppeal?.data?.ecListData[0]?.data?.rec_id)
    }
  }, [
    getCurrentAppeal.loading,
    getCurrentAppeal.data,
  ])

  useEffect(() => {
    if (getCurrentAchievement?.data && currentAchievementId) {
      setCurrentAchievement(
        getCurrentAchievement?.data?.ecListData[0]?.data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCurrentAchievement.loading,
    getCurrentAchievement.data,
  ])


  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (rec_id: any, newData: any, ids: any) => onUpdateRow({
    variables: {
      rec_id: rec_id,
      new_data: newData,
      user_ids: ids,
    },
  })


  useEffect(() => {
    setRejectAchievement(false)
  }, [
    data,
  ])


  return <>
    <TitleH3>
      Издание учебной и научной литературы
    </TitleH3>
    <p>Внесите требуемые изменения и подтвердие или отклоните апелляцию</p>
    <p>Комментарий: <i><b>{ textAppeal }</b></i></p>
    <br />


    { !rejectAchievement ? (
      <>
        <ButtonSimple
          value={ isAddMode ? 'Применить' : 'Добавить / удалить участников' }
          variant="alone"
          onClickHandler={ () => {
            setIsAddMode(!isAddMode)
            setParticipants('')
          } }
        />

        <br /><br /><Divider /><br />

        { isAddMode ? (
          <>
            <TeacherSearch
              defaultHint="Добавьте участников по их ФИО"
              value={ participants }
              onChangeValue={ setParticipants }
              handler={ (answer: any) => {
                if (!ids.find(id => id === answer.guid)) {
                  setIds([
                    // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
                    ...ids,
                    // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
                    answer.guid,
                  ])

                  setAuthors([
                    ...authors,
                    {
                      guid: answer.guid,
                      fio: answer.fio,
                    },
                  ])
                }
              } }
            />

            <br />

            <AttachedParticipants
              // @ts-expect-error TS(2339): Property 'fio' does not exist on type 'never'.
              participantsList={ authors.map(author => author.fio) }
              getName={ (name: any) => {
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                const removeId = authors.find(item => item.fio === name).guid

                setIds(ids.filter(item => item !== removeId))

                if (removeId === null) {
                  // @ts-expect-error TS(2339): Property 'fio' does not exist on type 'never'.
                  setAuthors(authors.filter(item => item.fio !== name))
                } else {
                  // @ts-expect-error TS(2339): Property 'guid' does not exist on type 'never'.
                  setAuthors(authors.filter(item => item.guid !== removeId))
                }
              } }
            />
          </>
        ) : (
          <>
            <InputTextDeprecated
              defaultHint="ФИО автора (авторов)"
              // @ts-expect-error TS(2339): Property 'fio' does not exist on type 'never'.
              value={ authors.map(author => author.fio).join(', ') }
              disabled
            /><br />


            <InputTextDeprecated
              defaultHint="Название работы"
              value={ book_title }
              onChangeValue={ setBook_title }
            /><br />


            <InputSelectDeprecated
              defaultHint="Вид работы"
              items={ table_cat_1_1.projectType }
              defaultValue={ type_work }
              withResetValue={ true }
              callBack={ setType_work }
            /><br />


            <InputSelectDeprecated
              defaultHint="Издательство"
              items={ table_cat_1_1.publish }
              defaultValue={ publish }
              withResetValue={ true }
              callBack={ setPublish }
            /><br />


            <InputTextDeprecated
              defaultHint="Год издания"
              value={ publication_year }
              onChangeValue={ setPublication_year }
              numbersOnly
            /><br />


            <InputTextDeprecated
              defaultHint="Количество авторов"
              value={ authors.length }
              disabled
            /><br />


            <SpecsSearch
              defaultHint="Шифр и наименование направления подготовки / специальности"
              value={ major }
              onChangeValue={ setMajor }
              handler={ (answer: any) => {
                setMajor(answer)
              } }
            /><br />


            <InputTextDeprecated
              defaultHint="Название дисциплины"
              value={ disciplineName }
              onChangeValue={ setDisciplineName }
            /><br />


            <InputTextDeprecated
              defaultHint="Объем, п.л"
              value={ volume }
              onChangeValue={ setVolume }
              numbersOnly
            /><br />


            <InputSelectDeprecated
              defaultHint="Формат издания"
              items={ table_cat_1_1.format }
              defaultValue={ format }
              callBack={ setFormat }
            /><br />


            <InputSelectDeprecated
              defaultHint="Источник финансирования"
              items={ table_cat_1_1.financeSource }
              defaultValue={ financeSource }
              callBack={ setFinanceSource }
            /><br />


            <InputTextDeprecated
              defaultHint="Тираж"
              value={ print_run }
              onChangeValue={ setPrint_run }
              numbersOnly
            /><br />


            <TitleH5>
              Документ
            </TitleH5>

            { document.length < 1 ? (
              <InputFile
                onChange={ event => {
                  // TODO: сделать upload
                  // uploadFile(...event.target.files, setDocument)
                } }
              />
            ) : false }

            <AttachedFiles
              isDeleteFilesAllowed={ true }
              fileList={ document }
              getIndexFile={ (index: any) => {
                setDocument(document.filter((file, fIndex) => fIndex !== index))
              } }
            /><br />


            <InputTextDeprecated
              defaultHint="Баллы"
              value={ scores }
              numbersOnly
              disabled
            /><br />
          </>
        ) }
      </>
    ) : false }


    { rejectAchievement ? (
      <>
        <TextArea
          defaultHint='По какой причине вы хотите отклонить заявку?'
          withPlaceholder={ false }
          value={ reason }
          onChangeValue={ setReason }
        />

        <br />

        <div style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }>
          <ButtonSimple
            value="Отменить"
            variant="groupe"
            styles={ {
              marginRight: '1rem',
              backgroundColor: '#F5F6FA',
            } }
            onClickHandler={ () => {
              setRejectAchievement(false)
            } }
          />

          <ButtonSimple
            value={ (
              <IconComponent
                Icon={ IconCircleX }
                iconStyles={ { color: '#FF5E5E', } }
                text="Отклонить"
              />
            ) }
            variant="groupe"
            isDisabled={ reason.split(' ').length < 3 }
            onClickHandler={ () => {
              onUpdateRowHandler(
                currentAppealId,
                {
                  // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                  ...currentAppeal,
                  status: 'appealRejected',
                  appealConclusion: reason,
                },
                ids
              )

              onClosePopUp()
            } }
          />
        </div>
      </>
    ) : false }

    { !rejectAchievement ? (
      <div style={ {
        display: 'flex',
        justifyContent: 'flex-end',
      } }>
        <ButtonSimple
          isDisabled={ ids.length < 1 }
          value={ (
            <IconComponent
              Icon={ IconCircleX }
              iconStyles={ { color: '#FF5E5E', } }
              text="Отклонить"
            />
          ) }
          variant="groupe"
          styles={ { marginRight: '1rem', } }
          onClickHandler={ () => setRejectAchievement(true) }
        />

        <ButtonSimple
          isDisabled={ ids.length < 1 }
          value={ (
            <IconComponent
              Icon={ IconCircleCheck }
              text="Подтвердить"
            />
          ) }
          variant="groupe"
          active
          onClickHandler={ () => {
            onUpdateRowHandler(
              currentAchievementId,
              {
                // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                ...currentAchievement,
                authors: authors,
                book_title: book_title,
                type_work: type_work,
                publish: publish,
                publication_year: publication_year,
                countAuthors: countAuthors,
                major: major,
                disciplineName: disciplineName,
                volume: volume,
                format: format,
                financeSource: financeSource,
                print_run: print_run,
                document: document[0],
                status: 'approved',
                scores: scores,
              },
              ids
            )

            onUpdateRowHandler(
              currentAppealId,
              {
                // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                ...currentAppeal,
                status: 'appealApproved',
                appealConclusion: 'Ваша апелляция удовлетворена',
              },
              ids
            )

            onClosePopUp()
          } }
        />
      </div>
    ) : false }
  </>
}


export default PopUpAppealContent_1_1

import './Popup.less'
import clsx from 'clsx'


interface PopupProps {
  children: JSX.Element,
  isOpen: boolean,
  handlePopup: Function,
  isCloseButton?: boolean,
  styles?: object,
  withScroll?: boolean,
  wrapperStyle?: object,
}


const Popup = ({
  children,
  isOpen,
  handlePopup,
  isCloseButton = true,
  styles,
  withScroll,
  wrapperStyle,
}: PopupProps) => (
  <>
    <div
      className={ clsx('popup', isOpen && 'popup_is-open') }
      onPointerDown={ event => {
        // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Event... Remove this comment to see the full error message
        if (!isCloseButton && event.target.className.split(' ').find((item: any) => item === 'popup')) {
          handlePopup()
        }
      } }
    >
      <div style={ {
        alignSelf: 'center',
        display: 'flex',
      } }>
        <div className="popup__outer-container" style={ wrapperStyle }>
          <div
            className={ clsx({
              'popup__container': true,
              'popup__container_with-scroll': withScroll,
              'popup__container_is-open': isOpen,
              'd-scrollbar': true,
            }) }
            style={ styles }
            onClick={ event => event.stopPropagation() }
          >
            { children }
          </div>
        </div>

        { isCloseButton && (
          // @ts-ignore
          <div className="popup__close-button" onClick={ handlePopup }>X</div>
        ) }

      </div>
    </div>
  </>
)


export default Popup

/* eslint-disable no-console */
import { EndPoints, } from '@/shared/constants/endpoints'


export function downloadFile (i: any) {
  fetch(`${ EndPoints.download }/${ i.upload_id }`, {
    credentials: 'include',
  })
    .then(res => res.blob())
    .then(blob => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = i.name
      link.click()
    })
    .catch(e => console.log('err', e))
}

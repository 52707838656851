import useUpdateEcStages from '../../../../gql/hooks/effectiveContractHooks/useUpdateEcStages'
import { TitleH3, } from '../../../common/GeneralElements/GeneralElements'
import PaperContainerWrapper from '../../../common/PaperContainer/PaperContainerWrapper'
import EcStageCard from '../EcStageCard/EcStageCard'
import styles from './Stages.module.less'


const Stages = ({
  stages,
  active_stage,
}: any) => {
  const { onUpdateEcStageComment, onUpdateEcActiveStage, } = useUpdateEcStages()


  return (
    <PaperContainerWrapper>
      <TitleH3>
        Этапы эффективного контракта
      </TitleH3>

      <div className={ styles.stagesWrapper }>
        { stages.map((stage: any) => (
          <EcStageCard
            stage_number={ stage.stage_number }
            stage_name={ stage.stage_name }
            message={ stage.message }
            isActive={ active_stage.stage_number === stage.stage_number }
            key={ stage.stage_number }
            onUpdateActiveStage={ onUpdateEcActiveStage }
            returnNewMessage={ (data: any) => onUpdateEcStageComment(
              updateMessageData({
                stages,
                ...data,
              })
            ) }
          />
        )) }
      </div>

    </PaperContainerWrapper>
  )
}


const updateMessageData = ({
  stages,
  stage_number,
  newMessage,
}: any) => stages.map((item: any) => {
  if (item.stage_number === stage_number) {
    return {
      ...item,
      message: newMessage,
    }
  } return item
})


export default Stages

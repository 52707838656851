import './CollapseContainer.less'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { IconChevronDown, } from '@tabler/icons'

const CollapseContainer = ({
  content = [],
  styles,
  accordionStyle,
}: any): JSX.Element => (
  <div className="collapsed-container" style={ styles }>
    { content.map((block: any) => {
      const Icon = block.icon
      const [
        FirstCard,
        CardComponent,
      ] =
        typeof block.cardType === 'object'
          ? block.cardType
          : [
            false,
            block.cardType,
          ]

      return (
        <Accordion
          defaultExpanded={ block.opened }
          style={ {
            borderRadius: '1rem',
            boxShadow:
              '0px 12.521552085876465px 6.636422634124756px 0px rgba(0, 0, 0, 0.015)',
            marginBottom: '.7rem',
            ...accordionStyle,
          } }
          key={ block.headerText }
        >
          <AccordionSummary
            className="collapsed-container__accordion-summary"
            style={ {
              padding: Icon ? '0 2.6rem 0 .5rem' : '1.2rem 2.6rem .8rem 1.9rem',
            } }
            expandIcon={ <IconChevronDown /> }
          >
            { Icon ? (
              <div className="collapsed-container__icon-wrapper">
                <Icon
                  style={ {
                    height: '3.2rem',
                    width: '3.2rem',
                    color: '#bfc6e0',
                  } }
                />
              </div>
            ) : (
              false
            ) }

            <Typography
              style={ {
                alignSelf: 'center',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: 0.35,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              } }
            >
              <span className="collapsed-container__header-text">
                { block.headerText }
              </span>

              <span className="collapsed-container__header-text collapsed-container__header-text_subheader">
                { block.subHeaderText }
              </span>
            </Typography>
          </AccordionSummary>

          <AccordionDetails
            className="collapsed-container__accordion-details"
            style={ {
              padding: Icon ? '0 .6rem 2rem 7rem' : '0 1.9rem 2rem 1.9rem',
              display: 'flex',
              flexWrap: 'wrap',
            } }
          >
            { FirstCard ? <FirstCard returnInfo={ block } /> : false }

            { block.cardContent && block.cardContent.length > 0 ? (
              block.cardContent.map((card: any, index: any) => (
                <CardComponent
                  key={ card.title || card.name || index }
                  { ...card }
                />
              ))
            ) : (
              <DefaultContent value={ block.defaultContent } />
            ) }
          </AccordionDetails>
        </Accordion>
      )
    }) }
  </div>
)

const DefaultContent = ({ value, }: any) => (
  <div className="collapsed-container__default-content">{ value }</div>
)

export default CollapseContainer

import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  ePlan: {},
  updateFacultatives: false,
  signedStudents: [],
  updateProcedure: false,
  updateStats: false,
  updateTracks: false,
  trackStudents: false,
  updateProjectThemes: false,
}

export const myEducationSlice = createSlice({
  name: 'MyEducation',
  initialState,
  reducers: {
    setEPlan: (state, action) => {
      state.ePlan = action.payload
    },
    updateFaculty: state => {
      state.updateFacultatives = !state.updateFacultatives
    },
    setSignedStudents: (state, action) => {
      state.signedStudents = action.payload
    },
    setUpdateProcedure: state => {
      state.updateProcedure = !state.updateProcedure
    },
    updateStats: state => {
      state.updateStats = !state.updateStats
    },
    updateTracks: state => {
      state.updateTracks = !state.updateTracks
    },
    updateTrackStudents: state => {
      state.trackStudents = !state.trackStudents
    },
    updateProjectThemes: state => {
      state.updateProjectThemes = !state.updateProjectThemes
    },
  },
})


export const { setEPlan, updateFaculty, setSignedStudents,
  setUpdateProcedure, updateStats, updateTracks, updateTrackStudents, updateProjectThemes, } = myEducationSlice.actions

export default myEducationSlice.reducer

import AttachedFiles from '@/legacy/components/common/AttachedFiles/AttachedFiles'
import InputFile from '@/legacy/components/common/InputFile/InputFile'
import useUpload from '@/legacy/hooks/useUpload/useUpload'
import { FileData, } from '@/legacy/types'
import { OnChangeRowValue, } from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableRow/TableRow'
import { Alert, Snackbar, } from '@mui/material'
import useDeleteUpload from '@/legacy/gql/hooks/common/useDeleteUpload'


const isFileData = (files: FileList | File[] | FileData[] | []): files is FileData[] => {
  if (Array.isArray(files) && files.length < 1) {
    return true
  }

  if ('upload_id' in files[0]) {
    return true
  }

  return false
}


interface TableInputFileProps {
  uploadCategory: string
  cellKey: string,
  fileList: File[] | FileList | FileData[] | undefined,
  onChangeRowValue: OnChangeRowValue,
  onUpdateRowHandler: Function,
}


const TableInputFile = ({
  uploadCategory,
  cellKey,
  fileList,
  onChangeRowValue,
  onUpdateRowHandler,
}: TableInputFileProps) => {
  const {
    onCheckFilesHandler,
    onUploadFileHandler,
    isFilesExists,
    setIsFilesExists,
  } = useUpload()

  const onDeleteUploadHandler = useDeleteUpload()


  return (
    <td>
      <InputFile
        isMultiple
        onChange={ event => {
          const files = event.target.files
          if (files && fileList && isFileData(fileList)) {
            onCheckFilesHandler(files, uploadCategory)
              .then(isUploadFilesAllowed => {
                if (isUploadFilesAllowed) {
                  onChangeRowValue(cellKey, 'loading')

                  onUploadFileHandler(files, uploadCategory)
                    .then(files => {
                      onChangeRowValue(cellKey, [
                        ...fileList,
                        ...files,
                      ])
                    })
                    .then(() => onUpdateRowHandler())
                }
              })
          }
        } }
      />


      <AttachedFiles
        fileList={ fileList }
        isDeleteFilesAllowed={ !!fileList && fileList.length > 1 }
        getDeleteFileInfo={ file => {
          if (fileList && isFileData(fileList)) {
            onChangeRowValue(cellKey, fileList.filter(currentFile => currentFile.name !== file.name))
          }

          if ('upload_id' in file) {
            onDeleteUploadHandler({ uploadId: file.upload_id, })
              .then(() => onUpdateRowHandler())
          }
        } }
      />


      <Snackbar open={ isFilesExists }>
        <Alert onClose={ () => setIsFilesExists(undefined) } severity="error">
          Файл с таким именем уже существует!
        </Alert>
      </Snackbar>
    </td>
  )
}


export default TableInputFile

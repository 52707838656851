import { TableCellType, TableRowType, } from '@/legacy/types'
import useGetCellContent from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/hooks/useGetCellContent'
import { OnChangeRowValue, } from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableRow/TableRow'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'


interface TableCellProps {
  row: TableRowType
  cell: TableCellType
  rowNumber: number
  isRowEditAvailable: boolean
  dataType: EcTableName
  onChangeRowValue: OnChangeRowValue
  onUpdateRowHandler: Function
  isAppealMode?: boolean
}


const TableCell = ({
  row,
  cell,
  rowNumber,
  isRowEditAvailable,
  dataType,
  onChangeRowValue,
  onUpdateRowHandler,
  isAppealMode,
}: TableCellProps): JSX.Element => {
  const cellValue = useGetCellContent({
    cell,
    row,
    rowNumber,
    isRowEditAvailable,
    onChangeRowValue,
    onUpdateRowHandler,
    dataType,
    isAppealMode,
  })

  return (
    <>
      { cellValue }
    </>
  )
}


export default TableCell

import { AuthMe, AuthMePositionItem, } from '@/legacy/redux/features/auth/types'
import { useAppSelector, } from '@/legacy/redux/hooks'
import { UUID, } from '@/legacy/types'


const isExistMe = (me: false | AuthMe): me is AuthMe => !!me


interface Result {
  userGuid: '' | UUID
  userPositions: AuthMePositionItem[]
  isScientificAdviser: boolean
}


/**
 * Добавлять возвращаемые данные по мере необходимости
 */
const useGetUserInfo = () => {
  const result: Result = {
    userGuid: '',
    userPositions: [],
    isScientificAdviser: false,
  }
  const me = useAppSelector(state => state.auth.me)

  if (isExistMe(me)) {
    const userGuid: UUID = me.data.guid
    const userPositions = me.positions
    const isScientificAdviser = me.isScientificAdviser
    result.userGuid = userGuid
    result.userPositions = userPositions
    result.isScientificAdviser = isScientificAdviser
  }

  return { ...result, }
}


export default useGetUserInfo

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from "react";
import { Row } from "antd";
import Col from "../Col/Col";
import "./StudyForm.less";
import { useSelector } from "react-redux";

function StudyForm() {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const personalInfo = useSelector((state) => state.prof.studentInfo);

  const studylBlock = useMemo(() => {
    return personalInfo?.map((i: any) => {
      const {
        namespec,
        recordbook_number,
        department_name,
        eform,
        elevel,
        nameprof,
        course,
        group,
        subgroup,
        basis,
        headman,
      } = i;
      return (
        <div key={personalInfo.indexOf(i)}>
          <h4 className="specTitle">{namespec}</h4>
          <Row gutter={30}>
            <Col
              space={12}
              title="Номер зачетной книжки"
              value={recordbook_number}
            />
            <Col space={12} title="Институт" value={department_name} />
            {/* <Col space={ 8 } title="Факультет" value={ department_name } /> */}
          </Row>
          <Row gutter={30}>
            <Col space={8} title="Форма обучения" value={eform} />
            <Col space={8} title="Уровень подготовки" value={elevel} />
            <Col space={8} title="Специальность" value={namespec} />
          </Row>
          <Row gutter={30}>
            <Col space={8} title="Профиль (специализация)" value={nameprof} />
            <Col space={8} title="Курс" value={course} />
            <Col space={8} title="Группа" value={group} />
          </Row>
          <Row gutter={30}>
            <Col space={8} title="Подгруппа" value={subgroup} />
            <Col space={8} title="Основа обучения" value={basis} />
            {/*
          * Это поле скрыто в соответствии с замечанием от ТГУ
          <Col space={8} title="Староста" value={headman ? headman : "Нет"} />
          */}
          </Row>
        </div>
      );
    });
  }, [personalInfo]);

  return studylBlock;
}

export default StudyForm;

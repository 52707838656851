import { useMemo, } from 'react'
import './JournalCard.less'
import { useDispatch, } from 'react-redux'
import useRedirect from '@/legacy/hooks/useRedirect'
import { setDiscipline, setSemester, setGroup, } from '@/legacy/redux/JournalSlices/StudentSlice'
import { useUserpicQuery, } from '@/legacy/redux/utilsApi/imageApi'

function JournalCard ({
  value,
  group,
  semester,
}: any) {
  const dispatch = useDispatch()
  const { discipline, teachers, } = value
  const redirect = useRedirect()


  function redirectCurrent () {
    dispatch(setDiscipline(value))
    dispatch(setSemester(semester))
    dispatch(setGroup(group))
    redirect('/journal-inner')
  }

  const Teacher = ({
    i,
  }: any) => {
    const teacher = i
    const { data, } = useUserpicQuery(teacher.guid)

    const memo = useMemo(() => {
      if (data) {
        return (
          <div className="teacherWrap">
            <img src={ `data:image/png;base64,${ data }` } alt="avatar" />
            <div>
              <h3>{ teacher.name }</h3>
              <p>Преподаватель</p>
            </div>
          </div>)
      } return false
    }, [
      teacher,
      data,
    ])

    return memo
  }


  return (
    <div className="studentJournalCard" onClick={ redirectCurrent }>
      <div>
        <h2>{ discipline }</h2>
        <p>{ `${ semester } семестр` }</p>
      </div>
      { teachers.map((i: any) => {
        // @ts-expect-error TS(2786): 'Teacher' cannot be used as a JSX component.
        return <Teacher key={ teachers.indexOf(i) } i={ i } />
      }) }
    </div>
  )
}

export default JournalCard

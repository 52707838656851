import clsx from 'clsx'
import { DetailedHTMLProps, HTMLAttributes, } from 'react'


interface GroupPopupProps extends DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement> {
  items: [],
  isOpen: boolean,
  click: Function,
}

const GroupPopup = ({
  items,
  isOpen,
  click,
}: GroupPopupProps): JSX.Element => (
  <ul className={ clsx('refPopup', { 'hidden': !isOpen, }) }>
    {
      items.map((item: any, index: any) => {
        return (
          <li
            onClick={ () => click(item) }
            key={ index }
            className="refPopupItem"
          >
            {
              typeof item === 'string'
                ? item
                : item.namespec
            }
          </li>
        )
      }) }
  </ul>
)


export default GroupPopup

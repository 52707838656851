import { gql, } from '@/__generated__'
import { useQuery, } from '@apollo/client'


export const GET_TEACHERS = gql(`
  query Teachers($search:String, $pagination:JSON) {
    teacherList(search: $search, pagination: $pagination)
  }
`)


const GET_TEACHERS_WITH_ACHIEVEMENT = gql(`
  query EcDataTableByUserAndStatus($input:ecDataTableByUserAndStatusInput!) {
    ecDataTableByUserAndStatus(input:$input)
  }
`)


export const useGetTeacher = (
  search: any,
  pagination: any
) => useQuery(GET_TEACHERS, {
  variables: {
    search,
    pagination,
  },
})


export const useGetTeachersWithAchievement = (
  filter: any,
  pagination: any,
  sort: any,
  mode: any
) => {
  return useQuery(
    GET_TEACHERS_WITH_ACHIEVEMENT,
    {
      variables: {
        input: {
          filter,
          pagination,
          sort,
          mode,
        },
      },
    }
  )
}

import './PopupContentCreate.less'

import { useEffect, useState, } from 'react'
import { TitleH4, TitleH5, } from '../../../common/GeneralElements/GeneralElements'
import INPUTS_1_1 from './contentByTables/Inputs_1_1'
import INPUTS_1_2 from './contentByTables/Inputs_1_2'
import INPUTS_1_3 from './contentByTables/Inputs_1_3'
import INPUTS_1_41 from './contentByTables/Inputs_1_41'
import INPUTS_2_1 from './contentByTables/Inputs_2_1'
import INPUTS_2_2 from './contentByTables/Inputs_2_2'
import INPUTS_2_3 from './contentByTables/Inputs_2_3'
// import INPUTS_2_4 from './contentByTables/Inputs_2_4'
import INPUTS_2_41 from './contentByTables/Inputs_2_41'
import INPUTS_2_5 from './contentByTables/Inputs_2_5'
import INPUTS_2_6 from './contentByTables/Inputs_2_6'
import INPUTS_2_7 from './contentByTables/Inputs_2_7'
import INPUTS_2_8 from './contentByTables/Inputs_2_8'
import INPUTS_2_10 from './contentByTables/Inputs_2_10'
import INPUTS_3_1 from './contentByTables/Inputs_3_1'
import INPUTS_3_2 from './contentByTables/Inputs_3_2'
import INPUTS_3_3 from './contentByTables/Inputs_3_3'
import INPUTS_3_4 from './contentByTables/Inputs_3_4'
import INPUTS_3_5 from './contentByTables/Inputs_3_5'
import INPUTS_4_1 from './contentByTables/Inputs_4_1'


const PopupContentCreateOrEdit = ({
  hint,
  defaultValues,
  onClickHandler,
}: any) => {
  const [
    categoryType,
    setCategoryType,
  ] = useState(null)
  const [
    achievementType,
    setAchievementType,
  ] = useState(null)


  useEffect(() => {
    setCategoryType(defaultValues.categoryType)
    setAchievementType(defaultValues.achievementType)
  }, [
    defaultValues,
  ])


  const contentSelector: any = {
    'table_1_1': <INPUTS_1_1
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_1_2': <INPUTS_1_2
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_1_3': <INPUTS_1_3
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_1_41': <INPUTS_1_41
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_2_1': <INPUTS_2_1
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_2_2': <INPUTS_2_2
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_2_3': <INPUTS_2_3
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    // 'table_2_4': <INPUTS_2_4
    //   hint={ hint }
    //   defaultValues={ defaultValues }
    //   onClickHandler={ onClickHandler }
    // />,

    'table_2_41': <INPUTS_2_41
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_2_5': <INPUTS_2_5
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_2_6': <INPUTS_2_6
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_2_7': <INPUTS_2_7
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_2_8': <INPUTS_2_8
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_2_10': <INPUTS_2_10
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_3_1': <INPUTS_3_1
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_3_2': <INPUTS_3_2
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_3_3': <INPUTS_3_3
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_3_4': <INPUTS_3_4
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_3_5': <INPUTS_3_5
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,

    'table_4_1': <INPUTS_4_1
      hint={ hint }
      defaultValues={ defaultValues }
      onClickHandler={ onClickHandler }
    />,
  }


  return (
    <>
      <h2 className="popup-content-create__title">
        { hint === 'edit'
          ? 'Редактировать'
          : defaultValues.tableName === 'table_4_1'
            ? 'Основания для снижения выплат стимулирующего характера'
            : 'Добавить достижение' }
      </h2>
      <TitleH4>
        { categoryType }
      </TitleH4>

      { defaultValues.tableName !== 'table_4_1' ? (
        <TitleH5>
          { achievementType }
        </TitleH5>
      ) : false }

      <div className="popup-content-create__inputs-wrapper">
        <br />
        { contentSelector[defaultValues?.tableName] }
      </div>
    </>
  )
}

export default PopupContentCreateOrEdit

import { useQuery, gql, } from '@apollo/client'

const EDUCATION_CONTRACES = gql`
  query GetEducationContracts {
    finEducationContracts {
      contract
      createDate
      startDate
      endDate
      customer
      debt
      penalties
      hasPenalties
      educationType
      actual
      refreshDate
      paymentSchedule{
        nearestPayDate
        paySum
        TheSumFaktPayment
        graph{
          PayDo
          TheAmount
        }
      }
      isLegal
    }
  }
`

const EDUCATION_PAYMENTS_HISTORY = gql`
  query GetEducationPaymentsHistory($contract: String) {
    educationPaymentsHistory(contract: $contract)
  }
`

const GET_AVAILABLE_BANKS = gql`
  query QuitancesBanks($contract: String) {
    quittancesBanks(contract: $contract)
  }
`

const DORMITORY_CONTRACTS = gql`
  query GetDormitoryContracts {
    dormitoryContracts
  }
`

const DORMITORY_PAYMENTS = gql`
  query GetDormitoryPayments($number_contract: String) {
    dormitoryPayments(number_contract: $number_contract)
  }
`

const GET_QR = gql`
  query GetQR($contract: String, $type: String) {
    quittances(contract: $contract, type: $type)
  }
`

const ACCRUED_SCHOLARSHIP = gql`
  query GetAccrudeScholarShip($mode: String) {
    accruedScholarship(mode: $mode)
  }
`

const PLANNED_CHARGES = gql`
  query GetPlannedCharges {
    plannedCharges
  }
`

const PERIODS = gql`
query Periods($input:AccruedScholarshipInput) {
  finAccruedScholarship(input: $input) {
    period
  }
}`

const PERIOD_SCOLAR = gql`
query Scolarship($input:AccruedScholarshipInput) {
  finAccruedScholarship(input: $input) {
    period
    rec_id
    updated
    blocks {

      block_accruals {
        sum
        date
        name
      }

      block_holdings{
        sum
        date
        name
      }

      block_payout {
        sum
        date
        name
      }
    }
  }
}`


export const useEducationContracts = () => useQuery(EDUCATION_CONTRACES)
export const useDormitoryContracts = () => useQuery(DORMITORY_CONTRACTS)

export const useScolarPeriod = (input: any) => useQuery(PERIOD_SCOLAR, { variables: { input, }, })

export const useEducationPaymentsHistory = (contract: any) => useQuery(EDUCATION_PAYMENTS_HISTORY, { variables: { contract, }, })

export const useDormitoryPaymentsHistory = (number_contract: any) => useQuery(DORMITORY_PAYMENTS, { variables: { number_contract, }, })

export const useContractAvailableBanks = (contract: any) => useQuery(GET_AVAILABLE_BANKS, { variables: { contract, }, })

export const usePaymentQRCode = ({
  contract,
  type,
}: any) => useQuery(GET_QR, {
  variables: {
    contract,
    type,
  },
})

export const useAccruedScholarship = (mode: any) => useQuery(ACCRUED_SCHOLARSHIP, { variables: { mode, }, })

export const usePlannedCharges = () => useQuery(PLANNED_CHARGES)
export const usePeriods = (input: any) => useQuery(PERIODS, { variables: { input, }, })

import { useContext, } from 'react'
import ButtonSimple from '../../../../../common/ButtonSimple/ButtonSimple'
import InputTextDeprecated, { useInputText, } from '../../../../../common/InputTextDeprecated/InputTextDeprecated'
import { CreateJsonContext, } from '../../../PortfolioEditIndicator'
import styles from './SelectHeader.module.less'


const SelectHeader = ({
  name,
  field,
  fieldType,
  addNew,
  index,
  isSecondLevel,
}: any) => {
  const {
    // @ts-expect-error TS(2339): Property 'addCriteriaElement' does not exist on ty... Remove this comment to see the full error message
    addCriteriaElement,
    // @ts-expect-error TS(2339): Property 'onChangeInputValue' does not exist on ty... Remove this comment to see the full error message
    onChangeInputValue,
    // @ts-expect-error TS(2339): Property 'addTwoLevelCriteriaElement' does not exi... Remove this comment to see the full error message
    addTwoLevelCriteriaElement,
  } = useContext(CreateJsonContext)

  const nameInput = useInputText({
    initialValue: name,
    isLazyData: true,
  })


  return (
    <>
      <div className={ styles.selectNameWrapper }>
        <InputTextDeprecated
          defaultHint={ 'Название' }
          withPlaceholder
          { ...nameInput }
          onBlur={ () => onChangeInputValue({
            field,
            index,
            fieldType,
            subfield: 'name',
            newValue: nameInput.value,
            isSecondLevel,
          }) }
        />

        <ButtonSimple
          active
          value={ 'Добавить пункт списка' }
          variant={ 'alone' }
          onClickHandler={ () => {
            if (addNew === 'select-option') {
              addCriteriaElement(field, { name: '', })
            }

            if (addNew === 'select') {
              addTwoLevelCriteriaElement(field, index, { name: '', })
            }
          } }
        />
      </div>
      <br />
    </>
  )
}


export default SelectHeader

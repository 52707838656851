/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, } from 'react'
import './CardDisciplinePathway.less'
import { IconEyeOff, IconEye, IconLock, IconLockOpen, } from '@tabler/icons'
import { IconCircleCheck, } from '@tabler/icons'
import clsx from 'clsx'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import { styled, } from '@mui/material/styles'
import {
  useDeleteFacultative,
  useUpdateStats,
} from '../../../../../gql/hooks/useMyEducation'
import DeletePopup from '../../../../common/DeletePopup/DeletePopup'
import { useCreateFacultative, } from '../../../../../gql/hooks/useMyEducation'
import AnimatedTrash from '../../../../common/AnimatedTrash/AnimatedTrash'
import useRedirect from '@/legacy/hooks/useRedirect'
import TooltipTgu from '@/shared/ui/TooltipTgu/TooltipTgu'
import { TooltipPlacementVariant, } from '@/shared/ui/TooltipTgu/types'
import { useAppContext, } from '@/app/providers/AppProvider'

const CardDisciplinePathway = ({
  i,
  type,
  styles,
  active = false,
  role = false,
  nameSpec,
  handleActivateOne,
  is_signed,
}: any) => {
  const redirect = useRedirect()

  const [
    isDeletePopup,
    setIsDeletePopup,
  ] = useState(false)

  const [
    esDeleteFacultative,
  ] = useDeleteFacultative({
    facultative_id: i.facultative_id,
  })

  const { openToast, } = useAppContext()

  const handleDeleteDiscipline = (e: any) => {
    e.stopPropagation()
    esDeleteFacultative()
      .then(res => {
        setIsDeletePopup(!isDeletePopup)
        openToast?.({ message: 'Дисциплина успешно удалена', })
      })
      .catch(e => console.log(e))
  }

  const [
    esUpdateDisciplineStats,
  ] = useUpdateStats({
    ref_id: Number(i.facultative_id),
    namespec: nameSpec?.namespec,
    type: 'facultative',
    counter: 'view',
  })

  const handleNavigate = (e: any) => {
    if (role === 'student' || role === 'student-rakus') {
      esUpdateDisciplineStats()
        .then(res => {
          redirect(
            `/learning-pathway/detail?type=${ type }&id=${ i.facultative_id }&name=${ i.name }&namespec=${ nameSpec?.namespec }`
          )
        })
        .catch(e => console.log(e))
    } else {
      redirect(
        `/learning-pathway/detail?type=${ type }&id=${ i.facultative_id }&name=${ i.name }`
      )
    }
  }

  const handlePopupDelete = (e: any) => {
    e.stopPropagation()
    setIsDeletePopup(!isDeletePopup)
  }

  const [
    esCreateFacultative,
  ] = useCreateFacultative({
    ...i,
    is_visible: !i.is_visible,
  })

  const allowParticipate = (e: any) => {
    e.stopPropagation()
    esCreateFacultative()
      .then(res => {
        console.log(res)
        openToast?.({
          message: `Факультатив ${ res.data.esCreateFacultative.is_visible ? 'отображен' : 'скрыт'
          } для студентов`,
        })
      })
      .catch(e => console.log(e))
  }

  return (
    <>
      <div
        className={ clsx({
          'card-discipline-pathway': true,
          'card-discipline-pathway_active': is_signed,
          'unvisible-card': !i.is_visible,
        }) }
        style={ styles }
        onClick={ e => handleNavigate(e) }
      >
        <div className="card-discipline-pathway__title-wrapper">
          <div className="card-discipline-pathway__name">{ i.name }</div>
          <div className="moderStatusesWrap">
            { is_signed ? (
              <IconCircleCheck
                style={ {
                  color: '#7671DD',
                  fontSize: '1.5rem',
                  marginLeft: '.5rem',
                } }
              />
            ) : (
              false
            ) }
            { role === 'osrv-moder' ? (
              i.is_visible ? (
                <TooltipTgu children={
                  <IconEyeOff
                    /*    className={ clsx({
                     'card-discipline-pathway__visible-icon': true,
                     'card-discipline-pathway__visible-icon_active': i.is_visible,
                   }) } */
                    onClick={ e => allowParticipate(e) }
                    color="#7F8190"
                  />
                }
                placement={ TooltipPlacementVariant.bottom }
                title="скрыть дисциплину от студентов" />
              ) : (
                <TooltipTgu children={
                  <IconEye
                    color="#7671dd"
                    onClick={ e => allowParticipate(e) }
                  />
                }
                placement={ TooltipPlacementVariant.bottom }
                title='Отображать дисциплину студентам' />
              )
            ) : (
              false
            ) }
            { role === 'osrv-moder' ? (
              i.is_active ? (
                <TooltipTgu children={
                  <IconLockOpen
                    size={ 27 }
                    color="#7671dd"
                    onClick={ e => handleActivateOne(e, [
                      i.facultative_id,
                    ], !i.is_active)
                    }
                  />
                }
                placement={ TooltipPlacementVariant.bottom }
                title='Закрыть запись' />
              ) : (
                <TooltipTgu children={
                  <IconLock
                    color="#7F8190"
                    onClick={ e => handleActivateOne(e, [
                      i.facultative_id,
                    ], !i.is_active)
                    }
                    size={ 27 }
                  />
                }
                placement={ TooltipPlacementVariant.bottom }
                title='Открыть запись' />
              )
            ) : (
              false
            ) }
          </div>
        </div>

        <div className="card-discipline-pathway__type">
          { type }
          { role === 'osrv-moder' && (
            <AnimatedTrash
              clickHandler={ (e: any) => handlePopupDelete(e) }
              styles={ {
                width: '15px',
                height: '20px',
              } }
            />
          ) }
        </div>

        {/* <div className='card-discipline-pathway__student-count-wrapper'>
    { studentCount ? (
      <div className='card-discipline-pathway__student-count'>
        Студентов { studentCount[0] } / { studentCount[1] }
      </div>
    ) : false }

    { studentCount ? (
      <CustomizedProgressBars
        value={ studentCount[0] / studentCount[1] * 100 }
      />
    ) : false }
  </div> */}
        {/* <ReactTooltip
          place="bottom"
          className="tollTip"
          backgroundColor="#43444D"
        /> */}
      </div>
      <DeletePopup
        title={ `Удалить ${ type } ${ i.name }?` }
        onClickHandler={ (e: any) => handleDeleteDiscipline(e) }
        isDeletePopup={ isDeletePopup }
        setIsDeletePopup={ setIsDeletePopup }
      />
    </>
  )
}

const BorderLinearProgress = styled(LinearProgress)(({ theme, }) => ({
  height: '.5rem',
  width: '7.5rem',
  borderRadius: 5,
  [`&.${ linearProgressClasses.colorPrimary }`]: {
    backgroundColor: '#F5F6FA',
  },
  [`& .${ linearProgressClasses.bar }`]: {
    borderRadius: 5,
    backgroundColor: '#7671DD',
  },
}))

const CustomizedProgressBars = ({ value = 0, }) => {
  return <BorderLinearProgress variant="determinate" value={ value } />
}

export default CardDisciplinePathway

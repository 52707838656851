import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    editable: false,
    index: 'number',
  },

  {
    title: 'Наименование подразделения',
    index: 'department',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'input',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Стипендиаты',
    index: 'winners',
    indexDescription: 'autoFill',
    editable: true,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Победители и призеры олимпиад',
    index: 'winners_olympiad',
    indexDescription: 'autoFill',
    editable: true,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Абитуриенты – победители всероссийских олимпиад',
    index: 'winners_olympiad_all_russia',
    indexDescription: 'autoFill',
    editable: true,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Набрано по показателю',
    index: 'calculated_winners',
    indexDescription: 'autoFill',
    editable: false,
    round: '2',
    editType: 'input'
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Пороговое значение (1 на кафедру)',
    index: 'thresholdValue',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'input'
  },
  {
    title: 'Начислено баллов',
    index: 'scores',
    indexDescription: 'autoFill',
    editable: false,
    ceil: '0',
    editType: 'input'
  },

  {
    title: 'Статус',
    editable: false,
    index: 'status',
    style: { minWidth: '11rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    editable: false,
    index: 'number',
  },

  {
    title: 'Наименование подразделения',
    index: 'department',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'input',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Стипендиаты',
    index: 'winners',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Победители и призеры олимпиад',
    index: 'winners_olympiad',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Абитуриенты – победители всероссийских олимпиад',
    index: 'winners_olympiad_all_russia',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Набрано по показателю',
    index: 'calculated_winners',
    editable: false,
    round: '2',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Пороговое значение',
    index: 'thresholdValue',
    indexDescription: 'autoFill',
    editable: false,
  },
  {
    title: 'Начислено баллов',
    index: 'scores',
    indexDescription: 'autoFill',
    editable: false,
    ceil: '0',
  },

  {
    editable: false,
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
  },
  {
    title: 'Комментарий',
    editable: false,
    index: 'textAppeal',
    style: { minWidth: '20rem', },
  },
  {
    title: 'Статус',
    editable: false,
    index: 'status',
    style: { minWidth: '11rem', },
  },
]

import clsx from 'clsx'
import { useState, } from 'react'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import InputTextDeprecated from '../../../common/InputTextDeprecated/InputTextDeprecated'
import styles from './EcStageCard.module.less'

const EcStageCard = ({
  stage_number,
  stage_name,
  message,
  isActive,
  returnNewMessage,
  onUpdateActiveStage,
}: any) => {
  const [
    newMessage,
    setNewMessage,
  ] = useState(message)

  return (
    <div className={ styles.card }>
      <header
        className={ clsx({
          [styles.header]: true,
          [styles.header_active]: isActive,
        }) }
      >
        <div
          className={ clsx({
            [styles.descriptionWrapper]: true,
            [styles.descriptionWrapper_active]: isActive,
          }) }
        >
          <span className={ styles.descriptionText }>Этап { stage_number }</span>
          <span className={ styles.descriptionText }>{ isActive && 'Активно' }</span>
        </div>
        <span
          className={ clsx({
            [styles.title]: true,
            [styles.title_active]: isActive,
          }) }
        >
          { stage_name }
        </span>
      </header>

      <div className={ styles.footer }>
        <InputTextDeprecated
          withPlaceholder
          defaultHint={ 'Сообщение для преподавателей' }
          isAutosizeArea
          styles={ { border: 'none', } }
          onChangeValue={ setNewMessage }
          value={ newMessage }
        />

        <br />
        <br />

        <div className={ styles.buttonWrapper }>
          <ButtonSimple
            isDisabled={ isActive }
            active
            value="Включить"
            onClickHandler={ () => onUpdateActiveStage(stage_number) }
          />

          <ButtonSimple
            value="Сохранить"
            onClickHandler={ () => returnNewMessage({
              stage_number,
              newMessage,
            }) }
          />
        </div>
      </div>
    </div >
  )
}


export default EcStageCard

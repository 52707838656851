import { useQuery, gql, useMutation, useSubscription, } from '@apollo/client'
import { NameProfsListInput, } from '@/__generated__/graphql'

const GET_INITIATES = gql(`
  query GetAllProjects($params: JSON, $pagination: JSON) {
    paGetAll(params: $params, pagination: $pagination)
  }
`)

const GET_SPECS = gql(`
query NameSpecList($search: String) {
  namespecs(search: $search)
}
`)
const GET_ACCEPTED_SPECS = gql(`
query listNameSpecsWithAcceptedStudents($filter: JSON) {
  listNameSpecsWithAcceptedStudents(filter: $filter)
}
`)
const GET_PROFS = gql(`
query ProfsList($search: String) {
  nameprofs(search: $search)
}
`)
const GET_PROFS_ACCEPTED = gql(`
query Query($namespec: [String], $search: String) {
  listNameProfsWithAcceptedStudents(namespec: $namespec, search: $search)
}
`)
const GET_PROFS_BY_SPEC = gql(`
query ProfsListBySpec($input: NameProfsListInput) {
  nameprofsList(input: $input)
}
`)

const GET_PERSONS = gql(`
query GetPersons($search: String, $mode: String, $pagination: JSON) {
  persons(search: $search, mode: $mode, pagination: $pagination)
}
`)

const GET_TASKS = gql(`
query GetTasks($params: JSON) {
  paTasks(params: $params)
}
`)

const GET_STAGES = gql(`
query StagesList($params: JSON){
  paStages(params: $params)
}
`)

const GET_PROJECT_APPLYS = gql(`
query GetProjectApplys($params: JSON, $pagination: JSON){
  paListProjects(params: $params, pagination: $pagination)
}
`)


const APPLY_CREATE_PROJECT = gql(`
  mutation ApplyProject($params: JSON) {
    paApply(params: $params)
  }
`)

const CREATE_PROJECT = gql(`
mutation CreateProject($params: JSON) {
  paControl(params: $params)
}
`)


const INITIATE_PROJECT = gql(`
  mutation ProjectActivityCreate($params: JSON) {
    paInitiate(params: $params)
  }
`)

const HANDLE_STATUS = gql(`
mutation Modify($params: JSON) {
  paModify(params: $params)
}
`)


const PROJECT_INT_CHANGED = gql(`
subscription OnProjectAdded {
  projectChanged
}
`)

const ADD_STAGE = gql(`
mutation AddStage($params: JSON) {
  paChangeStage(params: $params)
}
`)

const DELETE_STAGE = gql(`
mutation DeleteStage($req_id:Int!,$stage_num:Int!,$event:String!) {
  paDeleteStage(req_id: $req_id, stage_num: $stage_num, event: $event)
}
`)

const ADD_TASK = gql(`
mutation AddTask($params: JSON) {
  paAddTask(params: $params)
}
`)

const CHANGE_TASK = gql(`
mutation ChangeTask($params: JSON) {
  paChangeTask(params: $params)
}
`)
const DELETE_APPLY = gql(`
mutation DeleteApply($req_id: [Int]) {
  paDeleteInitiative(req_id: $req_id)
}
`)

const DELETE_PROJECT = gql(`
mutation DeleteProject($params: JSON) {
  paDeleteProject(params: $params)
}
`)

const ADD_COMMENT = gql(`
mutation AddComment($params: JSON) {
  paAddComment(params: $params)
}
`)

const GET_COMMENTS = gql(`
query GetComments($params: JSON){
  paComments(params: $params)
}
`)

const GET_PROJECT = gql(`
query GetProject($req_id: Int){
  paGetProject(req_id: $req_id)
}
`)

const GET_TASK = gql(`
query GetTask($task_id: Int){
  paGetTask(task_id: $task_id)
}
`)

const GET_CONFIG = gql(`
query paGetConfig($configName:String,$defaultConfig:JSON){
  paGetConfig(configName:$configName,defaultConfig:$defaultConfig)
}
`)

const SET_CONFIG = gql(`
mutation PaSetConfig($configName:String,$config:JSON){
  paSetConfig(configName:$configName,config:$config)
}
`)


export const useApplyProject = () => useMutation(APPLY_CREATE_PROJECT)
export const useCreateProject = () => useMutation(CREATE_PROJECT)
export const useDeleteApply = () => useMutation(DELETE_APPLY)
export const useProjectActivityCreate = () => useMutation(INITIATE_PROJECT)
export const useProjectInitiated = () => useSubscription(PROJECT_INT_CHANGED)
export const useDeleteProject = () => useMutation(DELETE_PROJECT)
export const useGetAllProjects = (params: any, pagination: any) => useQuery(GET_INITIATES, {
  variables: {
    params,
    pagination,
  },
})
export const useGetProjectApplys = (params: any, pagination: any) => useQuery(GET_PROJECT_APPLYS, {
  variables: {
    params,
    pagination,
  },
})
export const useNameSpecList = (search: any) => useQuery(GET_SPECS, { variables: { search, }, })
export const useNameSpecListAccepted = (filter: any) => useQuery(GET_ACCEPTED_SPECS, { variables: { filter, }, })
export const useGetConfig = (configName: any, defaultConfig: any) => useQuery(GET_CONFIG, {
  variables: {
    configName,
    defaultConfig,
  },
})
export const useProfsList = (search: any) => useQuery(GET_PROFS, { variables: { search, }, })
export const useProfsListAccepted = (namespec: any, search: any) => useQuery(GET_PROFS_ACCEPTED, { variables: { namespec,
  search, }, })
export const useProfsListBySpec = (name: String) => useQuery(GET_PROFS_BY_SPEC, {
  variables: {
    input: {
      filter: {
        name,
      },
    },
  },
})
export const useGetPersons = (search: any, mode: any, pagination: any) => useQuery(GET_PERSONS, {
  variables: {
    search,
    mode,
    pagination,
  },
})
export const useModify = () => useMutation(HANDLE_STATUS)
export const useAddStage = () => useMutation(ADD_STAGE)
export const useDeleteStage = () => useMutation(DELETE_STAGE)
export const useSetConfig = () => useMutation(SET_CONFIG)
export const useAddTask = () => useMutation(ADD_TASK)
export const useChangeTask = () => useMutation(CHANGE_TASK)
export const useAddComment = () => useMutation(ADD_COMMENT)
export const useStagesList = (params: any) => useQuery(GET_STAGES, { variables: { params, }, })
export const useGetComments = (params: any) => useQuery(GET_COMMENTS, { variables: { params, }, })
export const useGetTasks = (params: any) => useQuery(GET_TASKS, { variables: { params, }, })
export const useGetProject = (req_id: any) => useQuery(GET_PROJECT, { variables: { req_id, }, })
export const useGetTask = (task_id: any) => useQuery(GET_TASK, { variables: { task_id, }, })

import './MobileApplicationLinks.less'
import googleLogo from '@/shared/assets/images/google.svg'
import apple from '@/shared/assets/images/apple.svg'


function MobileApplicationLinks () {
  return (
    <div className="appContainer">
      <a href="https://apps.apple.com/us/app/%D0%BC%D0%BE%D0%B9-%D1%82%D0%B3%D1%83/id1645166117" target="blank">
        <div className="appLink">
          <img src={ apple } alt="logo" />
          <p>Загрузите<br />в AppStore</p>
        </div>
      </a>

      <a href="https://play.google.com/store/apps/details?id=com.mytgumobile" target="blank">
        <div className="appLink">
          <img src={ googleLogo } alt="logo" />
          <p>Загрузите<br />в Google Play</p>
        </div>
      </a>
    </div>
  )
}

export default MobileApplicationLinks

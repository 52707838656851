import React, { useState, } from 'react'
import './AddStudentPopup.less'
import Popup from '../Popup/Popup'
import { DebounceInput, } from 'react-debounce-input'
import { IconSearch, } from '@tabler/icons'
import ButtonSimple from '../ButtonSimple/ButtonSimple'
import StudentsList from './StudentsList'
// import useStudentInfoById from '../../../../../gql/hooks/useStudentInfo';

function AddStudentPopup ({
  isOpen,
  handlePopup,
  handleSubmit,
  isSubmitDisabled,
}: any) {
  const [
    student,
    setStudent,
  ] = useState('')
  const [
    newStudents,
    setNewStudents,
  ] = useState([])

  return (
    <Popup isOpen={ isOpen } handlePopup={ () => handlePopup(!isOpen) } >
      <div className="addStudentWrap">
        <h2 className="addStudentTitle">Добавить студентов</h2>


        <div className="searchWrap">
          <IconSearch color="#C5C5C5" />

          <DebounceInput
            debounceTimeout={ 500 }
            type="text"
            placeholder='Ф.И.О студента'
            onChange={ e => setStudent(e.target.value) }
            value={ student } />
        </div>


        <div className="addStudentsScroll" >
          { student.length > 3 && (
            <StudentsList
              setNewStudents={ setNewStudents }
              newStudents={ newStudents }
              student={ student }
            />) }
        </div>


        <ButtonSimple
          onClickHandler={ () => handleSubmit(newStudents) }
          variant="fill"
          value="Добавить студентов"
          styles={ {
            width: 'fit-content',
            alignSelf: 'end',
            marginTop: '10px',
          } }
        />
      </div>
    </Popup>
  )
}

export default AddStudentPopup

import React from 'react'
import clsx from 'clsx'
import { useDispatch, } from 'react-redux'
import { setLesson, } from '@/legacy/redux/JournalSlices/StudentSlice'
import { setType } from '@/legacy/redux/NotificationsSlice/NotificationsSlice'


function TypeFilter ({
  items,
  isOpen,
  type,
  setStat,
}: any) {
  const dispatch = useDispatch()

  function chooseType (i: any) {
    if (type === 'Уведомления') {
      dispatch(setType(i))
    } else if (type === 'own') {
      setStat(i)
    } else {
      dispatch(setLesson(i))
    }
  }


  return (
    <ul className={ clsx('refPopup', !isOpen && 'hidden') }>
      { items.map((item: any, index: any) => {
        return (
          <li
            className="refPopupItem"
            onClick={ () => chooseType(item) }
            key={ index }
          >
            { item }
          </li>
        )
      }) }
    </ul>
  )
}


export default TypeFilter

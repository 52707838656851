import React from 'react'
import { gql, useQuery, } from '@apollo/client'
import cls from './BasicIndicatorsTab.module.less'

import AchievementAccordion from '@/legacy/components/EffectiveContract/Tabs/refactor/AchievementAccordion/AchievementAccordion'
import { useSelector, } from 'react-redux'
import TableSpinner from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/TableSpinner/TableSpinner'

const EC_ACHIEVEMENT_TYPE_BY_DTSUID = gql`
    query Query($dtsuId: String!) {
        ecAchievementTypeByDTSUID(dtsuId: $dtsuId)
    }
`

const BasicIndicatorsTab_2 = ({ stage, handlers, }: any) => {
  // console.log(handlers)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)
  const { loading, error, data, } = useQuery(EC_ACHIEVEMENT_TYPE_BY_DTSUID, {
    variables: { dtsuId: currentUserId, },
  })
  if (loading) {
    return <TableSpinner></TableSpinner>
  }
  if (error) {
    return <p>Error: {error.message}</p>
  }

  const firstItem = data?.ecAchievementTypeByDTSUID.find((item: any) => item.achievementTypeName === 'Базовые показатели')

  if (!firstItem) {
    return <p className={cls.title}>Базовая часть не предусмотрена</p>
  }

  const children = firstItem?.children || []

  return (
    <div className="basic-indicators-tab-2">
      {children.map((achievement: any) => (
        <AchievementAccordion
          key={achievement.id}
          achievement={achievement}
          moderateId={currentUserId}
          stage={stage}
          handlers={handlers}/>
      ))}
    </div>
  )
}

export default BasicIndicatorsTab_2

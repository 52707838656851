import { FormData, } from '@/legacy/components/Projects/NewProject/types/types'
import { useState, } from 'react'

export function useFormFields(initialValues: FormData) {
  const [
    formFields,
    setFormFields,
  ] = useState(initialValues)

  function handleFieldChange(fieldName: any, value:any) {
    setFormFields({ ...formFields,
      [fieldName]: value, })
  }
  function setFields(fieldsToUpdate: Partial<FormData>) {
    setFormFields({ ...formFields,
      ...fieldsToUpdate, })
  }
  function resetForm() {
    setFormFields(initialValues)
  }

  return {
    formFields,
    setField: handleFieldChange,
    setFields,
    resetForm,
  }
}

import { useState, useMemo, } from 'react'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import './Portfolio.less'
import RatingScale from './RatingScale/RatingScale'
import AchivmentCard from './AchivmentCard/AchivmentCard'
import AddAchivmentPopup from './AddAchivmentPopup/AddAchivmentPopup'
import useAchievementDirections from '../../gql/hooks/portfolio/useAchievementDirections'
import { usePersonalRating, } from '../../gql/hooks/useAchievements'
import { useDispatch, useSelector, } from 'react-redux'
import { setIsDetails as importedSetIsDetails, } from '../../redux/PortfolioSlices/PortfolioSlice'
import useAchievementChanged from '../../gql/hooks/portfolio/useAchievementChanged'
import { useLocation, } from 'react-router-dom'
import { useEffect, } from 'react'
import useGetNotifications from '../../gql/hooks/portfolio/useGetNotifications'
import useAverageScoreRecordBook from '../../gql/hooks/portfolio/useAverageScoreRecordBook'
import useRequiredStudentPapers from '../../gql/hooks/portfolio/useRequiredStudentPapers'
import AchivmentDetails from './AchivmentDetails/AchivmentDetails'
import { onAddToHistory, } from '@/legacy/redux/HistorySlice/HistorySlice'
import { useRecordbook, } from '@/legacy/gql/hooks/useRecordbook'


const historyLinks = [
  {
    value: 'Главная',
    path: '/',
  },
]


const sortDirections = (inputDirections: any) => {
  const directions = JSON.parse(JSON.stringify(inputDirections))
  const findElementName = 'Работы обучающегося'
  const findElementIndex = directions.findIndex((direction: any) => direction.name === findElementName)

  directions.splice(0, 0, directions[findElementIndex])
  directions.splice(findElementIndex + 1, 1)

  return directions
}


function Portfolio () {
  const { pathname, } = useLocation()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const history = useSelector(state => state.history.history)
  const setHistory = (pathName: any) => dispatch(onAddToHistory(pathName))

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const isDetails = useSelector(state => state.port.isDetails)
  const dispatch = useDispatch()
  const setIsDetails = (value: any) => dispatch(importedSetIsDetails(value))

  const personalRating = usePersonalRating()
  const [
    isAdd,
    setIsAdd,
  ] = useState(false)
  const { directions: achievementDirections, } = useAchievementDirections({ is_admin: true, })
  const categoriesNotifications = useGetNotifications()

  const recordBooks = useRecordbook()?.data?.recordbooks
  const { averageScoreRecordBook, } = useAverageScoreRecordBook({
    recordBookNumber: recordBooks?.[0]?.recordbook_number,
  })
  const { requiredStudentPapers, } = useRequiredStudentPapers({
    recordBookNumber: recordBooks?.[0]?.recordbook_number,
  })

  useAchievementChanged(personalRating.refetch)


  useEffect(() => {
    if (history.length > 0) {
      setIsDetails(false)
    }

    setHistory(pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const directions = useMemo(() => {
    const setIsDetails = (value: any) => dispatch(importedSetIsDetails(value))

    if (achievementDirections) {
      const directions = sortDirections(achievementDirections)

      directions.unshift({
        key: 0,
        name: 'Средний балл зачетной книжки',
        additionalCardData: averageScoreRecordBook,
      })

      const studentWorks = directions.find((direction: any) => direction.name === 'Работы обучающегося')
      if (studentWorks) {
        studentWorks.additionalCardData = requiredStudentPapers
      }


      return directions.map(
        (achievementDirection: any, index: any) => {
          const achievementPersonalRating = personalRating?.data?.achievementPersonalRating


          return (
            <>
              { (
                !!achievementDirection.available
                || (!!achievementPersonalRating && achievementPersonalRating.some((item: any) => item.direction === achievementDirection.name))
              )
                && (
                  <AchivmentCard
                    key={ index }
                    currentStudentRating={ achievementPersonalRating?.find((item: any) => item.direction === achievementDirection.name) }
                    achievementDirection={ achievementDirection }
                    setIsDetails={ () => setIsDetails(achievementDirection) }
                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    notificationsCount={ categoriesNotifications[achievementDirection.key] || null }
                    additionalCardData={ achievementDirection.additionalCardData }
                  />
                ) }
            </>
          )
        }
      )
    }
  }, [
    achievementDirections,
    dispatch,
    averageScoreRecordBook,
    requiredStudentPapers,
    personalRating?.data?.achievementPersonalRating,
    categoriesNotifications,
  ])


  return (
    <div className="InnerPage portfolio " >
      <HistoryLinks links={ historyLinks } loc="Портфолио" />

      <div className="studentInner scroll">
        <h2 className="profileTitle">Портфолио</h2>

        <RatingScale setIsAdd={ setIsAdd } isAdd={ isAdd } />
      </div>

      { isDetails ? (
        <AchivmentDetails setIsDetails={ setIsDetails } i={ isDetails } />
      ) : (
        <div className="cardList">
          { directions }
        </div>
      ) }

      <AddAchivmentPopup isOpen={ isAdd } setIsOpen={ setIsAdd } />
    </div>
  )
}

export default Portfolio

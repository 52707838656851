import './PopupContentSupplyAppeal.less'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import InputTextDeprecated from '../../../common/InputTextDeprecated/InputTextDeprecated'
import appealCheck from './appealCheck.svg'
import {useEffect, useState,} from 'react'
import useUpdateEcData from '@/legacy/gql/hooks/effectiveContractHooks/useUpdateEcData'
import useCreateEcData from '@/legacy/gql/hooks/effectiveContractHooks/useCreateEcData'
import {EcAppealStatus, EcTableName,} from '@/legacy/components/EffectiveContract/constants'


export const defineAppealDataType = (tableName: any) => {
  return tableName.replace('table', 'appeal')
}


export const PopupContentSupplyAppeal = ({
  onClosePopUp,
  defaultValues,
}: any) => {
  const [
    textAppeal,
    setTextAppeal,
  ] = useState('')
  const [
    isComfirmDisplay,
    setIsConfirmDisplay,
  ] = useState(false)


  useEffect(() => {
    setIsConfirmDisplay(false)
  }, [
    defaultValues,
  ])


  const { onUpdateEcDataHandler, } = useUpdateEcData()

  const onSupplyAppealHandler = async (oldData:any, appealLinkId:any) => {
    const result = await onUpdateEcDataHandler({
      recordId: oldData.rec_id,
      newData: {
        ...oldData,
        appealLinkId: appealLinkId,
      },
      userIds: oldData?.user_ids || oldData.userId || null,
    })
    return result
  }

  const { onCreateEcDataHandler, } = useCreateEcData()

  const onAddNewEcDataAppealHandler = (params: any, oldDataOfCurrentAchievement: any) => {
    params.variables.data.isAppeal = true
    onCreateEcDataHandler(
      { dataType: params.variables.dataType as EcTableName,
        newData: {
          rec_id: params.variables.data.rec_id,
          data: {},
          status: EcAppealStatus.APPEAL_PENDING,
          reason: params.variables.data.reason,
          scores: params.variables.data.scores,
          textAppeal: params.variables.data.textAppeal,
          isAddedByModer: false,
          user_id: params.variables.data.user_id,
        },
        user_ids: params?.user_ids || params?.userId || null, })
      .then(data => {
        onSupplyAppealHandler(oldDataOfCurrentAchievement, data.data?.ecCreateData.rec_id)
      })
  }


  const confirmDisplay = (
    <>
      <div style={ {
        display: 'flex',
        justifyContent: 'center',
      } }>
        <div style={ {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        } }>
          <img src={ appealCheck } style={ {
            width: '7.5rem',
            alignSelf: 'center',
          } } alt="" />

          <br />

          <h2 className="popup-content-reject__title">Апелляция подана</h2>

        </div>
      </div>

      <br />

      <ButtonSimple
        value="Хорошо"
        variant="full-filled"
        onClickHandler={ onClosePopUp }
      />
    </>
  )
  return (
    isComfirmDisplay ? confirmDisplay : (
      <>
        <h2 className="popup-content-reject__title">Подать апелляцию</h2>

        <InputTextDeprecated
          defaultHint="Текст апелляции"
          value={ textAppeal }
          onChangeValue={ setTextAppeal }
        />

        <br />

        <div>
          <ButtonSimple
            value="Подать апелляцию"
            variant="full-filled"
            onClickHandler={ () => {
              onAddNewEcDataAppealHandler(
                {
                  variables: {
                    dataType: defineAppealDataType(defaultValues.allValues.dataType),
                    data: {
                      ...defaultValues.achievementFieldsOnly,
                      dataType: defaultValues.allValues.dataType,
                      textAppeal: textAppeal.trim(),
                      status: 'appealPending',
                    },
                  },
                },
                defaultValues.allValues
              )
              setIsConfirmDisplay(true)
            } }
            isDisabled={ textAppeal.trim().length <= 0 }
          />
        </div>
      </>
    )
  )
}


export default PopupContentSupplyAppeal

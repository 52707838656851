/* eslint-disable no-console */

import { courseSemesters, semesterItems, } from '@/legacy/components/Projects/NewProject/consts/consts'

export const updateStateArray = (item: any, array: any, setFields: any, fieldName: string, fieldsToUpdate: any) => {
  if (Array.isArray(array)) {
    if (!array.find((arrayItem: { id: any }) => (item.id ? item.id === arrayItem.id : item === arrayItem))) {
      const newArray = [
        ...array,
        item,
      ]
      const updatedFields = {
        ...fieldsToUpdate,
        [fieldName]: newArray,
      }
      setFields(updatedFields)
    }
  } else {
    console.error('Array is undefined or null')
  }
}


export const handleCources = (i: any, setArrayFields: Function, checkboxValue: number) => {
  const updatedFields: { courses: any; semester?: any } = {
    courses: i,
  }
  if (i in courseSemesters) {
    const semesterToSet = checkboxValue % 2 !== 0 ? courseSemesters[i][0] : courseSemesters[i][1]
    updatedFields.semester = semesterToSet
    semesterItems.length = 0
    semesterItems.push(...courseSemesters[i])
  } else {
    updatedFields.semester = null
  }
  setArrayFields(updatedFields)
}


export const handleCheckboxChange = (checkboxValue: number, currentYear:any, nextYear:any, setRangeDate:Function, setCheckboxValue:Function) => {
  type checkboxType = { [key: number]: Date[] };
  const startYear = currentYear
  const endYear = nextYear

  const semesterRanges: checkboxType = {
    1: [
      new Date(startYear - 1, 8, 1),
      new Date(startYear - 1, 11, 31),
    ],
    2: [
      new Date(startYear, 1, 1),
      new Date(startYear, 4, 31),
    ],
    3: [
      new Date(startYear, 8, 1),
      new Date(startYear, 11, 31),
    ],
    4: [
      new Date(endYear, 1, 1),
      new Date(endYear, 4, 31),
    ],
  }

  setRangeDate(semesterRanges[checkboxValue])
  setCheckboxValue(checkboxValue)
}


export const hadlerChecker = (field:string, e:any, setField: Function) => {
  console.log(e)
  console.log(field)
  setField(field, e)
}

export const handleWorkCheck = (i: any, workType: string, setField:Function) => {
  if (i === workType) {
    setField('workType', null)
  } else {
    setField('workType', i)
  }
}


import './CollapseContainer.less'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { IconChevronDown, } from '@tabler/icons'

const CollapseContainer2 = ({ children, styles, }: any) => (
  <div className="collapsed-container" style={styles}>
    {children}
  </div>
)

export const AccordionInnder = ({
  Icon,
  accordionStyle,
  accordionDirection = 'unset',
  headerText,
  subHeaderText,
  isOpened,
  children,
}: any): JSX.Element => (
  <Accordion
    defaultExpanded={isOpened}
    style={{
      borderRadius: '1rem',
      boxShadow:
        '0px 12.521552085876465px 6.636422634124756px 0px rgba(0, 0, 0, 0.015)',
      marginBottom: '.7rem',
      ...accordionStyle,
    }}
  >
    <AccordionSummary
      className="collapsed-container__accordion-summary"
      style={{
        padding: Icon ? '0 2.6rem 0 .5rem' : '1.2rem 2.6rem .8rem 1.9rem',
      }}
      expandIcon={<IconChevronDown />}
    >
      {Icon ? (
        <div className="collapsed-container__icon-wrapper">
          <Icon
            style={{
              height: '3.2rem',
              width: '3.2rem',
              color: '#bfc6e0',
            }}
          />
        </div>
      ) : (
        false
      )}

      <Typography
        style={{
          alignSelf: 'center',
          fontSize: '1.2rem',
          fontWeight: 'bold',
          letterSpacing: 0.35,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span className="collapsed-container__header-text">{headerText}</span>

        <span className="collapsed-container__header-text collapsed-container__header-text_subheader">
          {subHeaderText}
        </span>
      </Typography>
    </AccordionSummary>

    <AccordionDetails
      className="collapsed-container__accordion-details"
      style={{
        padding: Icon ? '0 .6rem 2rem 7rem' : '0 1.9rem 2rem 1.9rem',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: accordionDirection,
      }}
    >
      {children}
    </AccordionDetails>
  </Accordion>
)

export const DefaultContent = ({ children, }: any) => (
  <div className="collapsed-container__default-content">{children}</div>
)

export default CollapseContainer2

import { gql, useMutation, } from '@apollo/client'


export const EDIT_AVHIEVEMENT_TYPE = gql`
  mutation editAchievementType($input: EditAchievementTypeInput) {
    editAchievementType(input: $input)
  }
`

const useCreateAchievementType = () => {
  const [
    create,
  ] = useMutation(EDIT_AVHIEVEMENT_TYPE)

  const onCreateHandler = ({
    direction_key,
    name,
  }: any) => create({
    variables: {
      input: {
        direction_key,
        name,
        points: 0,
        status: 'notavailable',
        jsondata: [],
      },
    },
  })


  return onCreateHandler
}


export default useCreateAchievementType

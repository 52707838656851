import { useMemo, } from 'react'
import styles from './StatisticColumns.module.less'

const DetailStatisticBlock = ({
  style,
  stats,
}: any) => {
  const statColumns = useMemo(() => {
    const views = stats ? stats.views.reduce((previos: any, current: any) => {
      return previos + current.views
    }, 0) : 0

    const signIns = stats ? stats.signs.reduce((previos: any, current: any) => {
      return previos + current.sign_in
    }, 0) : 0

    const signOuts = stats ? stats.signs.reduce((previos: any, current: any) => {
      return previos + current.sign_out
    }, 0) : 0

    return <div className={ styles.wrapper } style={ style }>
      <div className={ styles.column }>
        <p className={ styles.subtitle }>
          Просмотров
        </p>

        <p className={ styles.important }>
          { views }
        </p>
      </div>

      <div className={ styles.column }>
        <p className={ styles.subtitle }>
          Студентов записалось
        </p>

        <p className={ styles.important }>
          { signIns }
        </p>
      </div>

      <div className={ styles.column }>
        <p className={ styles.subtitle }>
          Студентов отписалось
        </p>

        <p className={ styles.important }>
          { signOuts }
        </p>
      </div>
    </div>
  }, [
    stats,
    style,
  ])

  return (
    statColumns
  )
}


export default DetailStatisticBlock

/* eslint-disable no-console */
import React, { useState, useEffect, ChangeEvent, MouseEvent, } from 'react'
import './TaskPopup.less'
import { IconFile, IconCircleX, IconCirclePlus, } from '@tabler/icons'
import clsx from 'clsx'
import { useAddTask, } from '../../../../../gql/hooks/useProjects'
import Persons from '../../../../common/PersonsInput/PersonsInput'
import { DebounceInput, } from 'react-debounce-input'
import { format, } from 'date-fns'
import { IconCalendarMinus, } from '@tabler/icons'
import Calendar from 'react-calendar'
import ru from 'date-fns/locale/ru'
import useUpload from '@/legacy/hooks/useUpload/useUpload'
import { Alert, Snackbar, } from '@mui/material'


function TaskPopup ({
  isOpen,
  handlePopup,
  id,
  project,
}: any) {
  const [
    file,
    setFile,
  ] = useState<File | null>(null)
  const [
    paAddTask,
  ] = useAddTask()
  const [
    currentField,
    setCurrentField,
  ] = useState<string | null>(null)
  const [
    calendar,
    setCalendar,
  ] = useState(false)

  const [
    performer,
    setPerformer,
  ] = useState<string[]>([])
  const [
    performerValue,
    setPerformerValue,
  ] = useState('')

  const [
    responsible,
    setResponsible,
  ] = useState<any>(null)
  const [
    respValue,
    setRespValue,
  ] = useState('')

  const [
    taskName,
    setTaskName,
  ] = useState<string | null>(null)
  const [
    description,
    setDescription,
  ] = useState<string | null>(null)
  const [
    date,
    setDate,
  ] = useState<any>(null)

  const [
    isDisabled,
    setIsDisabled,
  ] = useState(false)

  const {
    isFilesExists,
    setIsFilesExists,
    onCheckFilesHandler,
    onUploadFileHandler,
  } = useUpload()


  const onPreUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files
    const category = `project/${ id.req_id }/${ id.stage_id }/tasks`

    if (fileList) {
      onCheckFilesHandler(fileList, category)
        .then(isUploadAllewed => {
          if (isUploadAllewed) {
            setFile(fileList[0])
          }
        })
    }
  }


  function handleAddTask (event: MouseEvent<HTMLButtonElement>) {
    const category = `project/${ id.req_id }/${ id.stage_id }/tasks`
    event.preventDefault()

    if (file) {
      onUploadFileHandler([
        file,
      ], category)
        .then(files => {
          const params = {
            stage_id: id.stage_id,
            req_id: id.req_id,
            event: 'taskChanged',
            data: {
              date: format(new Date(date), 'yyyy-MM-dd'),
              taskName,
              description,
              responsible,
              performer,
              files,
              resultFiles: [],
              result: '',
            },
          }

          paAddTask({ variables: { params, }, })
            .then(res => {
              handlePopup()
              // @ts-expect-error TS(2531): Object is possibly 'null'.
              document.querySelector('.taskContainer').reset()
              console.log('стадия создана', res)
            })
            .catch(e => console.log('stage err', e))
        })
        .catch(e => {
          console.log('ERROR', e)
        })
    } else {
      const params = {
        stage_id: id.stage_id,
        req_id: id.req_id,
        event: 'taskChanged',
        data: {
          date: format(new Date(date), 'yyyy-MM-dd'),
          taskName,
          description,
          responsible,
          performer,
          resultFiles: [],
          result: '',
        },
      }

      paAddTask({ variables: { params, }, })
        .then(res => {
          handlePopup()
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          document.querySelector('.taskContainer').reset()
          console.log('стадия создана', res)
        })
        .catch(e => console.log('stage err', e))
    }
  }

  function handleCalendar (e: any) {
    e.stopPropagation()
    setCalendar(!calendar)
  }

  useEffect(() => {
    setIsDisabled([
      date,
      taskName,
      description, /* responsible, */
      performer,
    ].some(i => !i))
  }, [
    date,
    taskName,
    description,
    responsible,
    performer,
    file,
  ])


  return (
    <>
      <div className={ clsx('taskPopup', isOpen && 'taskPopAct') } onMouseDown={ handlePopup }>
        <form className="taskContainer" onMouseDown={ e => e.stopPropagation() }>
          <button className="close" onMouseDown={ handlePopup }>x</button>
          <h2>Добавить задачу</h2>
          <input type="text" placeholder='Название задачи' onChange={ e => setTaskName(e?.target?.value) } />
          <textarea placeholder='Описание задачи' onChange={ e => setDescription(e?.target?.value) } />
          { project.data.workType === 'ВКР' && <div className="dateWrap long" >
            <DebounceInput
              onChange={ e => setRespValue(e.target.value) }
              debounceTimeout={ 300 }
              type="text"
              value={ responsible?.full_name }
              placeholder='Ответственный (руководитель/консультант)'
              onClick={ () => setCurrentField('Имя ответственного') }
            />
            { respValue?.length > 3 && (
              <Persons
                field='Имя ответственного'
                mode="person" handler={ (i: any) => setResponsible(i) }
                setCurrentField={ () => setCurrentField('Имя ответственного') }
                currentField={ currentField }
                value={ respValue }
                clear={ setRespValue }
              />
            ) }
          </div> }

          <div className="dateWrap multiple long">
            <span>
              { performer?.map((i: any) => (
                <div className="specTag" key={ i.id }>
                  { i.full_name }

                  <IconCircleX
                    onClick={ () => setPerformer(performer.filter((item: any) => item.full_name !== i.full_name)) } color="#C5C5C5"
                  />
                </div>
              )) }
            </span>

            <DebounceInput
              onChange={ e => setPerformerValue(e.target.value) }
              debounceTimeout={ 300 }
              type="text"
              placeholder='Исполнитель'
              onClick={ () => setCurrentField('исполнитель') }
            />

            { performerValue?.length > 3 && (
              <Persons
                mode="person"
                field="исполнитель"
                handler={ (i: any) => setPerformer([
                  ...performer,
                  i,
                ]) }
                setCurrentField={ () => setCurrentField('исполнитель') }
                currentField={ currentField }
                value={ performerValue }
                clear={ setPerformerValue }
              />
            ) }
          </div>

          { !file ? (
            <div>
              <input
                style={ { display: 'none', } }
                type="file"
                id="taskpopup"
                onChange={ onPreUploadFile }
              />

              <label htmlFor="taskpopup" className="dash noFull"><IconFile />Прикрепить файл<IconCirclePlus color="#7671DD" /></label>
            </div>
          ) : (
            <div className="dash full">
              <IconFile />
              <p className="activity">{ file?.name }<span style={ {
                color: '#C5C5C5',
                marginLeft: '10px',
              } }>{ `${ (file?.size / 1000000).toFixed(2) }Мб` }</span></p>
              <IconCircleX color="#C5C5C5" onClick={ () => setFile(null) } />
            </div>
          ) }


          <div className="dateWrap long" style={ { width: '100%', } }>
            <div onClick={ () => setCalendar(!calendar) }>
              <input type="text" placeholder='Крайняя дата' onClick={ () => setCalendar(!calendar) } disabled value={ date ? format(new Date(date), 'yyyy.MM.dd') : '' } />
            </div>

            <IconCalendarMinus color="#7F8191" onClick={ () => setCalendar(!calendar) } />

            <Calendar
              formatMonthYear={ (locale: any, date: any) => format(date, 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(date, 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(date, 'dd MMMM yyyy', { locale: ru, }).slice(4) }
              locale={ 'Ru' }
              className={ calendar ? 'ScheduleCalendar ' : 'ScheduleCalendar off' }
              onChange={ setDate }
              selectRange={ false }
            />
          </div>

          <button onClick={ handleAddTask } disabled={ isDisabled } className={ clsx(!isDisabled && 'formButton', isDisabled && 'disabled') }>
            Добавить задачу
          </button>
        </form>

        <div className={ clsx('hiddenLayer', calendar && 'hiddenLayer_vis') } onMouseDown={ e => handleCalendar(e) } />
      </div>


      <Snackbar open={ isFilesExists }>
        <Alert onClose={ () => setIsFilesExists(undefined) } severity="error">
          Файл с таким именем уже существует!
        </Alert>
      </Snackbar>
    </>
  )
}

export default TaskPopup

/* eslint-disable no-console */
export const dataAdapterForTable = (dataFromDb: any) => {
  if (dataFromDb?.length) {
    return dataFromDb.map((item: any) => {
      return {
        ...item.data,
        dataType: item.dataType,
        rec_id: item.rec_id,
        user_ids: item.user_ids,
        old_data: item.data,
        creator_id: item.creator_id,
      }
    })
  } return dataFromDb
}


export const clearAchievementFields = (fields: any) => fields.filter((field: any) => !/^number$|^status$|^textAppeal$/.test(field.index))
/**
 * Возврат значения с заданной длиной мантиссы
 * @param value
 * @param count
 */
export const roundingByMantissa = (value: number, count: number) => Math.round(value * Math.pow(10, count)) / Math.pow(10, count)

export const dataWithThresholdValues = ({
  data,
  thresholdValues,
}: any) => {
  const result = data?.map((item: any) => {
    if ('key' in thresholdValues) {

      const { key, value, coefficient, } = thresholdValues
      const newItem = {
        ...item,
        thresholdValue: value,
        // scores: item[key] >= value ? item[key] : 0,
      }

      if ('max_completion_scores' in thresholdValues) {
        newItem.completion_scores =  roundingByMantissa(thresholdValues.max_completion_scores / 100 * newItem[thresholdValues?.key], 0)
      }

      // table_21_2

      if (thresholdValues.key === 'AreasOfTrainingUnclosedCCP') {
        if (Number(item?.AreasOfTrainingUnclosedCCP) <= item?.thresholdValue) {
          newItem.scores = 1
        } else {
          newItem.scores = 0
        }
      }

      // table_22_1

      if (thresholdValues.key === 'AreasOfStudyNoEnrolledStudents') {
        if (Number(item?.AreasOfStudyNoEnrolledStudents) <= value) {
          newItem.scores = 280
        } else {
          newItem.scores = 0
        }
      }

      // table_22_2

      if (thresholdValues.key === 'AverageUSEScoreOfStudents') {
        if (Number(item?.AverageUSEScoreOfStudents) >= value) {
          newItem.scores =roundingByMantissa((400 * Number(item?.AverageUSEScoreOfStudents)) / value,
              0)
        } else {
          newItem.scores = 0
        }
      }

      // table_22_6 расчет баллов

      if (thresholdValues.key === 'proportion') {
        if (Number(item?.proportion) >= item?.thresholdValue) {
          newItem.scores = roundingByMantissa(Number(item.proportion) * 30 / value,
              0)
        } else {
          newItem.scores = 0
        }
      }

      // table_22_7 расчет баллов
      if (thresholdValues.key === 'amount_services') {
        if (Number(item?.amount_services) >= item?.thresholdValue) {
          newItem.scores = roundingByMantissa(coefficient * Number(item.amount_services) / value,
              0)
        } else {
          newItem.scores = 0
        }
      }

      // table_22_8, расчет показателя и баллов
      if (thresholdValues.key === 'calculated_projects') {
        const sumFields = Number(item.amount_projects_50_100) + Number(item.amount_projects_100_500) + Number(item.amount_projects_500_1) + Number(item.amount_projects_over_1)
        newItem.scores =
            (Number(item?.amount_projects_50_100) * coefficient.amount_projects_50_100.value
            + Number(item?.amount_projects_100_500) * coefficient.amount_projects_100_500.value
        + Number(item?.amount_projects_500_1) * coefficient.amount_projects_500_1.value
        + Number(item?.amount_projects_over_1) * coefficient.amount_projects_over_1.value) / value

        newItem.calculated_projects = roundingByMantissa(sumFields, 0)
      }

      // table_22_5
      if (thresholdValues.key === 'calculated_winners') {
        const sumFields = (Number(item?.winners) + Number(item?.winners_olympiad) + Number(item?.winners_olympiad_all_russia))
        newItem.scores = Number(coefficient) * sumFields / value
        newItem.calculated_winners = roundingByMantissa(sumFields, 0)
      }

      // table_22_14
      if (thresholdValues.key === 'calculated_amount_teachers') {
        if (Number(item?.calculated_amount_teachers) >= item?.thresholdValue) {
          newItem.scores = roundingByMantissa(Number(coefficient) * item.calculated_amount_teachers / value, 0)
        } else {
          newItem.scores = 0
        }
      }

      // table_22_15
      if (thresholdValues.key === 'amount_programms') {
        newItem.scores = roundingByMantissa(Number(coefficient) * item.amount_programms / value, 0)
      }

      // table_22_16
      if (thresholdValues.key === 'works') {
        if (data[0].thresholdValue > data[0].works) {
          newItem.scores = 0
        } else {
          newItem.scores = roundingByMantissa(Number(coefficient) * item.works / value, 0)
        }
      }

      // table_22_17
      if (thresholdValues.key === 'works_in_speciality') {
        if (data[0].thresholdValue > data[0].works_in_speciality) {
          newItem.scores = 0
        } else {
          newItem.scores = roundingByMantissa(Number(coefficient) * item.works_in_speciality / value, 0)
        }
      }

      return newItem
    }

    if (!('key' in thresholdValues)) {
      if ('scored_by_indicator' in item) {
        return {
          ...item,
          scores: Number(item.scored_by_indicator),
        }
      }
    }

    return item
  })

  return result
}


export const dataWithThresholdValuesFromBackEnd = (data: any) => {
  const result = data?.map((item: any) => {
    const newItem = {
      ...item,
      thresholdValue: item?.threshold_value ? item?.threshold_value : 0,
      scores: item.scored_by_indicator >= item.threshold_value ? item.scored_by_indicator : 0,
    }

    return newItem
  })

  return result
}


export const addedDefaultValues = (data: any, header: any, dataType: any, user_ids: any) => {
  const newData = {}
  const fields = Object.values(clearAchievementFields(header))

  if (data?.length === 0) {
    fields.forEach((field: any) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newData[field.index] = 0
    })

    return [
      {
        dataType,
        data: {
          ...newData,
        },
        user_ids: [
          user_ids,
        ],
      },
    ]
  }

  return data
}
/**
 * Модифицирует объект, добавляя новое поле, указанное в аргументах функции
 * @param modifiedField
 * @param data
 * @param value
 */
export const modifiedFieldDataTable = (modifiedField: string, data: any, value: any) => {
  if (data) {
    data.forEach((el: any) => {
      // if (!el.hasOwnProperty(modifiedField)) {
      //   return
      // }
      el[modifiedField] = value
    })
    return data
  }
  return data
}
import { useContext, useState, } from 'react'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import { TitleH3, } from '../../../common/GeneralElements/GeneralElements'
import InputSelectDeprecated from '../../../common/InputSelectDeprecated/InputSelectDeprecated'
import PaperContainerWrapper from '../../../common/PaperContainer/PaperContainerWrapper'
import { CreateJsonContext, } from '../PortfolioEditIndicator'
import styles from './AddCriteriaBlock.module.less'


const selectOptions = [
  {
    type: 'text',
    name: 'Текст',
  },
  {
    type: 'select',
    name: 'Список',
  },
  {
    type: 'two-level-select',
    name: '2-х уровневый список',
  },
  {
    type: 'data',
    name: 'Дата',
  },
  {
    type: 'file',
    name: 'Файл',
  },
]

const types = [
  {
    typeList: [
      'select',
    ],
    fields: {
      field: '',
      type: '',
      name: '',
      scores: {
        amount: 0,
        isMainScores: false,
      },
      options: [],
    },
  },
  {
    typeList: [
      'two-level-select',
    ],
    fields: {
      field: '',
      type: '',
      name: '',
      scores: {
        amount: 0,
        isMainScores: false,
      },
      levels: [],
    },
  },
  {
    typeList: [
      'text',
      'data',
      'file',
    ],
    fields: {
      field: '',
      type: '',
      name: '',
      scores: {
        amount: 0,
        isMainScores: false,
      },
    },
  },
]


const AddCriteriaBlock = () => {
  const {
    // @ts-expect-error TS(2339): Property 'addCriteria' does not exist on type 'nev... Remove this comment to see the full error message
    addCriteria,
  } = useContext(CreateJsonContext)
  const [
    choisedOption,
    setChoisedOption,
  ] = useState(null)


  return (
    <PaperContainerWrapper>
      <TitleH3>
        Критерии
      </TitleH3>

      <div className={ styles.inputWrapper }>
        <InputSelectDeprecated
          defaultHint={ 'Новый критерий' }
          items={ selectOptions.map(option => option.name) }
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          callBack={ (name: any) => setChoisedOption(selectOptions.find(option => option.name === name).type) }
        />

        <ButtonSimple
          active
          variant={ 'alone' }
          value={ 'Добавить' }
          onClickHandler={ () => addCriteria(createNewCriteria(choisedOption, types)) }
          isDisabled={ choisedOption === null }
        />
      </div>
    </PaperContainerWrapper>
  )
}


const createNewCriteria = (typeName: any, types: any) => {
  const fields = types.find((type: any) => type.typeList.find((item: any) => item === typeName))?.fields

  fields.type = typeName

  return fields
}


export default AddCriteriaBlock

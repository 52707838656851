export const attachedFiles = [
  {
    name: 'Положение об освоении обучающимся дисциплин по выбору.docx',
    path: './#',
  },
  /* {
    name: 'Разные регламенты.docx',
    path: './#',
  },
  {
    name: 'Важная таблица.xlsx',
    path: './#',
  },
  {
    name: 'Важная таблица.xlsx',
    path: './#',
  },
  {
    name: 'Важная таблица.xlsx',
    path: './#',
  },
  {
    name: 'Важная таблица.xlsx',
    path: './#',
  },
  {
    name: 'Важная таблица.xlsx',
    path: './#',
  },
  {
    name: 'Важная таблица.xlsx',
    path: './#',
  },
  {
    name: 'Важная таблица.xlsx',
    path: './#',
  },
  {
    name: 'Важная таблица.xlsx',
    path: './#',
  },
  {
    name: 'Важная таблица.xlsx',
    path: './#',
  },
  {
    name: 'Важная таблица.xlsx',
    path: './#',
  },
  {
    name: 'Важная таблица.xlsx',
    path: './#',
  },
  {
    name: 'Важная таблица.xlsx',
    path: './#',
  }, */
]

import { gql, useQuery, } from '@apollo/client'


const GET_INSTITUTES = gql`
  query ecInstList {
    catalogs(names: ["ecInstList"])
  }
`


const useGetInstitutes = () => useQuery(GET_INSTITUTES)

/* const GET_INSTITUTES = gql`
query DepList($i: DepartmentListInput) {
  departmentList(input: $i)
}
`;


const useGetInstitutes = () => useQuery(GET_INSTITUTES, {variables: {filter: {
  level: 1
}}}); */

const GET_DEPARTMENT_LIST = gql`
    query DepList($input: DepartmentListInput) {
        departmentList(input: $input)
    }
`
export const useGetDepartmentList = () => useQuery(GET_DEPARTMENT_LIST, {
    variables: {
        input: null,
    },
})
export default useGetInstitutes

import styles from './EditAchievementPopup.module.less'
import ButtonSimple from '@/legacy/components/common/ButtonSimple/ButtonSimple'
import Popup from '@/legacy/components/common/Popup/Popup'
import useGetEcDataTypeFormInputs from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/hooks/useGetEcDataTypeFormInputs'
import { TableCellTypeWithValue, UpdateControlledField, } from '@/legacy/components/EffectiveContract/TableContainer/AddNewAchievement/AddNewAchievement'
import { TableCellType, UUID, } from '@/legacy/types'
import { useState, } from 'react'
import useUpdateEcData from '@/legacy/gql/hooks/effectiveContractHooks/useUpdateEcData'
import { EcAchivementStatus, EcAppealStatus, } from '@/legacy/components/EffectiveContract/constants'


const createNotRequiredAchievementData = (fields: TableCellTypeWithValue[]) => {
  const notRequiredAchievementData: { [key: string]: unknown } = {}

  for (let i = 0; i < fields.length; i++) {
    notRequiredAchievementData[fields[i].index as string] = fields[i].value
  }


  return notRequiredAchievementData
}


interface UseGetAchievementControllerParams {
  tableSource: TableCellType[]
  achievementValues: { [key: string]: unknown }
}


const useGetAchievementController = ({
  tableSource,
  achievementValues,
}: UseGetAchievementControllerParams) => {
  const fieldsWithValues = tableSource.filter(field => !!field.editable).map(field => ({
    ...field,
    value: achievementValues[field.index as string],
  })) as TableCellTypeWithValue[]

  const [
    fields,
    setFields,
  ] = useState(fieldsWithValues)


  const onChangeField: UpdateControlledField = (valueIndex, newValue) => {
    const newFields = [
      ...fields,
    ]
    const targetField = newFields.find(field => field.index === valueIndex)

    if (targetField && 'value' in targetField) {
      targetField.value = newValue
      setFields(newFields)
    }
  }


  return {
    fields,
    onChangeField,
  }
}


interface EditAchievementPopupProps {
  isPopupOpen: boolean
  tableSource: TableCellType[]
  achievementValues: { [key: string]: unknown }
  onClosePopup: Function
}


const EditAchievementPopup = ({
  isPopupOpen,
  tableSource,
  achievementValues,
  onClosePopup,
}: EditAchievementPopupProps) => {
  const { fields, onChangeField, } = useGetAchievementController({
    achievementValues,
    tableSource,
  })


  const inputs = useGetEcDataTypeFormInputs({
    controlledFields: fields,
    onChange: onChangeField,
  })


  const { onUpdateEcDataHandler, } = useUpdateEcData()


  return (
    <Popup
      isOpen={ isPopupOpen }
      handlePopup={ onClosePopup }
    >
      <form className={ styles.form }>
        { inputs }

        <ButtonSimple
          active
          value={ 'Сохранить' }
          onClickHandler={ () => onUpdateEcDataHandler({
            recordId: achievementValues.rec_id as number,
            newData: {
              ...createNotRequiredAchievementData(fields),
              status: achievementValues.status as EcAchivementStatus | EcAppealStatus,
              reason: achievementValues.reason as '',
              scores: achievementValues.scores as number | null,
              isAddedByModer: achievementValues.isAddedByModerts as boolean,
              userId: achievementValues.userId as UUID,
            },
            userIds: achievementValues.user_ids as UUID[],
          }) }
        />
      </form>
    </Popup>
  )
}


export default EditAchievementPopup

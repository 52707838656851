/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useMemo, } from 'react'
import './DormitoryPayment.less'
import NoContracts from '../../NoContracts/NoContracts'
import { useDormitoryContracts, } from '../../../../../gql/hooks/useFinances'
import { IconArrowDownLeft, IconWallet, IconCreditCard, IconArrowUpRight, } from '@tabler/icons'
import clsx from 'clsx'
import HistoryDormitory from './HistoryDormitory/HistoryDormitory'


function DormitoryPayment () {
  const { data, error, loading, } = useDormitoryContracts()
  const [
    activeContract,
    setActiveContract,
  ] = useState<any>()

  console.log('active', activeContract)

  function isActive (i: any) {
    return i === activeContract?.number_contract
  }

  const contractCards = useMemo(() => {
    return data?.dormitoryContracts.contracts.map((i: any) => {
      return i
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
  ])


  useEffect(() => {
    data && setActiveContract(contractCards[0])
  }, [
    contractCards,
    data,
  ])

  // console.log(activeContract?.rental.toString().startsWith('1'))
  // const {startDate, endDate, customer, debt} = activeContract ? activeContract : contractCards[0];

  const payButtons = [
    {
      value: 'Оплатить услуги найма',
      icon: <IconCreditCard style={ { margin: '0 18px 0 0 ', } } />,
    },
    {
      value: 'Оплатить коммунальные услуги',
      icon: <IconWallet style={ { margin: '0 18px 0 0 ', } } />,
    },
  ]

  const infoFields = [
    {
      title: 'Дата начала действия договора',
      value: activeContract?.start_date,
      big: false,
    },
    {
      title: 'Дата окончания действия договора',
      value: activeContract?.end_date,
      big: false,
    },
    {
      mainTitle: 'Тариф',
      title: 'Найм',
      value: `${ activeContract?.price_rental } руб.`,
      big: true,
    },
    {
      mainTitle: ' ',
      title: 'Коммунальные услуги',
      value: `${ activeContract?.price_utilities } руб.`,
      big: true,
    },
    {
      mainTitle: 'Задолженность и переплата',
      title: activeContract?.rental.toString().startsWith('-') ? 'Переплата по найму' : 'Задолженность по найму',
      value: `${ activeContract?.rental.toString().replace('-', '') } руб.`,
      big: true,
      icon: activeContract?.rental.toString().startsWith('-') ? <div className="plus"><IconArrowUpRight color={ 'white' } size={ 15 } /></div> : <div className="debt"><IconArrowDownLeft color={ 'white' } size={ 15 } /></div>,
    },
    {
      mainTitle: ' ',
      title: activeContract?.utilities.toString().startsWith('-') ? 'Переплата по коммунальным услугам' : 'Задолженность по коммунальным услугам',
      value: `${ activeContract?.utilities.toString().replace('-', '') } руб.`,
      big: true,
      icon: activeContract?.utilities.toString().startsWith('-') ? <div className="plus"><IconArrowUpRight color={ 'white' } size={ 15 } /></div> : <div className="debt"><IconArrowDownLeft color={ 'white' } size={ 15 } /></div>,
    },
  ]

  return activeContract ?
    <>
      <div className="paymentFrame">
        <div className="contracts">
          { contractCards?.map((i: any) => {
            const { number_contract, date_contract, /* start_date */ } = i
            return <div key={ number_contract } className={ clsx({
              'contractWrap': true,
              'wrapActive': isActive(number_contract),
            }) } onClick={ () => setActiveContract(i) }>
              <h2>{ `По договору № ${ number_contract } от ${ date_contract }` }</h2>
              <p>{ `Информация актуальна на ${ data?.dormitoryContracts.results_date }` }</p>
            </div>
          }) }
        </div>
        <div className="paymentInfo">
          <h3>Информация</h3>
          <div className="dorGrid" >
            {
              infoFields.map(i => {
                return <div key={ infoFields.indexOf(i) } className="wrap" >
                  { i.mainTitle ? <h4 className="tarif">{ i.mainTitle }</h4> : <></> }
                  <h2>{ i.title }</h2>
                  <div className={ clsx(!i.big && 'value', i.big && 'big') }>{ i.value }{ i.icon ? i.icon : '' }</div>
                </div>
              })
            }
          </div>
        </div>
        {/*  <div className="costil">
          <PaymentButtons buttons={payButtons} />
        </div> */}
      </div>
      <h2 className='dormitoryTable'>История платежей</h2>
      <HistoryDormitory contract={ activeContract?.number_contract } />
    </> : <NoContracts contract={ activeContract } title="Информация о данном виде договоров отсутствует" />
}

export default DormitoryPayment

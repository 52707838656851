import { TableCellType, } from '@/legacy/types'
import { table_cat_2_6, } from '../../popUpInputsCatalogs'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Название учебного заведения',
    index: 'univercityName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Принадлежность учебного заведения',
    index: 'membership',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_6.membership,
  },
  {
    title: 'Формат преподавательской деятельности',
    index: 'format',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_6.format,
  },
  {
    title: 'Документ подтверждающий ведение преподавательской деятельности',
    index: 'document',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Наличие договора о сетевом взаимодействии',
    index: 'network_interaction_agreement',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_6.network_interaction_agreement,
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: false,
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '8rem', },
    editable: false,
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Название учебного заведения',
    index: 'univercityName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Принадлежность учебного заведения',
    index: 'membership',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_6.membership,
  },
  {
    title: 'Формат преподавательской деятельности',
    index: 'format',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_6.format,
  },
  {
    title: 'Документ подтверждающий ведение преподавательской деятельности',
    index: 'document',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Наличие договора о сетевом взаимодействии',
    index: 'network_interaction_agreement',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_6.network_interaction_agreement,
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
    editable: false,
  },
]

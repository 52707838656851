import { numbersOnlyTrimmer, } from '@/legacy/Utils/numbersOnlyTrimmer'
import { ChangeEvent, } from 'react'


const onChangeHandler = (
  event: ChangeEvent<HTMLTextAreaElement>,
  isNumbersOnly: boolean,
  onChangeRowValue: Function,
  cellKey: string
) => {
  let value = event.target.value

  if (isNumbersOnly) {
    value = numbersOnlyTrimmer(value)
  }

  onChangeRowValue(cellKey, value)
}


export default onChangeHandler

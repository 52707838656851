import { DebounceInput, } from 'react-debounce-input'
import './InputTextWithDebounce.less'

const InputTextWithDebounce = ({
  defaultHint,
  value,
  onChange,
  withPlaceholder = false,
  style,
  disabled,
  debounceTimeout = 300,
  minInputLength = 2,
}: any) => {
  return (
    <label className="input-text" style={ style }>
      <div className="input-text__inner">
        <span className="input-text__label-hint">
          { (value || !withPlaceholder) ? defaultHint : false }
        </span>

        <DebounceInput
          value={ value }
          type="text"
          className={ 'input-text__value' }
          minLength={ minInputLength }
          debounceTimeout={ debounceTimeout }
          onChange={ event => onChange(event.target.value) }
          placeholder={ withPlaceholder ? defaultHint : '' }
          disabled={ disabled }
        />
      </div>
    </label>
  )
}

export default InputTextWithDebounce

import { createSlice, } from '@reduxjs/toolkit'


const initialState = {
  selectedTabIndex: 0,
}


export const learningPathwaySlices = createSlice({
  name: 'learningPathway',
  initialState,
  reducers: {
    setSelectedTabIndex: (state, action) => {
      state.selectedTabIndex = action.payload
    },
  },
})


export const {
  setSelectedTabIndex,
} = learningPathwaySlices.actions


export default learningPathwaySlices.reducer

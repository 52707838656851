import { configureStore, } from '@reduxjs/toolkit'
import { combineReducers, } from 'redux'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import stateReconciler from 'redux-persist/lib/stateReconciler/autoMergeLevel1'
import authSlice from './features/auth/auth'
import { authApi, } from './features/auth/authApi'
import { imageApi, } from './utilsApi/imageApi'
import { setupListeners, } from '@reduxjs/toolkit/query'
import subMenuSlice from './SideBarSlices/openSubMenu'
import headerSlice from './HeaderSlices/PopupSlice'
import profileSlice from './ProfileSlices/ProfileSlices'
import formSlice from './FormSlice/FormSlice'
import cropSlice from './ProfileSlices/CropSlice/CropSlice'
import mfcSlice from './MfcSlices/MfcSlice'
import NewsSlice from './NewsSlice/NewsSlices'
import subscriptionsSlice from './Subscriptions/SubscriptionsSlice'
import recordSlice from './RecordBookSlices/RecordBookSlice'
import mainSlice from './MainSlices/MainSlice'
import studentJournal from './JournalSlices/StudentSlice'
import PaymentSlice from './FinancesSlices/PaymentSlice'
import NotificationsSlice from './NotificationsSlice/NotificationsSlice'
import ProjectSlice from './ProjectsSlice/ProjectSlice'
import learningPathwaySlices from './learningPathwaySlices/learningPathwaySlices'
import portfolioSlice from './PortfolioSlices/PortfolioSlice'
import efectiveContractSlices from './efectiveContractSlices/efectiveContractSlices'
import utilsSlice from './utilsSlice'
import MyEducationSlice from './MyEducationSlice'
import HistorySlice from './HistorySlice/HistorySlice'
import FakeChageUserSlice from './FakeChageUserSlice/FakeChageUserSlice'
import moderateNotificationsSlice from './moderateNotificationsSlice/moderateNotificationsSlice'
import LibrarySlice from './LibrarySlice'
import mobileAppVersionSlice from '@/legacy/redux/mobileAppVersionSlice/mobileAppVersionSlice'


const rootReducer = combineReducers({
  auth: authSlice,
  [authApi.reducerPath]: authApi.reducer,
  [imageApi.reducerPath]: imageApi.reducer,
  sub: subMenuSlice,
  head: headerSlice,
  prof: profileSlice,
  form: formSlice,
  crop: cropSlice,
  mfc: mfcSlice,
  news: NewsSlice,
  rec: recordSlice,
  subscriptions: subscriptionsSlice,
  main: mainSlice,
  studJour: studentJournal,
  pay: PaymentSlice,
  notify: NotificationsSlice,
  proj: ProjectSlice,
  pathway: learningPathwaySlices,
  port: portfolioSlice,
  efContract: efectiveContractSlices,
  util: utilsSlice,
  myEd: MyEducationSlice,
  history: HistorySlice,
  fakeChangeUser: FakeChageUserSlice,
  moderateNotifications: moderateNotificationsSlice,
  lib: LibrarySlice,
  mobileAppVersion: mobileAppVersionSlice,
})

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler,
  whitelist: [
    'auth',
    'sub',
    'prof',
    'news',
    'studJour',
    'pay',
    'notify',
    'proj',
    'port',
    'subscriptions',
    'fakeChangeUser',
    'util',
    'mobileAppVersion',
    // 'mfc',
  ],
}

type RootReducer = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootReducer>(persistConfig, rootReducer)


export type RootState = ReturnType<typeof rootReducer>
const store = configureStore({
  devTools: true,
  reducer: persistedReducer,
  middleware: getDM => {
    const middlewares = getDM({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
      },
    }).concat(authApi.middleware, imageApi.middleware)
    return middlewares
  },
})

export const persistor = persistStore(store)

setupListeners(store.dispatch)


export type AppDispatch = typeof store.dispatch

export default store

/**
 * Компонент для отрисовки элемента select.
 *
 * @param title - Является лейблом для списка опций
 * @param value - Выбранный элемент или тот который должен быть вын по умолчанию
 * @param options - Список опций
 * @param onChange - Callback для события onChange для select
 * @param disabled - Блокирует input
 * @returns `JSX element`
 */


import styles from './InputSelect.module.less'
import { IconChevronDown, } from '@tabler/icons'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, } from '@mui/material'
import { StyledEngineProvider, } from '@mui/material/styles'


interface InputSelectProps {
  title: string
  value: string
  options: string[]
  onChange: (event: SelectChangeEvent) => void
  disabled?: boolean
}


const InputSelect = ({
  title,
  value,
  options,
  onChange,
  disabled,
}: InputSelectProps): JSX.Element => {
  return (
    <StyledEngineProvider injectFirst>
      <FormControl
        className={ styles.selectWrapper }
        variant="standard"
      >
        <InputLabel className={ styles.label }>
          { title }
        </InputLabel>


        <Select
          disabled={ disabled }
          IconComponent={ IconChevronDown }
          value={ value }
          onChange={ onChange }
          className={ styles.select }
        >
          { options.map((option, index) => (
            <MenuItem key={ index } value={ option }>
              { option }
            </MenuItem>
          )) }
        </Select>
      </FormControl>
    </StyledEngineProvider>
  )
}


export default InputSelect

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

const STATE_CLS = {
  true: 'backend-state-active',
  false: 'backend-state-inactive',
}

const MESSAGE = 'Ожидание сервиса...'

const createLock = () => {
  const { document, } = window
  const { body, } = document
  const found = document.getElementById('backend-service')
  if (found) {
    return found
  }

  /*
       Можно что-то более красивое, чем просто вот так...
      */
  const css = `
      .${ STATE_CLS.false } {
          position:absolute;
          left:0;
          top:0;
          right:0;
          bottom:0;
          background-color:rgba(0,0,0,.5);
          z-index:9999;
      }
      .${ STATE_CLS.true } {
          position:absolute;
          left:0;
          top:0;
          width:0px;
          height:0px;
          visibility:hidden;
      }
      .backend-message {
          display:flex;
          justify-content:center;
          align-items:center;
      }

      .backend-message-text {
          color:white;
          font-size:1.5em;
          background-color:#272626;
          border-radius:10px;
          padding:20px;
      }
  `
  const head = document.head || document.getElementsByTagName('head')[0]
  const style = document.createElement('style')
  style.appendChild(document.createTextNode(css))
  head.appendChild(style)


  const locked = document.createElement('DIV')
  locked.id = 'backend-service'
  locked.classList.add('backend-message')
  const message = document.createElement('DIV')
  message.classList.add('backend-message-text')
  message.appendChild(document.createTextNode(MESSAGE))
  locked.appendChild(message)
  body.appendChild(locked)
  return locked
}

const token = localStorage.getItem('access_token')

const updateBackendState = (isConnected: any) => {
  const locked = createLock()
  // @ts-expect-error TS(2538): Type 'boolean' cannot be used as an index type.
  locked.classList.remove(STATE_CLS[!isConnected])
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  locked.classList.add(STATE_CLS[isConnected])
}

export {
  updateBackendState
}

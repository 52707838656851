import './PopupContentGetAppealStatus.less'

import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import { useGetEcListData, } from '../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useEffect, useState, } from 'react'
import TextArea from '../../../common/TextArea/TextArea'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'


const PopupContentGetAppealStatus = ({
  onClosePopUp,
  defaultValues,
}: any) => {
  const [
    textAppeal,
    setTextAppeal,
  ] = useState('')
  const [
    appealConclusion,
    setAppealConclusion,
  ] = useState('')
  const getCurrentAppeal = useGetEcListData({
    rec_id: defaultValues.rec_id,
    dataType: defaultValues.dataType as EcTableName,
  })
  useEffect(() => {
    if (getCurrentAppeal?.data) {
      setTextAppeal(getCurrentAppeal?.data?.ecListData[0]?.textAppeal)
      setAppealConclusion(getCurrentAppeal?.data?.ecListData[0]?.appealConclusion)
    }
  }, [
    getCurrentAppeal.loading,
    getCurrentAppeal.data,
  ])


  return (
    <>
      <h2 className="popup-content-reject__title">Ваша апелляция</h2>

      <TextArea
        defaultHint="Текст апелляции"
        value={ textAppeal }
        rows={ 3 }
        isDisabled
      />

      <br />

      <TextArea
        style={ { borderColor: '#FF5E5E', } }
        defaultHint="Модератор:"
        value={ appealConclusion }
        rows={ 3 }
        isDisabled
      />

      <br />

      <div>
        <ButtonSimple
          value="Понятно"
          variant="full-filled"
          onClickHandler={ onClosePopUp }
        />
      </div>
    </>
  )
}


export default PopupContentGetAppealStatus

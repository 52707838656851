import { gql, useSubscription, } from '@apollo/client'
import { useEffect, } from 'react'


const PORTFOLIO_ACHIEVEMENT_DIRECTION_CHANGED = gql`
  subscription AchievementDirectionsChanged {
    achievementDirectionsChanged
  }
`


const useAchievementDirectionChanged = (refetch: any) => {
  const { loading, data, } = useSubscription(PORTFOLIO_ACHIEVEMENT_DIRECTION_CHANGED)

  useEffect(() => {
    if (!loading && data?.achievementDirectionsChanged && refetch) {
      refetch()
    }
  }, [
    loading,
    data?.achievementDirectionsChanged,
    refetch,
  ])
}


export default useAchievementDirectionChanged

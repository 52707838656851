import React, { useMemo, /* useEffect, useState */ } from 'react'
// import { useGetPersons } from '../../../gql/hooks/useProjects';
import FilterPopup from '../../Projects/FilterPopup/FilterPopup'
import { useGetTeacher, } from '../../../gql/hooks/effectiveContractHooks/useGetTeacher'


function TeacherSearch ({
  field,
  handler,
  value,
  currentField,
  setCurrentField,
  clear,
}: any): JSX.Element {
  const pagination = {
    offset: 0,
    limit: 200,
  }

  const { data, error, loading, } = useGetTeacher(value, pagination)

  const persons = useMemo(() => {
    if (error) {
      return <div>.....errrr</div>
    } else if (loading && !data) {
      return <div style={ {
        position: 'absolute',
        bottom: '-20px',
      } }>......loading....</div>
    }
    return (
      <FilterPopup
        type="person"
        onClickAway={ () => setCurrentField(null) }
        isOpen={ currentField === field }
        handler={ handler }
        items={ data?.teacherList?.items }
        clearField={ clear }
      />
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    data,
    loading,
  ])


  return (persons)
}

export default TeacherSearch

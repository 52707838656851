/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconNotes, IconSchool, IconSquareCheck, IconTrophy, } from '@tabler/icons'
import useGetEcListData from '../../../gql/hooks/effectiveContractHooks/useGetEcListData'
import CollapseContainer2, { AccordionInnder, } from '../../common/CollapseContainer2/CollapseContainer2'
import { addedDefaultValues, clearAchievementFields, dataAdapterForTable, dataWithThresholdValues, dataWithThresholdValuesFromBackEnd, } from '../amsUtils/amsUtils'
import CardContainer from '../cards/CardContainer'
import TableContainer from '../TableContainer/TableContainer'
import { tableSource as headerFields_22_9, } from '../constants/tablesFormData/table_22_9'
import { tableSource as headerFields_22_10, } from '../constants/tablesFormData/table_22_10'
import { tableSource as headerFields_22_11, } from '../constants/tablesFormData/table_22_11'
import { tableSource as headerFields_22_12, } from '../constants/tablesFormData/table_22_12'
import useGetEcAmsThresholdValues from '../../../gql/hooks/effectiveContractHooks/useGetEcAmsThresholdValues'
import AppealBlock from '../AppealBlock/AppealBlock'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'


const ScientificActivitiesTab = ({
  contentType,
  moderateId,
  dataTypeUpdated,
  setDataTypeUpdated,
  returnDataForAddNewRow,
  changeStatusHandlers,
  returnDataForDisplayRejectReason,
  userRole,
}: any) => {
  const table_22_9 = useGetEcListData(moderateId, 'table_22_9', dataTypeUpdated, setDataTypeUpdated)
  const table_22_10 = useGetEcListData(moderateId, 'table_22_10', dataTypeUpdated, setDataTypeUpdated)
  const table_22_11 = useGetEcListData(moderateId, 'table_22_11', dataTypeUpdated, setDataTypeUpdated)
  const table_22_12 = useGetEcListData(moderateId, 'table_22_12', dataTypeUpdated, setDataTypeUpdated)

  const thresholdValues = useGetEcAmsThresholdValues()


  return (
    <CollapseContainer2>
      {/*{ /^director-|-admin$|^aup-pfu-moder$/.test(userRole) && (*/}
      {/*  <AccordionInnder*/}
      {/*    Icon={ IconSquareCheck }*/}
      {/*    headerText='Полученные РИДы, прошедшие утверждение на НТС или УМС Университета'*/}
      {/*  // subHeaderText={ scores_1_1 }*/}
      {/*  >*/}
      {/*    { contentType === 'table' && (*/}
      {/*      <>*/}
      {/*        <TableContainer*/}
      {/*          dataType={ EcTableName.TABLE_22_9 }*/}
      {/*          // isEditable={ false }*/}
      {/*          headerFields={ headerFields_22_9 }*/}
      {/*          ecTableData={ dataAdapterForTable(table_22_9_dataAdapter(addedDefaultValues(table_22_9, headerFields_22_9, 'table_22_9', moderateId))) }*/}
      {/*        />*/}

      {/*        { /^director-/.test(userRole) && !!table_22_9?.length && (*/}
      {/*          <AppealBlock*/}
      {/*            data={ table_22_9?.at(-1) }*/}
      {/*          />*/}
      {/*        ) }*/}
      {/*      </>*/}
      {/*    ) }*/}


      {/*    { contentType === 'card' && (*/}
      {/*      <CardContainer cardsData={ table_22_9 } cardFields={ clearAchievementFields(headerFields_22_9) } />*/}
      {/*    ) }*/}
      {/*  </AccordionInnder>*/}
      {/*) }*/}


      { /^director-|-admin$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconNotes }
          headerText='Статьи в изданиях, входящих в Scopus  и Web of Science, 1-4 квартили'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_10 }
                // isEditable={ false }
                headerFields={ headerFields_22_10 }
                ecTableData={
                  dataWithThresholdValuesFromBackEnd(dataAdapterForTable(addedDefaultValues(table_22_10, headerFields_22_10, 'table_22_10', moderateId)))
                }
              />

              { /^director-/.test(userRole) && !!table_22_10?.length && (
                <AppealBlock
                  data={ table_22_10?.at(-1) }
                />
              ) }
            </>
          ) }
        </AccordionInnder>
      ) }


      {/*{ /^director-|-admin$/.test(userRole) && (*/}
      {/*  <AccordionInnder*/}
      {/*    Icon={ IconTrophy }*/}
      {/*    headerText='Научные гранты и проекты Университета, получившие финансовую поддержку'*/}
      {/*  // subHeaderText={ scores_1_1 }*/}
      {/*  >*/}
      {/*    { contentType === 'table' && (*/}
      {/*      <>*/}
      {/*        <TableContainer*/}
      {/*          dataType={ EcTableName.TABLE_22_11 }*/}
      {/*          // isEditable={ false }*/}
      {/*          headerFields={ headerFields_22_11 }*/}
      {/*          ecTableData={*/}
      {/*            dataWithThresholdValues({*/}
      {/*              data: dataAdapterForTable(addedDefaultValues(table_22_11, headerFields_22_11, 'table_22_11', moderateId)),*/}
      {/*              thresholdValues: thresholdValues?.table_22_11,*/}
      {/*            })*/}
      {/*          }*/}
      {/*        />*/}

      {/*        { /^director-/.test(userRole) && !!table_22_11?.length && (*/}
      {/*          <AppealBlock*/}
      {/*            data={ table_22_11?.at(-1) }*/}
      {/*          />*/}
      {/*        ) }*/}
      {/*      </>*/}
      {/*    ) }*/}


      {/*    { contentType === 'card' && (*/}
      {/*      <CardContainer cardsData={ table_22_11 } cardFields={ clearAchievementFields(headerFields_22_11) } />*/}
      {/*    ) }*/}
      {/*  </AccordionInnder>*/}
      {/*) }*/}


      { /^director-|-admin$|^aup-dms-moder$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconSchool }
          headerText='Число научных конференций с международным участием, проведенных Университетом, по которым изданы материалы, индексируемые в международных информационно-аналитических системах научного цитирования Web of Science и Scopus'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_22_12 }
                // isEditable={ false }
                // popUpControllHandler={ changeStatusHandlers?.popUpControllHandler }
                // getRowData={ changeStatusHandlers?.getRowData }
                headerFields={ headerFields_22_12 }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_22_12, headerFields_22_12, 'table_22_12', moderateId)),
                    thresholdValues: thresholdValues?.table_22_12,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_22_12?.length && (
                <AppealBlock
                  data={ table_22_12?.at(-1) }
                />
              ) }
            </>
          ) }


          { contentType === 'card' && (
            <CardContainer cardsData={ table_22_12 } cardFields={ clearAchievementFields(headerFields_22_12) } />
          ) }
        </AccordionInnder>
      ) }
    </CollapseContainer2>
  )
}


const table_22_9_dataAdapter = (tableData: any) => tableData?.map((item: any) => {
  return {
    ...item,
    data: {
      ...item.data,
      scores: item.data.result_intellectual_activity,
    },
  }
})


export default ScientificActivitiesTab

import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  filtStat: 'Образование',
}

export const PaymentSlice = createSlice({
  name: 'pay',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filtStat = action.payload
    },
  },
})

export const { setFilter, } = PaymentSlice.actions
export default PaymentSlice.reducer

import { TableCellType, } from '@/legacy/types'
import { table_cat_2_4, } from '../../popUpInputsCatalogs'


export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Название учебного курса',
    index: 'courseName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Вид учебного курса',
    index: 'courseType',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_4.courseType,
  },
  {
    title: 'Количество авторов',
    index: 'countAuthors',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Ссылка на облако или сторонний ресурс',
    index: 'link',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Выписка заседания УМС университета (ссылка на скан документа)',
    index: 'document1',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'ISBN',
    index: 'isbn',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Свидетельство о регистрации в качестве электронного издания (ссылка на скан документа)',
    index: 'document2',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: false,
  },
  {
    title: 'Статус',
    index: 'status',
    editable: false,
    style: { minWidth: '8rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Название учебного курса',
    index: 'courseName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Вид учебного курса',
    index: 'courseType',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_4.courseType,
  },
  {
    title: 'Количество авторов',
    index: 'countAuthors',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Ссылка на облако или сторонний ресурс',
    index: 'link',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Выписка заседания УМС университета (ссылка на скан документа)',
    index: 'document1',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'ISBN',
    index: 'isbn',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Свидетельство о регистрации в качестве электронного издания (ссылка на скан документа)',
    index: 'document2',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    style: { minWidth: '30rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
    editable: false,
  },
]

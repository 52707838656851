/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, } from 'react'
import { useEffect, } from 'react'
import { useDispatch, } from 'react-redux'
import { useContext, useState, } from 'react'
import { updateNotifications, } from '@/legacy/redux/Subscriptions/SubscriptionsSlice'
import { useSelector, } from 'react-redux'
import {
  updateApplyStatus, updateProjects,
  updateStages, updateTasks, updateComments,
} from '@/legacy/redux/Subscriptions/SubscriptionsSlice'
import { useME, } from '@/legacy/gql/hooks/useME'
import { useStudentInfo, } from '@/legacy/gql/hooks/useStudentInfo'
import { useProjectInitiated, } from '@/legacy/gql/hooks/useProjects'
import { useFacultativeChanged, useTracksChanged, } from '@/legacy/gql/hooks/useMyEducation'
// import {popupClick} from '../../redux/HeaderSlices/PopupSlice';
import { navItems, } from '@/shared/constants/constants'
import { clickSubItem, } from '@/legacy/redux/SideBarSlices/openSubMenu'
import { useCreateProject, } from '@/legacy/gql/hooks/useProjects'
import { useNavigate, useLocation, } from 'react-router-dom'
import {
  updateFaculty, setUpdateProcedure, updateStats, updateTracks,
  updateTrackStudents, updateProjectThemes,
} from '@/legacy/redux/MyEducationSlice'
import { setIsCounting, setTimeLeft, } from '@/legacy/redux/Subscriptions/SubscriptionsSlice'
import { useProjectThemeChanged, } from '@/legacy/gql/hooks/useMyEducation'

import { notification, } from 'antd'
import { IAddNotificationBody, IAddNotificationMeta, IAppContext, } from '@/app/types'
import { InputMaybe, NotificationStatus, } from '@/__generated__/graphql'
import { useAddNotification, useNotificationsSub, useNotificationStats, useUpdateNotification, } from '@/shared/api/notifications'


const defaults = {

}

const AppContext = createContext<Partial<IAppContext>>(defaults)


const useUINotifications = () => {
  const { loading, data, } = useNotificationsSub()
  const dispatch = useDispatch()

  useEffect(() => {
    if (loading) {
      return
    }
    if (data) {
      dispatch(updateNotifications())
      console.log('Notification Changed !', data)
    }
  }, [
    loading,
    data,
    dispatch,
  ])
}

const useUINotificationStats = () => {
  const { data, loading, refetch, } = useNotificationStats()

  const updateNotif = useSelector((state: any) => state.subscriptions.notifChanged)

  useEffect(() => {
    refetch()
  }, [
    updateNotif,
    refetch,
  ])


  const [
    info,
    setInfo,
  ] = useState([])
  useEffect(() => {
    if (loading) {
      return
    }
    setInfo(data?.uiNotificationsStats)
  }, [
    data,
    loading,
  ])
  return info
}

// const redirectUrl = window.location.hostname === 'localhost' ? 'http://localhost:3000' : EndPoints.front;

const AppProvider = ({ children, }: any) => {
  const [
    doAddNotification,
  ] = useAddNotification()
  const [
    doUpdateNotification,
  ] = useUpdateNotification()
  const initialStats = useUINotificationStats()

  useUINotifications()

  useEffect(() => {
  }, [
    initialStats,
  ])

  const addNotification = (source: string,
    targetIds: string[],
    body: IAddNotificationBody,
    meta: IAddNotificationMeta) => {
    doAddNotification({
      variables: {
        i: {
          to: targetIds,
          body: body,
          meta: meta,
          source: source,
        },
      },
    })
  }

  const updateNotification = (message_ids: number[], status: InputMaybe<NotificationStatus>) => {
    doUpdateNotification({
      variables: {
        message_ids,
        status,
      },
    })
  }

  useME()
  useStudentInfo()


  const timeLeft = useSelector((state: any) => state.subscriptions.timeLeft)
  const isCounting = useSelector((state: any) => state.subscriptions.isCounting)
  const dispatch = useDispatch()


  useEffect(() => {
    const time = timeLeft >= 1 ? timeLeft - 1 : 0
    const interval = setInterval(() => {
      isCounting && dispatch(setTimeLeft(time))
    }, 1000)
    if (timeLeft === 0) {
      dispatch(setIsCounting(false))
      dispatch(setTimeLeft(60))
    }
    return () => {
      clearInterval(interval)
    }
  }, [
    isCounting,
    dispatch,
    timeLeft,
  ])


  // const auth = useSelector(state => state.auth);
  // const isLogged = auth?.access_token;

  const [
    paControl,
  ] = useCreateProject()

  function completeProject (id: any) {
    const params = {
      event: 'change',
      payload: {
        req_id: id,
        args: { status: 'completed', },
        action: 'update',
      },
    }
    paControl({ variables: { params, }, })
      .then(res => {
        console.log('ПРоект завершен', res)
      })
      .catch(e => {
        console.log('onCreate error', e)
      })
  }


  const useProjectsApplyEevent = () => {
    const dispatch = useDispatch()
    const { error, loading, data, } = useProjectInitiated()

    useEffect(() => {
      if (loading || error) {
        return
      } else if (!data) {
        return
      } else if (data.projectChanged.event === 'change' || data.projectChanged.event === 'paWipe') {
        dispatch(updateApplyStatus())
      } else if (data.projectChanged.event === 'paApplied' || data.projectChanged.event === 'paWipe' || data.projectChanged.event === 'configChanged') {
        dispatch(updateProjects())
      } else if (data.projectChanged.event === 'stageChanged') {
        dispatch(updateStages())
      } else if (data.projectChanged.event === 'taskChanged') {
        if ((data.projectChanged.stats.length === 1) && (data.projectChanged.stats[0].status === 'completed')) {
          completeProject(data.projectChanged.stats[0].req_id)
        }
        console.log('subTask', data)
        dispatch(updateTasks())
      } else if (data.projectChanged.event === 'commentAdded') {
        dispatch(updateComments())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      loading,
      error,
      data,
    ])
  }

  const useMoEvent = () => {
    const dispatch = useDispatch()
    const { data, error, loading, } = useFacultativeChanged()

    useEffect(() => {
      if (loading || error) {
        return
      } else if (!data) {
        return
      } else if ((data.facultativesChanged.event === 'esCreateFacultative' || data.facultativesChanged.event === 'esDeleteFacultative'
        || data.facultativesChanged.event === 'esSignUpFacultative' || data.facultativesChanged.event === 'esSignOutFacultative'
        || data.facultativesChanged.event === 'esDisciplineActivate')) {
        dispatch(updateFaculty())
      } else if (data.facultativesChanged.event === 'esEditDisciplineProcedure') {
        dispatch(setUpdateProcedure())
      } else if (data.facultativesChanged.event === 'esUpdateStats') {
        dispatch(updateStats())
      } else if (data) {
        console.log('sub', data)
      }
    })
  }

  const useProjectIntroEvent = () => {
    const dispatch = useDispatch()
    const { data, error, loading, } = useProjectThemeChanged()

    useEffect(() => {
      if (loading || error) {
        return
      } else if (!data) {
        return
      } else if (data.projectIntroChanged.event === 'signup' || data.projectIntroChanged.event === 'signout' || data.projectIntroChanged.event === 'themeCreated') {
        dispatch(updateProjectThemes())
      } else if (data) {
        console.log('sub', data)
      }
    })
  }

  const useTrackEvent = () => {
    const { data, error, loading, } = useTracksChanged()

    useEffect(() => {
      if (loading || error) {
        return
      } else if (!data) {
        return
      } if (data.esTracksChanged.event === 'create' || data.esTracksChanged.event === 'track_activate' || data.esTracksChanged.event === 'delete' ||
        data.esTracksChanged.event === 'update' || data.esTracksChanged.event === 'track_signup' ||
        data.esTracksChanged.event === 'track_signout') {
        dispatch(updateTracks())
      } if (data.esTracksChanged.event === 'track_signup' || data.esTracksChanged.event === 'track_signout') {
        dispatch(updateTrackStudents())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      loading,
      error,
      data,
    ])
  }


  useProjectsApplyEevent()
  useMoEvent()
  useTrackEvent()
  useProjectIntroEvent()


  const location = useLocation()
  const navigate = useNavigate()

  function handleActiveItem (arr: any) {
    arr.forEach((i: any) => {
      if (i.items) {
        i.items.forEach((item: any) => {
          if (item.items) {
            handleActiveItem(item.items)
          } else {
            item.path === location.pathname && dispatch(clickSubItem(item.value))
          }
        })
      } else {
        i.path === location.pathname && dispatch(clickSubItem(i.value))
      }
    })
  }

  useEffect(() => {
    handleActiveItem(navItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigate,
    location,
  ])

  const [
    api,
    contextHolder,
  ] = notification.useNotification()

  const openToast = ({ message, }:
    { message: string}) => {
    api.info({
      message,
      placement: 'topRight',
    })
  }

  return (
    <AppContext.Provider value={ {
      addNotification,
      updateNotification,
      useNotificationStats,
      openToast,
    } }>
      { children }
      {contextHolder}
    </AppContext.Provider>
  )
}

const useAppContext = () => useContext(AppContext)

export { useAppContext }

export default AppProvider

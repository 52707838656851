/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useMemo, } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import {
  IconArrowBarToDown,
  IconChevronDown,
  IconChevronUp,
} from '@tabler/icons'
import clsx from 'clsx'
import { format, } from 'date-fns'
import { ru, } from 'date-fns/locale'
import { Col, Row, } from 'antd'
import './EmployeeApplicationsTemplate.less'
import { useLazyGetForm, } from '@/legacy/gql/hooks/mfc/useApplications'
import { Preloader, } from '@/shared'

const EmployeeApplicationsTemplate = ({ content, }: any) => {
  type foolDetailsType = {
    loading: boolean;
    data: any;
  };

  const [
    isOpen,
    setIsOpen,
  ] = useState(false)
  const [
    foolDetails,
    setFoolDetails,
  ] = useState<foolDetailsType>()
  const { statusName, comment, created, id, } = content
  const { statementName, AttachedDocument, TypeOfStatements, } =
    content.data.StatementsDetails
  const details = content.data.StatementsDetails

  const dateCreate =
    format(new Date(created), 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) +
    format(new Date(created), 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() +
    format(new Date(created), 'dd MMMM yyyy', { locale: ru, }).slice(4)
  const timeCreate = format(new Date(created), 'HH:mm')

  const [
    mfcGetForm,
  ] = useLazyGetForm(TypeOfStatements)

  const handleOpenCard = () => {
    setIsOpen(!isOpen)
    mfcGetForm()
      .then((res: any) => {
        setFoolDetails(res)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const waaait: any = useMemo(() => {
    if (!foolDetails?.data) {
      return <Preloader />
    } else if (foolDetails?.data) {
      return foolDetails?.data.mfcGetForm?.fields.map(
        (i: any, index: number) => (
          <div key={ index }>
            <h3>{ i.label }</h3>
            <p>{ details[i.jsonKey] }</p>
          </div>
        )
      )
    }
  }, [
    foolDetails,
    details,
  ])

  return (
    <Accordion
      style={ {
        borderRadius: '15px',
        boxShadow:
          '0px 12.521552085876465px 6.636422634124756px 0px rgba(0, 0, 0, 0.015)',
        marginBottom: '.7rem',
      } }
      className="infoCard"
    >
      <AccordionSummary id="panel1a-header" onClick={ () => handleOpenCard() }>
        <div className="accordionTitle">
          <div className="EmployeApplicationCardHeader">
            <div className="titleWrap">
              <h3>{ statementName }</h3>
              <div className="status">
                <p className={ `stuBut new` }>{ statusName }</p>
              </div>
            </div>
            { isOpen ? (
              <IconChevronUp
                size={ 35 }
                color="#C5C5C5"
                style={ { cursor: 'pointer', } }
              />
            ) : (
              <IconChevronDown
                size={ 35 }
                color="#C5C5C5"
                style={ { cursor: 'pointer', } }
              />
            ) }
          </div>
          <Row>
            <Col span={ 12 }>
              <h4>Дата и время заказа</h4>
              <p className="subtitleRef">{ `${ timeCreate } . ${ dateCreate }` }</p>
            </Col>
            {/* <Col span={ 12 }>
        <h4>Комментарий от модератора</h4>
        <p className="subtitleRef">{ comment ? comment : 'Че делаешь?' }</p>
      </Col> */}
          </Row>
          { AttachedDocument && (
            <div>
              <button>
                Приложенные документы
                <IconArrowBarToDown className={ clsx('download') } />
              </button>
              <ul>
                { AttachedDocument?.map((i: any, index: number) => (
                  <li key={ index }>{ i.name }</li>
                )) }
              </ul>
            </div>
          ) }
        </div>
      </AccordionSummary>
      <AccordionDetails>{ waaait }</AccordionDetails>
    </Accordion>
  )
}

export default EmployeeApplicationsTemplate

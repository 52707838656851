/* eslint-disable no-mixed-operators */
import { TitleH3, } from '../../../../common/GeneralElements/GeneralElements'
import ButtonSimple, { IconComponent, } from '../../../../common/ButtonSimple/ButtonSimple'
import { IconCircleCheck, IconCircleX, } from '@tabler/icons'
import { useState, } from 'react'
import TextArea from '../../../../common/TextArea/TextArea'
import { createInitialState, } from '../../../popupContent/ChangeStatusRecordPopupContent/ChangeStatusRecordPopupContent'
import useCreateForm from '../../../Hooks/useCreateForm/useCreateForm'

import { appealTableSource as headerAppealFields_21_1, } from '../../../constants/tablesFormData/table_21_1'
import { appealTableSource as headerAppealFields_21_2, } from '../../../constants/tablesFormData/table_21_2'

// import { headerAppealFields as headerAppealFields_22_1 } from '../../../TableContainer/tablesData/table_22_1';
// import { headerAppealFields as headerAppealFields_22_2 } from '../../../TableContainer/tablesData/table_22_2';
import { appealTableSource as headerAppealFields_22_3, } from '../../../constants/tablesFormData/table_22_3'
// import { headerAppealFields as headerAppealFields_22_4 } from '../../../TableContainer/tablesData/table_22_4';
import { appealTableSource as headerAppealFields_22_5, } from '../../../constants/tablesFormData/table_22_5'
import { appealTableSource as headerAppealFields_22_6, } from '../../../constants/tablesFormData/table_22_6'
import { appealTableSource as headerAppealFields_22_7, } from '../../../constants/tablesFormData/table_22_7'
import { appealTableSource as headerAppealFields_22_8, } from '../../../constants/tablesFormData/table_22_8'
import { appealTableSource as headerAppealFields_22_9, } from '../../../constants/tablesFormData/table_22_9'
// import { headerAppealFields as headerAppealFields_22_10 } from '../../../TableContainer/tablesData/table_22_10';

import { appealTableSource as headerAppealFields_22_11, } from '../../../constants/tablesFormData/table_22_11'
import { appealTableSource as headerAppealFields_22_12, } from '../../../constants/tablesFormData/table_22_12'
import { appealTableSource as headerAppealFields_22_13, } from '../../../constants/tablesFormData/table_22_13'
import { appealTableSource as headerAppealFields_22_14, } from '../../../constants/tablesFormData/table_22_14'
import { appealTableSource as headerAppealFields_22_15, } from '../../../constants/tablesFormData/table_22_15'
import { appealTableSource as headerAppealFields_22_16, } from '../../../constants/tablesFormData/table_22_16'
import { appealTableSource as headerAppealFields_22_17, } from '../../../constants/tablesFormData/table_22_17'
import { appealTableSource as headerAppealFields_22_18, } from '../../../constants/tablesFormData/table_22_18'

import useGetHandlerForUpdateEcData from '../../../../../gql/hooks/effectiveContractHooks/useGetHandlerForUpdateEcData'
import _ from 'lodash'
import useGetEcAmsThresholdValues from '../../../../../gql/hooks/effectiveContractHooks/useGetEcAmsThresholdValues'

const fields: any = {
  appeal_21_1: headerAppealFields_21_1,
  appeal_21_2: headerAppealFields_21_2,

  // appeal_22_1: headerAppealFields_22_1,
  // appeal_22_2: headerAppealFields_22_2,
  appeal_22_3: headerAppealFields_22_3,
  // appeal_22_4: headerAppealFields_22_4,
  appeal_22_5: headerAppealFields_22_5,
  appeal_22_6: headerAppealFields_22_6,
  appeal_22_7: headerAppealFields_22_7,
  appeal_22_8: headerAppealFields_22_8,
  appeal_22_9: headerAppealFields_22_9,
  // appeal_22_10: headerAppealFields_22_10,
  appeal_22_11: headerAppealFields_22_11,
  appeal_22_12: headerAppealFields_22_12,
  appeal_22_13: headerAppealFields_22_13,
  appeal_22_14: headerAppealFields_22_14,
  appeal_22_15: headerAppealFields_22_15,
  appeal_22_16: headerAppealFields_22_16,
  appeal_22_17: headerAppealFields_22_17,
  appeal_22_18: headerAppealFields_22_18,
}


const clearAppealData = (data: any) => _.omitBy(data, (value: any, key: any) => /^appeal_id$/gi.test(key))

const clearAppealFields = (fields: any) => fields.filter(
  (field: any) => !/^number$|^status$|^scores$|^thresholdValue$/.test(field.index))


const AmsPopUpAppealContent = ({
  data,
  dataType,
  onClosePopUp,
}: any) => {
  const [
    formState,
    setFormState,
  ] = useState(createInitialState(clearAppealFields(fields[dataType]), data))
  const form = useCreateForm(fields[dataType], formState, setFormState, false)

  const [
    rejectAchievement,
    setRejectAchievement,
  ] = useState(false)
  const [
    reason,
    setReason,
  ] = useState('')

  const thresholdValues = useGetEcAmsThresholdValues()
  const { onUpdateHandler, } = useGetHandlerForUpdateEcData()


  return <>
    <TitleH3>
      Рассмотрение апелляции
    </TitleH3>
    <p>Внесите требуемые изменения и подтвердие или отклоните апелляцию</p>
    <p>Комментарий: <i><b>{ data.textAppeal }</b></i></p>
    <br />


    { !rejectAchievement && (
      <>
        { form.fields }
      </>
    ) }


    { rejectAchievement && (
      <>
        <TextArea
          defaultHint='По какой причине вы хотите отклонить заявку?'
          withPlaceholder={ false }
          value={ reason }
          onChangeValue={ setReason }
        />

        <br />

        <div style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }>
          <ButtonSimple
            value="Отменить"
            variant="groupe"
            styles={ {
              marginRight: '1rem',
              backgroundColor: '#F5F6FA',
            } }
            onClickHandler={ () => {
              setRejectAchievement(false)
            } }
          />

          <ButtonSimple
            value={ (
              <IconComponent
                Icon={ IconCircleX }
                iconStyles={ { color: '#FF5E5E', } }
                text="Отклонить"
              />
            ) }
            variant="groupe"
            isDisabled={ reason.split(' ').length < 3 }
            onClickHandler={ () => {
              onUpdateHandler({
                rec_id: data.achievement_id,
                new_data: {
                  ..._.omitBy(data, (value: any, key: any) => /^appeal_id$|^achievement_id$|^scores$|^textAppeal$|^thresholdValue$/gi.test(key)),
                  reason: reason,
                  status: 'rejected',
                },
                new_appeal: {
                  textAppeal: data.textAppeal,
                  status: 'appealRejected',
                  appealConclusion: reason,
                },
              })
                .then(() => {
                  onUpdateHandler({
                    rec_id: data.appeal_id,
                    new_data: {
                      ...clearAppealData(data),
                      appealConclusion: reason,
                      status: 'appealRejected',
                    },
                  })
                })
                .then(() => onClosePopUp())
            } }
          />
        </div>
      </>
    ) }

    { !rejectAchievement && (
      <div style={ {
        display: 'flex',
        justifyContent: 'flex-end',
      } }>
        <ButtonSimple
          value={ (
            <IconComponent
              Icon={ IconCircleX }
              iconStyles={ { color: '#FF5E5E', } }
              text="Отклонить"
            />
          ) }
          variant="groupe"
          styles={ { marginRight: '1rem', } }
          onClickHandler={ () => setRejectAchievement(true) }
        />

        <ButtonSimple
          value={ (
            <IconComponent
              Icon={ IconCircleCheck }
              text="Подтвердить"
            />
          ) }
          variant="groupe"
          active
          onClickHandler={ () => {
            preproce({
              data,
              dataType: dataType?.replace('appeal', 'table'),
              thresholdValues,
              formState,
            })
              .then(params => {
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                onUpdateHandler({ ...params.updateAchievement, })

                return params
              })
              // @ts-expect-error TS(2571): Object is of type 'unknown'.
              .then(params => onUpdateHandler({ ...params.updateAppeal, }))
              .then(() => onClosePopUp())
          } }
        />
      </div>
    ) }
  </>
}


const preproce = ({
  data,
  dataType,
  thresholdValues,
  formState,
}: any) => new Promise(resolve => {
  if (thresholdValues[dataType]) {
    resolve({
      value: thresholdValues[dataType].value,
      coefficient: thresholdValues[dataType].coefficient,
    })
  }
  // @ts-expect-error TS(2345): Argument of type '({ value, coefficient }: { value... Remove this comment to see the full error message
}).then(({ value, coefficient, }) => {
  const formulas = {
    table_22_5: {
      calculated_winners: (Number(coefficient) * (Number(formState?.winners) + Number(formState?.winners_olympiad) + Number(formState?.winners_olympiad_all_russia)) / value),
    },
    table_22_8: {
      calculated_projects: ((Number(coefficient?.amount_projects_500_1?.value) * Number(formState?.amount_projects_50_100)) + (Number(coefficient?.amount_projects_50_100?.value) * Number(formState?.amount_projects_100_500)) + (Number(coefficient?.amount_projects_over_1?.value) * Number(formState?.amount_projects_500_1)) + (Number(coefficient?.amount_projects_100_500?.value) * Number(formState?.amount_projects_over_1)) / value),
    },
    table_22_14: {
      calculated_amount_teachers: (Number(coefficient) * Number(formState?.amount_teachers) / value),
    },
  }


  return {
    updateAchievement: {
      rec_id: data.achievement_id,
      new_data: {
        ..._.omitBy(formState, (value: any, key: any) => /^textAppeal$/gi.test(key)),
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ...formulas[dataType],
        status: 'approved',
      },
      new_appeal: {
        textAppeal: data.textAppeal,
        status: 'appealApproved',
        appealConclusion: 'Ваша апелляция удовлетворена',
      },
    },
    updateAppeal: {
      rec_id: data.appeal_id,
      new_data: {
        ...clearAppealData(data),
        status: 'appealApproved',
      },
    },
  }
})


export default AmsPopUpAppealContent

import InputSelectDeprecated from '../InputSelectDeprecated/InputSelectDeprecated'
import PaginationSimple from '../PaginationSimple/PaginationSimple'
import styles from './PaginationWithAmountRecords.module.less'


const PaginationWithAmountRecords = ({
  offset,
  totalRecords = 1,
  setOffset,
  recordsAmountList = [
    20,
    40,
    60,
    100,
  ],
  limit,
  setNewLimit,
}: any) => {
  return (
    < div className={ styles.wrapper } >
      <PaginationSimple
        className={ styles.pagination }
        page={ Math.floor((offset / limit) + 1) || 1 }
        count={ Math.ceil(totalRecords / limit) }
        onChange={ (event: any, newPage: any) => {
          setOffset((newPage - 1) * limit)
        } }
      />

      <div className={ styles.limitWrapper }>
        <span className={ styles.lable }>
          Записей на странице
        </span>

        <InputSelectDeprecated
          className={ styles.selectInput }
          items={ recordsAmountList }
          defaultValue={ limit }
          callBack={ setNewLimit }
        />
      </div>
    </div >
  )
}


export default PaginationWithAmountRecords

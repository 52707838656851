import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Поле №1',
    index: 'test_1',
    editable: true,
    editType: 'input',
    style: { minWidth: '15rem', },
  },
  {
    title: 'Поле №2',
    index: 'test_2',
    editable: true,
    editType: 'input',
    style: { minWidth: '15rem', },
  },
  {
    title: 'Поле №3',
    index: 'test_3',
    editable: true,
    editType: 'input',
    style: { minWidth: '15rem', },
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '11rem', },
    editable: false,
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    editable: false,
    index: 'number',
  },


  {
    title: 'Поле №1',
    index: 'test_1',
    editable: true,
    editType: 'input',
    style: { minWidth: '15rem', },
  },
  {
    title: 'Поле №2',
    index: 'test_2',
    editable: true,
    editType: 'input',
    style: { minWidth: '15rem', },
  },
  {
    title: 'Поле №3',
    index: 'test_3',
    editable: true,
    editType: 'input',
    style: { minWidth: '15rem', },
  },


  {
    title: 'Причина отказа',
    editable: false,
    index: 'reason',
    style: { minWidth: '20rem', },
  },
  {
    title: 'Комментарий',
    editable: false,
    index: 'textAppeal',
    style: { minWidth: '20rem', },
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '11rem', },
  },
]

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import _ from 'lodash'
import { useMemo, useState, } from 'react'
import { useGetPersons, } from '../../../gql/hooks/useProjects'
import FilterPopup from '../../Projects/FilterPopup/FilterPopup'


function Persons ({
  field,
  handler,
  value,
  currentField,
  setCurrentField,
  clear,
  mode,
  paginatonLimit = 200,
  className,
  setValue
}: any): JSX.Element {
  const [
    previusAmount,
    setPreviusAmount,
  ] = useState([])

  const pagination = {
    offset: previusAmount.length,
    limit: paginatonLimit,
  }

  const { data, error, loading, } = useGetPersons(value, mode, pagination)

  const persons = useMemo(() => {
    if (error) {
      return <div>.....errrr</div>
    } else if (loading && !data) {
      return <div style={ {
        position: 'absolute',
        bottom: '-20px',
      } }>......loading....</div>
    }
    return (
      <FilterPopup
        className={ className }
        type="person"
        onClickAway={ () => {
          if (setCurrentField) {
            setCurrentField(null)
          }
        } }
        isOpen={ currentField === field }
        handler={ handler }
        items={ _.uniqBy(data.persons, 'id') }
        clearField={ clear }
        field={setValue}
      />
    )


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    data,
    loading,
  ])


  return (persons)
}

export default Persons

import { CREATE_NOTIFICATION_DRAFT_FORM_NAMES, } from '../../../redux/moderateNotificationsSlice/moderateNotificationsSlice'

const createNotificationDraftBody = ({
  formTemplate,
}: any) => {
  return {
    type: formTemplate.find((field: any) => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.type).value,
    title: formTemplate.find((field: any) => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.title).value,
    html: formTemplate.find((field: any) => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.text).value,
    files: formTemplate.find((field: any) => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.files).value,
    publish_dates: formTemplate.find((field: any) => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.publishDates).value,
    is_important: formTemplate.find((field: any) => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.isImportant).value,
    target: formTemplate.find((field: any) => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.showFor).value,
    student_filter: formTemplate.find((field: any) => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.studentFilter).value,
    student_list: formTemplate.find((field: any) => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.studentList).value,
    is_active: formTemplate.find((field: any) => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.isActive).value,
  }
}


export default createNotificationDraftBody

import { useEffect, useMemo, } from 'react'
import './RecordBookInfo.less'
import BookData from './BookData/BookData'
import clsx from 'clsx'
import { useSelector, useDispatch, } from 'react-redux'
import { setFilter, } from '../../../redux/RecordBookSlices/RecordBookSlice'
import TableTemplate from './TableTemplate/TableTemplate'
import {
  setFifth,
  setFirst,
  setFourth,
  setSecond,
  setSix,
  setThird,
  setCurrentCourse,
} from '../../../redux/RecordBookSlices/RecordBookSlice'
import ToggleButtons from './ToggleButtons/ToggleButtons'
import { useNavigate, } from 'react-router-dom'
import { useRecordbookDetails, } from '../../../gql/hooks/useRecordbook'
import { Preloader, } from '@/shared'

function RecordBookInfo ({
  recordCard,
}: any) {
  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentCard = useSelector(state => state.rec.currentBook)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const first = useSelector(state => state.rec.firstCourse)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const second = useSelector(state => state.rec.secondCourse)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const third = useSelector(state => state.rec.thirdC)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const fourth = useSelector(state => state.rec.fourthC)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const fifth = useSelector(state => state.rec.fifthCourse)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const six = useSelector(state => state.rec.sixC)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const filtStatus = useSelector(state => state.rec.filterStatus)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentCourse = useSelector(state => state.rec.currentCourse)
  const navigate = useNavigate()

  function isCourse (i: any) {
    return currentCourse === i
  }

  function handleCourse (i: any) {
    dispatch(setCurrentCourse(i))
  }

  const courseButtons = [
    '1 курс',
    '2 курс',
    '3 курс',
    '4 курс',
    '5 курс',
    '6 курс',
  ]

  const { data, error, loading, } = useRecordbookDetails({ recordbook_number: recordCard.recordbook_number, })


  function setTableData () {
    if (currentCourse) {
      if (currentCourse[0] === '1') {
        return first
      } else if (currentCourse[0] === '2') {
        return second
      } else if (currentCourse[0] === '3') {
        return third
      } else if (currentCourse[0] === '4') {
        return fourth
      } else if (currentCourse[0] === '5') {
        return fifth
      } else if (currentCourse[0] === '6') {
        return six
      }
    } else {
      return first
    }
  }

  const tableData = setTableData()

  // простите за этот ад, небыло выхода
  useEffect(() => {
    if (data?.recordbookBySemester.length > 0) {
      dispatch(setFirst(data?.recordbookBySemester.slice(0, 2)))
      if (data?.recordbookBySemester.length > 2) {
        dispatch(setSecond(data?.recordbookBySemester.slice(2, 4)))
        if (data?.recordbookBySemester.length > 4) {
          dispatch(setThird(data?.recordbookBySemester.slice(4, 6)))
          if (data?.recordbookBySemester.length > 6) {
            dispatch(setFourth(data?.recordbookBySemester.slice(6, 8)))
            if (data?.recordbookBySemester.length > 8) {
              dispatch(setFifth(data?.recordbookBySemester.slice(8, 10)))
              if (data?.recordbookBySemester.length > 10) {
                dispatch(setSix(data?.recordbookBySemester.slice(10, 12)))
              }
            }
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    currentCard,
  ])

  const table = useMemo(() => {
    if (error) {
      return <div>...error...</div>
    } else if (loading) {
      return <Preloader />
    } else if (data) {
      return <TableTemplate data={ tableData } />
    }
  }, [
    data,
    error,
    loading,
    tableData,
  ])


  const togg = useMemo(() => {
    return <ToggleButtons />

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filtStatus,
  ])

  useEffect(() => {
    dispatch(setFilter('Учебная деятельность'))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigate,
  ])


  return (
    <>
      <div className="bookContent">
        <BookData data={ recordCard } />
        <div className="buttonsPanel">
          { togg }
          <div className="recCourseButtons">
            { courseButtons.map(i => {
              // @ts-expect-error TS(2365): Operator '<=' cannot be applied to types 'string' ... Remove this comment to see the full error message
              if (i[0] <= Math.round(data?.recordbookBySemester.length / 2)) {
                return <button key={ i } onClick={ () => handleCourse(i) } className={ clsx({
                  'courseBut': true,
                  'recButActive': isCourse(i),
                }) } /* disabled={!disabled} */>{ i }</button>
              } return null
            }) }
          </div>
        </div>
        { table }
      </div>
    </>
  )
}

export default RecordBookInfo

/* eslint-disable no-console */
import React, { useMemo, useState, } from 'react'
import './HistoryPayment.less'
import { Table, } from 'antd'
import { useEducationPaymentsHistory, } from '../../../../gql/hooks/useFinances'
import clsx from 'clsx'
import { IconArrowDownLeft, IconArrowUpRight, } from '@tabler/icons'
import NoContracts from '../NoContracts/NoContracts'
import { format, } from 'date-fns'


function HistoryPayment ({
  contract,
}: any) {
  const [
    histType,
    setHistType,
  ] = useState('График платежей')


  function History () {
    const { data, error, loading, } = useEducationPaymentsHistory(contract?.contract)

    console.log('history', data)

    const historyTable = useMemo(() => {
      const history = data?.educationPaymentsHistory.history
      const columns = [
        {
          title: 'Тип',
          dataIndex: 'type',
          key: 'type',
          width: 200,
        },
        {
          title: 'Дата',
          dataIndex: 'date',
          key: 'date',
          width: 150,
        },
        {
          title: 'Сумма',
          dataIndex: 'summ',
          key: 'summ',
          width: 200,
        },
        {
          title: 'Вид расчетов',
          dataIndex: 'debt',
          key: 'debt',
          width: 300,
        },
        {
          title: 'Способ оплаты',
          dataIndex: 'payType',
          key: 'payType',
          width: 300,
        },

      ]

      /*       console.log(data) */

      const content = history?.map((i: any) => {
        return {
          key: history?.indexOf(i),
          date: i.period,
          summ: `${ Number(i.amount) } руб.`,
          debt: i.type,
          payType: i.method,
          type: i.isCalculation ? <div className="wantiki"><div className="debt"><IconArrowDownLeft color={ 'white' } size={ 15 } /></div><p>Начисление</p></div> : <div className="wantiki"><div className="debt plus"><IconArrowUpRight color={ 'white' } size={ 15 } /></div><p>Оплата</p></div>,
        }
      })

      return content?.length > 0 ? <Table pagination={ false } columns={ columns } dataSource={ content/* ?.reverse() */ } /> : <NoContracts title="Информация отсутствует" />

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      data,
      error,
      loading,
    ])

    return (historyTable)
  }

  function Graphic () {
    const graphicTable = useMemo(() => {
      const columns = [
        {
          title: 'Дата',
          dataIndex: 'date',
          key: 'date',
          width: 180,
        },
        {
          title: 'К оплате',
          dataIndex: 'summ',
          key: 'summ',
          width: 300,
        },
      ]


      console.log('contract - !', contract)

      const content = contract?.paymentSchedule?.graph?.map((paymentItem: any) => {
        return {
          key: contract?.paymentSchedule.graph.indexOf(paymentItem),
          date: (paymentItem.expiresOn && format(new Date(paymentItem.expiresOn), 'dd.MM.yyyy'))
            || (paymentItem.PayDo && format(new Date(paymentItem.PayDo), 'dd.MM.yyyy')),
          summ: `${ Number(paymentItem.amount) || Number(paymentItem.TheAmount) } руб.`,
        }
      })

      return content?.length > 0 ? <Table pagination={ false } columns={ columns } dataSource={ content/* ?.reverse() */ } /> : <NoContracts title="Информация отсутствует" />

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      contract,
    ])

    return (graphicTable)
  }

  const histButtons = [
    'График платежей',
    'История начислений и платежей',
  ]

  return (
    <div className="historyPayment">
      <div className="histButs">
        { histButtons.map(i => {
          return <button className={ clsx({ 'active': histType === i, }) } key={ histButtons.indexOf(i) } onClick={ () => setHistType(i) }>{ i }</button>
        }) }
      </div>
      { histType === 'График платежей' ? <Graphic /> : <History /> }
    </div>
  )
}

export default HistoryPayment

import TableStringCell from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/AchievementDataCell/components/TableStringCell/TableStringCell'
import TableTextArea from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/AchievementDataCell/components/TableTextArea/TableTextArea'
import TableSelect from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/AchievementDataCell/components/TableSelect/TableSelect'
import { AchievementDataCellType, } from '@/legacy/types'
import toFilesArrayAdapter from '@/legacy/Utils/fileToFilesArrayAdapter'
import { isFilesTypeCheck, } from '@/legacy/Utils/isFilesTypeCheck'
import TableAttachedFiles from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/AchievementDataCell/components/TableAttachedFiles/TableAttachedFiles'
import { OnChangeRowValue, } from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableRow/TableRow'
import { format, } from 'date-fns'
import TableCalendar from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/AchievementDataCell/components/TableCalendar/TableCalendar'
import TableInputFile from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/AchievementDataCell/components/TableInputFile/TableInputFile'
import TableSpecsSearch from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/AchievementDataCell/components/TableSpecsSearch/TableSpecsSearch'
import { AchievementMixedParticipant, } from '@/legacy/components/EffectiveContract/types/achievementParticipant'
import TableParticipants from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/AchievementDataCell/components/TableParticipants/TableParticipants'
import TableSpinner from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/TableSpinner/TableSpinner'


interface UseDefineCellContentProps {
  cellKey: string
  componentType: AchievementDataCellType | undefined
  isEditable: boolean
  cellValue: unknown
  isRowEditAvailable: boolean
  onChangeRowValue: OnChangeRowValue
  onUpdateRowHandler: Function
  uploadCategory: string
  cellSelectOptions?: string[]
}


const useDefineCellContent = ({
  cellKey,
  componentType,
  isEditable,
  cellValue,
  isRowEditAvailable,
  onChangeRowValue,
  onUpdateRowHandler,
  uploadCategory,
  cellSelectOptions,
}: UseDefineCellContentProps): JSX.Element => {
  if (cellValue === undefined) {
    if (!isRowEditAvailable) {
      return (
        <td>Нет данных</td>
      )
    }
  }


  if (componentType === 'input') {
    if (isRowEditAvailable && isEditable) {
      return (
        <TableTextArea
          cellKey={ cellKey }
          cellValue={ cellValue as string }
          onChangeRowValue={ onChangeRowValue }
          onUpdateRowHandler={ onUpdateRowHandler }
        />
      )
    } return (
      <TableStringCell cellValue={ cellValue } />
    )
  }


  if (componentType === 'inputNumbers') {
    if (isRowEditAvailable && isEditable) {
      return (
        <TableTextArea
          isNumbersOnly
          cellKey={ cellKey }
          cellValue={ cellValue as string }
          onChangeRowValue={ onChangeRowValue }
          onUpdateRowHandler={ onUpdateRowHandler }
        />
      )
    } return (
      <TableStringCell cellValue={ cellValue } />
    )
  }


  if (componentType === 'select' && cellSelectOptions) {
    if (isRowEditAvailable && isEditable) {
      return (
        <TableSelect
          cellValue={ cellValue as string }
          cellSelectOptions={ cellSelectOptions }
          onChangeRowValue={ onChangeRowValue }
          cellKey={ cellKey }
          onUpdateRowHandler={ onUpdateRowHandler }
        />
      )
    } return (
      <TableStringCell cellValue={ cellValue } />
    )
  }


  if (componentType === 'participants') {
    if (!cellValue) {
      return (
        <TableSpinner />
      )
    }

    const participants = cellValue as AchievementMixedParticipant[]
    const stringLikeCellValue = participants.map(participant => participant.fio).join(', ')

    if (isRowEditAvailable && isEditable) {
      return (
        <TableParticipants
          stringLikeCellValue={ stringLikeCellValue }
          cellValue={ cellValue as AchievementMixedParticipant[] }
          cellKey={ cellKey }
          onChangeRowValue={ onChangeRowValue }
          onUpdateRowHandler={ onUpdateRowHandler }
        />
      )
    } return (
      <TableStringCell cellValue={ stringLikeCellValue } />
    )
  }


  if (componentType === 'inputWithSpecsSearch') {
    if (isRowEditAvailable && isEditable) {
      return (
        <TableSpecsSearch
          cellKey={ cellKey }
          cellValue={ cellValue as string }
          onChangeRowValue={ onChangeRowValue }
          onUpdateRowHandler={ onUpdateRowHandler }
        />
      )
    } return (
      <TableStringCell cellValue={ cellValue } />
    )
  }


  if (componentType === 'calendar') {
    if (isRowEditAvailable && isEditable) {
      return (
        <TableCalendar
          cellKey={ cellKey }
          cellValue={ cellValue as string }
          onUpdateRowHandler={ onUpdateRowHandler }
          onChangeRowValue={ onChangeRowValue }
        />
      )
    } return (
      <TableStringCell cellValue={ format(new Date(String(cellValue)), 'dd.MM.yyyy') } />
    )
  }


  if (componentType === 'upload') {
    const fileList = (isFilesTypeCheck(cellValue) && toFilesArrayAdapter(cellValue)) || []

    if (isRowEditAvailable && isEditable) {
      return (
        <TableInputFile
          uploadCategory={ uploadCategory }
          cellKey={ cellKey }
          onUpdateRowHandler={ onUpdateRowHandler }
          onChangeRowValue={ onChangeRowValue }
          fileList={ fileList }
        />
      )
    } return (
      <TableAttachedFiles fileList={ fileList } />
    )
  }


  return (
    // TODO: Выпилить этот компонент, нужен только для отладки
    <td style={ { color: 'red', } }>
      {/* { String(row[cellIndex]) } */ }
      Unknown Cell Type
    </td>
  )
}


export default useDefineCellContent

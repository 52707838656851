import clsx from 'clsx'
import './CardAchievement.less'

import { ReactComponent as IconCheck, } from './images/IconCheck.svg'
import { ReactComponent as IconX, } from './images/IconX.svg'
import { IconHelp, IconEdit, } from '@tabler/icons'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import { Tooltip, Button, } from '@mui/material'
import { useState, } from 'react'
import useGetCurrentRecord from '../../../../gql/hooks/effectiveContractHooks/useGetCurrentAppeal'
import { useEffect, } from 'react'
import useDeleteEcData from '@/legacy/gql/hooks/effectiveContractHooks/useDeleteEcData'
import ButtonsElement from '@/legacy/components/EffectiveContract/cards/CardAchievement/components/ButtonsElement/ButtonsElement'


export const statuses: any = {
  approved: {
    icon: <IconCheck />,
    text: 'Подтверждено',
  },
  rejected: {
    icon: <IconX />,
    text: 'Отклонено',
  },
  pending: {
    icon: <IconCheck />,
    text: 'Ожидает проверки',
  },
  archived: {
    icon: <IconX />,
    text: 'В архиве',
  },
  expertise: {
    text: 'На экспертизе',
  },
  adjustment: {
    icon: <IconX />,
    text: 'Доработка',
  },
  goneToPrint: {
    text: 'Ушло в печать',
  },
  removed: {
    text: 'Снято',
  },
  manufactured: {
    text: 'Изготовлено',
  },
  сertificateIssued: {
    text: 'Свидетельство информ регистра',
  },
  appealPending: {
    icon: <IconEdit />,
    text: 'Апелляция подана',
  },
  appealApproved: {
    text: 'Апелляция удовлетворена',
  },
  appealRejected: {
    text: 'Апелляция отклонена',
  },

  catalog: [
    {
      key: 'expertise',
      value: 'На экспертизе',
    },
    {
      key: 'adjustment',
      value: 'Доработка',
    },
    {
      key: 'goneToPrint',
      value: 'Ушло в печать',
    },
    {
      key: 'removed',
      value: 'Снято',
    },
    {
      key: 'manufactured',
      value: 'Изготовлено',
    },
    {
      key: 'сertificateIssued',
      value: 'Свидетельство информ регистра',
    },

    {
      key: 'appealPending',
      value: 'Апелляция подана',
    },
    {
      key: 'appealApproved',
      value: 'Апелляция удовлетворена',
    },
    {
      key: 'appealRejected',
      value: 'Апелляция отклонена',
    },
  ],
}

const CardAchievement = ({
  values,
  status,
  description,
  title,
  subtitle,
  date,
  getReasonFailure,
  getAppealStatus,
  onRebaseAchievement,
  onSupplyApeal,
  isEditable = true,
  stage,
  achievementFieldsOnly,
  scores,
  tableSource,
  dataType,
}: any) => {
  const [
    isNotConsider,
  ] = useState(
    !!((
      stage === 'stage-6'
      && values?.status === 'approved'
      && ((
        dataType === 'table_2_3'
        && values.volume < 10
      )
        || (
          dataType === 'table_2_7'
          && values?.quartile?.journal_quartile === 'Без квартиля'
        ))
    ))
  )
  const [
    isAppeal,
  ] = useState(values?.isAppeal)
  const { getAppeal, appealData, } = useGetCurrentRecord(isAppeal, values?.appealLinkId, `appeal_${dataType.slice(6)}`)
  useEffect(() => {
    if (!!isAppeal && !!values?.appealLinkId) {
      getAppeal()
    }
  }, [
    values?.appealLinkId,
    getAppeal,
    isAppeal,
  ])
  const { onDeleteEcDataHandler, } = useDeleteEcData()
  const appealBlock = (
    <div className="card-achievement__appeal-wrapper">
      <div className={ clsx(
        'card-achievement__appeal-table',
        `card-achievement__appeal-table_${ appealData?.status }`
      ) } >
        <IconEdit />
        { appealData && (statuses[appealData?.status].text) }
      </div>

      <IconHelp
        style={ {
          color: '#F9B754',
          cursor: 'pointer',
        } }
        onClick={ () => getAppealStatus({
          ...values,
          dataType: appealData.dataType,
        }) }
      />
    </div>
  )
  // console.log(values)
  return (
    <div className={ clsx({
      'card-achievement': true,
      'card-achievement_not-consider': isNotConsider,
    }) } style={{ width: 350, }}
    >
      { isAppeal && appealBlock }

      <div className='card-achievement__body-wrapper'>
        <div className='card-achievement__header'>
          <StatusGroup status={ status } />

          { status === 'rejected' && (
            <RejectedElement
              getReasonFailure={ getReasonFailure }
              values={ values }
            />
          ) }

          { scores && (status === 'archived' || status === 'approved') && (
            <ArchivedElement scores={ scores } />
          ) }
        </div>

        <div className='card-achievement__description'>
          { description }
        </div>

        <div className='card-achievement__title'>
          { title }
        </div>

        <div className='card-achievement__subtitle'>
          { subtitle }
        </div>


        { stage === 'stage-4' && !isAppeal && (
          <div className='card-achievement__subtitle-button'>
            <ButtonSimple
              value="Подать апелляцию"
              variant="small-button"
              active
              onClickHandler={ () => {
                onSupplyApeal(achievementFieldsOnly,
                  { ...values,
                    dataType, })
              } }
            />
          </div>
        ) }


        { stage === 'stage-6'
          && values?.status === 'approved'
          && (
            (
              dataType === 'table_1_1'
              // && values?.publication_year === new Date().getFullYear() - 1
              && values?.isRebased
            )
            || (
              dataType === 'table_1_4'
              // && values?.publication_year === new Date().getFullYear() - 1
              && values?.isRebased
            )
            || dataType === 'table_2_3'
            || dataType === 'table_2_7'
          )
          && (
            <div className='card-achievement__subtitle-button'>
              <ButtonSimple
                value={
                  (
                    dataType === 'table_2_3'
                    || dataType === 'table_2_7'
                  )
                    ? 'Перенести в базовую часть'
                    : 'Перенести в эффективную часть'
                }
                variant="small-button"
                active
                onClickHandler={ () => onRebaseAchievement({
                  rec_id: values.rec_id,
                  dataType: dataType,
                  achievementData: {
                    rec_id: values.rec_id,
                    current_data: values,
                    user_ids: values.user_ids,
                  },
                }) }
              />
            </div>
          ) }
      </div>

      <div className='card-achievement__footer'>
        <span className='card-achievement__footer-date'>
          { date }
        </span>

        { (values?.status === 'pending' || values?.status === 'expertise')
          && isEditable
          && stage === 'stage-2'
          && (
            <ButtonsElement
              recId={ values.rec_id }
              dataType={ dataType }
              onDeleteEcDataHandler={ onDeleteEcDataHandler }
              achievementValues={ achievementFieldsOnly }
              tableSource={ tableSource }
            />
          ) }

        { (
          stage === 'stage-6'
          && values?.status === 'approved'
          && (
            (
              dataType === 'table_1_1'
              && values.publication_year === new Date().getFullYear() - 1
              && values.isRebased
            )
            || (
              dataType === 'table_1_4'
              && values.publication_year === new Date().getFullYear() - 1
              && values.isRebased
            )
            || dataType === 'table_2_3'
            || dataType === 'table_2_7'
          )
        )
          && (
            <Tooltip title={ tooltipTitle }>
              <Button>
                <IconHelp style={ { color: '#F9B754', } } />
              </Button>
            </Tooltip>
          ) }

      </div>
    </div>
  )
}


interface StatusGroupProps {
  status: string
  style?: object
  isLink?: boolean
  onClickHandler?: Function
  isIconLess?: boolean
}

export const StatusGroup = ({
  status,
  style,
  isLink,
  onClickHandler,
  isIconLess,
}: StatusGroupProps) => (
  <span className='card-achievement__status-group' style={ style }>
    { !isIconLess && (
      <span className={ clsx(
        'card-achievement__icon-status',
        `card-achievement__icon-status_${ status }`
      ) }>
        { status && statuses[status]?.icon }
      </span>
    ) }

    <span
      className={ clsx(
        'card-achievement__text-status',
        `card-achievement__text-status_${ status }`,
        {
          [`card-achievement__text-status_is-link`]: isLink,
        }
      ) }
      onClick={ () => {
        if (isLink) {
          if (onClickHandler) {
            onClickHandler()
          }
        }
      } }
    >
      { status && statuses[status]?.text }
    </span>
  </span>
)


const RejectedElement = ({
  getReasonFailure,
  values,
}: any) => (
  <span
    className={
      clsx(
        'card-achievement__text-status',
        'card-achievement__text-status_clickable'
      )
    }
    onClick={ () => getReasonFailure({
      title: values?.title,
      reason: values?.reason,
    }) }
  >
    Причина отказа
  </span>
)


const ArchivedElement = ({
  scores,
}: any) => (
  <span
    className={
      clsx(
        'card-achievement__text-status',
        'card-achievement__text-status_light'
      )
    }
  >
    { scores } балл(ов)
  </span>
)


const tooltipTitle = (
  <>
    <b>Достижение переносится в случае:</b>
    <ul>
      <li>- издание менее 10 п.л.;</li>
      <li>- количество авторов более 6ти;</li>
      <li>- для полного выполнения базовой части не хватает достижения. В этом случае переносится достижение с баллами, которые при переносе обнуляются;</li>
      <li>- достижение предыдущих лет ошибочно попало в эффективную часть.</li>
    </ul>
  </>
)


export default CardAchievement

import { gql, useQuery, } from '@apollo/client'

const GET_EXPORTED_PROJECTS_ID = gql`
  query {
    createExcelProjects {
      result,
      fileId,
      fileName,
    }
  }
`

export const useGetExportedProjectId = () => {
  const { data, loading, error, refetch, } = useQuery(GET_EXPORTED_PROJECTS_ID)

  return { data,
    loading,
    error,
    refetch, }
}

import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'
import { useSelector, } from 'react-redux'


const STUDENTS_ACHIEVEMENTS_COUNTS = gql`
  query StudentAchievements($input:StudentAchievementStatsInput!) {
    studentAchievementStats(input:$input)
  }
`


const useGetStudentsWithAchievementCount = ({
  offset = 0,
  limit = 20,
}) => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const filtersValues = useSelector(state => state.port.filters.users)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const sort = useSelector(state => state.port.tableSort.users)
  const newFilter: any = {}

  for (const [
    key,
    value,
  ] of Object.entries(filtersValues)) {
    value && (newFilter[key] = value)
  }

  const { data, loading, } = useQuery(STUDENTS_ACHIEVEMENTS_COUNTS, {
    variables: {
      input: {
        filter: {
          full_name: newFilter.full_name,
          institute: newFilter.institute,
          eform: newFilter.education_form,
          elevel: Number(newFilter?.education_level?.length) === 0 ? undefined : newFilter.education_level,
          namespec: newFilter.major,
          course: newFilter.course_number,
          direction: newFilter.direction ? [
            newFilter?.direction,
          ] : undefined,
          type: newFilter.type ? [
            newFilter?.type,
          ] : undefined,
          dateStart: newFilter.dateRange?.from,
          dateEnd: newFilter.dateRange?.to,
        },
        pagination: {
          limit,
          offset,
        },
        sort: [
          {
            column: sort.field,
            order: sort.order,
          },
        ],
      },
    },
  })


  const result = useMemo(() => {
    if (!loading && data) {
      return data.studentAchievementStats
    } return {
      loading: true,
    }
  }, [
    loading,
    data,
  ])


  return result
}


export default useGetStudentsWithAchievementCount


// example:
// {
//   "input": {
//     "filter": {
//       "full_name": "",
//       "institute": "Медицинский институт",
//       "eform": "Очная",
//       "elevel": "Специалист",
//       "namespec": "31.05.03 - Стоматология",
//       "course": 1,
//       "type":["Практика"],
//       "direction": ["Работы обучающегося", "Учебная деятельность"]
//     },
//     "pagination": {
//       "limit": 20,
//         "offset": 0
//     },
//     "sort": [
//       { "column": "userpoints", "order": "asc" }
//     ]
//   }
// }

import styles from './TableContainer.module.less'
import AddNewAchievement from './AddNewAchievement/AddNewAchievement'

import { Scrollbars, } from 'react-custom-scrollbars-2'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import TableHeader from './components/TableHeader/TableHeader'
import TableBody from './components/TableBody/TableBody'
import { TableCellType, TableRowType, } from '@/legacy/types'
import { EcTableName, EC_MODER_GROUPE, } from '@/legacy/components/EffectiveContract/constants'
import { UserRoles, } from '@/shared/constants/userRoles'
import { EC_TEACHER_GROUPE, } from '@/legacy/components/EffectiveContract/constants/effectiveContractTeachers-constants'
import { modifiedFieldDataTable, } from '@/legacy/components/EffectiveContract/amsUtils/amsUtils'
import useUrlSearch from '@/legacy/hooks/useUrlSearch'


const defineCanUserAddNewAchievement = (userRole: UserRoles, dataType: EcTableName): boolean => {
  // TODO: Добавить условие, что среди обычных пользователей в table_1_3 могут добавлять только "Заведующий кафедрой"

  if (EC_TEACHER_GROUPE[userRole]?.canAddNewRecords.some(allowedDataType => allowedDataType === dataType)) {
    return true
  }

  if (EC_MODER_GROUPE[userRole]?.canAddNewRecords.some(allowedDataType => allowedDataType === dataType)) {
    return true
  }

  return false
}


interface TableContainerProps {
  dataType: EcTableName
  headerFields?: TableCellType[]
  ecTableData: TableRowType[]
  isAppealMode?: boolean
}


const TableContainer = ({
  headerFields,
  ecTableData,
  isAppealMode,
  dataType,
}: TableContainerProps) => {
  const userRole = useGetRole()
  const isAddNewAchievementAllowed = defineCanUserAddNewAchievement(userRole, dataType)

  const department = useUrlSearch('department')

  return (
    <>
      <Scrollbars
        hideTracksWhenNotNeeded
        className={ styles.wrapper }
      >
        <table className={ styles.table }>
          <TableHeader headerFields={ headerFields } />
          <TableBody
            headerFields={ headerFields }
            ecTableData={ modifiedFieldDataTable('department', ecTableData, department) }
            dataType={ dataType }
            isAppealMode={ isAppealMode }
          />
        </table>
      </Scrollbars>

      {
        isAddNewAchievementAllowed && !isAppealMode && (
          <AddNewAchievement
            headerFields={ headerFields }
            dataType={ dataType }
          />
        )
      }
    </>
  )
}


export default TableContainer

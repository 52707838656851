import { useState, } from 'react'
import { useDispatch, } from 'react-redux'
import format from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import clsx from 'clsx'
import useRedirect from '@/legacy/hooks/useRedirect'
import { setCurrentAnnounce } from '@/legacy/redux/NewsSlice/NewsSlices'


const BlockAds = ({
  props,
  count,
}: any) => {
  const redirect = useRedirect()

  const [
    active,
    setActive,
  ] = useState(false)

  const dispatch = useDispatch()


  const handleClick = () => {
    dispatch(setCurrentAnnounce(props))
    if (props.description.startsWith('<')) {
      document.location.href = props.urls.linkUrl
    } else {
      redirect('/announce-inner')
    }
  }


  return (
    <div className={ clsx('Block d-cursor', Number.isInteger((count + 1) / 2) && 'even') } onClick={ handleClick } onMouseOver={ () => setActive(true) } onMouseOut={ () => setActive(false) } key={ props.id }>
      <div className="InnerBlock">
        <div className="date">{ props.arr_tags[0] === 'Мероприятия' ? props.eventDate : format(new Date(props.date), 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(new Date(props.date), 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(new Date(props.date), 'dd MMMM yyyy', { locale: ru, }).slice(4) }</div>
        <h1>{ props.title }</h1>
        <div className="desc"/* dangerouslySetInnerHTML={ { __html: props.description } } */>{ props.description.startsWith('<') ? '' : props.description }</div>
        <button className={ `${ active ? 'activeAdsTrue' : 'activeAds' }` } onClick={ handleClick }>
          Подробнее
        </button>
      </div>
    </div>
  )
}

export default BlockAds

import AmsBasicIndicatorsTab from './AmsBasicIndicatorsTab'
import CareerGuidanceWorkTab from './CareerGuidanceWorkTab'
import EducationalActivitiesTab from './EducationalActivitiesTab'
import OtherActivitiesTab from './OtherActivitiesTab'
import ScientificActivitiesTab from './ScientificActivitiesTab'


const tabButtons = [
  {
    text: 'Базовые показатели',
    name: 'amsBasicIndicatorsTab',
    componentLink: AmsBasicIndicatorsTab,
    roles: [
      'director-Institute',
      'director-faculty',
      'aup-admin',
      'aup-pk-moder',
    ],
  },
  {
    text: 'Профориентационная работа',
    name: 'careerGuidanceWorkTab',
    componentLink: CareerGuidanceWorkTab,
    roles: [
      'director-Institute',
      'director-faculty',
      'aup-admin',
      'aup-pk-moder',
    ],
  },
  {
    text: 'Образовательная деятельность',
    name: 'educationalActivitiesTab',
    componentLink: EducationalActivitiesTab,
    roles: [
      'director-Institute',
      'director-faculty',
      'aup-admin',
      'aup-pfu-moder',
    ],
  },
  {
    text: 'Научная деятельность',
    name: 'scientificActivitiesTab',
    componentLink: ScientificActivitiesTab,
    roles: [
      'director-Institute',
      'director-faculty',
      'aup-admin',
      'aup-pfu-moder',
      'aup-dms-moder',
      'aup-ck-moder',
    ],
  },
  {
    text: 'Иные виды деятельности',
    name: 'otherActivitiesTab',
    componentLink: OtherActivitiesTab,
    roles: [
      'director-Institute',
      'director-faculty',
      'aup-admin',
      'aup-dms-moder',
      'aup-ck-moder',
    ],
  },
]


export default tabButtons

import { CreatePayloadParamDataResponses, OnUpdateProjectPayload, ProjectStudentResult, ProjectStudentResults, } from '@/legacy/gql/hooks/projects/useUpdateProjectTypes'
import { FileData, UUID, } from '@/legacy/types'

const createProjectPayloadData = (
  files: FileData[],
  title: string,
  studentResults: ProjectStudentResults,
  projectPayloadData: OnUpdateProjectPayload,
  responderGuid: UUID
) => {
  const newFiles: ProjectStudentResult[] = []

  studentResults.files.forEach((studentResultFile: ProjectStudentResult) => {
    if (studentResultFile.title === title) {
      newFiles.push({
        title: studentResultFile.title,
        file: files,
        whoAdded: studentResultFile.whoAdded,
      })
    } else {
      newFiles.push(studentResultFile)
    }
  })


  const newProjectPayloadData = JSON.parse(JSON.stringify(projectPayloadData))
  const userData = newProjectPayloadData.args.data.responses.find(
    (student: CreatePayloadParamDataResponses) => student.id === responderGuid)

  userData.studentResults.files = newFiles

  return newProjectPayloadData
}


export default createProjectPayloadData

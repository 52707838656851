import './CardNotify.less'
// import { IconTool } from '@tabler/icons';
import Icon from '../../Icon/Icon'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import ru from 'date-fns/locale/ru'
import { useAppContext, } from '@/app/providers/AppProvider'
import { useDispatch, } from 'react-redux'
import AttachedFiles from '../../common/AttachedFiles/AttachedFiles'
import useRedirect from '@/legacy/hooks/useRedirect'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { NotificationStatus, } from '@/__generated__/graphql'
import { setModerPage, setTeacherTab } from '@/legacy/redux/utilsSlice'


function CardNotify ({
  i,
}: any) {
  const redirect = useRedirect()

  const { updateNotification, } = useAppContext()

  const dispatch = useDispatch()

  const role = useGetRole()

  const paths: any = {
    'МФЦ': '/my-references',
  }
  function handleRead () {
    if (i?.status !== 'seen') {
      updateNotification?.([
        i.msg_id,
      ], NotificationStatus.Seen)
    }
    if (!i.meta.path) {
      // есть кейсы когда при броадкастной рассылке мы можем забыть указать путь куда надо редиректить, поэтому на всякий случай будем перенаправлять на текущую страницу (то есть на страницу уведомлений)
      redirect('./')
    } else if (role === 'pa_moder') {
      if (i.body.reqId) {
        redirect(i.meta.path, i.body.reqId)
        dispatch(setModerPage('Проектные заявки'))
      } else {
        redirect(i.meta.path, i.body.message.split(' ')[4])
        dispatch(setModerPage('Проектные заявки'))
      }
    } else if (role === 'worker' || role === 'teacherGPH') {
      /* dispatch(setNotifyId(`${i.body.reqId}`)) */
      if (i.meta.path) {
        dispatch(setTeacherTab(i.body.tab))
        redirect(i.meta.path, i.body.reqId)
      } else {
        redirect(paths[i.source], i.body.reqId)
      }
    } else if (role === 'student' || role === 'student-rakus') {
      if (i.meta.path) {
        redirect(i.meta.path, i.body.reqId)
      } else {
        redirect(paths[i.source], i.body.reqId)
      }
    } else {
      if (i.meta.path) {
        redirect(i.meta.path)
      } else {
        redirect(paths[i.source])
      }
    }
  }

  return (
    <div className="notifyCard" onClick={ handleRead }>
      <Icon color="#C5C5C5" /* size={30} */ name={ i.meta.from } />
      <div>
        <h3 dangerouslySetInnerHTML={ { __html: i.body.message, } } />
        <p>
          { `${ i.source }, ${ dateIsValid(i.created) && (formatDistanceToNowStrict(new Date(i.created), { locale: ru, })) } назад` }

          { i.status === 'new' && (
            <span className="newNotif" style={ {
              color: 'white',
              marginLeft: '20px',
            } }>новое</span>
          ) }
        </p>

        { i?.meta?.files?.length > 0 && (
          <>
            <br />
            <AttachedFiles
              fileList={ i.meta.files }
              isDeleteFilesAllowed={ false }
            />
          </>
        ) }
      </div>
    </div >
  )
}


const dateIsValid = (date: any) => {
  return !Number.isNaN(new Date(date).getTime())
}


export default CardNotify

/**
 * Формирует список всех файлов которые приложены к конкретному достижению.
 *
 * @remarks
 * У достижения может быть несколько полей через которые можно приложить файлы.
 * Важно обеспечить уникальность файлов в рамках всего достижения.
 * Результат выполнения данной функции будет использован на следующем шаге, когда будет проверяться повторение файлов межу списком всех файлов по достижению и новыми файлами, которые прикладывает пользователь.
 *
 * @param arrayWithControlledFields - массив объектов, в котором каждый объект - это значения ячейки в строке таблицы. Это контролируемые store'ом объекты вместе со значениями.
 * @returns Список всех файлов приложенных к достижению.
 */


import { TableCellTypeWithValue, } from '@/legacy/components/EffectiveContract/TableContainer/AddNewAchievement/AddNewAchievement'


const findAllFiles = (arrayWithControlledFields: TableCellTypeWithValue[]) => {
  const fileList: File[] = []

  arrayWithControlledFields.forEach(field => {
    if (field.editType === 'upload') {
      fileList.push(...field.value as File[])
    }
  })

  return fileList
}


export default findAllFiles

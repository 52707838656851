import { EcAchivementStatus, EcAppealStatus, EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { UUID, } from '@/legacy/types'
import { gql, } from '@/__generated__'
import { useMutation, } from '@apollo/client'


export const EC_CREATE_DATA = gql(`
  mutation EcCreateData(
    $dataType: String!,
    $data: JSON!,
    $user_ids: [String],
    $extra: JSON
  ) {
    ecCreateData(
      dataType: $dataType,
      data: $data,
      user_ids: $user_ids,
      extra: $extra
    )
  }
`)


interface RequiredFields {
  status: EcAchivementStatus | EcAppealStatus
  reason: ''
  scores: number | null
  isAddedByModer: boolean
}

interface NewData extends RequiredFields {
  [key: string]: unknown
}

export interface CreateEcData {
  dataType: EcTableName
  newData: NewData | RequiredFields
  user_ids: UUID[] | null
}


const useCreateEcData = () => {
  const [
    create,
  ] = useMutation(EC_CREATE_DATA)

  const onCreateEcDataHandler = ({
    dataType,
    newData,
    user_ids,
  }: CreateEcData) => create({
    variables: {
      dataType,
      data: newData,
      user_ids,
    },
  })

  return { onCreateEcDataHandler, }
}


export default useCreateEcData

import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    editable: false,
    index: 'number',
  },

  {
    title: 'Наименование подразделения',
    index: 'department',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'input',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'От 50 до 100 тыс. руб.',
    index: 'amount_projects_50_100',
    editable: true,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Свыше 100 до 500 тыс. руб.',
    index: 'amount_projects_100_500',
    editable: true,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Свыше 500 до 1 млн. руб.',
    index: 'amount_projects_500_1',
    editable: true,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Свыше 1 млн. руб.',
    index: 'amount_projects_over_1',
    editable: true,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Набрано по показателю',
    index: 'calculated_projects',
    round: '2',
    editable: false,
    indexDescription: 'autoFill',
    editType: 'input'
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Пороговое значение',
    index: 'thresholdValue',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'input'
  },
  {
    title: 'Начислено баллов',
    index: 'scores',
    indexDescription: 'autoFill',
    editable: false,
    ceil: '0',
  },

  {
    title: 'Статус',
    index: 'status',
    editable: false,
    style: { minWidth: '11rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    editable: false,
    index: 'number',
  },

  {
    title: 'Наименование подразделения',
    index: 'department',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'input',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'От 50 до 100 тыс. руб.',
    index: 'amount_projects_50_100',
    editable: true,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Свыше 100 до 500 тыс. руб.',
    index: 'amount_projects_100_500',
    editable: true,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Свыше 500 до 1 млн. руб.',
    index: 'amount_projects_500_1',
    editable: true,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Свыше 1 млн. руб.',
    index: 'amount_projects_over_1',
    editable: true,
    editType: 'inputNumbers',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Набрано по показателю',
    index: 'calculated_projects',
    editable: false,
    round: '2',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Пороговое значение',
    index: 'thresholdValue',
    indexDescription: 'autoFill',
    editable: false,
  },
  {
    title: 'Начислено баллов',
    index: 'scores',
    indexDescription: 'autoFill',
    editable: false,
    ceil: '0',
  },

  {
    editable: false,
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
  },
  {
    title: 'Комментарий',
    editable: false,
    index: 'textAppeal',
    style: { minWidth: '20rem', },
  },
  {
    title: 'Статус',
    editable: false,
    index: 'status',
    style: { minWidth: '11rem', },
  },
]

import { useContext, } from 'react'
import InputCheckbox from '../../../../../common/InputCheckbox/InputCheckbox'
import { CreateJsonContext, } from '../../../PortfolioEditIndicator'
import CriteriaInputs from '../CriteriaInputs/CriteriaInputs'

const PrimitiveCriteria = ({
  name,
  scores,
  field,
  detached,
}: any) => {
  const {
    // @ts-expect-error TS(2339): Property 'onChangeInputValue' does not exist on ty... Remove this comment to see the full error message
    onChangeInputValue,
  } = useContext(CreateJsonContext)


  return <>
    <CriteriaInputs
      name={ name }
      scores={ scores }
      field={ field }
      onChangeInputValue={ onChangeInputValue }
    />
    <br />

    <InputCheckbox
      label="Добавить в печатную форму как отдельное достижение"
      checked={ !!detached }
      onChange={ (event: any, isChecked: any) => onChangeInputValue({
        field,
        subfield: 'detached',
        newValue: isChecked,
      }) }
    />
  </>
}


export default PrimitiveCriteria

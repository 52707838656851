import { gql, } from '@/__generated__'
import { gql as apolloGql, useSubscription, } from '@apollo/client'


/* SUBSCRIPTION: */
export const EC_CHANGES = gql(`
  subscription OnContractChange {
    contractsChanged
  }
`)

const EXPORT_SUBSCRIPTION = apolloGql(`
  subscription {
    ecExportsProgress
  }
`)

/** @deprecated */
export const useEcSubscription = () => useSubscription(EC_CHANGES)

export const useEcExportSubscription = () => useSubscription(EXPORT_SUBSCRIPTION)

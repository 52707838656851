import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'
import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/app/providers/AppProvider'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import { defineUserGroupe, moderGroupe_4_1, } from '../../../defineUserGroupe'

const Inputs_4_1 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, } = useAppContext()


  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserType = useSelector(state => state.auth.userType)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)

  const [
    user_ids,
  ] = useState(
    moderGroupe_4_1.some(moderRole => (moderRole === currentRole || moderRole === currentUserType))
      ? moderateId
      : currentUserId
  )


  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification?.(
          'Эффективный контракт',
          (
            moderGroupe_4_1.some(moderRole => (moderRole === currentRole || moderRole === currentUserType))
              ? moderateId
              : currentUserId
          ),
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (id: any, newData: any) => onUpdateRow({
    variables: {
      rec_id: id,
      new_data: newData,
    },
  })


  const [
    basis,
    setBasis,
  ] = useState('')
  const [
    scores,
    setScores,
  ] = useState('')


  useEffect(() => {
    if (hint === 'edit') {
      setBasis(defaultValues.basis)
      setScores(defaultValues.scores)
    }
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    setBasis('')
    setScores('')
  }


  return (
    <>
      <InputTextDeprecated
        defaultHint="Основание"
        value={ basis }
        onChangeValue={ setBasis }
      />

      <InputTextDeprecated
        defaultHint="Баллы"
        value={ scores }
        onChangeValue={ setScores }
        numbersOnly
      />

      <br />

      <div style={ {
        display: 'flex',
        justifyContent: 'flex-end',
      } }>
        <ButtonSimple
          value="Отмена"
          variant="groupe"
          styles={ { marginRight: '1rem', } }
          onClickHandler={ () => {
            fieldsToDefault()
            onClickHandler()
          } }
        />

        <ButtonSimple
          isDisabled={ (
            basis.trim().length < 1
            || scores.trim().length < 1
          ) }
          active
          value={ hint === 'edit' ? 'Сохранить изменения' : 'Подтвердить' }
          variant="groupe"
          onClickHandler={ () => {
            if (hint === 'edit') {
              onUpdateRowHandler(defaultValues.rec_id, {
                basis: basis,
                scores: scores,
                status: 'approved',
              })

              fieldsToDefault()
              onClickHandler()
            } else {
              onAddNewEcDataHandler({
                variables: {
                  user_ids: [
                    user_ids,
                  ],
                  dataType: 'table_4_1',
                  data: {
                    basis: basis,
                    scores: scores,
                    status: 'approved',
                    isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
                  },
                },
              })

              fieldsToDefault()
              onClickHandler()
            };
          } }
        />


      </div>
    </>
  )
}


export default Inputs_4_1

import { gql, useMutation, } from '@apollo/client'


export const EDIT_AVHIEVEMENT_TYPE = gql`
  mutation changeTypeDirection($input: ChangeTypeDirectionInput) {
    changeTypeDirection(input: $input)
  }
`

const useChangeTypeDirection = () => {
  const [
    create,
  ] = useMutation(EDIT_AVHIEVEMENT_TYPE)

  const onCreateHandler = ({
    key,
    direction_key,
    new_direction_key,
  }: any) => create({
    variables: {
      input: {
        key,
        direction_key,
        new_direction_key,
      },
    },
  })


  return onCreateHandler
}


export default useChangeTypeDirection

import React from 'react'
import { Col, } from 'antd'

function Column ({
  space,
  value,
  title,
}: any) {
  return (
    <Col span={ space }>
      <div className="infoItemWrap">
        <h4 className="infoItemTitle">{ title }</h4>
        <p className="infoItemValue">{ value ? value : '--' }</p>
      </div>
    </Col>
  )
}

export default Column

import './DetailPage.less'
import { NewPageWrapper, } from '../../../../common/GeneralElements/GeneralElements'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import { useLocation, } from 'react-router-dom'
import FacultativeDetailPage from './FacultativeDetailPage'
import TrackDetailPage from './TrackDetailPage'
import ElectiveDetailPage from './ElectiveDetailPage'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'


const DetailPage = () => {
  const role = useGetRole()

  const location = useLocation()
  const type = new URLSearchParams(location.search).get('type')
  const name = new URLSearchParams(location.search).get('name')
  const id = new URLSearchParams(location.search).get('id')


  const detailPages: any = {
    // @ts-expect-error TS(2786): 'FacultativeDetailPage' cannot be used as a JSX co... Remove this comment to see the full error message
    Факультатив: <FacultativeDetailPage id={ id } type={ type } role={ role } />,
    // @ts-expect-error TS(2786): 'TrackDetailPage' cannot be used as a JSX componen... Remove this comment to see the full error message
    Трек: <TrackDetailPage type={ type } role={ role } id={ id } />,
    // @ts-expect-error TS(2786): 'ElectiveDetailPage' cannot be used as a JSX compo... Remove this comment to see the full error message
    Электив: <ElectiveDetailPage type={ type } role={ role } id={ id } />,
  }


  return (
    <NewPageWrapper className='detail-page'>
      <div className='detail-page__history-links-wrapper'>
        <HistoryLinks
          links={
            [
              {
                value: 'Главная',
                path: '/',
              },
              {
                value: 'Высшее образование',
                path: '/high-education',
              },
              {
                value: 'Выбор дисциплин и факультативов',
                path: '/learning-pathway',
              },
            ]
          }
          loc={ name }
        />
      </div>

      { type !== null && detailPages[type] }
    </NewPageWrapper>
  )
}


export default DetailPage

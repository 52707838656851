import { useState, } from 'react'
import './ChooseField.less'
import clsx from 'clsx'
import {
  IconChevronDown,
  IconChevronUp,
} from '@tabler/icons'
import FilterPopup from '../../FilterPopup/FilterPopup'


function ChooseField ({
  title,
  items,
  styles,
  handler,
  value,
  type,
  isDisabled,
}: any) {
  const [
    isOpen,
    setIsOpen,
  ] = useState(false)


  return (
    <button
      className={ clsx('chooseField' /*  isOpen && "chooseActive" */) }
      style={ styles }
      onClick={ () => !isDisabled && setIsOpen(!isOpen) }
      onBlur={ () => setIsOpen(false) }
    >
      <FilterPopup
        items={ items }
        isOpen={ isOpen }
        handler={ handler }
        itemType={ type }
      />

      <h4 className={ clsx({
        'fieldSubtitle': true,
        'fieldSubtitle_selected': value,
      }) }
      >
        { value ? value : title }
      </h4>

      { !isOpen ? <IconChevronDown /> : <IconChevronUp /> }
    </button>
  )
}


export default ChooseField

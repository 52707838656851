import { useMutation, } from '@apollo/client'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { LOG_FRONTEND, } from './useSystem'

const useLogFrontendErrors = () => {
  const location = useLocation()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const userName = useSelector(state => state?.auth?.me?.data?.full_name)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const userGuid = useSelector(state => state?.auth?.me?.data?.guid)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const userGroups = useSelector(state => state?.auth?.me?.groups)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studentInfo = useSelector(state => state?.prof?.studentInfo)

  const [
    sendInfo,
  ] = useMutation(LOG_FRONTEND)

  const onErrorFrontend = (tag = 'error', data = { message: 'неизвестная ошибка', }) => {
    // tag === 'error' | 'runTimeError' - летят в бот

    sendInfo({
      variables: {
        input: {
          tag,
          data: {
            userName,
            userGuid,
            userGroups,
            studentInfo,
            errorType: tag,
            pathname: location.pathname,
            data: {
              ...data,
            },
          },
        },
      },
    })
  }

  return onErrorFrontend
}


export default useLogFrontendErrors

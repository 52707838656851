/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useState, } from 'react'
import '../Card/Card.less'
import Icon from '../../../../Icon/Icon'


const NotifyCard = ({
  i,
  links,
}: any) => {
  const [
    active,
    setActive,
  ] = useState(false)


  return (
    <div
      className="Card d-cursor cardNot"
      onMouseOut={ () => setActive(false) }
      onMouseOver={ () => setActive(true) }
      style={ { backgroundColor: i.backgroundColor, } }
    >
      <div style={ {
        display: 'flex',
        width: '100%',
      } }>
        <div>
          <Icon name={ i.iconName } color={ i.iconColor } />
          <div className="C-h2 d-row d-center title">
            { i.title }
          </div>
        </div>
        <ul className="RecordLinks">
          { links.map((link: any, index: any) => <li key={ index } onClick={ () => window.open(`${ link }?view=true`, '_blank') }>{ i.file[index].title }&#8194;{ '->' }</li>) }
        </ul>
      </div>
    </div>
  )
}

export default NotifyCard

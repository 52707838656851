/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './CardDisciplineDetail.less'
import LinkButton from '../../../../common/LinkButton/LinkButton'
import { Link, } from 'react-router-dom'

const CardDisciplineDetail = ({
  id,
  name,
  type,
  active = false,
  moderMode = false,
  disciplineId,
  trackName,
}: any) => (
  <div className='card-discipline-detail'>
    <div>
      <div className='card-discipline-detail__title-wrapper'>
        <div className='card-discipline-detail__name'>
          { name }
        </div>
      </div>

      <div className='card-discipline-detail__type'>
        { type }
      </div>
    </div>

    { moderMode ? (
      <Link to={ `./edit-discipline?type=${ type }&id=${ id }&discipline_id=${ disciplineId }&name=${ name }&trackName=${ trackName }` }>
        <LinkButton type="edit" />
      </Link>
    ) : false }
  </div>
)


export default CardDisciplineDetail

/**
 * Проверяет что входящие данные являются валидным массивом файлов
 *
 * @remarks
 * Пустой массив так же является валидным
 */


const isFilesArrayCheck = (inputData: File[] | unknown): inputData is File[] => {
  if (
    Array.isArray(inputData)
    && (
      (inputData[0] && 'size' in inputData[0])
      || inputData.length === 0
    )
  ) {
    return true
  } return false
}


export default isFilesArrayCheck

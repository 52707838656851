import { gql, useMutation, } from '@apollo/client'


const DELETE_NOTIFICATION_DRAFT = gql`
  mutation NotificationDraftDelete($draft_id: Int!) {
    notificationDraftDelete(draft_id: $draft_id)
  }
`

const useDeleteNotificationDraft = () => {
  const [
    onDelete,
  ] = useMutation(DELETE_NOTIFICATION_DRAFT)

  const onDeleteNotificationDraft = ({
    draft_id,
  }: any) => onDelete({
    variables: {
      draft_id,
    },
  })

  return { onDeleteNotificationDraft, }
}


export default useDeleteNotificationDraft

import { useMemo, } from 'react'
import { useGetPersons, } from '../../../../../../gql/hooks/useProjects'
import AddStudentItem from './AddStudentItem/AddStudentItem'


const StudentsList = ({
  student,
  setNewStudents,
  newStudents,
}: any) => {
  const pagination = {
    offset: 0,
    limit: 200,
  }

  const { data, error, loading, } = useGetPersons(student, 'student', pagination)


  const studentsAll = useMemo(() => {
    if (error) {
      return <div>...произошла ошибка</div>
    }
    if (loading) {
      return <div>....loading...</div>
    } else if (data) {
      const withoutDoubles: any = []
      data.persons.reduce((previos: any, current: any) => {
        (current.id !== previos.id) && withoutDoubles.push(current)
        return current
      }, { id: 'sgerh', })

      // @ts-expect-error TS(7006): Parameter 'i' implicitly has an 'any' type.
      return withoutDoubles.map(i => <AddStudentItem key={ i.id } i={ i } setNewStudents={ setNewStudents } newStudents={ newStudents } />)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
    newStudents,
  ])

  return studentsAll
}

export default StudentsList

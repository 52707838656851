import React, { useEffect, useState, } from 'react'
import useDownload from '@/shared/api/hooks/useDownload/useDownload'
import TableSpinner from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/TableSpinner/TableSpinner'
import { useGetExportedCourseworks } from '@/features/portfolio/api/hooks/useGetExportedCourseworks'


const FileDownloader = () => {
  const { data, loading, error, } = useGetExportedCourseworks()
  const [
    fileId,
    setFiledId,
  ] = useState<string | null>(null)

  const { downloadUrl, errorMessage, loading: downloadLoading, } = useDownload(fileId)
  useEffect(() => {
    if (data?.exportCourseworks .fileId) {
      setFiledId(data.exportCourseworks.fileId)
    }
  }, [
    data,
  ])

  useEffect(() => {
    if (downloadUrl) {
      window.location.href = downloadUrl
    }
  }, [
    downloadUrl,
  ])

  return (
    <>
      {(loading || downloadLoading) && <TableSpinner />}
      {error && <p>Error: {error.message}</p>}
      {errorMessage && <p>Error generating download URL: {errorMessage}</p>}
    </>
  )
}

export default FileDownloader

import useUpdateProject from "@/legacy/gql/hooks/projects/useUpdateProject";
import { useDeleteUpload } from "@/legacy/gql/hooks/useAvatars";
import { downloadFile } from "@/legacy/hooks/useDownloadFile";
import useGetUserInfo from "@/legacy/hooks/useGetUserInfo";
import { IconCirclePlus } from "@tabler/icons";
import clsx from "clsx";
import { useMemo, useState } from "react";
import StudentResultItem from "@/legacy/components/Projects/ProjectPage/InfoProject/components/AddStudentResultInput/components/StudentResultItem/StudentResultItem";
import onAddResultHandler from "@/legacy/components/Projects/ProjectPage/InfoProject/components/AddStudentResultInput/handlers/onAddResultHandler";
import {
  OnUpdateProjectPayload,
  ProjectStudentResult,
  ProjectStudentResults,
} from "@/legacy/gql/hooks/projects/useUpdateProjectTypes";
import { FileData, UUID } from "@/legacy/types";
import onDeleteResultHandler from "@/legacy/components/Projects/ProjectPage/InfoProject/components/AddStudentResultInput/handlers/onDeleteResultHandler";
import defineToShowDeleteButton from "@/legacy/components/Projects/ProjectPage/InfoProject/components/AddStudentResultInput/utils/defineToShowDeleteButton";
import defineToShowAttacheFileLable from "@/legacy/components/Projects/ProjectPage/InfoProject/components/AddStudentResultInput/utils/defineToShowAttacheFileLable";
import createProjectPayloadData from "@/legacy/components/Projects/ProjectPage/InfoProject/components/AddStudentResultInput/utils/createProjectPayloadData";

export enum projectResponderType {
  STUDENT = "student",
  SUPERVISOR = "sepervisor",
}

const MAX_TITLE_LENGTH = 256;

const truncateTitle = (title: string) => {
  return title.length > MAX_TITLE_LENGTH
    ? `${title.slice(0, MAX_TITLE_LENGTH)}...`
    : title;
};
export interface OnUpdateProjectHandler {
  (files: FileData[], title: string): void;
}

interface AddStudentResultInputProps {
  projectId: number;
  studentResults: ProjectStudentResults | undefined;
  projectPayloadData: OnUpdateProjectPayload;
  responderGuid: UUID;
}

const AddStudentResultInput = ({
  projectId,
  studentResults,
  projectPayloadData,
  responderGuid,
}: AddStudentResultInputProps) => {
  const { userGuid: currentUserGuid } = useGetUserInfo();

  const [report, setReport] = useState<string>("");

  const { onUpdateProject } = useUpdateProject();

  const [deleteUpload] = useDeleteUpload();

  const docTypes = useMemo(() => {
    const onUpdateProjectHandler: OnUpdateProjectHandler = (
      files: FileData[],
      title: string
    ) => {
      if (studentResults) {
        onUpdateProject(
          createProjectPayloadData(
            files,
            title,
            studentResults,
            projectPayloadData,
            responderGuid
          )
        );
      }
    };

    return (
      <ul className="resultsList">
        {studentResults?.files
          ?.filter(
            (studentResult: ProjectStudentResult) =>
              studentResult.file ||
              (!studentResult.file &&
                currentUserGuid === studentResult.whoAdded)
          )
          .map((studentResult: ProjectStudentResult, index: number) => {
            const isApproved = /^approved$/.test(studentResults?.status);
            const isAuthorOfResult = currentUserGuid === studentResult.whoAdded;
            const currentUserType = (() => {
              if (responderGuid === currentUserGuid) {
                return projectResponderType.STUDENT;
              }
              return projectResponderType.SUPERVISOR;
            })();
            const isFileAttached = studentResult.file !== null;

            const resultItemParams = {
              currentUserType,
              isAuthorOfResult,
              isApproved,
              isFileAttached,
            };

            return (
              <StudentResultItem
                title={truncateTitle(studentResult.title)}
                file={studentResult.file}
                isAddFileMode={defineToShowAttacheFileLable({
                  ...resultItemParams,
                })}
                isShowDeleteButton={defineToShowDeleteButton({
                  ...resultItemParams,
                })}
                key={index}
                uploadFileCategory={`project/${projectId}/studentResult/${responderGuid}`}
                onUpdateProjectHandler={onUpdateProjectHandler}
                onDeleteResult={(resultForDelete: ProjectStudentResult) =>
                  onDeleteResultHandler.call(null, {
                    resultForDelete,
                    allStudentResults: studentResults,
                    projectPayloadData,
                    responderGuid,
                    deleteUpload,
                    onUpdateProject,
                  })
                }
                downloadFile={downloadFile}
              />
            );
          })}
      </ul>
    );
  }, [
    studentResults,
    onUpdateProject,
    projectPayloadData,
    responderGuid,
    currentUserGuid,
    projectId,
    deleteUpload,
  ]);

  return (
    <>
      {((studentResults &&
        !/^approved$/.test(studentResults?.status) &&
        responderGuid === currentUserGuid) ||
        responderGuid !== currentUserGuid) && (
        <>
          <br />

          <div className="addResult">
            <input
              type="text"
              placeholder="Введите название"
              maxLength={256}
              onChange={(e) => setReport(e?.target?.value)}
            />

            <button
              className={clsx(
                "statusButton buttonActive",
                !report && "disabled"
              )}
              disabled={!report}
              onClick={() =>
                onAddResultHandler(
                  onUpdateProject,
                  projectPayloadData,
                  responderGuid,
                  currentUserGuid,
                  report
                )
              }
            >
              Добавить результат
              <IconCirclePlus color="white" />
            </button>
          </div>
        </>
      )}

      {docTypes}
    </>
  );
};

export default AddStudentResultInput;

import { gql, useMutation, } from '@apollo/client'


const CHANGE_SETTINGS = gql(`
  mutation SetUpdateConfig($name: String!, $value: String!){
    updatePersonnelConfig(name: $name, value: $value)
  }
`)


const useChangePersonalRatingSettings = () => {
  const [
    onUpdate,
  ] = useMutation(CHANGE_SETTINGS)

  const update = ({
    name,
    value,
  }: any) => onUpdate({
    variables: {
      name,
      value,
    },
  })


  return update
}


export default useChangePersonalRatingSettings

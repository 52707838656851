import { useState, } from 'react'
import { useSelector, } from 'react-redux'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import BackToButton from '../../common/BackToButton/BackToButton'
import StudentInfo from './StudentInfo/StudentInfo'
import UserAchievementsDirections from './UserAchievementsDirections/UserAchievementsDirections'
import AchivmentDetails from '../AchivmentDetails/AchivmentDetails'
import useRedirect from '@/legacy/hooks/useRedirect'

function RatingById () {
  const [
    isDetails,
    setIsDetails,
  ] = useState(false)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studentRatingInfo = useSelector(state => state.port.ratingId.info)
  const redirect = useRedirect()


  return (
    <div className="InnerPage applyProject">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
          {
            value: 'Рейтинг',
            path: '/portfolio-rating',
          },
        ] }
        loc="Рейтинг обучающегося"
      />

      <div className="studentInner">
        <BackToButton title="Вернуться к рейтингу" onClick={ () => redirect('/portfolio-rating') } />

        <StudentInfo i={ studentRatingInfo } />

        { isDetails ? (
          <AchivmentDetails
            guid={ studentRatingInfo.id }
            setIsDetails={ setIsDetails }
            i={ isDetails }
            type="stranger"
          />
        ) : (
          <UserAchievementsDirections
            id={ studentRatingInfo.id }
            setIsDetails={ setIsDetails }
          />
        ) }
      </div>
    </div>
  )
}


export default RatingById

import { IconTrash, } from '@tabler/icons'
import { Fragment, useContext, } from 'react'
import ButtonSimple, { IconComponent, } from '../../../common/ButtonSimple/ButtonSimple'
import { TitleH4, } from '../../../common/GeneralElements/GeneralElements'
import PaperContainerWrapper from '../../../common/PaperContainer/PaperContainerWrapper'
import { CreateJsonContext, } from '../PortfolioEditIndicator'
import CriteriaHeader from './components/CriteriaHeader/CriteriaHeader'
import PrimitiveCriteria from './components/PrimitiveCriteria/PrimitiveCriteria'
import SelectHeader from './components/SelectHeader/SelectHeader'
import SelectPointItem from './components/SelectPointItem/SelectPointItem'
import styles from './CriteriaItem.module.less'


const CriteriaItem = ({
  field,
  type,
  name,
  scores,
  options,
  levels,
  ...rest
}: any) => {
  const {
    // @ts-expect-error TS(2339): Property 'removeCriteriaElement' does not exist on... Remove this comment to see the full error message
    removeCriteriaElement,
  } = useContext(CreateJsonContext)


  return (
    <PaperContainerWrapper>
      <CriteriaHeader
        type={ type }
        field={ field }
      />


      { !/select$/.test(type) && (
        <PrimitiveCriteria
          name={ name }
          scores={ scores }
          field={ field }
          detached={ rest.detached }
        />
      ) }


      { /select$/.test(type) && (
        <SelectHeader
          name={ name }
          field={ field }
          addNew={ 'select-option' }
        />
      ) }


      { type === 'select' && options.map((option: any, index: any) => (
        <SelectPointItem
          index={ index }
          optionIndex={ index }
          field={ field }
          type={ type }
          option={ option }
          scores={ scores }
          key={ index }
        />
      )) }


      { type === 'two-level-select' && levels.map((level: any, index: any) => (
        <Fragment key={ index }>
          <div className={ styles.titleWrapper }>
            <TitleH4 className={ styles.title }>
              Список { index + 1 }
            </TitleH4>

            <ButtonSimple
              variant={ 'alone' }
              value={
                <IconComponent text="Удалить" Icon={ IconTrash } iconStyles={ { color: '#FF5E5E', } } />
              }
              onClickHandler={ () => removeCriteriaElement({
                field,
                index,
              }) }
            />
          </div>
          <br />

          <SelectHeader
            name={ level.name }
            field={ field }
            fieldType='level'
            addNew={ 'select' }
            index={ index }
            isSecondLevel
          />

          { level.options.map((option: any, optionIndex: any) => (
            <SelectPointItem
              parentIndex={ index }
              index={ optionIndex }
              field={ field }
              fieldType='option'
              type={ type }
              option={ option }
              scores={ scores }
              key={ optionIndex }
            />
          )) }
        </Fragment>
      )) }
    </PaperContainerWrapper>
  )
}


export default CriteriaItem

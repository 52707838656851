/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { Fragment, useState, } from 'react'
import clsx from 'clsx'
import ChooseField from '../../common/Fields/ChooseField/ChooseField'
import TextField from '../../common/Fields/TextField/TextField'
import InputFile from '../../common/InputFile/InputFile'
import useUpdateAchievement from '../../../gql/hooks/portfolio/useUpdateAchievement'
import { getUnixTime, } from 'date-fns'
import { hidePostfixName, } from '../AddAchivmentPopup/AddAchivmentPopup'
import CalendarSimple from '../../common/CalendarSimple/CalendarSimple'
import AttachedFiles from '../../common/AttachedFiles/AttachedFiles'
import { defineDateFormat, } from '../DetailsCard/DetailsCard'
import { Checkbox, FormControlLabel, } from '@mui/material'
import { getNameWithPostfix, } from '../../../hooks/useUploadFiles'
import InputSelectDeprecated from '../../common/InputSelectDeprecated/InputSelectDeprecated'
import { EndPoints } from '@/shared/constants/endpoints'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'


export const transformPayloadToObject = (payload: any) => {
  const result = {}
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  payload.forEach((item: any) => result[item.field] = item.value)

  return result
}

export const handleEditUserPoints = (payload: any) => {
  const fields = Object.values(payload) as { [key: string]: unknown }[]


  const scores = fields.map((field: any) => {
    if (/^data$|^text$|^file$/.test(field.type)) {
      return Number(field?.scores?.amount || 0)
    }

    if (field.type === 'select') {
      return Number(field.options[field.value]?.scores?.amount || 0)
    }

    if (field.type === 'two-level-select') {
      // @ts-expect-error TS(2538): Type 'any[]' cannot be used as an index type.
      return Number(field.levels[[
        field.value[0],
      ]].options[field.value[1]]?.scores?.amount || 0)
    }

    return 0
  })

  const sumScores = scores.reduce((previousValue: any, currentValue: any) => previousValue + currentValue, 0)

  return sumScores
}

function EditPopup ({
  isOpen,
  setIsOpen,
  old,
}: any) {
  console.log('old', old)
  const [
    payload,
    setPayload,
  ] = useState(old?.fields ? old?.fields : [])
  const [
    moderatorComment,
  ] = useState(old?.comment?.moderator ? old?.comment?.moderator : null)
  const [
    authorComment,
    setAuthorComment,
  ] = useState(old?.comment?.author ? old?.comment?.author : null)
  const userRole = useGetRole()

  const onClose = () => {
    setIsOpen(!isOpen)
  }

  function uploadFile (item: any, field: any) {
    console.log('ITEM', item)
    console.log('FIELD', field)
    const formData = new FormData()
    formData.append(`portfolio/achivment`, item[0], getNameWithPostfix(item[0].name))

    fetch(EndPoints.upload, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    }).then(res => {
      return res.json()
    })
      .then(doc => {
        const formIndex = payload.findIndex((item: any) => item.name === field.name)
        const newPayload = [
          ...payload,
        ]
        newPayload[formIndex] = {
          ...field,
          value: [
            ...field.value,
            ...doc,
          ],
        }

        setPayload([
          ...newPayload,
        ])
      })
  }

  const updateAchievement = useUpdateAchievement()
  function handleUpdateAchivment () {
    updateAchievement({
      achievement_id: old.achievement_id,
      fields: transformPayloadToObject(payload),
      userpoints: handleEditUserPoints(payload),
      comment: moderatorComment ? {
        moderator: moderatorComment,
        author: authorComment,
      } : null,
      status: (/^student/.test(userRole) && old.status === 'rejected') ? 'notavailable' : old.status,
    })
      .then(res => {
        onClose()
      })
      .catch(e => console.log('fuckIt', e))
  }

  const handleNewValue = (index: any, value: any, isSecondLevel: any) => {
    setPayload(
      payload.map((item: any, payloadIndex: any) => {
        if (payloadIndex === index) {
          if (item.type === 'select') {
            return {
              ...item,
              value: item.options.findIndex((field: any) => field.name === value),
            }
          } else if (item.type === 'two-level-select') {
            if (isSecondLevel) {
              return {
                ...item,
                value: [
                  item.value[0],
                  item.levels[item.value[0]].options.findIndex((item: any) => item.name === value),
                ],
              }
            }

            if (item.levels.findIndex((item: any) => item.name === value) !== -1) {
              return {
                ...item,
                value: [
                  item.levels.findIndex((item: any) => item.name === value),
                  0,
                ],
              }
            }
          } else {
            return {
              ...item,
              value: value,
            }
          }
        } return item
      })
    )
  }


  const renderForm = (arr: any) => {
    return arr.map((item: any, index: any) => {
      if (item.type === 'data') {
        return (
          <Fragment key={ index }>
            <p>{ item.name }</p>

            <FormControlLabel
              control={ (
                <Checkbox
                  disabled={ /kurator/.test(userRole) }
                  checked={ item?.value?.isWithoutDay }
                  onChange={ (event, isChecked) => {
                    const newPayload = [
                      ...payload,
                    ]
                    if (isChecked) {
                      newPayload[index] = {
                        ...payload[index],
                        value: { isWithoutDay: isChecked, },
                      }
                    } else {
                      newPayload[index] = {
                        ...payload[index],
                        value: null,
                      }
                    }
                    return setPayload(newPayload)
                  } }
                />
              ) }
              label="Без даты"
            />

            <CalendarSimple
              disabled={ /kurator/.test(userRole) }
              isWithoutDay={ payload[index]?.value?.isWithoutDay }
              withBorder
              placeholderColor={ '#7f8190' }
              selectRange={ false }
              styles={ {
                marginBottom: `20px`,
                height: '64px',
              } }
              defaultSingleDate={ payload[index]?.value?.isWithoutDay ? null : defineDateFormat(payload[index]?.value) }
              // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
              returnSingle={ (value: any) => (payload[index]?.value?.isWithoutDay ? null : handleNewValue(index, getUnixTime(value))) }
            />
          </Fragment>
        )
      } else if (item.type === 'text') {
        return (
          <TextField
            isDisabled={ !/urtos-moder|^student/.test(userRole) }
            isWithDebounce
            key={ index }
            styles={ {
              width: '100%',
              marginBottom: '20px',
            } }
            value={ item.value }
            placeHold={ item.name }
            // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
            handler={ (value: any) => handleNewValue(index, value) }
          />
        )
      } else if (item.type === 'file') {
        return (
          <div key={ index }>
            <p className="fileTitle">
              { item?.name }
            </p>

            { /urtos-moder|^student/.test(userRole) && (
              <InputFile
                isDisabled={ item.value?.length > 0 }
                styles={ { marginBottom: '20px', } }
                onChange={ event => uploadFile(event.target.files, item) }
                accept='.pdf,.zip,.jpeg'
              />
            ) }

            { item.value?.length > 0 && (
              <AttachedFiles
                isDisabledDownload={ old.direction === 'Работы обучающегося' && !/urtos-moder|^student/.test(userRole) }
                isDeleteFilesAllowed={ /urtos-moder|^student/.test(userRole) }
                fileList={ item.value.map((file: any) => ({
                  ...file,
                  name: hidePostfixName(file.name),
                })) }
                getIndexFile={ (returnedIndex: any) => {
                  const currentFieldIndex = payload.findIndex((payloadItem: any) => payloadItem.name === item.name)
                  const currentPayloadItem = payload[currentFieldIndex]

                  const newPayload = [
                    ...payload,
                  ]
                  newPayload[currentFieldIndex] = {
                    ...currentPayloadItem,
                    value: currentPayloadItem.value.filter((item: any, index: any) => index !== returnedIndex),
                  }

                  setPayload(newPayload)
                } }
              />
            ) }
          </div>
        )
      } else if (item.type === 'two-level-select') {
        return (
          <Fragment key={ index }>
            <p className="fileTitle">
              { item?.name }
            </p>

            <InputSelectDeprecated
              isDisabled={ /kurator/.test(userRole) }
              className='full-filled'
              defaultHint={ item.name }
              defaultValue={ item?.levels[item?.value[0]]?.name }
              items={ item?.levels.map((option: any) => option.name) }
              // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
              callBack={ (value: any) => handleNewValue(index, value) }
            />
            <br />

            {
              // @ts-expect-error TS(2550): Property 'values' does not exist on type 'ObjectCo... Remove this comment to see the full error message
              typeof Object.values(payload)?.find((type: any) => type?.field === item?.field)?.value?.[0] === 'number'
              && (
                <>
                  <InputSelectDeprecated
                    withResetValue={ String(item.value[0]) }
                    isDisabled={ /kurator/.test(userRole) }
                    className='full-filled'
                    // @ts-expect-error TS(2550): Property 'values' does not exist on type 'ObjectCo... Remove this comment to see the full error message
                    defaultHint={ item.levels[Object.values(payload)?.find((type: any) => type?.field === item?.field)?.value?.[0]].name }
                    defaultValue={ item?.levels[item?.value[0]]?.options[item?.value[1]]?.name }
                    // @ts-expect-error TS(2550): Property 'values' does not exist on type 'ObjectCo... Remove this comment to see the full error message
                    items={ item?.levels[Object.values(payload)?.find((type: any) => type?.field === item?.field)?.value?.[0]].options.map((option: any) => option.name) }
                    callBack={ (value: any) => handleNewValue(index, value, true) }
                  />
                  <br />
                </>
              )
            }
          </Fragment>
        )
      }
      return (
        <ChooseField
          isDisabled={ /kurator/.test(userRole) }
          key={ index }
          styles={ {
            width: '100%',
            marginBottom: '20px',
          } }
          title={ item.value }
          items={ item?.options }
          type="multiple"
          handler={ (value: any) => {
            // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
            handleNewValue(index, value.value)
          } }
          value={ item?.options[item?.value]?.name }
        />
      )
    })
  }


  return (
    <div className={ clsx('addAchivePopup', isOpen && 'taskPopAct') }>
      <div className=" addAchive"
        style={ { position: 'relative', } }
      >
        <button className="close" onClick={ onClose }>x</button>

        <h2>Редактирование достижения</h2>

        { moderatorComment && (
          <TextField
            isWithDebounce
            styles={ {
              width: '100%',
              marginBottom: '20px',
              borderColor: 'red',
            } }
            value={ moderatorComment }
            isDisabled
          />
        ) }

        <div>
          { renderForm(payload) }
        </div>

        { moderatorComment && (
          <TextField
            isWithDebounce
            styles={ {
              width: '100%',
              marginBottom: '20px',
            } }
            value={ authorComment }
            handler={ setAuthorComment }
            placeHold="Комментарий"
            isDisabled={ !/^student/.test(userRole) }
          />
        ) }

        <button
          onClick={ handleUpdateAchivment }
          className={ clsx('statusButton buttonActive' /* disabled && 'disabled' */) }
        /* disabled={disabled} */
        >
          Изменить достижение
        </button>
      </div>
    </div>
  )
}


export default EditPopup

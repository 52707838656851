import { Col, } from 'antd'
import './ColInput.less'

function ColInput ({
  space,
  value,
  title,
  type,
  error,
  onChange,
  name,
}: any) {
  return (
    <Col span={ space }>
      <div className="infoItemWrap inputItemWrap">
        <label className="personalLable">{ title }<input minLength={ 8 } onChange={ onChange } type={ type } className="personalInput" name={ name } defaultValue={ value } autoComplete="off" required /></label>
        <span className="inputError">{ error }</span>
      </div>
    </Col>
  )
}

export default ColInput

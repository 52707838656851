import clsx from 'clsx'
import './AmsCardAchievement.less'
import { IconEdit, IconHelp, } from '@tabler/icons'
import { Fragment, } from 'react'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import { statuses, } from '../CardAchievement/CardAchievement'
import _ from 'lodash'


const AmsCardAchievement = ({
  status,
  reason,
  cardFormData,
  cardAchievementData,
  getReasonFailure,
  reurnDataForAddAppeal,
  scores,
  appeal,
  setIsShowAppealStatus,
  setAppealStatus,
}: any) => {
  const appealBlock = (
    <div className="card-achievement__appeal-wrapper">
      <div className={ clsx(
        'card-achievement__appeal-table',
        `card-achievement__appeal-table_${ appeal?.status }`
      ) } >
        <IconEdit />
        { statuses[appeal?.status]?.text }
      </div>

      <IconHelp
        style={ {
          color: '#F9B754',
          cursor: 'pointer',
        } }
        onClick={ () => {
          setIsShowAppealStatus(true)
          setAppealStatus(appeal)
        } }
      />
    </div>
  )


  return (
    <div className={ clsx({
      'card-achievement': true,
    }) }
    >
      { appeal?.status && appealBlock }

      <div className='card-achievement__body-wrapper'>
        <div className='card-achievement__header'>
          <StatusGroup status={ status } />


          { status === 'rejected'
            ? <RejectedElement
              getReasonFailure={ getReasonFailure }
              reason={ reason }
            /> : false }
          { scores && (status === 'archived' || status === 'approved') ? (
            <ArchivedElement scores={ scores } />
          ) : false }
        </div>


        { cardFormData.map((field: any, index: any) => (
          <Fragment key={ index }>
            <br />

            <div className='card-achievement__title'>
              { field.title }
            </div>

            <div className='card-achievement__subtitle'>
              {
                field.round
                  ? _.round(field.subtitle, field.round)
                  : field.subtitle
              }
            </div>
            <br />

            { field?.thresholdValue && (
              <>
                <div className='card-achievement__title'>
                  Пороговое значение:
                </div>

                <div className='card-achievement__subtitle'>
                  { field.thresholdValue }
                </div>

                <br />

                <div className={ clsx({
                  'card-achievement__tag': true,
                  'card-achievement__tag_failed': field.subtitle < field.thresholdValue,
                  'card-achievement__tag_succes': field.subtitle >= field.thresholdValue,
                }) }
                >
                  { field.subtitle < field.thresholdValue && (
                    'Показатель не выполнен'
                  ) }

                  { field.subtitle >= field.thresholdValue && (
                    'Показатель выполнен'
                  ) }
                </div>
              </>
            ) }
          </Fragment>
        )) }


        { !appeal?.status && (
          <div className='card-achievement__subtitle-button'>
            <ButtonSimple
              value="Подать апелляцию"
              variant="small-button"
              active
              onClickHandler={ () => {
                reurnDataForAddAppeal(cardAchievementData)
              } }
            />
          </div>
        ) }
      </div>
    </div>
  )
}


export const StatusGroup = ({
  status,
  style,
  isLink,
  onClickHandler,
  isIconLess,
}: any) => (
  <span className='card-achievement__status-group' style={ style }>
    { isIconLess ? false : (
      <span className={ clsx(
        'card-achievement__icon-status',
        `card-achievement__icon-status_${ status }`
      ) }>
        { status ? statuses[status]?.icon : false }
      </span>
    ) }

    <span
      className={ clsx(
        'card-achievement__text-status',
        `card-achievement__text-status_${ status }`,
        {
          [`card-achievement__text-status_is-link`]: isLink,
        }
      ) }
      onClick={ () => {
        if (isLink) {
          onClickHandler()
        }
      } }
    >
      { status ? statuses[status]?.text : false }
    </span>
  </span>
)


const RejectedElement = ({
  getReasonFailure,
  reason,
}: any) => (
  <span
    className={
      clsx(
        'card-achievement__text-status',
        'card-achievement__text-status_clickable'
      )
    }
    onClick={ () => getReasonFailure({ reason, }) }
  >
    Причина отказа
  </span>
)


const ArchivedElement = ({
  scores,
}: any) => (
  <span
    className={
      clsx(
        'card-achievement__text-status',
        'card-achievement__text-status_light'
      )
    }
  >
    { scores } балл(ов)
  </span>
)


export default AmsCardAchievement

/* eslint-disable no-console */
import { useCallback, useEffect, } from 'react'
import { useLocation, } from 'react-router-dom'
import useLogFrontendErrors from '@/legacy/gql/hooks/useLogFrontendErrors'
import { useLogFrontend, } from '@/legacy/gql/hooks/useSystem'


const LocationListener = () => {
  const [
    sendInfo,
  ] = useLogFrontend()
  const location = useLocation()
  const onErrorFrontend = useLogFrontendErrors()


  useEffect(() => {
    sendInfo({
      variables: {
        input: {
          tag: 'location',
          data: {
            ...location,
          },
        },
      },
    })
      .catch(console.error)
  }, [
    location,
    sendInfo,
  ])


  const errorListener = useCallback((event: any) => {
    if (event?.error?.hasBeenCaught !== undefined) {
      return false
    }
    if (event?.error?.hasBeenCaught) {
      event.error.hasBeenCaught = true
    }

    onErrorFrontend('runTimeError', {
      message: event?.error?.message,
      // @ts-expect-error TS(2345): Argument of type '{ message: any; stackTrace: any;... Remove this comment to see the full error message
      stackTrace: event?.error?.stack?.split('\n')?.slice(1, 6)?.map(
        (item: any) => item.replace(/ \(.*/, '')),
    })
  }, [
    onErrorFrontend,
  ])


  useEffect(() => {
    window.addEventListener('error', errorListener)

    return () => window.removeEventListener('error', errorListener)
  }, [
    errorListener,
  ])


  return null
}


export default LocationListener

import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Год набора',
    index: 'yearOfRecruitment',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Код и направление подготовки',
    index: 'major',
    editable: true,
    editType: 'inputWithSpecsSearch',
  },
  {
    title: 'Форма обучения',
    index: 'formOfEducation',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Программа магистратуры/аспирантуры',
    index: 'programm',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Тематика научного исследования',
    index: 'theme',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Документ',
    index: 'document',
    editable: true,
    editType: 'upload',
    style: { minWidth: '10rem', },
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '8rem', },
    editable: false,
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Год набора',
    index: 'yearOfRecruitment',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Код и направление подготовки',
    index: 'vector',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Форма обучения',
    index: 'formOfEducation',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Программа магистратуры/аспирантуры',
    index: 'programm',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Тематика научного исследования',
    index: 'theme',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Документ',
    index: 'document',
    editable: true,
    editType: 'upload',
    style: { minWidth: '10rem', },
  },
  {
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
    editable: false,
  },
]

import { useState, } from 'react'
import './ScolarInfo.less'
import InfoCard from './InfoCard'
import { scolars, } from '../../../../Utils/scolarShip'
import clsx from 'clsx'


function ScolarInfo () {
  const [
    activeInfo,
    setActiveInfo,
  ] = useState('Государственные академические стипендии студентам')

  return (
    <div className="scolarInfo">
      <div style={ {
        position: 'relative',
        height: '711px',
      } }>
        <div className="scolarSideBar">
          { scolars.map(i => {
            return <p key={ scolars.indexOf(i) } onClick={ () => setActiveInfo(i.name) } className={ clsx(i.name === activeInfo && 'p-Active') }>{ i.name }</p>
          }) }
        </div>
        <div className="vigorLayout" />
      </div>
      {
        scolars.map(i => {
          if (i.name === activeInfo) {
            return (
              <div key={ scolars.indexOf(i) } className="infoCardsWrap">
                <h3>{ i.name }</h3>

                { i.general ? (
                  <InfoCard info={ i.general } type="general" />
                ) : false }

                { i.get ? (
                  <InfoCard info={ i.get } type="get" />
                ) : false }

                { i.finish ? (
                  <InfoCard info={ i.finish } type="finish" />
                ) : false }
              </div>
            )
          } return null
        })
      }
    </div>
  )
}

export default ScolarInfo

import { TableCellType, } from '@/legacy/types'
import { table_cat_3_3, } from '../../popUpInputsCatalogs'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Уровень',
    index: 'level',
    editable: true,
    editType: 'select',
    style: { minWidth: '9rem', },
    selectOptions: table_cat_3_3.level,
  },
  {
    title: 'Название организации',
    index: 'organizationName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Сроки действия сертификата',
    index: 'validityPeriod',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Подтверждающие документы',
    index: 'documents',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: false,
    style: { minWidth: '4rem', },
    editType: 'inputNumbers',
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '12rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Уровень',
    index: 'level',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_3_3.level,
  },
  {
    title: 'Название организации',
    index: 'organizationName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Сроки действия сертификата',
    index: 'validityPeriod',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Подтверждающие документы',
    index: 'documents',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: false,
    editType: 'inputNumbers',
  },
  {
    title: 'Причина отказа',
    editable: false,
    index: 'reason',
    style: { minWidth: '20rem', },
    editType: 'input',
  },
  {
    title: 'Комментарий',
    editable: false,
    index: 'textAppeal',
    editType: 'input',
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
  },
]

import { gql, useLazyQuery, } from '@apollo/client'
import { useEffect, useState, } from 'react'
import { useEcExportSubscription, } from './useEcSubscription'

const CREATE_DATA_22_18 = gql`
  query Generate{
    ecCreateData22_18(mode: ALL)
  }
`


const useCreateData_22_18 = () => {
  const subscription_22_18 = useEcExportSubscription()
  const processingStatus = subscription_22_18?.data?.ecExportsProgress

  const [
    isLoading_22_18,
    setIsLoading_22_18,
  ] = useState(false)

  useEffect(() => {
    if (
      processingStatus?.state === 'running'
      && processingStatus?.type === 'effectiveStats'
      && /^обработка/gi.test(processingStatus?.message)
    ) {
      setIsLoading_22_18(true)
    }

    if (processingStatus?.state === 'done' && processingStatus?.type === 'ecCreateData22_18') {
      setIsLoading_22_18(false)
    }
  }, [
    processingStatus?.state,
    processingStatus?.type,
    processingStatus?.message,
  ])

  const [
    createData_22_18,
  ] = useLazyQuery(CREATE_DATA_22_18, {
    fetchPolicy: 'network-only',
  })


  return {
    createData_22_18,
    isLoading_22_18,
  }
}


export default useCreateData_22_18

import { gql, useMutation, } from '@apollo/client'


export const EDIT_AVHIEVEMENT_DIRECTION = gql`
  mutation EditDirection($input: EditAchievementDirectionInput) {
    editAchievementDirection(input: $input)
  }
`

const useCreateAchievementDirection = () => {
  const [
    create,
  ] = useMutation(EDIT_AVHIEVEMENT_DIRECTION)

  const onCreateHandler = (name: any) => create({
    variables: {
      input: {
        name,
        available: false,
      },
    },
  })


  return onCreateHandler
}


export default useCreateAchievementDirection

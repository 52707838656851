import { IconEdit, IconHelp, } from '@tabler/icons'
import clsx from 'clsx'
import { useState, } from 'react'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import { statuses, } from '../cards/CardAchievement/CardAchievement'
import './AppealBlock.less'
import PopupNewAppeal from './PopupNewAppeal'
import PopupStatus from './PopupStatus'

const AppealBlock = ({
  data,
}: any) => {
  const [
    isOpenPopupNewAppeal,
    setIsOpenPopupNewAppeal,
  ] = useState(false)
  const [
    isOpenPopupStatus,
    setIsOpenPopupStatus,
  ] = useState(false)

  return (
    <>
      <div className="card-achievement__appeal-wrapper">
        { !data?.appeal?.status && (
          <div className='card-achievement__subtitle-button'>
            <ButtonSimple
              value="Подать апелляцию"
              variant="small-button"
              active
              onClickHandler={ () => {
                setIsOpenPopupNewAppeal(true)
              } }
            />
          </div>
        ) }


        { data?.appeal?.status && (
          <>
            <div className={ clsx(
              'card-achievement__appeal-table',
              `card-achievement__appeal-table_${ data?.appeal?.status }`
            ) }  >
              <IconEdit />
              { statuses[data?.appeal?.status]?.text }
            </div>

            <IconHelp
              style={ {
                color: '#F9B754',
                cursor: 'pointer',
              } }
              onClick={ () => {
                setIsOpenPopupStatus(true)
              } }
            />
          </>
        ) }
      </div>


      { isOpenPopupNewAppeal && (
        <PopupNewAppeal
          achievementData={ data }
          isOpenPopupNewAppeal={ isOpenPopupNewAppeal }
          setIsOpenPopupNewAppeal={ setIsOpenPopupNewAppeal }
        />
      ) }

      { isOpenPopupStatus && (
        <PopupStatus
          appealData={ data?.appeal }
          isOpenPopupStatus={ isOpenPopupStatus }
          setIsOpenPopupStatus={ setIsOpenPopupStatus }
        />
      ) }
    </>
  )
}


export default AppealBlock

import React, { useEffect, useState, } from 'react'
import './InternetBarToggle.less'
import { CircularProgress, } from '@mui/material'

type InternetBarToggleProps = {
  intranetStatus: boolean | undefined
  code: number | undefined
  useActivateInternetSessionByCurrentUser: () => void
  useDeactivateInternetSessionMutation: () => void
  refetchData: () => void;
}

const InternetBarToggle: React.FC<InternetBarToggleProps> = ({
  intranetStatus,
  code,
  useActivateInternetSessionByCurrentUser,
  useDeactivateInternetSessionMutation,
  refetchData,
}) => {
  const [
    isChecked,
    setIsChecked,
  ] = useState(false)
  const [
    isDisabled,
    setIsDisabled,
  ] = useState(false)
  const [
    isLoading,
    setIsLoading,
  ] = useState(false)
  useEffect(() => {
    if (!intranetStatus) {
      // Не отрисовывать компонент
      setIsDisabled(true)
    } else {
      switch (code) {
        case 403:
        case 404:
        case 409:
          setIsChecked(false)
          break
        case 200:
          setIsChecked(true)
          break
        case 500:
          setIsDisabled(true)
          break
      }
    }
  }, [
    intranetStatus,
    code,
  ]
  )

  const handleToggleChange = async () => {
    setIsLoading(true)
    try {
      if (isChecked) {
        await useDeactivateInternetSessionMutation()
        setIsChecked(prev => !prev)
      } else {
        await useActivateInternetSessionByCurrentUser()
        setIsChecked(prev => !prev)
      }
      refetchData()
    } catch (error) {
      console.error('Error handling toggle change:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="internetBarToggleWrap">
      {isLoading ? (
        <div style={{ maxHeight: '20px', }}>
          <CircularProgress size={25} style={{ marginRight: '50px', }}/>
        </div>
      ) : (
        <React.Fragment>
          <input
            type="checkbox"
            id="switch"
            checked={isChecked}
            onChange={handleToggleChange}
            disabled={isDisabled}
          />
          <label className="labelBar" htmlFor="switch">
            Toggle
          </label>
        </React.Fragment>
      )}
    </div>
  )
}

export default InternetBarToggle

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './StudentJournal.less'
import { useState, useMemo, } from 'react'
import JournalCard from './JournalCard/JournalCard'
import { useSelector, } from 'react-redux'
import { IconChevronDown, IconChevronUp, } from '@tabler/icons'
import GroupPopup from './GroupPopup'
import { useDisciplines, } from '../../../gql/hooks/useJournal'


function StudentJournal () {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studInfo = useSelector(state => state.prof.studentInfo)
  const [
    isOpen,
    setIsOpen,
  ] = useState(false)
  const [
    group,
    setGroup,
  ] = useState(studInfo[0].group)
  const [
    spec,
    setSpec,
  ] = useState(studInfo[0].namespec)
  const { data, error, loading, } = useDisciplines(group)

  function handlePopup (i: any) {
    setIsOpen(!isOpen)
  }

  function updateGroup (i: any) {
    setGroup(i.group)
    setSpec(i.namespec)
    setIsOpen(!isOpen)
  }


  const journals = useMemo(() => {
    if (data) {
      return (
        <ul className="journalsList">
          { data.disciplines.map((i: any) => {
            return i.semesters.map((sem: any) => {
              return <li key={ i.discipline }><JournalCard value={ i } group={ group } semester={ sem } /></li>
            })
          }) }
        </ul>
      )
    } else if (loading) {
      return <div>.........Загрузка</div>
    }
    return <div>error</div>


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
  ])

  return (
    <>
      <h2 className="profileTitle">Электронный журнал</h2>

      <div style={ { position: 'relative', } }>
        <div className="K-ChooseSpec" onClick={ handlePopup } >
          { spec }
          { isOpen ? <IconChevronUp style={ { margin: '0 0 0 15px', } } size={ 20 } /> : <IconChevronDown style={ { margin: '0 0 0 15px', } } size={ 20 } /> }
        </div>

        <GroupPopup items={ studInfo } isOpen={ isOpen } click={ updateGroup } />
      </div>

      { journals }
    </>
  )
}


export default StudentJournal

import { gql, } from '@/__generated__/gql'
import { useQuery, } from '@apollo/client'


const GET_CATALOGS = gql(`
  query GetCatalogs($names: [String]) {
    catalogs(names: $names)
  }
`)

export const useCatalogs = (names: any) => useQuery(GET_CATALOGS, {
  variables: {
    names,
  },
})

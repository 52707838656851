import { IconAdjustmentsHorizontal, } from '@tabler/icons'
import clsx from 'clsx'
import { format, } from 'date-fns'
import { useState, } from 'react'
import { useDispatch, } from 'react-redux'
import { onChangeFilterValues, } from '../../../../redux/moderateNotificationsSlice/moderateNotificationsSlice'
import ButtonSimple, { IconComponent, } from '../../../common/ButtonSimple/ButtonSimple'
import CalendarSimple from '../../../common/CalendarSimple/CalendarSimple'
import InputSelectDeprecated from '../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputTextDeprecated from '../../../common/InputTextDeprecated/InputTextDeprecated'
import { defineCalenderPlaceholder, } from '../../hooks/useCreateFormNotificationEdit'
import styles from './Filters.module.less'
import useSetDefaultFilterValues from './useSetDefaultFilterValues'


const Filters = ({
  setLocalFilters,
  filterName,
  title,
  role,
  date,
  status,
}: any) => {
  const dispatch = useDispatch()
  const [
    isFiltersCollabsed,
    setIsFiltersCollabsed,
  ] = useState(true)

  useSetDefaultFilterValues()


  return <>
    <header className={ styles.header }>
      <InputTextDeprecated
        className={ styles.textInput }
        withPlaceholder
        defaultHint={ 'Введите название' }
        value={ title.value }
        onChangeValue={ (newValue: any) => dispatch(onChangeFilterValues({
          filterName,
          filterKey: 'title',
          newValue,
        })) }
      />

      <ButtonSimple
        variant={ 'transparent' }
        value={ (
          <IconComponent
            Icon={ IconAdjustmentsHorizontal }
            text='Развернуть фильтры'
          />
        ) }
        onClickHandler={ () => setIsFiltersCollabsed(prev => !prev) }
      />

      <ButtonSimple
        active
        variant={ 'alone' }
        value={ 'Поиск' }
        onClickHandler={ () => setLocalFilters({ filterName, }) }
      />
    </header>

    <br />

    <div className={ clsx(styles.filtersWrapper, {
      [styles.filtersWrapper_collabsed]: isFiltersCollabsed,
    }) }>
      <InputSelectDeprecated
        withResetValue
        defaultValue={ role.value }
        defaultHint={ 'Роль' }
        items={ role.items }
        callBack={ (newValue: any) => dispatch(onChangeFilterValues({
          filterName,
          filterKey: 'role',
          newValue,
        })) }
      />

      <CalendarSimple
        defaultRangeDate={ date.value }
        placeholder={ defineCalenderPlaceholder(date) }
        returnRange={ (newValue: any) => dispatch(onChangeFilterValues({
          filterName,
          filterKey: 'date',
          newValue: {
            from: format(new Date(newValue[0]), 'yyyy-MM-dd'),
            to: format(new Date(newValue[1]), 'yyyy-MM-dd'),
          },
        })) }
      />

      <InputSelectDeprecated
        withResetValue
        defaultValue={ status.value.name }
        defaultHint={ 'Статус' }
        items={ status.items.map((status: any) => status.name) }
        callBack={ (newValue: any) => dispatch(onChangeFilterValues({
          filterName,
          filterKey: 'status',
          newValue: status.items.find((status: any) => status.name === newValue),
        })) }
      />
    </div>
  </>
}


export default Filters

import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'
import { Divider, } from '@mui/material'
import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/app/providers/AppProvider'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputFile from '../../../../common/InputFile/InputFile'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import AttachedParticipants from '../../../AttachedParticipants/AttachedParticipants'
import { defineUserGroupe, } from '../../../defineUserGroupe'
import TeacherSearch from '../../../Hooks/TeacherSearch/TeacherSearch'
import { table_cat_2_7, } from '../../../popUpInputsCatalogs'


export const defineForeign_authors = (stringValue: any) => {
  if (stringValue === table_cat_2_7.foreign_authors[0]) {
    return false
  } return true
}


const Inputs_2_7 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, } = useAppContext()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserFullName = useSelector(state => state.auth.me.data.full_name)
  const [
    ids,
    setIds,
  ] = useState(
    defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
      ? []
      : [
        currentUserId,
      ]
  )

  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification?.(
          'Эффективный контракт',
          ids,
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (rec_id: any, newData: any, user_ids: any) => onUpdateRow({
    variables: {
      rec_id: rec_id,
      new_data: newData,
      user_ids: user_ids,
    },
  })


  const [
    isAddMode,
    setIsAddMode,
  ] = useState(false)
  const [
    participants,
    setParticipants,
  ] = useState('')
  const [
    authors,
    setAuthors,
  ] = useState(defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
    ? []
    : [
      {
        guid: currentUserId,
        fio: currentUserFullName,
      },
    ])

  const [
    otherAuthors,
    setOtherAuthors,
  ] = useState('')

  const [
    article,
    setArticle,
  ] = useState('')
  const [
    journal,
    setJournal,
  ] = useState('')
  const [
    publication_year,
    setPublication_year,
  ] = useState('')
  const [
    countAuthors,
    setCountAuthors,
  ] = useState('')
  const [
    foreign_authors,
    setForeign_authors,
  ] = useState('')
  const [
    document,
    setDocument,
  ] = useState([])
  const [
    quartileNumber,
    setQuartileNumber,
  ] = useState('')


  useEffect(() => {
    if (hint === 'edit') {
      setIds([
        currentUserId,
        ...defaultValues.authors.map((item: any) => item.guid),
      ])
      setAuthors(defaultValues.authors)

      setArticle(defaultValues.article)
      setJournal(defaultValues.journal)
      setPublication_year(defaultValues.publication_year)
      setForeign_authors(defaultValues.foreign_authors)
      setQuartileNumber(defaultValues.quartile.journal_quartile)
      // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setDocument(defaultValues.document ? [
        defaultValues.document,
      ] : [])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    setIds(
      defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
        ? []
        : [
          currentUserId,
        ]
    )
    setAuthors(defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
      ? []
      : [
        {
          guid: currentUserId,
          fio: currentUserFullName,
        },
      ])

    setOtherAuthors('')

    setArticle('')
    setJournal('')
    setPublication_year('')
    setCountAuthors('')
    setForeign_authors('')
    setQuartileNumber('')
    setDocument([])
  }


  return <>
    <ButtonSimple
      value={ isAddMode ? 'Применить' : 'Добавить / удалить участников' }
      variant="alone"
      onClickHandler={ () => {
        setIsAddMode(!isAddMode)
        setParticipants('')
      } }
    />

    <Divider /><br />

    { isAddMode ? (
      <>
        <TeacherSearch
          defaultHint="Добавьте участников по их ФИО"
          value={ participants }
          onChangeValue={ setParticipants }
          handler={ (answer: any) => {
            if (!authors.find(author => author.guid === answer.guid)) {
              setAuthors([
                ...authors,
                {
                  guid: answer.guid,
                  fio: answer.fio,
                },
              ])
            }
          } }
        />

        <InputTextDeprecated
          defaultHint="Прочие авторы (не сотрудники Державинского университета, перечислить через запятую)"
          value={ otherAuthors }
          onChangeValue={ setOtherAuthors }
          onBlur={ () => {
            setAuthors([
              ...authors,
              ...otherAuthors.split(', ').map(authorName => {
                return {
                  fio: authorName.trim(),
                  guid: null,
                }
              }).filter(author => author.fio.length > 0),
            ])

            setOtherAuthors('')
          } }
        />

        <br />

        <AttachedParticipants
          participantsList={ authors.map(author => author.fio) }
          getName={ (name: any) => {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            const removeId = authors.find(item => item.fio === name).guid

            setIds(ids.filter(item => item !== removeId))

            if (removeId === null) {
              setAuthors(authors.filter(item => item.fio !== name))
            } else {
              setAuthors(authors.filter(item => item.guid !== removeId))
            }
          } }
        />
      </>
    ) : (
      <>
        <InputTextDeprecated
          defaultHint="Авторы статьи"
          value={ authors.map(author => author.fio).join(', ') }
          disabled
        />

        <InputTextDeprecated
          defaultHint="Название статьи"
          value={ article }
          onChangeValue={ setArticle }
        />

        <InputTextDeprecated
          defaultHint="Источник"
          value={ journal }
          onChangeValue={ setJournal }
        />

        <InputTextDeprecated
          defaultHint="Год публикации"
          value={ publication_year }
          onChangeValue={ setPublication_year }
          numbersOnly
        />

        <InputTextDeprecated
          defaultHint="Количество авторов"
          value={ countAuthors }
          onChangeValue={ setCountAuthors }
          numbersOnly
        />

        <InputSelectDeprecated
          defaultHint="Соавторы"
          items={ table_cat_2_7.foreign_authors }
          defaultValue={ foreign_authors }
          withResetValue={ true }
          callBack={ setForeign_authors }
        />

        <InputSelectDeprecated
          defaultHint="Квартиль"
          items={ table_cat_2_7.quartileNumber }
          defaultValue={ quartileNumber }
          withResetValue={ true }
          callBack={ setQuartileNumber }
        />

        <br />

        <TitleH5>
          Документ
        </TitleH5>

        { document.length < 1 ? (
          <InputFile
            onChange={ event => {
              // TODO: сделать uploadFile
              // uploadFile(...event.target.files, setDocument)
            } }
          />
        ) : false }

        <AttachedFiles
          isDeleteFilesAllowed={ true }
          fileList={ document }
          getIndexFile={ (index: any) => {
            setDocument(document.filter((file, fIndex) => fIndex !== index))
          } }
        />

        <br />

        <ButtonSimple
          isDisabled={ (
            authors.length < 1
            || countAuthors.trim().length < 1
            || article.trim().length < 1
            || journal.trim().length < 1
            || publication_year.trim().length < 1
            || quartileNumber.trim().length < 1
            || document.length < 1
          ) }
          value={ hint === 'edit' ? 'Сохранить изменения' : 'Добавить достижение' }
          variant="full-filled"
          onClickHandler={ () => {
            if (hint === 'edit') {
              onUpdateRowHandler(
                defaultValues.rec_id,
                {
                  authors: [
                    ...authors,
                    ...otherAuthors.split(', ').map(author => {
                      return {
                        fio: author,
                        guid: null,
                      }
                    }),
                  ],
                  article: article,
                  journal: journal,
                  publication_year: publication_year,
                  countAuthors: countAuthors,
                  foreign_authors: defineForeign_authors(foreign_authors),
                  quartile: {
                    journal_quartile: quartileNumber,
                  },
                  document: document[0],
                  status: defaultValues.status,
                  reason: '',
                  scores: null,
                },
                authors.map(author => author.guid).filter(guid => guid !== null)
              )

              fieldsToDefault()
              onClickHandler()
            } else {
              onAddNewEcDataHandler({
                variables: {
                  dataType: 'table_2_7',
                  data: {
                    authors: authors,
                    article: article,
                    journal: journal,
                    publication_year: publication_year,
                    countAuthors: countAuthors,
                    foreign_authors: defineForeign_authors(foreign_authors),
                    quartile: {
                      journal_quartile: quartileNumber,
                    },
                    document: document[0],
                    status: 'pending',
                    reason: '',
                    scores: null,
                    isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
                  },
                  user_ids: authors.map(author => author.guid).filter(guid => guid !== null),
                },
              })

              fieldsToDefault()
              onClickHandler()
            };
          } }
        />
      </>
    ) }
  </>
}


export default Inputs_2_7

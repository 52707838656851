import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'
import useConfigSubscription from './useConfigSubscription'

export const GET_CONFIGS = gql`
  query UtilConfig($domain: String, $key: String) {
    utilConfig(domain: $domain, key: $key)
  }
`


const useGetEcDocuments = () => {
  const { loading, data, refetch, } = useQuery(GET_CONFIGS, {
    variables: {
      domain: 'effective-contract',
      key: 'documents',
    },
  })

  useConfigSubscription({
    condition: {
      domain: 'effective-contract',
      key: 'documents',
    },
    refetch,
  })


  const documents = useMemo(() => {
    if (!loading && data) {
      const items = data.utilConfig.config.items

      return items
    } return []
  }, [
    loading,
    data,
  ])


  return { documents, }
}


export default useGetEcDocuments

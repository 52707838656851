import { TitleH3, TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import ButtonSimple, { IconComponent, } from '../../../../common/ButtonSimple/ButtonSimple'
import { IconCircleCheck, IconCircleX, } from '@tabler/icons'
import { useEffect, useState, } from 'react'
import TextArea from '../../../../common/TextArea/TextArea'
import { useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useGetEcListData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useLocation, } from 'react-router-dom'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputFile from '../../../../common/InputFile/InputFile'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import { getScores_2_8, } from '../../../getScores'
import { useGet_2_8_categories, useGet_2_8_roles, } from '../../../../../gql/hooks/effectiveContractHooks/useEcCatalogs'


const PopUpAppealContent_2_8 = ({
  data,
  onClosePopUp,
}: any) => {
  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  const [
    ids,
  ] = useState(moderateId)


  const [
    theme,
    setTheme,
  ] = useState('')
  const [
    category,
    setCategory,
  ] = useState('')
  const [
    is_leader,
    setIs_leader,
  ] = useState(false)
  const [
    is_executor,
    setIs_executor,
  ] = useState(false)
  const [
    document,
    setDocument,
  ] = useState([])


  const [
    textAppeal,
    setTextAppeal,
  ] = useState('')
  const [
    currentAppealId,
    setCurrentAppealId,
  ] = useState(data.rec_id)
  const [
    currentAchievementId,
    setCurrentAchievementId,
  ] = useState(null)
  const [
    rejectAchievement,
    setRejectAchievement,
  ] = useState(false)
  const [
    reason,
    setReason,
  ] = useState('')
  const [
    scores,
    setScores,
  ] = useState('')


  useEffect(() => {
    setTheme(data.theme)
    setCategory(data.category)
    setIs_leader(data.is_leader)
    setIs_executor(data.is_executor)
    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setDocument(data.document ? [
      data.document,
    ] : [])

    setTextAppeal(data.textAppeal)
    setCurrentAppealId(data.rec_id)
  }, [
    data,
  ])


  const defineRole = (is_leader: any, is_executor: any) => {
    if (is_leader && !is_executor) {
      return 'Руководитель'
    } else if (!is_leader && is_executor) {
      return 'Участник'
    }
  }

  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    setScores(getScores_2_8({
      category: category,
      role: defineRole(is_leader, is_executor),
    }))
  }, [
    category,
    is_leader,
    is_executor,
  ])


  const [
    currentAchievement,
    setCurrentAchievement,
  ] = useState(null)
  const [
    currentAppeal,
    setCurrentAppeal,
  ] = useState(null)

  const getCurrentAppeal = useGetEcListData({
    rec_id: currentAppealId,
  })

  const getCurrentAchievement = useGetEcListData({
    rec_id: currentAchievementId,
  })

  useEffect(() => {
    if (getCurrentAppeal?.data) {
      setCurrentAppeal(
        getCurrentAppeal?.data?.ecListData[0]?.data)

      setCurrentAchievementId(getCurrentAppeal?.data?.ecListData[0]?.data?.rec_id)
    }
  }, [
    getCurrentAppeal.loading,
    getCurrentAppeal.data,
  ])

  useEffect(() => {
    if (getCurrentAchievement?.data && currentAchievementId) {
      setCurrentAchievement(
        getCurrentAchievement?.data?.ecListData[0]?.data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCurrentAchievement.loading,
    getCurrentAchievement.data,
  ])


  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (rec_id: any, newData: any, ids: any) => onUpdateRow({
    variables: {
      rec_id: rec_id,
      new_data: newData,
      user_ids: ids,
    },
  })


  useEffect(() => {
    setRejectAchievement(false)
  }, [
    data,
  ])


  const [
    categories_2_8,
    setCategories_2_8,
  ] = useState([])
  const [
    roles_2_8,
    setRoles_2_8,
  ] = useState([])
  const get_2_8_categoriesQuery = useGet_2_8_categories()
  const get_2_8_rolesQuery = useGet_2_8_roles()

  useEffect(() => {
    if (get_2_8_categoriesQuery?.data) {
      setCategories_2_8(get_2_8_categoriesQuery.data.catalogs.ecTable_2_8_Categories)
    }

    if (get_2_8_rolesQuery?.data) {
      setRoles_2_8(get_2_8_rolesQuery.data.catalogs.ecTable2_8_Roles)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    get_2_8_categoriesQuery.loading,
    get_2_8_rolesQuery.loading,
  ])


  return <>
    <TitleH3>
      Научные гранты и проекты университета, получившие финансовую поддержку.
    </TitleH3>
    <p>Внесите требуемые изменения и подтвердие или отклоните апелляцию</p>
    <p>Комментарий: <i><b>{ textAppeal }</b></i></p>
    <br />


    { !rejectAchievement ? (
      <>
        <InputTextDeprecated
          defaultHint="Тема научного гранта или проекта"
          value={ theme }
          onChangeValue={ setTheme }
        /><br />


        <InputSelectDeprecated
          defaultHint="Сумма финансовой поддержки"
          items={ categories_2_8 }
          defaultValue={ category }
          withResetValue={ true }
          callBack={ setCategory }
        /><br />


        <InputSelectDeprecated
          defaultHint="Роль"
          // @ts-expect-error TS(2339): Property 'value' does not exist on type 'never'.
          items={ roles_2_8.map(role => role.value) }
          defaultValue={
            (is_leader)
              ? 'Руководитель'
              : (is_executor)
                ? 'Участник'
                : ''
          }
          withResetValue={ true }
          callBack={ (answer: any) => {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            const role = roles_2_8.find(item => item.value === answer).role

            if (role === 'is_leader') {
              setIs_leader(true)
              setIs_executor(false)
            }

            if (role === 'is_executor') {
              setIs_leader(false)
              setIs_executor(true)
            }
          } }
        /><br />


        <TitleH5>
          Документ подтверждающий участие в гранте
        </TitleH5>

        { document.length < 1 ? (
          <InputFile
            onChange={ event => {
              // TODO: сделать upload
              // uploadFile(...event.target.files, setDocument)
            } }
          />
        ) : false }

        <AttachedFiles
          isDeleteFilesAllowed={ true }
          fileList={ document }
          getIndexFile={ (index: any) => {
            setDocument(document.filter((file, fIndex) => fIndex !== index))
          } }
        /><br />


        <InputTextDeprecated
          defaultHint="Баллы"
          value={ scores }
          numbersOnly
          disabled
        /><br />
      </>
    ) : false }


    { rejectAchievement ? (
      <>
        <TextArea
          defaultHint='По какой причине вы хотите отклонить заявку?'
          withPlaceholder={ false }
          value={ reason }
          onChangeValue={ setReason }
        />

        <br />

        <div style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }>
          <ButtonSimple
            value="Отменить"
            variant="groupe"
            styles={ {
              marginRight: '1rem',
              backgroundColor: '#F5F6FA',
            } }
            onClickHandler={ () => {
              setRejectAchievement(false)
            } }
          />

          <ButtonSimple
            value={ (
              <IconComponent
                Icon={ IconCircleX }
                iconStyles={ { color: '#FF5E5E', } }
                text="Отклонить"
              />
            ) }
            variant="groupe"
            isDisabled={ reason.split(' ').length < 3 }
            onClickHandler={ () => {
              onUpdateRowHandler(
                currentAppealId,
                {
                  // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                  ...currentAppeal,
                  status: 'appealRejected',
                  appealConclusion: reason,
                },
                ids
              )

              onClosePopUp()
            } }
          />
        </div>
      </>
    ) : false }

    { !rejectAchievement ? (
      <div style={ {
        display: 'flex',
        justifyContent: 'flex-end',
      } }>
        <ButtonSimple
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          isDisabled={ ids.length < 1 }
          value={ (
            <IconComponent
              Icon={ IconCircleX }
              iconStyles={ { color: '#FF5E5E', } }
              text="Отклонить"
            />
          ) }
          variant="groupe"
          styles={ { marginRight: '1rem', } }
          onClickHandler={ () => setRejectAchievement(true) }
        />

        <ButtonSimple
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          isDisabled={ ids.length < 1 }
          value={ (
            <IconComponent
              Icon={ IconCircleCheck }
              text="Подтвердить"
            />
          ) }
          variant="groupe"
          active
          onClickHandler={ () => {
            onUpdateRowHandler(
              currentAchievementId,
              {
                // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                ...currentAchievement,
                theme: theme,
                category: category,
                is_leader: is_leader,
                is_executor: is_executor,
                document: document,
                status: 'approved',
                scores: scores,
              },
              ids
            )

            onUpdateRowHandler(
              currentAppealId,
              {
                // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                ...currentAppeal,
                status: 'appealApproved',
                appealConclusion: 'Ваша апелляция удовлетворена',
              },
              ids
            )

            onClosePopUp()
          } }
        />
      </div>
    ) : false }
  </>
}


export default PopUpAppealContent_2_8

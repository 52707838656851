import { useState, } from 'react'
import './FilteredSearch.less'
import InputTextDeprecated from '../../common/InputTextDeprecated/InputTextDeprecated'
import { IconAdjustmentsHorizontal, IconX, } from '@tabler/icons'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import clsx from 'clsx'

const FilteredSearch = () => {
  const [
    isFilters,
    setIsFilters,
  ] = useState(false)

  return <div className="library-filters-section">
    <div className="library_filteresSearch_wrap">
      <InputTextDeprecated defaultHint="Введите название книги" withPlaceholder={ true } styles={ {
        padding: '19px 15px',
        width: '75%',
      } } />
      <p className="open_serach_btn" onClick={ () => setIsFilters(!isFilters) }>Расширенный поиск
        { isFilters ? <IconX color="#7671DD" /> :
          <IconAdjustmentsHorizontal color="#7671DD" /> }</p>
      <ButtonSimple value="поиск" variant="fill" />
    </div>
    <div className={ clsx('filters-wrap', !isFilters && 'filters-wrap_hidden') }>
      <h2 className="section-title">Расширенный поиск</h2>
    </div>
  </div>
}


export default FilteredSearch

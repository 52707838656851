/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { gql, } from '@/__generated__'
import { useMutation, } from '@apollo/client'

interface PresignedUploadUrl {
  __typename: string
  fileId: string
  url: string
  message?: string
}

interface InvalidArgumentError {
  __typename: string
  message: string
}

interface GetUploadUrlResponse {
  getPresignedUploadURL: PresignedUploadUrl | InvalidArgumentError
}

interface InputFile {
  fileCategory: string
  fileName: string
  fileType: string
}

const GET_UPLOAD_URL = gql(`
    mutation GetUploadUrl($inputFile: InputFile!) {
        getPresignedUploadURL(inputFile: $inputFile) {
            ... on PresignedUploadUrlResult {
                ... on InvalidArgumentError {
                    __typename
                    message
                }
                ... on PresignedUploadUrl {
                    __typename
                    fileId
                    url
                }
            }
        }
    }
`)

const useUploadS3Test = () => {
  const [
    uploadFile,
    { loading, error, },
  ] = useMutation<GetUploadUrlResponse>(GET_UPLOAD_URL)
  const handleFileUpload = async (file: File, inputFile: InputFile) => {
    try {
      const response = await uploadFile({
        variables: {
          inputFile: inputFile,
        },
      })
      const presignedUploadUrl =
        response.data?.getPresignedUploadURL as PresignedUploadUrl
        || response.data?.getPresignedUploadURL as InvalidArgumentError

      if (presignedUploadUrl && presignedUploadUrl.__typename === 'PresignedUploadUrl') {
        const { fileId, url, } = presignedUploadUrl
        // Выполняем fetch с методом PUT для загрузки файла в S3
        await fetch(url, {
          method: 'PUT',
          body: file,
        })
        console.log(fileId)
        console.log('Файл успешно загружен!')
        return fileId
      } else if (presignedUploadUrl && presignedUploadUrl.__typename === 'InvalidArgumentError') {
        // TODO Добавить логер
        console.error('Ошибка при получении пресайнед URL:', presignedUploadUrl?.message)
      }
    } catch (error: any) {
      // TODO Добавить логер
      console.error('Ошибка при загрузке файла:', error.message)
    }
  }
  return {
    handleFileUpload,
    loading,
    error,
  }
}

export default useUploadS3Test

import { gql, useSubscription, } from '@apollo/client'
import { useEffect, } from 'react'


const NOTIFICATION_DARFTS_CHANGE_SUBSCRIPTION = gql`
  subscription NotificationDraftChanged {
    notificationDraftChanged
  }
`


const useNotificationDraftChanged = ({
  refetches = [],
}) => {
  const { loading, data, } = useSubscription(NOTIFICATION_DARFTS_CHANGE_SUBSCRIPTION)

  useEffect(() => {
    if (!loading && data) {
      const event = data.notificationDraftChanged.event

      if (event) {
        // @ts-expect-error TS(2349): This expression is not callable.
        refetches.forEach(refetch => refetch())
      }
    }
  }, [
    data,
    loading,
    refetches,
  ])
}


export default useNotificationDraftChanged

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import {
  IconCircleMinus,
  IconCirclePlus,
  IconDotsVertical,
  IconEdit,
  IconTrash,
} from '@tabler/icons'
import clsx from 'clsx'
import {
  useCallback,
  useMemo,
  useState,
} from 'react'
import styles from './OptionsButton.module.less'


const OptionsButton = ({
  className,
  onEditHandler,
  onDeleteHandler,
  onPublishDraftHandler,
  onResetDraftHandler,
  onRemoveSendedNotificationHandler,
}: any) => {
  const [
    anchorEl,
    setAnchorEl,
  ] = useState(null)
  const open = Boolean(anchorEl)
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])


  const iconButton = useMemo(() => (
    <IconButton
      aria-label="more"
      id="long-button"
      aria-controls={ open ? 'long-menu' : undefined }
      aria-expanded={ open ? 'true' : undefined }
      aria-haspopup="true"
      onClick={ event => {
        // @ts-expect-error TS(2345): Argument of type 'EventTarget & HTMLButtonElement'... Remove this comment to see the full error message
        setAnchorEl(event.currentTarget)
      } }
      className={ clsx(className, styles.menuButton) }
    >
      <IconDotsVertical />
    </IconButton>
  ), [
    className,
    open,
  ])


  return (
    <>
      { iconButton }

      <Menu
        id="basic-menu"
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
      >
        <MenuItem
          onClick={ () => {
            onPublishDraftHandler({ newStatus: true, })
            handleClose()
          } }
        >
          <ListItemIcon>
            <IconCirclePlus className={ styles.icon } />
          </ListItemIcon>
          <ListItemText>Опубликовать</ListItemText>
        </MenuItem>


        <MenuItem
          onClick={ () => {
            onRemoveSendedNotificationHandler()
              .then(() => onResetDraftHandler())
              .then(() => onPublishDraftHandler({ newStatus: false, }))
              .then(() => handleClose())
          } }
        >
          <ListItemIcon>
            <IconCircleMinus className={ styles.icon } />
          </ListItemIcon>
          <ListItemText>Снять с публикации</ListItemText>
        </MenuItem>


        <MenuItem
          onClick={ () => {
            onEditHandler()
            handleClose()
          } }
        >
          <ListItemIcon>
            <IconEdit className={ styles.icon } />
          </ListItemIcon>
          <ListItemText>Редактировать</ListItemText>
        </MenuItem>


        <MenuItem
          onClick={ () => {
            onDeleteHandler()
            handleClose()
          } }
        >
          <ListItemIcon>
            <IconTrash className={ styles.icon_dangerColor } />
          </ListItemIcon>
          <ListItemText>Удалить</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}


export default OptionsButton

import './Logos.less'
import logo from '@/shared/assets/images/header-logo.svg'
import vk from '@/shared/assets/images/VK.svg'
/* import inst from '../../../assets/images/inst.svg';
import fb from '../../../assets/images/facebook.svg';
import twitter from '../../../assets/images/twitter.svg'; */
import youtube from '@/shared/assets/images/youtube.svg'
// import {redirect} from '../../../Utils/functions';
// import { useNavigate } from "react-router-dom"


const social = [
  {
    img: vk,
    path: 'https://vk.com/tsutmb',
  }, /* {img: inst, path: "https://www.instagram.com/tsutmb/"}, */
  /* {img: fb, path: "https://www.facebook.com/tsutmb/"}, *//*  {img: twitter, path: "#"}, */ {
    img: youtube,
    path: 'https://www.youtube.com/channel/UC-CQem9MXJWxTHPlL9aT8JA',
  },
]

function Logos () {
  return (
    <div className="footerLogos">
      <img src={ logo } alt="logo" className="footerTGU" />
      <ul className="footerSocial" >
        { social.map(i => {
          return <li key={ social.indexOf(i) }><a href={ i.path }><img src={ i.img } alt="logo" /></a></li>
        }) }
      </ul>
    </div>

  )
}

export default Logos

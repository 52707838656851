import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { IconBook, IconBulb, } from '@tabler/icons'
import useGetEcAmsThresholdValues from '../../../gql/hooks/effectiveContractHooks/useGetEcAmsThresholdValues'
import useGetEcListData from '../../../gql/hooks/effectiveContractHooks/useGetEcListData'
import CollapseContainer2, { AccordionInnder, } from '../../common/CollapseContainer2/CollapseContainer2'
import { addedDefaultValues, dataAdapterForTable, dataWithThresholdValues, } from '../amsUtils/amsUtils'
import AppealBlock from '../AppealBlock/AppealBlock'
import TableContainer from '../TableContainer/TableContainer'
import { tableSource as headerFields_21_1, } from '../constants/tablesFormData/table_21_1'
import { tableSource as headerFields_21_2, } from '../constants/tablesFormData/table_21_2'


const AmsBasicIndicatorsTab = ({
  contentType,
  moderateId,
  dataTypeUpdated,
  setDataTypeUpdated,
  userRole,
}: any) => {
  const table_21_1 = useGetEcListData(moderateId, 'table_21_1', dataTypeUpdated, setDataTypeUpdated)
  const table_21_2 = useGetEcListData(moderateId, 'table_21_2', dataTypeUpdated, setDataTypeUpdated)

  const thresholdValues = useGetEcAmsThresholdValues()


  return (
    <CollapseContainer2>
      { /^director-|-admin$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconBook }
          headerText='Обеспечение информационно-образовательной среды Университета учебными и учебно-методическими материалами'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_21_1 }
                // isEditable={ false }
                headerFields={ headerFields_21_1 }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_21_1, headerFields_21_1, 'table_21_1', moderateId)),
                    thresholdValues: thresholdValues?.table_21_1,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_21_1?.length && (
                <AppealBlock
                  data={ table_21_1?.at(-1) }
                />
              ) }
            </>
          ) }
        </AccordionInnder>
      ) }


      { /^director-|-admin$|^aup-pk-moder$/.test(userRole) && (
        <AccordionInnder
          Icon={ IconBulb }
          headerText='Количество направлений подготовки, на которые объявлен набор на бюджетной основе, но не закрытые КЦП'
        // subHeaderText={ scores_1_1 }
        >
          { contentType === 'table' && (
            <>
              <TableContainer
                dataType={ EcTableName.TABLE_21_2 }
                // isEditable={ false }
                headerFields={ headerFields_21_2 }
                ecTableData={
                  dataWithThresholdValues({
                    data: dataAdapterForTable(addedDefaultValues(table_21_2, headerFields_21_2, 'table_21_2', moderateId)),
                    thresholdValues: thresholdValues?.table_21_2,
                  })
                }
              />

              { /^director-/.test(userRole) && !!table_21_2?.length && (
                <AppealBlock
                  data={ table_21_2?.at(-1) }
                />
              ) }
            </>
          ) }
        </AccordionInnder>
      ) }
    </CollapseContainer2>
  )
}


export default AmsBasicIndicatorsTab

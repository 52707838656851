import SectionWrapper from '../../../common/SectionWrapper/SectionWrapper'
import './LocationTable.less'
import { IconMapPin, } from '@tabler/icons'
import { Table, } from 'antd'

const LocationTable = () => {
  const columns = [
    {
      title: 'Отдел',
      dataIndex: 'department',
    },
    {
      title: 'Всего',
      dataIndex: 'all',
    },
    {
      title: 'Свободно',
      dataIndex: 'left',
    },
    {
      title: 'Шифры хранения',
      dataIndex: 'code',
    },
    {
      title: 'Бронь',
      dataIndex: 'book',
    },

  ]

  const dataSource = [
    {
      key: 1,
      department: 'Мед. АБ',
      all: 28,
      left: 5,
      code: 1234567,
      book: 'Забронировать',
    },
    {
      key: 1,
      department: 'Мед. АБ',
      all: 28,
      left: 5,
      code: 1234567,
      book: 'Забронировать',
    },
  ]

  const locale = {
    emptyText: <div style={ { padding: '30px 0', } }>Нет данных</div>,
  }

  return <SectionWrapper content={
    <div className="book-location">
      <h2 className='section-title'>Местанохождение и доступность</h2>
      <p className="book-location__name"><IconMapPin color="#7671DD" />ТГУ им. Г. Р. Державина</p>
      <Table
        locale={ locale }
        rowClassName={ () => 'editable-row' }
        bordered
        dataSource={ dataSource }
        columns={ columns }
        pagination={ false }
      />
    </div>
  } />
}

export default LocationTable

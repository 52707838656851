import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  type: 'Все',
}

export const NotificationsSlice = createSlice({
  name: 'studJournal',
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload
    },
  },
})


export const { setType, } = NotificationsSlice.actions

export default NotificationsSlice.reducer

/**
 * Компонент для ввода длинного текста.
 * Для оптимизации ввода текста, под капотом лежит DebounceInput.
 *
 * @param title - Является лейблом для поля ввода
 * @param value - Значение поля ввода
 * @param disabled - Блокирует input
 * @param debounceTimeout - Таймаут для debounce функции, по умолчанию 400 ms
 * @param className - Внешний CSS класс
 * @param rows - Кол-во строк внутри поля ввода текста (по умолчанию 2)
 * @param onChange - Callback для события onChange для input
 * @param onBlur - Callback для события onBlur для input
 * @returns `JSX element`
 */


import styles from './InputTextArea.module.less'
import { DebounceInput, } from 'react-debounce-input'
import { ChangeEventHandler, FocusEventHandler, } from 'react'
import InputTextLable from '@/shared/ui/InputTextLable/InputTextLable'


interface InputTextAreaProps {
  value: string
  title?: string
  disabled?: boolean
  debounceTimeout?: number
  className?: string
  rows?: number
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}


const InputTextArea = ({
  value,
  onChange,
  title,
  disabled,
  onBlur,
  debounceTimeout = 400,
  className,
  rows = 2,
}: InputTextAreaProps) => (
  <InputTextLable
    title={ title }
    className={ className }
  >
    <DebounceInput
      element={ 'textarea' }
      rows={ rows }
      debounceTimeout={ debounceTimeout }
      className={ styles.input }
      value={ value }
      onChange={ event => onChange && onChange(event) }
      disabled={ disabled }
      onBlur={ onBlur }
    />
  </InputTextLable>
)


export default InputTextArea

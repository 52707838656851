export const courseSemesters: any = {
  1: [
    1,
    2,
  ],
  2: [
    3,
    4,
  ],
  3: [
    5,
    6,
  ],
  4: [
    7,
    8,
  ],
  5: [
    9,
    10,
  ],
  6: [
    11,
    12,
  ],
}
export const workTypes = [
  'ВКР',
  'Курсовая работа',
]
export const semesterItems: number[] = []

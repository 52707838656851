/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useMemo, } from 'react'
import './AddTrackPopup.less'
import Popup from '../../../../common/Popup/Popup'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import { IconFile, IconCircleX, IconPaperclip, } from '@tabler/icons'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { InputNumber, } from 'antd'
import TextArea from '../../../../common/TextArea/TextArea'
import InstitutesField from './InstitutesField'
import { DebounceInput, } from 'react-debounce-input'
import SpecsList from '../../../../common/SpecsSearch/SpecsSearch'
import ProfsSearch from '../../../../common/ProfsSearch/ProfsSearch'
import FilterDropDown from '../../../../common/FilterDropDown/FilterDropDown'
import Persons from '../../../../common/PersonsInput/PersonsInput'
import { useCreateFacultative, } from '../../../../../gql/hooks/useMyEducation'
import { useGetActiveStats, } from '../../../../../gql/hooks/useMyEducation'
import { useAppContext, } from '@/app/providers/AppProvider'

const AddFacultativePopup = ({
  isOpen,
  handlePopup,
  title,
  type,
  data,
}: any) => {
  const [
    file,
    setFile,
  ] = useState<any>(false)
  const [
    gradeValue,
    setGradeValue,
  ] = useState('')
  const [
    discription,
    setDiscription,
  ] = useState(data ? data?.description : '')
  const [
    nameSpec,
    setNameSpec,
  ] = useState(
    data?.data.namespec ? data.data.namespec : []
  )
  const [
    nameProf,
    setNameprof,
  ] = useState(
    data?.data.nameprof ? data.data.nameprof : []
  )
  const [
    eform,
    setEform,
  ] = useState(data ? data.data.eform : [])
  const [
    course,
    setCourse,
  ] = useState(
    data?.data.course ? data.data.course : []
  )
  const [
    semester,
    setSemester,
  ] = useState(
    data?.data.semester ? data?.data.semester : []
  )
  const [
    group,
    setGroup,
  ] = useState(
    data?.data.group ? data.data.group[0] : ''
  )
  const [
    performer,
    setPerformer,
  ] = useState(
    data?.students ? data?.students.items : []
  )
  const [
    participants,
    setParticipants,
  ] = useState(
    data?.max_participants ? data.max_participants : null
  )
  const [
    name,
    setName,
  ] = useState(data ? data?.name : '')
  const [
    institute,
    setInstitute,
  ] = useState(
    data?.data.department ? data.data.department : []
  )
  const [
    grade,
    setGrade,
  ] = useState(data?.data.grade ? data?.data.grade : [])

  const [
    grades,
    setGrades,
  ] = useState(data?.level ? data?.level : [])
  const [
    specValue,
    setSpecValue,
  ] = useState<any>()
  const [
    profValue,
    setProfileValue,
  ] = useState<any>()
  const [
    performerValue,
    setPerformerValue,
  ] = useState<any>()

  const [
    currentField,
    setCurrentField,
  ] = useState<any>(null)

  const deleteGradeItem = (item: any) => {
    setGrades(grades.filter((i: any) => i !== item))
  }

  const handleAddGrade = () => {
    setGrades([
      ...grades,
      gradeValue,
    ])
    setGradeValue('')
  }

  const handleSpeces = (i: any) => {
    if (!nameSpec.find((item: any) => i === item)) {
      setNameSpec([
        ...nameSpec,
        i,
      ])
    }
  }

  const handleProfs = (i: any) => {
    if (!nameProf.find((item: any) => i === item)) {
      setNameprof([
        ...nameProf,
        i,
      ])
    }
  }


  const stat = useGetActiveStats().data

  const { openToast, } = useAppContext()

  const isAllActive = useMemo(() => {
    if (stat?.esDisciplinesState) {
      const { total, active, } = stat.esDisciplinesState

      return total === active
    }
    return true
  }, [
    stat,
  ])

  const payload = {
    is_visible: true,
    is_active: isAllActive,
    name,
    description: discription,
    level: grades,
    max_participants: participants,
    data: {
      department: institute,
      nameprof: nameProf,
      namespec: nameSpec,
      course,
      eform,
      group: [
        group,
      ],
      semester,
      grade,
    },
    students: { items: performer, },
  }

  const generatePayload = () => {
    if (grades.length < 1) {
      delete payload.level
    }
    if (nameSpec.length < 1) {
      delete payload.data.namespec
    }
    if (nameProf.length < 1) {
      delete payload.data.nameprof
    }
    if (!participants) {
      delete payload.max_participants
    }
    if (group.length < 1) {
      // @ts-expect-error TS(2790): The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
      delete payload.data.group
    }
    if (performer.length < 1) {
      // @ts-expect-error TS(2790): The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
      delete payload.students
    }
    if (course.length < 1) {
      delete payload.data.course
    }
    if (semester.length < 1) {
      delete payload.data.semester
    }
    if (institute.length < 1) {
      delete payload.data.department
    }
    return data
      ? {
        ...payload,
        facultative_id: data?.facultative_id,
      }
      : payload
  }

  const disable = useMemo(() => {
    const requiredFields = [
      eform,
      discription,
      name,
      course,
      semester,
    ]
    const oneRequired = [
      institute,
      performer,
      nameSpec,
      nameProf,
      group,
    ]

    const isDisable = !requiredFields.find(i => i.length < 1)
      ? oneRequired.every(i => i.length < 1)
      : requiredFields.find(i => i.length < 1)

    return !!isDisable
  }, [
    course,
    semester,
    institute,
    eform,
    discription,
    name,
    performer,
    nameSpec,
    nameProf,
    group,
  ])

  const params = generatePayload()

  const [
    esCreateFacultative,
  ] = useCreateFacultative(params)

  const handleCreateFacultative = () => {
    esCreateFacultative()
      .then(res => {
        handlePopup()
        openToast?.({ message: data ? 'Факультатив изменен' : 'Факультатив создан', })
      })
      .then(() => {
        if (!data) {
          setDiscription('')
          setNameSpec([])
          setNameprof([])
          setEform([])
          setCourse([])
          setSemester([])
          setGroup('')
          setPerformer([])
          setParticipants(null)
          setName('')
          setInstitute([])
          setGrades([])
        }
      })
      .catch(e => console.log(e))
  }

  const handleCources = (i: any) => {
    if (!course.find((item: any) => i === item)) {
      setCourse([
        ...course,
        i,
      ])
    }
  }

  const handleSemesters = (i: any) => {
    if (!semester.find((item: any) => i === item)) {
      setSemester([
        ...semester,
        i,
      ])
    }
  }

  const handleGrade = (i: any) => {
    if (!grade.find((item: any) => i === item)) {
      setGrade([
        ...grade,
        i,
      ])
    }
  }

  const handleEforms = (i: any) => {
    if (!eform.find((item: any) => i === item)) {
      setEform([
        ...eform,
        i,
      ])
    }
  }

  const handleInstitutes = (i: any, all: any) => {
    if (i === 'Выбрать все') {
      setInstitute(all)
    } else if (!institute.find((item: any) => i === item)) {
      setInstitute([
        ...institute,
        i,
      ])
    }
  }

  return (
    <Popup isOpen={ isOpen } handlePopup={ handlePopup } withScroll>
      <div style={ {
        display: 'flex',
        flexDirection: 'column',
      } }>
        <h2 className="addStudentTitle">{ title }</h2>


        <form className="addTrackForm">
          <InputTextDeprecated defaultHint="Название" value={ name } onChangeValue={ setName } />


          <InputNumber min={ 1 } max={ 300 } defaultValue={ participants } /* bordered={false}  */ formatter={ value => `Количество участников: ${ value }` } autoFocus={ false } onChange={ setParticipants } />


          { (!file && (type !== 'elective' && type !== 'Факультатив')) ? (
            <div className="fileWrapES">
              <input required style={ { display: 'none', } } type="file" id="addTrack" onChange={ e => setFile([
                e?.target?.files?.[0],
              ]) } />
              <label htmlFor="addTrack" className="dash">Прикрепить файл<IconPaperclip color="#7671DD" /></label>
            </div>
          ) : ((type !== 'elective' && type !== 'Факультатив') && <div className="dash full">
            <IconFile color="#7671DD" />
            <p className="activity">{ file[0]?.name }<span style={ {
              color: '#C5C5C5',
              marginLeft: '10px',
            } }>{ `${ (file[0]?.size / 1000000).toFixed(2) }Мб` }</span></p>
            <IconCircleX color="#C5C5C5" onClick={ () => setFile(null) } />
          </div>) }


          <div className="popupGradeWrap">
            <InputTextDeprecated defaultHint="Уровень" value={ gradeValue } onChangeValue={ setGradeValue } />
            <ButtonSimple variant="fill" value="+ Уровень" onClickHandler={ handleAddGrade } styles={ { width: 'fit-content', } } />
          </div>


          <ul>
            { grades.map((i: any, index: any) => <li className="gradeItem" key={ index }>- { i }<IconCircleX color="#C5C5C5" onClick={ () => deleteGradeItem(i) } size={ 20 } /></li>) }
          </ul>


          <TextArea style={ { marginBottom: '14px', } } defaultHint="Описание" onChangeValue={ setDiscription } /* withPlaceholder={false} */ value={ discription } />


          { course.length > 0 && <span className="filedArray">{ course?.map((i: any) => <div className="specTag" key={ i } >{ i }<IconCircleX onClick={ () => setCourse(course.filter((item: any) => item !== i)) } color="#C5C5C5" /></div>) }</span> }


          <FilterDropDown items={ [
            1,
            2,
            3,
            4,
            5,
            6,
          ] } title="Курс" defaultHint="Выберите курс" onClickHandler={ handleCources } withBorder="true" />


          { semester.length > 0 && <span className="filedArray">{ semester?.map((i: any) => <div className="specTag" key={ i } >{ i }<IconCircleX onClick={ () => setSemester(semester.filter((item: any) => item !== i)) } color="#C5C5C5" /></div>) }</span> }


          <FilterDropDown items={ [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
          ] } title="Семестр" defaultHint="Выберите семестр" onClickHandler={ handleSemesters } withBorder="true" />


          { grade.length > 0 && <span className="filedArray">{ grade?.map((i: any) => <div className="specTag" key={ i } >{ i }<IconCircleX onClick={ () => setGrade(grade.filter((item: any) => item !== i)) } color="#C5C5C5" /></div>) }</span> }


          <FilterDropDown items={ [
            'Бакалавр',
            'Специалист',
            'Магистр',
          ] } title="Степень" defaultHint="Выберите уровень обучения" onClickHandler={ handleGrade } withBorder="true" />


          { eform.length > 0 && <span className="filedArray">{ eform?.map((i: any) => <div className="specTag" key={ i } >{ i }<IconCircleX onClick={ () => setEform(eform.filter((item: any) => item !== i)) } color="#C5C5C5" /></div>) }</span> }


          <FilterDropDown items={ [
            'Очная',
            'Очно-заочная',
            'Заочная',
          ] } defaultHint="Выберите форму обучения" onClickHandler={ handleEforms } withBorder="true" />


          { institute.length > 0 && <span className="filedArray">{ institute?.map((i: any) => <div className="specTag" key={ i } >{ i }<IconCircleX onClick={ () => setInstitute(institute.filter((item: any) => item !== i)) } color="#C5C5C5" /></div>) }</span> }


          <InstitutesField setInstitute={ handleInstitutes } />


          <div className="dateWrap multiple" style={ { width: '100%', } }>
            <span>{ nameSpec?.map((i: any) => <div className="specTag" key={ i }>{ i }<IconCircleX onClick={ () => setNameSpec(nameSpec.filter((item: any) => item !== i)) } color="#C5C5C5" /></div>) }</span>
            <DebounceInput onChange={ e => setSpecValue(e.target.value) } debounceTimeout={ 500 } type="text" placeholder='Специальности студентов' onClick={ () => setCurrentField('Специальности студентов') } value={ specValue } />
            { specValue?.length > 1 && <SpecsList handler={ (i: any) => handleSpeces(i) } currentField={ currentField } value={ specValue } setCurrentField={ () => setCurrentField('Специальности студентов') } field="Специальности студентов" clear={ () => setSpecValue('') } /> }
          </div>


          <div className="dateWrap multiple" style={ {
            width: '100%',
            marginBottom: '15px',
          } }>
            <span>{ nameProf?.map((i: any) => <div className="specTag" key={ i }>{ i }<IconCircleX onClick={ () => setNameprof(nameProf.filter((item: any) => item !== i)) } color="#C5C5C5" /></div>) }</span>


            <DebounceInput onChange={ e => setProfileValue(e.target.value) } debounceTimeout={ 500 } type="text" placeholder='Профили студентов' onClick={ () => setCurrentField('Профили студентов') } value={ profValue } />


            { profValue?.length > 1 && <ProfsSearch handler={ (i: any) => handleProfs(i) } currentField={ currentField } value={ profValue } setCurrentField={ () => setCurrentField('Профили студентов') } field="Профили студентов" clear={ () => setProfileValue('') } /> }
          </div>


          <InputTextDeprecated defaultHint="Группа" value={ group } onChangeValue={ setGroup } styles={ { marginTop: '15px', } } />


          <div className="dateWrap multiple" style={ {
            width: '100%',
            marginBottom: '15px',
          } }>
            <span>{ performer?.map((i: any) => <div className="specTag" key={ i.id }>{ i.full_name }<IconCircleX onClick={ () => setPerformer(performer.filter((item: any) => item.full_name !== i.full_name)) } color="#C5C5C5" /></div>) }</span>


            <DebounceInput required onChange={ e => setPerformerValue(e.target.value) } debounceTimeout={ 300 } type="text" placeholder='студенты' onClick={ () => setCurrentField('исполнитель') } />


            { performerValue?.length > 3 && <Persons mode="person" field="исполнитель" handler={ (i: any) => setPerformer([
              ...performer,
              i,
            ]) } setCurrentField={ () => setCurrentField('исполнитель') } currentField={ currentField } value={ performerValue } clear={ setPerformerValue } /> }
          </div>
        </form>


        <ButtonSimple isDisabled={ disable } variant="fill" value={ data ? 'Редактировать' : 'Добавить' } onClickHandler={ handleCreateFacultative } styles={ {
          width: 'fit-content',
          alignSelf: 'end',
          marginTop: '28px',
        } } />
      </div>
    </Popup>
  )
}

export default AddFacultativePopup

import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    editable: false,
    index: 'number',
  },

  {
    title: 'Набрано по показателю',
    index: 'middle_score_rating',
    editable: false,
    editType: 'input',
    round: '2',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Пороговое значение',
    index: 'thresholdValue',
    indexDescription: 'autoFill',
    editable: false,
    editType: 'input'
  },
  {
    title: 'Начислено баллов',
    index: 'scores',
    indexDescription: 'autoFill',
    editable: false,
    ceil: '0',
  },

  {
    title: 'Статус',
    editable: false,
    index: 'status',
    style: { minWidth: '11rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    editable: false,
    index: 'number',
  },

  {
    title: 'Набрано по показателю',
    index: 'middle_score_rating',
    editable: false,
    editType: 'input',
    round: '2',
    // style: { minWidth: '15rem' },
  },
  {
    title: 'Пороговое значение',
    index: 'thresholdValue',
    indexDescription: 'autoFill',
    editable: false,
  },
  {
    title: 'Начислено баллов',
    index: 'scores',
    indexDescription: 'autoFill',
    editable: false,
    ceil: '0',
  },

  {
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editable: false,
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    style: { minWidth: '20rem', },
    editable: false,
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '11rem', },
    editable: false,
  },
]

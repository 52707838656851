import './Footer.less'
import Logos from './Logos/Logos'
import ColumnInfo from './ColumnInfo/ColumnInfo'
import Contacts from './ColumnInfo/Contacts/Contacts'
import MobileApplicationLinks from './MobileApplicationLinks/MobileApplicationLinks'

const Footer = () => {
  const centers = {
    title: 'Наши центры',
    items: [
      {
        title: 'База отдыха "Галдым"',
        path: 'https://galdym.ru/',
      },
      {
        title: 'Фитнес - центр "Атлетико"',
        path: 'http://atletiko-tambov.ru/',
      },
      {
        title: 'Медицинский центр “Доктор Профи”',
        path: 'https://doctor-profi.ru/',
      },
      {
        title: 'Дворец спорта "Антей"',
        path: 'https://vk.com/club45712929',
      },
    ],
  }

  const about = {
    title: 'Университет ',
    items: [
      {
        title: 'Стратегия развития',
        path: 'https://tsutmb.ru/sveden/common/',
      },
      {
        title: 'Схема  проезда',
        path: 'https://tsutmb.ru/about/rasp/',
      },
      {
        title: '3D тур',
        path: 'https://tsutmb.ru/about/rasp/',
      },
      {
        title: 'О нас',
        path: 'https://tsutmb.ru/sveden/',
      },
    ],
  }

  return (
    <footer className="footer">
      <div style={ { display: 'flex', } }>
        <Logos />

        <ColumnInfo
          style={ {
            width: '202px',
            margin: '0 0 0 122px',
          } }
          items={ centers }
        />

        <ColumnInfo
          style={ {
            width: '138px',
            margin: '0 0 0 60px',
          } }
          items={ about }
        />

        <Contacts />
      </div>

      <MobileApplicationLinks />
    </footer>
  )
}


export default Footer

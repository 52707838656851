import React from 'react'
import Popup from '../Popup/Popup'
import ButtonSimple from '../ButtonSimple/ButtonSimple'
import './DeletePopup.less'


const DeletePopup = ({
  isDeletePopup,
  setIsDeletePopup,
  onClickHandler,
  title,
}: any) => {
  return (
    <Popup isOpen={ isDeletePopup } handlePopup={ () => setIsDeletePopup(!isDeletePopup) }>
      <div className="errorWrap">
        <h3 className="errorTitle">{ title }</h3>

        <p className="confirm">Подтвердите удаление.</p>

        <div style={ {
          display: 'flex',
          justifyContent: 'space-between',
        } }>
          <ButtonSimple styles={ { width: '28%', } } variant="alone" active={ false } value="Отмена" onClickHandler={ () => setIsDeletePopup(!isDeletePopup) } />

          <ButtonSimple styles={ { width: '69%', } } variant="alone" active={ true } value="Удалить" onClickHandler={ onClickHandler } />
        </div>
      </div>
    </Popup>
  )
}

export default DeletePopup

import React from 'react'
import { gql, useQuery, } from '@apollo/client'
import { useSelector, } from 'react-redux'
import AchievementAccordion from '@/legacy/components/EffectiveContract/Tabs/refactor/AchievementAccordion/AchievementAccordion'
import TableSpinner from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/TableSpinner/TableSpinner'

const EC_ACHIEVEMENT_TYPE_BY_DTSUID = gql`
    query Query($dtsuId: String!) {
        ecAchievementTypeByDTSUID(dtsuId: $dtsuId)
    }
`

const DowngradePaymentTab = ({ stage, handlers, }: any) => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)
  const { loading, error, data, } = useQuery(EC_ACHIEVEMENT_TYPE_BY_DTSUID, {
    variables: { dtsuId: currentUserId, },
  })

  if (loading) {
    return <TableSpinner></TableSpinner>
  }
  if (error) {
    return <p>Error: {error.message}</p>
  }

  const firstItem = data?.ecAchievementTypeByDTSUID.find((item: any) => item.achievementTypeName === 'Основания для снижения выплат стимулирующего характера')

  if (!firstItem) {
    return <p>Нет данных</p>
  }

  const children = firstItem?.children || []

  return (
    <div className="down-points-tab-2">
      {children.map((achievement: any) => (
        <AchievementAccordion
          stage={stage}
          handlers={handlers}
          key={achievement.id}
          achievement={achievement}
          moderateId={currentUserId}/>
      ))}
    </div>
  )
}

export default DowngradePaymentTab

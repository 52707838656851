import ButtonSimple from '@/legacy/components/common/ButtonSimple/ButtonSimple'
import Popup from '@/legacy/components/common/Popup/Popup'
import { UpdateControlledField, } from '@/legacy/components/EffectiveContract/TableContainer/AddNewAchievement/AddNewAchievement'
import ParticipantsInput from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/components/ParticipantsInput/ParticipantsInput'
import { OnChangeRowValue, } from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableRow/TableRow'
import { AchievementMixedParticipant, } from '@/legacy/components/EffectiveContract/types/achievementParticipant'
import { IconPencil, } from '@tabler/icons'
import { useState, } from 'react'
import styles from './TableParticipants.module.less'


interface TableParticipantsProps {
  cellValue: AchievementMixedParticipant[]
  cellKey: string
  stringLikeCellValue: string
  onChangeRowValue: OnChangeRowValue
  onUpdateRowHandler: Function
}


const TableParticipants = ({
  cellValue,
  cellKey,
  stringLikeCellValue,
  onChangeRowValue,
  onUpdateRowHandler,
}: TableParticipantsProps): JSX.Element => {
  const [
    isPopupOpen,
    setIsPopupOpen,
  ] = useState(false)


  return (
    <td>
      <span className={ styles.wrapper }>
        <IconPencil
          className={ styles.editIcon }
          onClick={ () => {
            setIsPopupOpen(true)
          } }
        />

        { stringLikeCellValue }
      </span>


      <Popup isOpen={ isPopupOpen } handlePopup={ () => setIsPopupOpen(false) } >
        <>
          <div className={ styles.inputWrapper }>
            <ParticipantsInput
              title={ 'Участники' }
              value={ cellValue }
              valueIndex={ cellKey }
              onChangeRowValue={ onChangeRowValue as UpdateControlledField }
            />
          </div>

          <div className={ styles.buttonWrapper }>
            <ButtonSimple
              active
              variant={ 'alone' }
              value={ 'Сохранить' }
              isDisabled={ cellValue.length < 1 }
              onClickHandler={ () => {
                Promise.resolve(onUpdateRowHandler())
                  .then(() => {
                    setIsPopupOpen(false)
                  })
              } }
            />
          </div>
        </>
      </Popup>
    </td>
  )
}


export default TableParticipants

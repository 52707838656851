import { gql, } from '@/__generated__/gql'
import { useQuery, useMutation, useSubscription, } from '@apollo/client'


const GET_AVATARS = gql(`
query GetUploads {
  uploads(category: "avatars")
}
`)

const DEFAULT_AVATAR = gql(`
  mutation SetDefaultAvatar($upload_id: Int!) {
    defaultAvatar(upload_id: $upload_id)
  }
`)

const DELETE_PHOTO = gql(`
mutation DeleteUpload($upload_ids: [Int]!) {
  deleteUpload(upload_ids: $upload_ids)
}
`)

const AVATARS_CHANGED = gql(`
subscription UploadChanged{
  uploadsChanged
}
`)

const DEFAULT_AVATAR_CHANGED = gql(`
subscription DefaultChanged{
  avatarChanged
}
`)

export const useAvatarsChanged = () => useSubscription(AVATARS_CHANGED)
export const useDefaultAvatarChanged = () => useSubscription(DEFAULT_AVATAR_CHANGED)
export const useDeleteUpload = () => useMutation(DELETE_PHOTO)
export const useDefaultAvatar = () => useMutation(DEFAULT_AVATAR)
export const useAvatarsList = () => useQuery(GET_AVATARS)

import React, { useState, } from 'react'
import './HomeWorkPopup.less'
import clsx from 'clsx'
import { IconPencil, IconPaperclip, } from '@tabler/icons'


function HomeWorkPopup ({
  isOpen,
  close,
  title,
}: any) {
  const [
    status,
    setStatus,
  ] = useState('Тема занятия')
  const [
    disabled,
    setDisabled,
  ] = useState(true)
  const homeButtons = [
    'Тема занятия',
    'Описание занятия',
    'Домашнее задание',
  ]

  function ThemeInput () {
    return (
      status === 'Тема занятия' ? <div className="editLecture">
        <input type="text" defaultValue={ title } disabled={ disabled } />
        <IconPencil onClick={ () => setDisabled(!disabled) } color="#C5C5C5" style={ { cursor: 'pointer', } } />
      </div> : <></>
    )
  }

  function LectureDiscription () {
    return (
      status === 'Описание занятия' ? <div style={ { position: 'relative', } }>
        <textarea className="discription" placeholder='Описание занятия'></textarea>
        <div className="clipWrap"><IconPaperclip color="#7F8191" /></div>
      </div> : <></>
    )
  }

  function HomeWork () {
    return (
      status === 'Домашнее задание' ? <div style={ { position: 'relative', } }>
        <textarea className="discription" placeholder='Домашнее Задание'></textarea>
        <div className="clipWrap"><IconPaperclip color="#7F8191" /></div>
      </div> : <></>
    )
  }

  return (
    <div onClick={ close } className={ clsx('K-LecturePop-Close', 'homework', isOpen && 'K-LecturePop') }>
      <div className="LectureContainer" onClick={ e => e.stopPropagation() }>
        <button className="close" onClick={ close }>x</button>
        <h2>Подробная информация о занятии</h2>
        <div className="homeButons">
          { homeButtons.map(i => {
            return <button className={ clsx({ 'active': i === status, }) } key={ homeButtons.indexOf(i) } onClick={ () => setStatus(i) }>{ i }</button>
          }) }
        </div>
        <ThemeInput />
        <LectureDiscription />
        <HomeWork />
        <button className="submit">Применить изменения</button>
      </div>
    </div>
  )
}

export default HomeWorkPopup

import styles from './ButtonsElement.module.less'
import { IconPencil, IconTrash, } from '@tabler/icons'
import clsx from 'clsx'
import { TableCellType, } from '@/legacy/types'
import { useState, } from 'react'
import EditAchievementPopup from '@/legacy/components/EffectiveContract/cards/CardAchievement/components/ButtonsElement/components/EditAchievementPopup/EditAchievementPopup'


interface ButtonsElementProps {
  recId: number
  dataType: string
  onDeleteEcDataHandler: Function
  achievementValues: { [key: string]: unknown }
  tableSource: TableCellType[] | undefined
}


const ButtonsElement = ({
  recId,
  dataType,
  onDeleteEcDataHandler,
  achievementValues,
  tableSource = [],
}: ButtonsElementProps) => {
  const [
    isPopupOpen,
    setIsPopupOpen,
  ] = useState(false)


  return (
    <>
      <div className={ styles.buttonsWrapper }>
        <button
          className={ clsx(styles.button, styles.button_edit) }
          type='button'
          onClick={ () => setIsPopupOpen(true) }
        >
          <IconPencil />
        </button>


        <button
          className={ clsx(styles.button, styles.button_delete) }
          type='button'
          onClick={ () => onDeleteEcDataHandler({ rec_id: recId,
            dataType, }) }
        >
          <IconTrash />
        </button>
      </div >


      { isPopupOpen && (
        <EditAchievementPopup
          isPopupOpen={ isPopupOpen }
          tableSource={ tableSource }
          achievementValues={ achievementValues }
          onClosePopup={ () => setIsPopupOpen(false) }
        />
      ) }
    </>
  )
}


export default ButtonsElement

import { useMutation, } from '@apollo/client'
import { EDIT_AVHIEVEMENT_DIRECTION, } from './useCreateAchievementDirection'


const useRenameAchievementDirection = () => {
  const [
    onUpdate,
  ] = useMutation(EDIT_AVHIEVEMENT_DIRECTION)

  const onUpdateHandler = ({
    newName,
    ...rest
  }: any) => onUpdate({
    variables: {
      input: {
        ...rest,
        name: newName,
      },
    },
  })


  return onUpdateHandler
}


export default useRenameAchievementDirection

import { useMemo, useState, useCallback, } from 'react'
import './RatingTable.less'
import { Table, } from 'antd'
import TooltipTgu from '@/shared/ui/TooltipTgu/TooltipTgu'
import PaginationSimple from '../../../common/PaginationSimple/PaginationSimple'
import ChooseField from '../../../common/Fields/ChooseField/ChooseField'
import { useDispatch, useSelector, } from 'react-redux'
import useGetStudentsWithAchievementCount from '../../../../gql/hooks/portfolio/useGetStudentsWithAchievementCount'
import { format, } from 'date-fns'
import { useLocation, } from 'react-router-dom'
import useAllRating from '../../../../gql/hooks/portfolio/useAllRating'
import { defineSection, } from '../RatingFilters/RatingFilters'
import useAchievementDirections from '../../../../gql/hooks/portfolio/useAchievementDirections'
import useRedirect from '@/legacy/hooks/useRedirect'
import { TooltipPlacementVariant, } from '@/shared/ui/TooltipTgu/types'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { setRatingId, setSortTable, } from '@/legacy/redux/PortfolioSlices/PortfolioSlice'


function RatingTable ({
  moderContent,
}: any) {
  const redirect = useRedirect()

  const userRole = useGetRole()
  const [
    itemsPerPage,
    setItemsPerPage,
  ] = useState(100)
  const [
    offset,
    setOffset,
  ] = useState(0)
  const [
    paginationCurrentPage,
    setPaginationCurrentPage,
  ] = useState(1)
  const { pathname, } = useLocation()
  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const sort = useSelector(state => state.port.tableSort.rating)

  // @ts-ignore: Unreachable code error
  const achievementDirections = useAchievementDirections()

  const {
    items,
    pagination,
    loading,
  }: any = (function useDefineSection () {
    const ratingData = useAllRating({
      offset,
      limit: itemsPerPage,
      // moderator_mode: !/^student/.test(userRole),
      moderator_mode: true,
    })

    const portfolioData = useGetStudentsWithAchievementCount({
      offset,
      limit: itemsPerPage,
    })


    if (pathname === '/portfolio-rating') {
      return { ...ratingData, }
    }

    if (pathname === '/moder-portfolio') {
      return { ...portfolioData, }
    }
  }())


  const tableHeaderDirections = useMemo(() => {
    return achievementDirections?.directions?.map((direction: any) => {
      if (direction.name !== 'Работы обучающегося') {
        return {
          width: 150,
          title: (
            <TooltipTgu
              children={
                <div className="thWrap">
                  <h3 className="tableTitle">{ direction.name }</h3>
                </div>
              }
              title={direction.name}
              placement={TooltipPlacementVariant.bottom}/>
          ),
          dataIndex: String(direction.key),
          key: String(direction.key),
          sorter: true,
          showSorterTooltip: false,
          defaultSortOrder: sort.field === `dir_${ direction.key }` && (sort.order === 'desc' ? 'descend' : 'ascend'),
        };
      }
      return null;
    }).filter(Boolean); // фильтруем null значения
  }, [
    achievementDirections?.directions,
    sort.field,
    sort.order,
  ]);


  const handleRatingById = useCallback((userItem: any) => {
    if (pathname === '/moder-portfolio') {
      redirect('/moderate-achivments','', true)
      dispatch(setRatingId(userItem))
    } else if (pathname === '/portfolio-rating') {
      redirect('/personal-rating','', true)
      dispatch(setRatingId(userItem))
    }
  }, [
    dispatch,
    pathname,
    redirect,
  ])


  const content = useMemo(() => {
    const columns = [
      {
        title: 'Место в рейтинге',
        width: 100,
        dataIndex: 'position',
        key: 'position',
        fixed: 'left',
      },
      {
        title: 'ФИО ',
        width: 350,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
      },
      ...tableHeaderDirections,
      {
        width: 100,
        title: 'Всего',
        dataIndex: 'total',
        key: 'total',
        sorter: true,
        showSorterTooltip: false,
        defaultSortOrder: sort.field === 'total' && (sort.order === 'desc' ? 'descend' : 'ascend'),
        fixed: 'right',
      },
    ]


    const onChange = (pagination: any, filters: any, sorter: any) => {
      const field = (() => {
        if (/\d/.test(sorter.field)) {
          return `dir_${ sorter.field }`
        } return sorter.field
      })()

      const sort = {
        field,
        order: sorter.order,
      }

      if (sorter.order === 'ascend') {
        sort.order = 'asc'
      }

      if (sorter.order === 'descend') {
        sort.order = 'desc'
      }

      if (!sorter.order) {
        sort.field = (defineSection(pathname) === 'rating') ? 'fio' : 'full_name'
        sort.order = 'asc'
      }


      dispatch(setSortTable({
        tableName: defineSection(pathname),
        newSort: { ...sort, },
      }))
    }


    if (loading) {
      return <>loading...</>
    }
    const content = items.map((item: any) => {
      const tableDirections = {}
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      tableHeaderDirections?.forEach((direction: any) => tableDirections[direction?.key] = 0)

      const itemEntries = Object.entries(item)
      for (const [
        key,
        value,
      ] of itemEntries) {
        if (value !== null && /^dir_/.test(key)) {
          const DirectionKey = key.split('_')?.at(-1)
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          tableDirections[DirectionKey] = value?.points
        }
      }
      const isStudentActive = item?.info?.is_student_rating_available_all
      if (defineSection(pathname) === 'rating') {
        return {
          ...tableDirections,
          key: item?.info?.position || item?.info?.id,
          name: (
            !/^student/.test(userRole) || isStudentActive ?
              <div className="needGuid" onClick={() => handleRatingById(item)}>{item?.info?.fio}</div> :
              <div className="inactiveStudent">{item?.info?.fio}</div>
          ),
          total: item?.info?.total,
          position: item?.info?.position,
        }
      }
      return {
        ...item,
        key: item?.id,
        name: <div className="needGuid" onClick={ () => handleRatingById(item) }>{ item?.full_name }</div>,
        last_activity: item?.last_activity && format(new Date(item?.last_activity), 'dd.MM.yyyy'),
      }
    })


    return <>
      <Table
        pagination={ false }
        columns={ moderContent ? moderContent : columns }
        dataSource={ content }
        onChange={ onChange }
        scroll={ {
          x: 0,
        } }
      />

      <div className="paginationWrap">
        <PaginationSimple
          count={ Math.round(pagination?.total / itemsPerPage) }
          onChange={ (event: any, newPageNumber: any) => {
            setOffset((newPageNumber - 1) * itemsPerPage)
            setPaginationCurrentPage(newPageNumber)
          } }
          page={ paginationCurrentPage }
        />

        <div className="limitWrap">
          Записей на странице
          <ChooseField
            items={ [
              20,
              40,
              60,
              80,
              100,
            ] }
            value={ itemsPerPage }
            handler={ setItemsPerPage }
            styles={ {
              width: '140px',
              marginLeft: '20px',
              padding: '15px',
            } }
          />
        </div>
      </div>
    </>
  }, [
    tableHeaderDirections,
    sort.field,
    sort.order,
    loading,
    dispatch,
    pathname,
    items,
    moderContent,
    pagination?.total,
    itemsPerPage,
    paginationCurrentPage,
    handleRatingById,
  ])


  return (
    <div className="ratingTable">
      { !moderContent && <h3>Отчетный период документов, участвующих в рейтинге с 16 апреля 2024г. по 15 апреля 2025г.</h3> }

      { content }
    </div>
  )
}


export default RatingTable

import onBlurHandler from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/AchievementDataCell/components/TableTextArea/handlers/onBlurHandler'
import onChangeHandler from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/AchievementDataCell/components/TableTextArea/handlers/onChangeHandler'
import { OnChangeRowValue, } from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableRow/TableRow'
import { TextareaAutosize, } from '@mui/material'
import { useRef, useState, } from 'react'
import styles from './TableTextArea.module.less'


interface TableTextAreaProps {
  cellKey: string
  cellValue: string
  onChangeRowValue: OnChangeRowValue
  onUpdateRowHandler: Function
  isNumbersOnly?: boolean
}


const TableTextArea = ({
  cellKey,
  cellValue,
  onChangeRowValue,
  onUpdateRowHandler,
  isNumbersOnly,
}: TableTextAreaProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [
    originValue,
    setOriginValue,
  ] = useState(cellValue)


  return (
    <td onBlur={ () => onBlurHandler(inputRef, onUpdateRowHandler, originValue, setOriginValue) }>
      <TextareaAutosize
        ref={ inputRef }
        className={ styles.TableTextArea }
        value={ cellValue }
        onChange={ event => onChangeHandler(event, Boolean(isNumbersOnly), onChangeRowValue, cellKey) }
      />
    </td>
  )
}


export default TableTextArea

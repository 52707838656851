export const duty = [
  {
    name: 'О направлении сотрудника в командировку',
    id: '20f37e57-7243-11ed-bba9-005056aa81dd',
    fields: [
      {
        type: 'person',
        label: 'Укажите сотрудника',
        jsonKey: 'EmployeeGuid',
      },
      {
        type: 'date',
        label: 'Дата прибытия',
        jsonKey: 'DepartureDate',
      },
      {
        type: 'date',
        label: 'Дата убытия',
        jsonKey: 'ArrivalDate',
      },
      {
        type: 'text',
        label: 'Укажите город назначения',
        jsonKey: 'DestinationCity',
      },
      {
        type: 'listOfFields',
        title: 'Командировочные расходы:',
        children: [
          {
            type: 'checkBox',
            label: 'проезд',
            jsonKey: 'Travel',
            default: { value: false, },
          },
          {
            type: 'checkBox',
            label: 'проживание',
            jsonKey: 'Accommodation',
            default: { value: false, },
          },
          {
            type: 'checkBox',
            label: 'Иные расходы',
            jsonKey: 'OtherExpenses',
            default: { value: false, },
          },
          {
            type: 'text',
            dependency: 'OtherExpenses',
            label: 'Введите иные расходы',
            jsonKey: 'OtherExpensesText',
          },
          {
            type: 'checkBox',
            label: 'суточные',
            jsonKey: 'DailyAllowance',
            default: { value: false, },
          },
          {
            type: 'checkBox',
            label: 'проезд на личном транспорте с оплатой ГСМ',
            jsonKey: 'PaymentForFuel',
            default: { value: false, },
          },
          {
            type: 'checkBox',
            label: 'с предоставлением служебного автотранспорта по маршруту',
            jsonKey: 'ProvisionCompanyVehicles',
            default: { value: false, },
          },
          {
            type: 'text',
            dependency: 'ProvisionCompanyVehicles',
            label: 'Укажите маршрут поездки',
            jsonKey: 'ProvisionCompanyVehiclesText',
          },
        ],
      },
      {
        type: 'listOfFields',
        title: 'Укажите источник финансового обеспечения',
        children: [
          {
            type: 'checkBox',
            label: 'за счет средств от приносящей доход деятельности',
            jsonKey: 'IncomeGeneratingActivities',
            default: { value: false, },
          },
          {
            type: 'checkBox',
            label: 'за счет средств субсидии на выполнение государственного задания',
            jsonKey: 'GovernmentSubsidies',
            default: { value: false, },
          },
          {
            type: 'checkBox',
            label: 'за счет средств принимающей стороны',
            jsonKey: 'HostParty',
            default: { value: false, },
          },
          {
            type: 'checkBox',
            label: 'за счет средств гранта №',
            jsonKey: 'GrantFunds',
            default: { value: false, },
          },
          {
            type: 'text',
            dependency: 'GrantFunds',
            label: 'Укажите номер гранта',
            jsonKey: 'GrantNumber',
          },
        ],
      },
      {
        type: 'switch',
        label: 'Занятия в данный период не осуществляются/График переноса занятий прилагается',
        jsonKey: 'ReschedulingClasses',
        default: { value: false, },
        role: 'worker',
      },
      {
        type: 'file',
        label: 'прикрепить график',
        jsonKey: 'SchedulePostponementOfClasses',
        dependency: 'ReschedulingClasses',
      },
      {
        type: 'switch',
        label: 'Уставная деятельность/Иное',
        default: { value: false, },
        jsonKey: 'StatutoryActivities',
      },
    ],
  },
  {
    name: 'О направлении студента в поездку',
    id: '20f37e57-7243-11ed-bba9-005056aa81dd',
    fields: [
      {
        type: 'personList',
        label: 'Укажите студентов направляемых в поездку',
        jsonKey: 'StudentsFIO',
      },
      {
        type: 'date',
        label: 'Дата убытия',
        jsonKey: 'StartTrip',
      },
      {
        type: 'date',
        label: 'Дата прибытия',
        jsonKey: 'EndDate',
      },
      {
        type: 'text',
        label: 'Укажите место назначения',
        jsonKey: 'Destination',
      },
      {
        type: 'text',
        label: 'Укажите цель организации поездки студентов',
        jsonKey: 'PurposeStudentTrip',
      },
      {
        type: 'personList',
        label: 'Укажите ответственное лицо за организацию поездки',
        jsonKey: 'EmployeeSubmittingMemoGuid',
      },
      {
        type: 'listOfFields',
        title: 'Расходы на проезд:',
        children: [
          {
            type: 'int',
            label: 'Количество (чел.)',
            jsonKey: 'TravelPerson',
          },
          {
            type: 'int',
            label: 'Стоимость за 1 чел. (руб.)',
            jsonKey: 'TravelCost',
          },
          {
            type: 'int',
            label: 'Всего расходов (руб.)',
            jsonKey: 'TravelExpenses',
            multiple: [
              'TravelPerson',
              'TravelCost',
            ],
          },
        ],
      },
      {
        type: 'listOfFields',
        title: 'Расходы на проживание:',
        children: [
          {
            type: 'int',
            label: 'Количество (чел.)',
            jsonKey: 'AccommodationPerson',
          },
          {
            type: 'int',
            label: 'Стоимость за 1 чел. (руб.)',
            jsonKey: 'AccommodationCost',
          },
          {
            type: 'int',
            label: 'Всего расходов (руб.)',
            jsonKey: 'AccommodationExpenses',
            multiple: [
              'AccommodationPerson',
              'AccommodationCost',
            ],
          },
        ],
      },
      {
        type: 'listOfFields',
        title: 'Расходы на суточные:',
        children: [
          {
            type: 'int',
            label: 'Количество (чел.)',
            jsonKey: 'DailyAllowancePerson',
          },
          {
            type: 'int',
            label: 'Стоимость за 1 чел. (руб.)',
            jsonKey: 'DailyAllowanceCost',
          },
          {
            type: 'int',
            label: 'Всего расходов (руб.)',
            jsonKey: 'DailyAllowanceExpenses',
            multiple: [
              'DailyAllowancePerson',
              'DailyAllowanceCost',
            ],
          },
        ],
      },
      {
        type: 'listOfFields',
        title: 'Расходы на организационный взнос за участие:',
        children: [
          {
            type: 'int',
            label: 'Количество (чел.)',
            jsonKey: 'ArrangementFeePerson',
          },
          {
            type: 'int',
            label: 'Стоимость за 1 чел. (руб.)',
            jsonKey: 'ArrangementFeeCost',
          },
          {
            type: 'int',
            label: 'Всего расходов (руб.)',
            jsonKey: 'ArrangementFeeExpenses',
            multiple: [
              'ArrangementFeePerson',
              'ArrangementFeeCost',
            ],
          },
        ],
      },
      {
        type: 'int',
        label: 'Всего расходов за поездку',
        jsonKey: 'TotalExpenses',
        sum: [
          'ArrangementFeeExpenses',
          'DailyAllowanceExpenses',
          'AccommodationExpenses',
          'TravelExpenses',
        ],
      },
      {
        type: 'file',
        label: 'Приложите документы основания для организации поездки',
        jsonKey: 'AttachedDocumentBasis',
      },
    ],
  },
  {
    name: 'Служебная записка на закупку',
    id: '20f37e57-7243-11ed-bba9-005056aa81dd',
    fields: [
      {
        type: 'text',
        label: 'Укажите обоснование закупки (причину)',
        jsonKey: 'PurchaseJustification',
      },
      {
        type: 'text',
        label: 'Укажите наименование товара/услуги/работы',
        jsonKey: 'PurchaseName',
      },
      {
        type: 'text',
        label: 'Укажите необходимые характеристики товара/услуги (описание функциональных и технических характеристик)',
        jsonKey: 'RequiredCharacteristics',
      },
      {
        type: 'select',
        items: [
          'штука',
          'пачка',
          'упаковка',
          'пакет',
          'метр погонный',
          'метр',
          'рулон',
          'грамм',
          'килограмм',
          'мешок',
          'коробка',
          'ящик',
          'банка',
          'свой вариант',
        ],
        label: 'Укажите единицу измерения',
        jsonKey: 'UnitMeasurement',
      },
      {
        type: 'text',
        label: 'Укажите свой вариант ед. измерения',
        jsonKey: 'UnitMeasurement',
        dependendOnValue: {
          dep: 'UnitMeasurement',
          value: 'свой вариант',
        },
      },
      {
        type: 'int',
        label: 'Укажите количество',
        jsonKey: 'NumberUnits',
      },
      {
        type: 'int',
        label: 'Укажите ориентировочную цену за единицу товара',
        jsonKey: 'UnitPrice',
      },
      {
        type: 'int',
        label: 'Общая сумма',
        jsonKey: 'PriceEverything',
        multiple: [
          'NumberUnits',
          'UnitPrice',
        ],
      },
      {
        type: 'text',
        label: 'Укажите место поставки/установки/выполнения',
        jsonKey: 'DeliveryAddress',
      },
      {
        type: 'date',
        label: 'Укажите дату поставки',
        jsonKey: 'DeliveryTime',
      },
      {
        type: 'text',
        label: 'При необходимости дополните информацию по закупке примечанием. Например: срок гарантии, доп. условия, разгрузка/погрузка, подъем на этаж и т.д.',
        jsonKey: 'PurchaseNote',
      },
    ],
  },
]

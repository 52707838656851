/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, } from 'react'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { useGetActiveStats, useUpdateActiveStatus, } from '../../../../../gql/hooks/useMyEducation'
import { useAppContext, } from '@/app/providers/AppProvider'


const ElectiveSignAllow = () => {
  const { data, refetch, } = useGetActiveStats('elective')

  const isAllActive = useMemo(() => {
    if (data?.esDisciplinesState) {
      const { total, active, } = data.esDisciplinesState

      return total === active
    } return true
  }, [
    data,
  ])


  const [
    esDisciplineActivate,
  ] = useUpdateActiveStatus(!isAllActive, 'elective')

  const { openToast, } = useAppContext()

  const handleActiveStatus = () => {
    esDisciplineActivate()
      .then(() => {
        openToast?.({ message: isAllActive ? 'Запись закрыта' : 'Запись открыта', })
        refetch()
      })
      .catch(e => console.log(e))
  }

  return <ButtonSimple value={ isAllActive ? 'Закрыть запись' : 'Открыть запись' }
    variant="fill" onClickHandler={ handleActiveStatus } />
}

export default ElectiveSignAllow

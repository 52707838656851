import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'

const GET_NOTIFICATION_FILTERS = gql`
  query NotificationDraftFilters {
    notificationDraftFilters
  }
`


const useGetNotificationfilters = () => {
  const { loading, data, } = useQuery(GET_NOTIFICATION_FILTERS)

  const result = useMemo(() => {
    if (!loading && data) {
      const currentData = data.notificationDraftFilters[0]

      const newData = {
        ...currentData,
        roles: currentData?.roles?.filter((role: any) => role !== 'guids'),
      }

      return newData
    }
  }, [
    data,
    loading,
  ])

  return {
    ...result,
  }
}


export default useGetNotificationfilters

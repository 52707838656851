import { TableCellType, } from '@/legacy/types'
import { table_cat_2_7, } from '../../popUpInputsCatalogs'

export const tableSource: TableCellType[] = [
  {
    editable: false,
    title: '№',
    index: 'number',
  },
  {
    title: 'Название статьи',
    index: 'article',
    editable: true,
    editType: 'input',
    style: { minWidth: '15rem', },
  },
  {
    title: 'Авторы',
    index: 'authors',
    editable: true,
    editType: 'participants',
    style: { minWidth: '12rem', },
  },
  {
    title: 'Источник',
    index: 'journal',
    editable: true,
    editType: 'input',
    style: { minWidth: '12rem', },
  },
  {
    title: 'Год публикации',
    index: 'publication_year',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Количество авторов',
    index: 'countAuthors',
    editType: 'inputNumbers',
    editable: true,
  },
  {
    title: 'Соавторы',
    index: 'foreign_authors',
    indexDescription: 'fromBooleanType',
    is_2_7_alternativeSelect: true,
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_7.foreign_authors,
    style: { minWidth: '12rem', },
  },
  {
    title: 'Квартиль',
    index: 'quartileNumber',
    indexDescription: 'quartileType',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_7.quartileNumber,
  },
  // {
  //   title: 'Подтверждающий документ',
  //   index: 'document',
  //   editable: true,
  //   editType: 'upload',
  //   style: { minWidth: '10rem', },
  // },
  {
    title: 'Тип публикации',
    index: 'type_work',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_7.publication_type,
  },
  {
    title: 'Тип источника',
    index: 'source_type',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_7.source_type,
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: false,
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '8rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Название статьи',
    index: 'article',
    editable: true,
    editType: 'input',
    style: { minWidth: '20rem', },
  },
  {
    title: 'Авторы',
    index: 'authors',
    editable: true,
    editType: 'input',
    style: { minWidth: '15rem', },
  },
  {
    title: 'Источник',
    index: 'journal',
    editable: true,
    editType: 'input',
    style: { minWidth: '30rem', },
  },
  {
    title: 'Год публикации',
    index: 'publication_year',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Количество авторов',
    index: 'countAuthors',
    editType: 'inputNumbers',
    editable: true,
  },
  {
    title: 'Соавторы',
    index: 'foreign_authors',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_7.foreign_authors,
    style: { minWidth: '8rem', },
  },
  {
    title: 'Квартиль',
    index: 'quartileNumber',
    indexDescription: 'quartileType',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_7.quartileNumber,
  },
  // {
  //   title: 'Подтверждающий документ',
  //   index: 'document',
  //   editable: true,
  //   editType: 'upload',
  //   style: { minWidth: '10rem', },
  // },
  {
    title: 'Тип публикации',
    index: 'publication_type',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_7.publication_type,
  },
  {
    title: 'Тип источника',
    index: 'source_type',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_7.source_type,
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    style: { minWidth: '20rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
    editable: false,
  },
]

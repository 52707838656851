/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  PGDate: any;
  Timestamp: any;
  /** The `UUID` scalar type represents UUID values as specified by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};

export type AccruedBlock = {
  __typename?: 'AccruedBlock';
  date?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sum?: Maybe<Scalars['String']>;
};

export type AccruedScholarship = {
  __typename?: 'AccruedScholarship';
  blocks?: Maybe<AccruedScholarshipBlock>;
  period?: Maybe<Scalars['String']>;
  rec_id?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['String']>;
};

export type AccruedScholarshipBlock = {
  __typename?: 'AccruedScholarshipBlock';
  block_accruals?: Maybe<Array<Maybe<AccruedBlock>>>;
  block_holdings?: Maybe<Array<Maybe<AccruedBlock>>>;
  block_payout?: Maybe<Array<Maybe<AccruedBlock>>>;
};

export type AccruedScholarshipInput = {
  id?: InputMaybe<Scalars['UUID']>;
  mode?: InputMaybe<AccruedScholarshipMode>;
  period?: InputMaybe<Scalars['String']>;
};

export enum AccruedScholarshipMode {
  All = 'all',
  Last = 'last'
}

export type AchievementFullFilter = {
  course?: InputMaybe<Scalars['Int']>;
  dateEnd?: InputMaybe<Scalars['String']>;
  dateStart?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<Array<Scalars['String']>>;
  eform?: InputMaybe<Scalars['String']>;
  elevel?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  full_name?: InputMaybe<Scalars['String']>;
  institute?: InputMaybe<Scalars['String']>;
  namespec?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<Scalars['String']>>;
};

export type AchievementHeader = {
  comment?: InputMaybe<Scalars['JSON']>;
  /**  achievement.catid (direction.key)  */
  direction_key: Scalars['Int'];
  status: AchievementStatus;
  /**  achievement.template_id (type.key)  */
  type_key: Scalars['Int'];
  userpoints?: InputMaybe<Scalars['Int']>;
};

export enum AchievementStatus {
  Archived = 'archived',
  Available = 'available',
  Notavailable = 'notavailable',
  Rejected = 'rejected',
  Suspend = 'suspend'
}

export type AchievementsFilter = {
  achievement_id?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  dateEnd?: InputMaybe<Scalars['PGDate']>;
  dateStart?: InputMaybe<Scalars['PGDate']>;
  direction?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['UUID']>;
  pointsMax?: InputMaybe<Scalars['Int']>;
  pointsMin?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<AchievementStatus>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AchievementsInput = {
  filter?: InputMaybe<AchievementsFilter>;
  pagination: PaginationType;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

export type AchievementsRatingInput = {
  filter?: InputMaybe<AchievementFullFilter>;
  moderator_mode?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

export enum AcievementTypeStatus {
  Available = 'available',
  Notavailable = 'notavailable'
}

export type AverageGradeInput = {
  id?: InputMaybe<Scalars['UUID']>;
  recordbook_guid?: InputMaybe<Scalars['UUID']>;
  recordbook_number?: InputMaybe<Scalars['String']>;
};

export enum CategoryName {
  Effectivecontract = 'effectivecontract',
  Portfolio = 'portfolio',
  Projectactivity = 'projectactivity'
}

export type ChangeTypeDirectionInput = {
  direction_key: Scalars['Int'];
  key: Scalars['Int'];
  new_direction_key: Scalars['Int'];
};

export type ComputeScoresInput = {
  dataType: Scalars['String'];
  user_id: Scalars['UUID'];
};

export type ContactInformation = {
  __typename?: 'ContactInformation';
  kind_contact_information?: Maybe<Scalars['String']>;
  represent?: Maybe<Scalars['String']>;
  type_contact_information?: Maybe<Scalars['String']>;
};

export type CreateAchievementInput = {
  fields: Scalars['JSON'];
  header: AchievementHeader;
  user_id?: InputMaybe<Scalars['UUID']>;
};

export type CreateFacultativeInput = {
  changed?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['String']>;
  data: Scalars['JSON'];
  description: Scalars['String'];
  facultative_id?: InputMaybe<Scalars['Int']>;
  is_active: Scalars['Boolean'];
  is_visible: Scalars['Boolean'];
  level?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  max_participants?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  students?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<DisciplineType>;
};

export type CreateTrackInput = {
  data: Scalars['JSON'];
  description?: InputMaybe<Scalars['String']>;
  is_active: Scalars['Boolean'];
  is_visible: Scalars['Boolean'];
  max_participants: Scalars['Int'];
  students?: InputMaybe<Scalars['JSON']>;
  upload_id: Scalars['Int'];
};

export type DataFl = {
  __typename?: 'DataFL';
  contact_information?: Maybe<Array<Maybe<ContactInformation>>>;
  country?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['PGDate']>;
  document_date_issue?: Maybe<Scalars['PGDate']>;
  document_department_code?: Maybe<Scalars['String']>;
  document_number?: Maybe<Scalars['String']>;
  document_series?: Maybe<Scalars['String']>;
  document_type?: Maybe<DocumentType>;
  document_type_notion?: Maybe<Scalars['String']>;
  document_was_issued_by?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  partner_asu?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  personal_accounts?: Maybe<Scalars['JSON']>;
  place_of_birth?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  snils?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export type DataTypeInput = {
  major?: InputMaybe<Scalars['Int']>;
  minor?: InputMaybe<Scalars['Int']>;
};

export type DataTypeSelector = {
  ext?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  major: Scalars['Int'];
  minor?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type DebugPersonsInput = {
  count?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  type: DebugPersonsType;
};

export enum DebugPersonsType {
  DormitoryContracts = 'dormitory_contracts',
  DormitoryPayments = 'dormitory_payments',
  EducationPaymentHistory = 'education_payment_history',
  Persons = 'persons',
  Recordbooks = 'recordbooks',
  Scholarship = 'scholarship'
}

export type DeleteFacultativeInput = {
  facultative_id: Scalars['Int'];
};

export type DepartmentListInput = {
  filter?: InputMaybe<DepartmentListInputFilter>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<SortField>>;
};

export type DepartmentListInputFilter = {
  level?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  /**  получить детей по паренту  */
  parent_id?: InputMaybe<Scalars['UUID']>;
};

export type DepartmentSpecsInput = {
  /**  department_id  */
  department_id: Scalars['UUID'];
  type: SpecListType;
};

export type Discipline = {
  __typename?: 'Discipline';
  ep_id?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  module?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  semester?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export enum DisciplineGroup {
  Namespec = 'namespec',
  Type = 'type',
  Views = 'views'
}

export type DisciplineProcedure = {
  __typename?: 'DisciplineProcedure';
  procedure_data?: Maybe<Scalars['JSON']>;
  procedure_info?: Maybe<Scalars['String']>;
  type?: Maybe<DisciplineType>;
};

export type DisciplineStatsFilter = {
  group?: InputMaybe<Array<DisciplineGroup>>;
  namespec?: InputMaybe<Scalars['String']>;
  ref_id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<DisciplineType>;
};

export type DisciplineStatsInput = {
  filter?: InputMaybe<DisciplineStatsFilter>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

export enum DisciplineType {
  Elective = 'elective',
  Facultative = 'facultative',
  Miner = 'miner'
}

export type DisciplinesBySemestersFilter = {
  namespec?: InputMaybe<Scalars['String']>;
  semester?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<Scalars['String']>>;
};

export type DisciplinesBySemestersInput = {
  filter?: InputMaybe<DisciplinesBySemestersFilter>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
  /**  Не обязательный параметр  */
  user_id?: InputMaybe<Scalars['UUID']>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type EsbLogSelectorInput = {
  TypeMessage?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  msg_id?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sync?: InputMaybe<Scalars['Boolean']>;
};

export type EditAchievementDirectionInput = {
  /**  Доступно ?  */
  available?: InputMaybe<Scalars['Boolean']>;
  /**  Если установлен - то изменение/удаление, без него - добавление   */
  key?: InputMaybe<Scalars['Int']>;
  /**  Если нет, но есть key - то удаление, иначе как обычно  */
  name?: InputMaybe<Scalars['String']>;
  /**  default 0  */
  order?: InputMaybe<Scalars['Int']>;
  /**  по умолчанию student  */
  type?: InputMaybe<Scalars['String']>;
};

export type EditAchievementTypeInput = {
  /**   default = false  */
  detached?: InputMaybe<Scalars['Boolean']>;
  /**  Направление  */
  direction_key: Scalars['Int'];
  /**  Extra  */
  extra?: InputMaybe<Scalars['JSON']>;
  /**  Если не указан, то удадение по [key,direction_key]   */
  jsondata?: InputMaybe<Scalars['JSON']>;
  /**  Если не указан - до добавление, иначе - изменение по [key,direction_key]  */
  key?: InputMaybe<Scalars['Int']>;
  /**  Для удаления поля ниже не учавствуют   */
  name?: InputMaybe<Scalars['String']>;
  /**   default = true  */
  need_confirm?: InputMaybe<Scalars['Boolean']>;
  points?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<AcievementTypeStatus>;
};

export type EditDisciplineProcedureInput = {
  procedureData?: InputMaybe<Scalars['JSON']>;
  procedureInfo: Scalars['String'];
  type: DisciplineType;
};

export type EducationContract = {
  __typename?: 'EducationContract';
  actual?: Maybe<Scalars['Boolean']>;
  contract?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  debt?: Maybe<Scalars['String']>;
  educationType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  hasPenalties?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  isLegal?: Maybe<Scalars['Boolean']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  penalties?: Maybe<Scalars['String']>;
  refreshDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type EducationContractsInput = {
  id?: InputMaybe<Scalars['UUID']>;
};

export type EducationPlan = {
  __typename?: 'EducationPlan';
  actual_time_study_month?: Maybe<Scalars['Int']>;
  actual_time_study_year?: Maybe<Scalars['Int']>;
  admission_end?: Maybe<Scalars['Int']>;
  admission_start?: Maybe<Scalars['Int']>;
  basic_time_study_month?: Maybe<Scalars['Int']>;
  basic_time_study_year?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['PGDate']>;
  disciplineList?: Maybe<Array<Maybe<Discipline>>>;
  eform?: Maybe<Scalars['String']>;
  elevel?: Maybe<Scalars['String']>;
  ep_id?: Maybe<Scalars['UUID']>;
  ep_number?: Maybe<Scalars['String']>;
  individual_plan?: Maybe<Scalars['Boolean']>;
  kind_of_iducation?: Maybe<KindOfEducation>;
  nameprof?: Maybe<Scalars['String']>;
  namespec?: Maybe<Scalars['String']>;
  qualification?: Maybe<Qualification>;
  standard_designation_code?: Maybe<Scalars['UUID']>;
  zet_per_week?: Maybe<Scalars['String']>;
};

export type EducationPlanDisciplinesFilter = {
  discipline?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  is_miner?: InputMaybe<Scalars['Boolean']>;
  /**
   * Нагрузка (Самостоятельная работа,Зачет)
   * строка или регулярка
   */
  load_name?: InputMaybe<Scalars['String']>;
  semester?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /**
   * Родитель
   * Тоже может быть регуляркой
   */
  study_parent_plan?: InputMaybe<Scalars['String']>;
  /**
   * Название плана  ( Б1.В )
   * строка или регулярка.
   * 'Б1.(w).(w)+.(01)+'
   */
  study_plan?: InputMaybe<Scalars['String']>;
};

export type EducationPlanDisciplinesInput = {
  /**
   * Зачетка студента, studentInfo.recordbook_guid
   * если нет, то выборка по всем зачеткам текущего студента
   */
  ep_number: Array<InputMaybe<Scalars['String']>>;
  filter?: InputMaybe<EducationPlanDisciplinesFilter>;
  grouped?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

/** Выборка либо по user_id лбо recordbook_guid */
export type EducationPlanInput = {
  recordbook_guid?: InputMaybe<Scalars['UUID']>;
  user_id?: InputMaybe<Scalars['UUID']>;
};

export type EducationPlanResult = {
  __typename?: 'EducationPlanResult';
  plans?: Maybe<Array<Maybe<EducationPlan>>>;
  recordbook?: Maybe<Scalars['JSON']>;
  user_id?: Maybe<Scalars['UUID']>;
};

export enum EffectiveStatsExportMode {
  /** Вообзе все, с 2.1 по 3.2 */
  All = 'ALL',
  /** Ежемесячные + богусы */
  Monthly = 'MONTHLY',
  /** Единовременные выплаты - 2.1-2-4 */
  Onetime = 'ONETIME'
}

export type EmployeePosition = {
  __typename?: 'EmployeePosition';
  categoryPosition?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['PGDate']>;
  guidFL?: Maybe<Scalars['UUID']>;
  isEffectiveContract?: Maybe<Scalars['Boolean']>;
  isPPS?: Maybe<Scalars['Boolean']>;
  orderDate?: Maybe<Scalars['PGDate']>;
  personnelNumber?: Maybe<Scalars['String']>;
  positionName?: Maybe<Scalars['String']>;
  positionType?: Maybe<Scalars['String']>;
};

export enum EmployeeReferenceCatalog {
  ForeignLanguage = 'ForeignLanguage',
  Orders = 'Orders',
  PassportIssuedBy = 'PassportIssuedBy',
  ReasonChoice = 'ReasonChoice',
  TypeOfStatements = 'TypeOfStatements'
}

export enum EventTypes {
  AchievementChanged = 'achievementChanged',
  AchievementDirectionsChanged = 'achievementDirectionsChanged',
  AchievementTypeChanged = 'achievementTypeChanged',
  AvatarChanged = 'avatarChanged',
  ConfigChanged = 'configChanged',
  ContractsChanged = 'contractsChanged',
  EcExportsProgress = 'ecExportsProgress',
  EsTracksChanged = 'esTracksChanged',
  FacultativesChanged = 'facultativesChanged',
  NotificationDraftChanged = 'notificationDraftChanged',
  PersonnelConfigChanged = 'personnelConfigChanged',
  ProgressEvent = 'progressEvent',
  ProjectChanged = 'projectChanged',
  ReferenceChanged = 'referenceChanged',
  SmsCodeChanged = 'smsCodeChanged',
  UiNotification = 'uiNotification',
  UploadsChanged = 'uploadsChanged'
}

export enum ExportTypes {
  Elective = 'elective',
  Facultative = 'facultative',
  Tracks = 'tracks'
}

export type Facultative = {
  __typename?: 'Facultative';
  data?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  facultative_id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_visible?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Array<Maybe<Scalars['String']>>>;
  max_participants?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  student?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

export type FacultativeFilter = {
  course?: InputMaybe<Scalars['Int']>;
  department?: InputMaybe<Scalars['String']>;
  eform?: InputMaybe<Scalars['String']>;
  elevel?: InputMaybe<Scalars['String']>;
  facultative_id?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Scalars['String']>;
  isModerator?: InputMaybe<Scalars['Boolean']>;
  nameprof?: InputMaybe<Scalars['String']>;
  namespec?: InputMaybe<Scalars['String']>;
  participant_id?: InputMaybe<Scalars['UUID']>;
  semester?: InputMaybe<Scalars['Int']>;
  student_id?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<DisciplineType>;
};

export type FacultativeInput = {
  filter?: InputMaybe<FacultativeFilter>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

export type FileEntryInput = {
  category: Scalars['String'];
  name: Scalars['String'];
};

export type FileNotFoundError = {
  __typename?: 'FileNotFoundError';
  message?: Maybe<Scalars['String']>;
};

export type FileUploadConfirmation = {
  __typename?: 'FileUploadConfirmation';
  result?: Maybe<Scalars['Boolean']>;
};

export type FileUploadConfirmationResult = FileNotFoundError | FileUploadConfirmation | InvalidArgumentError;

export enum FixRecordMode {
  PublicationYear_1_4 = 'PublicationYear_1_4'
}

export type InputFile = {
  fileCategory?: InputMaybe<CategoryName>;
  fileName?: InputMaybe<Scalars['String']>;
  fileType?: InputMaybe<Scalars['String']>;
};

export type InternetStatus = {
  __typename?: 'InternetStatus';
  code?: Maybe<Scalars['Int']>;
  dateLastLogin?: Maybe<Scalars['String']>;
  intranetStatus?: Maybe<Scalars['Boolean']>;
  ip?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
};

export type InvalidArgumentError = {
  __typename?: 'InvalidArgumentError';
  message?: Maybe<Scalars['String']>;
};

export type JournalOptions = {
  format?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationType>;
};

export type KindOfEducation = {
  __typename?: 'KindOfEducation';
  name?: Maybe<Scalars['String']>;
};

export type LogFrontendInput = {
  data: Scalars['JSON'];
  /**  к чему относится сообщение, по умолчанию - common  */
  tag?: InputMaybe<Scalars['String']>;
};

export enum LogLevel {
  Error = 'error',
  Info = 'info'
}

export enum MfcStatementType {
  EmployeeMemoDgu = 'EmployeeMemoDGU',
  EmployeeMemoZkgu = 'EmployeeMemoZKGU',
  EmployeeStatements = 'EmployeeStatements',
  StudentStatements = 'StudentStatements'
}

export type MfcStatementsInput = {
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
  status?: InputMaybe<Array<InputMaybe<ReferenceStatus>>>;
  type?: InputMaybe<MfcStatementType>;
};

export type MfcUpdateStatementInput = {
  /**  добавляем коммент  */
  comment?: InputMaybe<Scalars['String']>;
  doc_id: Scalars['Int'];
  /**  меняем статус  */
  status?: InputMaybe<ReferenceStatus>;
};

export type Me = {
  __typename?: 'Me';
  access_token?: Maybe<Scalars['String']>;
  data?: Maybe<DataFl>;
  email?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['UUID']>;
  sub?: Maybe<Scalars['String']>;
};

export enum MessageHistoryFields {
  FullName = 'full_name',
  TypeMessage = 'type_message',
  UserId = 'user_id'
}

export type MessageHistoryInput = {
  endDate?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
  startDate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['UUID']>;
};

export type MessageHistoryStatsInput = {
  statsBy?: InputMaybe<MessageHistoryFields>;
};

export type MoodleCoursesFilter = {
  discipline_name?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
};

export type MoodleCoursesInput = {
  filter?: InputMaybe<MoodleCoursesFilter>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
  user_id?: InputMaybe<Scalars['UUID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  achievementsCheckFiles?: Maybe<Scalars['JSON']>;
  achievementsImportDump?: Maybe<Scalars['JSON']>;
  activateInternetSessionByCurrentUser?: Maybe<InternetStatus>;
  /** Добавляет достижение */
  addAchievement?: Maybe<Scalars['JSON']>;
  /**
   * Добавление/линковка тьютора
   * id - data_fl.id
   * linkType - на что будет указывать linkId(uuid)
   * - может быть привязка groups,nameprof,namespec,students итд
   * data - фильтрация внутри выборки, номер курса например
   */
  addTutor?: Maybe<Scalars['JSON']>;
  archiveExpiredAchievements?: Maybe<Scalars['JSON']>;
  changeTypeDirection?: Maybe<Scalars['JSON']>;
  createAchievement?: Maybe<Scalars['JSON']>;
  createReference?: Maybe<Scalars['JSON']>;
  deactivateInternetSessionByCurrentUser?: Maybe<InternetStatus>;
  /** Устанавливает пользовательсикий аватар */
  defaultAvatar?: Maybe<Scalars['JSON']>;
  deleteAchievement?: Maybe<Scalars['JSON']>;
  /** Создат справку МФЦ */
  deleteNotification?: Maybe<Scalars['JSON']>;
  deleteUpload?: Maybe<Scalars['JSON']>;
  ecBuildEcData?: Maybe<Scalars['JSON']>;
  ecCommitEducationOfIndividials?: Maybe<Scalars['JSON']>;
  ecCreateData?: Maybe<Scalars['JSON']>;
  /**  Eff. Contracts  */
  ecCreateProffEvent?: Maybe<Scalars['JSON']>;
  ecDeleteData?: Maybe<Scalars['JSON']>;
  ecDeleteProffEvent?: Maybe<Scalars['JSON']>;
  ecFixRecords?: Maybe<Scalars['JSON']>;
  ecImportMoodleAchievement?: Maybe<Scalars['JSON']>;
  /**
   * async - true, срузу вернуть ответ,
   * а прогресс можно получать через подписку
   */
  ecImportTable_22_16x17?: Maybe<Scalars['JSON']>;
  ecMigration_UpdateArticlesDate?: Maybe<Scalars['JSON']>;
  ecMoveData?: Maybe<Scalars['JSON']>;
  ecMoveDocuments?: Maybe<Scalars['JSON']>;
  ecTranslateTable39_79?: Maybe<Scalars['JSON']>;
  ecUpdateData?: Maybe<Scalars['JSON']>;
  ecUpdateDataType?: Maybe<Scalars['JSON']>;
  ecUpdateProffEvent?: Maybe<Scalars['JSON']>;
  ecUpdateScores?: Maybe<Scalars['JSON']>;
  ecUpdateTableDataDefaults?: Maybe<Scalars['JSON']>;
  ecUpdateTableDataField?: Maybe<Scalars['JSON']>;
  editAchievementDirection?: Maybe<Scalars['JSON']>;
  editAchievementType?: Maybe<Scalars['JSON']>;
  esCreateFacultative?: Maybe<Facultative>;
  esDeleteFacultative?: Maybe<Facultative>;
  esDisciplineActivate?: Maybe<Scalars['JSON']>;
  esESB_SelectedDiscipline?: Maybe<Scalars['JSON']>;
  esESB_SelectedDisciplineFixNames?: Maybe<Scalars['JSON']>;
  esEditDisciplineProcedure?: Maybe<DisciplineProcedure>;
  esMigration_addFacultativeDataField?: Maybe<Scalars['JSON']>;
  esMigration_importTrackFile?: Maybe<Scalars['JSON']>;
  esSendDisciplines?: Maybe<Scalars['JSON']>;
  esSignOutFacultative?: Maybe<Scalars['JSON']>;
  esSignOutTrack?: Maybe<Scalars['JSON']>;
  esSignOutTrackStudent?: Maybe<Scalars['JSON']>;
  esSignUpFacultative?: Maybe<Scalars['JSON']>;
  esSignUpTrack?: Maybe<Scalars['JSON']>;
  esTrackActivate?: Maybe<Scalars['JSON']>;
  esTrackCreate?: Maybe<Scalars['JSON']>;
  esTrackDelete?: Maybe<Scalars['Int']>;
  esTrackUpdate?: Maybe<Scalars['JSON']>;
  esTrackUpdateDiscipline?: Maybe<Scalars['JSON']>;
  esTrackUpdateVisibility?: Maybe<Scalars['JSON']>;
  esUpdateDisciplineStats?: Maybe<Scalars['JSON']>;
  esUpdateSignupInfo?: Maybe<Scalars['JSON']>;
  esbCommitMFC?: Maybe<Scalars['JSON']>;
  esbSend?: Maybe<Scalars['JSON']>;
  eventPublish?: Maybe<Scalars['JSON']>;
  fileUploadConfirmation?: Maybe<FileUploadConfirmationResult>;
  getPresignedUploadURL?: Maybe<PresignedUploadUrlResult>;
  initInternals?: Maybe<Scalars['JSON']>;
  loadJSONFile?: Maybe<Scalars['JSON']>;
  logAction?: Maybe<Scalars['Boolean']>;
  mfcAddForm?: Maybe<Scalars['JSON']>;
  mfcImportForms?: Maybe<Scalars['JSON']>;
  mfcSendStatement?: Maybe<Scalars['JSON']>;
  mfcUpdateStatement?: Maybe<Scalars['JSON']>;
  notificationBroadcast?: Maybe<Scalars['JSON']>;
  notificationCreate?: Maybe<Scalars['JSON']>;
  notificationDelete?: Maybe<Scalars['JSON']>;
  notificationDraftAdd?: Maybe<Scalars['JSON']>;
  notificationDraftChangeActive?: Maybe<Scalars['JSON']>;
  notificationDraftDelete?: Maybe<Scalars['JSON']>;
  notificationDraftProcess?: Maybe<Scalars['JSON']>;
  notificationDraftPurgeMessages?: Maybe<Scalars['JSON']>;
  notificationDraftResetProcessed?: Maybe<Scalars['JSON']>;
  notificationDraftSend?: Maybe<Scalars['JSON']>;
  notificationUpdateStatus?: Maybe<Scalars['JSON']>;
  paAddComment?: Maybe<Scalars['JSON']>;
  /** Tasks */
  paAddTask?: Maybe<Scalars['JSON']>;
  paApply?: Maybe<Scalars['JSON']>;
  /** params ::= {req_id,state_num,data,...} */
  paChangeStage?: Maybe<Scalars['JSON']>;
  paChangeTask?: Maybe<Scalars['JSON']>;
  paControl?: Maybe<Scalars['JSON']>;
  paCreateProjectReport?: Maybe<Scalars['JSON']>;
  paDeleteComment?: Maybe<Scalars['JSON']>;
  paDeleteInitiative?: Maybe<Scalars['JSON']>;
  paDeleteProject?: Maybe<Scalars['JSON']>;
  paDeleteStage?: Maybe<Scalars['JSON']>;
  paDeleteTask?: Maybe<Scalars['JSON']>;
  paInitiate?: Maybe<Scalars['JSON']>;
  paModify?: Maybe<Scalars['JSON']>;
  paProjectThemeCreate?: Maybe<Scalars['JSON']>;
  paProjectThemeDelete?: Maybe<Scalars['JSON']>;
  paProjectThemeSignOut?: Maybe<Scalars['JSON']>;
  paProjectThemeSignUp?: Maybe<Scalars['JSON']>;
  paSetConfig?: Maybe<Scalars['JSON']>;
  referenceCommit?: Maybe<Scalars['JSON']>;
  refreshViews?: Maybe<Scalars['JSON']>;
  removeFile?: Maybe<RemoveFileResult>;
  replaceGUID?: Maybe<Scalars['JSON']>;
  replaceRPD?: Maybe<Scalars['JSON']>;
  replayESB?: Maybe<Scalars['JSON']>;
  restoreAvatar?: Maybe<Scalars['JSON']>;
  restoreDeletedFiles?: Maybe<Scalars['JSON']>;
  smsCodeRequest?: Maybe<Scalars['JSON']>;
  tgFlush?: Maybe<Scalars['JSON']>;
  tgMessage?: Maybe<Scalars['JSON']>;
  /**  Common Data  */
  uiAddNotification?: Maybe<Scalars['JSON']>;
  uiDeleteNotification?: Maybe<Scalars['JSON']>;
  /**  Mailer  */
  uiFeedback?: Maybe<Scalars['JSON']>;
  uiUpdateNotification?: Maybe<Scalars['JSON']>;
  updateAchievement?: Maybe<Scalars['JSON']>;
  updateNotification?: Maybe<Scalars['JSON']>;
  /** Если value не указан, то значение удаляется */
  updatePersonnelConfig?: Maybe<Scalars['JSON']>;
  utilImportDataFL?: Maybe<Scalars['JSON']>;
  utilImportESDocuments?: Maybe<Scalars['JSON']>;
  utilImportOrderTypes?: Maybe<Scalars['JSON']>;
  utilLogfrontEnd?: Maybe<Scalars['JSON']>;
  utilUpdateConfig?: Maybe<Scalars['JSON']>;
  utilUpdateTableData?: Maybe<Scalars['JSON']>;
  wipeMySession?: Maybe<Scalars['JSON']>;
};


export type MutationAchievementsImportDumpArgs = {
  count?: InputMaybe<Scalars['Int']>;
  dump: Scalars['String'];
  files?: InputMaybe<Scalars['String']>;
};


export type MutationAddAchievementArgs = {
  item?: InputMaybe<Scalars['JSON']>;
};


export type MutationAddTutorArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  linkId?: InputMaybe<Scalars['String']>;
  linkType?: InputMaybe<Scalars['String']>;
};


export type MutationArchiveExpiredAchievementsArgs = {
  day?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
};


export type MutationChangeTypeDirectionArgs = {
  input?: InputMaybe<ChangeTypeDirectionInput>;
};


export type MutationCreateAchievementArgs = {
  input: CreateAchievementInput;
};


export type MutationCreateReferenceArgs = {
  payload?: InputMaybe<Scalars['JSON']>;
};


export type MutationDefaultAvatarArgs = {
  upload_id: Scalars['Int'];
};


export type MutationDeleteAchievementArgs = {
  achievement_id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteNotificationArgs = {
  item_id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteUploadArgs = {
  upload_ids: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationEcBuildEcDataArgs = {
  sourceView?: InputMaybe<Scalars['String']>;
};


export type MutationEcCommitEducationOfIndividialsArgs = {
  noSend?: InputMaybe<Scalars['Boolean']>;
  rec_id?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationEcCreateDataArgs = {
  appeal?: InputMaybe<Scalars['JSON']>;
  data: Scalars['JSON'];
  dataType: Scalars['String'];
  extra?: InputMaybe<Scalars['JSON']>;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationEcCreateProffEventArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationEcDeleteDataArgs = {
  dataType?: InputMaybe<Scalars['String']>;
  rec_id: Scalars['Int'];
};


export type MutationEcDeleteProffEventArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationEcFixRecordsArgs = {
  args?: InputMaybe<Scalars['JSON']>;
  mode?: InputMaybe<FixRecordMode>;
};


export type MutationEcImportMoodleAchievementArgs = {
  base?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
};


export type MutationEcImportTable_22_16x17Args = {
  async?: InputMaybe<Scalars['Boolean']>;
  upload_id: Scalars['Int'];
};


export type MutationEcMigration_UpdateArticlesDateArgs = {
  args?: InputMaybe<Scalars['JSON']>;
};


export type MutationEcMoveDataArgs = {
  mode?: InputMaybe<Scalars['String']>;
};


export type MutationEcMoveDocumentsArgs = {
  dataType?: InputMaybe<Scalars['String']>;
};


export type MutationEcTranslateTable39_79Args = {
  destTable?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<Scalars['String']>;
  sourceTable?: InputMaybe<Scalars['String']>;
};


export type MutationEcUpdateDataArgs = {
  appeal?: InputMaybe<Scalars['JSON']>;
  data?: InputMaybe<Scalars['JSON']>;
  extra?: InputMaybe<Scalars['JSON']>;
  rec_id: Scalars['Int'];
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationEcUpdateDataTypeArgs = {
  dataType?: InputMaybe<Scalars['String']>;
  rec_id: Scalars['Int'];
};


export type MutationEcUpdateProffEventArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationEcUpdateTableDataDefaultsArgs = {
  dataType?: InputMaybe<Scalars['String']>;
  defaults?: InputMaybe<Scalars['JSON']>;
};


export type MutationEcUpdateTableDataFieldArgs = {
  dataType?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['String']>;
  lookup?: InputMaybe<Scalars['String']>;
  replace?: InputMaybe<Scalars['String']>;
};


export type MutationEditAchievementDirectionArgs = {
  input?: InputMaybe<EditAchievementDirectionInput>;
};


export type MutationEditAchievementTypeArgs = {
  input?: InputMaybe<EditAchievementTypeInput>;
};


export type MutationEsCreateFacultativeArgs = {
  input: CreateFacultativeInput;
};


export type MutationEsDeleteFacultativeArgs = {
  input: DeleteFacultativeInput;
};


export type MutationEsDisciplineActivateArgs = {
  facultative_id?: InputMaybe<Array<Scalars['Int']>>;
  is_active: Scalars['Boolean'];
  type?: InputMaybe<DisciplineType>;
};


export type MutationEsEsb_SelectedDisciplineArgs = {
  mode: Array<InputMaybe<DisciplineType>>;
};


export type MutationEsEsb_SelectedDisciplineFixNamesArgs = {
  names: Scalars['JSON'];
  view?: InputMaybe<Scalars['String']>;
};


export type MutationEsEditDisciplineProcedureArgs = {
  input: EditDisciplineProcedureInput;
};


export type MutationEsMigration_AddFacultativeDataFieldArgs = {
  field?: InputMaybe<Scalars['JSON']>;
};


export type MutationEsMigration_ImportTrackFileArgs = {
  upload_id: Scalars['Int'];
};


export type MutationEsSendDisciplinesArgs = {
  facultative_id?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  type?: InputMaybe<DisciplineType>;
};


export type MutationEsSignOutFacultativeArgs = {
  input: SignOutFacultativeInput;
};


export type MutationEsSignOutTrackArgs = {
  input: SignOutTrackInput;
};


export type MutationEsSignOutTrackStudentArgs = {
  input: SignOutTrackStudentInput;
};


export type MutationEsSignUpFacultativeArgs = {
  input: SignUpFacultativeInput;
};


export type MutationEsSignUpTrackArgs = {
  input: SignUpTrackInput;
};


export type MutationEsTrackActivateArgs = {
  is_active: Scalars['Boolean'];
  track_id?: InputMaybe<Scalars['Int']>;
};


export type MutationEsTrackCreateArgs = {
  input: CreateTrackInput;
};


export type MutationEsTrackDeleteArgs = {
  track_id: Array<Scalars['Int']>;
};


export type MutationEsTrackUpdateArgs = {
  input: TrackUpdateInput;
};


export type MutationEsTrackUpdateDisciplineArgs = {
  input: TrackDisciplineUpdateInput;
};


export type MutationEsTrackUpdateVisibilityArgs = {
  is_visible: Scalars['Boolean'];
  track_id?: InputMaybe<Scalars['Int']>;
};


export type MutationEsUpdateDisciplineStatsArgs = {
  input: UpdateStatsInput;
};


export type MutationEsUpdateSignupInfoArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type MutationEsbCommitMfcArgs = {
  doc_id?: InputMaybe<Scalars['Int']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type MutationEsbSendArgs = {
  TypeMessage: Scalars['String'];
  payload: Scalars['JSON'];
};


export type MutationEventPublishArgs = {
  event?: InputMaybe<EventTypes>;
  id?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['JSON']>;
};


export type MutationFileUploadConfirmationArgs = {
  fileId: Scalars['String'];
};


export type MutationGetPresignedUploadUrlArgs = {
  inputFile: InputFile;
};


export type MutationLogActionArgs = {
  level?: InputMaybe<LogLevel>;
  message?: InputMaybe<Scalars['String']>;
};


export type MutationMfcAddFormArgs = {
  fields: Scalars['JSON'];
  name: Scalars['String'];
  statementType: MfcStatementType;
};


export type MutationMfcImportFormsArgs = {
  items: Scalars['JSON'];
  statementType: MfcStatementType;
};


export type MutationMfcSendStatementArgs = {
  payload: Scalars['JSON'];
  type: MfcStatementType;
};


export type MutationMfcUpdateStatementArgs = {
  input: MfcUpdateStatementInput;
};


export type MutationNotificationBroadcastArgs = {
  input: NotificationBroadcastInput;
};


export type MutationNotificationCreateArgs = {
  input?: InputMaybe<UiNotificationInput>;
};


export type MutationNotificationDeleteArgs = {
  msg_id: Array<Scalars['Int']>;
};


export type MutationNotificationDraftAddArgs = {
  body: Scalars['JSON'];
  draft_id?: InputMaybe<Scalars['Int']>;
};


export type MutationNotificationDraftChangeActiveArgs = {
  draft_id: Array<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
};


export type MutationNotificationDraftDeleteArgs = {
  draft_id: Scalars['Int'];
};


export type MutationNotificationDraftPurgeMessagesArgs = {
  draft_id: Array<Scalars['Int']>;
};


export type MutationNotificationDraftResetProcessedArgs = {
  draft_id: Array<Scalars['Int']>;
};


export type MutationNotificationDraftSendArgs = {
  draft_id: Array<Scalars['Int']>;
  test_guid?: InputMaybe<Scalars['UUID']>;
};


export type MutationNotificationUpdateStatusArgs = {
  msg_id: Array<Scalars['Int']>;
  status?: InputMaybe<NotificationStatus>;
};


export type MutationPaAddCommentArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationPaAddTaskArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationPaApplyArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationPaChangeStageArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationPaChangeTaskArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationPaControlArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationPaCreateProjectReportArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationPaDeleteCommentArgs = {
  comment_id?: InputMaybe<Scalars['Int']>;
};


export type MutationPaDeleteInitiativeArgs = {
  req_id?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationPaDeleteProjectArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationPaDeleteStageArgs = {
  event: Scalars['String'];
  req_id: Scalars['Int'];
  stage_num: Scalars['Int'];
};


export type MutationPaDeleteTaskArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationPaInitiateArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationPaModifyArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationPaProjectThemeCreateArgs = {
  input: ProjectThemeCreateInput;
};


export type MutationPaProjectThemeDeleteArgs = {
  theme_id: Array<Scalars['Int']>;
};


export type MutationPaProjectThemeSignOutArgs = {
  input: ProjectThemeSignOutInput;
};


export type MutationPaProjectThemeSignUpArgs = {
  input: ProjectThemeSignUpInput;
};


export type MutationPaSetConfigArgs = {
  config?: InputMaybe<Scalars['JSON']>;
  configName?: InputMaybe<Scalars['String']>;
};


export type MutationReferenceCommitArgs = {
  doc_id?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type MutationRefreshViewsArgs = {
  views: Array<InputMaybe<Scalars['String']>>;
};


export type MutationRemoveFileArgs = {
  fileId: Scalars['String'];
};


export type MutationReplaceGuidArgs = {
  file: Scalars['String'];
  num?: InputMaybe<Scalars['Int']>;
};


export type MutationReplaceRpdArgs = {
  esb_id?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
};


export type MutationReplayEsbArgs = {
  input: EsbLogSelectorInput;
};


export type MutationRestoreAvatarArgs = {
  id: Scalars['String'];
  sub: Scalars['String'];
};


export type MutationRestoreDeletedFilesArgs = {
  file: Scalars['String'];
};


export type MutationSmsCodeRequestArgs = {
  expire?: InputMaybe<Scalars['Int']>;
  fake?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  source?: InputMaybe<Scalars['String']>;
};


export type MutationTgMessageArgs = {
  message?: InputMaybe<Scalars['String']>;
};


export type MutationUiAddNotificationArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type MutationUiDeleteNotificationArgs = {
  msg_id: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationUiFeedbackArgs = {
  body: Scalars['String'];
  extra?: InputMaybe<Scalars['JSON']>;
  subject: Scalars['String'];
};


export type MutationUiUpdateNotificationArgs = {
  msg_id: Array<InputMaybe<Scalars['Int']>>;
  status: Scalars['String'];
};


export type MutationUpdateAchievementArgs = {
  input: UpdateAchievementInput;
};


export type MutationUpdateNotificationArgs = {
  payload?: InputMaybe<Scalars['JSON']>;
};


export type MutationUpdatePersonnelConfigArgs = {
  name: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};


export type MutationUtilImportDataFlArgs = {
  TypeMessage?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  file: Scalars['String'];
};


export type MutationUtilImportEsDocumentsArgs = {
  outDir: Scalars['String'];
  searchPath: Scalars['String'];
};


export type MutationUtilImportOrderTypesArgs = {
  file: Scalars['String'];
};


export type MutationUtilLogfrontEndArgs = {
  input: LogFrontendInput;
};


export type MutationUtilUpdateConfigArgs = {
  input: UpdateConfigInput;
};


export type MutationUtilUpdateTableDataArgs = {
  defaults?: InputMaybe<Scalars['JSON']>;
  key?: InputMaybe<Scalars['String']>;
  table?: InputMaybe<Scalars['String']>;
};

export type NameProfsListInput = {
  filter?: InputMaybe<NameProfsListInputFilter>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<SortField>>;
};

export type NameProfsListInputFilter = {
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  namespec_id?: InputMaybe<Scalars['UUID']>;
};

export type NameSpecsListInput = {
  filter?: InputMaybe<NameSpecsListInputFilter>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<SortField>>;
};

export type NameSpecsListInputFilter = {
  /**  выборка только записей с префиком xx.yy.zzz  */
  anyPrefix?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  body?: Maybe<Scalars['JSON']>;
  created?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  from_id?: Maybe<Scalars['UUID']>;
  meta?: Maybe<Scalars['JSON']>;
  msg_id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  to_id?: Maybe<Scalars['UUID']>;
};

export type NotificationBroadcastInput = {
  expired?: InputMaybe<Scalars['String']>;
  message: Array<Scalars['String']>;
  meta?: InputMaybe<Scalars['JSON']>;
  source: Scalars['String'];
  test_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  type: NotificationBroadcastType;
};

export enum NotificationBroadcastType {
  All = 'all',
  Employees = 'employees',
  Students = 'students'
}

export type NotificationDraftFilterInput = {
  /**  YYYY-MM-DD  */
  from?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_important?: InputMaybe<Scalars['Boolean']>;
  /**  target.item  */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  /**  YYYY-MM-DD  */
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NotificationDraftInput = {
  filter?: InputMaybe<NotificationDraftFilterInput>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

export type NotificationInput = {
  payload?: InputMaybe<Scalars['JSON']>;
};

export type NotificationListInput = {
  metaType?: InputMaybe<NotificationMetaType>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
  source?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Array<InputMaybe<NotificationStatus>>>;
};

export enum NotificationMetaType {
  /**  Advisory card  */
  Dynamic = 'dynamic',
  /**  default  */
  Message = 'message'
}

export type NotificationResult = {
  __typename?: 'NotificationResult';
  items?: Maybe<Array<Maybe<Notification>>>;
  pagination?: Maybe<Scalars['JSON']>;
};

export enum NotificationStatus {
  Archive = 'archive',
  New = 'new',
  Seen = 'seen'
}

export type PaginationType = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PaymentSchedule = {
  __typename?: 'PaymentSchedule';
  TheSumFaktPayment?: Maybe<Scalars['String']>;
  graph?: Maybe<Array<Maybe<PaymentScheduleGraph>>>;
  nearestPayDate?: Maybe<Scalars['String']>;
  paySum?: Maybe<Scalars['String']>;
};

export type PaymentScheduleGraph = {
  __typename?: 'PaymentScheduleGraph';
  PayDo?: Maybe<Scalars['String']>;
  TheAmount?: Maybe<Scalars['String']>;
};

export type PersonnelPosition = {
  __typename?: 'PersonnelPosition';
  id?: Maybe<Scalars['UUID']>;
  isTeacher?: Maybe<Scalars['Boolean']>;
  order_date?: Maybe<Scalars['PGDate']>;
  position_name?: Maybe<Scalars['String']>;
  position_type?: Maybe<Scalars['String']>;
};

export type PresignedDownloadUrl = {
  __typename?: 'PresignedDownloadUrl';
  downloadUrl?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type PresignedDownloadUrlResult = FileNotFoundError | InvalidArgumentError | PresignedDownloadUrl;

export type PresignedUploadUrl = {
  __typename?: 'PresignedUploadUrl';
  fileId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PresignedUploadUrlResult = InvalidArgumentError | PresignedUploadUrl;

export type ProgramResults = {
  __typename?: 'ProgramResults';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ProjectSeminarGroupDetailInput = {
  groupName: Scalars['String'];
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

export type ProjectThemeCreateInput = {
  upload_id: Scalars['Int'];
};

export type ProjectThemeSignOutInput = {
  student_id: Scalars['UUID'];
  theme_id: Scalars['Int'];
};

export type ProjectThemeSignUpInput = {
  student_info?: InputMaybe<Scalars['JSON']>;
  theme_id: Scalars['Int'];
};

export type ProjectThemesInput = {
  project_type?: InputMaybe<Scalars['String']>;
};

export type Qualification = {
  __typename?: 'Qualification';
  name?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  EducationPlanList?: Maybe<Array<Maybe<EducationPlan>>>;
  /**
   * степендии и выплаты
   * mode = "last" - самая поздняя по дате запись в массиве
   */
  accruedScholarship?: Maybe<Scalars['JSON']>;
  accruedScholarshipPeriods?: Maybe<Scalars['JSON']>;
  achievementAllRating?: Maybe<RatingList>;
  achievementDirectionCounters?: Maybe<Scalars['JSON']>;
  achievementDirections?: Maybe<Scalars['JSON']>;
  achievementPersonalRating?: Maybe<Scalars['JSON']>;
  achievementRating?: Maybe<Scalars['JSON']>;
  achievementRatingById?: Maybe<Scalars['JSON']>;
  achievementStatuses?: Maybe<Scalars['JSON']>;
  achievementTemplates?: Maybe<Scalars['JSON']>;
  achievementTypes?: Maybe<Scalars['JSON']>;
  achievements?: Maybe<Scalars['JSON']>;
  achievementsExportProfile?: Maybe<Scalars['JSON']>;
  averageGrade?: Maybe<Scalars['JSON']>;
  /**
   * Данные для выбора фильтрации на экране финансов
   * (Общежитие,Обучение)
   * - пока просто прошито на бэке
   * если names === null, то возвращаются имена доступных каталогов ( без контента )
   * ** ДОСТУПНЫЕ **
   * contractTypes - типы договоров: общежитие, учеба
   * scholarshipsAndPayouts - Стипендии, выплаты, история
   * referenceStatus - статусы заявок в МФК
   * referenceAuthority - место предоставления справок
   * referenceTypes - типы справок
   */
  catalogs?: Maybe<Scalars['JSON']>;
  checkFiles?: Maybe<Scalars['JSON']>;
  departmentList?: Maybe<Scalars['JSON']>;
  departmentSpecs?: Maybe<Scalars['JSON']>;
  departments?: Maybe<Scalars['JSON']>;
  /** Получить дисциплины из группы студента */
  disciplines?: Maybe<Scalars['JSON']>;
  /** Договоры по общежитию */
  dormitoryContracts?: Maybe<Scalars['JSON']>;
  /**
   * Выплаты по общежитию
   * если number_contract === null, то все договры
   */
  dormitoryPayments?: Maybe<Scalars['JSON']>;
  ecCheckCompletion?: Maybe<Scalars['JSON']>;
  ecComputeScores?: Maybe<Scalars['JSON']>;
  ecCreateData22_18?: Maybe<Scalars['JSON']>;
  ecDataTableByUserAndStatus?: Maybe<Scalars['JSON']>;
  ecExportTable_1_1?: Maybe<Scalars['JSON']>;
  ecExportTable_2_3?: Maybe<Scalars['JSON']>;
  ecExportTable_2_5?: Maybe<Scalars['JSON']>;
  ecExportTable_3_5?: Maybe<Scalars['JSON']>;
  ecExportTable_BaseStats?: Maybe<Scalars['JSON']>;
  ecExportTable_EffectiveStats?: Maybe<Scalars['JSON']>;
  ecGetBaselineCompletion?: Maybe<Scalars['JSON']>;
  ecGetCompletionStats?: Maybe<Scalars['JSON']>;
  ecGetScoresTableBySelector?: Maybe<Scalars['JSON']>;
  ecGraphData?: Maybe<Scalars['JSON']>;
  ecGraphDataTotals?: Maybe<Scalars['JSON']>;
  ecListAwards?: Maybe<Scalars['JSON']>;
  ecListData?: Maybe<Scalars['JSON']>;
  ecListEducationOfIndividuals?: Maybe<Scalars['JSON']>;
  ecListProffEvents?: Maybe<Scalars['JSON']>;
  ecQueryBaseLineData?: Maybe<Scalars['JSON']>;
  /** Договоры на обучение и задолжности */
  educationContracts?: Maybe<Scalars['JSON']>;
  /** История платежей */
  educationPaymentsHistory?: Maybe<Scalars['JSON']>;
  educationPlan?: Maybe<Array<Maybe<EducationPlanResult>>>;
  educationPlanDisciplines?: Maybe<Scalars['JSON']>;
  /** Формы обучения */
  eforms?: Maybe<Scalars['JSON']>;
  employeesInfo?: Maybe<Scalars['JSON']>;
  esBroadcastFacultatives?: Maybe<Scalars['JSON']>;
  esBroadcastSignedTracks?: Maybe<Scalars['JSON']>;
  esDisciplineProcedure?: Maybe<DisciplineProcedure>;
  esDisciplineStats?: Maybe<Scalars['JSON']>;
  esDisciplinesBySemesters?: Maybe<Scalars['JSON']>;
  esDisciplinesState?: Maybe<Scalars['JSON']>;
  esEducationPlan?: Maybe<EducationPlan>;
  esFacultative?: Maybe<Scalars['JSON']>;
  esGetSignStatus?: Maybe<Scalars['JSON']>;
  esMoodleCoursesByDiscipline?: Maybe<Scalars['JSON']>;
  esSignedStudents?: Maybe<Scalars['JSON']>;
  esTrackDisciplines?: Maybe<Array<Maybe<TrackDiscipline>>>;
  esTrackList?: Maybe<TrackResult>;
  esTrackState?: Maybe<Scalars['JSON']>;
  esTrackStudents?: Maybe<Array<Maybe<TrackStudents>>>;
  esXLS_DisciplineStats?: Maybe<Scalars['JSON']>;
  esbMessageHistory?: Maybe<Scalars['JSON']>;
  esbMessageHistoryStats?: Maybe<Scalars['JSON']>;
  exportRatingPDF?: Maybe<Scalars['JSON']>;
  exportStudentAchievementRating?: Maybe<Scalars['JSON']>;
  exportStudentAchivementStats?: Maybe<Scalars['JSON']>;
  fileExists?: Maybe<Scalars['JSON']>;
  finAccruedScholarship?: Maybe<Array<Maybe<AccruedScholarship>>>;
  finEducationContracts?: Maybe<Array<Maybe<EducationContract>>>;
  getDirectionsWithAchievements?: Maybe<Scalars['JSON']>;
  getEmployeePosition?: Maybe<Array<Maybe<EmployeePosition>>>;
  getInternetConnectionStatusByCurrentUser?: Maybe<InternetStatus>;
  /** Получить новость с помощью ссылки */
  getNewsFromId?: Maybe<Scalars['JSON']>;
  getPresignedDownloadUrl?: Maybe<PresignedDownloadUrlResult>;
  /**
   * Получить журнал студента.
   * group - группа,
   * discipline - дисциплина
   * type - тип занятия (лекции, практические и тд.)
   */
  getStudentJournal?: Maybe<Scalars['JSON']>;
  /** Получить группы студента */
  getUserGroups?: Maybe<Scalars['JSON']>;
  getWorkspaceIconsByCurrentUser?: Maybe<Array<Maybe<WorkspaceIcon>>>;
  isLogged?: Maybe<Scalars['Boolean']>;
  journal?: Maybe<Scalars['JSON']>;
  libRuslanBook?: Maybe<Scalars['JSON']>;
  libRuslanDatabase?: Maybe<Scalars['JSON']>;
  listNameProfsWithAcceptedStudents?: Maybe<Scalars['JSON']>;
  listNameSpecsWithAcceptedStudents?: Maybe<Scalars['JSON']>;
  /**  Личные данные пользователя  */
  me?: Maybe<Scalars['JSON']>;
  mfcGetForm?: Maybe<Scalars['JSON']>;
  mfcGetFormTypes?: Maybe<Scalars['JSON']>;
  mfcStatements?: Maybe<Scalars['JSON']>;
  mfcStatementsStats?: Maybe<Scalars['JSON']>;
  moodleCourses?: Maybe<Scalars['JSON']>;
  nameprofs?: Maybe<Scalars['JSON']>;
  nameprofsList?: Maybe<Scalars['JSON']>;
  /** Journal */
  namespecs?: Maybe<Scalars['JSON']>;
  namespecsList?: Maybe<Scalars['JSON']>;
  nearestTimetable?: Maybe<Array<Maybe<Timetable>>>;
  news?: Maybe<Scalars['JSON']>;
  /** лента новостей */
  newsFeed?: Maybe<Scalars['JSON']>;
  /** Тэги новостей */
  newsTags?: Maybe<Scalars['JSON']>;
  notificationDraft?: Maybe<Scalars['JSON']>;
  notificationDraftFilters?: Maybe<Scalars['JSON']>;
  notificationLearningOutcomes?: Maybe<Scalars['JSON']>;
  notificationList?: Maybe<NotificationResult>;
  notificationSplash?: Maybe<Scalars['JSON']>;
  notifications?: Maybe<Scalars['JSON']>;
  notificationsCount?: Maybe<Scalars['JSON']>;
  paComments?: Maybe<Scalars['JSON']>;
  paExportProjectCompletions?: Maybe<Scalars['JSON']>;
  /**  Projects Activities All  */
  paGetAll?: Maybe<Scalars['JSON']>;
  paGetConfig?: Maybe<Scalars['JSON']>;
  paGetMyStats?: Maybe<Scalars['JSON']>;
  paGetProject?: Maybe<Scalars['JSON']>;
  paGetTask?: Maybe<Scalars['JSON']>;
  paGetTeam?: Maybe<Scalars['JSON']>;
  paListProjects?: Maybe<Scalars['JSON']>;
  paProjectSeminarGroupDetail?: Maybe<Scalars['JSON']>;
  paProjectThemes?: Maybe<Scalars['JSON']>;
  paStages?: Maybe<Scalars['JSON']>;
  paTasks?: Maybe<Scalars['JSON']>;
  paTeacherTimetableLookup?: Maybe<Scalars['JSON']>;
  paValidateExpiredResponses?: Maybe<Scalars['JSON']>;
  /**
   * Отдает список key:value,
   * id - logger или явный
   * name не указан, отдаст все !
   */
  personnelConfig?: Maybe<Scalars['JSON']>;
  personnelPosition?: Maybe<Array<Maybe<PersonnelPosition>>>;
  personnelPositionSafe?: Maybe<PersonnelPosition>;
  /**
   * match = default to "full_name"
   * - position_name,last,first,second, etc
   */
  persons?: Maybe<Scalars['JSON']>;
  /** Выплаты */
  plannedCharges?: Maybe<Scalars['JSON']>;
  positions?: Maybe<Scalars['JSON']>;
  /**
   * Данные для оплаты по QR через банк type
   * contract - Номер контракта,
   * если не указан, то все по пользователю
   * type = contract !== null ? [QRSber,QRElecsnet,QRPSB] : не используется
   */
  quittances?: Maybe<Scalars['JSON']>;
  /** Получить доступные банки платежей по договору */
  quittancesBanks?: Maybe<Scalars['JSON']>;
  /** Зачетные книжки */
  recordbook?: Maybe<Scalars['JSON']>;
  recordbookBySemester?: Maybe<Scalars['JSON']>;
  recordbooks?: Maybe<Array<Maybe<Recordbook>>>;
  referenceEmployeeCatalog?: Maybe<Scalars['JSON']>;
  referenceGetFormFieldData?: Maybe<Scalars['JSON']>;
  referenceList?: Maybe<Scalars['JSON']>;
  referenceStats?: Maybe<Scalars['JSON']>;
  /**
   * Справки МФЦ
   * mode (opt )= "stats" группировка по статусу + количество
   * status (opt) = выборка по статусам
   */
  references?: Maybe<Scalars['JSON']>;
  requiredStudentPapers?: Maybe<Scalars['JSON']>;
  self?: Maybe<Me>;
  sliderImages?: Maybe<Scalars['JSON']>;
  smsCodeCheck?: Maybe<Scalars['JSON']>;
  smsGetInfo?: Maybe<Scalars['JSON']>;
  specialities?: Maybe<Scalars['JSON']>;
  specialization?: Maybe<Specialization>;
  specialty?: Maybe<Specialty>;
  studentAchievementStats?: Maybe<Scalars['JSON']>;
  studentDataLookup?: Maybe<Scalars['JSON']>;
  studentInfo?: Maybe<Scalars['JSON']>;
  /**
   * Информация об обучнении
   * курс, группы итд, номер зачетки,
   * для самой зачетки нужно дергать recordbook
   */
  studentInfoById?: Maybe<Scalars['JSON']>;
  studentInfoWithFL?: Maybe<Scalars['JSON']>;
  /**
   * Расписание студента на диапазон даты
   * - формат YYYY-MM-DD
   * если dateStart не указан,то now()
   * если dateEnd не укащан, то dateStart || now()
   * limitOrCountOrAll
   * <  0 - группировка по количеству предметов на каждую дату
   * =  0 - выборка всего расписания на диапазон
   * >  0 - первых limitOrCountOrAll записей
   */
  studentTimetable?: Maybe<Scalars['JSON']>;
  studentTimetableV2?: Maybe<Array<Maybe<Timetable>>>;
  /**
   * Расписание текущего расписания + limit
   * для отрисовки текущего текущей пары и слдующих ( limit )
   * dateNow = YYYY-MM-DDTHH:mm ( iso ) | null - server now()
   */
  studentTimetableWithTime?: Maybe<Scalars['JSON']>;
  /**  Доступные года для расписания */
  studentTimetableYears?: Maybe<Scalars['JSON']>;
  studentsAchievementsRating?: Maybe<Scalars['JSON']>;
  teacherDisciplineGroups?: Maybe<Scalars['JSON']>;
  teacherList?: Maybe<Scalars['JSON']>;
  teacherPosition?: Maybe<TeacherPosition>;
  teacherPositions?: Maybe<Scalars['JSON']>;
  teacherTimetable?: Maybe<Scalars['JSON']>;
  tgQueueStats?: Maybe<Scalars['JSON']>;
  /** Полйчение своего тутора */
  tutor?: Maybe<Scalars['JSON']>;
  uiNotifications?: Maybe<Scalars['JSON']>;
  uiNotificationsStats?: Maybe<Scalars['JSON']>;
  /**
   * Список доступных загрузок пользователя
   * - category (opt) - категория: avatar, achievement тид
   */
  uploads?: Maybe<Scalars['JSON']>;
  userInfo?: Maybe<Scalars['JSON']>;
  utilConfig?: Maybe<Scalars['JSON']>;
  utilDebugPersons?: Maybe<Scalars['JSON']>;
  utilXLS2JSON?: Maybe<Scalars['JSON']>;
};


export type QueryEducationPlanListArgs = {
  pagination?: InputMaybe<PaginationType>;
};


export type QueryAccruedScholarshipArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
  mode?: InputMaybe<Scalars['String']>;
};


export type QueryAchievementAllRatingArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAchievementDirectionCountersArgs = {
  direction_key: Scalars['Int'];
  id?: InputMaybe<Scalars['UUID']>;
};


export type QueryAchievementDirectionsArgs = {
  is_admin?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAchievementRatingArgs = {
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryAchievementRatingByIdArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


export type QueryAchievementStatusesArgs = {
  all?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAchievementTemplatesArgs = {
  catid?: InputMaybe<Scalars['Int']>;
};


export type QueryAchievementTypesArgs = {
  catid?: InputMaybe<Scalars['Int']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['Int']>;
};


export type QueryAchievementsArgs = {
  input: AchievementsInput;
};


export type QueryAchievementsExportProfileArgs = {
  id?: InputMaybe<Scalars['UUID']>;
  p?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAverageGradeArgs = {
  input: AverageGradeInput;
};


export type QueryCatalogsArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCheckFilesArgs = {
  files: Array<FileEntryInput>;
};


export type QueryDepartmentListArgs = {
  input?: InputMaybe<DepartmentListInput>;
};


export type QueryDepartmentSpecsArgs = {
  input: DepartmentSpecsInput;
};


export type QueryDepartmentsArgs = {
  mode?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Scalars['JSON']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryDisciplinesArgs = {
  group: Scalars['String'];
};


export type QueryDormitoryContractsArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryDormitoryPaymentsArgs = {
  number_contract?: InputMaybe<Scalars['String']>;
};


export type QueryEcCheckCompletionArgs = {
  user_id?: InputMaybe<Scalars['String']>;
};


export type QueryEcComputeScoresArgs = {
  input: ComputeScoresInput;
};


export type QueryEcCreateData22_18Args = {
  cache?: InputMaybe<Scalars['Boolean']>;
  mode: EffectiveStatsExportMode;
};


export type QueryEcDataTableByUserAndStatusArgs = {
  input: EcDataTableByUserAndStatusInput;
};


export type QueryEcExportTable_EffectiveStatsArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
  mode: EffectiveStatsExportMode;
  name: Scalars['String'];
};


export type QueryEcGetBaselineCompletionArgs = {
  dataType: Array<InputMaybe<Scalars['String']>>;
  user_id?: InputMaybe<Scalars['String']>;
};


export type QueryEcGetCompletionStatsArgs = {
  dataType: Scalars['String'];
  user_id?: InputMaybe<Scalars['String']>;
};


export type QueryEcGetScoresTableBySelectorArgs = {
  dataTypeSelector?: InputMaybe<DataTypeSelector>;
  extra?: InputMaybe<Scalars['JSON']>;
  user_id?: InputMaybe<Scalars['String']>;
};


export type QueryEcGraphDataArgs = {
  dataTypeSelector?: InputMaybe<DataTypeSelector>;
  extra?: InputMaybe<Scalars['JSON']>;
  user_id?: InputMaybe<Scalars['String']>;
};


export type QueryEcGraphDataTotalsArgs = {
  user_id?: InputMaybe<Scalars['String']>;
};


export type QueryEcListAwardsArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type QueryEcListDataArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type QueryEcListEducationOfIndividualsArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type QueryEcListProffEventsArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type QueryEcQueryBaseLineDataArgs = {
  user_id: Scalars['String'];
};


export type QueryEducationPaymentsHistoryArgs = {
  contract?: InputMaybe<Scalars['String']>;
};


export type QueryEducationPlanArgs = {
  input?: InputMaybe<EducationPlanInput>;
};


export type QueryEducationPlanDisciplinesArgs = {
  input?: InputMaybe<EducationPlanDisciplinesInput>;
};


export type QueryEsBroadcastFacultativesArgs = {
  discipline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<DisciplineType>;
};


export type QueryEsBroadcastSignedTracksArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


export type QueryEsDisciplineProcedureArgs = {
  type: DisciplineType;
};


export type QueryEsDisciplineStatsArgs = {
  input?: InputMaybe<DisciplineStatsInput>;
};


export type QueryEsDisciplinesBySemestersArgs = {
  input: DisciplinesBySemestersInput;
};


export type QueryEsDisciplinesStateArgs = {
  type?: InputMaybe<DisciplineType>;
};


export type QueryEsEducationPlanArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryEsFacultativeArgs = {
  input: FacultativeInput;
};


export type QueryEsGetSignStatusArgs = {
  id: Scalars['UUID'];
};


export type QueryEsMoodleCoursesByDisciplineArgs = {
  discipline_name: Scalars['String'];
  semester?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryEsSignedStudentsArgs = {
  input: SignedStudentsInput;
};


export type QueryEsTrackDisciplinesArgs = {
  input?: InputMaybe<TrackDisciplinesInput>;
};


export type QueryEsTrackListArgs = {
  input: TrackListInput;
};


export type QueryEsTrackStudentsArgs = {
  input: TrackStudentsInput;
};


export type QueryEsXls_DisciplineStatsArgs = {
  type: DisciplineType;
  view?: InputMaybe<Scalars['Int']>;
};


export type QueryEsbMessageHistoryArgs = {
  input: MessageHistoryInput;
};


export type QueryEsbMessageHistoryStatsArgs = {
  input?: InputMaybe<MessageHistoryStatsInput>;
};


export type QueryExportRatingPdfArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryExportStudentAchievementRatingArgs = {
  id?: InputMaybe<Scalars['UUID']>;
  p?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFileExistsArgs = {
  category: Scalars['String'];
  name: Scalars['String'];
};


export type QueryFinAccruedScholarshipArgs = {
  input?: InputMaybe<AccruedScholarshipInput>;
};


export type QueryFinEducationContractsArgs = {
  input?: InputMaybe<EducationContractsInput>;
};


export type QueryGetDirectionsWithAchievementsArgs = {
  available?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  status?: InputMaybe<AchievementStatus>;
};


export type QueryGetEmployeePositionArgs = {
  guidFL: Scalars['UUID'];
  onTheDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGetNewsFromIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetPresignedDownloadUrlArgs = {
  fileId: Scalars['String'];
};


export type QueryGetStudentJournalArgs = {
  discipline: Scalars['String'];
  group: Scalars['String'];
  semester?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};


export type QueryJournalArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  group: Scalars['String'];
  options?: InputMaybe<Scalars['JSON']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryLibRuslanBookArgs = {
  db: RuslanDatabases;
  id: Scalars['String'];
};


export type QueryLibRuslanDatabaseArgs = {
  count: Scalars['Int'];
  db: RuslanDatabases;
  raw?: InputMaybe<Scalars['Boolean']>;
  start: Scalars['Int'];
};


export type QueryListNameProfsWithAcceptedStudentsArgs = {
  namespec?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryListNameSpecsWithAcceptedStudentsArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
};


export type QueryMfcGetFormArgs = {
  id: Scalars['UUID'];
};


export type QueryMfcGetFormTypesArgs = {
  statementType?: InputMaybe<MfcStatementType>;
};


export type QueryMfcStatementsArgs = {
  input: MfcStatementsInput;
};


export type QueryMfcStatementsStatsArgs = {
  type?: InputMaybe<MfcStatementType>;
};


export type QueryMoodleCoursesArgs = {
  input?: InputMaybe<MoodleCoursesInput>;
};


export type QueryNameprofsArgs = {
  pagination?: InputMaybe<Scalars['JSON']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryNameprofsListArgs = {
  input?: InputMaybe<NameProfsListInput>;
};


export type QueryNamespecsArgs = {
  pagination?: InputMaybe<Scalars['JSON']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryNamespecsListArgs = {
  input?: InputMaybe<NameSpecsListInput>;
};


export type QueryNearestTimetableArgs = {
  count?: InputMaybe<Scalars['Int']>;
  group: Scalars['String'];
};


export type QueryNewsArgs = {
  count?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryNewsFeedArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryNewsTagsArgs = {
  eventType?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryNotificationDraftArgs = {
  input?: InputMaybe<NotificationDraftInput>;
};


export type QueryNotificationListArgs = {
  input?: InputMaybe<NotificationListInput>;
};


export type QueryNotificationSplashArgs = {
  title?: InputMaybe<Scalars['String']>;
};


export type QueryNotificationsArgs = {
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  useCount?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNotificationsCountArgs = {
  status?: InputMaybe<Scalars['String']>;
};


export type QueryPaCommentsArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type QueryPaGetAllArgs = {
  pagination?: InputMaybe<Scalars['JSON']>;
  params?: InputMaybe<Scalars['JSON']>;
};


export type QueryPaGetConfigArgs = {
  configName?: InputMaybe<Scalars['String']>;
  defaultConfig?: InputMaybe<Scalars['JSON']>;
};


export type QueryPaGetProjectArgs = {
  req_id?: InputMaybe<Scalars['Int']>;
};


export type QueryPaGetTaskArgs = {
  task_id?: InputMaybe<Scalars['Int']>;
};


export type QueryPaGetTeamArgs = {
  req_id?: InputMaybe<Scalars['Int']>;
};


export type QueryPaListProjectsArgs = {
  pagination?: InputMaybe<Scalars['JSON']>;
  params?: InputMaybe<Scalars['JSON']>;
};


export type QueryPaProjectSeminarGroupDetailArgs = {
  input: ProjectSeminarGroupDetailInput;
};


export type QueryPaProjectThemesArgs = {
  input?: InputMaybe<ProjectThemesInput>;
};


export type QueryPaStagesArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type QueryPaTasksArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type QueryPaTeacherTimetableLookupArgs = {
  input: TeacherTimetableLookupInput;
};


export type QueryPersonnelConfigArgs = {
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPersonnelPositionArgs = {
  isPrevYear?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['UUID']>;
};


export type QueryPersonnelPositionSafeArgs = {
  user_id?: InputMaybe<Scalars['String']>;
};


export type QueryPersonsArgs = {
  match?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Scalars['JSON']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryPositionsArgs = {
  pagination?: InputMaybe<Scalars['JSON']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryQuittancesArgs = {
  contract?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryQuittancesBanksArgs = {
  contract?: InputMaybe<Scalars['String']>;
};


export type QueryRecordbookArgs = {
  debug_guid?: InputMaybe<Scalars['String']>;
  recordbook_number?: InputMaybe<Scalars['String']>;
};


export type QueryRecordbookBySemesterArgs = {
  id?: InputMaybe<Scalars['UUID']>;
  recordbook_number?: InputMaybe<Scalars['String']>;
  semester?: InputMaybe<Scalars['Int']>;
};


export type QueryRecordbooksArgs = {
  id?: InputMaybe<Scalars['UUID']>;
  recordbook_number?: InputMaybe<Scalars['String']>;
};


export type QueryReferenceEmployeeCatalogArgs = {
  type: EmployeeReferenceCatalog;
};


export type QueryReferenceGetFormFieldDataArgs = {
  input: ReferenceFormFieldDataInput;
};


export type QueryReferenceListArgs = {
  input?: InputMaybe<ReferenceListInput>;
};


export type QueryReferencesArgs = {
  mode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryRequiredStudentPapersArgs = {
  input: RequiredStudentPapersInput;
};


export type QuerySmsCodeCheckArgs = {
  code: Scalars['String'];
};


export type QuerySmsGetInfoArgs = {
  input: SmsGetInfoInput;
};


export type QuerySpecialitiesArgs = {
  mode?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Scalars['JSON']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerySpecializationArgs = {
  id: Scalars['String'];
};


export type QuerySpecialtyArgs = {
  id: Scalars['String'];
};


export type QueryStudentAchievementStatsArgs = {
  input: StudentAchievementStatsInput;
};


export type QueryStudentDataLookupArgs = {
  input: StudentDataLookupInput;
};


export type QueryStudentInfoByIdArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryStudentInfoWithFlArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryStudentTimetableArgs = {
  dateEnd?: InputMaybe<Scalars['String']>;
  dateStart?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limitOrCountOrAll?: InputMaybe<Scalars['Int']>;
};


export type QueryStudentTimetableV2Args = {
  input: StudentTimeTableInput;
};


export type QueryStudentTimetableWithTimeArgs = {
  dateNow?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryStudentTimetableYearsArgs = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryStudentsAchievementsRatingArgs = {
  input: AchievementsRatingInput;
};


export type QueryTeacherDisciplineGroupsArgs = {
  testID?: InputMaybe<Scalars['String']>;
};


export type QueryTeacherListArgs = {
  pagination?: InputMaybe<Scalars['JSON']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryTeacherPositionArgs = {
  position_id?: InputMaybe<Scalars['UUID']>;
};


export type QueryTeacherPositionsArgs = {
  match?: InputMaybe<Scalars['JSON']>;
  pagination?: InputMaybe<Scalars['JSON']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryTeacherTimetableArgs = {
  dateEnd?: InputMaybe<Scalars['String']>;
  dateStart?: InputMaybe<Scalars['String']>;
  teacher_guid?: InputMaybe<Scalars['String']>;
};


export type QueryTutorArgs = {
  args?: InputMaybe<Scalars['JSON']>;
};


export type QueryUiNotificationsArgs = {
  params?: InputMaybe<Scalars['JSON']>;
};


export type QueryUploadsArgs = {
  category?: InputMaybe<Scalars['String']>;
};


export type QueryUserInfoArgs = {
  user_id: Scalars['String'];
};


export type QueryUtilConfigArgs = {
  domain?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
};


export type QueryUtilDebugPersonsArgs = {
  input: DebugPersonsInput;
};


export type QueryUtilXls2JsonArgs = {
  upload_id: Scalars['Int'];
};

export type RatingList = {
  __typename?: 'RatingList';
  filter?: Maybe<Scalars['JSON']>;
  items?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  metrics?: Maybe<RatingMetrics>;
  offset?: Maybe<Scalars['Int']>;
};

export type RatingMetrics = {
  __typename?: 'RatingMetrics';
  count?: Maybe<Scalars['Int']>;
  filteredCount?: Maybe<Scalars['Int']>;
  filteredMaxRating?: Maybe<Scalars['Int']>;
  maxRating?: Maybe<Scalars['Int']>;
  remains?: Maybe<Scalars['Int']>;
};

export type Recordbook = {
  __typename?: 'Recordbook';
  course?: Maybe<Scalars['Int']>;
  department?: Maybe<Scalars['String']>;
  eform?: Maybe<Scalars['String']>;
  elevel?: Maybe<Scalars['String']>;
  nameprof?: Maybe<Scalars['String']>;
  namespec?: Maybe<Scalars['String']>;
  programresults?: Maybe<Array<Maybe<ProgramResults>>>;
  recordbook_file?: Maybe<Scalars['String']>;
  recordbook_number?: Maybe<Scalars['String']>;
};

export type ReferenceFormFieldDataInput = {
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
  type: Scalars['String'];
};

export type ReferenceListInput = {
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
  status?: InputMaybe<Array<InputMaybe<ReferenceStatus>>>;
};

export enum ReferenceStatus {
  New = 'new',
  Process = 'process',
  Ready = 'ready',
  Rejected = 'rejected',
  Sent = 'sent'
}

export type RemoveFile = {
  __typename?: 'RemoveFile';
  result?: Maybe<Scalars['Boolean']>;
};

export type RemoveFileResult = FileNotFoundError | InvalidArgumentError | RemoveFile;

export type RequiredStudentPapersInput = {
  id?: InputMaybe<Scalars['UUID']>;
  recordbook_guid?: InputMaybe<Scalars['UUID']>;
  recordbook_number?: InputMaybe<Scalars['String']>;
};

export enum RuslanDatabases {
  Books = 'BOOKS',
  Diss = 'DISS',
  Elib = 'ELIB',
  Hudabon = 'HUDABON',
  Kaf = 'KAF',
  Marsd = 'MARSD',
  Marsd1 = 'MARSD1',
  Marsd2 = 'MARSD2',
  Marsd3 = 'MARSD3',
  Marsd4 = 'MARSD4',
  Periodic = 'PERIODIC',
  Serial = 'SERIAL',
  Serial12 = 'SERIAL12',
  Spl = 'SPL',
  Test = 'TEST'
}

export type SmsGetInfoInput = {
  code?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationType>;
  phone?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
  source?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['UUID']>;
};

export type SignOutFacultativeInput = {
  facultative_id: Scalars['Int'];
  participant_id: Array<Scalars['UUID']>;
  type?: InputMaybe<DisciplineType>;
};

export type SignOutTrackInput = {
  semester: Scalars['Int'];
  student_id?: InputMaybe<Scalars['UUID']>;
  track_id: Scalars['Int'];
};

export type SignOutTrackStudentInput = {
  moder_del_all_disciplines?: InputMaybe<Scalars['Boolean']>;
  semester: Scalars['Int'];
  student_id: Scalars['UUID'];
  track_id: Scalars['Int'];
};

export type SignUpFacultativeInput = {
  facultative_id: Scalars['Int'];
  level?: InputMaybe<Scalars['String']>;
  participants: Scalars['JSON'];
  type?: InputMaybe<DisciplineType>;
};

export type SignUpTrackInput = {
  participants?: InputMaybe<Scalars['JSON']>;
  semester: Scalars['Int'];
  track_id: Scalars['Int'];
};

export type SignedStudentsInput = {
  facultative_id: Scalars['Int'];
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
  type?: InputMaybe<DisciplineType>;
};

export type SortField = {
  column: Scalars['String'];
  nulls?: InputMaybe<Scalars['String']>;
  order: SortOrder;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum SpecListType {
  Nameprofs = 'nameprofs',
  Namespecs = 'namespecs'
}

export type Specialization = {
  __typename?: 'Specialization';
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type Specialty = {
  __typename?: 'Specialty';
  id: Scalars['UUID'];
  name: Scalars['String'];
  specializations?: Maybe<Array<Maybe<Specialization>>>;
};

export type StudentAchievementStats = {
  __typename?: 'StudentAchievementStats';
  available?: Maybe<Scalars['Int']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  last_activity?: Maybe<Scalars['PGDate']>;
  notavailable?: Maybe<Scalars['Int']>;
};

export type StudentAchievementStatsInput = {
  filter?: InputMaybe<AchievementFullFilter>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

export enum StudentDataLookupFields {
  Basis = 'basis',
  Course = 'course',
  Department = 'department',
  Eform = 'eform',
  Elevel = 'elevel',
  FullName = 'full_name',
  Group = 'group',
  Id = 'id',
  Nameprof = 'nameprof',
  Namespec = 'namespec',
  RecordbookCode = 'recordbook_code',
  RecordbookGuid = 'recordbook_guid',
  RecordbookNumber = 'recordbook_number',
  Status = 'status'
}

export type StudentDataLookupFilter = {
  field: StudentDataLookupFields;
  search?: InputMaybe<Scalars['String']>;
};

export type StudentDataLookupInput = {
  filter: StudentDataLookupFilter;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

export type StudentTimeTableInput = {
  course?: InputMaybe<Scalars['Int']>;
  end: Scalars['String'];
  group: Scalars['String'];
  start: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  achievementChanged?: Maybe<Scalars['JSON']>;
  achievementDirectionsChanged?: Maybe<Scalars['JSON']>;
  achievementTypeChanged?: Maybe<Scalars['JSON']>;
  avatarChanged?: Maybe<Scalars['JSON']>;
  configChanged?: Maybe<Scalars['JSON']>;
  contractsChanged?: Maybe<Scalars['JSON']>;
  ecExportsProgress?: Maybe<Scalars['JSON']>;
  esTracksChanged?: Maybe<Scalars['JSON']>;
  esbMessage?: Maybe<Scalars['JSON']>;
  facultativesChanged?: Maybe<Scalars['JSON']>;
  notificationDraftChanged?: Maybe<Scalars['JSON']>;
  onSessionCreated?: Maybe<Scalars['JSON']>;
  onSessionExpired?: Maybe<Scalars['JSON']>;
  personnelConfigChanged?: Maybe<Scalars['JSON']>;
  progressEvent?: Maybe<Scalars['JSON']>;
  projectChanged?: Maybe<Scalars['JSON']>;
  projectIntroChanged?: Maybe<Scalars['JSON']>;
  referenceChanged?: Maybe<Scalars['JSON']>;
  smsCodeChanged?: Maybe<Scalars['JSON']>;
  uiNotification?: Maybe<Scalars['JSON']>;
  uploadsChanged?: Maybe<Scalars['JSON']>;
};

export type TeacherPosition = {
  __typename?: 'TeacherPosition';
  id?: Maybe<Scalars['UUID']>;
  position_name?: Maybe<Scalars['String']>;
};

export type TeacherTimetableLookupInput = {
  dateStart?: InputMaybe<Scalars['String']>;
  discipline?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

export type Timetable = {
  __typename?: 'Timetable';
  couple?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  day?: Maybe<Scalars['Int']>;
  dayName?: Maybe<Scalars['String']>;
  dayShort?: Maybe<Scalars['String']>;
  discipline?: Maybe<Scalars['String']>;
  dow?: Maybe<Scalars['Int']>;
  dtEnd?: Maybe<Scalars['String']>;
  dtStart?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  exercise?: Maybe<Scalars['String']>;
  group_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  moment?: Maybe<TimetableMoment>;
  monthName?: Maybe<Scalars['String']>;
  monthShort?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  study_period?: Maybe<Scalars['String']>;
  teachers?: Maybe<Array<Maybe<TimetableTeacher>>>;
  type?: Maybe<Scalars['String']>;
  week?: Maybe<Scalars['Int']>;
};

export enum TimetableMoment {
  Next = 'next',
  Now = 'now',
  Past = 'past'
}

export type TimetableTeacher = {
  __typename?: 'TimetableTeacher';
  guid?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type Track = {
  __typename?: 'Track';
  created?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  disciplines?: Maybe<Array<Maybe<TrackDiscipline>>>;
  file?: Maybe<Scalars['JSON']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_signed?: Maybe<Scalars['Boolean']>;
  is_visible?: Maybe<Scalars['Boolean']>;
  max_participants?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  sign_count?: Maybe<Scalars['Int']>;
  students?: Maybe<Scalars['JSON']>;
  track_id?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['String']>;
};

export type TrackDiscipline = {
  __typename?: 'TrackDiscipline';
  created?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  discipline?: Maybe<Scalars['String']>;
  discipline_id?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  hours?: Maybe<Scalars['Int']>;
  info?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_visible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  semester?: Maybe<Scalars['Int']>;
  students?: Maybe<Scalars['JSON']>;
  teachers?: Maybe<Scalars['JSON']>;
  themes?: Maybe<Scalars['JSON']>;
  track_id?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['String']>;
};

export type TrackDisciplineUpdateInput = {
  details?: InputMaybe<Scalars['String']>;
  discipline_id: Scalars['Int'];
  hours?: InputMaybe<Scalars['Int']>;
  info?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  semester?: InputMaybe<Scalars['Int']>;
  teachers?: InputMaybe<Scalars['JSON']>;
  themes?: InputMaybe<Scalars['JSON']>;
  track_id: Scalars['Int'];
};

export type TrackDisciplinesFilter = {
  data?: InputMaybe<TrackListFilter>;
  discipline?: InputMaybe<Scalars['String']>;
  discipline_id?: InputMaybe<Scalars['Int']>;
  info?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_visible?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  semester?: InputMaybe<Scalars['Int']>;
  track_id?: InputMaybe<Scalars['Int']>;
};

export type TrackDisciplinesInput = {
  filter?: InputMaybe<TrackDisciplinesFilter>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

export type TrackListFilter = {
  course?: InputMaybe<Scalars['Int']>;
  department?: InputMaybe<Scalars['String']>;
  eform?: InputMaybe<Scalars['String']>;
  elevel?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  is_moderator?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nameprof?: InputMaybe<Scalars['String']>;
  namespec?: InputMaybe<Scalars['String']>;
  semester?: InputMaybe<Scalars['Int']>;
  student_id?: InputMaybe<Scalars['UUID']>;
  track_id?: InputMaybe<Scalars['Int']>;
};

export type TrackListInput = {
  filter?: InputMaybe<TrackListFilter>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

export type TrackResult = {
  __typename?: 'TrackResult';
  items?: Maybe<Array<Maybe<Track>>>;
  pagination?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['JSON']>;
};

export type TrackStudents = {
  __typename?: 'TrackStudents';
  disciplines?: Maybe<Array<Maybe<Scalars['String']>>>;
  full_name?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['UUID']>;
  student_info?: Maybe<Scalars['JSON']>;
  track_id?: Maybe<Scalars['Int']>;
};

export type TrackStudentsInput = {
  student_id?: InputMaybe<Scalars['UUID']>;
  track_id?: InputMaybe<Scalars['Int']>;
};

export type TrackUpdateInput = {
  data?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['JSON']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_visible?: InputMaybe<Scalars['Boolean']>;
  max_participants?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  students?: InputMaybe<Scalars['JSON']>;
  track_id: Scalars['Int'];
};

export type UiNotificationInput = {
  body: Scalars['JSON'];
  expired?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['UUID']>;
  meta?: InputMaybe<Scalars['JSON']>;
  source?: InputMaybe<Scalars['String']>;
  started?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type UpdateAchievementInput = {
  achievement_id: Scalars['Int'];
  catid?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['JSON']>;
  fields?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<AchievementStatus>;
  type_id?: InputMaybe<Scalars['Int']>;
  userpoints?: InputMaybe<Scalars['Int']>;
};

export type UpdateConfigInput = {
  config?: InputMaybe<Scalars['JSON']>;
  /**  logical domain  */
  domain: Scalars['String'];
  /**  при удалении, key может быть = *,тогда грохнется весь domain  */
  key: Scalars['String'];
  /**  Default merges   */
  mode?: InputMaybe<UpdateConfigMode>;
};

export enum UpdateConfigMode {
  Delete = 'delete',
  Merge = 'merge',
  Replace = 'replace'
}

export type UpdateStatsInput = {
  counter: UpdateStatsType;
  increment?: InputMaybe<Scalars['Int']>;
  namespec: Scalars['String'];
  ref_id: Scalars['Int'];
  type: DisciplineType;
};

export enum UpdateStatsType {
  In = 'in',
  Out = 'out',
  View = 'view'
}

export type WorkspaceIcon = {
  __typename?: 'WorkspaceIcon';
  iconDescription?: Maybe<Scalars['String']>;
  iconID: Scalars['ID'];
  iconImage?: Maybe<Scalars['String']>;
  iconLink?: Maybe<Scalars['String']>;
  iconName?: Maybe<Scalars['String']>;
  iconSort?: Maybe<Scalars['Int']>;
};

export type EcDataTableByUserAndStatusFilter = {
  dataType?: InputMaybe<Array<InputMaybe<DataTypeInput>>>;
  institutes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  positions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EcDataTableByUserAndStatusInput = {
  filter?: InputMaybe<EcDataTableByUserAndStatusFilter>;
  mode?: InputMaybe<EcDataTableByUserAndStatusMode>;
  pagination?: InputMaybe<PaginationType>;
  sort?: InputMaybe<Array<InputMaybe<SortField>>>;
};

export enum EcDataTableByUserAndStatusMode {
  Ams = 'ams',
  AmsAppeal = 'amsAppeal',
  Appeal = 'appeal',
  Common = 'common'
}

export type EsEducationPlanQueryVariables = Exact<{
  esEducationPlanId?: InputMaybe<Scalars['String']>;
}>;


export type EsEducationPlanQuery = { __typename?: 'Query', esEducationPlan?: { __typename?: 'EducationPlan', disciplineList?: Array<{ __typename?: 'Discipline', ep_id?: any | null, id?: any | null, quantity?: number | null, module?: string | null, semester?: number | null, title?: string | null } | null> | null } | null };

export type EducationPlanListQueryVariables = Exact<{ [key: string]: never; }>;


export type EducationPlanListQuery = { __typename?: 'Query', EducationPlanList?: Array<{ __typename?: 'EducationPlan', ep_id?: any | null, nameprof?: string | null, namespec?: string | null, admission_end?: number | null, admission_start?: number | null } | null> | null };

export type OnProjectAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnProjectAddedSubscription = { __typename?: 'Subscription', projectChanged?: any | null };

export type GetNameProfsQueryVariables = Exact<{
  input?: InputMaybe<NameProfsListInput>;
}>;


export type GetNameProfsQuery = { __typename?: 'Query', nameprofsList?: any | null };

export type GetSpecsNameQueryVariables = Exact<{
  input?: InputMaybe<NameSpecsListInput>;
}>;


export type GetSpecsNameQuery = { __typename?: 'Query', namespecsList?: any | null };

export type CommitMutationVariables = Exact<{ [key: string]: never; }>;


export type CommitMutation = { __typename?: 'Mutation', ecCommitEducationOfIndividials?: any | null };

export type FrontCheckVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type FrontCheckVersionQuery = { __typename?: 'Query', build?: any | null, ios?: any | null, android?: any | null };

export type StudentDataLookupQueryVariables = Exact<{
  input: StudentDataLookupInput;
}>;


export type StudentDataLookupQuery = { __typename?: 'Query', studentDataLookup?: any | null };

export type SmsRequestMutationVariables = Exact<{
  phone: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  expire?: InputMaybe<Scalars['Int']>;
  fake?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
}>;


export type SmsRequestMutation = { __typename?: 'Mutation', smsCodeRequest?: any | null };

export type CheckCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type CheckCodeQuery = { __typename?: 'Query', smsCodeCheck?: any | null };

export type DeleteUploadMutationVariables = Exact<{
  upload_ids: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type DeleteUploadMutation = { __typename?: 'Mutation', deleteUpload?: any | null };

export type EcInstListQueryVariables = Exact<{ [key: string]: never; }>;


export type EcInstListQuery = { __typename?: 'Query', catalogs?: any | null };

export type DepListQueryVariables = Exact<{
  input?: InputMaybe<DepartmentListInput>;
}>;


export type DepListQuery = { __typename?: 'Query', departmentList?: any | null };

export type GetSplashQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSplashQuery = { __typename?: 'Query', notificationSplash?: any | null };

export type EcExportTable_BaseStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type EcExportTable_BaseStatsQuery = { __typename?: 'Query', ecExportTable_BaseStats?: any | null };

export type EcExportTable_1_1QueryVariables = Exact<{ [key: string]: never; }>;


export type EcExportTable_1_1Query = { __typename?: 'Query', ecExportTable_1_1?: any | null };

export type EcExportTable_3_5QueryVariables = Exact<{ [key: string]: never; }>;


export type EcExportTable_3_5Query = { __typename?: 'Query', ecExportTable_3_5?: any | null };

export type EcExportTable_EffectiveStats_AllQueryVariables = Exact<{ [key: string]: never; }>;


export type EcExportTable_EffectiveStats_AllQuery = { __typename?: 'Query', ecExportTable_EffectiveStats?: any | null };

export type EcExportTable_EffectiveStats_MounthlyQueryVariables = Exact<{ [key: string]: never; }>;


export type EcExportTable_EffectiveStats_MounthlyQuery = { __typename?: 'Query', ecExportTable_EffectiveStats?: any | null };

export type EcExportTable_EffectiveStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type EcExportTable_EffectiveStatsQuery = { __typename?: 'Query', ecExportTable_EffectiveStats?: any | null };

export type ConfigChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ConfigChangedSubscription = { __typename?: 'Subscription', configChanged?: any | null };

export type GenerateQueryVariables = Exact<{ [key: string]: never; }>;


export type GenerateQuery = { __typename?: 'Query', ecCreateData22_18?: any | null };

export type EcCreateDataMutationVariables = Exact<{
  dataType: Scalars['String'];
  data: Scalars['JSON'];
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  extra?: InputMaybe<Scalars['JSON']>;
}>;


export type EcCreateDataMutation = { __typename?: 'Mutation', ecCreateData?: any | null };

export type EcDeleteDataMutationVariables = Exact<{
  rec_id: Scalars['Int'];
  dataType: Scalars['String'];
}>;


export type EcDeleteDataMutation = { __typename?: 'Mutation', ecDeleteData?: any | null };

export type EcTable_2_8_CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type EcTable_2_8_CategoriesQuery = { __typename?: 'Query', catalogs?: any | null };

export type EcTable2_8_RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type EcTable2_8_RolesQuery = { __typename?: 'Query', catalogs?: any | null };

export type TeacherPositionsQueryVariables = Exact<{ [key: string]: never; }>;


export type TeacherPositionsQuery = { __typename?: 'Query', teacherPositions?: any | null };

export type GetModeratorPosFilterQueryVariables = Exact<{ [key: string]: never; }>;


export type GetModeratorPosFilterQuery = { __typename?: 'Query', catalogs?: any | null };

export type OnContractChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnContractChangeSubscription = { __typename?: 'Subscription', contractsChanged?: any | null };

export type EcListDataQueryVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type EcListDataQuery = { __typename?: 'Query', ecListData?: any | null };

export type EcUpdateDataTypeMutationVariables = Exact<{
  rec_id: Scalars['Int'];
  dataType?: InputMaybe<Scalars['String']>;
}>;


export type EcUpdateDataTypeMutation = { __typename?: 'Mutation', ecUpdateDataType?: any | null };

export type GetScoresBySelectorQueryVariables = Exact<{
  user_id?: InputMaybe<Scalars['String']>;
  selector?: InputMaybe<DataTypeSelector>;
  extra?: InputMaybe<Scalars['JSON']>;
}>;


export type GetScoresBySelectorQuery = { __typename?: 'Query', ecGetScoresTableBySelector?: any | null };

export type CheckCompletionQueryVariables = Exact<{
  user_id?: InputMaybe<Scalars['String']>;
}>;


export type CheckCompletionQuery = { __typename?: 'Query', ecCheckCompletion?: any | null };

export type GetBaseLineQueryVariables = Exact<{
  user_id?: InputMaybe<Scalars['String']>;
  dataType: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type GetBaseLineQuery = { __typename?: 'Query', ecGetBaselineCompletion?: any | null };

export type GetComplitionsStatsQueryVariables = Exact<{
  user_id?: InputMaybe<Scalars['String']>;
  dataType: Scalars['String'];
}>;


export type GetComplitionsStatsQuery = { __typename?: 'Query', ecGetCompletionStats?: any | null };

export type GetGraphDataTotalsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type GetGraphDataTotalsQuery = { __typename?: 'Query', ecGraphDataTotals?: any | null };

export type UtilConfigQueryVariables = Exact<{
  domain?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
}>;


export type UtilConfigQuery = { __typename?: 'Query', utilConfig?: any | null };

export type TeachersQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Scalars['JSON']>;
}>;


export type TeachersQuery = { __typename?: 'Query', teacherList?: any | null };

export type EcDataTableByUserAndStatusQueryVariables = Exact<{
  input: EcDataTableByUserAndStatusInput;
}>;


export type EcDataTableByUserAndStatusQuery = { __typename?: 'Query', ecDataTableByUserAndStatus?: any | null };

export type Import_22_16_17MutationVariables = Exact<{
  upload_id: Scalars['Int'];
  async?: InputMaybe<Scalars['Boolean']>;
}>;


export type Import_22_16_17Mutation = { __typename?: 'Mutation', ecImportTable_22_16x17?: any | null };

export type EcUpdateDataMutationVariables = Exact<{
  rec_id: Scalars['Int'];
  new_data?: InputMaybe<Scalars['JSON']>;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  new_extra?: InputMaybe<Scalars['JSON']>;
  new_appeal?: InputMaybe<Scalars['JSON']>;
}>;


export type EcUpdateDataMutation = { __typename?: 'Mutation', ecUpdateData?: any | null };

export type UtilUpdateConfigMutationVariables = Exact<{
  input: UpdateConfigInput;
}>;


export type UtilUpdateConfigMutation = { __typename?: 'Mutation', utilUpdateConfig?: any | null };

export type GetFormDataQueryVariables = Exact<{
  input: ReferenceFormFieldDataInput;
}>;


export type GetFormDataQuery = { __typename?: 'Query', referenceGetFormFieldData?: any | null };

export type GetApplicationsQueryVariables = Exact<{
  input: MfcStatementsInput;
}>;


export type GetApplicationsQuery = { __typename?: 'Query', mfcStatements?: any | null };

export type GetFormTypesQueryVariables = Exact<{
  statementType?: InputMaybe<MfcStatementType>;
}>;


export type GetFormTypesQuery = { __typename?: 'Query', mfcGetFormTypes?: any | null };

export type GetFormQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetFormQuery = { __typename?: 'Query', mfcGetForm?: any | null };

export type SendStatementMutationVariables = Exact<{
  payload: Scalars['JSON'];
  type: MfcStatementType;
}>;


export type SendStatementMutation = { __typename?: 'Mutation', mfcSendStatement?: any | null };

export type ReferenceStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type ReferenceStatusQuery = { __typename?: 'Query', references?: any | null };

export type ReferenceListQueryVariables = Exact<{
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type ReferenceListQuery = { __typename?: 'Query', references?: any | null };

export type CreateReferenceMutationVariables = Exact<{
  payload?: InputMaybe<Scalars['JSON']>;
}>;


export type CreateReferenceMutation = { __typename?: 'Mutation', createReference?: any | null };

export type CommitReferencesMutationVariables = Exact<{
  doc_id?: InputMaybe<Scalars['Int']>;
}>;


export type CommitReferencesMutation = { __typename?: 'Mutation', referenceCommit?: any | null };

export type ReferenceChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ReferenceChangedSubscription = { __typename?: 'Subscription', referenceChanged?: any | null };

export type NotificationDraftAddMutationVariables = Exact<{
  body: Scalars['JSON'];
}>;


export type NotificationDraftAddMutation = { __typename?: 'Mutation', notificationDraftAdd?: any | null };

export type NotificationDraftChangeActiveMutationVariables = Exact<{
  draft_id: Array<Scalars['Int']> | Scalars['Int'];
  is_active?: InputMaybe<Scalars['Boolean']>;
}>;


export type NotificationDraftChangeActiveMutation = { __typename?: 'Mutation', notificationDraftChangeActive?: any | null };

export type NotificationDraftDeleteMutationVariables = Exact<{
  draft_id: Scalars['Int'];
}>;


export type NotificationDraftDeleteMutation = { __typename?: 'Mutation', notificationDraftDelete?: any | null };

export type NotificationDraftQueryVariables = Exact<{
  input?: InputMaybe<NotificationDraftInput>;
}>;


export type NotificationDraftQuery = { __typename?: 'Query', notificationDraft?: any | null };

export type NotificationDraftFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationDraftFiltersQuery = { __typename?: 'Query', notificationDraftFilters?: any | null };

export type NotificationDraftChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationDraftChangedSubscription = { __typename?: 'Subscription', notificationDraftChanged?: any | null };

export type NotificationDraftPurgeMessagesMutationVariables = Exact<{
  draft_id: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type NotificationDraftPurgeMessagesMutation = { __typename?: 'Mutation', notificationDraftPurgeMessages?: any | null };

export type NotificationDraftResetMutationVariables = Exact<{
  draft_id: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type NotificationDraftResetMutation = { __typename?: 'Mutation', notificationDraftResetProcessed?: any | null };

export type OnAchievementChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnAchievementChangedSubscription = { __typename?: 'Subscription', achievementChanged?: any | null };

export type AchievementDirectionsChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AchievementDirectionsChangedSubscription = { __typename?: 'Subscription', achievementDirectionsChanged?: any | null };

export type DirectionsQueryVariables = Exact<{
  is_admin?: InputMaybe<Scalars['Boolean']>;
}>;


export type DirectionsQuery = { __typename?: 'Query', achievementDirections?: any | null };

export type StatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type StatusesQuery = { __typename?: 'Query', achievementStatuses?: any | null };

export type AchievementTypeChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AchievementTypeChangedSubscription = { __typename?: 'Subscription', achievementTypeChanged?: any | null };

export type AchievementTypes2QueryVariables = Exact<{
  catid?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['Int']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
}>;


export type AchievementTypes2Query = { __typename?: 'Query', achievementTypes?: any | null };

export type CreateAchievementMutationVariables = Exact<{
  input: CreateAchievementInput;
}>;


export type CreateAchievementMutation = { __typename?: 'Mutation', createAchievement?: any | null };

export type StudentsAchievementsRatingQueryVariables = Exact<{
  input: AchievementsRatingInput;
}>;


export type StudentsAchievementsRatingQuery = { __typename?: 'Query', studentsAchievementsRating?: any | null };

export type AverageGradeQueryVariables = Exact<{
  input: AverageGradeInput;
}>;


export type AverageGradeQuery = { __typename?: 'Query', averageGrade?: any | null };

export type SetUpdateConfigMutationVariables = Exact<{
  name: Scalars['String'];
  value: Scalars['String'];
}>;


export type SetUpdateConfigMutation = { __typename?: 'Mutation', updatePersonnelConfig?: any | null };

export type ChangeTypeDirectionMutationVariables = Exact<{
  input?: InputMaybe<ChangeTypeDirectionInput>;
}>;


export type ChangeTypeDirectionMutation = { __typename?: 'Mutation', changeTypeDirection?: any | null };

export type EditDirectionMutationVariables = Exact<{
  input?: InputMaybe<EditAchievementDirectionInput>;
}>;


export type EditDirectionMutation = { __typename?: 'Mutation', editAchievementDirection?: any | null };

export type EditAchievementTypeMutationVariables = Exact<{
  input?: InputMaybe<EditAchievementTypeInput>;
}>;


export type EditAchievementTypeMutation = { __typename?: 'Mutation', editAchievementType?: any | null };

export type DeleteAchievementMutationVariables = Exact<{
  achievement_id?: InputMaybe<Scalars['Int']>;
}>;


export type DeleteAchievementMutation = { __typename?: 'Mutation', deleteAchievement?: any | null };

export type GetAchievementRatingQueryVariables = Exact<{
  count?: InputMaybe<Scalars['Int']>;
}>;


export type GetAchievementRatingQuery = { __typename?: 'Query', achievementRating?: any | null };

export type GetDirectionsWithAchievementsQueryVariables = Exact<{
  id: Scalars['UUID'];
  available?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<AchievementStatus>;
}>;


export type GetDirectionsWithAchievementsQuery = { __typename?: 'Query', getDirectionsWithAchievements?: any | null };

export type PersonnelConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonnelConfigQuery = { __typename?: 'Query', personnelConfig?: any | null };

export type StudentAchievementsQueryVariables = Exact<{
  input: StudentAchievementStatsInput;
}>;


export type StudentAchievementsQuery = { __typename?: 'Query', studentAchievementStats?: any | null };

export type GetPersonalRatingByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']>;
}>;


export type GetPersonalRatingByIdQuery = { __typename?: 'Query', achievementRatingById?: any | null };

export type PersonnelConfigChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type PersonnelConfigChangedSubscription = { __typename?: 'Subscription', personnelConfigChanged?: any | null };

export type RequiredStudentPapersQueryVariables = Exact<{
  input: RequiredStudentPapersInput;
}>;


export type RequiredStudentPapersQuery = { __typename?: 'Query', requiredStudentPapers?: any | null };

export type CountersQueryVariables = Exact<{
  direction_key: Scalars['Int'];
  id?: InputMaybe<Scalars['UUID']>;
}>;


export type CountersQuery = { __typename?: 'Query', achievementDirectionCounters?: any | null };

export type UpdateAchievementMutationVariables = Exact<{
  input: UpdateAchievementInput;
}>;


export type UpdateAchievementMutation = { __typename?: 'Mutation', updateAchievement?: any | null };

export type PaProjectSeminarGroupDetailQueryVariables = Exact<{
  input: ProjectSeminarGroupDetailInput;
}>;


export type PaProjectSeminarGroupDetailQuery = { __typename?: 'Query', paProjectSeminarGroupDetail?: any | null };

export type PaTeacherTimetableLookupQueryVariables = Exact<{
  input: TeacherTimetableLookupInput;
}>;


export type PaTeacherTimetableLookupQuery = { __typename?: 'Query', paTeacherTimetableLookup?: any | null };

export type PaControlMutationVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type PaControlMutation = { __typename?: 'Mutation', paControl?: any | null };

export type AchievementTypesQueryVariables = Exact<{
  catid?: InputMaybe<Scalars['Int']>;
}>;


export type AchievementTypesQuery = { __typename?: 'Query', achievementTypes?: any | null };

export type GetAchievementsQueryVariables = Exact<{
  input: AchievementsInput;
}>;


export type GetAchievementsQuery = { __typename?: 'Query', achievements?: any | null };

export type GetPersonalRatingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPersonalRatingQuery = { __typename?: 'Query', achievementPersonalRating?: any | null };

export type GetUploadsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUploadsQuery = { __typename?: 'Query', uploads?: any | null };

export type SetDefaultAvatarMutationVariables = Exact<{
  upload_id: Scalars['Int'];
}>;


export type SetDefaultAvatarMutation = { __typename?: 'Mutation', defaultAvatar?: any | null };

export type UploadChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UploadChangedSubscription = { __typename?: 'Subscription', uploadsChanged?: any | null };

export type DefaultChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type DefaultChangedSubscription = { __typename?: 'Subscription', avatarChanged?: any | null };

export type GetCatalogsQueryVariables = Exact<{
  names?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetCatalogsQuery = { __typename?: 'Query', catalogs?: any | null };

export type GetEducationContractsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEducationContractsQuery = { __typename?: 'Query', finEducationContracts?: Array<{ __typename?: 'EducationContract', contract?: string | null, createDate?: string | null, startDate?: string | null, endDate?: string | null, customer?: string | null, debt?: string | null, penalties?: string | null, hasPenalties?: boolean | null, educationType?: string | null, actual?: boolean | null, refreshDate?: string | null, isLegal?: boolean | null, paymentSchedule?: { __typename?: 'PaymentSchedule', nearestPayDate?: string | null, paySum?: string | null, TheSumFaktPayment?: string | null, graph?: Array<{ __typename?: 'PaymentScheduleGraph', PayDo?: string | null, TheAmount?: string | null } | null> | null } | null } | null> | null };

export type GetEducationPaymentsHistoryQueryVariables = Exact<{
  contract?: InputMaybe<Scalars['String']>;
}>;


export type GetEducationPaymentsHistoryQuery = { __typename?: 'Query', educationPaymentsHistory?: any | null };

export type QuitancesBanksQueryVariables = Exact<{
  contract?: InputMaybe<Scalars['String']>;
}>;


export type QuitancesBanksQuery = { __typename?: 'Query', quittancesBanks?: any | null };

export type GetDormitoryContractsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDormitoryContractsQuery = { __typename?: 'Query', dormitoryContracts?: any | null };

export type GetDormitoryPaymentsQueryVariables = Exact<{
  number_contract?: InputMaybe<Scalars['String']>;
}>;


export type GetDormitoryPaymentsQuery = { __typename?: 'Query', dormitoryPayments?: any | null };

export type GetQrQueryVariables = Exact<{
  contract?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
}>;


export type GetQrQuery = { __typename?: 'Query', quittances?: any | null };

export type GetAccrudeScholarShipQueryVariables = Exact<{
  mode?: InputMaybe<Scalars['String']>;
}>;


export type GetAccrudeScholarShipQuery = { __typename?: 'Query', accruedScholarship?: any | null };

export type GetPlannedChargesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlannedChargesQuery = { __typename?: 'Query', plannedCharges?: any | null };

export type PeriodsQueryVariables = Exact<{
  input?: InputMaybe<AccruedScholarshipInput>;
}>;


export type PeriodsQuery = { __typename?: 'Query', finAccruedScholarship?: Array<{ __typename?: 'AccruedScholarship', period?: string | null } | null> | null };

export type ScolarshipQueryVariables = Exact<{
  input?: InputMaybe<AccruedScholarshipInput>;
}>;


export type ScolarshipQuery = { __typename?: 'Query', finAccruedScholarship?: Array<{ __typename?: 'AccruedScholarship', period?: string | null, rec_id?: number | null, updated?: string | null, blocks?: { __typename?: 'AccruedScholarshipBlock', block_accruals?: Array<{ __typename?: 'AccruedBlock', sum?: string | null, date?: string | null, name?: string | null } | null> | null, block_holdings?: Array<{ __typename?: 'AccruedBlock', sum?: string | null, date?: string | null, name?: string | null } | null> | null, block_payout?: Array<{ __typename?: 'AccruedBlock', sum?: string | null, date?: string | null, name?: string | null } | null> | null } | null } | null> | null };

export type GetDisciplinesQueryVariables = Exact<{
  group: Scalars['String'];
}>;


export type GetDisciplinesQuery = { __typename?: 'Query', disciplines?: any | null };

export type GetStudentJournalQueryVariables = Exact<{
  group: Scalars['String'];
  discipline: Scalars['String'];
  type: Scalars['String'];
  semester?: InputMaybe<Scalars['Int']>;
}>;


export type GetStudentJournalQuery = { __typename?: 'Query', getStudentJournal?: any | null };

export type TeacherDisciplineGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type TeacherDisciplineGroupsQuery = { __typename?: 'Query', teacherDisciplineGroups?: any | null };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me?: any | null };

export type UserInfoQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type UserInfoQuery = { __typename?: 'Query', userInfo?: any | null };

export type SliderImagesQueryVariables = Exact<{ [key: string]: never; }>;


export type SliderImagesQuery = { __typename?: 'Query', sliderImages?: any | null };

export type EducationPlanQueryVariables = Exact<{
  i?: InputMaybe<EducationPlanInput>;
}>;


export type EducationPlanQuery = { __typename?: 'Query', educationPlan?: Array<{ __typename?: 'EducationPlanResult', user_id?: any | null, recordbook?: any | null, plans?: Array<{ __typename?: 'EducationPlan', ep_id?: any | null, ep_number?: string | null, individual_plan?: boolean | null, admission_start?: number | null, admission_end?: number | null, date?: any | null } | null> | null } | null> | null };

export type EpDisciplinesQueryVariables = Exact<{
  i?: InputMaybe<EducationPlanDisciplinesInput>;
}>;


export type EpDisciplinesQuery = { __typename?: 'Query', educationPlanDisciplines?: any | null };

export type StatesQueryVariables = Exact<{
  type?: InputMaybe<DisciplineType>;
}>;


export type StatesQuery = { __typename?: 'Query', esDisciplinesState?: any | null };

export type TrackStateQueryVariables = Exact<{ [key: string]: never; }>;


export type TrackStateQuery = { __typename?: 'Query', esTrackState?: any | null };

export type DisciplinesBySemesterQueryVariables = Exact<{
  i: DisciplinesBySemestersInput;
}>;


export type DisciplinesBySemesterQuery = { __typename?: 'Query', bySemester?: any | null, common?: any | null };

export type MoodleCoursesQueryVariables = Exact<{
  i?: InputMaybe<MoodleCoursesInput>;
}>;


export type MoodleCoursesQuery = { __typename?: 'Query', moodleCourses?: any | null };

export type GetOneQueryVariables = Exact<{
  name: Scalars['String'];
  semester?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;


export type GetOneQuery = { __typename?: 'Query', esMoodleCoursesByDiscipline?: any | null };

export type ListFacultativesQueryVariables = Exact<{
  i: FacultativeInput;
}>;


export type ListFacultativesQuery = { __typename?: 'Query', esFacultative?: any | null };

export type SignedQueryVariables = Exact<{
  i: SignedStudentsInput;
}>;


export type SignedQuery = { __typename?: 'Query', esSignedStudents?: any | null };

export type GetDisciplineProcedureQueryVariables = Exact<{
  i: DisciplineType;
}>;


export type GetDisciplineProcedureQuery = { __typename?: 'Query', esDisciplineProcedure?: { __typename?: 'DisciplineProcedure', procedure_info?: string | null, procedure_data?: any | null } | null };

export type GetStatsQueryVariables = Exact<{
  i?: InputMaybe<DisciplineStatsInput>;
}>;


export type GetStatsQuery = { __typename?: 'Query', esDisciplineStats?: any | null };

export type GetTracksQueryVariables = Exact<{
  i: TrackListInput;
}>;


export type GetTracksQuery = { __typename?: 'Query', esTrackList?: { __typename?: 'TrackResult', items?: Array<{ __typename?: 'Track', is_active?: boolean | null, is_visible?: boolean | null, max_participants?: number | null, track_id?: number | null, is_signed?: boolean | null, name?: string | null, sign_count?: number | null, file?: any | null, data?: any | null, disciplines?: Array<{ __typename?: 'TrackDiscipline', discipline_id?: number | null, name?: string | null } | null> | null } | null> | null } | null };

export type GetTrackDisciplinesQueryVariables = Exact<{
  i?: InputMaybe<TrackDisciplinesInput>;
}>;


export type GetTrackDisciplinesQuery = { __typename?: 'Query', esTrackDisciplines?: Array<{ __typename?: 'TrackDiscipline', name?: string | null, semester?: number | null, discipline?: string | null, info?: string | null, discipline_id?: number | null, hours?: number | null, teachers?: any | null, details?: string | null, themes?: any | null } | null> | null };

export type GetTrackStudentsQueryVariables = Exact<{
  i: TrackStudentsInput;
}>;


export type GetTrackStudentsQuery = { __typename?: 'Query', esTrackStudents?: Array<{ __typename?: 'TrackStudents', full_name?: string | null, disciplines?: Array<string | null> | null, student_info?: any | null, student_id?: any | null } | null> | null };

export type GetSignStatusQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetSignStatusQuery = { __typename?: 'Query', esGetSignStatus?: any | null };

export type GetProjectThemesQueryVariables = Exact<{
  i?: InputMaybe<ProjectThemesInput>;
}>;


export type GetProjectThemesQuery = { __typename?: 'Query', paProjectThemes?: any | null };

export type CreateFacultativeMutationVariables = Exact<{
  i: CreateFacultativeInput;
}>;


export type CreateFacultativeMutation = { __typename?: 'Mutation', esCreateFacultative?: { __typename?: 'Facultative', facultative_id?: number | null, data?: any | null, is_active?: boolean | null, is_visible?: boolean | null } | null };

export type CreateTrackMutationVariables = Exact<{
  i: CreateTrackInput;
}>;


export type CreateTrackMutation = { __typename?: 'Mutation', esTrackCreate?: any | null };

export type UpdateTrackMutationVariables = Exact<{
  i: TrackUpdateInput;
}>;


export type UpdateTrackMutation = { __typename?: 'Mutation', esTrackUpdate?: any | null };

export type SignUpTrackMutationVariables = Exact<{
  i: SignUpTrackInput;
}>;


export type SignUpTrackMutation = { __typename?: 'Mutation', esSignUpTrack?: any | null };

export type SignOutTrackMutationVariables = Exact<{
  i: SignOutTrackInput;
}>;


export type SignOutTrackMutation = { __typename?: 'Mutation', esSignOutTrack?: any | null };

export type SignOutOneTrackMutationVariables = Exact<{
  i: SignOutTrackStudentInput;
}>;


export type SignOutOneTrackMutation = { __typename?: 'Mutation', esSignOutTrackStudent?: any | null };

export type UpdateTrackDisciplineMutationVariables = Exact<{
  i: TrackDisciplineUpdateInput;
}>;


export type UpdateTrackDisciplineMutation = { __typename?: 'Mutation', esTrackUpdateDiscipline?: any | null };

export type SignUpMutationVariables = Exact<{
  i: SignUpFacultativeInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', esSignUpFacultative?: any | null };

export type SignOutMutationVariables = Exact<{
  i: SignOutFacultativeInput;
}>;


export type SignOutMutation = { __typename?: 'Mutation', esSignOutFacultative?: any | null };

export type SignUpProjectThemeMutationVariables = Exact<{
  i: ProjectThemeSignUpInput;
}>;


export type SignUpProjectThemeMutation = { __typename?: 'Mutation', paProjectThemeSignUp?: any | null };

export type SignOutProjectThemeMutationVariables = Exact<{
  i: ProjectThemeSignOutInput;
}>;


export type SignOutProjectThemeMutation = { __typename?: 'Mutation', paProjectThemeSignOut?: any | null };

export type DeleteFacultativeMutationVariables = Exact<{
  i: DeleteFacultativeInput;
}>;


export type DeleteFacultativeMutation = { __typename?: 'Mutation', esDeleteFacultative?: { __typename?: 'Facultative', facultative_id?: number | null } | null };

export type DeleteTrackMutationVariables = Exact<{
  track_id: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteTrackMutation = { __typename?: 'Mutation', esTrackDelete?: number | null };

export type CreateProjectThemeMutationVariables = Exact<{
  i: ProjectThemeCreateInput;
}>;


export type CreateProjectThemeMutation = { __typename?: 'Mutation', paProjectThemeCreate?: any | null };

export type EsDisciplineActivateMutationVariables = Exact<{
  is_active: Scalars['Boolean'];
  type?: InputMaybe<DisciplineType>;
}>;


export type EsDisciplineActivateMutation = { __typename?: 'Mutation', esDisciplineActivate?: any | null };

export type ChangeActiveTrackMutationVariables = Exact<{
  is_active: Scalars['Boolean'];
}>;


export type ChangeActiveTrackMutation = { __typename?: 'Mutation', esTrackActivate?: any | null };

export type ChangeActiveTrackOneMutationVariables = Exact<{
  is_active: Scalars['Boolean'];
  track_id?: InputMaybe<Scalars['Int']>;
}>;


export type ChangeActiveTrackOneMutation = { __typename?: 'Mutation', esTrackActivate?: any | null };

export type UpdateTrackVisibilityMutationVariables = Exact<{
  is_visible: Scalars['Boolean'];
  track_id: Scalars['Int'];
}>;


export type UpdateTrackVisibilityMutation = { __typename?: 'Mutation', esTrackUpdateVisibility?: any | null };

export type EsDisciplineActivateOneMutationVariables = Exact<{
  is_active: Scalars['Boolean'];
  facultative_id?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  type?: InputMaybe<DisciplineType>;
}>;


export type EsDisciplineActivateOneMutation = { __typename?: 'Mutation', esDisciplineActivate?: any | null };

export type EditProcedureMutationVariables = Exact<{
  i: EditDisciplineProcedureInput;
}>;


export type EditProcedureMutation = { __typename?: 'Mutation', esEditDisciplineProcedure?: { __typename?: 'DisciplineProcedure', procedure_info?: string | null, procedure_data?: any | null } | null };

export type UpdateStatsMutationVariables = Exact<{
  i: UpdateStatsInput;
}>;


export type UpdateStatsMutation = { __typename?: 'Mutation', esUpdateDisciplineStats?: any | null };

export type SendMutationVariables = Exact<{
  i: Array<InputMaybe<DisciplineType>> | InputMaybe<DisciplineType>;
}>;


export type SendMutation = { __typename?: 'Mutation', esESB_SelectedDiscipline?: any | null };

export type OnFacultativeChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnFacultativeChangedSubscription = { __typename?: 'Subscription', facultativesChanged?: any | null };

export type OnTracksChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnTracksChangedSubscription = { __typename?: 'Subscription', esTracksChanged?: any | null };

export type OnProjectThemeChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnProjectThemeChangeSubscription = { __typename?: 'Subscription', projectIntroChanged?: any | null };

export type GetAllProjectsQueryVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
  pagination?: InputMaybe<Scalars['JSON']>;
}>;


export type GetAllProjectsQuery = { __typename?: 'Query', paGetAll?: any | null };

export type NameSpecListQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
}>;


export type NameSpecListQuery = { __typename?: 'Query', namespecs?: any | null };

export type ListNameSpecsWithAcceptedStudentsQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['JSON']>;
}>;


export type ListNameSpecsWithAcceptedStudentsQuery = { __typename?: 'Query', listNameSpecsWithAcceptedStudents?: any | null };

export type ProfsListQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
}>;


export type ProfsListQuery = { __typename?: 'Query', nameprofs?: any | null };

export type QueryQueryVariables = Exact<{
  namespec?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type QueryQuery = { __typename?: 'Query', listNameProfsWithAcceptedStudents?: any | null };

export type ProfsListBySpecQueryVariables = Exact<{
  input?: InputMaybe<NameProfsListInput>;
}>;


export type ProfsListBySpecQuery = { __typename?: 'Query', nameprofsList?: any | null };

export type GetPersonsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Scalars['JSON']>;
}>;


export type GetPersonsQuery = { __typename?: 'Query', persons?: any | null };

export type GetTasksQueryVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type GetTasksQuery = { __typename?: 'Query', paTasks?: any | null };

export type StagesListQueryVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type StagesListQuery = { __typename?: 'Query', paStages?: any | null };

export type GetProjectApplysQueryVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
  pagination?: InputMaybe<Scalars['JSON']>;
}>;


export type GetProjectApplysQuery = { __typename?: 'Query', paListProjects?: any | null };

export type ApplyProjectMutationVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type ApplyProjectMutation = { __typename?: 'Mutation', paApply?: any | null };

export type CreateProjectMutationVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', paControl?: any | null };

export type ProjectActivityCreateMutationVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type ProjectActivityCreateMutation = { __typename?: 'Mutation', paInitiate?: any | null };

export type ModifyMutationVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type ModifyMutation = { __typename?: 'Mutation', paModify?: any | null };

export type AddStageMutationVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type AddStageMutation = { __typename?: 'Mutation', paChangeStage?: any | null };

export type DeleteStageMutationVariables = Exact<{
  req_id: Scalars['Int'];
  stage_num: Scalars['Int'];
  event: Scalars['String'];
}>;


export type DeleteStageMutation = { __typename?: 'Mutation', paDeleteStage?: any | null };

export type AddTaskMutationVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type AddTaskMutation = { __typename?: 'Mutation', paAddTask?: any | null };

export type ChangeTaskMutationVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type ChangeTaskMutation = { __typename?: 'Mutation', paChangeTask?: any | null };

export type DeleteApplyMutationVariables = Exact<{
  req_id?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;


export type DeleteApplyMutation = { __typename?: 'Mutation', paDeleteInitiative?: any | null };

export type DeleteProjectMutationVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type DeleteProjectMutation = { __typename?: 'Mutation', paDeleteProject?: any | null };

export type AddCommentMutationVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type AddCommentMutation = { __typename?: 'Mutation', paAddComment?: any | null };

export type GetCommentsQueryVariables = Exact<{
  params?: InputMaybe<Scalars['JSON']>;
}>;


export type GetCommentsQuery = { __typename?: 'Query', paComments?: any | null };

export type GetProjectQueryVariables = Exact<{
  req_id?: InputMaybe<Scalars['Int']>;
}>;


export type GetProjectQuery = { __typename?: 'Query', paGetProject?: any | null };

export type GetTaskQueryVariables = Exact<{
  task_id?: InputMaybe<Scalars['Int']>;
}>;


export type GetTaskQuery = { __typename?: 'Query', paGetTask?: any | null };

export type PaGetConfigQueryVariables = Exact<{
  configName?: InputMaybe<Scalars['String']>;
  defaultConfig?: InputMaybe<Scalars['JSON']>;
}>;


export type PaGetConfigQuery = { __typename?: 'Query', paGetConfig?: any | null };

export type PaSetConfigMutationVariables = Exact<{
  configName?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['JSON']>;
}>;


export type PaSetConfigMutation = { __typename?: 'Mutation', paSetConfig?: any | null };

export type GetRecordbookQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRecordbookQuery = { __typename?: 'Query', recordbooks?: Array<{ __typename?: 'Recordbook', recordbook_number?: string | null, namespec?: string | null, department?: string | null, eform?: string | null, elevel?: string | null, course?: number | null, nameprof?: string | null, recordbook_file?: string | null, programresults?: Array<{ __typename?: 'ProgramResults', name?: string | null, value?: string | null } | null> | null } | null> | null };

export type GEtRecordbookDetailsQueryVariables = Exact<{
  recordbook_number?: InputMaybe<Scalars['String']>;
}>;


export type GEtRecordbookDetailsQuery = { __typename?: 'Query', recordbookBySemester?: any | null };

export type GetStudentInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStudentInfoQuery = { __typename?: 'Query', studentInfo?: any | null };

export type GetStudentInfoByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type GetStudentInfoByIdQuery = { __typename?: 'Query', studentInfoById?: any | null };

export type GetEmployeeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmployeeQuery = { __typename?: 'Query', employeesInfo?: any | null };

export type IsLoggedQueryVariables = Exact<{ [key: string]: never; }>;


export type IsLoggedQuery = { __typename?: 'Query', isLogged?: boolean | null };

export type SendFeedbackMutationVariables = Exact<{
  subject: Scalars['String'];
  body: Scalars['String'];
  extra?: InputMaybe<Scalars['JSON']>;
}>;


export type SendFeedbackMutation = { __typename?: 'Mutation', uiFeedback?: any | null };

export type LogFrontEndMutationVariables = Exact<{
  input: LogFrontendInput;
}>;


export type LogFrontEndMutation = { __typename?: 'Mutation', utilLogfrontEnd?: any | null };

export type ReferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type ReferencesQuery = { __typename?: 'Query', getUserGroups?: any | null };

export type TimetableWithTimeQueryVariables = Exact<{
  dateNow?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type TimetableWithTimeQuery = { __typename?: 'Query', studentTimetableWithTime?: any | null };

export type StudentTimetableQueryVariables = Exact<{
  dateStart?: InputMaybe<Scalars['String']>;
  dateEnd?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type StudentTimetableQuery = { __typename?: 'Query', studentTimetable?: any | null };

export type TeacherTimetableQueryVariables = Exact<{
  dateStart?: InputMaybe<Scalars['String']>;
  dateEnd?: InputMaybe<Scalars['String']>;
  teacher_guid?: InputMaybe<Scalars['String']>;
}>;


export type TeacherTimetableQuery = { __typename?: 'Query', teacherTimetable?: any | null };

export type StudentTimetableCountQueryVariables = Exact<{
  dateStart?: InputMaybe<Scalars['String']>;
  dateEnd?: InputMaybe<Scalars['String']>;
}>;


export type StudentTimetableCountQuery = { __typename?: 'Query', studentTimetable?: any | null };

export type StudentTimetableYearsQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentTimetableYearsQuery = { __typename?: 'Query', studentTimetableYears?: any | null };

export type ActivateInternetSessionByCurrentUserMutationVariables = Exact<{ [key: string]: never; }>;


export type ActivateInternetSessionByCurrentUserMutation = { __typename?: 'Mutation', activateInternetSessionByCurrentUser?: { __typename?: 'InternetStatus', code?: number | null, dateLastLogin?: string | null, intranetStatus?: boolean | null, ip?: string | null, login?: string | null } | null };

export type DeactivateInternetSessionByCurrentUserMutationVariables = Exact<{ [key: string]: never; }>;


export type DeactivateInternetSessionByCurrentUserMutation = { __typename?: 'Mutation', deactivateInternetSessionByCurrentUser?: { __typename?: 'InternetStatus', code?: number | null, dateLastLogin?: string | null, intranetStatus?: boolean | null, ip?: string | null, login?: string | null } | null };

export type RemoveFileMutationVariables = Exact<{
  fileId: Scalars['String'];
}>;


export type RemoveFileMutation = { __typename?: 'Mutation', removeFile?: { __typename?: 'FileNotFoundError' } | { __typename?: 'InvalidArgumentError' } | { __typename?: 'RemoveFile' } | null };

export type GetDownloadUrlQueryVariables = Exact<{
  fileId: Scalars['String'];
}>;


export type GetDownloadUrlQuery = { __typename?: 'Query', getPresignedDownloadUrl?: { __typename: 'FileNotFoundError', message?: string | null } | { __typename: 'InvalidArgumentError', message?: string | null } | { __typename: 'PresignedDownloadUrl', downloadUrl?: string | null } | null };

export type GetWorkspaceIconsByCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkspaceIconsByCurrentUserQuery = { __typename?: 'Query', getWorkspaceIconsByCurrentUser?: Array<{ __typename?: 'WorkspaceIcon', iconDescription?: string | null, iconID: string, iconImage?: string | null, iconLink?: string | null, iconName?: string | null, iconSort?: number | null } | null> | null };

export type GetInternetConnectionStatusByCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInternetConnectionStatusByCurrentUserQuery = { __typename?: 'Query', getInternetConnectionStatusByCurrentUser?: { __typename?: 'InternetStatus', code?: number | null, dateLastLogin?: string | null, intranetStatus?: boolean | null, ip?: string | null, login?: string | null } | null };

export type CheckFilesQueryVariables = Exact<{
  files: Array<FileEntryInput> | FileEntryInput;
}>;


export type CheckFilesQuery = { __typename?: 'Query', checkFiles?: any | null };

export type FileUploadConfirmationMutationVariables = Exact<{
  fileId: Scalars['String'];
}>;


export type FileUploadConfirmationMutation = { __typename?: 'Mutation', fileUploadConfirmation?: { __typename?: 'FileNotFoundError', message?: string | null } | { __typename?: 'FileUploadConfirmation', result?: boolean | null } | { __typename?: 'InvalidArgumentError', message?: string | null } | null };

export type GetUploadUrlMutationVariables = Exact<{
  inputFile: InputFile;
}>;


export type GetUploadUrlMutation = { __typename?: 'Mutation', getPresignedUploadURL?: { __typename?: 'InvalidArgumentError' } | { __typename?: 'PresignedUploadUrl' } | null };

export type OnUiNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUiNotificationSubscription = { __typename?: 'Subscription', uiNotification?: any | null };

export type ListNotificationQueryVariables = Exact<{
  i?: InputMaybe<NotificationListInput>;
}>;


export type ListNotificationQuery = { __typename?: 'Query', notificationList?: { __typename?: 'NotificationResult', items?: Array<{ __typename?: 'Notification', body?: any | null, meta?: any | null, from?: string | null, msg_id?: number | null, status?: string | null, created?: string | null, source?: string | null } | null> | null } | null };

export type NotificationStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationStatsQuery = { __typename?: 'Query', uiNotificationsStats?: any | null };

export type AddNotificationMutationVariables = Exact<{
  i?: InputMaybe<UiNotificationInput>;
}>;


export type AddNotificationMutation = { __typename?: 'Mutation', notificationCreate?: any | null };

export type UpdateNotificationMutationVariables = Exact<{
  message_ids: Array<Scalars['Int']> | Scalars['Int'];
  status?: InputMaybe<NotificationStatus>;
}>;


export type UpdateNotificationMutation = { __typename?: 'Mutation', notificationUpdateStatus?: any | null };


export const EsEducationPlanDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"EsEducationPlan"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"esEducationPlanId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esEducationPlan"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"esEducationPlanId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"disciplineList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ep_id"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"quantity"}},{"kind":"Field","name":{"kind":"Name","value":"module"}},{"kind":"Field","name":{"kind":"Name","value":"semester"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]}}]} as unknown as DocumentNode<EsEducationPlanQuery, EsEducationPlanQueryVariables>;
export const EducationPlanListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"EducationPlanList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"EducationPlanList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ep_id"}},{"kind":"Field","name":{"kind":"Name","value":"nameprof"}},{"kind":"Field","name":{"kind":"Name","value":"namespec"}},{"kind":"Field","name":{"kind":"Name","value":"admission_end"}},{"kind":"Field","name":{"kind":"Name","value":"admission_start"}}]}}]}}]} as unknown as DocumentNode<EducationPlanListQuery, EducationPlanListQueryVariables>;
export const OnProjectAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"OnProjectAdded"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"projectChanged"}}]}}]} as unknown as DocumentNode<OnProjectAddedSubscription, OnProjectAddedSubscriptionVariables>;
export const GetNameProfsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetNameProfs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"NameProfsListInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nameprofsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<GetNameProfsQuery, GetNameProfsQueryVariables>;
export const GetSpecsNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSpecsName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"NameSpecsListInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"namespecsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<GetSpecsNameQuery, GetSpecsNameQueryVariables>;
export const CommitDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Commit"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecCommitEducationOfIndividials"}}]}}]} as unknown as DocumentNode<CommitMutation, CommitMutationVariables>;
export const FrontCheckVersionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FrontCheckVersion"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"build"},"name":{"kind":"Name","value":"utilConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"domain"},"value":{"kind":"StringValue","value":"mobile","block":false}},{"kind":"Argument","name":{"kind":"Name","value":"key"},"value":{"kind":"StringValue","value":"version","block":false}}]},{"kind":"Field","alias":{"kind":"Name","value":"ios"},"name":{"kind":"Name","value":"utilConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"domain"},"value":{"kind":"StringValue","value":"mobile","block":false}},{"kind":"Argument","name":{"kind":"Name","value":"key"},"value":{"kind":"StringValue","value":"version.ios","block":false}}]},{"kind":"Field","alias":{"kind":"Name","value":"android"},"name":{"kind":"Name","value":"utilConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"domain"},"value":{"kind":"StringValue","value":"mobile","block":false}},{"kind":"Argument","name":{"kind":"Name","value":"key"},"value":{"kind":"StringValue","value":"version.android","block":false}}]}]}}]} as unknown as DocumentNode<FrontCheckVersionQuery, FrontCheckVersionQueryVariables>;
export const StudentDataLookupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"StudentDataLookup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"StudentDataLookupInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"studentDataLookup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<StudentDataLookupQuery, StudentDataLookupQueryVariables>;
export const SmsRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SMSRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phone"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"message"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expire"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"fake"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"source"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"smsCodeRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"phone"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phone"}}},{"kind":"Argument","name":{"kind":"Name","value":"message"},"value":{"kind":"Variable","name":{"kind":"Name","value":"message"}}},{"kind":"Argument","name":{"kind":"Name","value":"expire"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expire"}}},{"kind":"Argument","name":{"kind":"Name","value":"fake"},"value":{"kind":"Variable","name":{"kind":"Name","value":"fake"}}},{"kind":"Argument","name":{"kind":"Name","value":"source"},"value":{"kind":"Variable","name":{"kind":"Name","value":"source"}}}]}]}}]} as unknown as DocumentNode<SmsRequestMutation, SmsRequestMutationVariables>;
export const CheckCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CheckCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"smsCodeCheck"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"code"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}}]}]}}]} as unknown as DocumentNode<CheckCodeQuery, CheckCodeQueryVariables>;
export const DeleteUploadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteUpload"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"upload_ids"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteUpload"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"upload_ids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"upload_ids"}}}]}]}}]} as unknown as DocumentNode<DeleteUploadMutation, DeleteUploadMutationVariables>;
export const EcInstListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ecInstList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"catalogs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"names"},"value":{"kind":"ListValue","values":[{"kind":"StringValue","value":"ecInstList","block":false}]}}]}]}}]} as unknown as DocumentNode<EcInstListQuery, EcInstListQueryVariables>;
export const DepListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DepList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DepartmentListInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"departmentList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<DepListQuery, DepListQueryVariables>;
export const GetSplashDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSplash"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationSplash"}}]}}]} as unknown as DocumentNode<GetSplashQuery, GetSplashQueryVariables>;
export const EcExportTable_BaseStatsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ecExportTable_BaseStats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecExportTable_BaseStats"}}]}}]} as unknown as DocumentNode<EcExportTable_BaseStatsQuery, EcExportTable_BaseStatsQueryVariables>;
export const EcExportTable_1_1Document = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ecExportTable_1_1"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecExportTable_1_1"}}]}}]} as unknown as DocumentNode<EcExportTable_1_1Query, EcExportTable_1_1QueryVariables>;
export const EcExportTable_3_5Document = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ecExportTable_3_5"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecExportTable_3_5"}}]}}]} as unknown as DocumentNode<EcExportTable_3_5Query, EcExportTable_3_5QueryVariables>;
export const EcExportTable_EffectiveStats_AllDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ecExportTable_EffectiveStats_all"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecExportTable_EffectiveStats"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"StringValue","value":"allStats","block":false}},{"kind":"Argument","name":{"kind":"Name","value":"mode"},"value":{"kind":"EnumValue","value":"ALL"}}]}]}}]} as unknown as DocumentNode<EcExportTable_EffectiveStats_AllQuery, EcExportTable_EffectiveStats_AllQueryVariables>;
export const EcExportTable_EffectiveStats_MounthlyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ecExportTable_EffectiveStats_mounthly"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecExportTable_EffectiveStats"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"StringValue","value":"allStats","block":false}},{"kind":"Argument","name":{"kind":"Name","value":"mode"},"value":{"kind":"EnumValue","value":"MONTHLY"}}]}]}}]} as unknown as DocumentNode<EcExportTable_EffectiveStats_MounthlyQuery, EcExportTable_EffectiveStats_MounthlyQueryVariables>;
export const EcExportTable_EffectiveStatsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ecExportTable_EffectiveStats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecExportTable_EffectiveStats"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"StringValue","value":"allStats","block":false}},{"kind":"Argument","name":{"kind":"Name","value":"mode"},"value":{"kind":"EnumValue","value":"ONETIME"}}]}]}}]} as unknown as DocumentNode<EcExportTable_EffectiveStatsQuery, EcExportTable_EffectiveStatsQueryVariables>;
export const ConfigChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ConfigChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"configChanged"}}]}}]} as unknown as DocumentNode<ConfigChangedSubscription, ConfigChangedSubscriptionVariables>;
export const GenerateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Generate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecCreateData22_18"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mode"},"value":{"kind":"EnumValue","value":"ALL"}}]}]}}]} as unknown as DocumentNode<GenerateQuery, GenerateQueryVariables>;
export const EcCreateDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EcCreateData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dataType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user_ids"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"extra"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecCreateData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"dataType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dataType"}}},{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}},{"kind":"Argument","name":{"kind":"Name","value":"user_ids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user_ids"}}},{"kind":"Argument","name":{"kind":"Name","value":"extra"},"value":{"kind":"Variable","name":{"kind":"Name","value":"extra"}}}]}]}}]} as unknown as DocumentNode<EcCreateDataMutation, EcCreateDataMutationVariables>;
export const EcDeleteDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EcDeleteData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"rec_id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dataType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecDeleteData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"rec_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"rec_id"}}},{"kind":"Argument","name":{"kind":"Name","value":"dataType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dataType"}}}]}]}}]} as unknown as DocumentNode<EcDeleteDataMutation, EcDeleteDataMutationVariables>;
export const EcTable_2_8_CategoriesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ecTable_2_8_Categories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"catalogs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"names"},"value":{"kind":"ListValue","values":[{"kind":"StringValue","value":"ecTable_2_8_Categories","block":false}]}}]}]}}]} as unknown as DocumentNode<EcTable_2_8_CategoriesQuery, EcTable_2_8_CategoriesQueryVariables>;
export const EcTable2_8_RolesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ecTable2_8_Roles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"catalogs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"names"},"value":{"kind":"ListValue","values":[{"kind":"StringValue","value":"ecTable2_8_Roles","block":false}]}}]}]}}]} as unknown as DocumentNode<EcTable2_8_RolesQuery, EcTable2_8_RolesQueryVariables>;
export const TeacherPositionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TeacherPositions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teacherPositions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"search"},"value":{"kind":"StringValue","value":"","block":false}}]}]}}]} as unknown as DocumentNode<TeacherPositionsQuery, TeacherPositionsQueryVariables>;
export const GetModeratorPosFilterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetModeratorPosFilter"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"catalogs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"names"},"value":{"kind":"ListValue","values":[{"kind":"StringValue","value":"ecModeratorPositionsFilter","block":false}]}}]}]}}]} as unknown as DocumentNode<GetModeratorPosFilterQuery, GetModeratorPosFilterQueryVariables>;
export const OnContractChangeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"OnContractChange"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"contractsChanged"}}]}}]} as unknown as DocumentNode<OnContractChangeSubscription, OnContractChangeSubscriptionVariables>;
export const EcListDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"EcListData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecListData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<EcListDataQuery, EcListDataQueryVariables>;
export const EcUpdateDataTypeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EcUpdateDataType"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"rec_id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dataType"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecUpdateDataType"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"rec_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"rec_id"}}},{"kind":"Argument","name":{"kind":"Name","value":"dataType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dataType"}}}]}]}}]} as unknown as DocumentNode<EcUpdateDataTypeMutation, EcUpdateDataTypeMutationVariables>;
export const GetScoresBySelectorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetScoresBySelector"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user_id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"selector"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DataTypeSelector"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"extra"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecGetScoresTableBySelector"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"user_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user_id"}}},{"kind":"Argument","name":{"kind":"Name","value":"dataTypeSelector"},"value":{"kind":"Variable","name":{"kind":"Name","value":"selector"}}},{"kind":"Argument","name":{"kind":"Name","value":"extra"},"value":{"kind":"Variable","name":{"kind":"Name","value":"extra"}}}]}]}}]} as unknown as DocumentNode<GetScoresBySelectorQuery, GetScoresBySelectorQueryVariables>;
export const CheckCompletionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CheckCompletion"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user_id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecCheckCompletion"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"user_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user_id"}}}]}]}}]} as unknown as DocumentNode<CheckCompletionQuery, CheckCompletionQueryVariables>;
export const GetBaseLineDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetBaseLine"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user_id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dataType"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecGetBaselineCompletion"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"user_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user_id"}}},{"kind":"Argument","name":{"kind":"Name","value":"dataType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dataType"}}}]}]}}]} as unknown as DocumentNode<GetBaseLineQuery, GetBaseLineQueryVariables>;
export const GetComplitionsStatsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetComplitionsStats"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user_id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dataType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecGetCompletionStats"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"user_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user_id"}}},{"kind":"Argument","name":{"kind":"Name","value":"dataType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dataType"}}}]}]}}]} as unknown as DocumentNode<GetComplitionsStatsQuery, GetComplitionsStatsQueryVariables>;
export const GetGraphDataTotalsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetGraphDataTotals"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecGraphDataTotals"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"user_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<GetGraphDataTotalsQuery, GetGraphDataTotalsQueryVariables>;
export const UtilConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UtilConfig"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"domain"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"key"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"utilConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"domain"},"value":{"kind":"Variable","name":{"kind":"Name","value":"domain"}}},{"kind":"Argument","name":{"kind":"Name","value":"key"},"value":{"kind":"Variable","name":{"kind":"Name","value":"key"}}}]}]}}]} as unknown as DocumentNode<UtilConfigQuery, UtilConfigQueryVariables>;
export const TeachersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Teachers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"search"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teacherList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"search"},"value":{"kind":"Variable","name":{"kind":"Name","value":"search"}}},{"kind":"Argument","name":{"kind":"Name","value":"pagination"},"value":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}}}]}]}}]} as unknown as DocumentNode<TeachersQuery, TeachersQueryVariables>;
export const EcDataTableByUserAndStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"EcDataTableByUserAndStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ecDataTableByUserAndStatusInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecDataTableByUserAndStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<EcDataTableByUserAndStatusQuery, EcDataTableByUserAndStatusQueryVariables>;
export const Import_22_16_17Document = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Import_22_16_17"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"upload_id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"async"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecImportTable_22_16x17"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"upload_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"upload_id"}}},{"kind":"Argument","name":{"kind":"Name","value":"async"},"value":{"kind":"Variable","name":{"kind":"Name","value":"async"}}}]}]}}]} as unknown as DocumentNode<Import_22_16_17Mutation, Import_22_16_17MutationVariables>;
export const EcUpdateDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EcUpdateData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"rec_id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"new_data"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user_ids"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"new_extra"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"new_appeal"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ecUpdateData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"rec_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"rec_id"}}},{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"new_data"}}},{"kind":"Argument","name":{"kind":"Name","value":"user_ids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user_ids"}}},{"kind":"Argument","name":{"kind":"Name","value":"extra"},"value":{"kind":"Variable","name":{"kind":"Name","value":"new_extra"}}},{"kind":"Argument","name":{"kind":"Name","value":"appeal"},"value":{"kind":"Variable","name":{"kind":"Name","value":"new_appeal"}}}]}]}}]} as unknown as DocumentNode<EcUpdateDataMutation, EcUpdateDataMutationVariables>;
export const UtilUpdateConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UtilUpdateConfig"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateConfigInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"utilUpdateConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<UtilUpdateConfigMutation, UtilUpdateConfigMutationVariables>;
export const GetFormDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFormData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReferenceFormFieldDataInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referenceGetFormFieldData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<GetFormDataQuery, GetFormDataQueryVariables>;
export const GetApplicationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetApplications"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MFCStatementsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mfcStatements"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<GetApplicationsQuery, GetApplicationsQueryVariables>;
export const GetFormTypesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFormTypes"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"statementType"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"MFCStatementType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mfcGetFormTypes"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"statementType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"statementType"}}}]}]}}]} as unknown as DocumentNode<GetFormTypesQuery, GetFormTypesQueryVariables>;
export const GetFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mfcGetForm"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<GetFormQuery, GetFormQueryVariables>;
export const SendStatementDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendStatement"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"payload"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MFCStatementType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mfcSendStatement"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"payload"},"value":{"kind":"Variable","name":{"kind":"Name","value":"payload"}}},{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}}]}]}}]} as unknown as DocumentNode<SendStatementMutation, SendStatementMutationVariables>;
export const ReferenceStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ReferenceStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"references"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mode"},"value":{"kind":"StringValue","value":"stats","block":false}}]}]}}]} as unknown as DocumentNode<ReferenceStatusQuery, ReferenceStatusQueryVariables>;
export const ReferenceListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ReferenceList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"status"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"references"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"Variable","name":{"kind":"Name","value":"status"}}}]}]}}]} as unknown as DocumentNode<ReferenceListQuery, ReferenceListQueryVariables>;
export const CreateReferenceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateReference"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"payload"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createReference"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"payload"},"value":{"kind":"Variable","name":{"kind":"Name","value":"payload"}}}]}]}}]} as unknown as DocumentNode<CreateReferenceMutation, CreateReferenceMutationVariables>;
export const CommitReferencesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CommitReferences"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"doc_id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referenceCommit"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"doc_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"doc_id"}}}]}]}}]} as unknown as DocumentNode<CommitReferencesMutation, CommitReferencesMutationVariables>;
export const ReferenceChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ReferenceChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referenceChanged"}}]}}]} as unknown as DocumentNode<ReferenceChangedSubscription, ReferenceChangedSubscriptionVariables>;
export const NotificationDraftAddDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"NotificationDraftAdd"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"body"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationDraftAdd"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"body"},"value":{"kind":"Variable","name":{"kind":"Name","value":"body"}}}]}]}}]} as unknown as DocumentNode<NotificationDraftAddMutation, NotificationDraftAddMutationVariables>;
export const NotificationDraftChangeActiveDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"NotificationDraftChangeActive"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"draft_id"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"is_active"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationDraftChangeActive"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"draft_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"draft_id"}}},{"kind":"Argument","name":{"kind":"Name","value":"is_active"},"value":{"kind":"Variable","name":{"kind":"Name","value":"is_active"}}}]}]}}]} as unknown as DocumentNode<NotificationDraftChangeActiveMutation, NotificationDraftChangeActiveMutationVariables>;
export const NotificationDraftDeleteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"NotificationDraftDelete"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"draft_id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationDraftDelete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"draft_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"draft_id"}}}]}]}}]} as unknown as DocumentNode<NotificationDraftDeleteMutation, NotificationDraftDeleteMutationVariables>;
export const NotificationDraftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"notificationDraft"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"NotificationDraftInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationDraft"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<NotificationDraftQuery, NotificationDraftQueryVariables>;
export const NotificationDraftFiltersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"NotificationDraftFilters"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationDraftFilters"}}]}}]} as unknown as DocumentNode<NotificationDraftFiltersQuery, NotificationDraftFiltersQueryVariables>;
export const NotificationDraftChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"NotificationDraftChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationDraftChanged"}}]}}]} as unknown as DocumentNode<NotificationDraftChangedSubscription, NotificationDraftChangedSubscriptionVariables>;
export const NotificationDraftPurgeMessagesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"NotificationDraftPurgeMessages"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"draft_id"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationDraftPurgeMessages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"draft_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"draft_id"}}}]}]}}]} as unknown as DocumentNode<NotificationDraftPurgeMessagesMutation, NotificationDraftPurgeMessagesMutationVariables>;
export const NotificationDraftResetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"NotificationDraftReset"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"draft_id"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationDraftResetProcessed"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"draft_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"draft_id"}}}]}]}}]} as unknown as DocumentNode<NotificationDraftResetMutation, NotificationDraftResetMutationVariables>;
export const OnAchievementChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"OnAchievementChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"achievementChanged"}}]}}]} as unknown as DocumentNode<OnAchievementChangedSubscription, OnAchievementChangedSubscriptionVariables>;
export const AchievementDirectionsChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"AchievementDirectionsChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"achievementDirectionsChanged"}}]}}]} as unknown as DocumentNode<AchievementDirectionsChangedSubscription, AchievementDirectionsChangedSubscriptionVariables>;
export const DirectionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Directions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"is_admin"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"achievementDirections"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"is_admin"},"value":{"kind":"Variable","name":{"kind":"Name","value":"is_admin"}}}]}]}}]} as unknown as DocumentNode<DirectionsQuery, DirectionsQueryVariables>;
export const StatusesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Statuses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"achievementStatuses"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"all"},"value":{"kind":"BooleanValue","value":true}}]}]}}]} as unknown as DocumentNode<StatusesQuery, StatusesQueryVariables>;
export const AchievementTypeChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"AchievementTypeChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"achievementTypeChanged"}}]}}]} as unknown as DocumentNode<AchievementTypeChangedSubscription, AchievementTypeChangedSubscriptionVariables>;
export const AchievementTypes2Document = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AchievementTypes2"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"catid"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"key"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"is_admin"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"achievementTypes"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"catid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"catid"}}},{"kind":"Argument","name":{"kind":"Name","value":"key"},"value":{"kind":"Variable","name":{"kind":"Name","value":"key"}}},{"kind":"Argument","name":{"kind":"Name","value":"is_admin"},"value":{"kind":"Variable","name":{"kind":"Name","value":"is_admin"}}}]}]}}]} as unknown as DocumentNode<AchievementTypes2Query, AchievementTypes2QueryVariables>;
export const CreateAchievementDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateAchievement"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateAchievementInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createAchievement"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<CreateAchievementMutation, CreateAchievementMutationVariables>;
export const StudentsAchievementsRatingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"StudentsAchievementsRating"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AchievementsRatingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"studentsAchievementsRating"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<StudentsAchievementsRatingQuery, StudentsAchievementsRatingQueryVariables>;
export const AverageGradeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AverageGrade"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AverageGradeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"averageGrade"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<AverageGradeQuery, AverageGradeQueryVariables>;
export const SetUpdateConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetUpdateConfig"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"value"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePersonnelConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"value"},"value":{"kind":"Variable","name":{"kind":"Name","value":"value"}}}]}]}}]} as unknown as DocumentNode<SetUpdateConfigMutation, SetUpdateConfigMutationVariables>;
export const ChangeTypeDirectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"changeTypeDirection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ChangeTypeDirectionInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changeTypeDirection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<ChangeTypeDirectionMutation, ChangeTypeDirectionMutationVariables>;
export const EditDirectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EditDirection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"EditAchievementDirectionInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"editAchievementDirection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<EditDirectionMutation, EditDirectionMutationVariables>;
export const EditAchievementTypeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"editAchievementType"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"EditAchievementTypeInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"editAchievementType"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<EditAchievementTypeMutation, EditAchievementTypeMutationVariables>;
export const DeleteAchievementDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteAchievement"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"achievement_id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteAchievement"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"achievement_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"achievement_id"}}}]}]}}]} as unknown as DocumentNode<DeleteAchievementMutation, DeleteAchievementMutationVariables>;
export const GetAchievementRatingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAchievementRating"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"count"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"achievementRating"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"count"},"value":{"kind":"Variable","name":{"kind":"Name","value":"count"}}}]}]}}]} as unknown as DocumentNode<GetAchievementRatingQuery, GetAchievementRatingQueryVariables>;
export const GetDirectionsWithAchievementsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetDirectionsWithAchievements"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"available"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"status"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"AchievementStatus"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getDirectionsWithAchievements"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"available"},"value":{"kind":"Variable","name":{"kind":"Name","value":"available"}}},{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"Variable","name":{"kind":"Name","value":"status"}}}]}]}}]} as unknown as DocumentNode<GetDirectionsWithAchievementsQuery, GetDirectionsWithAchievementsQueryVariables>;
export const PersonnelConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"personnelConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"personnelConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"ListValue","values":[{"kind":"StringValue","value":"is_student_rating_available_all","block":false},{"kind":"StringValue","value":"is_student_rating_include_myself","block":false}]}}]}]}}]} as unknown as DocumentNode<PersonnelConfigQuery, PersonnelConfigQueryVariables>;
export const StudentAchievementsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"StudentAchievements"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"StudentAchievementStatsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"studentAchievementStats"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<StudentAchievementsQuery, StudentAchievementsQueryVariables>;
export const GetPersonalRatingByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPersonalRatingById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"achievementRatingById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<GetPersonalRatingByIdQuery, GetPersonalRatingByIdQueryVariables>;
export const PersonnelConfigChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"PersonnelConfigChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"personnelConfigChanged"}}]}}]} as unknown as DocumentNode<PersonnelConfigChangedSubscription, PersonnelConfigChangedSubscriptionVariables>;
export const RequiredStudentPapersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RequiredStudentPapers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RequiredStudentPapersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requiredStudentPapers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<RequiredStudentPapersQuery, RequiredStudentPapersQueryVariables>;
export const CountersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Counters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"direction_key"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"achievementDirectionCounters"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"direction_key"},"value":{"kind":"Variable","name":{"kind":"Name","value":"direction_key"}}},{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<CountersQuery, CountersQueryVariables>;
export const UpdateAchievementDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateAchievement"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateAchievementInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateAchievement"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<UpdateAchievementMutation, UpdateAchievementMutationVariables>;
export const PaProjectSeminarGroupDetailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"paProjectSeminarGroupDetail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProjectSeminarGroupDetailInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paProjectSeminarGroupDetail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<PaProjectSeminarGroupDetailQuery, PaProjectSeminarGroupDetailQueryVariables>;
export const PaTeacherTimetableLookupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"paTeacherTimetableLookup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TeacherTimetableLookupInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paTeacherTimetableLookup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<PaTeacherTimetableLookupQuery, PaTeacherTimetableLookupQueryVariables>;
export const PaControlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PaControl"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paControl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<PaControlMutation, PaControlMutationVariables>;
export const AchievementTypesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AchievementTypes"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"catid"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"achievementTypes"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"catid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"catid"}}}]}]}}]} as unknown as DocumentNode<AchievementTypesQuery, AchievementTypesQueryVariables>;
export const GetAchievementsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAchievements"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AchievementsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"achievements"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<GetAchievementsQuery, GetAchievementsQueryVariables>;
export const GetPersonalRatingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPersonalRating"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"achievementPersonalRating"}}]}}]} as unknown as DocumentNode<GetPersonalRatingQuery, GetPersonalRatingQueryVariables>;
export const GetUploadsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUploads"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploads"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"category"},"value":{"kind":"StringValue","value":"avatars","block":false}}]}]}}]} as unknown as DocumentNode<GetUploadsQuery, GetUploadsQueryVariables>;
export const SetDefaultAvatarDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetDefaultAvatar"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"upload_id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"defaultAvatar"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"upload_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"upload_id"}}}]}]}}]} as unknown as DocumentNode<SetDefaultAvatarMutation, SetDefaultAvatarMutationVariables>;
export const UploadChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UploadChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadsChanged"}}]}}]} as unknown as DocumentNode<UploadChangedSubscription, UploadChangedSubscriptionVariables>;
export const DefaultChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"DefaultChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"avatarChanged"}}]}}]} as unknown as DocumentNode<DefaultChangedSubscription, DefaultChangedSubscriptionVariables>;
export const GetCatalogsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCatalogs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"names"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"catalogs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"names"},"value":{"kind":"Variable","name":{"kind":"Name","value":"names"}}}]}]}}]} as unknown as DocumentNode<GetCatalogsQuery, GetCatalogsQueryVariables>;
export const GetEducationContractsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEducationContracts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"finEducationContracts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"contract"}},{"kind":"Field","name":{"kind":"Name","value":"createDate"}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}},{"kind":"Field","name":{"kind":"Name","value":"customer"}},{"kind":"Field","name":{"kind":"Name","value":"debt"}},{"kind":"Field","name":{"kind":"Name","value":"penalties"}},{"kind":"Field","name":{"kind":"Name","value":"hasPenalties"}},{"kind":"Field","name":{"kind":"Name","value":"educationType"}},{"kind":"Field","name":{"kind":"Name","value":"actual"}},{"kind":"Field","name":{"kind":"Name","value":"refreshDate"}},{"kind":"Field","name":{"kind":"Name","value":"paymentSchedule"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nearestPayDate"}},{"kind":"Field","name":{"kind":"Name","value":"paySum"}},{"kind":"Field","name":{"kind":"Name","value":"TheSumFaktPayment"}},{"kind":"Field","name":{"kind":"Name","value":"graph"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"PayDo"}},{"kind":"Field","name":{"kind":"Name","value":"TheAmount"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"isLegal"}}]}}]}}]} as unknown as DocumentNode<GetEducationContractsQuery, GetEducationContractsQueryVariables>;
export const GetEducationPaymentsHistoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEducationPaymentsHistory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"contract"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"educationPaymentsHistory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"contract"},"value":{"kind":"Variable","name":{"kind":"Name","value":"contract"}}}]}]}}]} as unknown as DocumentNode<GetEducationPaymentsHistoryQuery, GetEducationPaymentsHistoryQueryVariables>;
export const QuitancesBanksDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"QuitancesBanks"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"contract"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quittancesBanks"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"contract"},"value":{"kind":"Variable","name":{"kind":"Name","value":"contract"}}}]}]}}]} as unknown as DocumentNode<QuitancesBanksQuery, QuitancesBanksQueryVariables>;
export const GetDormitoryContractsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetDormitoryContracts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dormitoryContracts"}}]}}]} as unknown as DocumentNode<GetDormitoryContractsQuery, GetDormitoryContractsQueryVariables>;
export const GetDormitoryPaymentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetDormitoryPayments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"number_contract"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dormitoryPayments"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"number_contract"},"value":{"kind":"Variable","name":{"kind":"Name","value":"number_contract"}}}]}]}}]} as unknown as DocumentNode<GetDormitoryPaymentsQuery, GetDormitoryPaymentsQueryVariables>;
export const GetQrDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetQR"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"contract"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quittances"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"contract"},"value":{"kind":"Variable","name":{"kind":"Name","value":"contract"}}},{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}}]}]}}]} as unknown as DocumentNode<GetQrQuery, GetQrQueryVariables>;
export const GetAccrudeScholarShipDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAccrudeScholarShip"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mode"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accruedScholarship"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mode"}}}]}]}}]} as unknown as DocumentNode<GetAccrudeScholarShipQuery, GetAccrudeScholarShipQueryVariables>;
export const GetPlannedChargesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPlannedCharges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"plannedCharges"}}]}}]} as unknown as DocumentNode<GetPlannedChargesQuery, GetPlannedChargesQueryVariables>;
export const PeriodsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Periods"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"AccruedScholarshipInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"finAccruedScholarship"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"period"}}]}}]}}]} as unknown as DocumentNode<PeriodsQuery, PeriodsQueryVariables>;
export const ScolarshipDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Scolarship"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"AccruedScholarshipInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"finAccruedScholarship"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"rec_id"}},{"kind":"Field","name":{"kind":"Name","value":"updated"}},{"kind":"Field","name":{"kind":"Name","value":"blocks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"block_accruals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sum"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"block_holdings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sum"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"block_payout"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sum"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ScolarshipQuery, ScolarshipQueryVariables>;
export const GetDisciplinesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetDisciplines"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"group"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"disciplines"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"group"},"value":{"kind":"Variable","name":{"kind":"Name","value":"group"}}}]}]}}]} as unknown as DocumentNode<GetDisciplinesQuery, GetDisciplinesQueryVariables>;
export const GetStudentJournalDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetStudentJournal"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"group"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"discipline"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"semester"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getStudentJournal"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"group"},"value":{"kind":"Variable","name":{"kind":"Name","value":"group"}}},{"kind":"Argument","name":{"kind":"Name","value":"discipline"},"value":{"kind":"Variable","name":{"kind":"Name","value":"discipline"}}},{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}},{"kind":"Argument","name":{"kind":"Name","value":"semester"},"value":{"kind":"Variable","name":{"kind":"Name","value":"semester"}}}]}]}}]} as unknown as DocumentNode<GetStudentJournalQuery, GetStudentJournalQueryVariables>;
export const TeacherDisciplineGroupsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TeacherDisciplineGroups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teacherDisciplineGroups"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"testID"},"value":{"kind":"StringValue","value":"010c0df0-3dfc-11e6-80bd-7824af85d229","block":false}}]}]}}]} as unknown as DocumentNode<TeacherDisciplineGroupsQuery, TeacherDisciplineGroupsQueryVariables>;
export const GetMeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getME"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"}}]}}]} as unknown as DocumentNode<GetMeQuery, GetMeQueryVariables>;
export const UserInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserInfo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user_id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userInfo"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"user_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user_id"}}}]}]}}]} as unknown as DocumentNode<UserInfoQuery, UserInfoQueryVariables>;
export const SliderImagesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SliderImages"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sliderImages"}}]}}]} as unknown as DocumentNode<SliderImagesQuery, SliderImagesQueryVariables>;
export const EducationPlanDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"EducationPlan"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"EducationPlanInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"educationPlan"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user_id"}},{"kind":"Field","name":{"kind":"Name","value":"recordbook"}},{"kind":"Field","name":{"kind":"Name","value":"plans"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ep_id"}},{"kind":"Field","name":{"kind":"Name","value":"ep_number"}},{"kind":"Field","name":{"kind":"Name","value":"individual_plan"}},{"kind":"Field","name":{"kind":"Name","value":"admission_start"}},{"kind":"Field","name":{"kind":"Name","value":"admission_end"}},{"kind":"Field","name":{"kind":"Name","value":"date"}}]}}]}}]}}]} as unknown as DocumentNode<EducationPlanQuery, EducationPlanQueryVariables>;
export const EpDisciplinesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"EPDisciplines"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"EducationPlanDisciplinesInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"educationPlanDisciplines"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<EpDisciplinesQuery, EpDisciplinesQueryVariables>;
export const StatesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"States"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DisciplineType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esDisciplinesState"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}}]}]}}]} as unknown as DocumentNode<StatesQuery, StatesQueryVariables>;
export const TrackStateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TrackState"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esTrackState"}}]}}]} as unknown as DocumentNode<TrackStateQuery, TrackStateQueryVariables>;
export const DisciplinesBySemesterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DisciplinesBySemester"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DisciplinesBySemestersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"bySemester"},"name":{"kind":"Name","value":"esDisciplinesBySemesters"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]},{"kind":"Field","alias":{"kind":"Name","value":"common"},"name":{"kind":"Name","value":"esDisciplinesBySemesters"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"filter"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"semester"},"value":{"kind":"IntValue","value":"-1"}}]}}]}}]}]}}]} as unknown as DocumentNode<DisciplinesBySemesterQuery, DisciplinesBySemesterQueryVariables>;
export const MoodleCoursesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MoodleCourses"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"MoodleCoursesInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moodleCourses"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<MoodleCoursesQuery, MoodleCoursesQueryVariables>;
export const GetOneDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetOne"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"semester"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esMoodleCoursesByDiscipline"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"discipline_name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"semester"},"value":{"kind":"Variable","name":{"kind":"Name","value":"semester"}}}]}]}}]} as unknown as DocumentNode<GetOneQuery, GetOneQueryVariables>;
export const ListFacultativesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ListFacultatives"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FacultativeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esFacultative"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<ListFacultativesQuery, ListFacultativesQueryVariables>;
export const SignedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Signed"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SignedStudentsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esSignedStudents"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<SignedQuery, SignedQueryVariables>;
export const GetDisciplineProcedureDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetDisciplineProcedure"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DisciplineType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esDisciplineProcedure"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"procedure_info"}},{"kind":"Field","name":{"kind":"Name","value":"procedure_data"}}]}}]}}]} as unknown as DocumentNode<GetDisciplineProcedureQuery, GetDisciplineProcedureQueryVariables>;
export const GetStatsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetStats"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DisciplineStatsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esDisciplineStats"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<GetStatsQuery, GetStatsQueryVariables>;
export const GetTracksDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTracks"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TrackListInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esTrackList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"is_active"}},{"kind":"Field","name":{"kind":"Name","value":"is_visible"}},{"kind":"Field","name":{"kind":"Name","value":"max_participants"}},{"kind":"Field","name":{"kind":"Name","value":"track_id"}},{"kind":"Field","name":{"kind":"Name","value":"is_signed"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"sign_count"}},{"kind":"Field","name":{"kind":"Name","value":"file"}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"disciplines"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"discipline_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetTracksQuery, GetTracksQueryVariables>;
export const GetTrackDisciplinesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTrackDisciplines"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"TrackDisciplinesInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esTrackDisciplines"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"semester"}},{"kind":"Field","name":{"kind":"Name","value":"discipline"}},{"kind":"Field","name":{"kind":"Name","value":"info"}},{"kind":"Field","name":{"kind":"Name","value":"discipline_id"}},{"kind":"Field","name":{"kind":"Name","value":"semester"}},{"kind":"Field","name":{"kind":"Name","value":"hours"}},{"kind":"Field","name":{"kind":"Name","value":"teachers"}},{"kind":"Field","name":{"kind":"Name","value":"details"}},{"kind":"Field","name":{"kind":"Name","value":"themes"}},{"kind":"Field","name":{"kind":"Name","value":"discipline"}}]}}]}}]} as unknown as DocumentNode<GetTrackDisciplinesQuery, GetTrackDisciplinesQueryVariables>;
export const GetTrackStudentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTrackStudents"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TrackStudentsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esTrackStudents"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"full_name"}},{"kind":"Field","name":{"kind":"Name","value":"disciplines"}},{"kind":"Field","name":{"kind":"Name","value":"student_info"}},{"kind":"Field","name":{"kind":"Name","value":"student_id"}}]}}]}}]} as unknown as DocumentNode<GetTrackStudentsQuery, GetTrackStudentsQueryVariables>;
export const GetSignStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSignStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esGetSignStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<GetSignStatusQuery, GetSignStatusQueryVariables>;
export const GetProjectThemesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetProjectThemes"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ProjectThemesInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paProjectThemes"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<GetProjectThemesQuery, GetProjectThemesQueryVariables>;
export const CreateFacultativeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateFacultative"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateFacultativeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esCreateFacultative"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facultative_id"}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"is_active"}},{"kind":"Field","name":{"kind":"Name","value":"is_visible"}}]}}]}}]} as unknown as DocumentNode<CreateFacultativeMutation, CreateFacultativeMutationVariables>;
export const CreateTrackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTrack"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateTrackInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esTrackCreate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<CreateTrackMutation, CreateTrackMutationVariables>;
export const UpdateTrackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateTrack"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TrackUpdateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esTrackUpdate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<UpdateTrackMutation, UpdateTrackMutationVariables>;
export const SignUpTrackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignUpTrack"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SignUpTrackInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esSignUpTrack"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<SignUpTrackMutation, SignUpTrackMutationVariables>;
export const SignOutTrackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignOutTrack"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SignOutTrackInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esSignOutTrack"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<SignOutTrackMutation, SignOutTrackMutationVariables>;
export const SignOutOneTrackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignOutOneTrack"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SignOutTrackStudentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esSignOutTrackStudent"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<SignOutOneTrackMutation, SignOutOneTrackMutationVariables>;
export const UpdateTrackDisciplineDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateTrackDiscipline"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TrackDisciplineUpdateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esTrackUpdateDiscipline"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<UpdateTrackDisciplineMutation, UpdateTrackDisciplineMutationVariables>;
export const SignUpDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignUp"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SignUpFacultativeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esSignUpFacultative"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<SignUpMutation, SignUpMutationVariables>;
export const SignOutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignOut"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SignOutFacultativeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esSignOutFacultative"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<SignOutMutation, SignOutMutationVariables>;
export const SignUpProjectThemeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignUpProjectTheme"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProjectThemeSignUpInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paProjectThemeSignUp"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<SignUpProjectThemeMutation, SignUpProjectThemeMutationVariables>;
export const SignOutProjectThemeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignOutProjectTheme"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProjectThemeSignOutInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paProjectThemeSignOut"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<SignOutProjectThemeMutation, SignOutProjectThemeMutationVariables>;
export const DeleteFacultativeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteFacultative"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteFacultativeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esDeleteFacultative"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facultative_id"}}]}}]}}]} as unknown as DocumentNode<DeleteFacultativeMutation, DeleteFacultativeMutationVariables>;
export const DeleteTrackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteTrack"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"track_id"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esTrackDelete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"track_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"track_id"}}}]}]}}]} as unknown as DocumentNode<DeleteTrackMutation, DeleteTrackMutationVariables>;
export const CreateProjectThemeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateProjectTheme"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProjectThemeCreateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paProjectThemeCreate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<CreateProjectThemeMutation, CreateProjectThemeMutationVariables>;
export const EsDisciplineActivateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"esDisciplineActivate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"is_active"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DisciplineType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esDisciplineActivate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"is_active"},"value":{"kind":"Variable","name":{"kind":"Name","value":"is_active"}}},{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}}]}]}}]} as unknown as DocumentNode<EsDisciplineActivateMutation, EsDisciplineActivateMutationVariables>;
export const ChangeActiveTrackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeActiveTrack"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"is_active"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esTrackActivate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"is_active"},"value":{"kind":"Variable","name":{"kind":"Name","value":"is_active"}}}]}]}}]} as unknown as DocumentNode<ChangeActiveTrackMutation, ChangeActiveTrackMutationVariables>;
export const ChangeActiveTrackOneDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeActiveTrackOne"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"is_active"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"track_id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esTrackActivate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"is_active"},"value":{"kind":"Variable","name":{"kind":"Name","value":"is_active"}}},{"kind":"Argument","name":{"kind":"Name","value":"track_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"track_id"}}}]}]}}]} as unknown as DocumentNode<ChangeActiveTrackOneMutation, ChangeActiveTrackOneMutationVariables>;
export const UpdateTrackVisibilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateTrackVisibility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"is_visible"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"track_id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esTrackUpdateVisibility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"is_visible"},"value":{"kind":"Variable","name":{"kind":"Name","value":"is_visible"}}},{"kind":"Argument","name":{"kind":"Name","value":"track_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"track_id"}}}]}]}}]} as unknown as DocumentNode<UpdateTrackVisibilityMutation, UpdateTrackVisibilityMutationVariables>;
export const EsDisciplineActivateOneDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"esDisciplineActivateOne"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"is_active"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facultative_id"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DisciplineType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esDisciplineActivate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"is_active"},"value":{"kind":"Variable","name":{"kind":"Name","value":"is_active"}}},{"kind":"Argument","name":{"kind":"Name","value":"facultative_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facultative_id"}}},{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}}]}]}}]} as unknown as DocumentNode<EsDisciplineActivateOneMutation, EsDisciplineActivateOneMutationVariables>;
export const EditProcedureDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EditProcedure"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EditDisciplineProcedureInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esEditDisciplineProcedure"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"procedure_info"}},{"kind":"Field","name":{"kind":"Name","value":"procedure_data"}}]}}]}}]} as unknown as DocumentNode<EditProcedureMutation, EditProcedureMutationVariables>;
export const UpdateStatsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateStats"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateStatsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esUpdateDisciplineStats"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<UpdateStatsMutation, UpdateStatsMutationVariables>;
export const SendDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Send"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DisciplineType"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esESB_SelectedDiscipline"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<SendMutation, SendMutationVariables>;
export const OnFacultativeChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"OnFacultativeChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facultativesChanged"}}]}}]} as unknown as DocumentNode<OnFacultativeChangedSubscription, OnFacultativeChangedSubscriptionVariables>;
export const OnTracksChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"OnTracksChanged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"esTracksChanged"}}]}}]} as unknown as DocumentNode<OnTracksChangedSubscription, OnTracksChangedSubscriptionVariables>;
export const OnProjectThemeChangeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"OnProjectThemeChange"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"projectIntroChanged"}}]}}]} as unknown as DocumentNode<OnProjectThemeChangeSubscription, OnProjectThemeChangeSubscriptionVariables>;
export const GetAllProjectsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAllProjects"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paGetAll"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}},{"kind":"Argument","name":{"kind":"Name","value":"pagination"},"value":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}}}]}]}}]} as unknown as DocumentNode<GetAllProjectsQuery, GetAllProjectsQueryVariables>;
export const NameSpecListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"NameSpecList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"search"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"namespecs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"search"},"value":{"kind":"Variable","name":{"kind":"Name","value":"search"}}}]}]}}]} as unknown as DocumentNode<NameSpecListQuery, NameSpecListQueryVariables>;
export const ListNameSpecsWithAcceptedStudentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"listNameSpecsWithAcceptedStudents"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listNameSpecsWithAcceptedStudents"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}]}]}}]} as unknown as DocumentNode<ListNameSpecsWithAcceptedStudentsQuery, ListNameSpecsWithAcceptedStudentsQueryVariables>;
export const ProfsListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ProfsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"search"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nameprofs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"search"},"value":{"kind":"Variable","name":{"kind":"Name","value":"search"}}}]}]}}]} as unknown as DocumentNode<ProfsListQuery, ProfsListQueryVariables>;
export const QueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Query"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"namespec"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"search"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listNameProfsWithAcceptedStudents"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"namespec"},"value":{"kind":"Variable","name":{"kind":"Name","value":"namespec"}}},{"kind":"Argument","name":{"kind":"Name","value":"search"},"value":{"kind":"Variable","name":{"kind":"Name","value":"search"}}}]}]}}]} as unknown as DocumentNode<QueryQuery, QueryQueryVariables>;
export const ProfsListBySpecDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ProfsListBySpec"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"NameProfsListInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nameprofsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<ProfsListBySpecQuery, ProfsListBySpecQueryVariables>;
export const GetPersonsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPersons"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"search"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mode"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"persons"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"search"},"value":{"kind":"Variable","name":{"kind":"Name","value":"search"}}},{"kind":"Argument","name":{"kind":"Name","value":"mode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mode"}}},{"kind":"Argument","name":{"kind":"Name","value":"pagination"},"value":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}}}]}]}}]} as unknown as DocumentNode<GetPersonsQuery, GetPersonsQueryVariables>;
export const GetTasksDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTasks"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paTasks"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<GetTasksQuery, GetTasksQueryVariables>;
export const StagesListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"StagesList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paStages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<StagesListQuery, StagesListQueryVariables>;
export const GetProjectApplysDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetProjectApplys"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paListProjects"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}},{"kind":"Argument","name":{"kind":"Name","value":"pagination"},"value":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}}}]}]}}]} as unknown as DocumentNode<GetProjectApplysQuery, GetProjectApplysQueryVariables>;
export const ApplyProjectDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ApplyProject"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paApply"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<ApplyProjectMutation, ApplyProjectMutationVariables>;
export const CreateProjectDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateProject"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paControl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<CreateProjectMutation, CreateProjectMutationVariables>;
export const ProjectActivityCreateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ProjectActivityCreate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paInitiate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<ProjectActivityCreateMutation, ProjectActivityCreateMutationVariables>;
export const ModifyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Modify"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paModify"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<ModifyMutation, ModifyMutationVariables>;
export const AddStageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddStage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paChangeStage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<AddStageMutation, AddStageMutationVariables>;
export const DeleteStageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteStage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"req_id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"stage_num"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"event"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paDeleteStage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"req_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"req_id"}}},{"kind":"Argument","name":{"kind":"Name","value":"stage_num"},"value":{"kind":"Variable","name":{"kind":"Name","value":"stage_num"}}},{"kind":"Argument","name":{"kind":"Name","value":"event"},"value":{"kind":"Variable","name":{"kind":"Name","value":"event"}}}]}]}}]} as unknown as DocumentNode<DeleteStageMutation, DeleteStageMutationVariables>;
export const AddTaskDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddTask"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paAddTask"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<AddTaskMutation, AddTaskMutationVariables>;
export const ChangeTaskDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeTask"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paChangeTask"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<ChangeTaskMutation, ChangeTaskMutationVariables>;
export const DeleteApplyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteApply"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"req_id"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paDeleteInitiative"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"req_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"req_id"}}}]}]}}]} as unknown as DocumentNode<DeleteApplyMutation, DeleteApplyMutationVariables>;
export const DeleteProjectDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteProject"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paDeleteProject"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const AddCommentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddComment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paAddComment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<AddCommentMutation, AddCommentMutationVariables>;
export const GetCommentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetComments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paComments"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}}]}]}}]} as unknown as DocumentNode<GetCommentsQuery, GetCommentsQueryVariables>;
export const GetProjectDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetProject"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"req_id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paGetProject"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"req_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"req_id"}}}]}]}}]} as unknown as DocumentNode<GetProjectQuery, GetProjectQueryVariables>;
export const GetTaskDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTask"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"task_id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paGetTask"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"task_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"task_id"}}}]}]}}]} as unknown as DocumentNode<GetTaskQuery, GetTaskQueryVariables>;
export const PaGetConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"paGetConfig"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"configName"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"defaultConfig"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paGetConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"configName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"configName"}}},{"kind":"Argument","name":{"kind":"Name","value":"defaultConfig"},"value":{"kind":"Variable","name":{"kind":"Name","value":"defaultConfig"}}}]}]}}]} as unknown as DocumentNode<PaGetConfigQuery, PaGetConfigQueryVariables>;
export const PaSetConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PaSetConfig"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"configName"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"config"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paSetConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"configName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"configName"}}},{"kind":"Argument","name":{"kind":"Name","value":"config"},"value":{"kind":"Variable","name":{"kind":"Name","value":"config"}}}]}]}}]} as unknown as DocumentNode<PaSetConfigMutation, PaSetConfigMutationVariables>;
export const GetRecordbookDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetRecordbook"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"recordbooks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"recordbook_number"}},{"kind":"Field","name":{"kind":"Name","value":"namespec"}},{"kind":"Field","name":{"kind":"Name","value":"department"}},{"kind":"Field","name":{"kind":"Name","value":"eform"}},{"kind":"Field","name":{"kind":"Name","value":"elevel"}},{"kind":"Field","name":{"kind":"Name","value":"course"}},{"kind":"Field","name":{"kind":"Name","value":"nameprof"}},{"kind":"Field","name":{"kind":"Name","value":"recordbook_file"}},{"kind":"Field","name":{"kind":"Name","value":"programresults"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]} as unknown as DocumentNode<GetRecordbookQuery, GetRecordbookQueryVariables>;
export const GEtRecordbookDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GEtRecordbookDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"recordbook_number"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"recordbookBySemester"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"recordbook_number"},"value":{"kind":"Variable","name":{"kind":"Name","value":"recordbook_number"}}}]}]}}]} as unknown as DocumentNode<GEtRecordbookDetailsQuery, GEtRecordbookDetailsQueryVariables>;
export const GetStudentInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetStudentInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"studentInfo"}}]}}]} as unknown as DocumentNode<GetStudentInfoQuery, GetStudentInfoQueryVariables>;
export const GetStudentInfoByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetStudentInfoById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"studentInfoById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<GetStudentInfoByIdQuery, GetStudentInfoByIdQueryVariables>;
export const GetEmployeeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getEmployee"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"employeesInfo"}}]}}]} as unknown as DocumentNode<GetEmployeeQuery, GetEmployeeQueryVariables>;
export const IsLoggedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"IsLogged"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isLogged"}}]}}]} as unknown as DocumentNode<IsLoggedQuery, IsLoggedQueryVariables>;
export const SendFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"subject"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"body"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"extra"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uiFeedback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"subject"},"value":{"kind":"Variable","name":{"kind":"Name","value":"subject"}}},{"kind":"Argument","name":{"kind":"Name","value":"body"},"value":{"kind":"Variable","name":{"kind":"Name","value":"body"}}},{"kind":"Argument","name":{"kind":"Name","value":"extra"},"value":{"kind":"Variable","name":{"kind":"Name","value":"extra"}}}]}]}}]} as unknown as DocumentNode<SendFeedbackMutation, SendFeedbackMutationVariables>;
export const LogFrontEndDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LogFrontEnd"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LogFrontendInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"utilLogfrontEnd"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<LogFrontEndMutation, LogFrontEndMutationVariables>;
export const ReferencesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"References"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUserGroups"}}]}}]} as unknown as DocumentNode<ReferencesQuery, ReferencesQueryVariables>;
export const TimetableWithTimeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TimetableWithTime"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dateNow"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"studentTimetableWithTime"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"dateNow"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dateNow"}}},{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}}]}]}}]} as unknown as DocumentNode<TimetableWithTimeQuery, TimetableWithTimeQueryVariables>;
export const StudentTimetableDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"StudentTimetable"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dateStart"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dateEnd"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"groups"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"studentTimetable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"dateStart"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dateStart"}}},{"kind":"Argument","name":{"kind":"Name","value":"dateEnd"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dateEnd"}}},{"kind":"Argument","name":{"kind":"Name","value":"groups"},"value":{"kind":"Variable","name":{"kind":"Name","value":"groups"}}},{"kind":"Argument","name":{"kind":"Name","value":"limitOrCountOrAll"},"value":{"kind":"IntValue","value":"1000"}}]}]}}]} as unknown as DocumentNode<StudentTimetableQuery, StudentTimetableQueryVariables>;
export const TeacherTimetableDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TeacherTimetable"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dateStart"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dateEnd"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teacher_guid"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teacherTimetable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"dateStart"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dateStart"}}},{"kind":"Argument","name":{"kind":"Name","value":"dateEnd"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dateEnd"}}},{"kind":"Argument","name":{"kind":"Name","value":"teacher_guid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teacher_guid"}}}]}]}}]} as unknown as DocumentNode<TeacherTimetableQuery, TeacherTimetableQueryVariables>;
export const StudentTimetableCountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"StudentTimetableCount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dateStart"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dateEnd"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"studentTimetable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"dateStart"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dateStart"}}},{"kind":"Argument","name":{"kind":"Name","value":"dateEnd"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dateEnd"}}},{"kind":"Argument","name":{"kind":"Name","value":"limitOrCountOrAll"},"value":{"kind":"IntValue","value":"-1"}}]}]}}]} as unknown as DocumentNode<StudentTimetableCountQuery, StudentTimetableCountQueryVariables>;
export const StudentTimetableYearsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"StudentTimetableYears"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"studentTimetableYears"}}]}}]} as unknown as DocumentNode<StudentTimetableYearsQuery, StudentTimetableYearsQueryVariables>;
export const ActivateInternetSessionByCurrentUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ActivateInternetSessionByCurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activateInternetSessionByCurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"dateLastLogin"}},{"kind":"Field","name":{"kind":"Name","value":"intranetStatus"}},{"kind":"Field","name":{"kind":"Name","value":"ip"}},{"kind":"Field","name":{"kind":"Name","value":"login"}}]}}]}}]} as unknown as DocumentNode<ActivateInternetSessionByCurrentUserMutation, ActivateInternetSessionByCurrentUserMutationVariables>;
export const DeactivateInternetSessionByCurrentUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeactivateInternetSessionByCurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deactivateInternetSessionByCurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"dateLastLogin"}},{"kind":"Field","name":{"kind":"Name","value":"intranetStatus"}},{"kind":"Field","name":{"kind":"Name","value":"ip"}},{"kind":"Field","name":{"kind":"Name","value":"login"}}]}}]}}]} as unknown as DocumentNode<DeactivateInternetSessionByCurrentUserMutation, DeactivateInternetSessionByCurrentUserMutationVariables>;
export const RemoveFileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveFile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"fileId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeFile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"fileId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"fileId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RemoveFileResult"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RemoveFile"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"InvalidArgumentError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"InvalidArgumentError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]}}]} as unknown as DocumentNode<RemoveFileMutation, RemoveFileMutationVariables>;
export const GetDownloadUrlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetDownloadUrl"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"fileId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPresignedDownloadUrl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"fileId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"fileId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FileNotFoundError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"InvalidArgumentError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PresignedDownloadUrl"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"downloadUrl"}}]}}]}}]}}]} as unknown as DocumentNode<GetDownloadUrlQuery, GetDownloadUrlQueryVariables>;
export const GetWorkspaceIconsByCurrentUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetWorkspaceIconsByCurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getWorkspaceIconsByCurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"iconDescription"}},{"kind":"Field","name":{"kind":"Name","value":"iconID"}},{"kind":"Field","name":{"kind":"Name","value":"iconImage"}},{"kind":"Field","name":{"kind":"Name","value":"iconLink"}},{"kind":"Field","name":{"kind":"Name","value":"iconName"}},{"kind":"Field","name":{"kind":"Name","value":"iconSort"}}]}}]}}]} as unknown as DocumentNode<GetWorkspaceIconsByCurrentUserQuery, GetWorkspaceIconsByCurrentUserQueryVariables>;
export const GetInternetConnectionStatusByCurrentUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetInternetConnectionStatusByCurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getInternetConnectionStatusByCurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"dateLastLogin"}},{"kind":"Field","name":{"kind":"Name","value":"intranetStatus"}},{"kind":"Field","name":{"kind":"Name","value":"ip"}},{"kind":"Field","name":{"kind":"Name","value":"login"}}]}}]}}]} as unknown as DocumentNode<GetInternetConnectionStatusByCurrentUserQuery, GetInternetConnectionStatusByCurrentUserQueryVariables>;
export const CheckFilesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"checkFiles"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"files"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FileEntryInput"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkFiles"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"files"},"value":{"kind":"Variable","name":{"kind":"Name","value":"files"}}}]}]}}]} as unknown as DocumentNode<CheckFilesQuery, CheckFilesQueryVariables>;
export const FileUploadConfirmationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"FileUploadConfirmation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"fileId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fileUploadConfirmation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"fileId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"fileId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FileUploadConfirmation"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FileNotFoundError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"InvalidArgumentError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]} as unknown as DocumentNode<FileUploadConfirmationMutation, FileUploadConfirmationMutationVariables>;
export const GetUploadUrlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GetUploadUrl"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inputFile"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InputFile"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPresignedUploadURL"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inputFile"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inputFile"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PresignedUploadUrlResult"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"InvalidArgumentError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PresignedUploadUrl"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"fileId"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetUploadUrlMutation, GetUploadUrlMutationVariables>;
export const OnUiNotificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"OnUiNotification"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uiNotification"}}]}}]} as unknown as DocumentNode<OnUiNotificationSubscription, OnUiNotificationSubscriptionVariables>;
export const ListNotificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ListNotification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"NotificationListInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"body"}},{"kind":"Field","name":{"kind":"Name","value":"meta"}},{"kind":"Field","name":{"kind":"Name","value":"from"}},{"kind":"Field","name":{"kind":"Name","value":"msg_id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"created"}},{"kind":"Field","name":{"kind":"Name","value":"source"}}]}}]}}]}}]} as unknown as DocumentNode<ListNotificationQuery, ListNotificationQueryVariables>;
export const NotificationStatsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"NotificationStats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uiNotificationsStats"}}]}}]} as unknown as DocumentNode<NotificationStatsQuery, NotificationStatsQueryVariables>;
export const AddNotificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddNotification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"i"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"UINotificationInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationCreate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"i"}}}]}]}}]} as unknown as DocumentNode<AddNotificationMutation, AddNotificationMutationVariables>;
export const UpdateNotificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateNotification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"message_ids"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"status"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"NotificationStatus"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationUpdateStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"msg_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"message_ids"}}},{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"Variable","name":{"kind":"Name","value":"status"}}}]}]}}]} as unknown as DocumentNode<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
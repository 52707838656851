import styles from './PortfolioAchievementChangeStatusPopup.module.less'
import { useMemo, useState, } from 'react'
import ButtonSimple, { IconComponent, } from '../../common/ButtonSimple/ButtonSimple'
import { TitleH3, } from '../../common/GeneralElements/GeneralElements'
import useGetRole from '../../../redux/hooks/common/useGetRole'
import clsx from 'clsx'
import TextField from '../../common/Fields/TextField/TextField'
import AttachedFiles from '../../common/AttachedFiles/AttachedFiles'
import { format, } from 'date-fns'
import { ru, } from 'date-fns/locale'
import { IconCircleCheck, IconCircleX, } from '@tabler/icons'
import useUpdateAchievement from '../../../gql/hooks/portfolio/useUpdateAchievement'
// import { useGetAchievementTypeInfo, } from '../../../gql/hooks/portfolio/useAchievementTypes'


const PortfolioAchievementChangeStatusPopup = ({
  achievement,
  popUpCloseHandler,
}: any) => {
  const userRole = useGetRole()
  const [
    comment,
    setComment,
  ] = useState(achievement?.comment?.moderator ? achievement?.comment?.moderator : '')
  // const { basicScores, } = useGetAchievementTypeInfo(achievement?.catid, achievement?.type_id)

  const updateAchievement = useUpdateAchievement()


  const renderForm = (arr: any) => {
    const form = new Array(arr.length)

    arr.forEach((item: any, index: any) => {
      if (item.type === 'data' && !item?.value?.isWithoutDay) {
        return form[index] = (
          <TextField
            isDisabled
            key={ index }
            styles={ {
              width: '100%',
              marginBottom: '20px',
            } }
            value={
              format(
                new Date(item?.value ? item.value * 1000 : new Date().getTime()),
                'dd MMMM yyyy',
                { locale: ru, }
              )
            }
          />
        )
      }

      if (item.type === 'text') {
        return form[index] = (
          <TextField
            isDisabled
            key={ index }
            styles={ {
              width: '100%',
              marginBottom: '20px',
            } }
            value={ item.value }
          />
        )
      }

      if (item.type === 'select') {
        return form[index] = (
          <TextField
            isDisabled
            key={ index }
            styles={ {
              width: '100%',
              marginBottom: '20px',
            } }
            value={ item?.options[item?.value]?.name }
          />
        )
      }

      if (item.type === 'file' && (!item.required?.elevel?.[0])) {
        const file = {
          ...item,
          name: 'Скачать документ',
          upload_id: item.value,
        }

        return form[index] = (
          <div key={ index }>
            <p className="fileTitle">
              { item?.name }
            </p>

            <AttachedFiles
              fileList={ [
                file,
              ] }
              isDeleteFilesAllowed={ false }
            />
          </div>
        )
      }
    })


    return form
  }


  const renderedForm = useMemo(() => renderForm(achievement.fields), [
    achievement,
  ])


  return <>
    <TitleH3>
      { achievement.direction }
    </TitleH3>

    { renderedForm }

    <br />

    <TextField
      styles={ {
        width: '100%',
        marginBottom: '20px',
      } }
      value={ comment }
      handler={ setComment }
      placeHold="Комментарий"
    />


    { /moder$/.test(userRole) && (
      <div className={ clsx(
        styles.buttonWrapper,
        styles.buttonWrapper_toRight
      ) }>
        <ButtonSimple
          value={ (
            <IconComponent
              Icon={ IconCircleX }
              iconStyles={ { color: '#FF5E5E', } }
              text="Отклонить"
            />
          ) }
          variant="groupe"
          styles={ { marginRight: '1rem', } }
          onClickHandler={ () => {
            const newComment = {
              ...(achievement?.comment || {}),
            }

            if (comment?.length) {
              const commentIndex = Object.keys(newComment).length

              newComment[commentIndex] = {
                authorName: 'Модератор',
                message: comment,
              }
            }

            updateAchievement({
              achievement_id: achievement.achievement_id,
              comment: newComment,
              userpoints: 0,
              status: 'rejected',
            })
          } }
        />

        <ButtonSimple
          value={ (
            <IconComponent
              Icon={ IconCircleCheck }
              text="Подтвердить"
            />
          ) }
          variant="groupe"
          active
          onClickHandler={ () => {
            updateAchievement({
              achievement_id: achievement.achievement_id,
              comment: {
                ...achievement?.comment,
                moderator: comment,
              },
              // userpoints: basicScores,
              status: 'available',
            })
            popUpCloseHandler()
          } }
        />
      </div>
    ) }
  </>
}


export default PortfolioAchievementChangeStatusPopup

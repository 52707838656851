import { TitleH3, TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import ButtonSimple, { IconComponent, } from '../../../../common/ButtonSimple/ButtonSimple'
import { IconCircleCheck, IconCircleX, } from '@tabler/icons'
import { useEffect, useState, } from 'react'
import TextArea from '../../../../common/TextArea/TextArea'
import { useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useGetEcListData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useLocation, } from 'react-router-dom'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputFile from '../../../../common/InputFile/InputFile'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import { getScores_3_3, } from '../../../getScores'
import { table_cat_3_3, } from '../../../popUpInputsCatalogs'


const PopUpAppealContent_3_3 = ({
  data,
  onClosePopUp,
}: any) => {
  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  const [
    ids,
  ] = useState(moderateId)


  const [
    level,
    setLevel,
  ] = useState('')
  const [
    organizationName,
    setOrganizationName,
  ] = useState('')
  const [
    validityPeriod,
    setValidityPeriod,
  ] = useState('')
  const [
    documents,
    setDocuments,
  ] = useState([])


  const [
    textAppeal,
    setTextAppeal,
  ] = useState('')
  const [
    currentAppealId,
    setCurrentAppealId,
  ] = useState(data.rec_id)
  const [
    currentAchievementId,
    setCurrentAchievementId,
  ] = useState(null)
  const [
    rejectAchievement,
    setRejectAchievement,
  ] = useState(false)
  const [
    reason,
    setReason,
  ] = useState('')
  const [
    scores,
    setScores,
  ] = useState('')


  useEffect(() => {
    setLevel(data.level)
    setOrganizationName(data.organizationName)
    setValidityPeriod(data.validityPeriod)
    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setDocuments(data.documents ? [
      ...data.documents,
    ] : [])

    setTextAppeal(data.textAppeal)
    setCurrentAppealId(data.rec_id)
  }, [
    data,
  ])


  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    setScores(getScores_3_3())
  }, [])


  const [
    currentAchievement,
    setCurrentAchievement,
  ] = useState(null)
  const [
    currentAppeal,
    setCurrentAppeal,
  ] = useState(null)

  const getCurrentAppeal = useGetEcListData({
    rec_id: currentAppealId,
  })

  const getCurrentAchievement = useGetEcListData({
    rec_id: currentAchievementId,
  })

  useEffect(() => {
    if (getCurrentAppeal?.data) {
      setCurrentAppeal(
        getCurrentAppeal?.data?.ecListData[0]?.data)

      setCurrentAchievementId(getCurrentAppeal?.data?.ecListData[0]?.data?.rec_id)
    }
  }, [
    getCurrentAppeal.loading,
    getCurrentAppeal.data,
  ])

  useEffect(() => {
    if (getCurrentAchievement?.data && currentAchievementId) {
      setCurrentAchievement(
        getCurrentAchievement?.data?.ecListData[0]?.data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCurrentAchievement.loading,
    getCurrentAchievement.data,
  ])


  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (rec_id: any, newData: any, ids: any) => onUpdateRow({
    variables: {
      rec_id: rec_id,
      new_data: newData,
      user_ids: ids,
    },
  })


  useEffect(() => {
    setRejectAchievement(false)
  }, [
    data,
  ])


  return <>
    <TitleH3>
      Экспертная деятельность, членство в жюри, судейство федерального или международного уровней.
    </TitleH3>
    <p>Внесите требуемые изменения и подтвердие или отклоните апелляцию</p>
    <p>Комментарий: <i><b>{ textAppeal }</b></i></p>
    <br />


    { !rejectAchievement ? (
      <>
        <InputSelectDeprecated
          defaultHint="Уровень"
          items={ table_cat_3_3.level }
          defaultValue={ level }
          withResetValue={ true }
          callBack={ setLevel }
        /><br />


        <InputTextDeprecated
          defaultHint="Название организации"
          value={ organizationName }
          onChangeValue={ setOrganizationName }
        /><br />


        <InputTextDeprecated
          defaultHint="Сроки действия сертификата (удостоверения)"
          value={ validityPeriod }
          onChangeValue={ setValidityPeriod }
        /><br />


        <TitleH5>
          Подтверждающие документы
        </TitleH5>

        <InputFile
          onChange={ event => {
            // TODO: добавить uploadFiles
            // uploadFiles(event.target.files, documents, setDocuments)
          } }
        /><br />


        <AttachedFiles
          isDeleteFilesAllowed={ true }
          fileList={ documents }
          getIndexFile={ (index: any) => {
            setDocuments(documents.filter((file, fIndex) => fIndex !== index))
          } }
        /><br />


        <InputTextDeprecated
          defaultHint="Баллы"
          value={ scores }
          numbersOnly
          disabled
        /><br />
      </>
    ) : false }


    { rejectAchievement ? (
      <>
        <TextArea
          defaultHint='По какой причине вы хотите отклонить заявку?'
          withPlaceholder={ false }
          value={ reason }
          onChangeValue={ setReason }
        />

        <br />

        <div style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }>
          <ButtonSimple
            value="Отменить"
            variant="groupe"
            styles={ {
              marginRight: '1rem',
              backgroundColor: '#F5F6FA',
            } }
            onClickHandler={ () => {
              setRejectAchievement(false)
            } }
          />

          <ButtonSimple
            value={ (
              <IconComponent
                Icon={ IconCircleX }
                iconStyles={ { color: '#FF5E5E', } }
                text="Отклонить"
              />
            ) }
            variant="groupe"
            isDisabled={ reason.split(' ').length < 3 }
            onClickHandler={ () => {
              onUpdateRowHandler(
                currentAppealId,
                {
                  // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                  ...currentAppeal,
                  status: 'appealRejected',
                  appealConclusion: reason,
                },
                ids
              )

              onClosePopUp()
            } }
          />
        </div>
      </>
    ) : false }

    { !rejectAchievement ? (
      <div style={ {
        display: 'flex',
        justifyContent: 'flex-end',
      } }>
        <ButtonSimple
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          isDisabled={ ids.length < 1 }
          value={ (
            <IconComponent
              Icon={ IconCircleX }
              iconStyles={ { color: '#FF5E5E', } }
              text="Отклонить"
            />
          ) }
          variant="groupe"
          styles={ { marginRight: '1rem', } }
          onClickHandler={ () => setRejectAchievement(true) }
        />

        <ButtonSimple
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          isDisabled={ ids.length < 1 }
          value={ (
            <IconComponent
              Icon={ IconCircleCheck }
              text="Подтвердить"
            />
          ) }
          variant="groupe"
          active
          onClickHandler={ () => {
            onUpdateRowHandler(
              currentAchievementId,
              {
                // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                ...currentAchievement,
                level: level,
                organizationName: organizationName,
                validityPeriod: validityPeriod,
                documents: documents,
                status: 'approved',
                scores: scores,
              },
              ids
            )

            onUpdateRowHandler(
              currentAppealId,
              {
                // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                ...currentAppeal,
                status: 'appealApproved',
                appealConclusion: 'Ваша апелляция удовлетворена',
              },
              ids
            )

            onClosePopUp()
          } }
        />
      </div>
    ) : false }
  </>
}


export default PopUpAppealContent_3_3

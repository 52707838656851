import { useEffect, } from 'react'
import 'react-quill/dist/quill.snow.css'
import { useDispatch, useSelector, } from 'react-redux'
import useUpdateNotificationDraft from '../../../../../../gql/hooks/moderateNotifications/useUpdateNotificationDraft'
import { setCreateNotificationForm, } from '../../../../../../redux/moderateNotificationsSlice/moderateNotificationsSlice'
import ButtonSimple from '../../../../../common/ButtonSimple/ButtonSimple'
import { defineDisableForButton, } from '../../../../AddNotificationButton/PopupContent/PopupContent'
import useCreateFormNotificationEdit from '../../../../hooks/useCreateFormNotificationEdit'
import createNotificationDraftBody from '../../../../utils/createNotificationDraftBody'


const getFilledForm = ({
  form,
  formData,
}: any) => {
  const newForm = JSON.parse(JSON.stringify(form))
  const entries = Object.entries(formData)

  for (const [
    jsonFieldName,
    value,
  ] of entries) {
    const formField = newForm.find((field: any) => field.jsonFieldName === jsonFieldName)

    if (formField) {
      formField.value = value
    }
  }


  return newForm
}


const EditDraftPopupContent = ({
  onClosePopup,
  formData,
  itemId,
}: any) => {
  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const createNotificationForm = useSelector(state => state.moderateNotifications.createNotificationForm)
  const { onUpdateNotificationDraft, } = useUpdateNotificationDraft()


  useEffect(() => {
    dispatch(
      setCreateNotificationForm({
        filledForm: getFilledForm({
          form: createNotificationForm,
          formData,
        }),
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onUpdateNotificationDraftHandler = () => {
    onUpdateNotificationDraft({
      draft_id: itemId,
      body: createNotificationDraftBody({
        formTemplate: createNotificationForm,
      }),
    })
      .then(() => onClosePopup((prev: any) => !prev))
  }


  const form = useCreateFormNotificationEdit({
    formObject: createNotificationForm,
  })


  return (
    <>
      { form }

      <ButtonSimple
        variant={ 'full-filled' }
        value={ 'Сохранить' }
        onClickHandler={ onUpdateNotificationDraftHandler }
        isDisabled={ defineDisableForButton(createNotificationForm) }
      />
    </>
  )
}


export default EditDraftPopupContent

import { useMemo, useState, } from 'react'
import Popup from '../../common/Popup/Popup'
import AmsCardAchievement from './AmsCardAchievement/AmsCardAchievement'
import { TitleH3, } from '../../common/GeneralElements/GeneralElements'
import InputTextDeprecated from '../../common/InputTextDeprecated/InputTextDeprecated'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import { useCreateEcData, } from '../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import useGetHandlerForUpdateEcData from '../../../gql/hooks/effectiveContractHooks/useGetHandlerForUpdateEcData'
import TextArea from '../../common/TextArea/TextArea'

const CardContainer = ({
  cardsData = [],
  cardFields = [],
  returnDataForDisplayRejectReason,
}: any) => {
  const [
    isAddAppeal,
    setIsAddAppeal,
  ] = useState(false)
  const [
    appealData,
    setAppealData,
  ] = useState({})
  const [
    textAppeal,
    setTextAppeal,
  ] = useState('')
  const [
    appealStatus,
    setAppealStatus,
  ] = useState({})
  const [
    isShowAppealStatus,
    setIsShowAppealStatus,
  ] = useState(false)
  const { onUpdateHandler, } = useGetHandlerForUpdateEcData()

  const reurnDataForAddAppeal = (data: any) => {
    setIsAddAppeal(true)
    setAppealData(data)
  }

  const [
    onAddNewEcDataAppeal,
  ] = useCreateEcData()
  const onAddNewEcDataAppealHandler = (newAppealParam: any, updateCurrentAchievementParam: any) => {
    onAddNewEcDataAppeal(newAppealParam)
      .then(() => onUpdateHandler({ ...updateCurrentAchievementParam, }))
      .then(() => setIsAddAppeal(false))
  }


  const cards = useMemo(() => {
    // @ts-expect-error TS(7006): Parameter 'card' implicitly has an 'any' type.
    return cardsData.map(card => (
      <AmsCardAchievement
        // @ts-expect-error TS(7006): Parameter 'field' implicitly has an 'any' type.
        cardFormData={ cardFields.map(field => {
          return {
            title: field.title,
            subtitle: card.data[field.index],
            thresholdValue: field?.thresholdValue,
            round: field?.round,
          }
        }) }
        cardAchievementData={ {
          dataType: card.dataType.replace('table', 'appeal'),
          data: {
            ...card.data,
            achievement_id: card.rec_id,
            status: 'appealPending',
          },
          user_ids: card.user_ids,
        } }
        key={ card.rec_id }
        status={ card.data.status }
        getReasonFailure={ returnDataForDisplayRejectReason }
        reason={ card.data.reason }
        reurnDataForAddAppeal={ reurnDataForAddAppeal }
        appeal={ card.appeal }
        setIsShowAppealStatus={ setIsShowAppealStatus }
        setAppealStatus={ setAppealStatus }
      />)
    )
  }, [
    cardsData,
    cardFields,
    returnDataForDisplayRejectReason,
  ])


  return (
    <>
      { cards }

      { isAddAppeal && (
        <Popup
          isOpen={ isAddAppeal }
          handlePopup={ () => {
            setIsAddAppeal(false)
          } }
        >
          <>
            <TitleH3>
              Подать апелляцию
            </TitleH3>

            <InputTextDeprecated
              defaultHint="Текст апелляции"
              value={ textAppeal }
              onChangeValue={ setTextAppeal }
            />

            <br />

            <div>
              <ButtonSimple
                value="Подать апелляцию"
                variant="full-filled"
                onClickHandler={ () => {
                  onAddNewEcDataAppealHandler(
                    {
                      variables: {
                        // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
                        dataType: appealData.dataType,
                        data: {
                          // @ts-expect-error TS(2339): Property 'data' does not exist on type '{}'.
                          ...appealData.data,
                          textAppeal: textAppeal.trim(),
                        },
                      },
                    },
                    {
                      // @ts-expect-error TS(2339): Property 'data' does not exist on type '{}'.
                      rec_id: appealData.data.achievement_id,
                      new_appeal: {
                        textAppeal: textAppeal.trim(),
                        status: 'appealPending',
                      },
                    }
                  )
                } }
                isDisabled={ textAppeal.trim().length <= 0 }
              />
            </div>
          </>
        </Popup>
      ) }


      { isShowAppealStatus && (
        <Popup
          children={ (
            <>
              <h2 className="popup-content-reject__title">Ваша апелляция</h2>

              <TextArea
                defaultHint="Текст апелляции"
                // @ts-expect-error TS(2339): Property 'textAppeal' does not exist on type '{}'.
                value={ appealStatus?.textAppeal }
                rows={ 3 }
                isDisabled
              />

              <br />

              <TextArea
                style={ { borderColor: '#FF5E5E', } }
                defaultHint="Модератор:"
                // @ts-expect-error TS(2339): Property 'appealConclusion' does not exist on type... Remove this comment to see the full error message
                value={ appealStatus?.appealConclusion }
                rows={ 3 }
                isDisabled
              />

              <br />

              <div>
                <ButtonSimple
                  value="Понятно"
                  variant="full-filled"
                  onClickHandler={ () => setIsShowAppealStatus(false) }
                />
              </div>
            </>
          ) }
          isOpen={ isShowAppealStatus }
          handlePopup={ () => {
            setIsShowAppealStatus(false)
          } }
        />
      ) }
    </>
  )
}


export default CardContainer

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, } from 'react'
// import {useSelector} from 'react-redux';
import clsx from 'clsx'
import './FilterPopup.less'
// import ClickAwayListener from '@mui/material/ClickAwayListener';
// import { useUserpicQuery } from '../../../redux/utilsApi/imageApi';
import Image from '../Image/Image'


function FilterPopup ({
  items,
  isOpen,
  handler,
  onClickAway,
  type,
  clearField,
  itemType,
}: any) {
  function handleClick (i: any) {
    handler(i)
    clearField && clearField()
    if (onClickAway) {
      onClickAway()
    }
  }

  const [
    filteredItems,
    setFilteredItems,
  ] = useState([])


  useEffect(() => {
    if ((itemType === 'multiple' && items)) {
      items.map((i: any) => {
        // @ts-expect-error TS(2339): Property 'value' does not exist on type 'never'.
        if (!filteredItems.find(h => h.value === i.value)) {
          // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
          filteredItems.push({
            ...i,
            value: i.name,
            id: i.key,
          })
        }
      })
    }
  }, [
    items,
    filteredItems,
    itemType,
  ])

  const arr = itemType === 'multiple' ? filteredItems : items

  /* const container = document.querySelector('.projPopup')
    console.log('height', container)
  */
  /*  const listener = useCallback(() => {
     setMore((page.scrollHeight - page.scrollTop) - window.innerHeight)
   }, [page])

   useEffect(() => {
     if (more < 600) {
       console.log("ещеееееее", more)
     }
   }, [more, page, listener]) */

  return (
    <ul className={ clsx('projPopup', !isOpen && 'hidden') }>
      { arr && arr.map((i: any, index: any) => {
        return type === 'person' ? <li onClick={ () => handleClick(i) } key={ index } className="projPopupItem persons">
          { <Image id={ i.id } style={ {
            width: '24px',
            height: '24px',
            borderRadius: '20px',
          } } /> }
          <div className="consultant">
            <p>{ i.full_name }</p>
            <div className="mail">{ i.contacts.map((con: any) => {
              if (con.type === 'Корпортативная почта') {
                return con.value
              } return null
            }) }</div>
          </div>
        </li> : <li onClick={ itemType === 'multiple' ? () => handleClick(i) : () => handleClick(i.value ? i.value : i) } key={ index } className="projPopupItem">{ i.value ? i.value : i.name }{ itemType !== 'multiple' && i }</li>
      }) }
      { (arr?.length === 0) && <li key="sggreg" className="projPopupItem persons">Нет совпадений</li> }
      { !arr && <li key="sggrefgebrg" className="projPopupItem persons">....Поиск....</li> }
    </ul>
  )
}


export default FilterPopup

// TODO: Переписать этот компонент на более тупой


import { TextareaAutosize, } from '@mui/material'
import { useEffect, useMemo, useState, } from 'react'
import { DebounceInput, } from 'react-debounce-input'
import { useNameSpecList, } from '../../../../gql/hooks/useProjects'
import FilterPopup from '../../../Projects/FilterPopup/FilterPopup'

const SpecsSearch = ({
  value = '',
  onChangeValue,
  handler,
  styles,
  withPlaceholder = false,
  defaultHint,
  withListPositionAbsolute,
  maxHeightList,
}: any) => {
  const [
    searchValue,
    setSearchValue,
  ] = useState('')
  const [
    isOpen,
    setIsOpen,
  ] = useState(false)

  useEffect(() => {
    if (value.length > 0) {
      setSearchValue(value)
    }
  }, [
    value,
  ])

  const { data, error, loading, } = useNameSpecList(value)


  const result = useMemo(() => {
    if (error) {
      return <div>.....errrr</div>
    } else if (loading) {
      return <div>......loading....</div>
    } else if (data) {
      return (
        <FilterPopup
          isOpen={ searchValue.length > 0 && isOpen }
          handler={ (item: any) => {
            setSearchValue('')
            handler(item)
          } }
          clearField={ () => setIsOpen(false) }
          items={ data.namespecs.map((item: any) => item.value) }
          style={ {
            top: 'unset',
            left: 'unset',
            position: withListPositionAbsolute ? 'absolute' : 'fixed',
            maxHeight: maxHeightList,
          } }
        />
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    data,
    loading,
  ])


  return (
    <>
      <label className="input-text" style={ styles }>
        <div className="input-text__inner">
          <span className="input-text__label-hint">
            { (value || !withPlaceholder) ? defaultHint : false }
          </span>

          <DebounceInput
            debounceTimeout={ 500 }
            // @ts-expect-error TS(2769): No overload matches this call.
            element={ TextareaAutosize }
            value={ searchValue }
            onChange={ event => {
              onChangeValue(event.target.value)
              setSearchValue(event.target.value)
              if (!isOpen) {
                setIsOpen(true)
              }
            } }
            style={ {
              border: 0,
              resize: 'none',
              width: '100%',
              outline: 'none',
            } }
          />
        </div>
      </label>
      { result }
    </>
  )
}

export default SpecsSearch

import { createSlice, } from '@reduxjs/toolkit'


const initialState = {
  realUserGuid: null,
}


export const FakeChageUserSlice = createSlice({
  name: 'fakeChangeUser',
  initialState,
  reducers: {
    setRealUserGuid: (state, action) => {
      state.realUserGuid = action.payload
    },
  },
})


export const { setRealUserGuid, } = FakeChageUserSlice.actions


export default FakeChageUserSlice.reducer

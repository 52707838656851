export const enum UserRoles {
  TESTER = 'tester',
  STUDENT = 'student',
  /** Иностранный студент */
  STUDENT_RAKUS = 'student-rakus',
  EMPLOYEE = 'employee',
  TEACHER = 'teacher',
  TEACHER_GPH = 'teacherGPH',
  WORKER = 'worker',
  DIRECTOR_INSTITUTE = 'director-Institute',
  DIRECTOR_FACULTY = 'director-faculty',
  PA_MODER = 'pa_moder',
  OSRV_MODER = 'osrv-moder',
  URTOS_MODER = 'urtos-moder',
  PORTFOLIO_MFC_MODER = 'portfolio-mfc-moder',
  PORTFOLIO_KURATOR = 'portfolio-kurator',
  URVO_ADMIN = 'urvo-admin',
  AUP_ADMIN = 'aup-admin',
  DMS_MODER = 'dms-moder',
  UFPN_MODER = 'ufpn-moder',
  UONID_MODER = 'uonid-moder',
  DM_MODER = 'dm-moder',
  UKP_MODER = 'ukp-moder',
  TPD_MODER = 'tpd-moder',
  URVO_MODER = 'urvo-moder',
  URVO_TWO_MODER = 'urvo-2-moder',
  AUP_PFU_MODER = 'aup-pfu-moder',
  AUP_PK_MODER = 'aup-pk-moder',
  AUP_DMS_MODER = 'aup-dms-moder',
  PROSMOTR_MOE_OBUCHENIE = 'prosmotr-moe-obuchenie',
  AUP_CK_MODER = 'aup-ck-moder',
  NOTIFICATION_MODER = 'notification-moder',
  /** Гость */
  SELFSIGNUP = 'selfsignup',
  /** Неизвестная роль */
  UNKNOWN = 'unknown',
}

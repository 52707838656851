import { gql, useQuery, } from '@apollo/client'


const GET_LIST_OF_SCIENTIFIC_IDS = gql(`
 query ecListOfScientificIds($dtsuId: String!) {
  ecListOfScientificIds(dtsuId: $dtsuId)
}
`)

export const useGetListOfScientificIds = (dtsuId: string) => {
  const { data, loading, error, } = useQuery(GET_LIST_OF_SCIENTIFIC_IDS, {
    variables: {
      dtsuId,
    },
  })

  return { data,
    loading,
    error, }
}

import { gql, useMutation, } from '@apollo/client'


const REMOVE_SENDED_NOTIFICATIONS = gql`
  mutation NotificationDraftPurgeMessages($draft_id: [Int!]!) {
    notificationDraftPurgeMessages(draft_id: $draft_id)
  }
`

const useNotificationDraftPurgeMessages = () => {
  const [
    onRemove,
  ] = useMutation(REMOVE_SENDED_NOTIFICATIONS)

  const onRemoveSendedNotificationHandler = ({
    draftIds,
  }: any) => onRemove({
    variables: {
      draft_id: draftIds,
    },
  })


  return { onRemoveSendedNotificationHandler, }
}


export default useNotificationDraftPurgeMessages

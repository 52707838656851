export const table_cat_1_1 = {
  projectType: [
    'Учебник',
    // 'Монография',
    'Учебное пособие',
    'Учебно-методическое пособие',
    'Мультимедийное учебно-методическое пособие (для доцента)',
    'мультимедийное учебное пособие (для доцента/профессора)',
  ],
  publish: [
    // 'Зарубежное издательство',
    'Российское издательство',
    'Региональное издательство',
  ],
  countAuthors: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
  ],
  format: [
    'электронное',
    'печатное',
  ],
  financeSource: [
    'средства университета',
    'грант',
    'собственные средства',
  ],
}


export const table_cat_1_11 = {
  reason: [
    'РПД отсутствует/с недочетами',
    'MOODLE заполнен не в полном объёме',
    'Отсутствует РПД и заполненность в MOODLE',
  ],
}

export const table_cat_1_12 = {
  indicators: [
    'Своевременное ведение журнала (порог от 90%)',
    '% заполнения тем (порог от 94%)',
    'Своевременная выдача домашнего задания (порог от 90%)',
  ],
}
export const table_cat_1_2 = {
  course_of_category: [
    'Курсы по специальности',
    'Инклюзивное образование',
    'Основы оказания первой доврачебной помощи',
    'Информационно-коммуникационные технологии (ИКТ)',
    'Противодействие коррупции',
    'Педагогические курсы',
  ],
}


export const table_cat_1_4 = {
  articleName: [
    'article',
    'Название',
    'book_title',
    'Выходные данные',
    'Наименование публикации (на языке оригинала)',
    'Название статьи',
  ],
  journalName: [
    'journal',
    'Название журнала',
    'Название издания',
    'Вид публикации',
    'type_work',
  ],
  publication_year: [
    'Год издания',
    'publication_year',
    'Дата публикации',
  ],
  authorsName: [
    'names',
    'Роль',
    'ФИО авторов (сотрудники ТГУ)',
    'авторство',
  ],
}


export const table_cat_2_1 = {
  presentName: [
    'Премия Президента',
    'Премия Правительства',
  ],
}


export const table_cat_2_2 = {
  language: [
    'английский',
    'немецкий',
    'французский',
    'иной',
  ],
  level: [
    'B2',
    'C1',
    'C2',
  ],
}


export const table_cat_2_3 = {
  projectType: [
    'Учебник',
    'Монография',
    'Учебное пособие',
  ],
  publish: [
    'Зарубежное издательство',
    'Российское издательство',
    'Региональное издательство',
  ],
  countAuthors: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
  ],
  format: [
    'электронное',
    'печатное',
  ],
  financeSource: [
    'средства университета',
    'грант',
    'собственные средства',
  ],
}


export const table_cat_2_4 = {
  courseType: [
    'Мультимедийный электронный учебник',
    'мультимедийное учебное пособие',
  ],
}


export const table_cat_2_41 = {
  language: [
    'Русский',
    'Иностранный',
  ],
}


export const table_cat_2_5 = {
  vectorActivity: [
    'учебная',
    'научная',
    'инновационная',
    'культурно-массовая',
    'иная',
  ],
  level: [
    'Всероссийский',
    'Международный',
  ],
}


export const table_cat_2_6 = {
  membership: [
    'Российский университет',
    'Ближнее зарубежье',
    'Дальнее зарубежье',
  ],
  format: [
    'В очном формате',
    'В дистанционной форме',
  ],
  network_interaction_agreement: [
    'Нет',
    'Да',
  ],
}


export const table_cat_2_7 = {
  foreign_authors: [
    'без соавтора (ов) из дальнего зарубежья',
    'с соавтором (ами) из дальнего зарубежья',
  ],
  quartileNumber: [
    'Q1',
    'Q2',
    'Q3',
    'Q4',
    'Без квартиля',
  ],
  publication_type: [
    'Article',
    'Review',
  ],
  source_type: [
    'Журнал',
    'Иное',
  ],
}


export const table_cat_2_10 = {
  role: [
    'Член редколлегии журнала WOS/Scopus',
    'Гл. редактор журнала WOS/Scopus',
    'Зам гл. редактора журнала WOS/Scopus',
    'Гл. редактор  журнала из списка ВАК',
    'Член редколлегии журнала из списка ВАК',
  ],
}


export const table_cat_3_1 = {
  category: [
    'Защита собственной диссертации',
    'Осуществление руководства диссертацией, защищенной в отчетном периоде',
    'Рецензирование диссертационных работ (отзыв ведущей организации)',
    'Рецензирование диссертационных работ (оппонирование)',
  ],
  membership: [
    'докторская',
    'кандидатская',
  ],
}


export const table_cat_3_3 = {
  level: [
    'Федеральный',
    'Международный',
  ],
}

export const table_cat_3_31 = {
  level: [
    'Всероссийский',
    'Международный',
  ],
  roles: [
    'Член жюри',
    'Член судейской бригады',
  ],
}

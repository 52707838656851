import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import Popup from '../../common/Popup/Popup'
import TextArea from '../../common/TextArea/TextArea'

const PopupStatus = ({
  appealData,
  isOpenPopupStatus,
  setIsOpenPopupStatus,
}: any) => {
  return (
    <Popup
      isOpen={ isOpenPopupStatus }
      handlePopup={ () => {
        setIsOpenPopupStatus(false)
      } }
    >
      <>
        <h2 className="popup-content-reject__title">Ваша апелляция</h2>

        <TextArea
          defaultHint="Текст апелляции"
          value={ appealData?.textAppeal }
          rows={ 3 }
          isDisabled
        />

        <br />

        <TextArea
          style={ { borderColor: '#FF5E5E', } }
          defaultHint="Модератор:"
          value={ appealData?.appealConclusion }
          rows={ 3 }
          isDisabled
        />

        <br />

        <div>
          <ButtonSimple
            value="Понятно"
            variant="full-filled"
            onClickHandler={ () => setIsOpenPopupStatus(false) }
          />
        </div>
      </>
    </Popup>
  )
}


export default PopupStatus

import { useQuery, gql, } from '@apollo/client'

const GET_RECORDBOOK = gql`
  query GetRecordbook {
    recordbooks {
      recordbook_number
      namespec
      department
      eform
      elevel
      course
      nameprof
      recordbook_file
      programresults {
        name
        value
      }
    }
  }
`

const RECORD_BOOK_DETAILS = gql`
query GEtRecordbookDetails($recordbook_number: String){
  recordbookBySemester(recordbook_number: $recordbook_number)
}
`

export const useRecordbook = () => {
  return useQuery(GET_RECORDBOOK)
}

export const useRecordbookDetails = ({
  recordbook_number,
}: any) => {
  return useQuery(RECORD_BOOK_DETAILS, {
    variables: {
      recordbook_number,
    },
  })
}

import { Divider, } from '@mui/material'
import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/app/providers/AppProvider'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputFile from '../../../../common/InputFile/InputFile'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import AttachedParticipants from '../../../AttachedParticipants/AttachedParticipants'
import { defineUserGroupe, } from '../../../defineUserGroupe'
import TeacherSearch from '../../../Hooks/TeacherSearch/TeacherSearch'
import { moderGroupe_1_3, } from '../../../defineUserGroupe'
import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'

const Inputs_1_3 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, }: any = useAppContext()


  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)
  const [
    ids,
    setIds,
  ] = useState(
    moderGroupe_1_3.some(moderRole => moderRole === currentRole)
      ? [
        moderateId,
      ]
      : [
        currentUserId,
      ]
  )

  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification(
          'Эффективный контракт',
          ((
            currentRole === 'urvo-moder'
            || currentRole === 'urvo-admin'
          )
            ? [
              moderateId,
            ]
            : [
              currentUserId,
            ]
          ),
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (rec_id: any, newData: any, user_ids: any) => onUpdateRow({
    variables: {
      rec_id: rec_id,
      new_data: newData,
      user_ids: user_ids,
    },
  })


  const [
    isAddMode,
    setIsAddMode,
  ] = useState(false)
  const [
    participants,
    setParticipants,
  ] = useState('')
  const [
    authors,
    setAuthors,
  ] = useState<any>([])

  // const [eventName, setEventName] = useState('')
  // const [timeStart, setTimeStart] = useState('')
  // const [faculty, setFaculty] = useState('')
  // const [department, setDepartment] = useState('')
  // const [userRole, setUserRole] = useState('')
  const [
    confirmingDocument,
    setConfirmingDocument,
  ] = useState([])


  useEffect(() => {
    if (hint === 'edit') {
      setIds(
        (
          currentRole === 'urvo-moder'
          || currentRole === 'urvo-admin'
        )
          ? [
            ...defaultValues.authors.map((author: any) => author.guid).filter((guid: any) => !!guid),
          ]
          : [
            currentUserId,
            ...defaultValues.authors.map((author: any) => author.guid).filter((guid: any) => !!guid && guid !== currentUserId),
          ]
      )
      setAuthors(defaultValues.authors)
      // setEventName(defaultValues.eventName)
      // setTimeStart(format(new Date(defaultValues.timeStart), 'yyyy-MM-dd'))
      // setFaculty(defaultValues.faculty)
      // setDepartment(defaultValues.department)
      // setUserRole(defaultValues.userRole)
      // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setConfirmingDocument(defaultValues.confirmingDocument ? [
        defaultValues.confirmingDocument,
      ] : [])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    setAuthors([])
    // setEventName('')
    // setTimeStart('')
    // setFaculty('')
    // setDepartment('')
    // setUserRole('')
    setConfirmingDocument([])
  }


  // const getCafedral = useGetFacultiesList(
  //   `кафедра ${ department }`,
  //   { offset: 0, limit: 100 },
  // );


  // const [institutesList, setInstitutesList] = useState([]);
  // const getInstitutesQuery = useGetInstitutes();

  // useEffect(() => {
  //   if (getInstitutesQuery?.data) {
  //     setInstitutesList(getInstitutesQuery.data.catalogs.ecInstList)
  //   }
  // }, [
  //   getInstitutesQuery.loading,
  // ]);


  return <>
    <ButtonSimple
      value={ isAddMode ? 'Применить' : 'Добавить / удалить участников' }
      variant="alone"
      onClickHandler={ () => {
        setIsAddMode(!isAddMode)
        setParticipants('')
      } }
    />

    <Divider /><br />

    { isAddMode ? (
      <>
        <TeacherSearch
          defaultHint="Добавьте участников по их ФИО"
          value={ participants }
          onChangeValue={ setParticipants }
          handler={ (answer: any) => {
            if (!ids.find(id => id === answer.guid)) {
              setIds([
                ...ids,
                answer.guid,
              ])
            }

            setAuthors([
              ...authors,
              {
                guid: answer.guid,
                fio: answer.fio,
              },
            ])
          } }
        />

        <br />

        <AttachedParticipants
          // @ts-expect-error TS(2339): Property 'fio' does not exist on type 'never'.
          participantsList={ authors.map(author => author.fio) }
          getName={ (name: any) => {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            const removeId = authors.find(item => item.fio === name).guid

            setIds(ids.filter(item => item !== removeId))

            if (removeId === null) {
              // @ts-expect-error TS(2339): Property 'fio' does not exist on type 'never'.
              setAuthors(authors.filter(item => item.fio !== name))
            } else {
              // @ts-expect-error TS(2339): Property 'guid' does not exist on type 'never'.
              setAuthors(authors.filter(item => item.guid !== removeId))
            }
          } }
        />
      </>
    ) : (
      <>
        <InputTextDeprecated
          defaultHint="ФИО автора (авторов)"
          // @ts-expect-error TS(2339): Property 'fio' does not exist on type 'never'.
          value={ authors.map(author => author.fio).join(', ') }
          disabled
        />


        {/* <InputText
      defaultHint="Название мероприятия"
      value={ eventName }
      onChangeValue={ setEventName }
    />

    <TitleH5>
      Время проведения
    </TitleH5>

    <CalendarSimple
      selectRange={ false }
      withBorder
      styles={ { height: '4.56rem' } }
      defaultSingleDate={
        defaultValues.timeStart
          ? format(new Date(defaultValues.timeStart), 'dd.MM.yyyy')
          : timeStart
            ? format(new Date(timeStart), 'dd.MM.yyyy')
            : false }
      returnSingle={ date => setTimeStart(
        format(new Date(date), 'yyyy-MM-dd')) }
    />

    <InputSelect
      defaultHint="Институт/факультет"
      items={ institutesList }
      defaultValue={ hint === 'edit' ? faculty : '' }
      callBack={ setFaculty }
      withListFixedPosition
    />

    <InputTextWithSearch
      defaultHint="Кафедра"
      value={ department }
      onChangeValue={ setDepartment }
      searchParam={ getCafedral }
      isFilterPopUpPositonFixed
    />

    <InputText
      defaultHint="Роль пользователя"
      value={ userRole }
      onChangeValue={ setUserRole }
    /> */}

        <TitleH5>
          Подтверждающий документ
        </TitleH5>

        { confirmingDocument.length < 1 ? (
          <InputFile
            onChange={ event => {
              // TODO: сделать uploadFile
              // uploadFile(...event.target.files, setConfirmingDocument)
            } }
          />
        ) : false }

        <AttachedFiles
          isDeleteFilesAllowed={ true }
          fileList={ confirmingDocument }
          getIndexFile={ (index: any) => {
            setConfirmingDocument(confirmingDocument.filter((file, fIndex) => fIndex !== index))
          } }
        />

        <br />

        <ButtonSimple
          isDisabled={ (
            authors.length < 1
            // || eventName.trim().length < 1
            // || timeStart.length < 1
            // || faculty.trim().length < 1
            // || department.trim().length < 1
            // || userRole.trim().length < 1
            || confirmingDocument.length < 1
          ) }
          value={ hint === 'edit' ? 'Сохранить изменения' : 'Добавить достижение' }
          variant="full-filled"
          onClickHandler={ () => {
            if (hint === 'edit') {
              onUpdateRowHandler(
                defaultValues.rec_id,
                {
                  authors: authors,
                  // eventName: eventName,
                  // timeStart: format(new Date(timeStart), 'yyyy-MM-dd'),
                  // faculty: faculty,
                  // department: department,
                  // userRole: userRole,
                  confirmingDocument: confirmingDocument[0],
                  status: 'pending',
                  reason: '',
                  scores: null,
                },
                ids
              )

              fieldsToDefault()
              onClickHandler()
            } else {
              onAddNewEcDataHandler({
                variables: {
                  dataType: 'table_1_3',
                  data: {
                    authors: authors,
                    // eventName: eventName,
                    // timeStart: format(new Date(timeStart), 'yyyy-MM-dd'),
                    // faculty: faculty,
                    // department: department,
                    // userRole: userRole,
                    confirmingDocument: confirmingDocument[0],
                    status: 'pending',
                    reason: '',
                    scores: null,
                    isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
                  },
                  user_ids: ids,
                },
              })

              fieldsToDefault()
              onClickHandler()
            };
          } }
        />
      </>) }
  </>
}


export default Inputs_1_3

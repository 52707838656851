import GetReferencePopup from '../GetReferencePopup/GetReferencePopup'
import { IconChevronDown, IconChevronUp, } from '@tabler/icons'
import { useSelector, useDispatch, } from 'react-redux'
import {
  setField,
  setRefAccounData,
  setAuthority,
  setType,
  setRefAmount,
} from '../../../../redux/MfcSlices/MfcSlice'

function ChooseField ({ title, items, value, }: any) {
  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const field = useSelector(state => state.mfc.field)
  const isOpen = field === title

  function openPopup () {
    dispatch(setField(title))
  }

  function onSpecItemClick (i: any) {
    dispatch(setRefAccounData(i))
  }

  function onAuthorityClick (i: any) {
    dispatch(setAuthority(i))
  }

  function onRefTypeClick (i: any) {
    dispatch(setType(i))
  }

  function setAmount (i: any) {
    dispatch(setRefAmount(i))
  }

  return (
    <div className="chooseField" onClick={ openPopup }>
      <GetReferencePopup
        items={ items }
        title={ title }
        isOpen={ isOpen }
        onAuthorityClick={ onAuthorityClick }
        setAmount={ setAmount }
        onSpecItemClick={ onSpecItemClick }
        onRefTypeClick={ onRefTypeClick }
      />
      <div>
        <h4 className="fieldSubtitle">{ title }</h4>
        <div className="fieldValue">{ value }</div>
      </div>
      { !isOpen ? <IconChevronDown /> : <IconChevronUp /> }
    </div>
  )
}

export default ChooseField

import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Название проекта',
    index: 'projectName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Категория сложности',
    index: 'category',
    editable: true,
    editType: 'select',
    selectOptions: [
      'I категория сложности',
      'II категория сложности',
      'III категория сложности',
    ],
  },
  {
    title: 'Роль',
    index: 'userRole',
    editable: true,
    editType: 'select',
    selectOptions: [
      'Руководитель проекта',
      'Исполнитель проекта',
    ],
  },
  {
    title: 'Подтверждающие документы',
    index: 'documents',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: false,
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '8rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    editable: false,
    index: 'number',
  },
  {
    title: 'Название проекта',
    index: 'projectName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Категория сложности',
    index: 'category',
    editable: true,
    editType: 'select',
    selectOptions: [
      'I категория сложности',
      'II категория сложности',
      'III категория сложности',
    ],
  },
  {
    title: 'Роль',
    index: 'userRole',
    editable: true,
    editType: 'select',
    selectOptions: [
      'Руководитель проекта',
      'Исполнитель проекта',
    ],
  },
  {
    title: 'Подтверждающие документы',
    index: 'documents',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Причина отказа',
    index: 'reason',
    editable: false,
    style: { minWidth: '20rem', },
    editType: 'input',
  },
  {
    editable: false,
    title: 'Комментарий',
    index: 'textAppeal',
    editType: 'input',
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
  },
]

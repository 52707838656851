export const enum EcAchivementStatus {
  PENDING = 'pending',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  EXPERTISE = 'expertise',
  MANUFACTURED = 'manufactured',
  CERTIFICATE_ISSUED = 'сertificateIssued',
  ADJUSTMENT = 'adjustment',
}


export const enum EcAppealStatus {
  APPEAL_PENDING = 'appealPending',
  APPEAL_REJECTED = 'appealRejected',
  APPEAL_APPROVED = 'appealApproved',
}

import "./FilterDropDown.less";
import { IconChevronDown, IconChevronUp } from "@tabler/icons";

import { useEffect, useState } from "react";
import clsx from "clsx";

const FilterDropDown = ({
  items = ["empty"],
  defaultHint,
  firstIsSelected = false,
  onClickHandler,
  withBorder = false,
  customWidth = false,
  maxWidthOfinput,
  clearFilter,
  isWithOptionsWrap,
  firstValue,
  field = false,
  title,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(
    firstIsSelected && field ? items[0][field] : items[0]
  );

  useEffect(() => {
    if (defaultHint) {
      setActive(defaultHint);
    } else {
      setActive(field ? items[0][field] : items[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilter, defaultHint]);

  return (
    <div
      style={{
        width: customWidth,
      }}
      className={clsx({
        "filter-drop-down": true,
        "filter-drop-down_with-border": withBorder,
      })}
      onClick={() => setIsOpen(!isOpen)}
    >
      {isOpen && (
        <div className="filter-drop-down__opened-list">
          {items.map((item: any) => (
            <span
              className={clsx({
                "filter-drop-down__option-choose": true,
                "filter-drop-down__option-choose_with-wrap": isWithOptionsWrap,
              })}
              key={field ? item[field] : item}
              onClick={() => {
                setActive(field ? item[field] : item);
                onClickHandler(item, items);
              }}
            >
              {field ? item[field] : item}
            </span>
          ))}
        </div>
      )}
      <div
        style={{
          display: "flex",
          overflow: "hidden",
        }}
      >
        {title && (
          <span
            className={"filter-drop-down__title"}
            style={{ maxWidth: maxWidthOfinput }}
          >
            {`${title}:`}
          </span>
        )}
        <span
          className={"filter-drop-down__value"}
          style={{ maxWidth: maxWidthOfinput }}
        >
          {active || firstValue
            ? firstValue
              ? firstValue
              : active
            : defaultHint}
        </span>
      </div>
      {!isOpen ? <IconChevronDown /> : <IconChevronUp />}
    </div>
  );
};

export default FilterDropDown;

import CalendarSimple from '@/legacy/components/common/CalendarSimple/CalendarSimple'
import SpecsSearch from '@/legacy/components/EffectiveContract/Hooks/SpecsSearch/SpecsSearch'
import ParticipantsInput from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/components/ParticipantsInput/ParticipantsInput'
import { AchievementParticipant, } from '@/legacy/components/EffectiveContract/types/achievementParticipant'
import { InputSelect, InputText, } from '@/shared'
import { Fragment, } from 'react'
import InputFile from '@/legacy/components/common/InputFile/InputFile'
import { format, } from 'date-fns'
import AttachedFiles from '@/legacy/components/common/AttachedFiles/AttachedFiles'
import { TableCellTypeWithValue, UpdateControlledField, } from '@/legacy/components/EffectiveContract/TableContainer/AddNewAchievement/AddNewAchievement'
import isFilesArrayCheck from '@/legacy/Utils/isFilesCheck'
import findAllFiles from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/lib/findAllFiles'
import { ru, } from 'date-fns/locale'


const findDuplicates = (firstArray: File[], secondArray: File[]): boolean => {
  let result = false

  for (let i = 0; i < firstArray.length; i++) {
    if (result) {
      break
    }

    for (let j = 0; j < secondArray.length; j++) {
      if (firstArray[i].name === secondArray[j].name) {
        result = true
        break
      }
    }
  }

  return result
}


interface UseGetEcDataTypeFormInputs {
  controlledFields: TableCellTypeWithValue[]
  onChange: UpdateControlledField
}


const useGetEcDataTypeFormInputs = ({
  controlledFields,
  onChange,
}: UseGetEcDataTypeFormInputs) => {
  return (
    <>
      { controlledFields.map((field, index) => {
        if (field.editType === 'participants') {
          return (
            <ParticipantsInput
              key={ index }
              value={ field.value as AchievementParticipant[] }
              valueIndex={ field.index as string }
              title={ field.title }
              onChangeRowValue={ onChange }
            />
          )
        }


        if (field.editType === 'input') {
          return (
            <InputText
              key={ index }
              value={ field.value as string }
              title={ field.title }
              onChange={ event => onChange(field.index as string, event.target.value) }
            />
          )
        }


        if (field.editType === 'inputNumbers') {
          return (
            <InputText
              key={ index }
              value={ field.value as string }
              title={ field.title }
              type='number'
              onChange={ event => {
                onChange(field.index as string, event.target.value)
              } }
            />
          )
        }


        if (field.editType === 'select') {
          return (
            <InputSelect
              key={ index }
              value={ field.value as string }
              title={ field.title }
              onChange={ event => onChange(field.index as string, event.target.value) }
              options={ field.selectOptions || [] }
            />
          )
        }


        if (field.editType === 'inputWithSpecsSearch') {
          return (
            // TODO: Убрать div обертку, когда будет переписан компонент SpecsSearch
            <div key={ index }>
              <SpecsSearch
                defaultHint={ field.title }
                value={ field.value }
                onChangeValue={ (data: string) => onChange(field.index as string, data) }
                handler={ (answer: string) => onChange(field.index as string, answer) }
                withListPositionAbsolute
                maxHeightList="12rem"
              />
            </div>
          )
        }


        if (field.editType === 'calendar') {
          return (
            <CalendarSimple
              key={ index }
              selectRange={ false }
              withBorder
              styles={ { height: '4.56rem', } }
              defaultSingleDate={
                field.value
                  ? format(new Date(field.value as string), 'dd MMMM yyyy', { locale: ru, })
                  : field.title
              }
              returnSingle={ (date: Date) => onChange(field.index as string, format(new Date(date), 'yyyy-MM-dd')) }
              calendarPositionStatic
              clickableAreaPositioAbsolute
            />
          )
        }


        if (field.editType === 'upload') {
          return (
            <Fragment key={ index }>
              <InputFile
                title={ field.title }
                isMultiple
                onChange={ event => {
                  if (event.target.files && isFilesArrayCheck(field.value)) {
                    const newFileList = Array.from(event.target.files)
                    const oldFileList = field.value.filter(oldFile => !newFileList.some(newFile => newFile.name === oldFile.name))

                    const result = [
                      ...oldFileList,
                      ...newFileList,
                    ]

                    const allFilesInStore = findAllFiles(controlledFields)
                    const duplicates = findDuplicates(allFilesInStore, newFileList)


                    if (!duplicates) {
                      onChange(field.index as string, result)
                    }
                  }
                } }
              />


              { field.value.length > 0 && (
                <AttachedFiles
                  fileList={ field.value as File[] }
                  isDeleteFilesAllowed
                  getDeleteFileInfo={ file => {
                    if (isFilesArrayCheck(field.value)) {
                      const newFileList = field.value.filter(currentFile => currentFile.name !== file.name)

                      onChange(field.index as string, newFileList)
                    }
                  } }
                />
              ) }
            </Fragment>
          )
        }


        return (
          <Fragment key={ index }>unknown input type</Fragment>
        )
      }) }
    </>
  )
}


export default useGetEcDataTypeFormInputs

import { TableCellType, } from '@/legacy/types'
import styles from './TableHeader.module.less'


interface TableHeaderProps {
  headerFields?: [] | TableCellType[]
}


const TableHeader = ({
  headerFields = [],
}: TableHeaderProps): JSX.Element => (
  <thead>
    <tr>
      { headerFields.map((headerField: TableCellType) => (
        <th key={ headerField.title } className={ styles.headerField } style={ headerField?.style }>
          { headerField.title }
        </th>
      )) }
    </tr>
  </thead>
)


export default TableHeader

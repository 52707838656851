/* eslint-disable no-underscore-dangle */
import { gql, useQuery, } from '@apollo/client'
import { useState, useEffect, } from 'react'

type PresignedDownloadUrl = {
  __typename: 'PresignedDownloadUrl';
  downloadUrl: string;
};

type FileNotFoundError = {
  __typename: 'FileNotFoundError';
  message: string;
};

type InvalidArgumentError = {
  __typename: 'InvalidArgumentError';
  message: string;
};

type DownloadResult = PresignedDownloadUrl | FileNotFoundError | InvalidArgumentError;

const GET_DOWNLOAD_URL = gql`
  query GetDownloadUrl($fileId: String!) {
    getPresignedDownloadUrl(fileId: $fileId) {
      __typename
      ... on FileNotFoundError {
        message
      }
      ... on InvalidArgumentError {
        message
      }
      ... on PresignedDownloadUrl {
        downloadUrl
      }
    }
  }
`

const useDownload = (fileId: string | null) => {
  const { data, error, loading, refetch, } = useQuery(GET_DOWNLOAD_URL, {
    variables: { fileId, },
    skip: !fileId, // Skip query if fileId is null
  })

  const [
    downloadUrl,
    setDownloadUrl,
  ] = useState<string | null>(null)
  const [
    errorMessage,
    setErrorMessage,
  ] = useState<string | null>(null)

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message)
      return
    }

    if (data?.getPresignedDownloadUrl) {
      const result: DownloadResult = data.getPresignedDownloadUrl
      switch (result.__typename) {
        case 'PresignedDownloadUrl':
          setDownloadUrl(result.downloadUrl)
          break
        case 'FileNotFoundError':
        case 'InvalidArgumentError':
          setErrorMessage(result.message)
          break
        default:
          setErrorMessage('Unknown error occurred')
      }
    }
  }, [
    data,
    error,
  ])

  return {
    downloadUrl,
    errorMessage,
    loading,
    refetch,
  }
}

export default useDownload

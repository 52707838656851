import React, { useMemo, useState, } from 'react'
import { IconArrowBarToDown, } from '@tabler/icons'
import { Row, Col, } from 'antd'
import './ReferenceTemplate.less'
import { format, } from 'date-fns'
import clsx from 'clsx'
import { URL, } from '@/shared/constants/constants'
import ru from 'date-fns/locale/ru'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { useResendReference, } from '../../../gql/hooks/mfc/useReferences'

const StudentReferenceTemplate = ({ data, }: any) => {
  const { statusName, count, authority, method, created, updated, type, status, doc_id, result, AddInfo, } = data
  const [
    isSecondSend,
    setIsSecondSend,
  ] = useState(false)

  const dateCreate = format(new Date(created), 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(new Date(created), 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(new Date(created), 'dd MMMM yyyy', { locale: ru, }).slice(4)
  const timeCreate = format(new Date(created), 'HH:mm')
  const dateUpdate = format(new Date(updated), 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(new Date(updated), 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(new Date(updated), 'dd MMMM yyyy', { locale: ru, }).slice(4)
  const timeUpdate = format(new Date(updated), 'HH:mm')


  const isReady = status === 'ready'


  function downLoadFile () {
    if (result.file) {
      window.open(`https://${ URL }/api/public/references/${ result.file }`, '_blank', 'toolbar=0,location=0,menubar=0')
    }
  }

  const [
    referenceCommit,
  ] = useResendReference(doc_id)

  const timePast = formatDistanceToNowStrict(new Date(updated), { locale: ru, })


  const generateStatus = () => {
    if (method === 'Забрать в МФЦ оригинал' && isReady) {
      return (timePast.includes('минут') || timePast.includes('секунд')) ? 'new' : 'ready'
    }
    // console.log('status:', status)
    return status
  }

  const generateStatName = () => {
    if (method === 'Забрать в МФЦ оригинал' && isReady) {
      return (timePast.includes('минут') || timePast.includes('секунд')) ? 'Отправлена' : 'Готова'
    } 

    if(statusName ==='Получено'){
      return 'Отправлено в МФЦ'
    }

    return statusName
  }

  const references = useMemo(() => {
    return <div className="reference">
      <div className="titleWrap">
        <h3>{ type }</h3>
        <div className="status">
          { status === 'new' && <>
            <p className="d-red">Отправка не удалась, попробуйте повторить!  </p>
            <button onClick={ () => {
              setIsSecondSend(true)
              referenceCommit()
            } } className="rejected resendMfc" disabled={ isSecondSend }>Повторить</button>
          </> }
          <button onClick={ downLoadFile } disabled={ !isReady } className={ clsx({ 'inactive': (!isReady || !result.file), }) }>Скачать<IconArrowBarToDown className={ clsx({
            'download': (result?.file),
            'inactive': (!isReady || !result.file),
          }) } /></button>
          <p className={ `stuBut ${ generateStatus() }` }>{ generateStatName() }</p>
          <p className="count">Количество:&nbsp;<span className="countSpan">{ count }</span></p>
        </div>
      </div>
      <Row>
        <Col span={ 12 }>
          <h4>Место предоставления</h4>
          <p className="subtitleRef">{ authority }</p>
        </Col>
        <Col span={ 12 }>
          <h4>Способ Получения</h4>
          <p className="subtitleRef">{ method }</p>
        </Col>
      </Row>
      <Row>
        <Col span={ 12 }>
          <h4>Дата и время заказа</h4>
          <p className="subtitleRef">{ `${ timeCreate } . ${ dateCreate }` }</p>
        </Col>
        <Col span={ 12 }>
          <h4>Дата и время установки статуса</h4>
          <p className="subtitleRef">{ updated ? `${ timeUpdate } . ${ dateUpdate }` : '- -' }</p>
        </Col>
      </Row>
      { AddInfo && <Row>
        <Col span={ 12 }>
          <h4>Дополнительная информация</h4>
          <p className="subtitleRef">{ AddInfo }</p>
        </Col>
      </Row> }
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
  ])


  return (references)
}

export default StudentReferenceTemplate

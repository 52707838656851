import { useMemo, } from 'react'
import './InfoCard.less'
import { IconArrowDownLeft, IconArrowUpRight, IconWallet, IconQuestionMark, } from '@tabler/icons'
import clsx from 'clsx'
import { useEducationPaymentsHistory, } from '../../../../../gql/hooks/useFinances'
import { useDormitoryPaymentsHistory, } from '../../../../../gql/hooks/useFinances'
import { format, } from 'date-fns'


function InfoCard ({
  title,
  type,
  activeContract,
  dorDate,
}: any) {
  // const {nearestPayDate, paySum} = activeContract?.paymentSchedule;


  function LastEducation () {
    const sumIcon = type === 'LastPay' ? <IconArrowUpRight color="white" size={ 30 } /> : <IconArrowDownLeft color="white" size={ 30 } />
    const { data, error, loading, } = useEducationPaymentsHistory(activeContract?.contract)

    const info = useMemo(() => {
      if (loading) {
        return <div>.....loading</div>
      }
      if (error) {
        return <div>.......error</div>
      }

      const lastPay = () => {
        try {
          return data?.educationPaymentsHistory?.history.filter((i: any) => i.isCalculation === false)[0]
        } catch (e) {
          return false
        }
      }

      const historyPay = lastPay()

      return (
        <>
          { historyPay ? <div className="jopa">
            <h3>Сумма последнего платежа:</h3>
            <p className="mainSum">{ `${ historyPay.amount } руб.` }</p>
            <h3>{ `Дата последнего платежа: ${ historyPay.period }` }</h3>
          </div> : <p className="mainSum">Информация отсутствует</p> }
          <div className={ clsx('oreal', 'orealGreen', !historyPay && 'orealGrey') }>{ historyPay ? sumIcon : <IconQuestionMark color="#7F8191" size={ 30 } /> }</div>
        </>
      )

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      data,
      error,
      loading,
    ])

    return (info)
  }

  function Debt () {
    const debtIcon = activeContract?.debt === '0' ? <IconArrowUpRight color="white" size={ 30 } /> : <IconArrowDownLeft color="white" size={ 30 } />

    return (
      <>
        <div className="jopa">
          { activeContract?.debt === '0' ? (
            <p className="mainSum">Задолженность отсутствует</p>
          ) : (
            <>
              <h3>
                { activeContract?.debt.startsWith('-') ? `Ваша переплата на ${ format(new Date(activeContract?.refreshDate), 'dd.MM.y') }` : `Ваша задолженность на ${ format(new Date(activeContract?.refreshDate), 'dd.MM.y') }` }
              </h3>

              <p className="mainSum">
                { `${ activeContract?.debt.replace('-', '') } руб` }
              </p>
            </>
          ) }

          { activeContract?.debt.startsWith('-') || activeContract?.debt === '0' ? (
            <p className="plus">
              Спасибо, что оплачиваете услуги вовремя!
            </p>
          ) : (
            <p className={ clsx('penalti') }>
              Оплатите в ближайшее время
              <span className="warn">!</span>
            </p>
          ) }
        </div>

        <div className={ clsx('oreal', activeContract?.debt.startsWith('-') && 'orealGreen', activeContract?.debt === '0' && 'orealGreen') }>{ activeContract?.debt.startsWith('-') ? <IconArrowUpRight color="white" size={ 30 } /> : debtIcon }</div>
      </>
    )
  }

  function Peni () {
    return (
      <>
        <div className="jopa">
          <p className="mainSum">{ activeContract?.penalties === '0' ? 'Неоплаченные пени отсутствуют' : 'У вас есть неоплаченные пени!' }</p>

          { activeContract?.penalties === '0' ? (
            <p className="plus">
              Спасибо, что оплачиваете услуги вовремя!
            </p>
          ) : (
            <p className={ clsx('penalti orange') }>
              Оплатите в ближайшее время
              <span className="warn orangeBack">!</span>
            </p>
          ) }
        </div>
        <div className={ clsx('oreal', activeContract?.penalties !== '0' && 'orealOrange', activeContract?.penalties === '0' && 'orealGreen') }><IconWallet color="white" size={ 30 } /></div>
      </>
    )
  }

  function Dormitory () {
    const debt = type === 'Util' ? activeContract?.utilities.toString() : activeContract?.rental.toString()
    const debtIcon = debt === '0' ? <IconArrowUpRight color="white" size={ 30 } /> : <IconArrowDownLeft color="white" size={ 30 } />
    return (
      <>
        <div className="jopa">
          { debt === '0' ? <p className="mainSum">Задолженность отсутствует</p> : <><h3>{ debt?.startsWith('-') ? `Ваша переплата на ${ dorDate }` : `Ваша задолженность ${ dorDate }` }</h3>
            <p className="mainSum">{ `${ debt?.replace('-', '') } руб` }</p></> }

          { debt?.startsWith('-') || debt === '0' ? (
            <p className="plus">
              Спасибо, что оплачиваете услуги вовремя!
            </p>
          ) : (
            <p className={ clsx('penalti') }>
              Оплатите в ближайшее время
              <span className="warn">!</span>
            </p>
          ) }
        </div>

        <div className={ clsx('oreal', debt?.startsWith('-') && 'orealGreen', debt === '0' && 'orealGreen') }>{ debt?.startsWith('-') ? <IconArrowUpRight color="white" size={ 30 } /> : debtIcon }</div>
      </>
    )
  }

  function LastDormitory () {
    const sumIcon = type === 'LastPay' ? <IconArrowUpRight color="white" size={ 30 } /> : <IconArrowDownLeft color="white" size={ 30 } />
    const { data, error, loading, } = useDormitoryPaymentsHistory(activeContract?.number_contract)

    // console.log('active', data?.dormitoryPayments.reverse()[0])
    const info = useMemo(() => {
      if (loading) {
        return <div>.....loading</div>
      }
      if (error) {
        return <div>.......error</div>
      }


      const lastPay = data?.dormitoryPayments[data?.dormitoryPayments.length - 1]

      return (
        <>
          { lastPay ? <div className="jopa">
            <h3>Сумма последнего платежа:</h3>
            <p className="mainSum">{ `${ lastPay.total } руб.` }</p>
            <h3>{ `Дата последнего платежа: ${ lastPay.payment_date }` }</h3>
          </div> : <p className="mainSum">Информация отсутствует</p> }
          <div className={ clsx('oreal', 'orealGreen', !lastPay && 'orealGrey') }>{ lastPay ? sumIcon : <IconQuestionMark color="#7F8191" size={ 30 } /> }</div>
        </>
      )

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      data,
      error,
      loading,
    ])

    return (info)
  }


  return (
    <div className="mainFinInfo">
      <h2>{ title }</h2>
      <div className="infoGeneral">
        { type === 'LastPay' ? <>{ activeContract?.educationType ? <LastEducation /> : <LastDormitory /> }</> : <></> }
        { type === 'Debt' ? <Debt /> : <></> }
        { type === 'Peni' ? <Peni /> : <></> }
        { type === 'Util' || type === 'Rent' ? <Dormitory /> : <></> }
      </div>
    </div>
  )
}

export default InfoCard

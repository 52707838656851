/* eslint-disable no-console */
import '../NewProject.less'
import TypeAndNameSection from '@/legacy/components/Projects/NewProject/NewProjectForm/TypeAndNameSection/TypeAndName'
import ParticipantsSection from '@/legacy/components/Projects/NewProject/NewProjectForm/ParticipantsSection/ParticipantsSection'
import RequirementsSection from '@/legacy/components/Projects/NewProject/NewProjectForm/RequirementsSection/RequirementsSection'
import SubmissionSection from '@/legacy/components/Projects/NewProject/NewProjectForm/SubmissionSection/SubmissionSection'

const NewProjectForm = (props: any) => {
  const {
    editProject,
    setFile,
    setCurrentField,
    currentField,
    rangeDate,
    setCalendar,
    calendar,
    role,
    onProjectEdit,
    onModerSubmit,
    checkboxValue,
    setCheckboxValue,
    setRangeDate,
    formFields,
    setField,
    setFields,
    disabled,
  } = props

  return (
    <form className="newProjectForm">
      {/* Type and name of the project section */}
      <TypeAndNameSection
        setField={setField}
        setCurrentField={setCurrentField}
        currentField={currentField}
        formFields={formFields}
        setFile={setFile}
        editProject={editProject}
      />

      {/* Participants section */}
      <ParticipantsSection
        setField={setField}
        setFields={setFields}
        setCurrentField={setCurrentField}
        currentField={currentField}
        formFields={formFields}
        role={role}
        checkboxValue={checkboxValue}
        setCheckboxValue={setCheckboxValue}
        rangeDate={rangeDate}
        setRangeDate={setRangeDate}
        calendar={calendar}
        setCalendar={setCalendar}
      />

      {/* Requirements section */}
      <RequirementsSection
        setField={setField}
        setFields={setFields}
        formFields={formFields}
        checkboxValue={checkboxValue}
        currentField={currentField}
        setCurrentField={setCurrentField}
      />

      {/* Submission button */}
      <SubmissionSection
        disabled={disabled}
        editProject={editProject}
        onProjectEdit={onProjectEdit}
        onModerSubmit={onModerSubmit}
      />
    </form>
  )
}

export default NewProjectForm

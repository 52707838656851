import styles from './TableSpinner.module.less'
import { CircularProgress, } from '@mui/material'


const TableSpinner = () => (
  <td>
    <div className={ styles.spinnerWrapper } >
      <CircularProgress size={ 16 } />
    </div>
  </td>
)


export default TableSpinner

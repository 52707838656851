import React from 'react'

const ProfileForm = ({
  component: Component,
  title,
}: any) => {
  return (
    <form className="personalWrap">
      <h3 className="profileSubtitle">{ title }</h3>
      <Component />
    </form>
  )
}

export default ProfileForm

/* eslint-disable no-mixed-operators */
/* eslint-disable no-console */
import { useState, } from 'react'
import useCreateEcDataDeprecated from '../../../../gql/hooks/effectiveContractHooks/useCreateEcDataDeprecated'
import useGetEcAmsThresholdValues from '../../../../gql/hooks/effectiveContractHooks/useGetEcAmsThresholdValues'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'
import useUrlSearch from '../../../../hooks/useUrlSearch'
import useCreateForm from '../../Hooks/useCreateForm/useCreateForm'


const createInitialState = (formFields: any, autoFill: any) => {
  const result = {}
  const autoFillKeys = Object.keys(autoFill)

  for (const value of formFields) {
    if (value.indexDescription === 'autoFill') {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[value.index] = autoFill[autoFillKeys.find(key => key === value.index)]
      continue
    }

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    result[value.index] = ''
  }

  return result
}


const AddNewRecordPopupContent = ({
  formDataType,
  formFields,
  formUserIds,
  onClosePopup,
}: any) => {
  const thresholdValues = useGetEcAmsThresholdValues()
  const department = useUrlSearch('department')
  const [
    formState,
    setFormState,
  ] = useState(createInitialState(formFields.filter((field: any) => field?.editType), { department, }))
  // @ts-expect-error TS(2554): Expected 4 arguments, but got 3.
  const form = useCreateForm(formFields.filter((field: any) => field?.editType), formState, setFormState)
  // TODO: Заменить устаревший метод
  const createData = useCreateEcDataDeprecated()


  return <>
    <h1>Добавить достижение</h1>

    { form.fields }

    <ButtonSimple
      value="Добавить достижение"
      onClickHandler={ () => {
        preproce({
          thresholdValues,
          formDataType,
          formState,
          formUserIds,
        }).then(data => {
          // @ts-ignore
          return createData(data)
        }).catch(error => console.log(error))
        onClosePopup()
      } }
      variant="full-filled"
      isDisabled={ Object.values(formState).some((field: any) => !field || field === '') }
    />
  </>
}


const preproce = ({
  thresholdValues,
  formDataType,
  formState,
  formUserIds,
}: any) => new Promise((resolve, reject) => {
  if (thresholdValues[formDataType]) {
    resolve({
      value: thresholdValues[formDataType].value,
      coefficient: thresholdValues[formDataType].coefficient,
    })
  } else {
    reject(new Error(`Проверь конфиг, для ${ formDataType }`))
  }
  // @ts-expect-error TS(2345): Argument of type '({ value, coefficient }: { value... Remove this comment to see the full error message
}).then(({ value, coefficient, }) => {
  const formulas = {
    table_22_5: {
      calculated_winners: (Number(coefficient) * (Number(formState?.winners) + Number(formState?.winners_olympiad) + Number(formState?.winners_olympiad_all_russia)) / value),
    },
    table_22_8: {
      calculated_projects: ((Number(coefficient?.amount_projects_500_1?.value) * Number(formState?.amount_projects_50_100)) + (Number(coefficient?.amount_projects_50_100?.value) * Number(formState?.amount_projects_100_500)) + (Number(coefficient?.amount_projects_over_1?.value) * Number(formState?.amount_projects_500_1)) + (Number(coefficient?.amount_projects_100_500?.value) * Number(formState?.amount_projects_over_1)) / value),
    },
    table_22_14: {
      calculated_amount_teachers: (Number(coefficient) * Number(formState?.amount_teachers) / value),
    },
  }

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  switch (!!formulas[formDataType]) {
    case true:
      return {
        dataType: formDataType,
        data: {
          ...formState,
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...formulas[formDataType],
        },
        user_ids: formUserIds,
      }

    default:
      return {
        dataType: formDataType,
        data: formState,
        user_ids: formUserIds,
      }
  }
}).catch(error => console.log(error))


export default AddNewRecordPopupContent

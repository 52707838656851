import { gql, } from '@/__generated__/gql'
import { useQuery, } from '@apollo/client'


const BANNER_IMAGES = gql(`
  query SliderImages {
    sliderImages
  }
`)


export const useBanner = () => useQuery(BANNER_IMAGES)

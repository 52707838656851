import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'


function SwitchToggle ({ label, changeHandler, isChecked, }: {
  label?: string,
  changeHandler: () => void, isChecked: boolean
}) {
  return (
    <FormControlLabel
      label={ label ? label : 'Доступно всем' }
      control={ <Switch
        onChange={ changeHandler }
        checked={ isChecked ? isChecked : false }
      /> }
    />
  )
}

export default SwitchToggle

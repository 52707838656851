/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useMemo, useState, } from 'react'
import './ProjectActivityIntroduction.less'
import { NewPageWrapper, } from '../../../common/GeneralElements/GeneralElements'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import { Table, } from 'antd'
import { IconPaperclip, } from '@tabler/icons'
import { useCreateProjectTheme, useGetProjectThemes, useSignOutProjectTheme, useSignUpProjectTheme, } from '../../../../gql/hooks/useMyEducation'
import { EndPoints, } from '@/shared/constants/endpoints'
import { useSelector, } from 'react-redux'
import { Preloader, } from '@/shared'
import { useEffect, } from 'react'
import FilterDropDown from '../../../common/FilterDropDown/FilterDropDown'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { useAppContext, } from '@/app/providers/AppProvider'


const columns = [
  {
    title: 'Выбор',
    width: 81,
    dataIndex: 'choice',
    key: 'choice',
  },
  {
    title: 'Тема проекта',
    width: 559,
    dataIndex: 'theme',
    key: 'theme',
  },
  {
    title: 'Тип проекта',
    width: 559,
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Оставшиеся места',
    width: 559,
    dataIndex: 'placesLeft',
    key: 'placesLeft',
  },
]

const ProjectActivityIntroduction = () => {
  const role = useGetRole()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studInfo = useSelector(state => state.prof.studentInfo[0])

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.myEd.updateProjectThemes)

  const [
    themeType,
    setThemeType,
  ] = useState('все')


  const { data, error, loading, refetch, } = useGetProjectThemes(themeType === 'все' ? {} : { project_type: themeType, })

  useEffect(() => {
    refetch()
  }, [
    refresh,
    refetch,
    themeType,
  ])


  const [
    paProjectThemeSignUp,
  ] = useSignUpProjectTheme()
  const [
    paProjectThemeSignOut,
  ] = useSignOutProjectTheme()

  const { openToast, } = useAppContext()

  const handleSignUp = (id: any) => {
    paProjectThemeSignUp({
      variables: {
        i: {
          theme_id: id,
          student_info: {
            id: currentUserId,
            department_name: studInfo.department_name,
            namespec: studInfo.namespec,
            nameprof: studInfo.nameprof,
            group: studInfo.group,
          },
        },
      },
    })
      .then(() => openToast?.({ message: 'Вы успешно записались на тему', }))
      .catch(e => console.log(e))
  }

  const handleSignOut = (id: any) => {
    paProjectThemeSignOut({
      variables: {
        i: {
          theme_id: id,
          student_id: currentUserId,
        },
      },
    })
      .then(() => openToast?.({ message: 'Вы успешно отписались от темы', }))
      .catch(e => console.log(e))
  }


  const themesTable = useMemo(() => {
    if (error) {
      return <div>..произошла ошибка, попробуйте позже</div>
    }
    if (loading) {
      return <Preloader />
    } else if (data) {
      const locale = {
        emptyText: <div style={ { padding: '30px 0', } }>Нет тем</div>,
      }

      const studentContent = data.paProjectThemes.map((i: any, index: any) => {
        return {
          key: index,
          choice: (i.places_left > 0 && (role === 'student' || role === 'student-rakus')) ? <input type="checkbox" id="how-get" checked={ i.is_signed } onChange={ i.is_signed ? () => handleSignOut(i.theme_id) : () => handleSignUp(i.theme_id) } /> : null,
          theme: i.theme_name,
          placesLeft: i.places_left,
          type: i.project_type,
        }
      })

      return <Table locale={ locale } columns={ columns } dataSource={ studentContent } />
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
    role,
  ])


  const [
    paProjectThemeCreate,
  ] = useCreateProjectTheme()

  const handleThemeCreate = (file: any) => {
    const formData = new FormData()
    formData.append(`projectActivity/themesTable`, file[0])

    fetch(EndPoints.upload, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    }).then(res => {
      return res.json()
    })
      .then(doc => {
        if (doc.code === 500) {
          openToast?.({ message: 'Файл уже существует', })
        } else {
          paProjectThemeCreate({
            variables: {
              i: { upload_id: doc[0].upload_id, },
            },
          })
            .then(res => {
              openToast?.({ message: 'Темы успешно добавлены', })
            })
            .catch(e => console.log(e))
        }
      })
      .catch(e => console.log(e))
  }

  console.log('rrrrrrr', role)
  return <NewPageWrapper className='projects_introduction'>
    <HistoryLinks
      links={
        [
          {
            value: 'Главная',
            path: '/',
          },
          {
            value: 'Высшее образование',
            path: '/high-education',
          },
        ]
      }
      loc="Введение в проектную деятельность"
    />
    <h2 className="learning-pathway__title">Введение в проектную деятельность</h2>
    <div style={ {
      display: 'flex',
      marginBottom: '20px',
    } }>
      <FilterDropDown customWidth="30%" onClickHandler={ setThemeType } items={ [
        'все',
        'предпринимательский',
        'социальный',
        'научный',
        'технологический',
      ] } />
      { role === 'osrv-moder' && <>
        <input style={ { display: 'none', } } type="file" id="projectTheme" onChange={ e => handleThemeCreate(e.target.files) } />
        <label htmlFor="projectTheme" className="attacheBut" style={ {
          background: 'white',
          marginLeft: '15px',
        } }>Добавить тему<IconPaperclip color="#7671DD" /></label>
      </> }
    </div>
    <div className="studentInner">
      { themesTable }
    </div>
  </NewPageWrapper>
}

export default ProjectActivityIntroduction

import { useMemo, } from 'react'
import { Table, } from 'antd'
import { useDormitoryPaymentsHistory, } from '../../../../../../gql/hooks/useFinances'
import './HistoryDormitory.less'
import NoContracts from '../../../NoContracts/NoContracts'


function HistoryDormitory ({
  contract,
}: any) {
  const { data, loading, error, } = useDormitoryPaymentsHistory(contract)

  const dormitoryTable = useMemo(() => {
    if (data) {
      const columns = [
        {
          title: 'Дата',
          dataIndex: 'date',
          key: 'date',
          width: 180,
        },
        {
          title: 'Найм',
          dataIndex: 'naim',
          key: 'naim',
          width: 300,
        },
        {
          title: 'Коммунальные',
          dataIndex: 'utilities',
          key: 'utilities',
          width: 300,
        },
        {
          title: 'Итого',
          dataIndex: 'general',
          key: 'general',
          width: 300,
        },
      ]


      const content = data?.dormitoryPayments.map((i: any) => {
        return {
          key: data?.dormitoryPayments.indexOf(i),
          date: i.payment_date,
          naim: `${ i.rental } руб.`,
          utilities: `${ i.utilities } руб.`,
          general: `${ i.total } руб.`,
        }
      })

      return content?.length > 0 ? <Table pagination={ false } columns={ columns } dataSource={ content?.reverse() } /> : <NoContracts title="Информация отсутствует" />
    } else if (loading) {
      return <div>.....loading</div>
    } else if (error) {
      return <div>....error</div>
    }
  }, [
    data,
    error,
    loading,
  ])


  return (
    <div className="dor">
      { dormitoryTable }
    </div>
  )
}

export default HistoryDormitory

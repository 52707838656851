export enum EcTableName {
  TABLE_1_1 = 'table_1_1',
  TABLE_1_11 = 'table_1_11',
  TABLE_1_12 = 'table_1_12',
  TABLE_1_2 = 'table_1_2',
  TABLE_1_3 = 'table_1_3',
  TABLE_1_4 = 'table_1_4',
  TABLE_1_5 = 'table_1_5',
  TABLE_1_41 = 'table_1_41',
  TABLE_2_1 = 'table_2_1',
  TABLE_2_2 = 'table_2_2',
  TABLE_2_3 = 'table_2_3',
  TABLE_2_39 = 'table_2_39',
  TABLE_2_4 = 'table_2_4',
  TABLE_2_41 = 'table_2_41',
  TABLE_2_5 = 'table_2_5',
  TABLE_2_6 = 'table_2_6',
  TABLE_2_7 = 'table_2_7',
  TABLE_2_79 = 'table_2_79',
  TABLE_2_8 = 'table_2_8',
  TABLE_2_9 = 'table_2_9',
  TABLE_2_10 = 'table_2_10',
  TABLE_3_1 = 'table_3_1',
  TABLE_3_2 = 'table_3_2',
  TABLE_3_3 = 'table_3_3',
  TABLE_3_31 = 'table_3_31',
  TABLE_3_4 = 'table_3_4',
  TABLE_3_5 = 'table_3_5',
  TABLE_3_6 = 'table_3_6',
  TABLE_3_7 = 'table_3_7',
  TABLE_3_8 = 'table_3_8',
  TABLE_3_9 = 'table_3_9',
  TABLE_3_10 = 'table_3_10',
  TABLE_4_1 = 'table_4_1',

  TABLE_21_1 = 'table_21_1',
  TABLE_21_2 = 'table_21_2',
  TABLE_22_1 = 'table_22_1',
  TABLE_22_2 = 'table_22_2',
  TABLE_22_3 = 'table_22_3',
  TABLE_22_4 = 'table_22_4',
  TABLE_22_5 = 'table_22_5',
  TABLE_22_6 = 'table_22_6',
  TABLE_22_7 = 'table_22_7',
  TABLE_22_8 = 'table_22_8',
  TABLE_22_9 = 'table_22_9',
  TABLE_22_10 = 'table_22_10',
  TABLE_22_11 = 'table_22_11',
  TABLE_22_12 = 'table_22_12',
  TABLE_22_13 = 'table_22_13',
  TABLE_22_14 = 'table_22_14',
  TABLE_22_15 = 'table_22_15',
  TABLE_22_16 = 'table_22_16',
  TABLE_22_17 = 'table_22_17',
  TABLE_22_18 = 'table_22_18',

  APPEAL_1_1 = 'appeal_1_1',
  APPEAL_1_11 = 'appeal_1_11',
  APPEAL_1_12 = 'appeal_1_12',
  APPEAL_1_2 = 'appeal_1_2',
  APPEAL_1_3 = 'appeal_1_3',
  APPEAL_1_4 = 'appeal_1_4',
  APPEAL_1_5 = 'appeal_1_5',
  APPEAL_1_41 = 'appeal_1_41',
  APPEAL_2_1 = 'appeal_2_1',
  APPEAL_2_2 = 'appeal_2_2',
  APPEAL_2_3 = 'appeal_2_3',
  APPEAL_2_39 = 'appeal_2_39',
  APPEAL_2_4 = 'appeal_2_4',
  APPEAL_2_41 = 'appeal_2_41',
  APPEAL_2_5 = 'appeal_2_5',
  APPEAL_2_6 = 'appeal_2_6',
  APPEAL_2_7 = 'appeal_2_7',
  APPEAL_2_79 = 'appeal_2_79',
  APPEAL_2_8 = 'appeal_2_8',
  APPEAL_2_9 = 'appeal_2_9',
  APPEAL_2_10 = 'appeal_2_10',
  APPEAL_3_1 = 'appeal_3_1',
  APPEAL_3_2 = 'appeal_3_2',
  APPEAL_3_3 = 'appeal_3_3',
  APPEAL_3_31 = 'appeal_3_31',
  APPEAL_3_4 = 'appeal_3_4',
  APPEAL_3_5 = 'appeal_3_5',
  APPEAL_3_6 = 'appeal_3_6',
  APPEAL_3_7 = 'appeal_3_7',
  APPEAL_3_8 = 'appeal_3_8',
  APPEAL_3_9 = 'appeal_3_9',
  APPEAL_3_10 = 'appeal_3_10',
  APPEAL_4_1 = 'appeal_4_1',

}

import { OnUpdateProjectHandler } from "@/legacy/components/Projects/ProjectPage/InfoProject/components/AddStudentResultInput/AddStudentResultInput";
import useUpload from "@/legacy/hooks/useUpload/useUpload";
import { FileData } from "@/legacy/types";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { IconCircleX, IconFile } from "@tabler/icons";
import clsx from "clsx";
import styles from "./StudentResultItem.module.less";

interface StudentResultItemProps {
  title: string;
  file: FileData[] | null;
  isAddFileMode: boolean;
  isShowDeleteButton: boolean;
  downloadFile: Function;
  onDeleteResult: Function;
  uploadFileCategory: string;
  onUpdateProjectHandler: OnUpdateProjectHandler;
}

const StudentResultItem = ({
  title,
  file,
  isAddFileMode,
  isShowDeleteButton,
  downloadFile,
  onDeleteResult,
  uploadFileCategory,
  onUpdateProjectHandler,
}: StudentResultItemProps) => {
  const {
    InputFile,
    isFileLoading,
    isFilesExists,
    setIsFilesExists,
    onCheckFilesHandler,
    onUploadFileHandler,
  } = useUpload();

  return (
    <>
      <li key={title}>
        <div
          className={clsx(styles.resultFile, {
            [styles.resultFile_withoutFile]: !file,
            [styles.resultFile_withFile]: !!file,
          })}
        >
          <div className={styles.titleWrapper}>
            <IconFile
              className={clsx({
                [styles.fileIcon_pale]: !file,
                [styles.fileIcon_colorful]: !!file,
              })}
            />

            <span className={styles.title}>{title}</span>
          </div>

          {isAddFileMode && !isFileLoading && (
            <label className={clsx(styles.label, styles.label_withoutFile)}>
              <InputFile
                className={styles.inputFile}
                onChange={(event) => {
                  if (event.target.files) {
                    onCheckFilesHandler(
                      event.target.files,
                      uploadFileCategory
                    ).then((isUploadFilesAllowed) => {
                      if (isUploadFilesAllowed && event.target.files) {
                        onUploadFileHandler(
                          event.target.files,
                          uploadFileCategory
                        ).then((files) => onUpdateProjectHandler(files, title));
                      }
                    });
                  }
                }}
              />
              Прикрепить файл
            </label>
          )}

          {isAddFileMode && isFileLoading && (
            <CircularProgress
              disableShrink
              size={24}
              className={styles.loader}
            />
          )}

          {!isAddFileMode && (
            <span
              className={clsx(styles.label, styles.label_withFile)}
              onClick={() => file && downloadFile(file[0])}
            >
              {file?.[0]?.name || false}
            </span>
          )}

          {isShowDeleteButton && !isFileLoading && (
            <IconCircleX
              className={styles.deleteIcon}
              color="#C5C5C5"
              onClick={() =>
                onDeleteResult({
                  title,
                  file,
                })
              }
            />
          )}
        </div>
      </li>

      <Snackbar open={isFilesExists}>
        <Alert onClose={() => setIsFilesExists(undefined)} severity="error">
          Файл с таким именем уже существует!
        </Alert>
      </Snackbar>
    </>
  );
};

export default StudentResultItem;

import React from 'react'

function NotFound() {
  return (
    <div style={{ width: '100%',
      height: '70vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', }}>
      <h1>Данной страницы не существует</h1>
    </div>
  )
}

export default NotFound
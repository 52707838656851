import React from 'react'
import './RelatedDiscipline.less'
import InputTextDeprecated from '../../../../../common/InputTextDeprecated/InputTextDeprecated'


const RelatedDiscipline = ({
  position,
  dis,
  editMode = false,
  newPayload,
  setNewPayload,
}: any) => {
  const { title, semester, } = dis


  const handleUpdateRelated = (e: any, field: any) => {
    setNewPayload({
      ...newPayload,
      file: {
        ...newPayload.file,
        associated: newPayload.file.associated.map((i: any, index: any) => {
          if (position === index) {
            return {
              ...i,
              [field]: e,
            }
          }
          return i
        }),
      },
    })
  }


  return (
    <div className="RelatedDiscipline">
      { editMode ? <InputTextDeprecated
        defaultHint="Название"
        styles={ {
          width: '100%',
          marginRight: '1rem',
        } }
        value={ newPayload?.file?.associated[position]?.title }
        onChangeValue={ (e: any) => handleUpdateRelated(e, 'title') }
      /> : <h2 className="relatedTitle">{ title }</h2> }
      { editMode ? <InputTextDeprecated
        defaultHint="семестр"
        styles={ {
          width: '100%',
          marginRight: '1rem',
        } }
        value={ newPayload?.file?.associated[position]?.semester }
        onChangeValue={ (e: any) => handleUpdateRelated(e, 'semester') }
      /> : <p className="relatedSubtitle">{ `${ semester } семестр` }</p> }
    </div>
  )
}

export default RelatedDiscipline

import { gql, } from '@/__generated__/gql'
import { useQuery, } from '@apollo/client'
import { useEffect, useState, } from 'react'


const STUDENT_DATA_LOOKUP = gql(`
  query StudentDataLookup($input: StudentDataLookupInput!) {
    studentDataLookup(input: $input)
  }
`)

export type SearchResultItem = string;

export interface StudentSearchResultItem {
  full_name: string,
  id: string,
}


/**
 * @typedef { Object } Answer
 * @property { string[] | [] } items
 * @property { void}  setLimit
 * @property { Boolean } hasMore
 */

/**
 * @param { Object } params
 * @param { ('department' | 'basis' | 'eform' | 'elevel' | 'nameprof' | 'namespec' | 'recordbook_guid' | 'recordbook_code' | 'recordbook_number' | 'course' | 'id' | 'status' | 'group' | 'full_name') } params.field
 * @param { (string | null) } params.search
 *
 * @return { Answer }
 */
const useLookupStudentData = ({
  field,
  search = null,
}: any) => {
  const [
    items,
    setItems,
  ] = useState<SearchResultItem[] | StudentSearchResultItem[]>([])
  const [
    limit,
    setLimit,
  ] = useState(10)
  const [
    hasMore,
    setHasMore,
  ] = useState(true)

  const { data, loading, } = useQuery(STUDENT_DATA_LOOKUP, {
    variables: {
      input: {
        filter: {
          field,
          search,
        },
        pagination: {
          limit,
          offset: 0,
        },
      },
    },
  })


  useEffect(() => {
    setLimit(10)
  }, [
    search,
  ])


  useEffect(() => {
    if (!loading && data) {
      const currentData = Object.values(data)[0]
      setItems(currentData.items)

      if (currentData.pagination.total <= limit) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
    }
  }, [
    data,
    limit,
    loading,
  ])


  return {
    items,
    setLimit,
    hasMore,
  }
}


export default useLookupStudentData


/* sandbox example:

  query Lookup {
    studentDataLookup(input:{
      filter:{
        field:group
        search: "03/"
      }
      pagination:{
        limit:10
        offset:0
      }
    })
  }

 */

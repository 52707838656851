import ProfilePopup from './ProfilePopup'
import { profilePopup, } from '@/shared/constants/constants'
import { useDispatch, useSelector, } from 'react-redux'
import { useNavigate, } from 'react-router-dom'
import useRedirect from '@/legacy/hooks/useRedirect'
import { popupClick, } from '@/legacy/redux/HeaderSlices/PopupSlice'
import { clickSubItem, } from '@/legacy/redux/SideBarSlices/openSubMenu'


function ProfilePopupCurrent ({
  anchorEl,
  open,
  closePopup,
}: any) {
  const redirect = useRedirect()

  const dispatch = useDispatch()
  // const userType = useSelector((state) => state.auth.userType);
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const isPopup = useSelector(state => state.head.isProfilePopup)
  const navigate = useNavigate()

  // const popupItems = profilePopup.filter((i) => i.role.includes(userType));


  function signOut () {
    navigate('/logout')
  }
  /*  const signOut = () => {
    const session = localStorage.getItem('sessionId');
    return fetch(`${URL}/endsession?redirect=https://lk.tsutmb.ru`, {
      headers:{
        'x-sessionI': session || '',
        Cookie: `sessionId=${session}`
    },
    })
    .then(res => res.text())
    .then(async (res)=>{
      console.log("Called result",res);
        await client.cache.reset();
        localStorage.clear()
       dispatch(logoff())
    })
    .catch(e => {
      console.log('logour', e)
    })
  }
  */

  // function signOut() {
  //  const newWin = window.open(`https://lk.tsutmb.ru/api/endSession`, '_blank',  'toolbar=0,location=0,menubar=0' )
  /* newWin.onload = function() { */
  // создать div в документе нового окна
  //  var div = newWin.document.createElement('div'),
  //       body = newWin.document.body;

  //  div.innerHTML = 'Добро пожаловать!'
  //   div.style.fontSize = '30px'

  // вставить первым элементом в body нового окна
  //  body.insertBefore(div, body.firstChild);
  //     console.log(body)
  /*   } */
  // const doc = wind.document;
  // const but = doc.getElementsByTagName('body');
  // doc.addEventListener('click', function() { console.log("eeee")})
  // console.log(doc)
  // logout().then((res) => {
  /* const doc = wind.document;
  const but = doc.getElementByClassName('button') */
  /* localStorage.clear();
  dispatch(logoff()); */
  /* const modal = document.createElement('div');
  modal.innerHTML = `<iframe style={{ position: "absolute" }} id="inlineFrameExample"
  title="Inline Frame Example"
  width="1000px"
  height="1000px"
  src="https://lk.tsutmb.ru/api/endSession">
  modal.setAttribute("style", "height:1000px; padding: 0; width: 1000px;  position: absolute");
</iframe>`
document.body.appendChild(modal); */

  /*  window.open(`https://lk.tsutmb.ru/api/logout?redirect=https://lk.tsutmb.ru/api/endSession`, '_blank', 'toolbar=0,location=0,menubar=0') */
  /* const url = new URLSearchParams(history.location.search).delete('sid');
document.location.href=url; */
  /* const url = new URLSearchParams(history.location.search).delete('sid');
document.location.href=url; */
  //  })
  // }

  function redirectToPath (path: any, val: any) {
    redirect(path)
    dispatch(popupClick(false))
    dispatch(clickSubItem(val))
  }

  return (
    <>
      <ProfilePopup isPopup={ isPopup } signOut={ signOut } anchorEl={ anchorEl } closePopup={ closePopup } redirect={ redirectToPath } open={ open } items={ profilePopup } />
    </>
  )
}

export default ProfilePopupCurrent

import { useQuery, } from '@apollo/client'
import { useEffect, useState, } from 'react'
import { GETNEWSMAIN_QUERY, /* GETNEWS_QUERY */ } from '../../../../gql/hooks/useNews'
import './News.less'

// import first from '../../../../assets/news/image 1383.png'
// import { format } from 'date-fns'
import { NEWS_ROUTE, } from '@/shared/constants/routes'
import { useDispatch, } from 'react-redux'
// @ts-expect-error TS(2307): Cannot find module '../../../../assets/images/Defa... Remove this comment to see the full error message
import DefaultNews from '@/shared/assets/images/DefaultNews.jpg'
import useRedirect from '@/legacy/hooks/useRedirect'
import { setCurrentNews, } from '@/legacy/redux/NewsSlice/NewsSlices'

// import arrowB from '../../../../assets/images/Blue-arrow.svg'

const MiniNews = () => {
  const redirect = useRedirect()

  const main = useQuery(GETNEWSMAIN_QUERY(0, 1))
  const another = useQuery(GETNEWSMAIN_QUERY(1, 4))


  const [
    active,
    setActive,
  ] = useState(false)

  const [
    news,
    setNews,
  ] = useState([])
  const [
    mainNews,
    setMainNews,
  ] = useState<any>({})

  useEffect(() => {
    main.data && setMainNews(main.data.newsFeed.items)
    another.data && setNews(another.data.newsFeed.items)
  }, [
    main.loading,
    main.error,
    main.data,
    another.data,
    another.loading,
    another.error,
  ])

  /* if (main.loading || another.loading) {
    return <h1>Загрузка</h1>
  } */

  /* console.log(mainNews[0].id) */

  const dispatch = useDispatch()

  const goToCurrent = (i: any) => {
    dispatch(setCurrentNews(i))
    redirect(`${ NEWS_ROUTE }/${ i }`)
  }


  return (
    <div className="MiniNews">
      <h1 className="d-h1">Последние новости</h1>

      { mainNews[0] && (
        <div className="Main d-row d-space" onMouseOver={ () => setActive(true) } onMouseOut={ () => setActive(false) }>
          <div className="Image">
            <img src={ mainNews[0].urls.imageUrl } alt={ mainNews[0].urls.imageUrl } />
          </div>
          <div className="Info">
            { mainNews[0]
              && <div className="n-date text-date-style">
                { mainNews[0].pub_date }
              </div> }

            <h1 className="title">{ mainNews[0].title }</h1>

            { mainNews[0].details
              && <div
                className="n-desc description"
                style={ { marginBottom: '1rem', } }
                dangerouslySetInnerHTML={ { __html: mainNews[0].details.substring(0, 150), } }></div>
            }

            {/* {mainNews[0].details.length > 150 && "..."} */ }

            <button onClick={ () => goToCurrent(mainNews[0].id) } className={ active ? 'ActiveButton' : 'Button' }>Подробнее</button>
          </div>
        </div>
      )
      }
      <div className="n-blocks">
        { news && news.map((i: any) => <div
          className="n-block d-row d-space d-cursor"
          key={ i.id }
          onClick={ () => goToCurrent(i.id) }
        >
          <img src={ i.urls.imageUrl ? i.urls.imageUrl : DefaultNews } alt={ i.urls.imageUrl } />
          <div className="p-20">
            <div className="n-date text-date-style">{ i.pub_date }</div>
            <div className="title">{ i.title }</div>
          </div>
        </div>
        ) }
      </div>

      <div className="More">
        <button className="Open " onClick={ () => redirect(NEWS_ROUTE) }>Посмотреть все</button>

      </div>

    </div>
  )
}

export default MiniNews

import { useState, useMemo, useEffect, } from 'react'
import './MyApplications.less'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import clsx from 'clsx'
import ApplicationCard from './ApplicationCard/ApplicationCard'
import InitiatePopup from './InitiatePopup/InitiatePopup'
import { useGetAllProjects, useGetProjectApplys, } from '../../../gql/hooks/useProjects'
import { useSelector, useDispatch, } from 'react-redux'
import NoContracts from '../../Finances/PaymentPage/NoContracts/NoContracts'
import { /* useNavigate, */ useLocation, } from 'react-router-dom'
import useRedirect from '@/legacy/hooks/useRedirect'
import { setTeacherTab, } from '@/legacy/redux/utilsSlice'

function MyApplications () {
  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const filter = useSelector(state => state.util.teacherApplyTab)
  const redirect = useRedirect()

  const [
    isPopup,
    setIsPopup,
  ] = useState(false)

  const location = useLocation()

  const filterButtons = [
    // 'Предложенные темы проектов',
    'Проектные заявки',
  ]


  function isActive (i: any) {
    return i === filter
  }

  function AllThemes (): JSX.Element {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const updateStatus = useSelector(state => state.subscriptions.initiates)
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const updateApplys = useSelector(state => state.subscriptions.applyStatus)

    const params = {
      godMode: false,
    }

    const pagination = {
      offset: 0,
      limit: 1000,
    }


    const { refetch, data, error, loading, } = useGetAllProjects(params, pagination)

    const applications = useMemo(() => {
      if (error) {
        return <div>.....errrr</div>
      } else if (loading && !data) {
        return <div>......loading....</div>
      }
      return (
        <ul>
          { data?.paGetAll.items.map((i: any, index: any) => <li key={ index }><ApplicationCard i={ i } historyRef={ location.state.from } /></li>) }
        </ul>
      )
    }, [
      error,
      data,
      loading,
    ])

    useEffect(() => {
      refetch()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      updateStatus,
      updateApplys,
    ])

    return (data?.paGetAll.items.length > 0 ? applications : <NoContracts title="У вас еще нет заявок" />)
  }


  function AllAplys (): JSX.Element {
    const params = {
      godMode: false,
    }

    const pagination = {
      offset: 0,
      limit: 1000,
    }

    const { data, error, loading, } = useGetProjectApplys(params, pagination)


    const applications = useMemo(() => {
      if (error) {
        return <div>.....errrr</div>
      } else if (loading && !data) {
        return <div>......loading....</div>
      }
      return (
        <ul>
          { data?.paListProjects.items.map((i: any, index: any) => <li key={ index }><ApplicationCard i={ i } type="project" historyRef={ location?.state?.from } /></li>) }
        </ul>
      )
    }, [
      error,
      data,
      loading,
    ])


    return (data?.paListProjects.items.length > 0 ? applications : <NoContracts title="У вас еще нет заявок" />)
  }


  return (
    <div className="InnerPage myApplications">
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
      ] } loc="Мои заявки" />
      <div className="studentInner">
        <h2 className="profileTitle">Мои заявки</h2>
        <div className="initiateWrap">
          <div className="filterWrap">
            {
              filterButtons.map(i => {
                return <button className={ clsx({
                  'statusButton': true,
                  'buttonActive': isActive(i),
                }) } onClick={ () => dispatch(setTeacherTab(i)) } key={ filterButtons.indexOf(i) }>{ i }</button>
              })
            }
          </div>
          {/* <button className="statusButton buttonActive" onClick={ () => setIsPopup(true) }>Инициировать проект</button>*/}
          <button className="statusButton" onClick={ () => redirect('/apply-project') }>
            Добавить проектную заявку
          </button>
        </div>
        { filter === 'Проектные заявки' ? <AllAplys /> : <AllThemes /> }
      </div>
      <InitiatePopup isOpen={ isPopup } handlePopup={ () => setIsPopup(false) } />
      {/* <ToastContainer /> */ }
    </div>
  )
}

export default MyApplications

import React from 'react'
import './ColumnInfo.less'

function ColumnInfo ({
  style,
  items,
}: any) {
  return (
    <div className="footerInfo" style={ style }>
      <h2>{ items.title }</h2>
      { items.items.map((i: any) => {
        return <a key={ i.title } href={ i.path }>{ i.title }</a>
      }) }
    </div>
  )
}

export default ColumnInfo

import { useQuery, } from '@apollo/client'
import { useEffect, useMemo, } from 'react'
import { EC_LIST_DATA, } from './useEffectiveContract'
import useGetUserInfo from '@/legacy/hooks/useGetUserInfo'
import useUrlSearch from '@/legacy/hooks/useUrlSearch'
import useCreateEcData from '@/legacy/gql/hooks/effectiveContractHooks/useCreateEcData'
import { EcAchivementStatus, EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { clearAchievementFields, } from '@/legacy/components/EffectiveContract/amsUtils/amsUtils'


const useGetEcListData = (user_id: any, dataType: any, dataTypeUpdated: any, setDataTypeUpdated: any) => {
  const { data, loading, refetch, } = useQuery(EC_LIST_DATA, {
    variables: {
      params: {
        user_id,
        dataType,
      },
    },
  })

  useEffect(() => {
    if (dataType === dataTypeUpdated) {
      refetch()
      setDataTypeUpdated(null)
    }
  }, [
    dataType,
    dataTypeUpdated,
    refetch,
    setDataTypeUpdated,
  ])

  const { userGuid, } = useGetUserInfo()
  const moderateId = useUrlSearch('id')
  const { onCreateEcDataHandler, } = useCreateEcData()

  // Импортируем нужную таблице, присваиваем 0 необходимым свойствам, создаем запись в таблице в базе
  useEffect(() => {
    import(`@/legacy/components/EffectiveContract/constants/tablesFormData/${dataType}`).then(table => {
      const { tableSource, } = table
      const initialFields = {}

      const fields = Object.values(clearAchievementFields(tableSource))
      fields.forEach((field: any) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        initialFields[field.index] = 0
      })

      // Если изначально ничего не пришло, создаем соотв. запись в таблице с начальными данными
      // Пока это работает только для table_22_7/8/4 для теста
      if (data?.ecListData.length <= 0) {
        if (dataType === EcTableName.TABLE_22_7 || dataType === EcTableName.TABLE_22_8
        || dataType === EcTableName.TABLE_22_4) {
          onCreateEcDataHandler({
            dataType,
            user_ids: [
              moderateId || userGuid,
            ],
            newData: {
              reason: '',
              status: EcAchivementStatus.PENDING,
              scores: 0,
              isAddedByModer: true,
              ...initialFields,
            },
          }).then(r => console.log(`Record ${dataType} is created`))
            .catch(err => console.log(err))
        }
      }
    })
  }, [
    data,
    loading,
  ])

  const result = useMemo(() => {
    if (!loading && data) {
      if (data?.ecListData.length > 0) {
        return data?.ecListData
      } return []
    }
  }, [
    data,
    loading,
  ])


  return result
}


export default useGetEcListData

/**
 * Обработчик добавления нового участника достижения в список.
 *
 * @remarks Обработчик используется только для добаление участников имеющих GUID
 *
 * @param participant - Участник который будет добавлен в список (объект, должен иметь guid и строковое представление ФИО)
 * @param currentValueProps - Набор параметров относящихся к списку участников. value - список участников; valueIndex - строковый индекс по которому будет найдена ячейка в таблице, в которую запишется новое значение; onChangeRowValue - функция обновляющая значение в ячейке таблицы.
 */


import { CurrentValueProps, } from '@/legacy/components/EffectiveContract/components/AddNewAchievementPopup/components/ParticipantsInput/types/currentValueProps'
import { AchievementMixedParticipant, AchievementParticipant, } from '@/legacy/components/EffectiveContract/types/achievementParticipant'


const addParticipantsHandler = (
  participant: AchievementParticipant,
  currentValueProps: CurrentValueProps
) => {
  const { value, valueIndex, onChangeRowValue, } = currentValueProps
  const newValue = JSON.parse(JSON.stringify(value))

  const isDiplicate = newValue.some((oldParticipant: AchievementMixedParticipant) => oldParticipant.guid === participant.guid)

  if (!isDiplicate) {
    newValue.push(participant)
    onChangeRowValue(valueIndex, newValue)
  }
}


export default addParticipantsHandler

import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import useUrlSearch from '../../hooks/useUrlSearch'
import AmsUserList from './AmsUserList/AmsUserList'
import AmsAdministratorTemplate from './pages/AmsAdministratorTemplate/AmsAdministratorTemplate'
import AmsDirectorTemplate from './pages/AmsDirectorTemplate/AmsDirectorTemplate'


const AmsEffectiveContract = () => {
  const userRole = useGetRole()

  const moderateId = useUrlSearch('id')


  return <>
    { /^director-/.test(userRole) && (
      <AmsDirectorTemplate userRole={ userRole } />
    ) }


    { /-admin$|^aup-.*-moder$/.test(userRole) && !moderateId && (
      <AmsUserList />
    ) }


    { /-admin$|^aup-.*-moder$/.test(userRole) && !!moderateId && (
      <AmsAdministratorTemplate moderateId={ moderateId } userRole={ userRole } />
    ) }
  </>
}


export default AmsEffectiveContract

export const mfcButtons = [
  {
    name: 'Отправлено',
    serverStat: [
      'new',
      'sent',
    ],
  },
  {
    name: 'В работе',
    serverStat: [
      'process',
    ],
  },
  {
    name: 'Отказано',
    serverStat: [
      'rejected',
    ],
  },
  {
    name: 'Готово',
    serverStat: [
      'ready',
    ],
  },
]

import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { EcSubscriptionContext, } from '@/features'
import useGetTableData from '@/legacy/gql/hooks/effectiveContractHooks/useGetTableData'
import useGetUserInfo from '@/legacy/hooks/useGetUserInfo'
import {
  IconFileInvoice,
  IconMedal,
  IconNews,
  IconShield,
} from '@tabler/icons'
import { useContext, useEffect, useState, } from 'react'
import { useGetScores, } from '../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import CollapseContainer2, { AccordionInnder, } from '../../../common/CollapseContainer2/CollapseContainer2'
import TableContainer from '../../TableContainer/TableContainer'

import {
  tableSource as headerFields_3_1,
} from '../../constants/tablesFormData/table_3_1'
import {
  tableSource as headerFields_3_2,
} from '../../constants/tablesFormData/table_3_2'
import {
  tableSource as headerFields_3_3,
} from '../../constants/tablesFormData/table_3_3'
import {
  tableSource as headerFields_3_4,
} from '../../constants/tablesFormData/table_3_4'


const BonusPointsIndicators = () => {
  const dataTypeUpdated = useContext(EcSubscriptionContext)

  const { userGuid, } = useGetUserInfo()


  const table_3_1 = useGetTableData({
    dataType: EcTableName.TABLE_3_1,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_3_2 = useGetTableData({
    dataType: EcTableName.TABLE_3_2,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_3_3 = useGetTableData({
    dataType: EcTableName.TABLE_3_3,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_3_4 = useGetTableData({
    dataType: EcTableName.TABLE_3_4,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })

  const table_3_5 = useGetTableData({
    dataType: EcTableName.TABLE_3_5,
    user_id: userGuid,
    status: null,
    date: null,
    dataTypeUpdated,
  })


  const getScoresQuery = useGetScores({
    selector: {
      major: 3,
      minor: [
        1,
        2,
        3,
        4,
        5,
      ],
    },
  })

  const [
    scores_3_1,
    setScores_3_1,
  ] = useState(0)
  const [
    scores_3_2,
    setScores_3_2,
  ] = useState(0)
  const [
    scores_3_3,
    setScores_3_3,
  ] = useState(0)
  const [
    scores_3_4,
    setScores_3_4,
  ] = useState(0)
  const [
    scores_3_5,
    setScores_3_5,
  ] = useState(0)

  const setScoresByDefault = () => {
    setScores_3_1(0)
    setScores_3_2(0)
    setScores_3_3(0)
    setScores_3_4(0)
    setScores_3_5(0)
  }

  useEffect(() => {
    if (getScoresQuery.data) {
      if (getScoresQuery.data.ecGetScoresTableBySelector.length === 0) {
        setScoresByDefault()
      }

      getScoresQuery.data.ecGetScoresTableBySelector.forEach((item: any) => {
        if (item.dataType === 'table_3_1') {
          setScores_3_1(item.actualScores)
          // setScores_3_1(item.scores) // баллы с оганичением
        }

        if (item.dataType === 'table_3_2') {
          setScores_3_2(item.actualScores)
          // setScores_3_2(item.scores) // баллы с оганичением
        }

        if (item.dataType === 'table_3_3') {
          setScores_3_3(item.actualScores)
          // setScores_3_3(item.scores) // баллы с оганичением
        }

        if (item.dataType === 'table_3_4') {
          setScores_3_4(item.actualScores)
          // setScores_3_4(item.scores) // баллы с оганичением
        }

        if (item.dataType === 'table_3_5') {
          setScores_3_5(item.actualScores)
          // setScores_3_5(item.scores) // баллы с оганичением
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getScoresQuery.loading,
    getScoresQuery.data,
  ])


  return (
    <>
      <h3 className="effective-contract-add-chievement__subtitle">
        Бонусные баллы
      </h3>

      <CollapseContainer2>

        <AccordionInnder
          Icon={ IconShield }
          headerText="Диссертационная деятельность"
          subHeaderText={ `Баллы: ${ scores_3_1 }` }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_3_1 }
            headerFields={ headerFields_3_1 }
            // @ts-ignore
            ecTableData={ table_3_1 }
          />
        </AccordionInnder>

        <AccordionInnder
          Icon={ IconMedal }
          headerText="Получение степени Phd, признаваемой в России"
          subHeaderText={ `Баллы: ${ scores_3_2 }` }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_3_2 }
            headerFields={ headerFields_3_2 }
            // @ts-ignore
            ecTableData={ table_3_2 }
          />
        </AccordionInnder>

        <AccordionInnder
          Icon={ IconFileInvoice }
          headerText="Экспертная деятельность, членство в жюри, судейство федерального или международного уровней"
          subHeaderText={ `Баллы: ${ scores_3_3 }` }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_3_3 }
            headerFields={ headerFields_3_3 }
            // @ts-ignore
            ecTableData={ table_3_3 }
          />
        </AccordionInnder>

        <AccordionInnder
          Icon={ IconNews }
          headerText="Участие в стратегических, инновационных и иных проектах Университета"
          subHeaderText={ `Баллы: ${ scores_3_4 }` }
        >
          <TableContainer
            dataType={ EcTableName.TABLE_3_4 }
            headerFields={ headerFields_3_4 }
            // @ts-ignore
            ecTableData={ table_3_4 }
          />
        </AccordionInnder>

        {/*<AccordionInnder*/}
        {/*  Icon={ IconAward }*/}
        {/*  headerText="Отдельные достижения, имеющие значимость для Университета"*/}
        {/*  subHeaderText={ `Баллы: ${ scores_3_5 }` }*/}
        {/*>*/}
        {/*  <TableContainer*/}
        {/*    dataType={ EcTableName.TABLE_3_5 }*/}
        {/*    headerFields={ headerFields_3_5 }*/}
        {/*    // @ts-ignore*/}
        {/*    ecTableData={ table_3_5 }*/}
        {/*  />*/}
        {/*</AccordionInnder>*/}

      </CollapseContainer2>
    </>
  )
}


export default BonusPointsIndicators

import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'
import useConfigSubscription from './useConfigSubscription'


const EC_AMS_THRESHOLD_VALUES = gql`
  query UtilConfig($domain: String, $key: String) {
    utilConfig(domain: $domain, key: $key)
  }
`


const useGetEcAmsThresholdValues = () => {
  const { data, loading, refetch, } = useQuery(EC_AMS_THRESHOLD_VALUES, {
    variables: {
      'domain': 'ec-ams',
      'key': 'threshold-values',
    },
  })


  useConfigSubscription({
    condition: {
      domain: 'ec-ams',
      key: 'threshold-values',
    },
    refetch,
  })


  const result = useMemo(() => {
    if (!loading && data) {
      return data.utilConfig.config
    } return {}
  }, [
    data,
    loading,
  ])

  return result
}


export const addThresholdValue = ({
  thresholdValues,
  headerFields,
}: any) => {
  if (thresholdValues) {
    const newHeaderFields = headerFields
    const thresholdKeys = Object.keys(thresholdValues)

    thresholdKeys.forEach(key => newHeaderFields.forEach((item: any) => {
      if (item.index === key) {
        item.thresholdValue = thresholdValues[key]
      }
    }))

    return newHeaderFields
  } return headerFields
}


export default useGetEcAmsThresholdValues

import { IconCircleCheck, IconCircleX, } from '@tabler/icons'
import { useState, } from 'react'
import useGetHandlerForUpdateEcData from '../../../../gql/hooks/effectiveContractHooks/useGetHandlerForUpdateEcData'
import ButtonSimple, { IconComponent, } from '../../../common/ButtonSimple/ButtonSimple'
import TextArea from '../../../common/TextArea/TextArea'
import useCreateForm from '../../Hooks/useCreateForm/useCreateForm'


export const createInitialState = (formFields: any, formData: any) => {
  const result = {}

  if (Array.isArray(formFields)) {
    for (const value of formFields) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[value.index] = formData[value.index]
    }
  }

  return result
}


const ChangeStatusRecordPopupContent = ({
  formRecId,
  formFields,
  formData,
  formUserIds,
  onClosePopup,
}: any) => {
  const [
    rejectAchievement,
    setRejectAchievement,
  ] = useState(false)
  const [
    reason,
    setReason,
  ] = useState('')

  const [
    formState,
    setFormState,
  ] = useState(createInitialState(formFields, formData))
  const form = useCreateForm(formFields, formState, setFormState, true)

  const { onUpdateHandler, } = useGetHandlerForUpdateEcData()


  return (
    <>
      <h1>Проверка достиения</h1>

      { form.fields }


      { rejectAchievement && (
        <>
          <TextArea
            defaultHint='По какой причине вы хотите отклонить заявку?'
            withPlaceholder={ false }
            value={ reason }
            onChangeValue={ setReason }
          />

          <br />

          <div style={ {
            display: 'flex',
            justifyContent: 'flex-end',
          } }>
            <ButtonSimple
              value="Отменить"
              variant="groupe"
              styles={ {
                marginRight: '1rem',
                backgroundColor: '#F5F6FA',
              } }
              onClickHandler={ () => setRejectAchievement(false) }
            />

            <ButtonSimple
              value={ (
                <IconComponent
                  Icon={ IconCircleX }
                  iconStyles={ { color: '#FF5E5E', } }
                  text="Отклонить"
                />
              ) }
              variant="groupe"
              isDisabled={ reason.split(' ').length < 3 }
              onClickHandler={ () => {
                onUpdateHandler({
                  rec_id: formRecId,
                  new_data: {
                    ...formState,
                    status: 'rejected',
                    reason,
                  },
                  user_ids: formUserIds,
                })
                onClosePopup()
              } }
            />
          </div>
        </>
      ) }


      { !rejectAchievement && (
        <div style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }>
          <ButtonSimple
            value={ (
              <IconComponent
                Icon={ IconCircleX }
                iconStyles={ { color: '#FF5E5E', } }
                text="Отклонить"
              />
            ) }
            variant="groupe"
            styles={ { marginRight: '1rem', } }
            onClickHandler={ () => setRejectAchievement(true) }
          />

          <ButtonSimple
            value={ (
              <IconComponent
                Icon={ IconCircleCheck }
                text="Подтвердить"
              />
            ) }
            variant="groupe"
            active
            onClickHandler={ () => {
              onUpdateHandler({
                rec_id: formRecId,
                new_data: {
                  ...formState,
                  status: 'approved',
                },
                user_ids: formUserIds,
              })
              onClosePopup()
            } }
          />
        </div>
      ) }
    </>
  )
}


export default ChangeStatusRecordPopupContent

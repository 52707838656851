/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState, } from 'react'
import Cropper from 'react-easy-crop'
import { useDispatch, } from 'react-redux'
import { selectImg, } from '../../../redux/ProfileSlices/CropSlice/CropSlice'
import './ImgCropDialog.less'
import getCroppedImg from './CropeImage'
import { useDefaultAvatar, useDeleteUpload, } from '../../../gql/hooks/useAvatars'
import { URL, } from '@/shared/constants/constants'
import { EndPoints, } from '@/shared/constants/endpoints'


/* const aspectRatios = [
  { value: 4 / 3, text: 4 / 3 },
  { value: 16 / 9, text: 16 / 9 },
  { value: 1 / 2, text: 1 / 2 }
]; */
/* const dogImg =
  'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000'; */

/* function ImgCropDialog({ id, url, aspectInit, zoomInit, cropInit }) {
  const [upload] = useUploadFormDataMutation();
  const dispatch = useDispatch();
  const zoom = useSelector((state) => state.crop.zoom);
  const crop = useSelector((state) => state.crop.crop);
  const croppedAreaPixels = useSelector((state) => state.crop.croppedAreaPixels); */
/* const aspect = aspectRatios[0]; */

/* function onCropChange(crop) {
  dispatch(setCrop(crop))
}

function onZoomChange(zoom) {
  dispatch(setZoom(zoom))
}

function closeDialog() {
  dispatch(selectImg())
}

function onCropComplete(croppedAreaPixels) {
  dispatch(doCrop(croppedAreaPixels))
}

console.log('cropedArea', croppedAreaPixels) */

const ImgCropDialog = ({
  classes,
  url,
  id,
}: any) => {
  const dispatch = useDispatch()
  const [
    defaultAvatar,
  ] = useDefaultAvatar()
  const [
    deleteUpload,
  ] = useDeleteUpload()
  const [
    crop,
    setCrop,
  ] = useState({
    x: 0,
    y: 0,
  })
  const [
    rotation,
    setRotation,
  ] = useState(0)
  const [
    zoom,
    setZoom,
  ] = useState(1)
  const [
    croppedAreaPixels,
    setCroppedAreaPixels,
  ] = useState(null)
  // const [croppedImage, setCroppedImage] = useState(null)

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  function closeDialog () {
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    dispatch(selectImg())
  }

  const onCrop = useCallback(async () => {
    try {
      const file: any = await getCroppedImg(
        url,
        croppedAreaPixels
      )
      // console.log('donee', {file, croppedImage })
      const formData = new FormData()
      const formFile = new File([
        file,
      ], `newFile${ Math.random() }.jpeg`)
      formData.append('avatars', formFile)
      fetch(EndPoints.upload, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      })
        .then(res => {
          return res.json()
        })
        .then(res => defaultAvatar({
          variables: {
            upload_id: res[0].upload_id,
          },
        }))
        .then(() => closeDialog())
        .catch(e => {
          console.log('ERROR', e)
        })
    } catch (e) {
      console.error('ImageCrop', e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    croppedAreaPixels,
  ])

  function onDelete () {
    deleteUpload({
      variables: {
        upload_ids: [
          id,
        ],
      },
    })
    closeDialog()
  }

  return (
    <div className="redactorWrap">
      <div className="cropContainer">
        <Cropper style={ { cropAreaStyle: { color: 'rgba(255, 255, 255, 0.9)', }, } }
          image={ url }
          crop={ crop }
          rotation={ rotation }
          zoom={ zoom }
          aspect={ 4 / 3 }
          onCropChange={ setCrop }
          onRotationChange={ setRotation }
          onCropComplete={ onCropComplete }
          onZoomChange={ setZoom }
        />
      </div>
      <div className="controls">
        <div className="controlsUp">
          <input className="slider" type="range" min={ 1 } max={ 3 } step={ 0.1 } value={ zoom } onInput={ e => {
            // @ts-expect-error TS(2339): Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
            setZoom(e.target.value)
          } }></input>
          <button className="closeButton" onClick={ closeDialog } type="button">x</button>
          <button onClick={ onCrop } className="save">Сохранить</button>
          <button onClick={ onDelete } className="save">Удалить</button>
        </div>
      </div>
    </div>
  )
}


/* return (
  <div className="redactorWrap">
    <div className="cropContainer">
      <Cropper style={{cropAreaStyle: {color: 'rgba(255, 255, 255, 0.9)'}}} image={dogImg}
      zoom={zoom} crop={crop} onZoomChange={onZoomChange} onCropChange={onCropChange}
      onCropComplete={onCropComplete}/>
    </div>
    <div className="controls">
      <div className="controlsUp">
        <input className="slider" type="range" min={1} max={3} step={0.1} value={zoom} onInput={(e) => {
          onZoomChange(e.target.value)
        }}></input>
        <button className="closeButton" onClick={closeDialog} type="button">x</button>
        <button onClick={onCrop} className="save">Сохранить</button>
      </div>
    </div>
  </div>
);
} */

export default ImgCropDialog

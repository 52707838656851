import React from 'react'
import './RangeField.less'


function RangeField ({
  styles,
  placeholder,
  fromHandler,
  toHandler,
  fromValue,
  toValue,
}: any) {
  return (
    <div className='rangeField' style={ styles }>
      <div className="from">
        <h3>От</h3>
        <div className="fromValue">
          <input
            type="namber"
            placeholder={ placeholder }
            onChange={ fromHandler }
            value={ fromValue }
          />
        </div>
      </div>
      <div className="from to">
        <h3>До</h3>
        <div className="fromValue">
          <input
            type="namber"
            placeholder={ placeholder }
            onChange={ toHandler }
            value={ toValue }
          />
        </div>
      </div>
    </div>
  )
}

export default RangeField

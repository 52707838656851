import { Col, } from 'antd'

function ColRec ({
  space,
  value,
  title,
}: any) {
  return (
    <Col span={ space }>
      <div className="recordCol">
        <h4 className="recColTitle">{ title }</h4>
        <p className="infoValue">{ value ? value : '--' }</p>
      </div>
    </Col>
  )
}

export default ColRec

/* eslint-disable no-console */
import { gql, } from '@apollo/client'
import { useMutation, } from '@apollo/client'

interface FileUploadConfirmation {
  result: string;
}

interface FileNotFoundError {
  message: string;
}

interface InvalidArgumentError {
  message: string;
}

interface FileUploadConfirmationVariables {
  fileId: string;
}

const FILE_UPLOAD_CONFIRMATION = gql(`
  mutation FileUploadConfirmation($fileId: String!) {
    fileUploadConfirmation(fileId: $fileId) {
      ... on FileUploadConfirmation {
        result
      }
      ... on FileNotFoundError {
        message
      }
      ... on InvalidArgumentError {
        message
      }
    }
  }
`)

const useFileUploadConfirmation = () => {
  const [
    confirmFileUpload,
    { loading: confirmationLoading, error: confirmationError, },
  ] = useMutation<FileUploadConfirmation, FileUploadConfirmationVariables>(
    FILE_UPLOAD_CONFIRMATION
  )

  const confirmFileUploadHandler = async (fileId: string) => {
    try {
      const response = await confirmFileUpload({ variables: { fileId, }, })
      console.log('File upload confirmation successful:', response)
    } catch (error: any) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const firstError = error.graphQLErrors[0]

        if (firstError.extensions?.code === 'NOT_FOUND') {
          const fileNotFoundError: FileNotFoundError = {
            message: firstError.message,
          }
          throw fileNotFoundError
        } else if (firstError.extensions?.code === 'INVALID_ARGUMENT') {
          const invalidArgumentError: InvalidArgumentError = {
            message: firstError.message,
          }
          throw invalidArgumentError
        }
      }
    }
  }

  return {
    confirmFileUpload: confirmFileUploadHandler,
    confirmationLoading,
    confirmationError,
  }
}

export default useFileUploadConfirmation

import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  isProfilePopup: false,
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    popupClick: (state, action) => {
      state.isProfilePopup = action.payload
    },
  },
})

export const { popupClick, } = headerSlice.actions
export default headerSlice.reducer

import './InputNumber.less'


const InputNumber = ({
  min = 2,
  max = 1000,
  placeholder = 'ввидите число',
  onChangeHandler,
  styles,
}: any) => {
  return <input style={ styles } className="numberSms" type="number"
    min={ min } max={ max } placeholder={ placeholder }
    onChange={ e => onChangeHandler(e.target.value) } />
}

export default InputNumber

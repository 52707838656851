import { useMemo, } from 'react'
import { useNameSpecListAccepted, } from '../../../gql/hooks/useProjects'
import FilterPopup from '../../Projects/FilterPopup/FilterPopup'

function SpecsListAccepted({
  value,
  course,
  setCurrentField,
  currentField,
  field,
  handler,
  clear,
  workType,
}: any): JSX.Element {
  let filter = {
    status: 'Является студентом',
    elevels: [
      'Магистр',
      'Бакалавр',
      'Специалист',
    ],
    search: value,
    course: course,
    vkr: false,
    coursework: false,

  }
  if (workType === null) {
    filter = {
      ...filter,
      vkr: false,
      coursework: false,
    }
  } else if (workType === 'ВКР') {
    filter = {
      ...filter,
      vkr: true,
      coursework: false,
    }
  } else if (workType === 'Курсовая работа') {
    filter = {
      ...filter,
      vkr: false,
      coursework: true,
    }
  }
  const { data, error, loading, } = useNameSpecListAccepted(filter)

  const specs = useMemo(() => {
    if (error) {
      return <div>.....errrr</div>
    } else if (loading && !data) {
      return <div>......loading....</div>
    }
    return (
      <FilterPopup
        setCurrentField={ setCurrentField }
        isOpen={ field === currentField }
        handler={ handler }
        items={ data.listNameSpecsWithAcceptedStudents }
        clearField={ clear }
      />
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    data,
    loading,
  ])


  return (specs)
}

export default SpecsListAccepted

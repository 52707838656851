import useDeleteNotificationDraft from '../../../../../../gql/hooks/moderateNotifications/useDeleteNotificationDraft'
import ButtonSimple from '../../../../../common/ButtonSimple/ButtonSimple'
import { TitleH3, } from '../../../../../common/GeneralElements/GeneralElements'
import styles from './DeleteDraftPopupContent.module.less'


const DeleteDraftPopupContent = ({
  onClosePopup,
  itemId,
}: any) => {
  const { onDeleteNotificationDraft, } = useDeleteNotificationDraft()


  return (
    <>
      <TitleH3>
        (Удаление черновика)
      </TitleH3>

      <div className={ styles.buttonsWrapper }>
        <ButtonSimple
          active
          variant={ 'groupe' }
          value={ 'Подтвердить' }
          onClickHandler={ () => {
            onDeleteNotificationDraft({ draft_id: itemId, })
              .then(() => onClosePopup(false))
          } }
        />

        <ButtonSimple
          variant={ 'groupe' }
          value={ 'Отклонить' }
          onClickHandler={ () => onClosePopup(false) }
        />
      </div>
    </>
  )
}


export default DeleteDraftPopupContent

import pdfMake from 'pdfmake/build/pdfmake'
import htmlToPdfmake from 'html-to-pdfmake'
import { useCreateHtmlBlank, } from '@/shared/api/hooks/portfolio/useGetHtmlBlank'
import { IconArrowBarToDown, } from '@tabler/icons'
import BookAntiqua from './base64book'
import BookAntiquaBold from './base64bookBold'
import back from '@/legacy/components/Portfolio/ModeratePortfolio/HtmlToPdf/backgroundBase64'
import header from '@/legacy/components/Portfolio/ModeratePortfolio/HtmlToPdf/headerBase64'


const bookAntiquaBase64 = BookAntiqua
const bookAntiquaBase64Bold = BookAntiquaBold

pdfMake.vfs = {
  ...pdfMake.vfs,
  'bookantiqua.ttf': bookAntiquaBase64,
  'bookantiqua_bold.ttf': bookAntiquaBase64Bold,
}

const fonts = {
  BookAntiqua: {
    normal: 'bookantiqua.ttf',
    bold: 'bookantiqua_bold.ttf',
  },
}

pdfMake.fonts = {
  ...pdfMake.fonts,
  ...fonts,
}

const HtmlToPdf = (props:any) => {
  const { data, loading, } = useCreateHtmlBlank(props.id)
  const setMarginsToZero = (content:any) => {
    if (Array.isArray(content)) {
      return content.map(item => {
        if (item.stack) {
          item.stack = setMarginsToZero(item.stack)
        }
        if (item.table) {
          item.table.body = item.table.body.map((row:any) => row.map((cell:any) => {
            if (typeof cell === 'object') {
              return { ...cell,
                margin: [
                  0,
                  0,
                  0,
                  0,
                ], }
            }
            return cell
          }))
        }
        return { ...item,
          margin: [
            0,
            0,
            0,
            0,
          ], }
      })
    }
    return content
  }

  const generatePdf = () => {
    if (!loading) {
      const content = htmlToPdfmake(data.createHTMLBlank)
      const contentWithMargins = setMarginsToZero(content)

      const documentDefinition = {
        content: [
          contentWithMargins,
          {
            layout: 'noBorders',
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 2,
              widths: [
                '*',
                '*',
                '*',
                '*',
              ],
              body: [
                [
                  '',
                  { text: 'Ректор',
                    bold: true,
                    fontSize: 12, },
                  '',
                  { text: 'П.С. Моисеев',
                    bold: true,
                    fontSize: 12,
                    alignment: 'right', },
                ],
                [
                  '',
                  '',
                  '',
                  { text: `*Рейтинг составлен 30.05.${new Date().getFullYear()} г.`,
                    fontSize: 6,
                    color: '#00008B',
                    alignment: 'right', },
                ],
              ],
            },
          },
        ],
        images: {
          // background: {
          //   url: 'https://tsutmb.ru/upload/6u9Oz_Di3t0.jpg',
          // },
          // header: {
          //   url: 'https://tsutmb.ru/upload/header.jpg',
          // },
          background: `data:image/jpeg;base64,${back}`,
          header: `data:image/jpeg;base64,${header}`,

        },
        header: (currentPage:any) => {
          if (currentPage === 1) {
            return {
              image: 'header',
              width: 595,
              height: 65,
              absolutePosition: { x: 0,
                y: 0, },
            }
          }
          return null
        },
        background: [
          {
            image: 'background',
            width: 595,
            height: 842,
            absolutePosition: { x: 0,
              y: 0, },
          },
        ],
        footer: [
          {
            text: `Тамбовский государственный университет имени Г.Р. Державина, ${new Date().getFullYear()} `,
            fontSize: 11,
            absolutePosition: { x: 120,
              y: 10, },
          },
        ],
        defaultStyle: {
          font: 'BookAntiqua',
        },
      }
      pdfMake.createPdf(documentDefinition).download('student.pdf')
    }
  }

  return (
    <>
      <div
        style={ {
          marginBottom: '0',
          marginRight: '2rem',
        } }
        className="showRate"
        onClick={generatePdf}
      >
        Выгрузить бланк
        <IconArrowBarToDown color="#7671DD" size={ 25 } />
      </div>
    </>
  )
}

export default HtmlToPdf

import styles from './DarkLayer.module.less'


const DarkLayer = ({
  ...props
}) => (
  <div
    className={ styles.darkLayer }
    { ...props }
  />
)


export default DarkLayer

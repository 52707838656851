import { IconTrash, } from '@tabler/icons'
import { useContext, } from 'react'
import ButtonSimple, { IconComponent, } from '../../../../../common/ButtonSimple/ButtonSimple'
import { TitleH4, } from '../../../../../common/GeneralElements/GeneralElements'
import InputCheckbox from '../../../../../common/InputCheckbox/InputCheckbox'
import { CreateJsonContext, } from '../../../PortfolioEditIndicator'
import CriteriaInputs from '../CriteriaInputs/CriteriaInputs'
import styles from './SelectPointItem.module.less'


const SelectPointItem = ({
  parentIndex,
  index,
  field,
  fieldType,
  type,
  option,
  scores,
}: any) => {
  const {
    // @ts-expect-error TS(2339): Property 'removeCriteriaElement' does not exist on... Remove this comment to see the full error message
    removeCriteriaElement,
    // @ts-expect-error TS(2339): Property 'onChangeInputValue' does not exist on ty... Remove this comment to see the full error message
    onChangeInputValue,
  } = useContext(CreateJsonContext)


  return <>
    <div className={ styles.titleWrapper }>
      <TitleH4 className={ styles.title }>
        Пункт { index + 1 }
      </TitleH4>

      <ButtonSimple
        variant={ 'alone' }
        value={
          <IconComponent text="Удалить" Icon={ IconTrash } iconStyles={ { color: '#FF5E5E', } } />
        }
        onClickHandler={ () => removeCriteriaElement({
          field,
          index,
          parentIndex,
        }) }
      />
    </div>
    <br />

    <CriteriaInputs
      field={ field }
      fieldType={ fieldType }
      parentIndex={ parentIndex }
      index={ index }
      type={ type }
      name={ option.name }
      scores={ scores }
      onChangeInputValue={ onChangeInputValue }
      option={ option }
    />

    <br />

    <InputCheckbox
      label="Добавить в печатную форму как отдельное достижение"
      checked={ option?.detached }
      onChange={ (event: any, isChecked: any) => {
        onChangeInputValue({
          field,
          fieldType,
          index,
          parentIndex,
          subfield: 'detached',
          newValue: isChecked,
        })
      } }
    />
    <br />
  </>
}


export default SelectPointItem

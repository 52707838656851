import './DefaultPage.less'
import Develop from '../../../shared/assets/images/Depelopment-in-process.png'
import ButtonSimple from '../common/ButtonSimple/ButtonSimple'
import useRedirect from '@/legacy/hooks/useRedirect'


function DefaultPage () {
  const redirect = useRedirect()


  return (
    <div className="defaultPage">
      <img src={ Develop } alt="картинка" />
      <h1>Страница в разработке</h1>
      <h2>Пока что страница находится в разработке, скоро она будет готова!</h2>
      <ButtonSimple active={ true } variant="fill" value="вернуться на главную" onClickHandler={ () => redirect('/') } />
    </div>
  )
}

export default DefaultPage

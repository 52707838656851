/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, } from 'react'
import './Debt.less'
import InfoCard from './InfoCard/InfoCard'
import { IconCreditCard, IconWallet, IconDiamond, } from '@tabler/icons'
import NoContracts from '../../../Finances/PaymentPage/NoContracts/NoContracts'
import EducationCon from './EducationCon'
import DormitoryCon from './DormitoryCon'
import useRedirect from '@/legacy/hooks/useRedirect'


const Debt = () => {
  const redirect = useRedirect()

  const [
    activeContract,
    setActiveContract,
  ] = useState()
  const [
    dor,
    setDor,
  ] = useState(true)
  const [
    ed,
    setEd,
  ] = useState(true)
  const [
    dorActual,
    setDorActual,
  ] = useState(null)

  function AllContracts () {
    return (
      <div className="mainContracts">
        <EducationCon setEd={ setEd } activeContract={ activeContract } setActiveContract={ setActiveContract } />
        <DormitoryCon setDorActual={ setDorActual } setDor={ setDor } activeContract={ activeContract } setActiveContract={ setActiveContract } />
      </div>
    )
  }

  const educationInfo = [
    {
      title: 'Платежи',
      type: 'LastPay',
    },
    {
      title: 'Задолженность и переплата',
      type: 'Debt',
    },
    {
      title: 'Пени',
      type: 'Peni',
    },
  ]

  const dormitoryInfo = [
    {
      title: 'Платежи',
      type: 'LastPay',
    },
    {
      title: 'Услуги найма',
      type: 'Rent',
    },
    {
      title: 'Коммунальные услуги',
      type: 'Util',
    },
  ]


  const educButtons = [
    {
      value: 'Оплатить',
      icon: <IconCreditCard style={ { margin: '0 18px 0 0 ', } } />,
    },
    {
      value: 'Оплатить досрочно',
      icon: <IconWallet style={ { margin: '0 18px 0 0 ', } } />,
    },
    {
      value: 'Взять образовательный кредит',
      icon: <IconDiamond strokeWidth={ 2 } style={ { margin: '0 18px 0 0 ', } } />,
    },
  ]

  const dorButtons = [
    {
      value: 'Оплатить услуги найма',
      icon: <IconCreditCard style={ { margin: '0 18px 0 0 ', } } />,
    },
    {
      value: 'Оплатить коммунальные услуги',
      icon: <IconWallet style={ { margin: '0 18px 0 0 ', } } />,
    },
  ]

  return (
    <div>
      <div className="finTitleWrap">
        <h1 className="d-h1">Оплата и задолженность</h1>
        <button onClick={ () => redirect('/payment') }>Подробнее</button>
      </div>
      { dor || ed ?
        <div className="Debt">
          <AllContracts />
          <div className="Debt__card-wrapper">
            {
              // @ts-expect-error TS(2339): Property 'educationType' does not exist on type 'n... Remove this comment to see the full error message
              activeContract?.educationType ? (
                <>
                  { educationInfo.map(item => (
                    <InfoCard
                      title={ item.title }
                      type={ item.type }
                      activeContract={ activeContract }
                      key={ item.title }
                    />
                  )) }
                </>
              ) : (
                <>
                  { dormitoryInfo.map(item => (
                    <InfoCard
                      title={ item.title }
                      type={ item.type }
                      activeContract={ activeContract }
                      dorDate={ dorActual }
                      key={ item.title }
                    />
                  )) }
                </>
              )
            }
          </div>
        </div> : <NoContracts title="У Вас нет заключенных договоров" /> }
    </div>
  )
}

export default Debt

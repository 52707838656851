export function checkParticipateData(data: any) {
  const participateData = (data?.paListProjects?.items || []).flatMap((item: any) => {
    return (item.data?.responses || [])
      .filter((response: any) => response.approved === 'send' || response.approved === 'approved')
      .map((response: any) => ({
        id: response.id,
        semester: item.data.semester,
      }))
  })
  return participateData
}

export function isNewResponse(me: any, stud: any, responses: any, action: any, studentNamespec:any) {
  const studentsInfo = Object.values(stud).filter(item => typeof item === 'object')
  const index = studentsInfo.findIndex((student:any) => student.namespec === studentNamespec)

  if (index !== -1) {
    const foundStudent = studentsInfo.splice(index, 1)[0]
    studentsInfo.unshift(foundStudent)
  }


  let updated = false
  const newResponces = responses.map((i: any) => {
    if (i.id === me.data.guid && i.approved !== 'rejected') {
      updated = true
      return {
        ...i,
        approved: action === 'send' ? 'send' : 'rejected',
        created: new Date(Date.now()),
      }
    }
    return i
  })
  if (!updated) {
    newResponces.unshift({
      name: me.data.full_name,
      id: me.data.guid,
      birth: me.data.date_of_birth,
      spec_info: studentsInfo || stud,
      approved: action === 'send' ? 'send' : 'rejected',
      created: new Date(Date.now()),
    })
  }
  const nullApprovedCount = newResponces.filter((i: any) => i.approved === null && i.id === me.data.guid).length
  if (nullApprovedCount > 1) {
    const nullApprovedIndexes = newResponces.map((i: any, index: any) => (i.approved === null ? index : -1))
      .filter((index: any) => index !== -1)
    nullApprovedIndexes.slice(1).forEach((index: any) => {
      newResponces.splice(index, 1)
    })
  }
  return newResponces
}

import { useQuery, /* useLazyQuery, */ gql } from "@apollo/client";

export const GETUSERGROUPS_QUERY = gql`
  query References {
    getUserGroups
  }
`;

// const GET_PLAIN_TIMETABLE = gql`
//   query GetPlainTimeTable($group: String, $date: String) {
//     plainTimetableStudent(group: $group, date: $date) {
//       group
//       date
//       dayName
//       day
//       monthName
//       dayShort
//       week
//       dow
//       couple
//       start
//       end
//       room
//       discipline
//       period
//       type
//       teacher
//       teachers
//     }
//   }
// `;

// const GET_WEEK_TIMETABLE = gql`
//   query GetWeekTimeTable($group: String, $date: String) {
//     weekTimetableStudent(group: $group, date: $date) {
//       group
//       day
//       dayName
//       monthName
//       subjects
//     }
//   }
// `;

// const GET_CURRENT_TIMETABLE = gql`
//   query GetCurrentTimeTable($date: String, $limit: Int) {
//     currentTimetableStudent(date: $date, limit: $limit)
//   }
// `;

/*
const GET_TIMETABLE_RANGE = gql`
  query GetTimetableRange($dateStart: String, $dateEnd: String) {
    studentTimetableRange(dateStart: $dateStart, dateEnd: $dateEnd)
  }
`;

const GET_TIMETABLE_RANGE_COUNT = gql`
  query GetTimetableRange($dateStart: String, $dateEnd: String) {
    studentTimetableRangeCount(dateStart: $dateStart, dateEnd: $dateEnd)
  }
`;
*/

/*
export const usePlainTimetableStudent = (args: any) => {
  const { selDate, selGroup } = useSelector((state: any) => state.timetable);
  const res = useQuery(GET_PLAIN_TIMETABLE, {
    variables: {
      group: args?.group || selGroup,
      date: args?.date || selDate,
    },
  });
  return res;
};

export const useWeekTimetableStudent = () => {
  const { selDate, selGroup } = useSelector((state: any) => state.timetable);
  const res = useQuery(GET_WEEK_TIMETABLE, {
    variables: {
      group: selGroup,
      date: selDate,
    },
  });
  return res;
};

export const useCurrentTimetableStudent = (args: any) => {
  const res = useQuery(GET_CURRENT_TIMETABLE, {
    variables: {
      date: args?.date || 'now()',
    },
  });
  return res;
};

export const useLazyTimetableDate = () => {
  const res = useQuery(GET_CURRENT_TIMETABLE);
  return res;
};

export const useTimetableDateStudent = (args: any) => {
  const res = useLazyQuery(GET_CURRENT_TIMETABLE, {
    variables: {
      date: args?.date || 'now()',
      limit: 100,
    },
  });
  return res;
};
*/
const TIMETABLE_WITH_TIME = gql`
  query TimetableWithTime($dateNow: String, $limit: Int) {
    studentTimetableWithTime(dateNow: $dateNow, limit: $limit)
  }
`;

export const GET_TIMETABLE = gql`
  query StudentTimetable(
    $dateStart: String
    $dateEnd: String
    $groups: [String]
  ) {
    studentTimetable(
      dateStart: $dateStart
      dateEnd: $dateEnd
      groups: $groups
      limitOrCountOrAll: 1000
    )
  }
`;

export const GET_TEACHER_TIMETABLE = gql`
  query TeacherTimetable($dateStart: String, $dateEnd: String, $teacher_guid: String) {
    teacherTimetable(
      dateStart: $dateStart
      dateEnd: $dateEnd
      teacher_guid: $teacher_guid
    )
  }
`
const GET_TIMETABLE_COUNT = gql`
  query StudentTimetableCount($dateStart: String, $dateEnd: String) {
    studentTimetable(
      dateStart: $dateStart
      dateEnd: $dateEnd
      limitOrCountOrAll: -1
    )
  }
`;

const GET_YEARS = gql`
  query StudentTimetableYears {
    studentTimetableYears
  }
`;

/* export const useStudentTimetableWithTime = (args: any) => {
  const res = useQuery(TIMETABLE_WITH_TIME, {
    variables: {
      dateNow: args?.dateNow || dayjs().format('YYYY-MM-DD HH:mm'),
      limit: 2,
    },
  })
  return res
} */

export const useStudentTimetable = (args: any) => {
  const res = useQuery(GET_TIMETABLE, {
    variables: {
      dateStart: args?.dateStart || "now()",
      dateEnd: args?.dateEnd || "now()",
      groups: [args?.group],
    },
  });
  return res;
};

export const useStudentTimetableCount = (args: any) => {
  const res = useQuery(GET_TIMETABLE_COUNT, {
    variables: {
      dateStart: args?.dateStart || "now()",
      dateEnd: args?.dateEnd || "now()",
    },
  });
  return res;
};

export const useTeacherTimetable = (args: any) => {
  const res = useQuery(GET_TEACHER_TIMETABLE, {
    variables: {
      dateStart: args?.dateStart || 'now()',
      dateEnd: args?.dateEnd || 'now()',
      teacher_guid: args?.teacher_guid || null,
    },
  })
  return res
}

export const useStudentTimetableYears = () => useQuery(GET_YEARS);

import { gql, useSubscription, } from '@apollo/client'
import { useEffect, } from 'react'


const PORTFOLIO_ACHIEVEMENT_TYPE_CHANGED = gql`
  subscription AchievementTypeChanged {
    achievementTypeChanged
  }
`


const useAchievementTypeChanged = (refetch: any) => {
  const { loading, data, } = useSubscription(PORTFOLIO_ACHIEVEMENT_TYPE_CHANGED)

  useEffect(() => {
    if (!loading && data?.achievementTypeChanged && refetch) {
      refetch()
    }
  }, [
    loading,
    data?.achievementTypeChanged,
    refetch,
  ])
}


export default useAchievementTypeChanged

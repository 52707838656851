import React from 'react'
import clsx from 'clsx'


const TabButtons = ({
  items,
  isActive,
  clickHandler,
}: any) => {
  return (
    <ul className="tabButtons">
      { items.map((i: any) => {
        return <button className={ clsx({
          'statusButton': true,
          'buttonActive': isActive(i),
        }) } onClick={ (() => clickHandler(i)) } key={ i.serverStat }>{ i.name }</button>
      }) }
    </ul>
  )
}

export default TabButtons

import FileDownloader from '@/features/project-activity/DownloadExportedStudents/ui/FileDownloaderStudents'
import { IconArrowBarToDown, } from '@tabler/icons'
import React, { useState, } from 'react'


const StudentsDistribution = () => {
  const [
    showFileDownloader,
    setShowFileDownloader,
  ] = useState(false)

  const handleButtonClick = () => {
    setShowFileDownloader(true)
  }
  return (
    <>
      {showFileDownloader && <FileDownloader/>}
      <div
        style={{ marginBottom: '0',
          marginRight: '2rem', }}
        className="showRate"
        onClick={handleButtonClick}
      >
    Выгрузить распредление студентов
        <IconArrowBarToDown color="#7671DD" size={25} />
      </div>
    </>
  )
}

export default StudentsDistribution

import './FilterButtons.less'
import clsx from 'clsx'

function FilterButtons ({
  items,
  status,
  onClick,
}: any): JSX.Element {
  function checkActive (i: any) {
    return i === status
  }

  return (
    <div className="filterButtons">
      { items.map((i: any) => {
        return <button key={ i } onClick={ () => onClick(i) } className={ clsx({ 'activeBut': checkActive(i), }) }>{ i }</button>
      }) }
    </div>
  )
}

export default FilterButtons

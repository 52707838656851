import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { TitleH3, } from '../../../../common/GeneralElements/GeneralElements'
import InputTextDeprecated, { useInputText, } from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import PaperContainerWrapper from '../../../../common/PaperContainer/PaperContainerWrapper'
import styles from './CreateNewItemBlock.module.less'


const CreateNewItemBlock = ({
  onCreateNewItem,
}: any) => {
  const newItemInput = useInputText({ initialValue: '', })

  return (
    <PaperContainerWrapper>
      <TitleH3>
        Создание нового раздела
      </TitleH3>

      <div className={ styles.inputsWrapper }>
        <InputTextDeprecated
          className={ styles.input }
          { ...newItemInput }
        />
        <ButtonSimple
          value={ 'Создать раздел' }
          variant={ 'alone' }
          active
          onClickHandler={ () => onCreateNewItem(newItemInput.value).then(() => newItemInput.onChangeValue('')) }
        />
      </div>
    </PaperContainerWrapper>
  )
}


export default CreateNewItemBlock

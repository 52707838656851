import './PopupContentReject.less'

import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'


export const PopupContentReject = ({
  title,
  reason,
  onClickHandler,
  type = 'achievement',
}: any) => (
  <>
    <h2 className="popup-content-reject__title" > Причина отказа</h2>

    {
      type === 'achievement' && (
        <p className="popup-content-reject__text-reason" >
          Вам отказано по причине:
        </p>
      )
    }

    <strong className="popup-content-reject__desription-reason" >
      { reason }
    </strong>

    <div>
      <ButtonSimple
        value="Понятно"
        variant="full-filled"
        onClickHandler={ onClickHandler }
      />
    </div>
  </>
)


export default PopupContentReject

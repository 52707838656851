import { Fragment, useContext, } from 'react'
import CriteriaItem from '../CriteriaItem/CriteriaItem'
import { CreateJsonContext, } from '../PortfolioEditIndicator'

const Criteries = () => {
  const {
    // @ts-expect-error TS(2339): Property 'jsondata' does not exist on type 'never[... Remove this comment to see the full error message
    jsondata,
    // @ts-expect-error TS(2339): Property 'removeCriteria' does not exist on type '... Remove this comment to see the full error message
    removeCriteria,
    // @ts-expect-error TS(2339): Property 'removeCriteriaElement' does not exist on... Remove this comment to see the full error message
    removeCriteriaElement,
  } = useContext(CreateJsonContext)


  return <>
    {
      jsondata?.map((data: any, index: any) => {
        return (
          <Fragment key={ index } >
            <CriteriaItem
              removeCriteria={ removeCriteria }
              removeCriteriaElement={ removeCriteriaElement }
              { ...data }
            />
            <br />
          </Fragment>
        )
      })
    }
  </>
}


export default Criteries

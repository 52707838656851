import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    editable: false,
    title: 'Наименование публикации',
    index: [
      'Наименование публикации (на языке оригинала)',
      'Название статьи',
      'Выходные данные',
      'Название',
    ],
    style: { minWidth: '20rem', },
  },
  {
    editable: false,
    title: 'ФИО авторов',
    subIndex: 'names',
    index: [
      'ФИО авторов (сотрудники ТГУ)',
      'ФИО авторов (из других организаций)',
    ],
  },
  {
    editable: false,
    title: 'Количество авторов',
    index: [
      'Количество авторов',
      'авторство',
      'Авторство',
      'Роль',
    ],
  },
  {
    editable: false,
    title: 'Название издания',
    index: [
      'Название издания',
      'Название журнала',
      'База',
      'Вид издания',
    ],
  },
  {
    editable: false,
    title: 'Том, номер, выпуск',
    index: [
      'Том, номер, выпуск',
      'Номер',
    ],
  },
  // {
  //   title: 'Баллы',
  //   index: 'userpoints',
  // },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '8rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    editable: false,
    title: 'Наименование публикации',
    index: [
      'Наименование публикации (на языке оригинала)',
      'Название статьи',
      'Выходные данные',
      'Название',
    ],
    style: { minWidth: '20rem', },
  },
  {
    editable: false,
    title: 'ФИО авторов',
    subIndex: 'names',
    index: [
      'ФИО авторов (сотрудники ТГУ)',
      'ФИО авторов (из других организаций)',
    ],
  },
  {
    editable: false,
    title: 'Количество авторов',
    index: [
      'Количество авторов',
      'авторство',
      'Авторство',
      'Роль',
    ],
  },
  {
    editable: false,
    title: 'Название издания',
    index: [
      'Название издания',
      'Название журнала',
      'База',
      'Вид издания',
    ],
  },
  {
    editable: false,
    title: 'Том, номер, выпуск',
    index: [
      'Том, номер, выпуск',
      'Номер',
    ],
  },
  // {
  //   title: 'Баллы',
  //   index: 'userpoints',
  // },
  {
    editable: false,
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
  },
  {
    title: 'Комментарий',
    editable: false,
    index: 'textAppeal',
  },
  {
    title: 'Статус',
    editable: false,
    index: 'status',
    style: { minWidth: '10rem', },
  },
]

import { useEffect, useMemo, } from 'react'
import { useDormitoryContracts, } from '../../../../gql/hooks/useFinances'
import ContractCard from './ContractCard/ContractCard'

function DormitoryCon ({
  setDor,
  setDorActual,
  activeContract,
  setActiveContract,
}: any) {
  const { data, error, loading, } = useDormitoryContracts()

  useEffect(() => {
    if (data) {
      !activeContract && setActiveContract(data?.dormitoryContracts.contracts[0])
      setDor(data?.dormitoryContracts.contracts.length)
      setDorActual(data?.dormitoryContracts.results_date)
    }
  }, [
    data,
    error,
    loading,
    setDor,
    setDorActual,
    activeContract,
    setActiveContract,
  ])

  const dormitory = useMemo(() => {
    if (data) {
      return data?.dormitoryContracts.contracts.map((i: any) => <ContractCard key={ i.number_contract } onClick={ () => setActiveContract(i) } contr={ i } activeContract={ activeContract } />)
    } else if (error) {
      return <div>.....error</div>
    } else if (loading) {
      return <div>......loading</div>
    }
  }, [
    data,
    error,
    loading,
    activeContract,
    setActiveContract,
  ])

  return dormitory
}

export default DormitoryCon

import React from 'react'

import { Routes, Route, } from 'react-router-dom'
import { AuthRoutes, } from './routes'
import ProtectedRoute from '@/legacy/components/ProtectedRoute/ProtectedRoute'

const AppRouter = () => {
  return (
    <Routes>
      { AuthRoutes.map(({ path, Component, roles, }) => <Route key={ path } path={ path } element={
        <ProtectedRoute key={ path } path={ path } element={ Component } roles={ roles } exact />
      } />
      ) }
    </Routes>
  )
}

export default AppRouter

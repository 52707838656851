import { useEffect, useState, } from 'react'
import useAchievementDirections from '../../../../gql/hooks/portfolio/useAchievementDirections'
import { TitleH3, } from '../../../common/GeneralElements/GeneralElements'
import InputCheckbox, { useInputCheckbox, } from '../../../common/InputCheckbox/InputCheckbox'
import InputSelectDeprecated from '../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputTextDeprecated, { useInputText, } from '../../../common/InputTextDeprecated/InputTextDeprecated'
import PaperContainerWrapper from '../../../common/PaperContainer/PaperContainerWrapper'
import useUrlSearch from '../../../../hooks/useUrlSearch'
import styles from './MainInfo.module.less'


const MainInfo = ({
  achievementType,
  setMainInfo,
}: any) => {
  const isNeedConfirmCheckbox = useInputCheckbox({
    initialValue: achievementType?.need_confirm,
    isLazyData: true,
  })
  const isDetachedCheckbox = useInputCheckbox({
    initialValue: achievementType?.detached,
    isLazyData: true,
  })

  const searchCatid = useUrlSearch('catid')
  // @ts-ignore: Unreachable code error
  const { directions, } = useAchievementDirections()

  const indicatorInput = useInputText({
    initialValue: achievementType?.name,
    isLazyData: true,
  })
  const [
    directionSelect,
    setDirectionSelect,
  ] = useState(searchCatid)


  useEffect(() => {
    setMainInfo({
      direction_key: directionSelect,
      name: indicatorInput.value,
      need_confirm: isNeedConfirmCheckbox.checked,
      detached: isDetachedCheckbox.checked,
    })
  }, [
    setMainInfo,
    directionSelect,
    indicatorInput.value,
    isNeedConfirmCheckbox.checked,
    isDetachedCheckbox.checked,
  ])

  return (
    <PaperContainerWrapper>
      <TitleH3>
        Основная информация
      </TitleH3>
      <InputTextDeprecated
        defaultHint={ 'Показатель' }
        withPlaceholder={ true }
        { ...indicatorInput }
      />
      <br />

      <InputsWrapper>
        <InputSelectDeprecated
          defaultHint={ 'Раздел' }
          defaultValue={ directions?.find((direction: any) => direction?.key === Number(searchCatid))?.name }
          items={ directions?.map((direction: any) => direction?.name) }
          callBack={ (value: any) => setDirectionSelect(directions.find((direction: any) => direction.name === value).key) }
        />
      </InputsWrapper>
      <br />

      <InputCheckbox label="Достижение требует подтверждения" { ...isNeedConfirmCheckbox } />
      <InputCheckbox label="Добавить в печатную форму как отдельное достижение" { ...isDetachedCheckbox } />
    </PaperContainerWrapper>
  )
}


export const InputsWrapper = ({
  children,
}: any) => (
  <div className={ styles.inputsWrapper }>
    { children }
  </div>
)


export default MainInfo

import React, { useEffect, useState, } from 'react'
import { useGetExportedProjectId, } from '@/features/project-activity/api/hooks/useGetProjectsExport'
import useDownload from '@/shared/api/hooks/useDownload/useDownload'
import TableSpinner from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/TableSpinner/TableSpinner'

const FileDownloader = () => {
  const { data: projectData, loading: projectLoading, error: projectError, } = useGetExportedProjectId()
  const [
    fileId,
    setFiledId,
  ] = useState<string | null>(null)

  const { downloadUrl, errorMessage, loading: downloadLoading, } = useDownload(fileId)

  useEffect(() => {
    if (projectData?.createExcelProjects.fileId) {
      setFiledId(projectData.createExcelProjects.fileId)
    }
  }, [
    projectData,
  ])

  useEffect(() => {
    if (downloadUrl) {
      window.location.href = downloadUrl
    }
  }, [
    downloadUrl,
  ])

  return (
    <>
      {(projectLoading || downloadLoading) && <TableSpinner />}
      {projectError && <p>Error loading project ID: {projectError.message}</p>}
      {errorMessage && <p>Error generating download URL: {errorMessage}</p>}
    </>
  )
}

export default FileDownloader

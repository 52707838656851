/**
 * Компонент для вывода списка элементов какой либо сущности (например для соединения с полем ввода текста).
 * Каждый элемент имеет кнопку удаления из списка элементов.
 *
 * @param items - Список элементов, каждый элемент должен иметь строковое содержание и уникальный id
 * @param getRemoveItemInfo - Функция возвращающая элемент списка который должен быть удален из списка и его индекс для дальнейшей обработки внешней функцией.
 */


import { IconCircleX, } from '@tabler/icons'
import styles from './ChipsBox.module.less'


interface Item {
  id: string | number
  title: string
}

interface ItemInfo {
  itemTitle: string
  itemIndex: number
  itemId: string | number
}

interface ChipsBoxProps {
  items: Item[]
  getRemoveItemInfo: (answer: ItemInfo) => void
}


const ChipsBox = ({
  items,
  getRemoveItemInfo,
}: ChipsBoxProps) => {
  return (
    <div className={ styles.chipsContainer }>
      {
        items.map((item, itemIndex: number) => {
          const itemInfo: ItemInfo = {
            itemTitle: item.title,
            itemIndex,
            itemId: item.id,
          }


          return (
            <span className={ styles.tag } key={ item.id } >
              { item.title }

              <IconCircleX
                className={ styles.deleteButton }
                onClick={ () => getRemoveItemInfo(itemInfo) }
              />
            </span>
          )
        })
      }
    </div>
  )
}


export default ChipsBox

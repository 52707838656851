import { TitleH3, } from '../../../../common/GeneralElements/GeneralElements'
import ButtonSimple, { IconComponent, } from '../../../../common/ButtonSimple/ButtonSimple'
import { IconCircleX, } from '@tabler/icons'
import { useEffect, useState, } from 'react'
import { useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import { table_cat_1_11, } from '../../../popUpInputsCatalogs'


const PopUpContent_1_1 = ({
  data,
  onClosePopUp,
}: any) => {
  const [
    reason,
    setReason,
  ] = useState('')

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (oldData: any, reason = '') => onUpdateRow({
    variables: {
      rec_id: oldData.rec_id,
      new_data: {
        ...oldData,
        reason: reason,
        status: 'rejected',
      },
    },
  })


  useEffect(() => {
    setReason('')
  }, [
    data,
  ])


  return (
    <>
      <TitleH3>
        Moodle
      </TitleH3>
      <br />

      <InputSelectDeprecated
        defaultHint="По какой причине вы хотите отклонить заявку?"
        items={ table_cat_1_11.reason }
        defaultValue={ reason }
        withResetValue={ true }
        callBack={ setReason }
      />

      <br /><br /><br /><br />

      <div
        style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }
      >
        <ButtonSimple
          value={ (
            <IconComponent
              Icon={ IconCircleX }
              iconStyles={ { color: '#FF5E5E', } }
              text="Отклонить"
            />
          ) }
          variant="alone"
          isDisabled={ !reason }
          onClickHandler={ () => {
            onUpdateRowHandler(data, reason)
            onClosePopUp()
          } }
        />
      </div>
    </>
  )
}


export default PopUpContent_1_1

import { useQuery, } from '@apollo/client'
import { useMemo, } from 'react'
import { LIST_DIRECTIONS, } from './useAchievementDirections'


const useGetSortedAchievementDirections = ({
  directions,
}: any) => {
  const { data, loading, } = useQuery(LIST_DIRECTIONS, { variables: { is_admin: true, }, })

  const directionsOrder = useMemo(() => {
    if (!loading && data) {
      const currentData = data.achievementDirections
      const result = {}

      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      currentData.forEach((item: any) => result[item.name] = item.order)

      return result
    } return null
  }, [
    data,
    loading,
  ])


  const sortedDirections = useMemo(() => {
    if (directions && directionsOrder) {
      return directions.map((direction: any) => {
        let name
        if ('name' in direction) {
          name = direction.name
        } else if ('direction' in direction) {
          name = direction.direction
        }

        return {
          ...direction,
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          order: directionsOrder[name],
        }
      })
        .sort((previous: any, next: any) => {
          if (previous.order > next.order) {
            return 1
          }

          if (previous.order < next.order) {
            return -1
          }

          return 0
        })
    }
  }, [
    directions,
    directionsOrder,
  ])


  return { sortedDirections, }
}


export default useGetSortedAchievementDirections

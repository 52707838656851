// TODO: Выпилить этот компонент из проекта, заменить его на ChipsBox из shared слоя


import { IconCircleX, } from '@tabler/icons'
import styles from './ChipsBox.module.less'


interface ChipsBoxProps {
  items: string[]
  onRemoveItem: (item: string) => void
}


/** @deprecated используй ChipsBox из shared слоя (он отличается, надо будет адаптировать) */
const ChipsBox = ({
  items,
  onRemoveItem,
}: ChipsBoxProps) => {
  return (
    <div className={ styles.chipsContainer }>
      {
        items.map(item => <span className={ styles.tag } key={ item } >
          { item }

          <IconCircleX
            onClick={ onRemoveItem.bind(null, item) }
            color="#C5C5C5"
          />
        </span>)
      }
    </div>
  )
}


export default ChipsBox

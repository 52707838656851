import DefaultPage from '../../DefaultPage/DefaultPage'


const DPOEducation = () => {
  return (
    <DefaultPage />
  )
}

export default DPOEducation

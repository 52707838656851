import { gql, } from '@/__generated__'
import { useMutation, } from '@apollo/client'


const UPDATE = gql(`
  mutation UpdateAchievement($input: UpdateAchievementInput!) {
    updateAchievement(input: $input)
  }
`)


const useUpdateAchievement = () => {
  const [
    updateMutation,
  ] = useMutation(UPDATE)

  const onUpdate = ({
    achievement_id,
    fields,
    userpoints,
    comment,
    status,
  }: any) => updateMutation({
    variables: {
      input: {
        achievement_id,
        fields,
        userpoints,
        comment,
        status,
      },
    },
  })

  return onUpdate
}


export default useUpdateAchievement

import ButtonSimple, { IconComponent, } from '@/legacy/components/common/ButtonSimple/ButtonSimple'
import TextArea from '@/legacy/components/common/TextArea/TextArea'
import {EcAchivementStatus, EcAppealStatus, EcTableName,} from '@/legacy/components/EffectiveContract/constants'
import {
  getScores_1_1,
  getScores_2_1,
  getScores_2_10,
  getScores_2_2,
  getScores_2_4,
  getScores_2_41,
  getScores_2_5,
  getScores_2_6,
  getScores_2_7,
  getScores_2_8,
  getScores_3_1,
  getScores_3_2,
  getScores_3_3,
  getScores_3_4,
} from '@/legacy/components/EffectiveContract/getScores'
import { OnChangeRowValue, } from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableRow/TableRow'
import { InputText, } from '@/shared'
import { TableRowType, } from '@/legacy/types'
import { IconCircleCheck, IconCircleX, } from '@tabler/icons'
import { useEffect, useState, } from 'react'


interface PopupContentProps {
  row: TableRowType
  dataType: EcTableName
  onChangeRowValue: OnChangeRowValue
  onUpdateRowHandler: Function
  onClosePopup: Function
  isAppealMode?: boolean
}


const PopupContent = ({
  row,
  dataType,
  onChangeRowValue,
  onUpdateRowHandler,
  onClosePopup,
  isAppealMode,
}: PopupContentProps) => {
  const [
    rejectAchievement,
    setRejectAchievement,
  ] = useState(false)


  useEffect(() => {
    if (dataType === EcTableName.TABLE_1_1) {
      onChangeRowValue('scores', String(getScores_1_1(row)))
    }

    if (dataType === EcTableName.TABLE_2_1) {
      onChangeRowValue('scores', String(getScores_2_1(row)))
    }


    if (dataType === EcTableName.TABLE_2_2) {
      onChangeRowValue('scores', String(getScores_2_2(row)))
    }

    if (dataType === EcTableName.TABLE_2_3) {
      onChangeRowValue('scores', String(getScores_1_1(row)))
    }

    if (dataType === EcTableName.TABLE_2_4) {
      onChangeRowValue('scores', String(getScores_2_4(row)))
    }

    if (dataType === EcTableName.TABLE_2_41) {
      onChangeRowValue('scores', String(getScores_2_41(row)))
    }

    if (dataType === EcTableName.TABLE_2_5) {
      onChangeRowValue('scores', String(getScores_2_5(row)))
    }

    if (dataType === EcTableName.TABLE_2_6) {
      onChangeRowValue('scores', String(getScores_2_6(row)))
    }

    if (dataType === EcTableName.TABLE_2_7) {
      onChangeRowValue('scores', String(getScores_2_7(row)))
    }

    if (dataType === EcTableName.TABLE_2_8) {
      onChangeRowValue('scores', String(getScores_2_8(row)))
    }

    if (dataType === EcTableName.TABLE_2_10) {
      onChangeRowValue('scores', String(getScores_2_10(row)))
    }

    if (dataType === EcTableName.TABLE_3_1) {
      onChangeRowValue('scores', String(getScores_3_1(row)))
    }

    if (dataType === EcTableName.TABLE_3_2) {
      onChangeRowValue('scores', String(getScores_3_2()))
    }

    if (dataType === EcTableName.TABLE_3_3) {
      onChangeRowValue('scores', String(getScores_3_3()))
    }

    if (dataType === EcTableName.TABLE_3_4) {
      onChangeRowValue('scores', String(getScores_3_4(row)))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      { !rejectAchievement && (
        <>
          <InputText
            title={ 'Будет начислено баллов:' }
            value={ String(row.scores) }
            type='number'
            disabled
            onChange={ event => onChangeRowValue('scores', String(event.target.value)) }
          />

          <br />
          <br />

          <div style={ {
            display: 'flex',
            justifyContent: 'flex-end',
          } }>
            <ButtonSimple
              value={ (
                <IconComponent
                  Icon={ IconCircleX }
                  iconStyles={ { color: '#FF5E5E', } }
                  text="Отклонить"
                />
              ) }
              variant="groupe"
              styles={ { marginRight: '1rem', } }
              onClickHandler={ () => setRejectAchievement(true) }
            />

            <ButtonSimple
              value={ (
                <IconComponent
                  Icon={ IconCircleCheck }
                  text="Подтвердить"
                />
              ) }
              variant="groupe"
              active
              // isDisabled={ row.scores === null }
              onClickHandler={ () => {
                if (!isAppealMode) {
                  Promise.resolve(onChangeRowValue('status', EcAchivementStatus.APPROVED))
                    .then(() => onUpdateRowHandler())
                    .then(() => onClosePopup())
                }

                if (isAppealMode) {
                  Promise.resolve(onChangeRowValue('status', EcAppealStatus.APPEAL_APPROVED))
                    .then(() => onUpdateRowHandler())
                    .then(() => onClosePopup())
                }
              } }
            />
          </div>
        </>
      ) }


      { rejectAchievement && (
        <>
          <TextArea
            defaultHint='По какой причине вы хотите отклонить заявку?'
            withPlaceholder={ false }
            value={ row.reason }
            onChangeValue={ (value: string) => onChangeRowValue('reason', value) }
          />

          <br />

          <div style={ {
            display: 'flex',
            justifyContent: 'flex-end',
          } }>
            <ButtonSimple
              value="Отменить"
              variant="groupe"
              styles={ {
                marginRight: '1rem',
                backgroundColor: '#F5F6FA',
              } }
              onClickHandler={ () => setRejectAchievement(false) }
            />

            <ButtonSimple
              value={ (
                <IconComponent
                  Icon={ IconCircleX }
                  iconStyles={ { color: '#FF5E5E', } }
                  text="Отклонить"
                />
              ) }
              variant="groupe"
              isDisabled={ row?.reason?.length < 1 }
              onClickHandler={ () => {
                if (!isAppealMode) {
                  Promise.resolve(onChangeRowValue('status', EcAchivementStatus.REJECTED))
                    .then(() => onUpdateRowHandler())
                    .then(() => onClosePopup())
                }

                if (isAppealMode) {
                  Promise.resolve(onChangeRowValue('status', EcAppealStatus.APPEAL_REJECTED))
                    .then(() => onUpdateRowHandler())
                    .then(() => onClosePopup())
                }
              } }
            />
          </div>
        </>
      ) }
    </>
  )
}


export default PopupContent

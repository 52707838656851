import Filter from '@/legacy/components/Projects/MyProjects/Filter/Filter'
import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import Cards from '@/legacy/components/Projects/MyProjects/MyProjectCards/MyProjectCards'

function MyProjects () {
  return (
    <div className="InnerPage allProjects">
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
      ] } loc="Мои проекты" />
      <div className="studentInner">
        <h2 className="profileTitle">Мои проекты</h2>
        <Filter />
      </div>
    </div>
  )
}

export default MyProjects

import { useState, } from 'react'
import { useCreateEcData, } from '../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import useGetHandlerForUpdateEcData from '../../../gql/hooks/effectiveContractHooks/useGetHandlerForUpdateEcData'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import { TitleH3, } from '../../common/GeneralElements/GeneralElements'
import InputTextDeprecated from '../../common/InputTextDeprecated/InputTextDeprecated'
import Popup from '../../common/Popup/Popup'

const PopupNewAppeal = ({
  achievementData,
  isOpenPopupNewAppeal,
  setIsOpenPopupNewAppeal,
}: any) => {
  const [
    textAppeal,
    setTextAppeal,
  ] = useState('')
  const { onUpdateHandler, } = useGetHandlerForUpdateEcData()
  const [
    onAddNewEcDataAppeal,
  ] = useCreateEcData()
  const onAddNewEcDataAppealHandler = (newAppealParam: any, updateCurrentAchievementParam: any) => {
    onAddNewEcDataAppeal(newAppealParam)
      .then(() => onUpdateHandler({ ...updateCurrentAchievementParam, }))
      .then(() => setIsOpenPopupNewAppeal(false))
  }

  return (
    <Popup
      isOpen={ isOpenPopupNewAppeal }
      handlePopup={ () => {
        setIsOpenPopupNewAppeal(false)
      } }
    >
      <>
        <TitleH3>
          Подать апелляцию
        </TitleH3>

        <InputTextDeprecated
          defaultHint="Текст апелляции"
          value={ textAppeal }
          onChangeValue={ setTextAppeal }
        />

        <br />

        <div>
          <ButtonSimple
            value="Подать апелляцию"
            variant="full-filled"
            onClickHandler={ () => {
              onAddNewEcDataAppealHandler(
                {
                  variables: {
                    dataType: achievementData.dataType.replace('table', 'appeal'),
                    data: {
                      ...achievementData.data,
                      textAppeal: textAppeal.trim(),
                      status: 'appealPending',
                      achievement_id: achievementData.rec_id,
                    },
                  },
                },
                {
                  rec_id: achievementData.rec_id,
                  new_appeal: {
                    textAppeal: textAppeal.trim(),
                    status: 'appealPending',
                  },
                }
              )
            } }
            isDisabled={ textAppeal.trim().length <= 0 }
          />
        </div>
      </>
    </Popup>
  )
}


export default PopupNewAppeal

import { useState, } from 'react'
import useUpdateEcDocuments from '../../../../../gql/hooks/effectiveContractHooks/useUpdateEcDocuments'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import UploadFile from '../../../UploadFile/UploadFile'

const AddDocument = ({
  currentDocuments = [],
}) => {
  const [
    fileList,
    setFileList,
  ] = useState([])
  const { onUpdateEcDocuments, } = useUpdateEcDocuments()

  return <>
    <div>
      <UploadFile
        setFileList={ setFileList }
        category="effective-contract/documents"
      />

      <AttachedFiles
        isDeleteFilesAllowed={ false }
        fileList={ fileList }
        getIndexFile={ (fileIndex: any) => setFileList(fileList.filter((file, index) => index !== fileIndex)) }
      />
    </div>

    <ButtonSimple
      active
      variant='alone'
      value="Загрузить файл"
      isDisabled={ !fileList.length }
      onClickHandler={ () => {
        onUpdateEcDocuments([
          ...currentDocuments,
          ...fileList,
        ])
          .then(() => setFileList([]))
      } }
    />
  </>
}


export default AddDocument

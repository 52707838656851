import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { EC_CHANGES, } from '@/legacy/gql/hooks/effectiveContractHooks/useEcSubscription'
import { useSubscription, } from '@apollo/client'
import { createContext, ReactNode, useEffect, useState, } from 'react'


export const EcSubscriptionContext = createContext<null | EcTableName>(null)


const EcSubscriptionsProvider = ({
  children,
}: { children: ReactNode }) => {
  const [
    dataType,
    setDataType,
  ] = useState<null | EcTableName>(null)

  const { data, loading, } = useSubscription(EC_CHANGES)


  useEffect(() => {
    if (dataType !== null) {
      setDataType(null)
    }
  }, [
    dataType,
  ])

  useEffect(() => {
    if (!loading && data) {
      const targetType = Object.values(data)[0]?.result?.dataType satisfies EcTableName
      setDataType(targetType)
    }
  }, [
    data,
    loading,
  ])


  return (
    <EcSubscriptionContext.Provider value={ dataType }>
      { children }
    </EcSubscriptionContext.Provider>
  )
}


export default EcSubscriptionsProvider

import './VisualizeDataBox.less'
import { useEffect, useState, } from 'react'
import { ResponsiveRadialBar, } from '@nivo/radial-bar'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import { initialSettings, } from './radialBarSettings'
import clsx from 'clsx'
import Popup from '@/legacy/components/common/Popup/Popup'
import { useSelector, } from 'react-redux'
import { useGetListOfScientificIds, } from '@/legacy/gql/hooks/effectiveContractHooks/useGetListOfScientificIds'
import '@/legacy/components/Portfolio/RatingScale/RatingScale.less'
import { useGetEcPersonData, } from '@/entities/EffectiveContract/api/useGetEcPersonData'


const updateData = (dataOfPoints: any, settings: any) => {
  if (dataOfPoints) {
    return settings.map((item: any) => ({
      ...item,

      data: [
        {
          ...item.data[0],
          'y': dataOfPoints[item.id]?.data,
        },
      ],
    }))
  } return settings
}


const VisualizeDataBox = ({
  stage,
  dataOfPoints,
}: any) => {
  const [
    barSettings,
    setBarSettings,
  ] = useState(initialSettings)

  const [
    isPopupOpen,
    setIsPopupOpen,
  ] = useState(false)
  const onClosePopupHandler = () => {
    setIsPopupOpen(false)
    // setDefaultPopupValues({});
  }

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)

  // const ListOfScientific = useGetListOfScientificIds('47b59957-c980-11e9-bb99-005056aa8995')
  const ListOfScientific = useGetListOfScientificIds(currentUserId)

  const { data, } = useGetEcPersonData(currentUserId)

  useEffect(() => {
    setTimeout(() => setBarSettings(updateData(dataOfPoints, initialSettings)), 0)
  }, [
    dataOfPoints,
  ])


  return (
    <div className={ clsx({
      'effective-contract__visualize-data': true,
      'effective-contract__visualize-data_acent': stage === 'stage-6',
    }) }
    >
      <div className="effective-contract__radial-bar-with-description">
        <div className='effective-contract__radial-bar-wrapper'>
          <ResponsiveRadialBar
            data={ barSettings }
            maxValue={ 100 }
            endAngle={ 180 }
            innerRadius={ 0.2 }
            padding={ 0.6 }
            cornerRadius={ 16 }
            colors={ [
              '#43d65a',
              '#f9b754',
              '#7671dd',
            ] }
            tracksColor="#f5f6fa"
            enableTracks={ true }
            enableRadialGrid={ false }
            enableCircularGrid={ false }
            radialAxisStart={ null }
            // @ts-expect-error TS(2559): Type 'false' has no properties in common with type... Remove this comment to see the full error message
            circularAxisOuter={ false }
            // @ts-expect-error TS(2322): Type 'boolean' is not assignable to type 'number'.
            labelsSkipAngle={ false }
            // @ts-expect-error TS(2322): Type 'false' is not assignable to type 'InheritedC... Remove this comment to see the full error message
            labelsTextColor={ false }
            isInteractive={ false }
            motionConfig="slow"
            transitionMode="startAngle"
            // @ts-expect-error TS(2322): Type 'boolean' is not assignable to type 'LegendPr... Remove this comment to see the full error message
            legends={ false }
            animate={ true }
          />
        </div>
        <div className="effective-contract__radial-bar-wrapper-text-block">
          <p className="effective-contract__description-item">
            Всего баллов: { dataOfPoints.totalPoints }
          </p>
          <p
            className={ clsx({
              'effective-contract__description-item': true,
              'effective-contract__description-item_acent': stage === 'stage-6',
            }) }
          >
            <span className="indicator-circul indicator-circul_basic-indicators"></span>
            Базовые показатели: { dataOfPoints.basicIndicators.data }%
          </p>
          <p className="effective-contract__description-item">
            <span className="indicator-circul indicator-circul_hight-performance"></span>
            Показатели высокой эффективности труда: { dataOfPoints.hightPerformance.commonScores } балла(ов) {/* ({ dataOfPoints.hightPerformance.data }%) */ }
          </p>
          <p className="effective-contract__description-item">
            <span className="indicator-circul indicator-circul_bonus-points"></span>
            Бонусные баллы: { dataOfPoints.bonusPoints.commonScores } балла(ов) {/* ({ dataOfPoints.bonusPoints.data }%)*/ }
          </p>
          <p className="effective-contract__description-item">
            <span className="indicator-circul indicator-circul_downgrade-payment"></span>
            Основания для снижения выплат стимулирующего характера: { dataOfPoints.downgradePayment.commonScores } балла(ов) {/* ({ dataOfPoints.downgradePayment.data }%)*/ }
          </p>


          <div className="effective-contract__ref-container">
            {ListOfScientific.data?.ecListOfScientificIds.orcid_id && (
              <p className="showRate effective-contract__ref">
                <a href={`https://orcid.org/${ListOfScientific.data?.ecListOfScientificIds.orcid_id}`} target="_blank" rel="noopener noreferrer">
                  ORCID
                </a>
              </p>
            )}

            {ListOfScientific.data?.ecListOfScientificIds.researcher_id && (
              <p className="showRate effective-contract__ref">
                <a href={`https://www.webofscience.com/wos/author/record/${ListOfScientific.data?.ecListOfScientificIds.researcher_id}`} target="_blank" rel="noopener noreferrer">
                  Web of Science
                </a>
              </p>
            )}

            {ListOfScientific.data?.ecListOfScientificIds.scopus_id && (
              <p className="showRate effective-contract__ref">
                <a href={`https://www.scopus.com/authid/detail.uri?authorId=${ListOfScientific.data?.ecListOfScientificIds.scopus_id}`} target="_blank" rel="noopener noreferrer">
                  Scopus
                </a>
              </p>
            )}

            {ListOfScientific.data?.ecListOfScientificIds.rinc_id && (
              <p className="showRate effective-contract__ref">
                <a href={`https://www.elibrary.ru/author_profile.asp?authorid=${ListOfScientific.data?.ecListOfScientificIds.rinc_id}`} target="_blank" rel="noopener noreferrer">
                  eLIBRARY.RU
                </a>
              </p>
            )}
          </div>

          <div className="effective-contract__position-info">
            <p className="effective-contract__description-item">
              <strong>Должность: </strong> {data?.ecGetEmployeePosition?.positionName}
            </p>
            <p className="effective-contract__description-item">
              <strong>В должности с </strong> {
                data?.ecGetEmployeePosition?.orderDate
                  ? new Intl.DateTimeFormat('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(data.ecGetEmployeePosition.orderDate))
                  : '—'
              }
            </p>
          </div>

        </div>
        <Popup
          isOpen={isPopupOpen}
          handlePopup={onClosePopupHandler}
          withScroll={true}
        >
          <>
            <div style={ {
              display: 'flex',
              justifyContent: 'center',
            } }>
              <div style={ {
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              } }>
                <h2 style={{ textAlign: 'center',
                  width: '100%', }}>Уважаемые коллеги!</h2>
                <p>Расчет стимулирующих выплат по базовой части и показателям высокой эффективности за 2023 отчетный год будет осуществляться в процентном соотношении от суммарного выполнения базовых показателей.</p>
                <h3>Вес каждого из показателей базовой части:</h3>
                <h3>«ассистент, старший преподаватель, доцент, профессор»:</h3>
                <ul>
                  <li>показатель 1.1 «Учебно-методическое обеспечение образовательного процесса» - 50%</li>
                  <li>показатель 1.2 «Плановое повышение квалификации» - 10%</li>
                  <li>показатель 1.3 «Участие в профориентационных мероприятиях, организованных кафедрой, институтом/факультетом, Университетом» - 10%</li>
                  <li>показатель 1.4 «Участие в научно-исследовательской работе по профилю кафедры, института (факультета) и (или) преподаваемого курса, дисциплины (модуля)» - 30%</li>
                  <li style={{ fontWeight: 'bold',
                    fontSize: 18,
                    marginTop: 10, }}>100%=15% от должностного оклада (пропорционально ставке по программам ВО, но не более 1 ставки)</li>
                </ul>
                <h3>«преподаватель»:</h3>
                <ul>
                  <li>показатель 1.1 «Учебно-методическое обеспечение образовательного процесса» - 50%</li>
                  <li>показатель 1.2 «Плановое повышение квалификации» - 10%</li>
                  <li>показатель 1.3 «Участие в профориентационных мероприятиях, организованных кафедрой, институтом/факультетом, Университетом» - 10%</li>
                  <li>показатель 1.4 «Участие в научно-исследовательской работе по профилю кафедры, института (факультета) и (или) преподаваемого курса, дисциплины (модуля)» - 20%</li>
                  <li>показатель 1.5. Организация и участие в социально-воспитательных мероприятиях Университета – 10%</li>
                  <li style={{ fontWeight: 'bold',
                    fontSize: 18,
                    marginTop: 10, }}>100%=20 % от должностного оклада (пропорционально ставке по программам СО и СПО, но не более 1 ставки)</li>
                </ul>
                <h3>«учитель»:</h3>
                <ul>
                  <li>показатель 1.1 «Учебно-методическое обеспечение образовательного процесса» - 50%</li>
                  <li>показатель 1.2 «Плановое повышение квалификации» - 10%</li>
                  <li>показатель 1.3 «Участие в профориентационных мероприятиях, организованных кафедрой, институтом/факультетом, Университетом» - 10%</li>
                  <li>показатель 1.4 «Участие в научно-исследовательской работе по профилю кафедры, института (факультета) и (или) преподаваемого курса, дисциплины (модуля)» - не предусмотрен</li>
                  <li>показатель 1.5. Организация и участие в социально-воспитательных мероприятиях Университета – 30%</li>
                  <li style={{ fontWeight: 'bold',
                    fontSize: 18,
                    marginTop: 10, }}>100%=20 % от должностного оклада (пропорционально ставке по программам СО и СПО, но не более 1 ставки)</li>
                </ul>
                <p style={{ fontWeight: 'bold',
                  fontSize: 18, }}>Пример расчета.</p>
                <p>Доцент выполнил 3 показателя базовой части: 1.1+1.2+1.3 =50%+10%+10%=70%</p>
                <p>(70%*15%)/100%=10,5% от должностного оклада за выполнение базовой части.</p>
                <p>ПЛЮС 70% от выплат по высокой эффективности</p>
              </div>
            </div>
            <br />
            <ButtonSimple
              value="Хорошо"
              variant="full-filled"
              onClickHandler={ () => {
                setIsPopupOpen(false)
              }}
            />
          </>
        </Popup>
      </div>

      <div className="effective-contract__visualize-data-accent-message">
        <ButtonSimple
          value="Порядок расчета стимулирующих выплат за 2023 отчетный год"
          variant="fill"
          onClickHandler={() => setIsPopupOpen(true)}
        />
      </div>
      {/* { stage === 'stage-2' ? (*/}
      {/*  <div className="effective-contract__visualize-data-button-wrapper">*/}
      {/*    <Link to={ EFFECTIVE_CONTRACT_ADD_ACHIEVEMENT_ROUTE }>*/}
      {/*      <ButtonSimple value="Добавить достижение" variant="fill" />*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/* ) : false }*/}


      { stage === 'stage-6' ? (
        accentMessage
      ) : false }

    </div>
  )
}


const accentMessage = (
  <div className="effective-contract__visualize-data-accent-message-wrapper">
    <p style={ { margin: 0, } }>
      Обратите внимание, при незакрытой базовой части эффективного контракта, баллы за оставшиеся пункты учитываться не будут!
    </p>
  </div>
)


export default VisualizeDataBox

import { TableCellType, } from '@/legacy/types'
import { table_cat_3_1, } from '../../popUpInputsCatalogs'

export const tableSource: TableCellType[] = [
  {
    editable: false,
    title: '№',
    index: 'number',
  },
  {
    title: 'Категория работ',
    index: 'category',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_3_1.category,
  },
  {
    title: 'Научная работа',
    index: 'membership',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_3_1.membership,
  },
  {
    title: 'Подтверждающие документы',
    index: 'documents',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: false,
  },
  {
    title: 'Статус',
    editable: false,
    index: 'status',
    style: { minWidth: '8rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    editable: false,
    index: 'number',
  },
  {
    title: 'Категория работ',
    index: 'category',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_3_1.category,
  },
  {
    title: 'Научная работа',
    index: 'membership',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_3_1.membership,
  },
  {
    title: 'Подтверждающие документы',
    index: 'documents',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Причина отказа',
    editable: false,
    index: 'reason',
    style: { minWidth: '20rem', },
    editType: 'input',
  },
  {
    title: 'Комментарий',
    editable: false,
    index: 'textAppeal',
    editType: 'input',
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
    editable: false,
  },
]

import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  projectId: null,
  fixProject: {},
  currentProject: null,
  currentTask: null,
  oppenedCard: null,
  editStatus: null,
  notifyId: null,
  filter: 'Информация о проекте',
}

export const ProjectSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProjectId: (state, action) => {
      state.projectId = action.payload
    },
    setFixProject: (state, action) => {
      state.fixProject = action.payload
    },
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload
    },
    setCurrentTask: (state, action) => {
      state.currentTask = action.payload
    },
    setOppenedCard: (state, action) => {
      state.oppenedCard = action.payload
    },
    setEditStatus: (state, action) => {
      state.editStatus = action.payload
    },
    setNotifyId: (state, action) => {
      state.notifyId = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
  },
})


export const { setProjectId, setFixProject, setCurrentProject, setCurrentTask,
  setOppenedCard, setEditStatus, setNotifyId, setFilter, } = ProjectSlice.actions

export default ProjectSlice.reducer

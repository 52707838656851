import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'


const useGetDirectionsWithAchievements = ({
  id,
  available,
  status,
}: any) => {
  const GET_DIRECTIONS_WITH_ACHIEVEMENTS = gql`
  query GetDirectionsWithAchievements($id: UUID!, $available: Boolean, $status: AchievementStatus) {
    getDirectionsWithAchievements(id: $id, available: $available, status: $status)
  }
`

  const { data, loading, } = useQuery(GET_DIRECTIONS_WITH_ACHIEVEMENTS, {
    variables: {
      id,
      available,
      status,
    },
  })


  const result = useMemo(() => {
    if (!loading && data) {
      return data.getDirectionsWithAchievements
    }
  }, [
    data,
    loading,
  ])


  return result
}


export default useGetDirectionsWithAchievements

import { useQuery, gql, } from '@apollo/client'

const GET_PERSONNEL_CONFIG = gql`
    query Query($personnelConfigId: UUID) {
        personnelConfig(id: $personnelConfigId)
    }
`

interface PersonnelConfig {
  name: string;
  value: string;
}

interface GetPersonnelConfigQueryResponse {
  personnelConfig: PersonnelConfig[];
}

interface GetPersonnelConfigQueryVariables {
  personnelConfigId: string;
}

const usePersonnelConfig = (personnelConfigId: string) => {
  const { loading, error, data, } = useQuery<GetPersonnelConfigQueryResponse,
    GetPersonnelConfigQueryVariables>(GET_PERSONNEL_CONFIG, {
      variables: { personnelConfigId, },
    })

  const settings = data?.personnelConfig?.reduce<{
    [key: string]: string;
  }>((acc, config) => {
    acc[config.name] = config.value
    return acc
  }, {})

  return { loading,
    error,
    data,
    settings, }
}

export default usePersonnelConfig

import { gql, useMutation, } from '@apollo/client'


const RESET_DRAFT_PROCESSED = gql`
  mutation NotificationDraftReset($draft_id: [Int!]!) {
    notificationDraftResetProcessed(draft_id: $draft_id)
  }
`

const useNotificationDraftResetProcessed = () => {
  const [
    onReset,
  ] = useMutation(RESET_DRAFT_PROCESSED)

  const onResetDraftHandler = ({
    draftIds,
  }: any) => onReset({
    variables: {
      draft_id: draftIds,
    },
  })


  return { onResetDraftHandler, }
}


export default useNotificationDraftResetProcessed

/* eslint-disable no-console */
import './TaskCard.less'
import { IconPaperclip, IconFile, IconX, } from '@tabler/icons'
import { format, } from 'date-fns'
import ru from 'date-fns/locale/ru'
import Image from '../../../common/Image/Image'
import { useChangeTask, } from '../../../../gql/hooks/useProjects'
import compareAsc from 'date-fns/compareAsc'
import { downloadFile, } from '../../../../hooks/useDownloadFile'
import { useDeleteUpload, } from '../../../../gql/hooks/useAvatars'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import useUpload from '@/legacy/hooks/useUpload/useUpload'
import { ChangeEvent, } from 'react'
import { Alert, CircularProgress, Snackbar, } from '@mui/material'


function TaskCard ({
  task,
}: any) {
  const role = useGetRole()

  const { taskName, date, responsible, description, files, performer, } = task.data


  function getStatus (i: any) {
    if (i === 'new') {
      return 'В работе'
    } else if (i === 'finished') {
      return 'На проверке'
    } else if (i === 'completed') {
      return 'Завершена'
    }
  }

  const mainInfo = [
    {
      title: 'Дата постановки',
      value: format(new Date(task.created), 'dd MMMM yyyy', { locale: ru, }),
    },
    {
      title: 'Срок задачи',
      value: format(new Date(date), 'dd MMMM yyyy', { locale: ru, }),
    },
  ]


  const [
    paChangeTask,
  ] = useChangeTask()


  const {
    InputFile,
    isFileLoading,
    isFilesExists,
    setIsFilesExists,
    onCheckFilesHandler,
    onUploadFileHandler,
  } = useUpload()


  function changeTask (fileList: FileList | null) {
    const category = `project/${ task.req_id }/${ task.stage_id }/${ task.task_id }tasks`

    if (fileList) {
      onCheckFilesHandler(fileList, category)
        .then(isUploadFilesAllowed => {
          if (isUploadFilesAllowed) {
            onUploadFileHandler(fileList, category)
              .then(uploadedFiles => {
                const params = {
                  task_id: task.task_id,
                  event: 'taskChanged',
                  data: {
                    ...task.data,
                    files: [
                      ...files,
                      ...uploadedFiles,
                    ],
                  },
                }

                paChangeTask({ variables: { params, }, })
              })
          }
        })
    }
  }

  const [
    deleteUpload,
  ] = useDeleteUpload()

  function deleteFile (event: any, current: any) {
    event.stopPropagation()
    const newFiles = files ? files.filter((i: any) => i?.name !== current.name) : []

    const params = {
      task_id: task.task_id,
      event: 'taskChanged',
      data: {
        ...task.data,
        files: newFiles,
      },
    }

    paChangeTask({ variables: { params, }, })
      .then(() => {
        deleteUpload({
          variables: {
            upload_ids: [
              current.upload_id,
            ],
          },
        })
          .then(() => console.log('Файл удален из базы'))
          .catch(e => console.log(e))
      })
      .catch(e => console.log('stage err', e))
  }

  const isExpire = compareAsc(new Date(task.data.date.replaceAll('-', ',')), new Date(Date.now()))

  return (
    <div className='taskCard'>
      <div className="status">{ isExpire < 0 ? 'Просрочена' : getStatus(task.status) }</div>
      <h2>{ taskName }</h2>
      <div className="mainInfo">
        { mainInfo.map(i => <div key={ mainInfo.indexOf(i) } className="infoItem">
          <h3>{ i.title }</h3>
          <p>{ i.value }</p>
        </div>) }
        { (responsible && (role === 'student' || role === 'student-rakus')) && <div className="teacher">
          <Image id={ responsible.id } />
          <div>
            <p className="subtitle">Руководитель</p>
            <p className="value">{ responsible.full_name }</p>
          </div>
        </div> }
        { role !== 'student' && performer.map((i: any, index: any) => <div className="teacher" key={ index }>
          <Image id={ i.id } />
          <div>
            <p className="subtitle">Исполнитель</p>
            <p className="value">{ i.full_name }</p>
          </div>
        </div>) }
      </div>


      <p className="taskText">{ description }</p>

      { role !== 'student' && (
        <>
          <label className="attacheBut">
            <InputFile
              style={ { display: 'none', } }
              onChange={ (event: ChangeEvent<HTMLInputElement>) => changeTask(event.target.files) }
            />

            Прикрепить файл <IconPaperclip color="#7671DD" />
          </label>

          <Snackbar open={ isFilesExists }>
            <Alert onClose={ () => setIsFilesExists(undefined) } severity="error">
              Файл с таким именем уже существует!
            </Alert>
          </Snackbar>
        </>
      ) }

      <ul>
        { files && files.map((file: any) => file && (
          <li key={ files.indexOf(file) }>
            <div
              className="addedFile"
              onClick={ () => downloadFile(file) }
            >
              <IconFile color="#7671DD" />

              { file.name }

              { role !== 'student' && (
                <IconX color="#C5C5C5" onClick={ event => deleteFile(event, file) } />
              ) }
            </div>
          </li>)
        ) }

        { isFileLoading && (
          <li><CircularProgress disableShrink size={ 24 } /></li>
        ) }

      </ul>
    </div>
  )
}

export default TaskCard

import { useMemo, } from 'react'
import './BookData.less'
import { Row, } from 'antd'
import ColRec from './ColRec'


function BookData ({
  data,
}: any) {
  const { eform, department, nameprof, namespec, course, elevel, } = data

  const bookData = useMemo(() => {
    return <div className="bookData">
      <Row>
        <ColRec space={ 12 } value={ department } title="Факультет" />
        <ColRec space={ 12 } value={ eform } title="Форма обучения" />
      </Row>
      <Row>
        <ColRec space={ 12 } value={ elevel } title="Уровень подготовки" />
        <ColRec space={ 12 } value={ namespec } title="Специальность" />
      </Row>
      <Row>
        <ColRec space={ 12 } value={ nameprof } title="Профиль" />
        <ColRec space={ 12 } value={ course } title="Курс" />
      </Row>
    </div>

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
  ])

  return (bookData)
}

export default BookData

import { useState, useMemo, } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { IconChevronDown, } from '@tabler/icons'
import clsx from 'clsx'
import DetailsCard from '../../DetailsCard/DetailsCard'

function ArchiveAchivments ({
  archive,
}: any) {
  const [
    isFull,
    setIsFull,
  ] = useState(false)

  const achivments = useMemo(() => {
    return archive.map((i: any, index: any) => <DetailsCard key={ index } item={ i } index={ index } />)
  }, [
    archive,
  ])


  return (
    <Accordion className="ratingFilters" style={ {
      borderRadius: '15px',
      boxShadow: '0px 9px 14px rgba(0, 0, 0, 0.03)',
    } }>
      <AccordionSummary onClick={ () => setIsFull(!isFull) }>
        <h2>Достижения в архиве</h2>

        <div className={ clsx('chevronCircle', isFull && 'chevronUp') }>{ <IconChevronDown color={ '#7F8190' } /> }</div>
      </AccordionSummary>


      <AccordionDetails style={ {
        display: 'flex',
        flexWrap: 'wrap',
      } }>
        { achivments }
      </AccordionDetails>
    </Accordion>
  )
}


export default ArchiveAchivments

import { createSlice, } from '@reduxjs/toolkit'

const initialState = {
  news: [],
  selectedNews: {},
  categories: [],
  selectedCategories: [],
  currentNews: null,
  currentItems: [],
  currentAnnounce: null,
}

export const NewsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNews: (state, action) => {
      state.news = action.payload
    },
    setSelectedNews: (state, action) => {
      state.selectedNews = action.payload
    },

    setSelectedCategories: (state, action) => {
      state.selectedCategories = action.payload
    },
    setCurrentItems: (state, action) => {
      state.currentItems = action.payload
    },
    setCurrentAnnounce: (state, action) => {
      state.currentAnnounce = action.payload
    },
    setCurrentNews: (state, action) => {
      state.currentNews = action.payload
    },
  },
})


export const { setNews, setCurrentNews, setSelectedNews, setSelectedCategories, setCurrentItems, setCurrentAnnounce, } = NewsSlice.actions

export default NewsSlice.reducer

import { useMemo, useEffect, useState, } from 'react'
import LinkButton from '../../../../../common/LinkButton/LinkButton'
import PaperContainerWrapper from '../../../../../common/PaperContainer/PaperContainerWrapper'
import { useGetStats, } from '../../../../../../gql/hooks/useMyEducation'
import { useLocation, } from 'react-router-dom'
import { Preloader, } from '@/shared'
import { useSelector, } from 'react-redux'
import StatisticDetailPopup from './StatisticDetailPopup'
import DetailStatisticBlock from '../../StatisticColumns/DetailStatisticBlock'


const TrackStatistics = ({
  type,
  payloadType,
}: any) => {
  const location = useLocation()
  const id = new URLSearchParams(location.search).get('id')

  const { data, error, loading, refetch, } = useGetStats({
    filter: {
      ref_id: Number(id),
      type: payloadType,
    },
  })

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.myEd.updateStats)

  useEffect(() => {
    refetch()
  }, [
    refresh,
    refetch,
  ])

  const statsTable = useMemo(() => {
    if (error) {
      return <div>...error</div>
    }
    if (loading) {
      return <Preloader />
    } else if (data) {
      return <DetailStatisticBlock stats={ data.esDisciplineStats.items[0] } />
    }
  }, [
    data,
    error,
    loading,
  ])

  const [
    isPopup,
    setIsPopup,
  ] = useState(false)


  return (
    <PaperContainerWrapper variant="sub-paper">
      <div className='discipline-detail-card__statistics'>
        <h3 className='discipline-detail-card__description-title'>
          { `Статистика по ${ type === 'Факультатив' ? 'факультативу' : (type === 'Трек' ? 'треку' : 'элективу') }` }
        </h3>
        { statsTable }
        <LinkButton type="statistics" onClickHandler={ () => setIsPopup(!isPopup) } />
        <StatisticDetailPopup type={ type } isOpen={ isPopup } setPopup={ () => setIsPopup(!isPopup) } payloadType={ payloadType } />
      </div>
    </PaperContainerWrapper>
  )
}


export default TrackStatistics

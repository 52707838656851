import Field from '@/legacy/components/Projects/AllProjects/Field/Field'
import { semesterItems, } from '@/legacy/components/Projects/NewProject/consts/consts'
import { handleCources, updateStateArray, } from '@/legacy/components/Projects/NewProject/handlers/handlers'
import ProfsSearchAccepted from '@/legacy/components/common/ProfsSearch/ProfsSearchAccepted'
import SpecsListAccepted from '@/legacy/components/common/SpecsSearch/SpecsSearchAccepted'
import { IconCircleX, } from '@tabler/icons'
import { DebounceInput, } from 'react-debounce-input'
import PhoneInput from 'react-phone-number-input'

const RequirementsSection = ({ setField, setFields, formFields, currentField, setCurrentField, checkboxValue, }:any) => {
  return (
    <div>
      <h2>Требования к студентам, участникам проекта</h2>

      <div className="type">
        <textarea
          placeholder="Требования к студентам, участникам проекта"
          onChange={(e: any) => setField('requirments', e.target.value) }
          defaultValue={ formFields.requirments }
        />


        <div
          className="dateWrap multiple"
          style={ {
            width: '100%',
            border: 'none',
          } }
        >
          <div style={ { display: 'flex', } }>
            <Field
              value={ formFields.courses }
              items={ [
                1,
                2,
                3,
                4,
                5,
                6,
              ] }
              title="Курс"
              handler={(e: any) => handleCources(e, setFields, checkboxValue)}
              onClick={ setCurrentField }
              activeField={ currentField }
            />
            <Field
              value={ formFields.semester }
              items={ semesterItems }
              title="семестр"
              handler={(e:any) => setField('semester', e) }
              onClick={ setCurrentField }
              activeField={ currentField }
            />
          </div>

        </div>

        { formFields.courses !== null ? (
          <div className="dateWrap multiple" style={ { width: '100%', } }>
            <span>
              { formFields.nameSpec?.map((i: any) => (
                <div className="specTag" key={ i }>
                  { i }
                  <IconCircleX
                    onClick={() => {
                      const updatedNameSpecs = formFields.nameSpec.filter((item: any) => item !== i)
                      setField('nameSpec', updatedNameSpecs)
                    }}
                    color="#C5C5C5"
                  />
                </div>
              )) }
            </span>

            <DebounceInput
              onChange={ (e:any) => setField('specValue', e.target.value) }
              debounceTimeout={ 500 }
              type="text"
              placeholder="Специализации студентов"
              onClick={ () => setCurrentField('Специализации студентов') }
              value={ formFields.specValue }
            />
            { formFields.specValue?.length > 0 && (
              <SpecsListAccepted
                handler={(item: any) => updateStateArray(item, formFields.nameSpec, setFields, 'nameSpec', { specValue: '', })}
                currentField={ currentField }
                value={ formFields.specValue }
                course={ formFields.courses }
                workType={formFields.workType}
                setCurrentField={ () => setCurrentField('Специализации студентов')
                }
                field="Специализации студентов"
              />
            ) }
          </div>
        ) : null }


        { formFields.courses !== null ? (
          <div className="dateWrap multiple" style={ { width: '100%', } }>
            <span>
              { formFields.nameProf?.map((i: any) => (
                <div className="specTag" key={ i }>
                  { i }
                  <IconCircleX
                    onClick={() => {
                      const updatedNameProfs = formFields.nameProf.filter((item: any) => item !== i)
                      setField('nameProf', updatedNameProfs)
                    }}
                    color="#C5C5C5"
                  />
                </div>
              )) }
            </span>

            <DebounceInput
              onChange={ e => setField('profValue', e.target.value) }
              debounceTimeout={ 500 }
              type="text"
              placeholder="Профили студентов"
              onClick={ () => setCurrentField('Профили студентов') }
              value={ formFields.profValue }
            />
            { formFields.profValue?.length > 0 && (
              <ProfsSearchAccepted
                handler={(item: any) => updateStateArray(item, formFields.nameProf, setFields, 'nameProf', { profValue: '', })}
                currentField={ currentField }
                namespec={ formFields.nameSpec }
                value={ formFields.profValue }
                setCurrentField={ () => setCurrentField('Профили студентов') }
                field="Профили студентов"
              />
            ) }
          </div>
        ) : null }

        <PhoneInput
          defaultCountry="RU"
          placeholder="Введите номер мобильного телефона"
          value={ formFields.mobile }
          onChange={(e: any) => setField('mobile', e) }
        />
        <DebounceInput
          debounceTimeout={ 500 }
          type="text"
          placeholder="Адрес электронной почты"
          onChange={(e: any) => setField('email', e.target.value) }
          value={ formFields.email }
        />
        <DebounceInput
          debounceTimeout={ 500 }
          type="text"
          placeholder="Форма отчета"
          onChange={(e: any) => setField('reportForm', e.target.value) }
          value={ formFields.reportForm }
        />
        <textarea
          placeholder="Дополнительная информация"
          onChange={(e: any) => setField('additional', e.target.value) }
          defaultValue={ formFields.additional }
        />
      </div>
    </div>
  )
}


export default RequirementsSection

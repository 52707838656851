import React, { useMemo, useState, } from 'react'
import { useContractAvailableBanks, } from '../../../../gql/hooks/useFinances'
import './Banks.less'
import { URL, } from '@/shared/constants/constants'
import clsx from 'clsx'
import QrPopup from '../../QrPopup/QrPopup'

function Banks ({
  contract,
}: any) {
  const { data, loading, error, } = useContractAvailableBanks(contract)
  const [
    isQr,
    setIsQr,
  ] = useState(false)
  const [
    type,
    setType,
  ] = useState('QRSber')
  const [
    name,
    setName,
  ] = useState()

  function handlePopup (i: any) {
    setType(i.type)
    setIsQr(!isQr)
    setName(i.name)
  }

  const cards = useMemo(() => {
    if (data) {
      const banks = data.quittancesBanks.map((i: any) => {
        return <div key={ data.quittancesBanks.indexOf(i) } onClick={ () => handlePopup(i) } className='bankCard'><img src={ `https://${ URL }/api/public/images/${ i.logo }` } alt="красивое" />{ i.name }</div>
      })
      return <>
        { banks }
      </>
    } else if (loading) {
      return <div>.....loading</div>
    }
    return <div>error</div>

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    loading,
    data,
    isQr,
  ])

  return (
    <div className={ clsx({
      'QRS': true,
      'QRSHidden': data?.quittancesBanks.length === 0,
    }) }>
      <h3>QR-коды для оплаты</h3>
      <div className="banksWrap">
        <QrPopup type={ type } name={ name } contract={ contract } isOpen={ isQr } handleOpen={ () => setIsQr(!isQr) } />
        { cards }
      </div>
    </div>
  )
}

export default Banks

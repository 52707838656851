import './PaginationSimple.less'

import Pagination from '@mui/material/Pagination'
import clsx from 'clsx'


const PaginationSimple = ({
  count,
  onChange,
  page,
  className,
  style,
}: any): JSX.Element => (
  <div
    className={ clsx('pagination-simple', className) }
    style={ style }
  >
    <Pagination count={ count } shape="rounded" onChange={ onChange } page={ page } />
    <div className='pagination-simple__background' > </div>
  </div>
)


export default PaginationSimple

import HistoryLinks from '@/shared/ui/HistoryLinks/HistoryLinks'
import './GetReference.less'
import ChooseField from './ChooseField/ChooseField'
import { useSelector, } from 'react-redux'
import RefData from './RefData/RefData'
// import GetReferencePopup from './GetReferencePopup/GetReferencePopup';
import BackToButton from '../../common/BackToButton/BackToButton'
import useRedirect from '@/legacy/hooks/useRedirect'
import { setIsCounting, } from '@/legacy/redux/Subscriptions/SubscriptionsSlice'

function GetReference () {
  const redirect = useRedirect()

  // @ts-ignore: Unreachable code error
  const personalInfo = useSelector(state => state.prof.studentInfo)
  // @ts-ignore: Unreachable code error
  const specData = useSelector(state => state.mfc.refAccountData)
  // @ts-ignore: Unreachable code error
  const timeLeft = useSelector(state => state.subscriptions.timeLeft)
  // @ts-ignore: Unreachable code error
  const isCounting = useSelector(state => state.subscriptions.isCounting)
  const specValue = specData ? `${ specData.namespec }` : null

  return (
    <div className="mfc">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
          {
            value: 'Мои справки',
            path: '/my-references',
          },
        ] }
        loc="Заказать новую справку"
      />
      <BackToButton
        onClick={ () => redirect('/my-references') }
        title="Вернуться к моим справкам"
      />
      <h2 className="mfcTitle">Заказать новую справку</h2>
      <div className="getRefContent">
        <h3 className="getRefSubtitle">Выберите специальность для справки</h3>
        <ChooseField
          items={ personalInfo }
          value={ specValue }
          title="Выберите специальность"
        />
        { specData ? (
          <RefData
            studyData={ specData }
            timeLeft={ timeLeft }
            setIsCounting={ setIsCounting }
            isCounting={ isCounting }
          />
        ) : (
          <></>
        ) }
      </div>
    </div>
  )
}

export default GetReference

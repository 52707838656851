import { useQuery, } from '@apollo/client'
import { useMemo, } from 'react'
import useConfigSubscription from './useConfigSubscription'
import { GET_CONFIGS, } from './useGetEcDocuments'


const useGetEcStages = () => {
  const { loading, data, refetch, } = useQuery(GET_CONFIGS, {
    variables: {
      domain: 'effective-contract',
      key: 'stages',
    },
  })

  useConfigSubscription({
    condition: {
      domain: 'effective-contract',
      key: 'stages',
    },
    refetch,
  })


  const stages = useMemo(() => {
    if (!loading && data) {
      const stages = data.utilConfig.config.stages
      const active_stage = data.utilConfig.config.active_stage

      return {
        stages,
        active_stage,
      }
    } return {
      stages: [],
      active_stage: null,
    }
  }, [
    loading,
    data,
  ])


  return { ...stages, }
}


export default useGetEcStages

import React from 'react'

const ProfileBlock = ({
  component: Component,
  title,
}: any) => {
  return (
    <div className="personalWrap">
      <h3 className="profileSubtitle">{ title }</h3>
      <Component />
    </div>
  )
}

export default ProfileBlock

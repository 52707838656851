import clsx from 'clsx'
import { TitleH4, } from '../../../common/GeneralElements/GeneralElements'
import styles from './CommentsHistory.module.less'
import { defineIsAnotherAuthor, } from './commentsHistoryUtils'
import { useEffect, useRef, useState, } from 'react'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import useUpdateAchievement from '@/legacy/gql/hooks/portfolio/useUpdateAchievement'
import ButtonSimple, { IconComponent } from '@/legacy/components/common/ButtonSimple/ButtonSimple'
import { IconTrash } from '@tabler/icons'


const CommentsHistory = ({ history, achievement_id }: any) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const userRole = useGetRole()
  const updateAchievement = useUpdateAchievement()
  const [localHistory, setHistory] = useState(history);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [history]);

  const handleDeleteComment = (messageDelete: any) => {
    const newHistory = localHistory.filter((message: any) => message.message !== messageDelete);
    setHistory(newHistory);
    updateAchievement({
      achievement_id: achievement_id,
      comment: newHistory,
    });
  };

  return (
    <>
      <TitleH4>История переписки:</TitleH4>
      <div className={styles.scrollContainer} ref={scrollContainerRef}>
        <div className={styles.bubblesWrapper}>
          {localHistory.map((message: any, index: any, allHistory: any) => {
            return (
              <div
                key={index}
                className={clsx(styles.messageBubbe, {
                  [styles.messageBubbe_secondary]: defineIsAnotherAuthor(allHistory, index),
                })}
              >
                <h3 className={clsx(styles.authorName, {
                  [styles.authorName_secondary]: defineIsAnotherAuthor(allHistory, index),
                })}>
                  {message?.authorName}
                </h3>
                
                <p className={styles.messageText}>
                  {message?.message}
                </p>
                {userRole === 'urtos-moder' && (
                  <ButtonSimple
                  className={styles.deleteButton}
                  variant={ 'alone' }
                  value={
                    <IconComponent customClassName={styles.deleteIcon} text="Удалить" Icon={ IconTrash }  />
                  }
                  onClickHandler={() => handleDeleteComment(message?.message)}
                />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CommentsHistory;

